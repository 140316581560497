import app from '@/firebase'
import {
    getFirestore,
    collection,
    doc,
    runTransaction,
    getDocs,
    getDoc,
    setDoc,
} from "firebase/firestore";
const db = getFirestore(app)

export default {
    namespaced: true,
    state: {
        items: [],
        currentItem: "",
        systemList: [],
        unknownStats: {number:0,percent:0}
    },
    actions: {
        async getSites({
            commit,
            getters,
            rootGetters
        }) {
            try {
                let val
                rootGetters.systemData.sitList.forEach(async el => {
                    val = getters.sitios.find(elem => elem.id === el)
                    if (!val) {
                        let item = await getDoc(doc(db, 'Sitios', el))
                        commit('ADD_SITIO', {
                            id: item.id,
                            ...item.data(),
                            estadistica: 0
                        })
                    }
                })
                return true
            } catch (e) {
                console.log('error', e)
            }
        },
        async getRooms({
                commit
            },
            payload
        ) {
            try {
                let qS = await getDocs(collection(db, "Sitios/" + payload.id + "/rooms"))
                let arr = []
                qS.forEach(doc => {
                    arr.push({
                        id: doc.id,
                        ...doc.data()
                    })
                })
                payload.rooms = arr
                commit("ADD_SITIO", payload)
                return true
            } catch (e) {
                console.log("error", e)
            }
        },
        async addNewSite({
            commit
        }, payload) {
            try {
                await setDoc(doc(db, "Sitios", payload.id), {
                    ...payload.data,
                    nextIndex: 1
                })
                commit("ADD_SITE", {
                    id: payload.id,
                    nextIndex: 1,
                    ...payload.data
                })
                return true
            } catch (e) {
                console.log("error", e)
            }
        },
        async addNewRoom({
            commit
        }, payload) {
            try {
                let site = doc(db, "Sitios", payload.site)
                let result = await runTransaction(db, async (transaction) => {
                    let doc = await transaction.get(site)
                    let index = doc.data().nextIndex
                    let id
                    if (index < 10) {
                        id = "00" + index
                    } else if (index > 9 && index < 100) {
                        id = "0" + index
                    } else {
                        id = index
                    }
                    let newIndex = index + 1
                    let rooms = doc.data().rooms
                    rooms.push({
                        id: 'HAB' + id,
                        name: payload.name
                    })
                    transaction.update(site, {
                        rooms: rooms
                    })
                    transaction.update(site, {
                        nextIndex: newIndex
                    })
                    return {
                        site: site.id,
                        room: {
                            id: "HAB" + id,
                            name: payload.name
                        }
                    }
                })
                commit("ADD_ROOM_TO_SITE", result)
                return result
            } catch (e) {
                console.log("error", e)
            }
        },
        async getStatistics({
            commit,
            rootGetters
        }) {
            rootGetters.allItems.forEach(item => {
                item.found.forEach(elem => {
                    commit("SUM_ONE_TO_STATISTIC", elem)
                })
            })
            return true
        },
    },
    getters: {
        sitios(state) {
            return state.items
        },
        abcItems: state => {
            let arr = []
            if (state.items.length > 0) {
                state.items.forEach(item => {
                    let char = item.name.split('')[0]
                    let exists = arr.find(el => el === char.toUpperCase())
                    if (exists === undefined) {
                        arr.push(char.toUpperCase())
                    }
                })
            }
            return arr
        },
        itemsByIndex: state => payload => {
            let arr = []
            state.items.forEach(item => {
                let char = item.name.split('')[0]
                if (char.toUpperCase() === payload) {
                    if (item.name !== "Listado Cajas") {
                        arr.push(item)
                    }
                }
            })
            return arr
        },
        itemById: state => payload => {
            return state.items.find(item => item.id === payload)
        },
        getSiteById: state => payload => {
            let site = state.items.find(item => item.id === payload)
            if (site) {
                return site
            }
            return null
        },
        getRoomById: state => payload => {
            let site = state.items.find(item => item.id === payload.siteId)
            if (site) {
                let room = site.rooms.find(item => item.id === payload.roomId)
                if (room) {
                    return room
                }
            }
            return null
        },
        getSitiosInExhibition(state) {
            console.log(state.items)
            return state.items.filter(site => site.deposit === false)
        },
        getDepositSites(state) {
            return state.items.filter(site => site.deposit === true)
        },
        statistics:state=>{//retorna lista de elementos ordenados de mayor a menor estadistica
            let arr=[]
            let pos=0
            arr.push({
                estadistica:state.unknownStats.number,
                percent:state.unknownStats.percent,
                id:'Desconocido',
                name:'Desconocido'
            })
            state.items.forEach(item=>{
                pos=0
                arr.forEach(el=>{
                    if(el.estadistica>item.estadistica){
                        pos++
                    }
                })
                arr.splice(pos,0,item)
            })
            return arr
        }
    },
    mutations: {
        ADD_SITIO(state, payload) {
            let sitio = state.items.find(item => item.id === payload.id)
            if (sitio) {
                sitio = payload
                if (sitio.image === null || sitio.image === undefined) {
                    sitio.image = ""
                }
            } else {
                if (payload.image === null || payload.image === undefined) {
                    payload.image = ""
                }
                state.items.push(payload)
            }
        },
        ADD_ROOM_TO_SITE(state, payload) {
            let sitio = state.items.find(item => item.id === payload.site)
            if (sitio) {
                let room = sitio.rooms.find(item => item.id === payload.room.id)
                if (room) {
                    room = payload.room
                } else {
                    sitio.rooms.push(payload.room)
                }
            }
        },
        SUM_ONE_TO_STATISTIC(state, payload) {
            if (payload.label === 'sitio') {
                if (payload.value !== 'Desconocido') {
                    let elem = state.items.find(item => item.id === payload.value)
                    if (elem) {
                        elem.estadistica = elem.estadistica + 1
                    }
                }else{
                    state.unknownStats.number++
                }
            }
        },
        CALC_PERCENTS(state){
            let max=state.unknownStats.number
            state.items.forEach(element=>{
                if(element.estadistica>max){
                    max=element.estadistica
                }
            })
            state.items.forEach(element=>{
                element.porcentaje=element.estadistica/max
            })
            state.unknownStats.percent=state.unknownStats.number/max
        },
    }
}