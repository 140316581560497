import jspdf from "jspdf";
export default {
  state: {
    document: new jspdf({
      format: "letter",
    }),
    marginTop: 0,
    marginLeft: 0,
    cardInitialPosition: 0,
    objeto: null,
    relatedOjects: [],
    conjunto: [],
    paragraph: {},
    page: 1,
    icon: {},
    objetoActual: {},
    height: 0,
    cardPosition: 1,
  },
  actions: {
    async imprimirObjeto({
      commit,
      getters,
      rootGetters,
    }) {
      commit("RESET_DOCUMENTO")
      commit("ADD_MAIN_IMAGE", getters.mainImage)
      commit("SET_SIDE")

      commit("SET_MARGIN_LEFT", 14)
      commit("SET_MARGIN_TOP", 89)


      commit("ADD_SIDE_TITLE", {
        payload: "MEDIDAS",
        getters
      })
      getters.objeto.measurements.forEach((item) => {
        commit("ADD_TEXTO_COMPUESTO_TO_SIDE", {
          payload: {
            title: item.dimension + ":",
            content: item.measure
          },
          getters
        })
      });
      commit("NEXT_SECTION", {
        top: 5,
        left: 14
      })
      commit("ADD_SIDE_TITLE", {
        payload: "TÉCNICA",
        getters
      })
      let text = "";
      let list = rootGetters["techniques/items"]
      getters.objeto.techniques.forEach((item, index) => {
        text = text + list.find(el => el.id === item).name
        if (index < getters.objeto.techniques.length - 1) {
          text = text + ", ";
        }
      });
      commit("ADD_TEXTO_TO_SIDE", {
        payload: text,
        getters
      })
      commit("NEXT_SECTION", {
        top: 5,
        left: 14
      })
      commit("ADD_SIDE_TITLE", {
        payload: "MATERIAL",
        getters
      })
      text = "";
      list = rootGetters["materials/items"]
      getters.objeto.materials.forEach((item, index) => {
        text = text + list.find(el => el.id === item).name
        if (index < getters.objeto.materials.length - 1) {
          text = text + ", ";
        }
      });
      commit("ADD_TEXTO_TO_SIDE", {
        payload: text,
        getters
      })
      commit("NEXT_SECTION", {
        top: 5,
        left: 14
      })
      commit("ADD_SIDE_TITLE", {
        payload: "ESTADO DEL OBJETO",
        getters
      })
      commit("ADD_TEXTO_COMPUESTO_TO_SIDE", {
        payload: {
          title: getters.objeto.pieceStatus.status + ",",
          content: getters.objeto.pieceStatus.comments
        },
        getters
      })
      commit("NEXT_SECTION", {
        top: 5,
        left: 14
      })
      commit("ADD_SIDE_TITLE", {
        payload: "CONCEPTOS RELACIONADOS",
        getters
      })
      text = "";
      list = rootGetters["keywords/items"]
      getters.objeto.keyWords.forEach((item, index) => {
        text = text + list.find(el => el.id === item).name
        if (index < getters.objeto.keyWords.length - 1) {
          text = text + ", ";
        }
      });
      commit("ADD_TEXTO_TO_SIDE", {
        payload: text,
        getters
      })


      commit("SET_MARGIN_LEFT", 79)
      commit("SET_MARGIN_TOP", 79)
      commit("ADD_TEXTO_COMPUESTO_TO_HEADER", {
        payload: {
          title: "Registrador:",
          content: getters.objeto.creator
        },
        getters
      })
      commit("ADD_TEXTO_COMPUESTO_TO_HEADER", {
        payload: {
          title: "Categoría:",
          content: getters.categorias
        },
        getters
      })
      commit("ADD_TEXTO_COMPUESTO_TO_HEADER", {
        payload: {
          title: "ID:",
          content: getters.objeto.id
        },
        getters
      })
      commit("ADD_HEADER_TITLE", {
        payload: getters.objeto.name,
        getters
      })


      commit("SET_MARGIN_LEFT", 79)
      commit("SET_MARGIN_TOP", 89)
      commit("ADD_SECTION_TITLE_TO_PRINCIPAL", "DESCRIPCIÓN")
      commit("ADD_TEXTO_TO_PRINCIPAL", {
        payload: {
          text: getters.objeto.description,
          ancho: 128
        },
        getters
      })
      commit("NEXT_SECTION", {
        top: 8,
        left: 79
      })
      commit("ADD_SECTION_TITLE_TO_PRINCIPAL", "UBICACIÓN")
      commit("ADD_TEXTO_COMPUESTO_TO_PRINCIPAL", {
        payload: {
          title: "Lugar de Origen:",
          content: getters.objeto.origin,
          ancho: 128
        },
        getters
      })
      commit("ADD_TEXTO_COMPUESTO_TO_PRINCIPAL", {
        payload: {
          title: "Lugar donde fue Encontrado:",
          content: getters.encontradoEn,
          ancho: 128
        },
        getters
      })
      if (getters.ubicacion !== "") {
        commit("ADD_TEXTO_COMPUESTO_TO_PRINCIPAL", {
          payload: {
            title: getters.ubicacion.title,
            content: getters.ubicacion.value,
            ancho: 128
          },
          getters
        })
      }
      if (getters.objeto.locationComments !== "") {
        commit("ADD_TEXTO_COMPUESTO_TO_PRINCIPAL", {
          payload: {
            title: "Otras referencias de la Ubicación:",
            content: getters.objeto.locationComments,
            ancho: 128
          },
          getters
        })
      }
      commit("NEXT_SECTION", {
        top: 8,
        left: 79
      })
      if (rootGetters.user) {
        commit("ADD_SECTION_TITLE_TO_PRINCIPAL", "GESTIÓN DEL OBJETO")
        commit("ADD_TEXTO_COMPUESTO_TO_PRINCIPAL", {
          payload: {
            title: "Se han realizado acciones preventivas:",
            content: getters.objeto.conservation.requires,
            ancho: 128
          },
          getters
        })
        commit("ADD_TEXTO_COMPUESTO_TO_PRINCIPAL", {
          payload: {
            title: "¿Qué acciones preventivas?:",
            content: getters.objeto.conservation.comments,
            ancho: 128
          },
          getters
        })
        commit("ADD_TEXTO_COMPUESTO_TO_PRINCIPAL", {
          payload: {
            title: "Requiere restauración:",
            content: getters.objeto.restoration,
            ancho: 128
          },
          getters
        })
        commit("ADD_TEXTO_COMPUESTO_TO_PRINCIPAL", {
          payload: {
            title: "Primer propietario o Donante:",
            content: getters.objeto.donant,
            ancho: 128
          },
          getters
        })
        commit("ADD_TEXTO_COMPUESTO_TO_PRINCIPAL", {
          payload: {
            title: "Ubicación del Rotulado:",
            content: getters.objeto.identifier,
            ancho: 128
          },
          getters
        })
        commit("ADD_TEXTO_COMPUESTO_TO_PRINCIPAL", {
          payload: {
            title: "Observaciones:",
            content: getters.objeto.others,
            ancho: 128
          },
          getters
        })
      }
      if (getters.objeto.compuesto) {
        commit("ADD_PAGE")
        commit("ADD_SECTION_TITLE_TO_PRINCIPAL", "ELEMENTOS DEL CONJUNTO")
        commit("ADD_TEXTO_TO_PRINCIPAL", {
          payload: {
            text: "Este objeto está compuesto por un conjunto de piezas, partes y/o objetos de caracteríticas similares que a continuación se detallan:",
            ancho: 198
          },
          getters
        })
        commit("SET_MARGIN_TOP",getters.marginTop-3)
        getters.conjunto.forEach(item => {
          if (getters.freeSpace < 50) {

            commit("SET_MARGIN_TOP", 15)
            commit("SET_MARGIN_LEFT", 9)
            commit("ADD_PAGE")
          }
          commit("SET_TEXT_HEIGHT", 10)
          commit("CALC_TEXT_HEIGHT", {
            payload: {
              text: "ID: " + item.id,
              ancho: 141
            },
            getters
          })

          if (item.description !== "") {
            commit("CALC_TEXT_HEIGHT", {
              payload: {
                text: "Descripción: " + item.description,
                ancho: 141
              },
              getters
            })
          }
          if (item.techniques !== "") {
            commit("CALC_TEXT_HEIGHT", {
              payload: {
                text: "Técnica: " + item.techniques,
                ancho: 141
              },
              getters
            })
          }
          if (item.materials !== "") {
            commit("CALC_TEXT_HEIGHT", {
              payload: {
                text: "Materiales: " + item.materials,
                ancho: 141
              },
              getters
            })
          }
          if (item.dimensions.length > 0) {
            item.dimensions.forEach(el => {
              commit("CALC_TEXT_HEIGHT", {
                payload: {
                  text: el.dimension + " - " + el.value,
                  ancho: 141
                },
                getters
              })
            })
          }
          commit("SET_CARD_INITAL_POSITION")
          commit("ADD_CARD_CONTAINER")
          commit("SET_MARGIN_TOP", getters.marginTop + 5)
          commit("ADD_CARD_MAIN_IMAGE", {
            name: item.id + "main",
            img: item.referencia
          })
          commit("SET_MARGIN_TOP", getters.marginTop + 5)
          commit("SET_MARGIN_LEFT", 62)
          commit("ADD_CARD_TITLE", {
            text: item.name,
            ancho: 122
          })
          commit("ADD_TEXTO_COMPUESTO_TO_PRINCIPAL", {
            payload: {
              title: "ID:",
              content: item.id,
              ancho: 141
            },
            getters
          })
          if (item.description !== "") {
            commit("ADD_TEXTO_COMPUESTO_TO_PRINCIPAL", {
              payload: {
                title: "Descripción:",
                content: item.description,
                ancho: 141
              },
              getters
            })
          }
          if (item.techniques !== "") {
            commit("ADD_TEXTO_COMPUESTO_TO_PRINCIPAL", {
              payload: {
                title: "Técnica:",
                content: item.techniques,
                ancho: 141
              },
              getters
            })
          }
          if (item.materials !== "") {
            commit("ADD_TEXTO_COMPUESTO_TO_PRINCIPAL", {
              payload: {
                title: "Materiales:",
                content: item.materials,
                ancho: 141
              },
              getters
            })
          }
          let pos = getters.marginTop
          if (item.dimensions.length > 0) {
            commit("ADD_TEXTO_COMPUESTO_TO_PRINCIPAL", {
              payload: {
                title: "Dimensiones:",
                content: "",
                ancho: 141
              },
              getters
            })
            commit("SET_PARAGRAPH_DATA", {
              text: "Dimensiones:"
            })
            let ancho = getters.getTextWidth + 3
            commit("SET_MARGIN_LEFT", 62 + ancho)
            commit("SET_MARGIN_TOP", pos)
            item.dimensions.forEach(item => {
              commit("ADD_TEXTO_TO_PRINCIPAL", {
                payload: {
                  text: item.dimension + " - " + item.value,
                  ancho: 141 - ancho
                },
                getters
              })
            })
          }
          commit("SET_MARGIN_TOP", getters.marginTop + 8)
          if (getters.marginTop - getters.cardInitialPosition < 50) {
            commit("SET_MARGIN_TOP", getters.cardInitialPosition + 54)
          }
          if (getters.freeSpace < 50) {
            commit("SET_MARGIN_TOP", 15)
            commit("SET_MARGIN_LEFT", 9)
            commit("ADD_PAGE")
          }
        })
      }
      if (getters.freeSpace < 70) {
        commit("ADD_PAGE")

      }
        if (getters.page === 1) {
          commit("NEXT_SECTION", {
            top: 8,
            left: 79
          })
        } else {
          commit("NEXT_SECTION", {
            top: 8,
            left: 9
          })
        
      }
      commit("ADD_SECTION_TITLE_TO_PRINCIPAL", "FOTOGRAFÍAS")
      if (getters.objeto.compuesto&&getters.objeto.images.length>0) {

        commit("ADD_IMAGE_SECTION", "CONJUNTO")
      }
      if(getters.objeto.images.length>0){
        commit("ADD_IMAGES", {
          getters
        })
      }
      if (getters.objeto.compuesto) {
        getters.conjunto.forEach(item => {
          if (getters.freeSpace < 55) {
            commit("ADD_PAGE")
          }
          commit("ADD_IMAGE_SECTION", item.id)
          commit("ADD_IMAGES_CONJUNTO", {
            payload: item,
            getters
          })
        })
      }
      commit("SET_MARGIN_LEFT",9)
      commit("SET_MARGIN_TOP",getters.marginTop+5)

      if (getters.relatedOjects.length > 0) {

        if (getters.freeSpace < 90) {
          commit("ADD_PAGE")
        }
        commit("ADD_SECTION_TITLE_TO_PRINCIPAL", "OTROS OBJETOS DE INTERÉS")
        getters.relatedOjects.forEach(item => {
          commit("ADD_SMALL_CARD")
          commit("ADD_CARD_IMAGE", {
            img: item.img,
            name: item.id
          })
          if (getters.cardPosition === 1) {
            commit("SET_MARGIN_LEFT", 47)
          } else {
            commit("SET_MARGIN_LEFT", 148)
          }
          commit("ADD_CARD_TITLE", {
            text: item.name,
            ancho: 55
          })
          commit("ADD_TEXTO_COMPUESTO_TO_PRINCIPAL", {
            payload: {
              title: "ID:",
              content: item.id,
              ancho: 55
            },
            getters
          })
          commit("ADD_TEXTO_COMPUESTO_TO_PRINCIPAL", {
            payload: {
              title: "Relación:",
              content: item.relation,
              ancho: 55
            },
            getters
          })
          if (getters.cardPosition === 1) {
            commit("SET_CARD_POSITION", 2)
          } else {
            commit("SET_CARD_POSITION", 1)
            commit("SET_MARGIN_TOP", getters.cardInitialPosition + 42)
            if (getters.freeSpace < 40) {
              commit("ADD_PAGE")
            }
          }
        })

      }
    }
  },
  getters: {
    objeto(state) {
      return state.objeto
    },
    paragraph(state) {
      let i = 1;
      let words = state.paragraph.text.split(" ");
      let largo = 0;
      let firstline = "";
      words.forEach((item) => {
        if (largo + state.document.getTextWidth(item + " ") < state.paragraph.width) {
          largo = largo + state.document.getTextWidth(item + " ");
          if (i === 1) {
            firstline = firstline + item + " ";
          }
        } else {
          i++;
          largo = state.document.getTextWidth(item + " ");
        }
      });
      if (i > 1) {
        return {
          multiline: true,
          linea: state.paragraph.text.slice(0, firstline.length - 1),
          resto: state.paragraph.text.slice(firstline.length),
        };
      } else {
        return {
          multiline: false,
          texto: state.paragraph.text
        };
      }
    },
    countLines(state) {
      let i = 1;
      let words = state.paragraph.text.split(" ");
      let largo = 0;
      words.forEach((item) => {
        if (largo + state.document.getTextWidth(item + " ") < state.paragraph.width) {
          largo = largo + state.document.getTextWidth(item + " ");
        } else {
          i++;
          largo = state.document.getTextWidth(item + " ");
        }
      });
      return i;
    },
    encontradoEn(state, rootGetters){
      let text=""
      state.objeto.found.forEach((item) => {
        if (item.label === "sitio") {
          if(item.value==="Desconocido"||item.value==="desconocido"){

            text="Lugar desconocido"
          }else{
          let site = rootGetters['sitios/sitios'].find(el => el.id === item.value)
          text=site.name}
        } });
        return text
    },

    ubicacion(state, rootGetters) {
      if (state.objeto.exhibition !== "") {
        let text = {};
        text.title = "Ubicación actual:";
        text.value = "";
        if (state.objeto.exhibition === "No") {
          text.value = "Depósito";
          if (rootGetters.user) {
            if (state.objeto.location.label === "Deposito") {
              text.value = text.value + ", " + state.objeto.location.value;
            } else {
              text.value =
                text.value + ", Caja: " + state.objeto.location.value;
            }
          }
        } else {
          text.value = "En Exhibición";
          let site
          if (rootGetters.user) {
            if (state.objeto.location) {
              state.objeto.location.forEach((item) => {
                if (item.label === "Sitio") {
                  site = rootGetters['sitios/sitios'].find(el => el.id === item.value)
                  text.value =
                    text.value +
                    ", Sitio: " +
                    site.name;
                } else {
                  let room = site.rooms.find(el => el.id === item.value)
                  text.value =
                    text.value +
                    ", en la Habitación: " +
                    room.name;
                }
              });
            }
          } else {
            if (state.objeto.location) {
              state.objeto.location.forEach((item) => {
                if (item.label === "Sitio") {
                  site = rootGetters['sitios/sitios'].find(el => el.id === item.value)
                  text.value =
                    text.value +
                    ", Sitio: " +
                    site.name;
                }
              });
            }
          }
        }
        return text;
      } else {
        return "";
      }
    },
    mainImage(state) {
      return state.objeto.image
    },
    categorias(state) {
      let text = "";
      state.objeto.clasification.forEach((item) => {
        if (text === "") {
          text = item.name;
        } else {
          text = text + " - " + item.name;
        }
      });
      return text;
    },
    freeSpace(state) {
      return 271 - state.marginTop
    },
    conjunto(state) {
      return state.conjunto
    },
    marginTop(state) {
      return state.marginTop
    },
    getTextWidth(state) {
      return state.document.getTextDimensions(state.paragraph.text).w
    },
    calcCardHeight(state) {
      let height = 10
      if (state.objetoActual.description !== "") {
        height = height + 6
      }
      return height
    },
    cardPosition(state) {
      return state.cardPosition
    },
    relatedOjects(state) {
      return state.relatedOjects
    },
    cardInitialPosition(state) {
      return state.cardInitialPosition
    },
    images(state) {
      return state.objeto.images
    },
    page(state){
      return state.page
    }
  },
  mutations: {
    SET_OBJETO(state, payload) {
      state.objeto = payload
      state.objeto.imageList = []
    },
    SET_RELATED(state, payload) {
      state.relatedOjects = payload
    },
    SET_ICON(state, payload) {
      state.icon = payload
    },
    SET_OBJETO_ACTUAL(state, payload) {
      state.objetoActual = payload
    },
    SET_CONJUNTO(state, payload) {
      state.conjunto = payload
    },
    SET_MAIN_IMAGE(state, payload) {
      state.objeto.image = payload
    },
    PUSH_IMAGE(state, payload) {
      if (!state.objeto.imageList) {
        state.objeto.imageList = []
      }
      state.objeto.imageList.push(payload)
    },
    RESET_DOCUMENTO(state) {
      state.document = new jspdf({
        format: "letter",
      })
      state.page = 1
    },
    SET_TEXT_HEIGHT(state, payload) {
      state.height = payload
    },
    CALC_TEXT_HEIGHT(state, {
      payload,
      getters
    }) {
      state.document.setFontSize(12)
      state.document.setTextColor(58, 58, 58)
      state.document.setFont("Helvetica", "")
      let dimension = state.document.getTextDimensions(payload.text)
      state.paragraph = {
        text: payload.text,
        width: payload.ancho
      }
      let lines = getters.countLines;
      state.height = state.height + lines * dimension.h * 1.25;
    },
    SET_SIDE(state) {
      state.document.setDrawColor(0);
      state.document.setFillColor(153, 69, 0);
      state.document.roundedRect(9, 79, 66, 192, 5, 5, "F");
    },
    ADD_PAGE(state) {
      state.document.addPage()
      state.marginLeft = 9
      state.marginTop = 15
      state.page = state.page + 1
    },
    SET_MARGIN_TOP(state, payload) {
      state.marginTop = payload
    },
    SET_MARGIN_LEFT(state, payload) {
      state.marginLeft = payload
    },
    ADD_MAIN_IMAGE(state, payload) {
      state.document.addImage(payload, "JPEG", 9, 9, 66, 66, "main", "NONE");
      state.document.setDrawColor(255, 255, 255);
      state.document.setLineWidth(6)
      state.document.roundedRect(6, 6, 72, 72, 7, 7, "S");
    },
    ADD_TEXTO_COMPUESTO_TO_PRINCIPAL(state, {
      payload,
      getters
    }) {
      state.document.setFontSize(12)
      state.document.setTextColor(58, 58, 58)
      state.document.setFont("Helvetica", "Bold")


      state.document.text(payload.title, state.marginLeft, state.marginTop)
      let dimension = state.document.getTextDimensions(payload.title)
      let endPosition = dimension.w + 1 + state.marginLeft;

      state.document.setFont("Helvetica", "");
      let lines = 0
      state.paragraph = {
        text: payload.content,
        width: payload.ancho - dimension.w - 1
      }
      let response = getters.paragraph
      if (response.multiline) {
        state.document.text(response.linea, endPosition, state.marginTop, {
          maxWidth: payload.ancho - dimension.w - 1,
          lineHeightFactor: 1.25,
        });
        state.marginTop = state.marginTop + dimension.h * 1.25
        state.document.text(response.resto, state.marginLeft, state.marginTop, {
          maxWidth: payload.ancho,
          lineHeightFactor: 1.25,
        });
        state.paragraph = {
          text: response.resto,
          width: payload.ancho
        }
        lines = getters.countLines;
        state.marginTop = state.marginTop + lines * dimension.h * 1.25;
      } else {
        state.document.text(response.texto, endPosition, state.marginTop, {
          maxWidth: payload.ancho - dimension.w - 1,
          lineHeightFactor: 1.25,
        });
        state.marginTop = state.marginTop + dimension.h * 1.25;
      }
    },
    ADD_SECTION_TITLE_TO_PRINCIPAL(state, payload) {
      state.document.setFont("Helvetica", "");
      state.document.setFontSize(20);
      state.document.setTextColor(153, 69, 0);
      state.document.text(payload, state.marginLeft, state.marginTop);
      state.marginTop = state.marginTop + state.document.getTextDimensions(payload).h;
    },
    ADD_TEXTO_TO_PRINCIPAL(state, {
      payload,
      getters
    }) {
      state.document.setFontSize(12)
      state.document.setTextColor(58, 58, 58)
      state.document.setFont("Helvetica", "")
      state.document.text(payload.text, state.marginLeft, state.marginTop, {
        maxWidth: payload.ancho,
        lineHeightFactor: 1.25,
      })
      let dimension = state.document.getTextDimensions(payload.text)

      state.paragraph = {
        text: payload.text,
        width: payload.ancho
      }
      let lines = getters.countLines;
      state.marginTop = state.marginTop + lines * dimension.h * 1.25;
    },
    NEXT_SECTION(state, payload) {
      state.marginTop = state.marginTop + payload.top
      state.marginLeft = payload.left
    },
    SET_PARAGRAPH_DATA(state, payload) {
      state.paragraph = payload
    },
    ADD_TEXTO_COMPUESTO_TO_HEADER(state, {
      payload,
      getters
    }) {

      state.document.setFontSize(12)
      state.document.setTextColor(58, 58, 58)
      state.document.setFont("Helvetica", "Bold");

      let dimension = state.document.getTextDimensions(payload.title)
      state.marginLeft = dimension.w + 1 + 79;

      let lines = 0
      state.paragraph = {
        text: payload.content,
        width: 128 - dimension.w - 1
      }
      let response = getters.paragraph;
      if (response.multiline) {
        state.paragraph = {
          text: response.resto,
          width: 128
        }
        lines = getters.countLines
        state.document.setFont("Helvetica", "Bold")
        state.document.text(payload.title, 79, state.marginTop)
        state.document.setFont("Helvetica", "");
        state.document.text(response.linea, state.marginLeft, state.marginTop, {
          maxWidth: 128 - dimension.w - 1,
          lineHeightFactor: 1.25,
        });
        state.marginTop = state.marginTop - (lines + 1) * dimension.h * 1.25 - 2;
        state.marginTop = state.marginTop + dimension.h * 1.25
        state.document.text(response.resto, 79, state.marginTop, {
          maxWidth: 128,
          lineHeightFactor: 1.25,
        });
        state.marginTop = state.marginTop - dimension.h * 1.25
      } else {
        state.marginTop = state.marginTop - dimension.h * 1.25
        state.document.setFont("Helvetica", "Bold")
        state.document.text(payload.title, 79, state.marginTop)
        state.document.setFont("Helvetica", "");
        state.document.text(response.texto, state.marginLeft, state.marginTop, {
          maxWidth: 128 - dimension.w - 1,
          lineHeightFactor: 1.25,
        });
      }
    },
    ADD_HEADER_TITLE(state, {
      payload,
      getters
    }) {
      state.document.setFont("Helvetica", "Bold");
      state.document.setFontSize(35);
      state.document.setTextColor(153, 69, 0);
      let dimension = state.document.getTextDimensions(payload)

      state.paragraph = {
        text: payload,
        width: 128
      }
      let lines = getters.countLines
      state.marginTop = state.marginTop - lines * dimension.h;
      state.document.text(payload, 79, state.marginTop, {
        maxWidth: 128
      });
    },
    ADD_TEXTO_COMPUESTO_TO_SIDE(state, {
      payload,
      getters
    }) {
      state.document.setFontSize(12);
      state.document.setTextColor(255, 255, 255);
      state.document.setFont("Helvetica", "Bold")


      state.document.text(payload.title, 14, state.marginTop, {
        maxWidth: 56
      })
      let dimension = state.document.getTextDimensions(payload.title)
      state.marginLeft = dimension.w + 1 + 14;

      state.document.setFont("Helvetica", "");
      let lines = 0
      state.paragraph = {
        text: payload.content,
        width: 56 - dimension.w - 1
      }
      let response = getters.paragraph
      if (response.multiline) {
        state.document.text(response.linea, state.marginLeft, state.marginTop, {
          maxWidth: 56 - dimension.w - 1,
          lineHeightFactor: 1.25,
        });
        state.marginTop = state.marginTop + dimension.h * 1.25
        state.document.text(response.resto, 14, state.marginTop, {
          maxWidth: 56,
          lineHeightFactor: 1.25,
        });

        state.paragraph = {
          text: response.resto,
          width: 56
        }
        lines = getters.countLines
        state.marginTop = state.marginTop + (lines + 1) * dimension.h * 1.25;
      } else {
        state.document.text(response.texto, state.marginLeft, state.marginTop, {
          maxWidth: 56 - dimension.w - 1,
          lineHeightFactor: 1.25,
        });
        state.marginTop = state.marginTop + dimension.h * 1.25;
      }
    },
    ADD_SIDE_TITLE(state, {
      payload,
      getters
    }) {
      state.document.setFont("Helvetica", "");
      state.document.setFontSize(16);
      state.document.setTextColor(255, 241, 230);
      state.document.text(payload, state.marginLeft, state.marginTop, {
        maxWidth: 56
      });
      let dimension = state.document.getTextDimensions(payload)

      state.paragraph = {
        text: payload,
        width: 56
      }
      let lines = getters.countLines
      state.marginTop = state.marginTop + lines * dimension.h + 2;
    },
    ADD_TEXTO_TO_SIDE(state, {
      payload,
      getters
    }) {
      state.document.setFontSize(12);
      state.document.setTextColor(255, 255, 255);
      state.document.setFont("Helvetica", "")
      state.document.text(payload, state.marginLeft, state.marginTop, {
        maxWidth: 56
      })
      let dimension = state.document.getTextDimensions(payload)

      state.paragraph = {
        text: payload,
        width: 56
      }
      let lines = getters.countLines
      state.marginTop = state.marginTop + lines * dimension.h * 1.25;
    },
    PRINT(state) {
      state.document.save(state.objeto.id + ".pdf");
    },
    ADD_IMAGES(state, {
      getters
    }) {
      state.document.setFontSize(12)
      state.document.setTextColor(58, 58, 58)
      state.document.setFont("Helvetica", "")

      state.document.setDrawColor(255, 255, 255);
      state.document.setLineWidth(4)
      let dimension = state.document.getTextDimensions('Referencia')
      let lines = 0
      let left = [79, 10]
      if (state.objeto.compuesto) {
        left = [100, 23]
      }
      if (state.page === 1) {
        state.marginLeft = left[0]
      } else {
        state.marginLeft = left[1]
      }
      state.objeto.imageList.forEach((imagen, index) => {
        state.document.addImage(imagen.img, "JPEG", state.marginLeft, state.marginTop, 45, 45, "image" + index, "NONE");
        state.document.roundedRect(state.marginLeft - 2, state.marginTop - 2, 49, 49, 6, 6, "S");

        state.document.text(imagen.name, state.marginLeft + 22.5, state.marginTop + 50, {
          maxWidth: 45,
          align: "center"
        })
        state.paragraph = {
          text: imagen.name,
          width: 45
        }
        if (getters.countLines > lines) {
          lines = getters.countLines
        }
        if ((216 - state.marginLeft - 49) >= 59) {
          state.marginLeft = state.marginLeft + 49
        } else {
          state.marginTop = state.marginTop + 45 + dimension.h * lines + 2
          if (state.page === 1) {
            if (getters.freeSpace > 60) {
              state.marginLeft = left[0]
            } else {
              state.document.addPage()
              state.marginLeft = left[1]
              state.marginTop = 15
              state.page = state.page + 1
            }
          } else {
            state.marginLeft = left[1]
            if (getters.freeSpace < 60) {
              state.document.addPage()
              state.marginLeft = left[1]
              state.marginTop = 15
              state.page = state.page + 1
            }
          }
        }
      })
      state.marginTop = state.marginTop + 50 + dimension.h * lines + 2
    },
    ADD_CARD_MAIN_IMAGE(state, payload) {
      state.document.addImage(payload.img, "JPEG", 13, state.marginTop, 45, 45, payload.name, "NONE");
      state.document.setDrawColor(217, 217, 217);
      state.document.setLineWidth(4)
      state.document.roundedRect(11, state.marginTop - 2, 49, 49, 6, 6, "S");
    },
    ADD_CARD_TITLE(state, payload) {
      state.document.setFont("Helvetica", "");
      state.document.setFontSize(16);
      state.document.setTextColor(153, 69, 0);
      state.document.text(payload.text, state.marginLeft, state.marginTop, {
        maxWidth: payload.ancho
      });
      state.marginTop = state.marginTop + state.document.getTextDimensions(payload.text).h;
    },
    SET_CARD_INITAL_POSITION(state) {
      state.cardInitialPosition = state.marginTop + 1
    },
    ADD_CARD_CONTAINER(state) {
      state.document.setDrawColor(153, 170, 191);
      state.document.setFillColor(217, 217, 217);
      state.height = state.height + 4
      if (state.height < 53) {
        state.height = 53
      }
      state.document.roundedRect(9, state.cardInitialPosition, 198, state.height, 5, 5, "F")
      /*state.document.setDrawColor(153, 69, 0);
      state.document.setLineWidth(0.6)
      if (state.marginTop - state.cardInitialPosition < 66) {
        state.marginTop = state.cardInitialPosition + 45
      }
      state.document.roundedRect(58, state.cardInitialPosition - 1, 146, state.marginTop - state.cardInitialPosition, 5, 5, "S")
      state.marginTop = state.marginTop + 4*/
    },
    ADD_IMAGE_SECTION(state, payload) {
      state.document.setDrawColor(0);
      state.document.setFillColor(153, 69, 0);
      state.document.roundedRect(9, state.marginTop, 10, 45, 3, 3, "F");
      state.marginLeft = 23
      state.document.setFont("Helvetica", "");
      state.document.setFontSize(17);
      //state.document.setTextColor(0, 0, 0);
      state.document.setTextColor(255, 255, 255);
      let dimension = state.document.getTextDimensions(payload)
      state.document.text(payload, 9 + (dimension.h / 2) + 4, state.marginTop + (dimension.w / 2) + 22.5, {
        rotationDirection: 1,
        angle: 90
      })
      //state.document.addImage(state.icon, "PNG", 9, state.marginTop + 17.5, 10, 10, "icon", "NONE");
    },
    ADD_IMAGES_CONJUNTO(state, {
      payload,
      getters
    }) {
      state.document.setFontSize(12)
      state.document.setTextColor(58, 58, 58)
      state.document.setFont("Helvetica", "")

      state.document.setDrawColor(255, 255, 255);
      state.document.setLineWidth(4)
      let dimension = state.document.getTextDimensions('Referencia')
      let lines = 0
      payload.images.forEach((imagen, index) => {
        state.document.addImage(imagen.img, "JPEG", state.marginLeft, state.marginTop, 45, 45, payload.id + "-" + index, "NONE");
        state.document.roundedRect(state.marginLeft - 2, state.marginTop - 2, 49, 49, 6, 6, "S");

        state.document.text(imagen.name, state.marginLeft + 22.5, state.marginTop + 50, {
          maxWidth: 45,
          align: "center"
        })
        state.paragraph = {
          text: imagen.name,
          width: 45
        }
        if (getters.countLines > lines) {
          lines = getters.countLines
        }
        if ((216 - state.marginLeft) > 49) {
          state.marginLeft = state.marginLeft + 49
        } else {
          if(index+1===payload.images.length){
            state.marginTop = state.marginTop +  dimension.h * lines + 2

          }else{
            state.marginTop = state.marginTop + 49 + dimension.h * lines + 2}
          if (getters.freeSpace > 45) {
            state.marginLeft = state.marginLeft + 49
          } else {
            state.document.addPage()
            state.marginLeft = 23
            state.marginTop = 15
            state.page = state.page + 1
          }
        }
      })
      state.marginTop = state.marginTop + 49 + dimension.h * lines + 2
    },
    SET_CARD_POSITION(state, payload) {
      state.cardPosition = payload
    },
    ADD_SMALL_CARD(state) {
      state.document.setFillColor(217, 217, 217);
      if (state.cardPosition === 1) {
        state.document.roundedRect(9, state.marginTop, 97, 38, 5, 5, "F")
        state.cardInitialPosition = state.marginTop
      } else {
        state.marginTop = state.cardInitialPosition
        state.document.roundedRect(110, state.marginTop, 97, 38, 5, 5, "F")
      }
      state.marginTop = state.marginTop + 8
    },

    ADD_CARD_IMAGE(state, payload) {
      let left = 11
      if (state.cardPosition === 2) {
        left = 112
      }
      state.document.addImage(payload.img, "JPEG", left + 2, state.marginTop - 4, 30, 30, payload.name, "NONE");
      state.document.setDrawColor(217, 217, 217);
      state.document.setLineWidth(4)
      state.document.roundedRect(left, state.marginTop - 6, 34, 34, 5, 5, "S");
    },
  }
}