<template>
  <div
    class="navigation"
    :class="{'dots-2':dotNumber===2,'dots-3':dotNumber===3,'dots-4':dotNumber===4,'dots-5':dotNumber===5,'dots-6':dotNumber===6}"
  >
    <div
      v-for="n in (dotNumber*2-1)"
      :key="n"
      :class="{'dot':n %2 !==0,'conector':n %2 ===0,'selected':(n-(n-1)/2)<=value}"
    >
      <button v-if="n % 2!==0" @click="changeSelected(n-(n-1)/2)" :disabled="(n-(n-1)/2)>value">{{n-(n-1)/2}}</button>
      <div v-else></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Dot-Step",
  props: {
    dotNumber: {
      default() {
        return 3;
      }
    },
    value: {}
  },
  methods:{
      changeSelected(val){
          this.$emit('input',val)
      }
  }
};
</script>
<style lang="scss" scoped>
@import "../assets/sass/variables";
.navigation {
  padding: 0px 5%;
  display: grid;
  width: 100%;
  grid-template-rows: 50px;
  .dot {
    button {
      background-color: $theme-l1;
      border-radius: 50px;
      color: white;
      margin-block-end: 0px;
      margin-block-start: 0px;
      width: 100%;
      height: 100%;
      border: none;
      padding: 0px;
      vertical-align: middle;
      cursor: pointer;
    }
    button:disabled{
        cursor: not-allowed;
    }
  }
  .dot.selected {
    button {
      background-color: $theme-d2;
    }
  }
  .conector.selected {
    div {
      border-bottom: solid 1px $theme-d2;
    }
  }
  .conector {
    border: none;
    background-color: transparent;
    div {
      width: 100%;
      height: 25px;
      border-bottom: solid 1px $theme-l1;
    }
  }
}

.dots-2 {
  grid-template-columns: 50px auto 50px;
}
.dots-3 {
  grid-template-columns: 50px auto 50px auto 50px;
}
.dots-4 {
  grid-template-columns: 50px auto 50px auto 50px auto 50px;
}
.dots-5 {
  grid-template-columns: 50px auto 50px auto 50px auto 50px auto 50px;
}
</style>