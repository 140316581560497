<template>
  <div class="grapper">
    <div class="image">
      <h1 v-html="'Registra Nuevos Objetos'"></h1>
    </div>
    <h4
      class="center"
      v-html="'En esta secci&oacute;n podr&aacute;s registrar nuevos documentos en las colecciones del museo y editar aquellas que hayas ingresado previamente.'"
    ></h4>
    <div class="main-container">
      <h3 v-html="'Registrar nuevo'"></h3>
      <div class="card-container">
        <div class="card" @click="addModuleDisplay=true" style="cursor:pointer">
          <div class="card-img">
            <i class="fas fa-guitar"></i>
          </div>
          <div class="middle-icon">
            <div>
              <i class="material-icons">add</i>
            </div>
          </div>
          <div class="card-content">
            <h4>Agregar Objeto</h4>
          </div>
        </div>
        <div class="card" @click="addCGroupModuleDisplay=true" style="cursor:pointer">
          <div class="card-img">
            <i class="material-icons">category</i>
          </div>
          <div class="middle-icon">
            <div>
              <i class="material-icons">add</i>
            </div>
          </div>
          <div class="card-content">
            <h4>Agregar Conjunto</h4>
          </div>
        </div>
      </div>
        <h3 v-html="'&Uacute;ltimos objetos registrados'"></h3>

        <div class="card-container" v-if="lastFiveItems!==null">
          <router-link
            :to="'/item/'+item.id"
            class="card"
            v-for="item in lastFiveItems"
            :key="item.id"
          >
            <div class="card-img" :style="'background-image:url('+item.referenceImage.url+')'" v-if="item.referenceImage"></div>
            <div class="card-img" v-else>
              <i class="material-icons">insert_photo</i>
            </div>
            <div class="middle-icon" v-if="!item.content">
              <div :class="'right'">
                <i class="material-icons">add</i>
              </div>
            </div>
            <div class="card-content">
              <h4>{{item.name}}</h4>
              <p>{{item.id}}</p>
            </div>
          </router-link>
        </div>
        
    </div>
    <div class="modal" v-show="addModuleDisplay" @response="hideAddModule">
    <div class="top" @click="addModuleDisplay=false"></div>
    <div class="left" @click="addModuleDisplay=false"></div>
    <div class="right" @click="addModuleDisplay=false"></div>
    <div class="bottom" @click="addModuleDisplay=false"></div>
      <div class="box">
        <p class="icon">
          <i class="material-icons" @click="addModuleDisplay=false">close</i>
        </p>
        <div class="content">
          <Add />
        </div>
      </div>
    </div>

    <div class="modal" v-show="addGroupModuleDisplay" @response="addGroupModuleDisplay">
    <div class="top" @click="addGroupModuleDisplay=false"></div>
    <div class="left" @click="addGroupModuleDisplay=false"></div>
    <div class="right" @click="addGroupModuleDisplay=false"></div>
    <div class="bottom" @click="addGroupModuleDisplay=false"></div>
      <div class="box">
        <p class="icon">
          <i class="material-icons" @click="addGroupModuleDisplay=false">close</i>
        </p>
        <div class="content">
          <Add />
        </div>
      </div>
    </div>

    <Loading text="cargando" />
    <Message />
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import Message from "@/components/Message.vue";
import Loading from "@/components/Loading.vue";
import Add from "@/components/Add.vue";
//import firebase from "@/firebase";
//let db = firebase.firestore();
export default {
  name: "Insert-section",
  components: {
    Message,
    Loading,
    Add
  },
  data: function() {
    return {
      addModuleDisplay: false,
      addGroupModuleDisplay: false
    };
  },
  computed: {
    ...mapGetters(["lastFiveItems"])
  },
  watch: {
    lastFiveItems() {}
  },
  methods: {
    ...mapMutations([
      "SET_LOADING",
      "SET_SUCCESS_MESSAGE_PARAMETERS",
      "SET_CURRENT_OBJECT_ID"
    ]),
    hideAddModule() {
      this.addModuleDisplay = false;
    },
    hideAddGroupModule() {
      this.addGroupModuleDisplay = false;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../assets/sass/variables";
@import "../assets/sass/buttons";
@import "../assets/sass/cards";
div.grapper {
  width: 100%;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: auto;
  grid-template-rows: 70vh auto;
  h4 {
    color: $theme-d1;
    padding: 5%;
    text-align: center;
  }
  .image {
    background-image: linear-gradient(
        to bottom,
        transparent,
        rgba(0, 0, 0, 0.75)
      ),
      url("../assets/media/objects.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: $theme;
    i.material-icons {
      font-size: 48px;
      height: 100%;
      width: 100%;
      vertical-align: middle;
      color: white;
      text-align: center;
    }
    display: grid;
    h1 {
      padding-top: 50%;
      color: $theme-l4;
      vertical-align: middle;
      text-align: center;
      padding-right: 10%;
      padding-left: 10%;
      display: inline;
    }
  }
  div.main-container {
    margin: 0px 5%;
    background-color: white;
    border-radius: 15px;
    width: auto;
    padding: 25px;
  }
  .modal {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    margin: 0px;
    background-color: #22190880;
    z-index: 5;
    padding: 5%;
    div.box {
      top: 50%;
      left: 50%;
      width: 85%;
      border-radius: 10px;
      -webkit-box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.2),
        0 8px 20px 0 rgba(0, 0, 0, 0.19);
      -moz-box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.2),
        0 8px 20px 0 rgba(0, 0, 0, 0.19);
      box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.2),
        0 8px 20px 0 rgba(0, 0, 0, 0.19);
      margin-bottom: 10px;
      background-color: white;
      transform: translate(-50%, -50%) !important;
      position: absolute;
      padding: 10px 12px;
      max-height: 90vh;
      overflow-y: auto;
      p.icon {
        text-align: right;
        i {
          font-size: 24px;
          cursor: pointer;
        }
        i:hover {
          font-weight: bold;
        }
        padding: 0px;
        color: $text;
        margin-block-start: 0px;
        width: 100%;
        margin-block-end: 0px;
      }
      h2 {
        margin-block-start: 0px;
        text-align: center;
      }
      p {
        color: $text;
        text-align: center;
      }
      .content {
        margin: 0px 12px 8px 12px;
        width: auto;
      }
    }
  }
}
@media (min-width: 768px) {
  div.grapper {
    .modal {
      div.box {
        width: 70%;
      }
    }
  }
}
@media (min-width: 992px) {
  div.grapper {
    .modal {
      div.box {
        width: 50%;
      }
    }
  }
}
</style>
