<template>
  <div class="home">
    <div class="search">
      <div>
        <h1 v-html="'SERVICIOS'"></h1>
      </div>
      <h4 v-html="'Sector Campamento'"></h4>
    </div>
    <div class="section color1 split left">
      <div class="full-height"><div class="image" :style="{'background-image': 'url(https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F8%20servicios%2FSchool%20bell.jpg?alt=media&token=080d146b-57b6-4698-8524-06ec98d79555)'}"></div></div>
      <div>
        <div class="content">
          <h1 v-html="'ESCUELA'"></h1>
          <p v-html="'Los primeros a&ntilde;os funcion&oacute; hasta cuarto curso, ampliando posteriormente la formaci&oacute;n hasta sexto y llegando en los &uacute;ltimos a&ntilde;os hasta octavo b&aacute;sico. '"></p>
<p v-html="'Para terminar los &uacute;ltimos a&ntilde;os de estudios, los escolares se iban a estudiar a la localidad de Chile Chico. Los que se quedaban, empezaban a trabajar en labores menores como, por ejemplo, los “bolseros”, que cos&iacute;an los sacos de concentrado de mineral y los “campa&ntilde;istas”, que estaban a cargo de los animales de la empresa.  Algunos ni&ntilde;os eran elegidos por la empresa para ense&ntilde;arles labores industriales tales como: ayudante en el taller el&eacute;ctrico o en la maestranza; y en el caso de las ni&ntilde;as, las contrataban para realizar labores dom&eacute;sticas o para el cuidado de los ni&ntilde;os. '"></p>
<p v-html="'Adem&aacute;s de impartir las clases b&aacute;sicas del programa educativo, la escuela era el lugar donde se realizaban actividades extra-program&aacute;ticas, pudiendo los escolares participar del grupo de boy-scouts, de la brigada escolar, de talleres de teatro o de m&uacute;sica. Para fiestas patrias u otras fechas importantes, los ni&ntilde;os participaban en desfiles y bailes que se realizaban en la cancha de f&uacute;tbol, por ejemplo, la Cruz Roja adem&aacute;s de ense&ntilde;arles a hacer curaciones, realizaba desfiles donde los ni&ntilde;os vest&iacute;an delantal blanco y toca. Los ni&ntilde;os participaban tambi&eacute;n en campeonatos deportivos y paseos anuales en otras localidades de la regi&oacute;n. '"></p>
</div>
      </div>
    </div>
    
    <div class="section color2 split right">
      <div class="full">
        <div class="content img">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F8%20servicios%2FIMG_9485.jpg?alt=media&token=11f442f5-da9c-48ff-b649-bda7528f90f7&_gl=1*15hcjag*_ga*NjAzNTQwNTkxLjE2ODU3MjcyNzM.*_ga_CW55HF8NVT*MTY4NjUzMjA3OS4yMy4xLjE2ODY1MzI1NDUuMC4wLjA."
            alt=""
            class="right-40"
          />
          <h1 v-html="'POSTA'"></h1>
          <p v-html="'La posta de Puerto Cristal depend&iacute;a del Servicio de Salud de Ays&eacute;n. Era atendida por un practicante, que hac&iacute;a intervenciones b&aacute;sicas, hasta la llegada de las rondas mensuales donde se recib&iacute;a a los m&eacute;dicos, dentistas y otros especialistas. La posta contaba con la implementaci&oacute;n necesaria para la salud de los cristalinos, incluyendo un sill&oacute;n dental. '"></p>
<p v-html="'El practicante era elegido por el Servicio de Salud de Ays&eacute;n, sin embargo, la empresa era quien pagaba su remuneraci&oacute;n y se encargaba de acomodarlo en el campamento. En ciertas oportunidades, la empresa contrataba m&eacute;dicos de otras ciudades de Chile para que hicieran las rondas m&eacute;dicas en Puerto Cristal. '"></p>
<p v-html="'En la posta se llevaba el historial m&eacute;dico de todos los habitantes de Puerto Cristal, el cual era registrado en fichas m&eacute;dicas individuales, en fichas m&eacute;dicas familiares y en fichas m&eacute;dicas de lactante. Adicionalmente, se llevaba un registro de pacientes atendidos en Puerto Cristal, que no viv&iacute;an en el campamento, que llegaban de paso en los barcos de la empresa. '"></p>
<p v-html="'Las dependencias de la posta eran varias, pues contaba con una cl&iacute;nica, una farmacia, una sala para el programa de salud llamado “control del ni&ntilde;o sano” y una sala para el dentista. La sala de urgencias o cl&iacute;nica estaba conectada por radio con el resto del campamento para que, en caso de accidente, los veh&iacute;culos de la empresa trasladaran al practicante al lugar del accidente y pudiera atender a los afectados. Dependiendo de la gravedad del accidente o enfermedad, se derivaban los pacientes a Chile Chico. '"></p>
<p v-html="'La matrona era la encargada de llevar el “control del ni&ntilde;o sano”. En esos a&ntilde;os nacieron muchos ni&ntilde;os en el campamento, algunos en sus casas y otros en la posta, incluso hab&iacute;a mujeres de otras localidades del lago que viajaban a Puerto Cristal para dar a luz, ya que era el centro de asistencia m&aacute;s cercano. En el caso de embarazos de alto riesgo, las pacientes se derivaban v&iacute;a lacustre para ser atendidas en el hospital de Chile Chico. '"></p>

        </div>
      </div>
    </div>

    <div class="section split left">
      <div class="full-height"><div class="image" :style="{'background-image': 'url(https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F8%20servicios%2FP2070114.jpg?alt=media&token=a6b90e3e-2609-413e-8e05-8b60a1aee920)'}"></div></div>
      <div>
        <div class="content">
          <h1 v-html="'RET&Eacute;N DE CARABINEROS'"></h1>
          <p v-html="'Para velar por el bienestar de la poblaci&oacute;n m&aacute;s aislada, los carabineros deb&iacute;an de hacer rondas y visitar a los pobladores desplaz&aacute;ndose a caballo o traslad&aacute;ndose en las embarcaciones de la empresa. '"></p>
<p v-html="'El ret&eacute;n depend&iacute;a de Chile Chico y contaba con un sargento y cuatro cabos. Generalmente los carabineros viv&iacute;an en el mismo ret&eacute;n, pero seg&uacute;n las circunstancias tomaban pensi&oacute;n en los casinos o acced&iacute;an a una vivienda junto a su familia. '"></p>
<p v-html="'El ret&eacute;n de carabineros se ubicaba en un edificio de ladrillo de dos plantas. Una parte de la planta inferior era destinada a la comisar&iacute;a y a los calabozos, con entrada independiente para el p&uacute;blico, mientras que la otra albergaba las dependencias de uso exclusivo de los carabineros dentro de las que se encontraban: cocina, ba&ntilde;o y vestuario. En el segundo piso se ubicaban los dormitorios. Detr&aacute;s del ret&eacute;n se ubicaba un galp&oacute;n y bebederos destinados a las caballerizas de la polic&iacute;a montada. '"></p>

        </div>
      </div>
    </div>

    <div class="section color1 split right">
      <div class="full">
        <div class="content img">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F8%20servicios%2FVIVIENDAS%20OBREROS%20-21.jpg?alt=media&token=3fd8f9b3-7074-41e9-816e-c686653aeeb2"
            alt=""
            class="left-60"
          />
          <h1 v-html="'COMPA&Ntilde;&Iacute;A DE BOMBEROS'"></h1>
          <p v-html="'La Primera Brigada de Bomberos de Puerto Cristal pertenec&iacute;a a la Tercera Compa&ntilde;&iacute;a de Bomberos de Chile Chico. '"></p>
<p v-html="'Para combatir los incendios se decidi&oacute; entre varios cristalinos formar la Brigada de Bomberos, llegando a tener hasta cincuenta voluntarios. Para poder comprar la vestimenta, las mangueras y las escaleras, se hac&iacute;an actos ben&eacute;ficos en Puerto Cristal y en otras localidades. Adem&aacute;s la empresa minera aportaba con equipamiento que pudiese necesitar la compa&ntilde;&iacute;a. Alrededor de todo el campamento se instal&oacute; una red de grifos, pues los bomberos nunca contaron con un cami&oacute;n bomba y debieron apagar los incendios con la manguera y la escalera al hombro. '"></p>
<p v-html="'Los ejercicios f&iacute;sicos los hac&iacute;an al aire libre en la cancha de f&uacute;tbol. '"></p>

        </div>
      </div>
      
    </div>
    <div class="section split right">
      <div class="full">
        <div class="content img">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F8%20servicios%2FDSC06799.jpg?alt=media&token=b96a3a19-d348-4290-9470-c3546aad040d"
            alt=""
            class="right-60"
          />
          <h1 v-html="'TEL&Eacute;FONO'"></h1>
          <p v-html="'Respecto a la comunicaci&oacute;n, exist&iacute;a en Puerto Cristal un tel&eacute;fono p&uacute;blico con el que la poblaci&oacute;n pod&iacute;a tomar contacto con sus familiares que viv&iacute;an fuera del campamento. '"></p>
<p v-html="'En el centro de llamados trabajaba una telefonista a cargo de gestionar las llamadas agendadas por los cristalinos, adem&aacute;s de informales cuando ten&iacute;an una llamada urgente o de emergencia. '"></p>
<p v-html="'Al interior del centro de llamados se encontraba una peque&ntilde;a sala de espera y una cabina de tel&eacute;fono. Se llevaba el registro de todas las llamadas con la informaci&oacute;n de qui&eacute;n emit&iacute;a y recib&iacute;a la llamada, el tiempo que duraba y el costo que ten&iacute;a. '"></p>
<p v-html="'Adicionalmente, la empresa ten&iacute;a una l&iacute;nea de tel&eacute;fono interna para la comunicaci&oacute;n entre las distintas secciones del sector industrial que eran la mina, el fonteche, la planta de concentrado, la planta hidro&eacute;lectrica, el laboratorio y las bodegas portuarias. Se comunicaban con un tel&eacute;fono manual que pose&iacute;a una manivela que al ser girada produc&iacute;a un “toque”. Cada secci&oacute;n de la empresa ten&iacute;a asignado un n&uacute;mero de “toques” que iban del uno al seis. '"></p>
</div>
      </div>
    </div>
    <div class="section color2 split left">
      <div class="full-height"><div class="image" :style="{'background-image': 'url(https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F8%20servicios%2FIMG_9472.jpg?alt=media&token=6cce39a5-a816-47f3-8ef6-0626c6f50a3c)'}"></div></div>
      <div>
        <div class="content">
          <h1 v-html="'RADIO Y TELEVIS&Oacute;N'"></h1>
          <p v-html="'La radio se llamaba Radio MADIPRO, que significaba Madre de la Divina Providencia. Se encontraba ubicada a un costado de la Iglesia Cat&oacute;lica y fue fundada por el Padre Ronchi, reconocido sacerdote de la Regi&oacute;n de Ays&eacute;n que contribuy&oacute;, durante esos a&ntilde;os, al desarrollo de las localidades m&aacute;s aisladas. '"></p>
<p v-html="'El Padre Ronchi llev&oacute; la radio MADIPRO a gran parte de las localidades de Ays&eacute;n, conectando los poblados m&aacute;s aislados e incomunicados. A trav&eacute;s de esta se enviaban mensajes o informaci&oacute;n, como, por ejemplo, un aviso de fallecimiento de alg&uacute;n ser querido o la visita de un familiar. Adem&aacute;s se emit&iacute;an avisos para la comunidad de cristalinos, como actividades importantes de la empresa o citaciones a reuniones de los socios de los Clubes Deportivos y Centros de Madres. En la radio se escuchaban programas nacionales que se llevaban grabados desde la zona central de Chile y programas internacionales como “Las Voces de Am&eacute;rica” (“Voice of America”), que se produc&iacute;an en Miami. '"></p>
<p v-html="'Adem&aacute;s los cristalinos disfrutaron de la televisi&oacute;n directa y de la proyecci&oacute;n de pel&iacute;culas de cine. Puerto Cristal fue el primer pueblo de la regi&oacute;n en tener se&ntilde;al de televisi&oacute;n directa desde Santiago, mientras que en el resto de Ays&eacute;n los programas eran enviados en un formato f&iacute;sico y eran reproducidos d&iacute;as despu&eacute;s. El cine se encontraba ubicado en la sede del sindicato y se proyectaban pel&iacute;culas de 16mm tra&iacute;das por los barcos de la empresa. '"></p>
</div>
      </div>
    </div>
    <div class="section color1 split right">
      <div>
        <div class="content">
          <h1 v-html="'OFICINA DE BIENESTAR'"></h1>
          <p v-html="'La empresa pon&iacute;a a disposici&oacute;n de los trabajadores una oficina de bienestar que se encargaba de mantener en buen estado la infraestructura de las viviendas. Esta oficina encomendaba los trabajos a otras unidades de la empresa como, la maestranza, el taller el&eacute;ctrico o la muebler&iacute;a. '"></p>
<p v-html="'Cuando alg&uacute;n trabajador ten&iacute;a alg&uacute;n problema en su vivienda, como problemas de gasfiter&iacute;a, problemas con el ca&ntilde;o de la cocina a le&ntilde;a o requerimientos de instalaci&oacute;n de aparatos el&eacute;ctricos, se acercaba a la oficina de bienestar a exponer su necesidad, lo que era derivado a la unidad de trabajo correspondiente. '"></p>
</div>
      </div>
      <div class="full-height"><div class="image" :style="{'background-image': 'url(https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F8%20servicios%2FDSC_0579.jpg?alt=media&token=2bfd0ac6-cc8b-4159-9bcd-eafd3144d2b5)'}"></div></div>
      
    </div>
    <div class="section">
          <h1 v-html="'Fotograf&iacute;as'"></h1>
          <div class="gallery">
            <div
              class="item"
              v-for="(item, index) in images"
              :key="index"
              :style="'background-image:url(' + item.url + ')'"
            >
              <div class="hover" @click="openGallery(index)">
                <div>
                  <i class="material-icons">fullscreen</i>
                  <h5>{{ item.name }}</h5>
                </div>
              </div>
            </div>
        
      </div>
    </div>
    <Loading text="cargando" />
    <Message />
    <Gallery/>
  </div>
</template>
<script>
// @ is an alias to /src
import Loading from "@/components/Loading.vue";
import Message from "@/components/Message.vue";

import Gallery from "@/components/Gallery.vue";

import { mapMutations } from "vuex";
export default {
  name: "Home-page",
  components: {
    Loading,
    Message,
    Gallery
  },
  data: function () {
    return {
      
      images: [{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F8%20servicios%2FLastScan%2082.jpg?alt=media&token=8b9470bf-83f7-40e6-b7e9-7aa3acceb06d',name:'Foto Antigua',position:0},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F8%20servicios%2FLastScan%2083.jpg?alt=media&token=2058fb68-06e7-4c7d-bc67-a82de66c3157',name:'Foto Antigua',position:1},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F8%20servicios%2FLastScan%20RT.jpg?alt=media&token=904bf496-d5cf-4b22-982f-e2b4a1accc15',name:'Foto Antigua',position:2},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F8%20servicios%2Fsi%20LastScan%2043.jpg?alt=media&token=8242163e-b40b-4dbe-b529-8de2ff8b6969',name:'Foto Antigua',position:3},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F8%20servicios%2Fsi%20LastScan%2089.jpg?alt=media&token=a28b40bf-7e3e-4c34-90e7-ea7d0c85329a',name:'Foto Antigua',position:4},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F8%20servicios%2Fsi%20LastScan%2092.jpg?alt=media&token=2fd0532e-7d32-4351-b8e1-d1626413c916',name:'Foto Antigua',position:5}
],
    };
  },
  created() {
    this.SET_LOADING(false);
  },
  methods: {
    ...mapMutations([
      "SET_LOADING",
      "SET_GALLERY",
      "SET_GALLERY_INDEX",
      "SET_GALLERY_ITEMS",
    ]),
    openGallery(payload) {
      let pos = this.images.findIndex((item) => item.position === payload);
      let el = this.images[pos];
      let arr = this.images;
      arr.splice(pos, 1);
      arr.splice(0, 0, el);
      this.SET_GALLERY_ITEMS(arr);
      this.SET_GALLERY_INDEX(payload);
      this.SET_GALLERY(true);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/sass/variables";
@import "../../assets/sass/buttons";
@import "../../assets/sass/cards";
@import "../../assets/sass/modal";

.home {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 70vh auto;
  width: 100vw;
  div.autocomplete-container {
    padding: 25px 13em 45px 3em;
    div {
      display: grid;
      grid-template-columns: auto 60px;
      grid-template-rows: 50px;
      border-radius: 50px;
      background-color: white;
      padding: 0px;
      overflow: visible;
      .radius-left {
        border-bottom-left-radius: 50px;
        border-top-left-radius: 50px;
      }
      .radius-right {
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
      }

      button {
        margin-block-end: 0px;
        margin-block-start: 0px;
        background-color: $theme;
        border: none;
        cursor: pointer;
        i.material-icons {
          vertical-align: middle;
          font-size: 35px;
          color: white;
        }
      }
      button:disabled {
        opacity: 0.5;
        cursor: no-drop;
      }
    }
  }
  .search {
    background-image: linear-gradient(
        to bottom,
        transparent,
        rgba(0, 0, 0, 0.75)
      ),
      url("https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F8%20servicios%2FLastScan%2082.jpg?alt=media&token=8b9470bf-83f7-40e6-b7e9-7aa3acceb06d&_gl=1*1obwb7d*_ga*NjAzNTQwNTkxLjE2ODU3MjcyNzM.*_ga_CW55HF8NVT*MTY4NjUzMjA3OS4yMy4xLjE2ODY1MzIxOTguMC4wLjA.");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: grid;
    grid-template-rows: calc(100% - 220px) 50px 120px 50px;
    h1,
    h4 {
      position: relative;
      text-align: center;
      padding-left: 10%;
      padding-right: 10%;
      margin-block-end: 0px;
      margin-block-start: 0px;
    }
    div.search-options {
      align-content: center;
      input[type="radio"] {
        margin-block-start: 5px;
        margin-block-end: 5px;
        height: 20px;
        width: 20px;
        margin-right: 5px;
        display: inline-block;
        vertical-align: middle;
      }
      label {
        color: white;
        display: inline-block;
        margin-right: 30px;
        margin-block-end: 0px;
        margin-block-start: 0px;
        vertical-align: middle;
        margin-top: 0px;
        line-height: 20px;
      }
    }
    div {
      padding-left: 10%;
      padding-right: 10%;
      position: relative;
      h1 {
        text-align: center;
        display: block;
        width: 100%;
        bottom: 0%;
        left: 50%;
        margin-bottom: 0px;
        position: absolute;
        transform: translateX(-50%);
      }
    }
    h1 {
      font-size: 3em;
      font-weight: 700;
      color: $theme-l4;
      vertical-align: text-bottom;
    }
    h4 {
      font-size: 2em;
      color: wheat;
    }
  }
  .section {
    position: relative;
    padding: 0.5em 2em;
    display: grid;
    grid-template-rows: auto;
    gap: 1em;
    
      
    h1 {
            font-size: 3em;
            font-weight: 700;
            margin-block-start: 0;
            margin-block-end: 0;
          }
          p {
            font-size: 1.1em;
            font-weight: 300;
            text-align: justify;
          }
    &.color1 {
      background-color: $blue-grey;
      h1,
      h2,
      h3,
      h4,
      h5 {
        color: $grey-2;
      }
      p,
      li {
        color: $grey-1;
      }
    }
    &.color2 {
      background-color: $grey-1;
      h1,
      h2,
      h3,
      h4,
      h5 {
        color: $theme-d1;
      }
      p,
      li {
        color: $theme-d3;
      }
    }

    &.color3 {
      background-color: $theme-l4;
      h1,
      h2,
      h3,
      h4,
      h5 {
        color: $theme-d2;
      }
      p,
      li {
        color: $blue-grey-dark;
      }
    }
    &.split,
    &.full {
      height: 100%;
      div {
        &.full {
          grid-column-start: 1;
          grid-column-end: 3;
        }
        position: relative;
        height: 100% !important;
        display: block;
        .title,
        .content {
          width: 100%;
          display: block;
          height: fit-content;
          &.img {
            img.left-40,img.left-60 {
              border-radius: 1em;
              width: 100%;
              margin-bottom: 0.8em;
            }
            img.right-40,img.right-60 {
              border-radius: 1em;
              width: 100%;
              margin-bottom: 0.8em;
            }
          }
          &.multy {
            display: grid;
          }
          h1 {
            font-size: 3em;
            font-weight: 700;
            margin-block-start: 0;
            margin-block-end: 0;
          }
          p {
            font-size: 1.1em;
            font-weight: 300;
            text-align: justify;
          }
          
        }
        .image {
          border-radius: 1em;
          width: 100%;
          padding-top: 100%;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          &#img1 {
            background-image: url("https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F1%20mina%2FLastScan%20TYUI.jpg?alt=media&token=a22f582f-788c-48b4-b1b2-4a826a056bd4&_gl=1*anfvst*_ga*NjAzNTQwNTkxLjE2ODU3MjcyNzM.*_ga_CW55HF8NVT*MTY4NTg0MDU5My41LjEuMTY4NTg0MDYwMS4wLjAuMA..");
          }
          &#img2 {
            background-image: url("https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F1%20mina%2FMINA%20-40.jpg?alt=media&token=ade126b1-af45-4eb7-a212-3cf1e6d13b9f&_gl=1*lf7w9i*_ga*NjAzNTQwNTkxLjE2ODU3MjcyNzM.*_ga_CW55HF8NVT*MTY4NTkxNTczMy43LjEuMTY4NTkxNjU0OS4wLjAuMA..");
          }
          &#img3 {
            background-image: url("https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F1%20mina%2FLastScan%20106.jpg?alt=media&token=996f6d57-a11f-48b8-a920-405597c273dd&_gl=1*1gbuw9u*_ga*NjAzNTQwNTkxLjE2ODU3MjcyNzM.*_ga_CW55HF8NVT*MTY4NTkxNTczMy43LjEuMTY4NTkxNjgwMC4wLjAuMA..");
          }
        }
      }
    }
    .gallery {
            display: grid;
            grid-gap: 0.8em;
            grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
            position: relative;
            padding-bottom: 2em;
            .item {
              padding-top: 100%;
              background-size: cover;
              background-repeat: no-repeat;
              background-position: center;
              position: relative;
              border-radius: 5px;
              overflow: hidden;
              .hover {
                height: 100%;
                width: 100%;
                position: absolute;
                background-color: rgba($color: #000000, $alpha: 0.65);
                top: 0%;
                margin-top: 0%;
                opacity: 0;
                div {
                  width: 100%;
                  margin-top: calc(50% - 32px);
                  i.material-icons {
                    font-size: 35px;
                    color: whitesmoke;
                    text-align: center;
                    width: 100%;
                    vertical-align: middle;
                  }
                  h5 {
                    color: whitesmoke;
                    text-align: center;
                  }
                }
              }
              .hover:hover {
                cursor: pointer;
                opacity: 1;
              }
            }
          }
  }
}
@media (min-width: 768px) {
  .home {
    grid-template-rows: 75vh auto;
    .search {
      .video-container {
        video {
          min-height: 100%;
          min-width: 100%;
        }
      }
      .content {
        margin-left: 5em !important;
      }
    }
    .section {
      padding: 3em 5em;
      gap: 0.5em 2em;
      &.split {
        &.left {
          grid-template-columns: 1.5fr 2.5fr;
        }
        &.right {
          grid-template-columns: 2.5fr 1.5fr;
        }
        .content {
          &.img {
            img.left-40{
              border-radius: 1em;
              float: left;
              max-width: 40%;
              margin-right: 2em;
            }
            img.left-60{
              border-radius: 1em;
              float: left;
              max-width: 60%;
              margin-right: 2em;
            }
            img.right-60 {
              border-radius: 1em;
              float: right;
              max-width: 60%;
              margin-left: 2em;
            }
            img.right-40 {
              border-radius: 1em;
              float: right;
              max-width: 40%;
              margin-left: 2em;
            }
          }
        }
      }
    }
  }
}
@media (min-width: 992px) {
  .home {
    grid-template-rows: 75vh auto;
    .search {
      .video-container {
        video {
          min-height: 100%;
          min-width: 100%;
        }
      }
      .content {
        margin-left: 11em !important;
      }
    }
    .section {
      padding: 3em 13em;
      &.split {
        &.left {
          grid-template-columns: 1.5fr 2.5fr;
        }
        &.right {
          grid-template-columns: 2.5fr 1.5fr;
        }
      }
    }
  }
}
</style>
