<template>
  <div>
    <div class="info-card">
      <div class="image">
        <div
          class="img"
          v-if="documento.imagen !== ''"
          :style="{ 'background-image': 'url(' + documento.imagen + ')' }"
        ></div>
        <i class="material-icons" v-else>image</i>
      </div>
      <div class="header">
        <h1>Ficha del Documento</h1>
      </div>
      <div class="id-card">
        <h3 v-html="documento.publicData[0].value"></h3>
        <p>
          <strong v-html="'C&oacute;digo: '"></strong>
          {{ documento.id }}
        </p>
        <p class="inline">
          <strong v-html="'Fondo: '"></strong>
        </p>
        <p class="inline">
          {{ getFondoName(documento.Fondo) }}
        </p>
        <br />
        <p class="inline">
          <strong v-html="'Serie: '"></strong>
        </p>
        <p class="inline">
          {{ getSerieName({ fondo: documento.Fondo, serie: documento.Serie }) }}
        </p>
        <br />
      </div>
    </div>
    <div class="container">
      <div class="edit-options" v-show="user !== null">
        <div class="left">
          <p>Publicado:</p>
          <div class="onoffswitch">
            <input
              type="checkbox"
              name="onoffswitch"
              class="onoffswitch-checkbox"
              id="myonoffswitch"
              tabindex="0"
              v-model="documento.published"
              @click="togglePublished"
            />
            <label class="onoffswitch-label" for="myonoffswitch">
              <span class="onoffswitch-inner"></span>
              <span class="onoffswitch-switch"></span>
            </label>
          </div>
        </div>
        <div class="right">
          <p
            class="icon-container"
            :style="{ width: '100%', textAlign: 'right' }"
          >
            <button
              class="icon-to-label-btn"
              :class="{ selected: editMode }"
              @click="editMode = !editMode"
            >
              <div class="inner">
                <p>editar</p>
                <i class="material-icons">edit</i>
              </div>
            </button>
          </p>
        </div>
      </div>
      <!--MOSTRAR DOCUMENTO -->
      <div class="section" v-if="!editMode">
        <div
          class="theme"
          :class="{
            'right-narrow': documento.link !== null,
            'half-l':
              documento.link === null &&
              (visibleBlocks === 0 || visibleBlocks === 2),
            'left-narrow': documento.link === null && visibleBlocks === 1,
          }"
        >
          <h4 v-html="'Identificaci&oacute;n'"></h4>
          <div>
            <li>
              <strong>{{ documento.publicData[0].field }}:</strong>
              {{ documento.publicData[0].value }}
            </li>
            <li>
              <strong v-html="'Descripci&oacute;n del Contenido:'"></strong>
              {{ documento.publicData[6].value }}
            </li>
            <li>
              <strong>{{ documento.publicData[5].field }}:</strong>
              {{ documento.publicData[5].value }}
            </li>
            <li>
              <strong>{{ documento.publicData[1].field }}:</strong>
              {{ documento.publicData[1].value }}
            </li>
          </div>
        </div>
        <div
          class="theme-light"
          :class="{
            'right-narrow': documento.link !== null,
            'half-r':
              documento.link === null &&
              (visibleBlocks === 0 || visibleBlocks === 2),
            'middle-narrow': documento.link === null && visibleBlocks === 1,
          }"
        >
          <h4 v-html="'Caracter&iacute;sticas'"></h4>
          <div>
            <li>
              <strong>{{ documento.publicData[3].field }}:</strong>
              {{ documento.publicData[3].value }}
            </li>
            <li>
              <strong>{{ documento.publicData[4].field }}:</strong>
              {{ documento.publicData[4].value }}
            </li>
            <li v-if="(documento.conjunto===null||documento.conjunto===undefined) &&documento.publicData[2].value==='Unidad Documental Compuesta'">
              <strong>{{ documento.publicData[2].field }}:</strong>
              {{ documento.publicData[2].value }} - Forma parte de un conjunto
            </li>
            <li v-else>
              <strong>{{ documento.publicData[2].field }}:</strong>
              {{ documento.publicData[2].value }}
            </li>
            <li>
              <strong>{{ documento.publicData[9].field }}:</strong>
              {{ documento.publicData[9].value }}
            </li>
            <li>
              <strong>{{ documento.publicData[10].field }}:</strong>
              {{ documento.publicData[10].value }}
            </li>
          </div>
        </div>
        <div
          class="dark-gray"
          :class="{
            'right-narrow': (documento.link !== null)||(documento.link === null && visibleBlocks === 1),
            'half-l':
              documento.link === null &&
              (visibleBlocks === 0 || visibleBlocks === 2)
          }"
          v-if="
            documento.publicData[7].value.length > 0 &&
            documento.publicData[7].value.length > 0
          "
        >
          <h4 v-html="'Referencias'"></h4>
          <div>
            <li v-if="documento.publicData[7].value.length > 0">
              <strong>{{ documento.publicData[7].field }}:</strong>
              <span
                v-for="(item, index) in documento.publicData[7].value"
                :key="index"
                > #{{ item }}
              </span>
            </li>
            <li v-if="documento.publicData[8].value.length > 0">
              <strong>{{ documento.publicData[8].field }}:</strong>
              <span
                v-for="(item, index) in documento.publicData[8].value"
                :key="index"
                > #{{ item }}
              </span>
            </li>
          </div>
        </div>
        <div
          class="orange"
          :class="{
            'right-narrow': documento.link !== null||(documento.link === null && visibleBlocks === 1),
            'half-r':
              documento.link === null &&
              (visibleBlocks === 0 || visibleBlocks === 2)
          }"
          v-if="user && documento.publicData[11].value !== '-'"
        >
          <h4 v-html="'Notas'"></h4>
          <p v-html="documento.publicData[11].value"></p>
        </div>
        <!-- <div class="wide white" v-if="user">
          <h4 v-html="'Gesti&oacute;n del Objeto'"></h4>
        </div>
        <div
          class="left-wide white pdf-view"
          id="pdf-view"
          v-if="documento.link !== '' && !editMode"
        ></div> -->
        <iframe
          class="left-wide white pdf"
          :src="documento.link"
          style="display: flex; padding: 0%"
          width="100%"
          height="100%"
          frameborder="0"
          scrolling="no"
          v-if="documento.link !== null && !editMode"
        ></iframe>
        <div class="wide white" v-if="documento.conjunto!==null&&documento.conjunto!==undefined&&documento.conjunto.length > 0">
          <h4 v-html="'Notas'"></h4>
          <p
            v-html="
              'Este documento est&aacute; compuesto por las siguientes unidades:'
            "
          ></p>
          <div class="card-container-h">
            <router-link :to="'/archive/'+item.id" class="card-h" v-for="(item,index) in documento.conjunto" :key="index">
              <div class="img" :style="{ 'background-image': 'url(' + item.imagen + ')' }"></div>
              <div class="data">
                <h5>{{item.publicData[0].value}}</h5>
                <p>{{ item.id }}</p>
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <!--EDITAR DOCUMENTO -->
      <div class="section" v-else>
        <div class="wide white edit">
          <h4 v-html="'Datos de uso p&uacute;blico'"></h4>
          <ul class="update">
            <li
              class="update"
              v-for="(value, key) in documento.publicData"
              :key="key"
              v-show="value.position !== 3"
            >
              <label>{{ value.field }}: </label>
              <input
                type="text"
                :name="'att' + value.position"
                v-model="value.value"
                v-if="
                  value.position === 1 ||
                  value.position === 2 ||
                  value.position === 7 ||
                  value.position === 4 ||
                  value.position === 5 ||
                  value.position === 6 ||
                  value.position === 10 ||
                  value.position === 11
                "
              />
              <div v-else-if="value.position === 8">
                <div class="input-tag">
                  <input
                    type="text"
                    :name="'att' + value.position"
                    v-model="nuevaPersona"
                  />
                  <button
                    class="icon-btn"
                    title="Agregar"
                    :disabled="nuevaPersona === '' && nuevaPersona.length < 4"
                    @click="addNuevaPersona"
                  >
                    <i class="material-icons">add</i>
                  </button>
                </div>
                <div
                  class="tag"
                  v-for="(item, index) in value.value"
                  :key="index"
                >
                  <p>
                    {{ item }}
                    <i
                      class="material-icons"
                      @click="value.value.splice(index, 1)"
                      >close</i
                    >
                  </p>
                </div>
              </div>
              <div v-else-if="value.position === 9">
                <div class="input-tag">
                  <input
                    type="text"
                    :name="'att' + value.position"
                    v-model="nuevaInstitucion"
                  />
                  <button
                    class="icon-btn"
                    title="Agregar"
                    :disabled="
                      nuevaInstitucion === '' && nuevaInstitucion.length < 4
                    "
                    @click="addNuevaInstitucion"
                  >
                    <i class="material-icons">add</i>
                  </button>
                </div>
                <div
                  class="tag"
                  v-for="(item, index) in value.value"
                  :key="index"
                >
                  <p>
                    {{ item }}
                    <i
                      class="material-icons"
                      @click="value.value.splice(index, 1)"
                      >close</i
                    >
                  </p>
                </div>
              </div>
              <textarea
                rows="5"
                :name="'att' + value.position"
                v-model="value.value"
                v-else-if="value.position === 12"
              ></textarea>
            </li>
          </ul>
          <p class="btn-center">
            <button class="ok-btn" @click="updatePublicDataOfDocumento">
              Guardar
            </button>
            <button
              class="cancel-btn"
              @click="updateSerie = { visible: false, serie: {} }"
            >
              Cancelar
            </button>
          </p>
        </div>
        <div class="wide white">
          <h4 v-html="'Datos de uso privado'"></h4>
          <ul>
            <li
              class="update"
              v-for="(value, key) in documento.privateData"
              :key="key"
            >
              <label>{{ value.field }}: </label>
              <input
                type="text"
                :name="'att' + value.position"
                v-model="value.value"
              />
            </li>
          </ul>
          <p class="btn-center">
            <button class="ok-btn" @click="updatePrivateDataOfDocumento">
              Guardar
            </button>
            <button
              class="cancel-btn"
              @click="updateSerie = { visible: false, serie: {} }"
            >
              Cancelar
            </button>
          </p>
        </div>
        <div class="wide white">
          <h4 v-html="'Documentos cargados'"></h4>
          <SimpleBar>
            <table>
              <tr>
                <th>Nombre</th>
                <th>Cargado</th>
                <th>Tipo de Archivo</th>
                <th>Contiene Datos Sensibles</th>
                <th>Opciones</th>
              </tr>
              <tr>
                <td class="left">Imagen de Referencia</td>
                <td v-if="documento.imagen !== ''">
                  <i class="material-icons">done</i> Si
                </td>
                <td v-else><i class="material-icons">clear</i> No</td>
                <td v-if="documento.imagen !== ''">
                  <i class="material-icons">image</i> Imagen
                </td>
                <td v-else>N/A</td>
                <td>N/A</td>
                <td v-if="documento.imagen !== ''">
                  <div class="action-btn">
                    <div>
                      <button
                        class="icon-btn fill-on-hover margin-right"
                        @click="displayImageModule = true"
                      >
                        <i class="material-icons">visibility</i>
                        <span class="tooltiptext">Visualizar</span>
                      </button>
                      <button
                        class="icon-btn fill-on-hover"
                        @click="deleteImage"
                      >
                        <i class="material-icons">delete</i>
                        <span class="tooltiptext">Eliminar</span>
                      </button>
                    </div>
                  </div>
                </td>
                <td v-else>
                  <button
                    class="icon-btn fill-on-hover"
                    @click="addImageModule = true"
                  >
                    <i class="material-icons">upload</i>
                    <span class="tooltiptext">Subir Imagen</span>
                  </button>
                </td>
              </tr>
              <tr>
                <td class="left border-bottom">Documento Digitalizado</td>
                <td class="border-bottom" v-if="documento.link !== ''">
                  <i class="material-icons">done</i> Si
                </td>
                <td class="border-bottom" v-else>
                  <i class="material-icons">clear</i> No
                </td>
                <td class="border-bottom" v-if="documento.link !== ''">
                  <i class="material-icons">description</i> Archivo PDF
                </td>
                <td class="border-bottom" v-else>N/A</td>
                <td
                  class="border-bottom"
                  v-if="documento.link !== '' && documento.link.restricted"
                >
                  <i class="material-icons">lock</i> Si
                </td>
                <td
                  class="border-bottom"
                  v-else-if="
                    documento.link !== '' && !documento.link.restricted
                  "
                >
                  <i class="material-icons">lock_open</i> No
                </td>
                <td class="border-bottom" v-else>N/A</td>

                <td class="border-bottom" v-if="documento.link !== ''">
                  <div class="action-btn">
                    <div>
                      <button class="icon-btn fill-on-hover margin-right">
                        <i class="material-icons">edit</i>
                        <span class="tooltiptext">Editar</span>
                      </button>
                      <button
                        class="icon-btn fill-on-hover margin-right"
                        @click="displayPDFModule = true"
                      >
                        <i class="material-icons">visibility</i>
                        <span class="tooltiptext">Visualizar</span>
                      </button>
                      <button class="icon-btn fill-on-hover">
                        <i class="material-icons">delete</i>
                        <span class="tooltiptext">Eliminar</span>
                      </button>
                    </div>
                  </div>
                </td>
                <td class="border-bottom" v-else>
                  <button
                    class="icon-btn fill-on-hover"
                    @click="addPDFModule = true"
                  >
                    <i class="material-icons">upload</i>
                    <span class="tooltiptext">Subir Imagen</span>
                  </button>
                </td>
              </tr>
            </table>
          </SimpleBar>
        </div>
      </div>
    </div>
    <Message />
    <Loading text="Cargando..." />
    <Gallery />

    <div class="modal" v-if="addImageModule">
      <div class="top" @click="addImageModule = false"></div>
      <div class="left" @click="addImageModule = false"></div>
      <div class="right" @click="addImageModule = false"></div>
      <div class="bottom" @click="addImageModule = false"></div>
      <SimpleBar class="box card">
        <div
          v-if="image.preview !== ''"
          class="image"
          :style="'background-image:url(' + image.preview + ')'"
        >
          <p class="icon">
            <i class="material-icons" @click="addImageModule = false">close</i>
          </p>
        </div>
        <div class="content padding">
          <h2 v-html="'Agregar Imagen de Referencia'"></h2>
        </div>
        <div class="padding">
          <label v-html="'Seleccionar archivo'" class="long"></label>
          <div class="file-loader">
            <input type="text" disabled v-model="image.name" />
            <input
              class="file"
              type="file"
              ref="fileInput"
              accept="image/*"
              @change="onFilePicked"
            />
            <button class="icon-btn" @click="onPickFile">
              <i class="material-icons">cloud_upload</i>
            </button>
          </div>

          <p class="btn-center">
            <button
              class="ok-btn"
              @click="
                uploadImage();
                addImageModule = false;
              "
            >
              Guardar
            </button>
            <button
              class="cancel-btn"
              @click="
                image = { name: '', preview: '', file: null };
                addImageModule = false;
              "
            >
              Cancelar
            </button>
          </p>
        </div>
      </SimpleBar>
    </div>
    <div class="modal" v-if="displayImageModule">
      <div class="top" @click="displayImageModule = false"></div>
      <div class="left" @click="displayImageModule = false"></div>
      <div class="right" @click="displayImageModule = false"></div>
      <div class="bottom" @click="displayImageModule = false"></div>
      <SimpleBar class="box card">
        <div
          v-if="documento.imagen !== ''"
          class="image"
          :style="'background-image:url(' + documento.imagen.link + ')'"
        >
          <p class="icon">
            <i class="material-icons" @click="displayImageModule = false"
              >close</i
            >
          </p>
        </div>
        <div class="content padding">
          <h2 v-html="'Imagen de Referencia'"></h2>
        </div>
        <div class="padding">
          <p class="btn-center">
            <button
              class="ok-btn"
              @click="
                uploadImage();
                addImageModule = false;
              "
            >
              Eliminar
            </button>
            <button class="cancel-btn" @click="displayImageModule = false">
              Salir
            </button>
          </p>
        </div>
      </SimpleBar>
    </div>
    <div class="modal" v-if="addPDFModule">
      <div class="top" @click="addPDFModule = false"></div>
      <div class="left" @click="addPDFModule = false"></div>
      <div class="right" @click="addPDFModule = false"></div>
      <div class="bottom" @click="addPDFModule = false"></div>
      <SimpleBar class="box">
        <div
          v-if="pdf.preview !== ''"
          class="image"
          :style="'background-image:url(' + pdf.preview + ')'"
        >
          <p class="icon">
            <i class="material-icons" @click="addPDFModule = false">close</i>
          </p>
        </div>
        <div class="content padding">
          <h2 v-html="'Cargar Documento'"></h2>
        </div>
        <div class="padding">
          <label v-html="'Seleccionar archivo'" class="long"></label>
          <div class="file-loader">
            <input type="text" disabled />
            <input
              class="file"
              type="file"
              ref="docInput"
              multiple="multiple"
              accept="application/pdf"
              @change="onMultiplePDFPicked"
            />
            <button
              class="icon-btn"
              @click="onPickPDF"
              :disabled="view === 'none'"
            >
              <i class="material-icons">cloud_upload</i>
            </button>
          </div>

          <p class="btn-center">
            <button class="ok-btn" @click="uploadMultiplePDF">Guardar</button>
            <button
              class="cancel-btn"
              @click="
                pdf = { name: '', preview: '', file: null };
                addPDFModule = false;
              "
            >
              Cancelar
            </button>
          </p>
        </div>
      </SimpleBar>
    </div>
    <div class="modal" v-if="displayPDFModule">
      <div class="top" @click="displayPDFModule = false"></div>
      <div class="left" @click="displayPDFModule = false"></div>
      <div class="right" @click="displayPDFModule = false"></div>
      <div class="bottom" @click="displayPDFModule = false"></div>
      <SimpleBar class="box">
        <p class="icon">
          <i class="material-icons" @click="displayPDFModule = false">close</i>
        </p>
        <iframe :src="documento.link.link" frameborder="0"></iframe>
        <div class="content padding">
          <h2 v-html="'Documento'"></h2>
        </div>
        <div class="padding">
          <p class="btn-center">
            <button
              class="ok-btn"
              @click="
                uploadImage();
                displayPDFModule = false;
              "
            >
              Eliminar
            </button>
            <button class="cancel-btn" @click="displayPDFModule = false">
              Salir
            </button>
          </p>
        </div>
      </SimpleBar>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import { mapGetters, mapMutations, mapActions } from "vuex";
import Message from "@/components/Message.vue";
import Loading from "@/components/Loading.vue";
import Gallery from "@/components/Gallery.vue";
import { SimpleBar } from "simplebar-vue3";
import app from "@/firebase";
import {
  getFirestore,
  arrayUnion,
  writeBatch,
  arrayRemove,
} from "firebase/firestore";
const db = getFirestore(app);
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
const storage = getStorage(app);
const ADOBE_KEY = "8edbb90a3e884f7a80dfb4594f8d5be6";
export default {
  name: "DocumentCard",
  components: {
    Message,
    Loading,
    Gallery,
    SimpleBar,
  },
  data() {
    return {
      publicated: "",
      editMode: false,
      image: {
        name: "",
        preview: "",
        file: null,
      },
      pdf: {
        name: "",
        preview: "",
        file: null,
      },
      saveChanges: false,
      isSensitive: false,
      addImageModule: false,
      displayImageModule: false,
      addPDFModule: false,
      displayPDFModule: false,
      pdfFiles: [],
      pdfAPIReady: false,
      adobeDCView: null,
      nuevaPersona: "",
      nuevaInstitucion: "",
    };
  },
  created() {
    //console.log(window.AdobeDC);
    //if (window.AdobeDC) {
    ////   this.pdfAPIReady = true;
    // }
    //document.addEventListener("adobe_dc_view_sdk.ready", () => { this.pdfAPIReady = true; });
  },
  computed: {
    ...mapGetters(["user", "getMessageAction"]),
    ...mapGetters("archive", {
      fondos: "fondos",
      documentos: "documentos",
      documento: "currentDocument",
    }),
    visibleBlocks() {
      let blocks = 0;
      if (
        this.documento.publicData[7].value.length > 0 &&
        this.documento.publicData[7].value.length > 0
      ) {
        blocks++;
      }
      if (this.user && this.documento.publicData[11].value !== "-") {
        blocks++;
      }
      return blocks;
    },
  },
  watch: {
    pdfAPIReady(val) {
      // should only be called when true, but be sure
      if (val) {
        this.adobeDCView = new window.AdobeDC.View({
          clientId: ADOBE_KEY,
          divId: "pdf-view",
        });
        this.previewPDF();
      }
    },
    documento(update) {
      if(update){
      this.publicated = update.published;}
    },
    async getMessageAction(payload) {
      if (payload.component === "alertStatusChange") {
        if (payload.action === 1) {
          if (this.publicated !== this.documento.published) {
            this.SET_LOADING_TEXT("Cargando...");
            this.SET_LOADING_OPACITY(true);
            this.SET_LOADING(true);
            let doc = doc(db, "archive", this.documento.id);
            let arr = doc(db, "archive", "published");
            var batch = writeBatch(db);
            batch.update(doc, {
              published: this.publicated,
            });
            if (this.publicated) {
              batch.update(arr, {
                list: arrayUnion(this.documento.id),
              });
            } else {
              batch.update(arr, {
                list: arrayRemove(this.documento.id),
              });
            }
            await batch.commit();
            this.SET_LOADING(false);
            let mess =
              "El estado del documento se ha actualizado correctamente, ";
            if (this.publicated) {
              mess +=
                "el documento est&aacute; disponible para todo p&uacute;blico.";
            } else {
              mess +=
                "el documento est&aacute; oculta  para el p&uacute;blico.";
            }
            this.SET_MESSAGE_PARAMETERS({
              isVisible: true,
              type: "success",
              icon: "done",
              title: "Estado del Documento Actualizado",
              message: mess,
              buttonAction: "",
              button: "Aceptar",
              respondTo: "successUpdateObject",
              action: 0,
            });
          }
        } else if (payload.action === -1) {
          this.newKeyWord = { id: "", name: "", definition: "" };
          this.SET_MESSAGE_PARAMETERS({});
        }
      }
    },
    uploadTask() {
      var p = new Promise((resolve, reject) => {
        this.uploadTask.on(
          "state_changed",
          function (snapshot) {
            this.progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log(this.progress);
            this.SET_LOADING_TEXT("Cargando: " + this.progress + "%");
            switch (snapshot.state) {
              case "paused": // or 'paused'
                console.log("Upload is paused");
                break;
              case "running": // or 'running'
                console.log("Upload is running");
                break;
            }
          },
          function (error) {
            // Handle unsuccessful uploads
            console.log(error);
            reject(error);
          },
          function () {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            //this.SET_LOADING_TEXT("");
            resolve(true);
          }
        );
      });
      p.then((val) => {
        if (val) {
          this.SET_LOADING(false);
          this.SET_MESSAGE_PARAMETERS({
            isVisible: true,
            type: "success",
            icon: "done",
            title: "Imagen Cargada",
            message:
              "Se ha cargado correctamente la imagen del documento <strong>" +
              this.documento.id +
              ".</strong>",
            buttonAction: "",
            button: "Aceptar",
            respondTo: "successUpdateObject",
            action: 0,
          });
        }
      });
    },
    "$route.params.id"(newValue) {
      this.SET_CURRENT_DOC(newValue);
    },
  },
  mounted() {
    this.publicated = this.documento.published;
    let script = document.createElement("script");
    script.setAttribute(
      "src",
      "https://documentcloud.adobe.com/view-sdk/main.js"
    );
    document.head.appendChild(script);
  },
  methods: {
    ...mapMutations([
      "SET_MESSAGE_PARAMETERS",
      "SET_LOADING",
      "SET_LOADING_OPACITY",
      "SET_LOADING_TEXT",
    ]),
    ...mapActions("archive", {
      updateDocumentoPublicData: "updateDocumentoPublicData",
      updateDocumentoPrivateData: "updateDocumentoPrivateData",
      loadDocumento: "loadDocumento",
      updateImageOfDocumento: "updateImageOfDocumento",
      deleteImageOfDocumento: "deleteImageOfDocumento",
      updatePDFOfDocumento: "updatePDFOfDocumento",
      deletePDFOfDocumento: "deletePDFOfDocumento",
    }),
    ...mapMutations("archive",{
      SET_CURRENT_DOC:"SET_CURRENT_DOC"
    }),

    addNuevaPersona() {
      if (this.nuevaPersona !== "" && this.nuevaPersona.length > 4) {
        this.documento.publicData[7].value.push(this.nuevaPersona);
        this.nuevaPersona = "";
      }
    },
    addNuevaInstitucion() {
      if (this.nuevaInstitucion !== "" && this.nuevaInstitucion.length > 4) {
        this.documento.publicData[8].value.push(this.nuevaInstitucion);
        this.nuevaInstitucion = "";
      }
    },
    onPickFile: function () {
      this.$refs.fileInput.click();
    },
    onFilePicked: function (event) {
      const file = event.target.files;
      let fileName = file[0].name;
      if (fileName.lastIndexOf(".") > 0) {
        var ext = fileName.slice(fileName.lastIndexOf("."));
        console.log(ext);
        if (
          ext.toUpperCase() === ".PNG" ||
          ext.toUpperCase() === ".JPEG" ||
          ext.toUpperCase() === ".JPG"
        ) {
          const fileReader = new FileReader();
          fileReader.addEventListener("load", () => {
            this.image.preview = fileReader.result;
          });
          this.image.name = fileName;
          fileReader.readAsDataURL(file[0]);
          this.image.file = file[0];
        } else {
          return alert("Ingrese una imagen v&aacute;lida");
        }
      }
      //this.previewDisplayed = true;
    },
    onPickPDF: function () {
      this.$refs.docInput.click();
    },
    onPDFPicked: function (event) {
      const file = event.target.files;
      console.log(file.length);
      let fileName = file[0].name;
      if (fileName.lastIndexOf(".") <= 0) {
        return alert("Ingrese un documento v&aacute;lido");
      }
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        this.pdf.preview = fileReader.result;
      });
      this.pdf.name = fileName;
      fileReader.readAsDataURL(file[0]);
      this.pdf.file = file[0];
      //this.previewDisplayed = true;
    },
    async uploadImage() {
      try {
        if (this.image.name) {
          this.SET_LOADING(true);
          var ext = this.image.name.slice(this.image.name.lastIndexOf("."));
          let refImg = ref(
            storage,
            "archive/referencia/" + this.documento.id + ext
          );
          this.uploadTask = uploadBytesResumable(refImg, this.image.file);
          this.uploadTask.on(
            "state_changed",
            (snap) => {
              var progress = Math.trunc(
                (snap.bytesTransferred / snap.totalBytes) * 100
              );
              this.SET_LOADING_TEXT("Cargando " + progress + "%");
              console.log("Upload is " + progress + "% done");
            },
            (error) => {
              this.SET_MESSAGE_PARAMETERS({
                isVisible: true,
                type: "error",
                icon: "error",
                title: "Ocurri&oacute; un error al cargar la imagen",
                message:
                  "Ocurri&oacute; un error al subir la imagen al servidor, por favor intentelo m&aacute;s tarde.",
                buttonAction: "",
                button: "Aceptar",
                respondTo: "error",
                action: 0,
              });
              this.SET_LOADING(false);
              console.log(error);
            },
            () => {
              getDownloadURL(this.uploadTask.snapshot.ref).then((url) => {
                this.updateImageOfDocumento({
                  id: this.documento.id,
                  link: url,
                  name: this.documento.id + ext,
                });
                this.image = { preview: "", name: "", file: "" };
                this.SET_LOADING_TEXT("Cargando...");
                this.SET_LOADING(false);
                return url;
              });
            }
          );
        } else {
          return false;
        }
      } catch (e) {
        console.log("error", e);
      }
    },
    deleteImage() {
      return new Promise((resolve, reject) => {
        try {
          if (this.documento.imagen !== "") {
            this.SET_LOADING(true);
            let refImg = ref(
              storage,
              "archive/referencia/" + this.documento.imagen.name
            );
            deleteObject(refImg)
              .then(() => {
                this.deleteImageOfDocumento(this.documento.id);
                this.SET_LOADING(false);
                this.SET_MESSAGE_PARAMETERS({
                  isVisible: true,
                  type: "success",
                  icon: "done",
                  title: "Imagen Eliminada",
                  message:
                    "Se ha eliminado exitosamente la vista previa del documento " +
                    this.documento.id,
                  buttonAction: "",
                  button: "Aceptar",
                  respondTo: "success",
                  action: 0,
                });
              })
              .catch((error) => {
                this.SET_LOADING(false);
                this.SET_MESSAGE_PARAMETERS({
                  isVisible: true,
                  type: "success",
                  icon: "done",
                  title: "Ocurri&oacute; un Error",
                  message:
                    "Ha ocurrido un error y no se ha podido eliminar la vista previa del documento " +
                    this.documento.id +
                    "<br>Para m&aacute;s informaci&oacute;n ver error: " +
                    error,
                  buttonAction: "",
                  button: "Aceptar",
                  respondTo: "success",
                  action: 0,
                });
              });
          } else {
            resolve(false);
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    uploadPDF() {
      return new Promise((resolve, reject) => {
        try {
          if (this.pdf.name) {
            this.SET_LOADING(true);
            var ext = this.pdf.name.slice(this.pdf.name.lastIndexOf("."));
            let refPDF = ref(
              storage,
              "archive/documento/" + this.documento.id + ext
            );
            this.uploadTask = uploadBytesResumable(refPDF, this.pdf.file);
            this.uploadTask.on(
              "state_changed",
              (snap) => {
                var progress = Math.trunc(
                  (snap.bytesTransferred / snap.totalBytes) * 100
                );
                this.SET_LOADING_TEXT("Cargando " + progress + "%");
                console.log("Upload is " + progress + "% done");
              },
              (error) => {
                this.SET_MESSAGE_PARAMETERS({
                  isVisible: true,
                  type: "error",
                  icon: "error",
                  title: "Ocurri&oacute; un error al cargar el documento",
                  message:
                    "Ocurri&oacute; un error al subir el documento al servidor, por favor intentelo m&aacute;s tarde.",
                  buttonAction: "",
                  button: "Aceptar",
                  respondTo: "error",
                  action: 0,
                });
                this.SET_LOADING(false);
                console.log(error);
              },
              () => {
                getDownloadURL(this.uploadTask.snapshot.ref).then((url) => {
                  this.updatePDFOfDocumento({
                    id: this.documento.id,
                    link: url,
                    name: this.documento.id + ext,
                    restricted: this.isSensitive,
                  });
                  this.link = { preview: "", name: "", file: "" };
                  this.SET_LOADING_TEXT("Cargando...");
                  this.SET_LOADING(false);
                  resolve(url);
                });
              }
            );
          } else {
            resolve(false);
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    onMultiplePDFPicked: function (event) {
      const file = event.target.files;
      this.pdfFiles = [];
      file.forEach((doc) => {
        let fileName = doc.name;
        this.pdfFiles.push({ name: fileName, file: doc });
        console.log(
          doc.name.slice(doc.name.lastIndexOf(".")),
          doc.name.slice(0, 12)
        );
      });
      console.log(this.pdfFiles);
    },
    uploadMultiplePDF() {
      return new Promise((resolve, reject) => {
        try {
          if (this.pdfFiles.length > 0) {
            this.SET_LOADING(true);
            this.pdfFiles.forEach((pdf) => {
              var ext = pdf.name.slice(pdf.name.lastIndexOf("."));
              var id = pdf.name.slice(0, 12);
              let refDoc = ref(storage, "archive/documento/" + id + ext);
              this.uploadTask = uploadBytesResumable(refDoc, pdf.file);
              this.uploadTask.on(
                "state_changed",
                (snap) => {
                  var progress = Math.trunc(
                    (snap.bytesTransferred / snap.totalBytes) * 100
                  );
                  this.SET_LOADING_TEXT("Cargando " + progress + "%");
                  console.log("Upload is " + progress + "% done");
                },
                (error) => {
                  this.SET_MESSAGE_PARAMETERS({
                    isVisible: true,
                    type: "error",
                    icon: "error",
                    title: "Ocurri&oacute; un error al cargar el documento",
                    message:
                      "Ocurri&oacute; un error al subir el documento " +
                      id +
                      " al servidor, por favor intentelo m&aacute;s tarde.",
                    buttonAction: "",
                    button: "Aceptar",
                    respondTo: "error",
                    action: 0,
                  });
                  this.SET_LOADING(false);
                  console.log(error);
                },
                () => {
                  getDownloadURL(this.uploadTask.snapshot.ref).then((url) => {
                    this.updatePDFOfDocumento({
                      id: id,
                      link: url,
                      name: id + ext,
                      restricted: false,
                    });
                    this.SET_LOADING_TEXT("Cargando...");
                    this.SET_LOADING(false);
                    resolve(url);
                  });
                }
              );
            });
          } else {
            resolve(false);
          }
        } catch (error) {
          reject(error);
        }
      });
    },

    deletePDF() {
      return new Promise((resolve, reject) => {
        try {
          if (this.documento.link !== "") {
            this.SET_LOADING(true);
            let refDoc = ref(
              storage,
              "archive/documento/" + this.documento.link.name
            );
            deleteObject(refDoc)
              .then(() => {
                this.deletePDFOfDocumento(this.documento.id);
                this.SET_LOADING(false);
                this.SET_MESSAGE_PARAMETERS({
                  isVisible: true,
                  type: "success",
                  icon: "done",
                  title: "Imagen Eliminada",
                  message:
                    "Se ha eliminado exitosamente la vista previa del documento " +
                    this.documento.id,
                  buttonAction: "",
                  button: "Aceptar",
                  respondTo: "success",
                  action: 0,
                });
              })
              .catch((error) => {
                this.SET_LOADING(false);
                this.SET_MESSAGE_PARAMETERS({
                  isVisible: true,
                  type: "success",
                  icon: "done",
                  title: "Ocurri&oacute; un Error",
                  message:
                    "Ha ocurrido un error y no se ha podido eliminar la vista previa del documento " +
                    this.documento.id +
                    "<br>Para m&aacute;s informaci&oacute;n ver error: " +
                    error,
                  buttonAction: "",
                  button: "Aceptar",
                  respondTo: "success",
                  action: 0,
                });
              });
          } else {
            resolve(false);
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    getFondoName(payload) {
      let fondo = this.fondos.find((item) => item.id === payload);
      if (fondo) {
        return fondo.publicData[0].value;
      } else {
        return "No encontrado";
      }
    },
    getSerieName(payload) {
      let fondo = this.fondos.find((item) => item.id === payload.fondo);
      if (fondo) {
        let serie = fondo.series.find((item) => item.id === payload.serie);
        if (serie) {
          return serie.publicData[0].value;
        } else {
          return "No encontrado";
        }
      } else {
        return "No encontrado";
      }
    },
    updatePublicDataOfDocumento() {
      this.SET_LOADING(true);
      this.updateDocumentoPublicData({
        id: this.$route.params.id,
        doc: { publicData: this.documento.publicData },
      })
        .then((result) => {
          if (result === true) {
            this.SET_LOADING(false);
            this.SET_MESSAGE_PARAMETERS({
              isVisible: true,
              type: "success",
              icon: "done",
              title: "Informaci&oacute;n Actualizada",
              message:
                "Se ha actualizado exitosamente la informaci&oacute;n del documento " +
                this.documento.id,
              buttonAction: "",
              button: "Aceptar",
              respondTo: "success",
              action: 0,
            });
          }
        })
        .catch((e) => {
          this.SET_LOADING(false);
          this.SET_MESSAGE_PARAMETERS({
            isVisible: true,
            type: "success",
            icon: "done",
            title: "Ocurri&oacute; un Error",
            message:
              "Ha ocurrido un error y no se ha podido eliminar la vista previa del documento " +
              this.documento.id +
              "<br>Para m&aacute;s informaci&oacute;n ver error: " +
              e,
            buttonAction: "",
            button: "Aceptar",
            respondTo: "success",
            action: 0,
          });
        });
    },
    updatePrivateDataOfDocumento() {
      this.updateDocumentoPrivateData({
        id: this.$route.params.id,
        doc: { privateData: this.documento.privateData },
      });
    },
    previewPDF() {
      this.adobeDCView.previewFile(
        {
          content: { location: { url: this.documento.link } },
          metaData: { fileName: this.$route.params.id },
        },
        {
          embedMode: "SIZED_CONTAINER",
          dockPageControls: false,
          showDownloadPDF: false,
          showPrintPDF: false,
        }
      );
    },
    ...mapMutations([
      "SET_CURRENT_OBJECT_ID",
      "SET_MESSAGE_PARAMETERS",
      "SET_LOADING",
      "SET_LOADING_OPACITY",
      "SET_LOADING_TEXT",
      "SET_ITEM_STATUS",
    ]),
    togglePublished() {
      let data;
      if (this.documento.published) {
        data = {
          title: "Cambiar estado de Publicaci&oacute;n",
          message:
            "Al cambiar el estado de esta ficha ya no estar&aacute; visible para todo p&uacute;blico, &iquest;Confirma que desea cambiar su estado?",
        };
      } else {
        data = {
          title: "Cambiar estado de Publicaci&oacute;n",
          message:
            "Cambiar el estado de esta ficha har&aacute; que est&eacute; visible para todo p&uacute;blico, &iquest;Confirma que desea cambiar su estado?",
        };
      }
      this.SET_MESSAGE_PARAMETERS({
        isVisible: true,
        type: "alert",
        icon: "warning",
        ...data,
        buttonAction: "Confirmar",
        button: "Cancelar",
        respondTo: "alertStatusChange",
        action: 0,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./../../assets/sass/variables";
@import "./../../assets/sass/buttons";
@import "./../../assets/sass/ficha";
@import "../../assets/sass/modal";
@import "../../assets/sass/cards";
object {
  width: 100%;
  height: 100%;
}
.container {
  padding: 10px calc(5% + 10px) 10px 5%;
  position: relative;

  .edit-options {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 10px;
    .left {
      margin-top: 4.5px;
      p {
        display: inline;
        color: $text-dark;
        line-height: 30px;
        margin-block-end: 0;
        margin-block-start: 0;
        vertical-align: middle;
        top: 0%;
        margin-right: 10px;
      }
    }
  }
}
.info-card {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-rows: auto auto auto;
  grid-template-columns: auto;
  //background-color: #bbb9b9;
  background-color: $grey-1;
  //background-color: #e5e5e5;
  .header,
  .image,
  .id-card,
  .container {
    grid-column-start: 1;
    grid-column-end: 2;
  }
  .header {
    grid-row-start: 1;
    z-index: 2;
    grid-row-end: 2;
    margin-top: auto;
    margin-bottom: 0%;
    bottom: 0%;
    padding-left: 1rem;
    h1 {
      font-size: 2.5em;
      font-weight: 900;
      text-transform: uppercase;
      color: rgba($color: $orange-d1, $alpha: 0.9);
    }
  }
  .image {
    grid-row-start: 2;
    grid-row-end: 3;
    padding: 1rem;
    margin: 0;
    height: fit-content;
    overflow: hidden;
    div.img {
      width: 100%;

      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      padding-top: 100%;
      margin: 0;
      //top: 50%;
      //transform: translateY(-50%);
    }
    i.material-icons {
      width: 100%;
      font-size: 15rem;
      text-align: center;
      color: rgba($color: $orange-d1, $alpha: 0.8);
      height: 100%;
    }
  }
  .id-card {
    grid-row-start: 3;
    grid-row-end: 4;
    padding: 0rem 1rem 1rem 1rem;
    h3 {
      color: rgba($color: $orange-d1, $alpha: 0.9);
      font-weight: bolder;
      letter-spacing: 1px;
    }
    p {
      font-weight: 400;
      margin-left: 3px;
      margin-block-end: 0px !important;
      margin-block-start: 0px !important;
      //color: white;
      color: $text-dark;
      line-height: 1.5em;
      strong {
        letter-spacing: 1px;
      }
    }
    p.inline {
      display: inline-block;
    }
  }
  .content {
    grid-row-start: 5;
    grid-row-end: 6;
    padding: 1em 1em;
    p {
      display: inline-block;
      margin-left: 3px;
      margin-block-end: 0px !important;
      margin-block-start: 0px !important;
      color: white;
      line-height: 1.5em;
      letter-spacing: 1px;
    }
  }
  .info {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
    position: relative;
    background-color: darkgreen;
    div {
      width: 100%;
      bottom: 0;
      margin-bottom: 0;
      position: absolute;
      height: fit-content;
      div {
        position: relative;

        h3,
        p {
          color: whitesmoke;
          border-block-end: 0px;
          border-block-start: 0px;
        }
        .category-section {
          width: 100%;
          height: fit-content;
          position: relative;
          div {
            p {
              display: inline-block;
              margin-left: 3px;
              margin-block-end: 0px !important;
              margin-block-start: 0px !important;
            }
            p:first-child {
              margin-left: 0px;
            }
          }
        }
      }
    }
  }
  button {
    background-color: transparent;
    border: solid 1px whitesmoke;
    border-radius: 20px;
    cursor: pointer;
    padding: 6px 0px;
    font-weight: 300;
    width: 100%;
    color: $text;
    i,
    p {
      color: whitesmoke;
    }
    i {
      font-size: 24px;
      margin-right: 8px;
    }
    br {
      display: block;
    }
  }
  button:hover,
  button.selected {
    border-color: $theme;
    i,
    p {
      color: $theme;
    }
  }
}
@media (min-width: 576px) {
  .container {
    .edit-options {
      grid-template-columns: 1fr 1fr;
    }
  }
}

@media (min-width: 768px) {
  .info-card {
    grid-template-rows: auto auto;
    grid-template-columns: repeat(2, 1fr);
    .header {
      grid-column-start: 1;
      grid-column-end: 2;
      padding-left: 3rem;
      h1 {
        font-size: 3rem;
        font-weight: bolder;
      }
    }
    .image {
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 3;
    }
    .id-card {
      grid-row-start: 2;
      grid-row-end: 3;
      padding-left: 3rem;
    }
  }
  .container {
    padding: 1rem 1rem;
  }
}
@media (min-width: 992px) {
  .info-card {
    grid-template-columns: 2fr 1.5fr;
    .id-card,
    .header {
      padding-left: calc(10vw + 2rem);
    }
  }
  .container {
    padding: 10px calc(10vw + 14px) 10px 10vw;
  }
}
</style>