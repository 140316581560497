<template>
  <div class="grapper">
    <div class="search">
      <h1 v-html="'Categor&iacute;as'"></h1>
      <div class="autocomplete-container">
        <div>
          <Autocomplete
            :list="words"
            :alternativeText="'Buscar Categor&iacute;ia'"
            v-on:get-result="setResult"
            :border="false"
            height="50px"
            margin="25px"
            class="radius-left"
          />
          <button class="radius-right" :disabled="searchedValue===''" @click="search">
            <i class="material-icons">search</i>
          </button>
        </div>
      </div>
    </div>
    <div class="search-result" v-show="result.display">
      <i class="material-icons" @click="result.display=false">close</i>
      <h2 v-html="'Resultado de B&uacute;squeda'"></h2>
      <div class="description">
        <h4>
          <strong v-html="'Categor&iacute;a'"></strong>
          {{result.doc.name}}
        </h4>
        <p>
          <strong v-html="'Definici&oacute;n: '"></strong>
          {{result.doc.definition}}
        </p>
      </div>
      <div class="dependencies">
        <h3>Conceptos Relacionados</h3>
        <h5>Dependencias</h5>
        <ul class="parents">
          <li v-for="item in result.doc.parents" :key="item.id">
            <i class="material-icons">subdirectory_arrow_right</i>
            {{item.name}}
          </li>
        </ul>
        <h5 v-html="'Subcategor&iacute;as'"></h5>
        <ul class="children">
          <li v-for="item in result.doc.children" :key="item.id">
            <i class="material-icons">subdirectory_arrow_right</i>
            {{item.name}}
          </li>
        </ul>
      </div>
    </div>
    <div class="create">
      <h3 v-html="'Agregar Conceptos'"></h3>
      <div class="first" @click="addCategoryDisplayed=true">
        <i class="fas fa-sitemap"></i>
        <h4 v-html="'Agregar Categor&iacute;a Principal'"></h4>
      </div>
      <div @click="addConceptDisplayed=true">
        <i class="material-icons">note_add</i>
        <h4 v-html="'Agregar Subcategor&iacute;a'"></h4>
      </div>
    </div>
    <div class="category-group">
      <h2 v-html="'Categor&iacute;as Principales'"></h2>
      <div class="category" v-for="item in categories" :key="item.id">
        <div class="main-category" v-if="!(descriptionModule(item.id).displayed)">
          <h4>{{item.name}}</h4>
          <p v-show="isShortDefinitionDisplayed(item.id)">
            <strong v-html="'Definici&oacute;n: '"></strong>
            {{item.definition.slice(0,150)}}
            <span
              v-html="' + ver m&aacute;s'"
              @click="setShortDefinitionDisplayed({id:item.id,value:true})"
            ></span>
          </p>
          <p v-show="!isShortDefinitionDisplayed(item.id)">
            <strong v-html="'Definici&oacute;n: '"></strong>
            {{item.definition}}
            <span
              v-html="' - ver menos'"
              @click="setShortDefinitionDisplayed({id:item.id,value:false})"
            ></span>
          </p>
          <div class="list" v-if="item.subCategories.length>0">
            <h5 v-html="'Clasificaci&oacute;n'"></h5>
            <ul>
              <li
                v-for="elem in item.subCategories"
                :key="elem.id"
                @click="setCurrentWords({id:item.id,newId:elem.id})"
              >{{elem.name}}</li>
            </ul>
          </div>
        </div>
        <div class="word-description" v-if="(descriptionModule(item.id).displayed)">
          <div class="parents">
            <p
              class="parent-link"
              v-for="elem in descriptionModule(item.id).data.parents"
              :key="elem.id"
              @click="setCurrentWords({id:item.id,newId:elem.id})"
            >/{{elem.name}}</p>
          </div>
          <h4>{{descriptionModule(item.id).data.name}}</h4>
          <p>
            <strong v-html="'Definici&oacute;n: '"></strong>
            {{descriptionModule(item.id).data.definition}}
          </p>
          <div
            class="list"
            v-if="getChildren({pos:0,parents:descriptionModule(item.id).data.parents,id:descriptionModule(item.id).data.id,current:item}).length>0"
          >
            <h5 v-html="'Subcategor&iacute;as'"></h5>
            <ul>
              <li
                v-for="elem in getChildren({pos:0,parents:descriptionModule(item.id).data.parents,id:descriptionModule(item.id).data.id,current:item})"
                :key="elem.id"
                @click="setCurrentWords({id:item.id,newId:elem.id})"
              >{{elem.name}}</li>
            </ul>
          </div>
          <button
            class="back"
            @click="goBack({id:item.id,parents:descriptionModule(item.id).data.parents})"
          >
            <i class="material-icons">keyboard_arrow_left</i>Volver
          </button>
        </div>
      </div>
    </div>
    <div class="add" v-show="addCategoryDisplayed||addConceptDisplayed">
      <div class="box">
        <p class="icon">
          <i
            class="material-icons"
            @click="addCategoryDisplayed=false;addConceptDisplayed=false"
          >close</i>
        </p>
        <AddCategory
          :concepts="words"
          v-show="addCategoryDisplayed"
          @exit="setAddCategoryDisplayed"
        />
        <AddConcept :concepts="words" v-show="addConceptDisplayed" @exit="setAddConceptDisplayed" />
      </div>
    </div>
    <Loading text="cargando" />
    <Message />
  </div>
</template>
<script>
// @ is an alias to /src
import { mapGetters, mapMutations } from "vuex";
import Message from "@/components/Message.vue";
import Loading from "@/components/Loading.vue";
import AddCategory from "@/components/AddCategory.vue";
import AddConcept from "@/components/AddConcept.vue";
import Autocomplete from "@/components/Autocomplete.vue";

export default {
  name: "Sorting-compoent",
  components: {
    Message,
    AddCategory,
    Loading,
    AddConcept,
    Autocomplete
  },
  data: function() {
    return {
      result: {
        display: false,
        doc: { id: "", name: "", definition: "", parents: [] }
      },
      searchedValue:'',
      currentWords: [],
      words: [],
      addCategoryDisplayed: false,
      addConceptDisplayed: false,
      shortDefinition: []
    };
  },
  computed: {
    ...mapGetters(["categories"])
  },
  watch: {
    categories(payload) {
      this.words = [];
      payload.forEach(el => {
        var obj = {
          id: el.id,
          name: el.name,
          definition: el.definition,
          subCategories: el.subCategories,
          parents: []
        };
        this.listedCategories(obj);
      });
    }
  },
  mounted() {
    this.categories.forEach(el => {
      var obj = {
        id: el.id,
        name: el.name,
        definition: el.definition,
        subCategories: el.subCategories,
        parents: []
      };
      this.listedCategories(obj);
    });
  },
  methods: {
    ...mapMutations(["SET_CURRENT_OBJECT_ID"]),
    listedCategories(payload) {
      var obj = {
        id: payload.id,
        name: payload.name,
        definition: payload.definition,
        parents: payload.parents,
        children: payload.subCategories
      };
      this.addWordToArray(obj);
      var arr = [];
      payload.parents.forEach(element => {
        arr.push({ id: element.id, name: element.name });
      });
      arr.push({ id: payload.id, name: payload.name });
      payload.subCategories.forEach(el => {
        this.listedCategories({
          id: el.id,
          name: el.name,
          definition: el.definition,
          subCategories: el.subCategories,
          parents: arr
        });
      });
    },
    addWordToArray(payload) {
      var pos = 0;
      var added = false;
      if (this.words.findIndex(doc => doc.id === payload.id) > 0) {
        return;
      } else {
        this.words.forEach(val => {
          if (added) {
            return;
          } else {
            if (
              val.name
                .toLowerCase()
                .localeCompare(payload.name.toLowerCase()) === -1
            ) {
              pos++;
            } else if (
              val.name
                .toLowerCase()
                .localeCompare(payload.name.toLowerCase()) === 1
            ) {
              this.words.splice(pos, 0, payload);
              added = true;
              return;
            } else {
              pos++;
              return;
            }
          }
        });
        if (!added) {
          this.words.push(payload);
        }
      }
    },
    setCurrentWords(payload) {
      const i = this.currentWords.findIndex(doc => doc.id === payload.id);
      if (i > -1) {
        if (payload.id === payload.newId) {
          this.currentWords.splice(i, 1);
        } else {
          this.currentWords[i].doc = this.words.find(
            el => el.id === payload.newId
          );
        }
      } else {
        this.currentWords.push({
          id: payload.id,
          doc: this.words.find(doc => doc.id === payload.newId)
        });
      }
    },
    descriptionModule(payload) {
      const el = this.currentWords.find(doc => doc.id === payload);
      if (el !== undefined && el !== null) {
        return { displayed: true, data: el.doc };
      } else {
        return { displayed: false, data: null };
      }
    },
    setResult(payload) {
      if(payload.doc===null ||payload.doc===undefined){
        this.searchedValue=payload.text
        this.result.doc=null
      }else{
        if(payload.text===payload.doc.name){
          this.searchedValue=payload.doc.name
          this.result.doc=payload.doc
        }else{
          this.searchedValue=payload.text
          this.result.doc=null
        }
      }
    },
    search(){
      if(this.result.doc!==null){
        this.result.display=true
      }
    },
    getChildren(payload) {
      if (payload.pos < payload.parents.length - 1) {
        return this.getChildren({
          pos: payload.pos + 1,
          parents: payload.parents,
          id: payload.id,
          current: payload.current.subCategories.find(
            doc => doc.id === payload.parents[payload.pos + 1].id
          )
        });
      } else {
        return payload.current.subCategories.find(doc => doc.id === payload.id)
          .subCategories;
      }
    },
    setShortDefinitionDisplayed(payload) {
      if (payload.value) {
        this.shortDefinition.push(payload.id);
      } else {
        const i = this.shortDefinition.findIndex(elem => elem === payload.id);
        this.shortDefinition.splice(i, 1);
      }
    },
    isShortDefinitionDisplayed(payload) {
      if (this.shortDefinition.findIndex(doc => doc === payload) > -1) {
        return false;
      } else {
        return true;
      }
    },
    setAddCategoryDisplayed(payload) {
      this.addCategoryDisplayed = payload;
    },
    setAddConceptDisplayed(payload) {
      this.addConceptDisplayed = payload;
    },
    goBack(payload) {
      const i = payload.parents.length - 1;
      this.setCurrentWords({ id: payload.id, newId: payload.parents[i].id });
    }
  }
};
</script>
<style lang="scss" scoped>
@import "./../assets/sass/variables";
.grapper {
  display: grid;
  grid-template-rows: 65vh auto;
  grid-gap: 7px;
  .search {
    background-image: linear-gradient(
        to bottom,
        transparent,
        rgba(0, 0, 0, 0.75)
      ),
      url("../assets/media/School.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: grid;
    grid-template-rows: auto 120px;
    h1 {
      padding-top: 40%;
      color: $theme-l4;
      vertical-align: middle;
      text-align: center;
    }
    div.autocomplete-container {
      padding: 25px 10% 45px 10%;
      div {
        display: grid;
        grid-template-columns: auto 60px;
        grid-template-rows: 50px;
        border-radius: 50px;
        background-color: white;
        padding: 0px;
        overflow: visible;
        .radius-left{
          border-bottom-left-radius: 50px;
          border-top-left-radius: 50px;
        }
        .radius-right{
          border-top-right-radius: 50px;
          border-bottom-right-radius: 50px;
        }
        
        button {
          margin-block-end: 0px;
          margin-block-start: 0px;
          background-color: $theme;
          border: none;
          cursor: pointer;
          i.material-icons {
            vertical-align: middle;
            font-size: 35px;
            color: white;
          }
        }
        button:disabled {
          opacity: 0.5;
          cursor: no-drop;
        }
      }
    }
  }
  .category-group {
    overflow: hidden;
    border-radius: 25px;
    background-color: white;
    margin: 1% 3%;
    display: grid;
    grid-gap: 5px;
    h2 {
      color: $theme-d1;
      text-align: center;
    }
    .category {
      border-left: solid 10px;
      padding: 1.5% 3%;
      .main-category,
      .word-description {
        p {
          span {
            text-decoration: underline;
            color: $theme-l2;
            cursor: pointer;
          }
        }
        .list {
          ul {
            padding-left: 10px;
            li {
              list-style: none;
              text-decoration: underline;
            }
            li:hover {
              font-weight: bold;
              cursor: pointer;
            }
          }
        }
      }
      .word-description {
        .parents {
          p {
            display: inline;
            text-decoration: underline;
            font-size: 14px;
            margin-left: 4px;
            cursor: pointer;
          }
          p:hover {
            color: $theme;
          }
        }
        button.back {
          float: right;
          background-color: white;
          color: $theme;
          width: fit-content;
          padding: 5px 12px 5px 0px;
          border: none;
          margin-right: 10px;
          border-bottom: solid 3px white;
          cursor: pointer;
          margin-block-start: 0px;
          margin-block-end: 0px;
          i.material-icons {
            vertical-align: middle;
            margin-right: 5px;
          }
        }
        button.back:hover {
          border-bottom-color: $theme-d2;
          color: $theme-d2;
        }
      }
    }
    .category:nth-child(1) {
      border-left-color: $theme-l3;
      h4,
      h5 {
        color: $theme-l3;
      }
      .word-description {
        .parents {
          p:hover {
            color: $theme-l3;
          }
        }
        button.back {
          color: $theme-l3;
        }
        button.back:hover {
          border-bottom-color: $theme;
          color: $theme;
        }
      }
    }
    .category:nth-child(2) {
      border-left-color: $theme-l2;
      h4,
      h5 {
        color: $theme-l2;
      }
      .word-description {
        .parents {
          p:hover {
            color: $theme-l2;
          }
        }
        button.back {
          color: $theme-l2;
        }
        button.back:hover {
          border-bottom-color: $theme;
          color: $theme;
        }
      }
    }
    .category:nth-child(3) {
      border-left-color: $theme-l1;
      h4,
      h5 {
        color: $theme-l1;
      }
      .word-description {
        .parents {
          p:hover {
            color: $theme-l1;
          }
        }
        button.back {
          color: $theme-l1;
        }
        button.back:hover {
          border-bottom-color: $theme;
          color: $theme;
        }
      }
    }
    .category:nth-child(4) {
      border-left-color: $theme-d1;
      h4,
      h5 {
        color: $theme-d1;
      }
      .word-description {
        .parents {
          p:hover {
            color: $theme-d1;
          }
        }
        button.back {
          color: $theme-d1;
        }
        button.back:hover {
          border-bottom-color: $theme;
          color: $theme;
        }
      }
    }
    .category:nth-child(5) {
      border-left-color: $theme-d2;
      h4,
      h5 {
        color: $theme-d2;
      }
      .word-description {
        .parents {
          p:hover {
            color: $theme-d2;
          }
        }
        button.back {
          color: $theme-d2;
        }
        button.back:hover {
          border-bottom-color: $theme;
          color: $theme;
        }
      }
    }
    .category:nth-child(6) {
      border-left-color: $theme-d3;
      h4,
      h5 {
        color: $theme-d3;
      }
      .word-description {
        .parents {
          p:hover {
            color: $theme-d3;
          }
        }
        button.back {
          color: $theme-d3;
        }
        button.back:hover {
          border-bottom-color: $theme;
          color: $theme;
        }
      }
    }
  }
  .search-result {
    border-radius: 25px;
    background-color: $theme-d2;
    margin: 1% 3%;
    display: grid;
    i.material-icons {
      color: white;
      cursor: pointer;
      margin-right: 8px;
      right: 0%;
      margin-top: 8px;
      text-align: right;
    }
    h2 {
      color: $theme-l2;
      text-align: center;
      margin-block-start: 0px;
      margin-block-end: 0px;
    }
    .description,
    .dependencies {
      padding: 3%;
      h4,
      h5,
      h3 {
        font-weight: 300;
        color: $theme-l4;
      }
      h3 {
        text-align: center;
      }
      p {
        color: whitesmoke;
      }
    }
    .dependencies {
      ul.parents,
      ul.children {
        padding-left: 15px;
        li {
          list-style: none;
          color: white;
          text-decoration: underline;
          margin-block-end: 2px;
          margin-block-start: 2px;
          cursor: pointer;
          i.material-icons {
            margin-top: 0px;
            vertical-align: middle;
            margin-right: 3px;
          }
        }
      }
      ul.parents {
        li:nth-child(2) {
          margin-left: 5px;
        }
        li:nth-child(3) {
          margin-left: 10px;
        }
        li:nth-child(4) {
          margin-left: 15px;
        }
        li:nth-child(5) {
          margin-left: 20px;
        }
        li:nth-child(6) {
          margin-left: 25px;
        }
        li:nth-child(7) {
          margin-left: 30px;
        }
      }
    }
  }
  .create {
    border-radius: 25px;
    background-color: white;
    margin: 1% 3%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    h3 {
      color: $theme-d1;
      text-align: center;
      grid-column-start: 1;
      grid-column-end: 3;
    }
    div {
      color: $text;
      margin: 5%;
      cursor: pointer;
      i,
      i.material-icons {
        font-size: 54px;
        padding: 10px;
        text-align: center;
        width: 100%;
      }
      h4 {
        color: $text;
        font-weight: 300;
        text-align: center;
      }
    }
    div.first {
      border-right: solid 1px lightgray;
      margin-right: 0%;
      padding-right: 5%;
    }
    div:hover {
      color: $theme-l2;
      h4 {
        color: $theme-l2;
      }
    }
  }
  .add {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    margin: 0px;
    background-color: #ededed80;
    z-index: 5;
    padding: 5%;
    div.box {
      top: 50%;
      left: 50%;
      width: 85%;
      border-radius: 10px;
      border: solid 5px hsla(0, 0%, 65%, 0.514);
      background-color: white;
      transform: translate(-50%, -50%) !important;
      position: absolute;
      padding: 10px 12px;
      max-height: 90vh;
      overflow-y: auto;
      p.icon {
        text-align: right;
        i {
          font-size: 24px;
          cursor: pointer;
        }
        i:hover {
          font-weight: bold;
        }
        padding: 0px;
        color: $text;
        margin-block-start: 0px;
        width: 100%;
        margin-block-end: 0px;
      }
      h2 {
        margin-block-start: 0px;
        text-align: center;
      }
      p {
        color: $text;
        text-align: center;
      }
    }
  }
}
</style>