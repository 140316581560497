export default {
    state: {
        loading: false,
        loadingText: '',
        isTransparent: false,
        adminBarCollapsed: true,
        adminBarDisplayed: true,
        gallery: false,
        galleryIndex: 0,
        galleryItems: [],
        searchHistory: [],
        searchId: 1
    },
    actions: {
        addSearchRequest({
            commit,
            getters
        }, payload) {
            let newId = 'search=' + getters.searchId
            commit("ADD_SEARCH_REQUEST", {
                id: newId,
                ...payload
            })
            commit("SET_SEARCH_ID", getters.searchId + 1)
            return newId
        }
    },
    getters: {
        adminBarCollapsed: state => {
            return state.adminBarCollapsed
        },
        adminBarDisplayed: state => {
            return state.adminBarDisplayed
        },
        loadingText: state => {
            return state.loadingText
        },
        loading: state => {
            return state.loading
        },
        isTransparent: state => {
            return state.isTransparent
        },
        gallery: state => {
            return state.gallery
        },
        galleryIndex: state => {
            return state.galleryIndex
        },
        galleryItems: state => {
            if (state.galleryItems) {
                return state.galleryItems
            } else {
                return []
            }
        },
        getSearchHistoryResult: (state) => (payload) => {
            return state.searchHistory.find(item => item.id === payload)
        },
        searchId: (state) => {
            return state.searchId
        }
    },
    mutations: {
        SET_LOADING: function (store, val) {
            store.loading = val
        },
        SET_LOADING_TEXT: function (store, val) {
            store.loadingText = val
        },
        SET_LOADING_OPACITY: function (store, val) {
            store.isTransparent = val
        },
        SET_ADMIN_BAR_DISPLAY(store, val) {
            store.adminBarDisplayed = val
        },
        SET_ADMIN_BAR_COLLAPSE(store, val) {
            store.adminBarCollapsed = val
        },
        SET_GALLERY(store, val) {
            store.gallery = val
        },
        SET_GALLERY_INDEX(store, val) {
            store.galleryIndex = val
        },
        SET_GALLERY_ITEMS(store, val) {
            store.galleryItems = val
        },
        ADD_SEARCH_REQUEST(store, payload) {
            store.searchHistory.push(payload)
        },
        SET_SEARCH_ID(store, payload) {
            store.searchId = payload
        },
    }
}