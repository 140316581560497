<template>
  <div class="home">
    <div class="search">
      <div>
        <h1 v-html="'Sistema de Gesti&oacute;n de Colecciones'"></h1>
      </div>
      <h4 v-html="'Museo de Sitio Puerto Cristal'"></h4>
      <div class="autocomplete-container" v-if="searchMethod === 'name'">
        <div>
          <Autocomplete
            :list="autocompleteList"
            :alternativeText="'Buscar por Nombre del Objeto'"
            v-on:get-result="setResult"
            :border="false"
            height="50px"
            margin="25px"
            class="radius-left"
          />
          <button
            class="radius-right"
            :disabled="result.doc === null"
            @click="search"
          >
            <i class="material-icons">search</i>
          </button>
        </div>
      </div>
      <div class="autocomplete-container" v-if="searchMethod === 'id'">
        <div>
          <Autocomplete
            :list="autocompleteList"
            :alternativeText="'Buscar Código del Objeto'"
            v-on:get-result="setResult"
            :border="false"
            height="50px"
            margin="25px"
            class="radius-left"
          />
          <button
            class="radius-right"
            :disabled="result.doc === null"
            @click="search"
          >
            <i class="material-icons">search</i>
          </button>
        </div>
      </div>
      <div class="search-options">
        <input
          type="radio"
          name="option"
          id="option"
          value="name"
          v-model="searchMethod"
        />
        <label>Nombre</label>
        <input
          type="radio"
          name="option"
          id="option"
          value="id"
          v-model="searchMethod"
        />
        <label v-html="'C&oacute;digo de registro'"></label>
      </div>
    </div>
    <div class="section" v-if="resultList.length > 0">
      <h4
        v-if="result.doc !== null"
        v-html="'Resultado de B&uacute;squeda'"
      ></h4>
      <div class="card-container">
        <router-link
          class="card"
          :to="'/item/' + item.id"
          v-for="item in resultList"
          :key="item.id"
        >
          <div
            class="card-img"
            :style="'background-image:url(' + item.referenceImage.url + ')'"
            v-if="item.referenceImage"
          ></div>
          <div class="card-img" v-else>
            <i class="material-icons">insert_photo</i>
          </div>
          <div class="middle-icon">
            <div class="right">
              <i class="material-icons">add</i>
            </div>
          </div>
          <div class="card-content">
            <h4>{{ item.name }}</h4>
            
          </div>
        </router-link>
      </div>
    </div>
    <div class="section side-menu" v-else>
      <FilterMenu class="filter" :payload="filters" />
      <div class="objects">
        <h4 v-html="'OBJETOS REGISTRADOS'"></h4>
        <PageViewer
          :setup="{ link: true, iconPosition: 'right', buttons: [] }"
        />
      </div>
    </div>
    <Loading text="cargando" />
    <Message />
  </div>
</template>
<script>
// @ is an alias to /src
import Loading from "@/components/Loading.vue";
import Message from "@/components/Message.vue";
import PageViewer from "@/components/PageViewer.vue";
import Autocomplete from "@/components/Autocomplete.vue";
import FilterMenu from "@/components/Filter.vue";
import { mapGetters, mapMutations, mapActions } from "vuex";
import app from "@/firebase";
import {
  getFirestore,
  collection,
  doc,
  query,
  where,
} from "firebase/firestore";
const db = getFirestore(app);
export default {
  name: "Collection-landpage",
  components: {
    Loading,
    Message,
    Autocomplete,
    FilterMenu,
    PageViewer,
  },
  data: function () {
    return {
      searchedValue: "",
      result: { doc: null },
      id: "",
      searchId: "",
      searchMethod: "name",
      lastName: "",
      itemsPerPage: 8,
      currentPage: 1,
      filters: ["clasification", "material", "technique"],
    };
  },
  created() {
    this.SET_LOADING(false);
  },
  mounted() {
    if (this.$route.params.searchID !== undefined) {
      let historial = this.getSearchHistoryResult(this.$route.params.searchID);
      console.log(historial)
      if (historial) {
        this.searchMethod = historial.searchMethod;
        this.result = historial.searchParams;
      } else {
        this.$router.push("/collection");
      }
    }
  },
  watch: {
    searchResult() {},
    itemsPerPage() {
      this.currentPage = 1;
    },
    "$route.params.searchID"(value) {
      if (value !== undefined) {
        let historial = this.getSearchHistoryResult(value);
        if (historial) {
          this.searchMethod = historial.searchMethod;
          this.result = historial.searchParams;
        } else {
          this.$router.push("/collection");
        }
      }
    },
  },
  computed: {
    ...mapGetters([
      "searchResult",
      "categories",
      "currentItemList",
      "allItems",
      "idsList",
      "collections",
      "getDocumentById",
      "getSearchHistoryResult",
    ]),
    ...mapGetters("typologies", {
      glossary: "items",
    }),
    autocompleteList() {
      let arr = [];
      if (this.searchMethod === "name") {
        let position;
        this.idsList.forEach((element) => {
          let el = arr.find((el) => el.name === element.name);
          if (el === undefined) {
            position = 0;
            arr.forEach((item) => {
              if (item.name.localeCompare(element.name) === -1) {
                position++;
              }
            });
            arr.splice(position, 0, element);
          }
        });
        return arr;
      } else {
        this.idsList.forEach((item) => {
          arr.push({ id: item.id, name: item.id + ": " + item.name });
        });
        return arr;
      }
    },
    resultList() {
      let arr = [];
      if (this.$route.params.searchID !== undefined) {
        let historial = this.getSearchHistoryResult(
          this.$route.params.searchID
        );
        historial.listResult.forEach((el) => {
          arr.push(this.getDocumentById(el));
        });
      }
      return arr;
    },
  },
  methods: {
    ...mapMutations([
      "SET_LOADING",
      "SET_LOADING_OPACITY",
      "ADD_SEARCH_REQUEST",
    ]),
    ...mapActions([
      "getItemsByQuery",
      "getSingleItemByQuery",
      "addSearchRequest",
    ]),
    //...mapGetters(["getSearchHistoryResult"]),
    setResult(payload) {
      if (payload.doc === null || payload.doc === undefined) {
        this.searchedValue = payload.text;
        this.result.doc = null;
      } else {
        if (payload.text === payload.doc.name) {
          this.searchedValue = payload.doc.name;
          this.result.doc = payload.doc;
        } else {
          this.searchedValue = payload.text;
          this.result.doc = null;
        }
      }
    },
    getId(payload) {
      if (payload.doc === null || payload.doc === undefined) {
        this.searchId = payload.text;
        this.id = "";
      } else {
        if (payload.text === payload.doc.name) {
          this.searchId = payload.doc.id;
          this.id = payload.doc.id;
        } else {
          this.searchId = payload.text;
          this.id = "";
        }
      }
    },
    async search() {
      let q;
      let lista = [];
      if (this.searchMethod === "name") {
        this.lastName = this.result.doc.name;
        console.log(this.result.doc.name)
        q = query(
          collection(db, "ListaObjetos"),
          where("name", "==", this.result.doc.name)
        );
        this.SET_LOADING(true);
        this.SET_LOADING_OPACITY(false);
        lista = await this.getItemsByQuery(q);
        this.SET_LOADING(false);
      } else if (this.searchMethod === "id") {
        q = doc(db, "ListaObjetos", this.result.doc.id);
        this.SET_LOADING(true);
        this.SET_LOADING_OPACITY(false);
        lista.push(await this.getSingleItemByQuery(q));
        this.SET_LOADING(false);
      }
      let ids = [];
      lista.forEach((item) => {
        ids.push(item.id);
      });
      let search = await this.addSearchRequest({
        searchMethod: this.searchMethod,
        searchParams: this.result,
        listResult: ids,
      });
      this.$router.push("/collection/" + search);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/sass/variables";
@import "../assets/sass/buttons";
@import "../assets/sass/cards";

.home {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 70vh auto;
  grid-gap: 10px;
  width: 100vw;
  div.autocomplete-container {
    padding: 25px 13em 45px 3em;
    div {
      display: grid;
      grid-template-columns: auto 60px;
      grid-template-rows: 50px;
      border-radius: 50px;
      background-color: white;
      padding: 0px;
      overflow: visible;
      .radius-left {
        border-bottom-left-radius: 50px;
        border-top-left-radius: 50px;
      }
      .radius-right {
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
      }

      button {
        margin-block-end: 0px;
        margin-block-start: 0px;
        background-color: $theme;
        border: none;
        cursor: pointer;
        i.material-icons {
          vertical-align: middle;
          font-size: 35px;
          color: white;
        }
      }
      button:disabled {
        opacity: 0.5;
        cursor: no-drop;
      }
    }
  }
  .search {
    background-image: linear-gradient(
        to bottom,
        transparent,
        rgba(0, 0, 0, 0.75)
      ),
      url("../assets/media/home.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: grid;
    grid-template-rows: calc(100% - 220px) 50px 120px 50px;
    h1,
    h4 {
      position: relative;
      text-align: center;
      padding-left: 10%;
      padding-right: 10%;
      margin-block-end: 0px;
      margin-block-start: 0px;
    }
    div.search-options {
      align-content: center;
      input[type="radio"] {
        margin-block-start: 5px;
        margin-block-end: 5px;
        height: 20px;
        width: 20px;
        margin-right: 5px;
        display: inline-block;
        vertical-align: middle;
      }
      label {
        color: white;
        display: inline-block;
        margin-right: 30px;
        margin-block-end: 0px;
        margin-block-start: 0px;
        vertical-align: middle;
        margin-top: 0px;
        line-height: 20px;
      }
    }
    div {
      padding-left: 10%;
      padding-right: 10%;
      position: relative;
      h1 {
        text-align: center;
        display: block;
        width: 100%;
        bottom: 0%;
        left: 50%;
        margin-bottom: 0px;
        position: absolute;
        transform: translateX(-50%);
      }
    }
    h1 {
      color: $theme-l4;
      vertical-align: text-bottom;
    }
    h4 {
      color: wheat;
    }
  }
  .section {
    display: block;
    border-radius: 15px;
    background-color: white;
    margin-right: 5%;
    margin-left: 5%;
    padding: 10px 2em;
    .show-selector {
      width: 100%;
      position: relative;
      div {
        width: fit-content;
        margin-right: 0px;
        margin-left: auto;
        label {
          width: fit-content;
          margin-right: 5px;
        }
        select {
          width: 75px;
        }
      }
    }
    &.side-menu {
      display: grid;
      grid-template-columns: 100%;
      grid-template-rows: auto auto;
      grid-gap: 10px;
      background-color: transparent;
      padding: 0%;
      grid-template-areas:
        "filter"
        "objects";
    }
    .filter {
      grid-area: filter;
    }
    .objects {
      grid-area: objects;
      background-color: white;
      padding: 5px 1em;
      border-radius: 10px;
    }
  }
}
@media (min-width: 768px) {
  .home {
    .section {
      .search-container {
        width: 70%;
      }
    }
    .tabs {
      padding: 15px 10%;
    }
    .section {
      margin: 15px 2.5em;
      &.side-menu {
        grid-template-columns: auto 260px;
        grid-template-rows: auto;
        grid-template-areas: "objects filter";
      }
    }
  }
}
@media (min-width: 992px) {
  .home {
    .section {
      .search-container {
        width: 60%;
      }
    }
    .tabs {
      padding: 15px 15%;
    }
    .section {
      &.side-menu {
        grid-template-columns: auto 280px;
      }
    }
  }
}
</style>
