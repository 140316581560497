<template>
  <div class="container">
    <h3 v-html="'Agregar Categor&iacute;a Principal'"></h3>
    <p
      class="full-length"
      v-html="'Complete los siguientes campos para agregar la nueva categor&iacute;a'"
    ></p>
    <div>
      <label v-html="'C&oacute;digo Identificador'"></label>
      <input name="id" v-model="id" />
      <label>Nombre</label>
      <input name="name" v-model="name" />
      <label v-html="'Definici&oacute;n'"></label>
      <textarea name="definition" v-model="definition" id="definition" rows="6"></textarea>
    </div>
    <p>
      <button
        class="add-btn"
        :disabled="!(name.length>3&&definition.length>10)"
        @click="addConcept"
      >Agregar</button>
    </p>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import app from "@/firebase";
import {
  getFirestore,
  setDoc,
  doc,
} from "firebase/firestore";
const db = getFirestore(app);
export default {
  name: "Add-Category",
  data: function() {
    return {
      name: "",
      id: "",
      definition: ""
    };
  },
  props: ["concepts"],
  computed: {
    ...mapGetters(["categories"])
  },
  methods: {
    ...mapActions(["getLastIndex"]),
    ...mapMutations([
      "SET_LOADING",
      'SET_LOADING_OPACITY',
      "SET_ERROR_MESSAGE",
      "SET_SUCCESS_MESSAGE_PARAMETERS",
      "SET_MESSAGE_PARAMETERS"
    ]),
    async addConcept() {
      var arr = this.concepts.filter(
        item => item.name.toLowerCase() === this.name.toLowerCase()
      );
      var i = this.categories.filter(item => item.id === this.id);
      if (arr.length > 0) {
        this.SET_MESSAGE_PARAMETERS({
            isVisible: true,
            type: "error",
            icon: "error",
            title: "Concepto ya existe",
            message:"Este concepto ya est&aacute; registrado en la base de datos, ingrese uno diferente.",
            buttonAction: "",
            button: "Aceptar",
            respondTo: "error",
            action: 0,
          });
      } else if (i.length > 0) {
        this.SET_MESSAGE_PARAMETERS({
            isVisible: true,
            type: "error",
            icon: "error",
            title: "C&oacute;digo identificador ya existe",
          message:
            "Ya se ha asignado este C&oacute;digo Identificador a otra categor&iacute:a. Ingrese un c&oacute;digo distinto.",
          buttonAction: "",
            button: "Aceptar",
            respondTo: "error",
            action: 0,
          });
          
      } else {
        this.SET_LOADING(true);
        this.SET_LOADING_OPACITY(true)
        try{
        await setDoc(doc(db,"Categoria",this.id),{
            definition: this.definition,
            name: this.name,
            lastValue: 1
          })
            this.SET_SUCCESS_MESSAGE_PARAMETERS({
              isVisible: true,
              title: "Categor&iacute;a Agregada",
              message: "La nueva categor&iacute;a se ha agregado exitosamente.",
              button: "Acepar"
            });
            this.SET_LOADING(false);
          
          }catch(error) {
            this.SET_ERROR_MESSAGE({
              state: true,
              title: "Ocurri&oacute; un error",
              text:
                "Ocurri&oacute; un error, no se pudo agregar la categor&iacute;a. Intente nuevamente m&aacute;s tarde.",
              button: "Acepar"
            });
            console.error("Error writing document: ", error);
          }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../assets/sass/variables";
div.container {
  width: 100%;
  padding: 0px;
  display: grid;
  grid-gap: 5px;
  grid-template-columns: auto;
  h2,
  h3,
  h4,
  div {
    label,
    input,
    select,
    textarea {
      width: 100%;
    }
  }
  h3 {
    margin-block-start: 0px;
    text-align: center;
  }
  p {
    text-align: center;
    .add-btn {
      margin-top: 5px;
      background-color: $theme;
      color: whitesmoke;
      border: none;
      font-weight: 400;
      cursor: pointer;
      border-radius: 10px;
      border: none;
      width: auto;
      font-size: 18px;
      line-height: 32px;
      padding: 8px 25px;
      vertical-align: middle;
    }
    button.add-btn:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}
</style>
