<template>
  <div class="profile-container">
    <div class="area">
      <div class="profile">
        <div class="picture">
          <div>
            <i class="material-icons">account_circle</i>
          </div>
        </div>
        <div class="title">
          <h3 class="center">Bienvenido: {{user.name}}</h3>
          <p class="center logout" v-html="'Cerrar Sesi&oacute;n'" @click="logout"></p>
        </div>
      </div>
      <div class="empty" @click="hide"></div>
      <div class="empty1" @click="hide"></div>
      <div class="empty2" @click="hide"></div>
      <div class="empty3" @click="hide"></div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import { mapMutations, mapGetters } from "vuex";
import { getAuth, signOut } from "firebase/auth";

const auth = getAuth();
export default {
  name: "profile-card",
  computed: {
    ...mapGetters(["user"])
  },
  methods: {
    ...mapMutations(["SET_PROFILE_DISPLAYED","SET_USER"]),
    hide() {
      this.SET_PROFILE_DISPLAYED(false);
    },
    logout() {
      signOut(auth).then(() => {
          this.SET_PROFILE_DISPLAYED(false);
          if (this.$route.path !== "/") {
            this.$router.push({ name: "home" });
          }
          this.SET_USER(null);
        });
    }
  }
};
</script>
<style lang="scss" scoped>
@import "./../assets/sass/variables";
.profile-container {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0%;
  z-index: 6;
  .area {
    display: grid;
    position: relative;
    grid-template-columns: calc(100vw - 335px) 300px 35px;
    grid-template-rows: 60px 400px calc(100vh - 460px);
    grid-template-areas:
      "empty empty1 empty2"
      "empty profile empty2"
      "empty empty3 empty2";
    .profile {
      background-color: white;
      border-radius: 10px;
      -webkit-box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.2),
        0 8px 20px 0 rgba(0, 0, 0, 0.19);
      -moz-box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.2),
        0 8px 20px 0 rgba(0, 0, 0, 0.19);
      box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.2),
        0 8px 20px 0 rgba(0, 0, 0, 0.19);
      padding: 8px;
      width: auto;
      position: relative;
      grid-area: profile;
      display: grid;
      grid-template-columns: auto;
      grid-template-rows: 200px auto auto;
      .picture {
        i.material-icons {
          font-size: 140px;
          width: 100%;
          height: 200px;
          vertical-align: middle;
          color: $text;
          text-align: center;
        }
      }
      .title {
        .center {
          text-align: center;
        }
        .logout{
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
    .empty,
    .empty1,
    .empty2,
    .empty3 {
      width: 100%;
      background-color: transparent;
      height: 100%;
    }
    div.empty {
      grid-area: empty;
    }
    div.empty1 {
      grid-area: empty1;
    }
    div.empty2 {
      grid-area: empty2;
    }
    div.empty3 {
      grid-area: empty3;
    }
  }
}
</style>