<template>
  <div>
    <div class="info-card" id="card-info">
      <div class="image">
        <div
          class="img"
          v-if="currentItem && currentItem.referenceImage"
          :style="{
            'background-image': 'url(' + currentItem.referenceImage.url + ')',
          }"
        ></div>
        <i class="material-icons" v-else>image</i>
      </div>
      <div class="header">
        <h1>Ficha del Objeto</h1>
      </div>
      <div class="id-card" v-if="currentItem">
        <h3
          v-html="currentItem.name"
          v-if="currentItem && currentItem.name"
        ></h3>
        <p v-if="currentItem !== null">
          <strong v-html="'C&oacute;digo: '"></strong>
          {{ $route.params.id }}
        </p>
        <p class="inline">
          <strong v-html="'Categor&iacute;as: '"></strong>
        </p>
        <p
          class="inline"
          v-for="(item, index) in currentItem.clasification"
          :key="item.id"
        >
          {{ item.name }}
          <span v-if="index < currentItem.clasification.length - 1">-</span>
        </p>
        <p v-if="currentItem !== null">
          <strong v-html="'Registrador: '"></strong>
          {{ currentItem.creator }}
        </p>
      </div>
    </div>
    <!--<div class="info-card">
      <div
        class="info-image"
        v-bind:style="[(currentItem&&currentItem.referenceImage) ? {'background-image':'url('+currentItem.referenceImage.url+')'} : {'background-color':'black'}]"
      ></div>
      <div class="info">
        <div>
          <div>
            <h3 v-html="currentItem.name" v-if="currentItem!==null"></h3>
            <p
              v-html="'<strong>C&oacute;digo:&nbsp;</strong>'+$route.params.id"
              v-if="currentItem!==null"
            ></p>
            <div class="category-section">
              <div v-if="currentItem!==null">
                <p>
                  <strong v-html="'Categor&iacute;as: '"></strong>
                </p>
                <p v-for="item in currentItem.clasification" :key="item.id">{{item.name}} -</p>
              </div>
            </div>
            <p
              v-html="'<strong>Registrador:&nbsp;</strong>'+currentItem.creator"
              v-if="currentItem!==null"
            ></p>
          </div>
        </div>
      </div>
      <div class="info-whitespace">p</div>
    </div>-->

    <div class="container">
      <div class="edit-options" >
        <div >
          <div class="left" v-show="user !== null">
            <p>Publicado:</p>
            <div class="onoffswitch">
              <input
                type="checkbox"
                name="onoffswitch"
                class="onoffswitch-checkbox"
                id="myonoffswitch"
                tabindex="0"
                v-model="publicated"
                @click="togglePublished"
              />
              <label class="onoffswitch-label" for="myonoffswitch">
                <span class="onoffswitch-inner"></span>
                <span class="onoffswitch-switch"></span>
              </label>
            </div>
          </div>
          <div class="left" v-show="user !== null">
            <p v-html="'Modo de Edici&oacute;n:'"></p>
            <div class="onoffswitch">
              <input
                type="checkbox"
                name="onoffswitch"
                class="onoffswitch-checkbox"
                id="edit"
                tabindex="1"
                @click="
                  $route.params.edit === undefined && user !== null
                    ? $router.push('/item/' + $route.params.id + '/edit')
                    : $router.push('/item/' + $route.params.id + '/')
                "
              />
              <label class="onoffswitch-label" for="edit">
                <span class="onoffswitch-inner"></span>
                <span class="onoffswitch-switch"></span>
              </label>
            </div>
          </div>
        </div>

        <div class="right">
          <p class="icon-container full-size">
            <button
              class="icon-to-label-btn"
              @click="print"
              style="display: inline-block"
            >
              <div class="inner">
                <p>descargar</p>
                <i class="material-icons">download</i>
              </div>
            </button>
          </p>
        </div>
      </div>

      <EditObjectInfo v-show="$route.params.edit === 'edit'" />
      <DisplayInfo v-show="$route.params.edit === undefined" />
    </div>
    <Message />
    <Loading text="Cargando..." />
    <Gallery />
    <div ref="pdf"></div>
  </div>
</template>
<script>
// @ is an alias to /src
import { mapActions, mapGetters, mapMutations } from "vuex";
import EditObjectInfo from "@/components/EditObject.vue"; //EditInfo
import DisplayInfo from "@/components/DisplayInfo.vue";
import Message from "@/components/Message.vue";
import Loading from "@/components/Loading.vue";
import Gallery from "@/components/Gallery.vue";
//import domtoimage from "dom-to-image-more";
/*import pdfMake from "pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import htmlToPdfmake from "html-to-pdfmake";*/
import app from "@/firebase";
import {
  writeBatch,
  arrayUnion,
  arrayRemove,
  getFirestore,
  doc
} from "firebase/firestore";
const db = getFirestore(app);
export default {
  name: "Edit-Object",
  components: {
    EditObjectInfo,
    Message,
    DisplayInfo,
    Loading,
    Gallery,
  },
  data() {
    return {
      publicated: "",
    };
  },
  computed: {
    ...mapGetters([
      "currentItem",
      "user",
      "getMessageAction",
      "getConjuntoObjetos",
      "getItem"
    ]),
    ...mapGetters("sitios", ["getSiteById", "getRoomById"]),

    ...mapGetters("materials", { materials: "items", materialName: "name" }),
    ...mapGetters("techniques", { techniques: "items", techniqueName: "name" }),
    ...mapGetters("keywords", { keywords: "items", keywordName: "name" }),
    getArrayImages() {
      let arr = [];
      if (this.currentItem && this.currentItem.referenceImage) {
        arr.push(this.currentItem.referenceImage);
      }
      if (this.currentItem && this.currentItem.images) {
        this.currentItem.images.forEach((item) => {
          arr.push(item);
        });
      }
      return arr;
    },
    categorias() {
      let text = "";
      this.currentItem.clasification.forEach((item) => {
        if (text === "") {
          text = item.name;
        } else {
          text = text + " - " + item.name;
        }
      });
      return text;
    },
    ubicacion() {
      if (this.currentItem.exhibition !== "") {
        let text = {};
        text.title = "Ubicación actual:";
        text.value = "";
        if (this.currentItem.exhibition === "No") {
          text.value = "Depósito";
          if (this.user) {
            if (this.currentItem.location.label === "Deposito") {
              text.value = text.value + ", " + this.currentItem.location.value;
            } else {
              text.value =
                text.value + ", Caja: " + this.currentItem.location.value;
            }
          }
        } else {
          text.value = "En Exhibición";
          if (this.user) {
            let siteId = "";
            if (this.currentItem.location) {
              this.currentItem.location.forEach((item) => {
                if (item.label === "Sitio") {
                  siteId = item.value;
                  text.value =
                    text.value +
                    ", Sitio: " +
                    this.getSiteById(item.value).name;
                } else {
                  text.value =
                    text.value +
                    ", en la Habitación: " +
                    this.getRoomById({ siteId: siteId, roomId: item.value })
                      .name;
                }
              });
            }
          } else {
            if (this.currentItem.location) {
              this.currentItem.location.forEach((item) => {
                if (item.label === "Sitio") {
                  text.value =
                    text.value +
                    ", Sitio: " +
                    this.getSiteById(item.value).name;
                }
              });
            }
          }
        }
        return text;
      } else {
        return "";
      }
    },
  },
  watch: {
    currentItem(update) {
      if(update){
      if (update.published) {
        this.publicated = update.published;
      }}
    },
    getMessageAction(payload) {
      if (payload.component === "alertStatusChange") {
        if (payload.action === 1) {
          if (this.publicated !== this.currentItem.published) {
            this.SET_LOADING_TEXT("Cargando...");
            this.SET_LOADING_OPACITY(true);
            this.SET_LOADING(true);
            let docu = doc(db, "ListaObjetos", this.currentItem.id);
            let arr = doc(db, "ListaObjetos", "published");
            var batch = writeBatch(db);
            batch.update(docu, {
              published: this.publicated,
            });
            if (this.publicated) {
              batch.update(arr, {
                list: arrayUnion(this.currentItem.id),
              });
            } else {
              batch.update(arr, {
                list: arrayRemove(this.currentItem.id),
              });
            }
            batch.commit().then(() => {
              this.SET_ITEM_STATUS({
                id: this.currentItem.id,
                value: this.publicated,
              });
              this.SET_LOADING(false);
              let mess =
                "El estado de la ficha se ha actualizado correctamente, ";
              if (this.publicated) {
                mess +=
                  "la ficha est&aacute; disponible para todo p&uacute;blico.";
              } else {
                mess += "la ficha est&aacute; oculta  para el p&uacute;blico.";
              }
              this.SET_MESSAGE_PARAMETERS({
                isVisible: true,
                type: "success",
                icon: "done",
                title: "Estado de Ficha Actualizada",
                message: mess,
                buttonAction: "",
                button: "Aceptar",
                respondTo: "successUpdateObject",
                action: 0,
              });
            });
          }
        } else if (payload.action === -1) {
          this.newKeyWord = { id: "", name: "", definition: "" };
          this.SET_MESSAGE_PARAMETERS({});
        }
      }
    },
  },
  mounted() {
    if (this.currentItem) {
      this.publicated = this.currentItem.published;
    }
  },
  methods: {
    ...mapMutations([
      "SET_CURRENT_OBJECT_ID",
      "SET_MESSAGE_PARAMETERS",
      "SET_LOADING",
      "SET_LOADING_OPACITY",
      "SET_LOADING_TEXT",
      "SET_ITEM_STATUS",
      "SET_OBJETO",
      "SET_MAIN_IMAGE",
      "PRINT",
      "PUSH_IMAGE",
      "SET_CONJUNTO",
      "SET_ICON",
      "SET_RELATED",
    ]),
    ...mapActions(["imprimirObjeto"]),
    async print() {
      this.SET_LOADING_TEXT("Generando PDF...");
      this.SET_LOADING(true);
      this.SET_OBJETO(this.currentItem);
      let img = await this.loadImage(this.currentItem.referenceImage.url);
      //let icon =await this.loadImage("../assets/media/photo.png");
      //this.SET_ICON(icon)
      this.SET_MAIN_IMAGE(img);
      let result = await this.loadPhotos(this.currentItem.images);
      if (this.currentItem.compuesto) {
        let conjunto = this.getConjuntoObjetos(this.currentItem.id);
        let arr = [];
        let i = 0;
        while (i < conjunto.length) {
          let images = await this.loadPhotosConjunto(conjunto[i].data.photos);
          let principal = await this.loadImage(conjunto[i].data.mainImage.url);
          conjunto[i].data.images = images;
          conjunto[i].data.referencia = principal;
          conjunto[i].data.id = conjunto[i].id;
          conjunto[i].data.techniques=this.getTecnicas(conjunto[i].data.techniques)
          conjunto[i].data.materials=this.getMateriales(conjunto[i].data.materials)
          arr.push(conjunto[i].data);
          i++;
        }
        this.SET_CONJUNTO(arr);
      }
      if(this.currentItem.relatedObjects.length>0){
        let related=[]
        let i=0
        while(i<this.currentItem.relatedObjects.length){
          let actual=this.getItem(this.currentItem.relatedObjects[i].id)
          let rImg=await this.loadImage(actual.referenceImage.url)
          related.push({img:rImg,name:actual.name,id:this.currentItem.relatedObjects[i].id,relation:this.currentItem.relatedObjects[i].relation})
          i++
        }
        this.SET_RELATED(related)
      }
      if (result) {
        this.imprimirObjeto();
        this.PRINT();

        this.SET_LOADING_TEXT("Cargando...");
        this.SET_LOADING(false);
      } else {
        console.log("problema");
      }
    },
    getTecnicas(payload){
      let text=""
      payload.forEach((elem,index)=>{
        text=text+this.techniqueName(elem)
        if(index<payload.length-1){
          text=text+", "
        }
      })
      return text
    },
    getMateriales(payload){
      let text=""
      payload.forEach((elem,index)=>{
        text=text+this.materialName(elem)
        if(index<payload.length-1){
          text=text+", "
        }
      })
      return text
    },
    displayAddModule() {
      this.addModuleVisible = true;
    },
    setSelected(val) {
      this.addSelectedTab = val;
    },
    closeModule() {
      this.addModuleVisible = false;
    },
    async loadImage(payload) {
      const options = {
        method: "GET",
      };

      let response = await fetch(payload, options);

      if (response.status === 200) {
        const imageBlob = await response.blob();
        const imageObjectURL = URL.createObjectURL(imageBlob);

        const image = document.createElement("img");
        image.src = imageObjectURL;
        return image;
      } else {
        console.log("HTTP-Error: " + response.status);
        return null;
      }
    },
    async loadPhotos(payload) {
      let img;
      let i = 0;
      while (i < payload.length) {
        let elem = payload[i];
        img = await this.loadImage(elem.url);
        this.PUSH_IMAGE({ name: elem.view, img: img });
        i++;
      }
      return true;
    },
    async loadPhotosConjunto(payload) {
      let img;
      let i = 0;
      let images = [];
      while (i < payload.length) {
        let elem = payload[i];
        img = await this.loadImage(elem.url);
        images.push({ name: elem.view, img: img });
        i++;
      }
      return images;
    },
    togglePublished() {
      let data;
      if (this.publicated) {
        data = {
          title: "Cambiar estado de Publicaci&oacute;n",
          message:
            "Al cambiar el estado de esta ficha ya no estar&aacute; visible para todo p&uacute;blico, &iquest;Confirma que desea cambiar su estado?",
        };
      } else {
        data = {
          title: "Cambiar estado de Publicaci&oacute;n",
          message:
            "Cambiar el estado de esta ficha har&aacute; que est&eacute; visible para todo p&uacute;blico, &iquest;Confirma que desea cambiar su estado?",
        };
      }
      this.SET_MESSAGE_PARAMETERS({
        isVisible: true,
        type: "alert",
        icon: "warning",
        ...data,
        buttonAction: "Confirmar",
        button: "Cancelar",
        respondTo: "alertStatusChange",
        action: 0,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/sass/variables";
@import "./../assets/sass/buttons";
.container {
  padding: 10px calc(5% + 10px) 10px 5%;
  position: relative;
  .edit-options {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 10px;
    .left {
      top: 0%;
      margin-top: 4.5px;
      p {
        display: inline;
        color: $text-dark;
        line-height: 30px;
        margin-block-end: 0;
        margin-block-start: 0;
        vertical-align: middle;
        top: 0%;
        margin-right: 10px;
      }
    }
  }
}
.info-card {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-rows: auto auto auto;
  grid-template-columns: auto;
  //background-color: #bbb9b9;
  background-color: $grey-1;
  //background-color: #e5e5e5;
  .header,
  .image,
  .id-card,
  .container {
    grid-column-start: 1;
    grid-column-end: 2;
  }
  .header {
    grid-row-start: 1;
    z-index: 2;
    grid-row-end: 2;
    margin-top: auto;
    margin-bottom: 0%;
    bottom: 0%;
    padding-left: 1rem;
    h1 {
      font-size: 2.5em;
      font-weight: 900;
      text-transform: uppercase;
      color: rgba($color: $orange-d1, $alpha: 0.9);
    }
  }
  .image {
    grid-row-start: 2;
    grid-row-end: 3;
    padding: 1rem;
    margin: 0;
    height: fit-content;
    overflow: hidden;
    div.img {
      width: 100%;
      border-radius: 8px;

      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      padding-top: 100%;
      margin: 0;
      //top: 50%;
      //transform: translateY(-50%);
    }
    i.material-icons {
      width: 100%;
      font-size: 15rem;
      text-align: center;
      color: rgba($color: $orange-d1, $alpha: 0.8);
      height: 100%;
    }
  }
  .id-card {
    grid-row-start: 3;
    grid-row-end: 4;
    padding: 0rem 1rem 1rem 1rem;
    h3 {
      color: rgba($color: $orange-d1, $alpha: 0.9);
      font-weight: bolder;
      letter-spacing: 1px;
    }
    p {
      font-weight: 400;
      margin-left: 3px;
      margin-block-end: 0px !important;
      margin-block-start: 0px !important;
      //color: white;
      color: $text-dark;
      line-height: 1.5em;
      strong {
        letter-spacing: 1px;
      }
    }
    p.inline {
      display: inline-block;
    }
  }
  .content {
    grid-row-start: 5;
    grid-row-end: 6;
    padding: 1em 1em;
    p {
      display: inline-block;
      margin-left: 3px;
      margin-block-end: 0px !important;
      margin-block-start: 0px !important;
      color: white;
      line-height: 1.5em;
      letter-spacing: 1px;
    }
  }
  .info {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
    position: relative;
    background-color: darkgreen;
    div {
      width: 100%;
      bottom: 0;
      margin-bottom: 0;
      position: absolute;
      height: fit-content;
      div {
        position: relative;

        h3,
        p {
          color: whitesmoke;
          border-block-end: 0px;
          border-block-start: 0px;
        }
        .category-section {
          width: 100%;
          height: fit-content;
          position: relative;
          div {
            p {
              display: inline-block;
              margin-left: 3px;
              margin-block-end: 0px !important;
              margin-block-start: 0px !important;
            }
            p:first-child {
              margin-left: 0px;
            }
          }
        }
      }
    }
  }
  button {
    background-color: transparent;
    border: solid 1px whitesmoke;
    border-radius: 20px;
    cursor: pointer;
    padding: 6px 0px;
    font-weight: 300;
    width: 100%;
    color: $text;
    i,
    p {
      color: whitesmoke;
    }
    i {
      font-size: 24px;
      margin-right: 8px;
    }
    br {
      display: block;
    }
  }
  button:hover,
  button.selected {
    border-color: $theme;
    i,
    p {
      color: $theme;
    }
  }
}
@media (min-width: 576px) {
  .container {
    .edit-options {
      grid-template-columns: 1fr 1fr;
    }
  }
}

@media (min-width: 768px) {
  .info-card {
    grid-template-rows: auto auto;
    grid-template-columns: repeat(2, 1fr);
    .header {
      grid-column-start: 1;
      grid-column-end: 2;
      padding-left: 3rem;
      h1 {
        font-size: 3rem;
        font-weight: bolder;
      }
    }
    .image {
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 3;
    }
    .id-card {
      grid-row-start: 2;
      grid-row-end: 3;
      padding-left: 3rem;
    }
  }
  .container {
    padding: 1rem 1rem;
  }
}
@media (min-width: 992px) {
  .info-card {
    grid-template-columns: 2fr 1.5fr;
    .id-card,
    .header {
      padding-left: calc(10vw + 2rem);
    }
  }
  .container {
    padding: 10px calc(10vw + 14px) 10px 10vw;
  }
}
</style>