import app from '@/firebase'
import {
  getFirestore,
  doc,
  getDoc,
} from "firebase/firestore";

const db = getFirestore(app)
import {
  getAuth,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  getIdToken
} from "firebase/auth";
const auth = getAuth();
export default {
  state: {
    user: null
  },
  actions: {
    async signUserIn({
      commit
    }, payload) {
      signInWithEmailAndPassword(auth, payload.email, payload.password)
        .then(async user => {
          const newUser = {
            id: user.user.uid
          }
          let elem = await getDoc(doc(db, 'Users', newUser.id))
          newUser.name = elem.data().name
          newUser.surname = elem.data().surname
          newUser.rol = elem.data().role
          commit('SET_USER', newUser)
          commit('SET_LOADING', false)
        })
        .catch(
          error => {
            let title = 'No se pudo iniciar sesion'
            var message = ''
            if (error.code === 'auth/wrong-password') {
              message = 'Usuario o contrase&ntilde;a inv&aacute;lidos'
            } else if (error.code === 'auth/user-not-found') {
              message = 'Usuario o contrase&ntilde;a inv&aacute;lidos'
            }
            this.SET_MESSAGE_PARAMETERS({
              isVisible: true,
              type: "error",
              icon: "error",
              title: title,
              message: message,
              buttonAction: "",
              button: "Aceptar",
              respondTo: "authError",
              action: 0,
            });
            commit('SET_LOADING', false)
          }
        )
    },
    async checkAuthChange({
      commit
    }) {
      onAuthStateChanged(auth, async user => {
        if (user) {
          const token = await getIdToken(user);
          auth.getAuth(token)

          //user.getIdToken().then(accessToken => {
          //  firebase.auth(accessToken)
          //})
          const newUser = {
            id: user.user.id
          }
          let elem = await getDoc(doc(db, 'Users', user.user.id))
          newUser.name = elem.data().name
          newUser.surname = elem.data().surname
          newUser.rol = elem.data().role

          commit('SET_USER', newUser)
        }
        return user
      })
    },
    async autoSignIn({
      commit
    }, payload) {
      const newUser = {
        id: payload.uid
      }
      let elem = await getDoc(doc(db, 'Users', newUser.id))
      newUser.name = elem.data().name
      newUser.surname = elem.data().surname
      newUser.rol = elem.data().role
      commit('SET_USER', newUser)
    },
  },
  getters: {
    user: state => {
      return state.user
    },
    isUserLoged(state) {
      return state.user !== null
    }
  },
  mutations: {
    SET_USER: function (store, payload) {
      store.user = payload
    },
  }
}