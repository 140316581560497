<template>
  <div class="home">
    <div class="search">
      <div>
        <h1 v-html="'LABORATORIO QU&Iacute;MICO'"></h1>
      </div>
      <h4 v-html="'Sector Industrial'"></h4>
    </div>
    <div class="section color1 split left">
      <div class="full-height"><div class="image" :style="{'background-image': 'url(https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F3%20laboratorio%2FLABORATORIO%20Y%20TALLER%20-16.jpg?alt=media&token=0a0b6705-aa93-416a-b097-f64a92b2515e&_gl=1*g8yh8a*_ga*NjAzNTQwNTkxLjE2ODU3MjcyNzM.*_ga_CW55HF8NVT*MTY4NjE4NzYzMS4xMi4xLjE2ODYxODg1MjQuMC4wLjA.)'}"></div></div>
      <div>
        <div class="content">
          <p v-html="'El laboratorio era un edificio que se divid&iacute;a en dos unidades: la chumacera y el laboratorio qu&iacute;mico. En la chumacera o sala de muestreo se preparaban las muestras del mineral, estandariz&aacute;ndolas para que, posteriormente, fueran analizadas en el laboratorio. '"></p>
<p v-html="'Todos los d&iacute;as se tomaban muestras de los minerales que llegaban a la planta de concentrado, siendo &eacute;stas extra&iacute;das durante las distintas etapas del proceso, de tal manera que se tomaban muestras del mineral bruto proveniente de la mina, del concentrado que iba a ser exportado y del material est&eacute;ril que se desechaba en el tranque de relave. '"></p>
<p v-html="'En la planta de concentrado se separaba el material est&eacute;ril del mineral. Era importante este proceso pues el mineral ten&iacute;a un valor comercial que el material est&eacute;ril no ten&iacute;a.  Por esta raz&oacute;n, una vez que ingresaba el mineral a la planta se obten&iacute;an dos subproductos: el concentrado, con alto porcentaje de zinc y plomo, que posteriormente se exportaba, y la “cola”, que se acumulaba en el tranque de relave. '"></p>
<p v-html="'Sin embargo, el proceso de separaci&oacute;n no era perfecto y en consecuencia exist&iacute;a la posibilidad de que un porcentaje de mineral se fuera al relave, significando una p&eacute;rdida econ&oacute;mica para la empresa. Debido a ello, era importante mantener el proceso siempre controlado, determinando que los porcentajes de mineral que se iban al relave (tanto de zinc como de plomo) fueran los m&iacute;nimos posibles y estuvieran dentro de los par&aacute;metros aceptados. La labor del laboratorio fue crucial dentro de la cadena productiva de la empresa porque determinaba cu&aacute;ndo y cu&aacute;nto hab&iacute;a que corregir el proceso en la planta de concentrado, manteniendo as&iacute; una productividad alta y constante. '"></p>
</div>
      </div>
    </div>
    <div class="section color2 split right">
      <div class="full">
        <div class="content img">
          <h1>El Proceso</h1>
          <p v-html="'Desde la mina se extra&iacute;an rocas que llegaban a la chumacera. Para ser analizadas, estas rocas deb&iacute;an reducirse a un polvo muy fino. Para este fin, se utilizaban una serie de molinos y morteros que lograban reducir el tama&ntilde;o de la roca hasta convertirla en polvillo, material que luego era colocado en sobres donde se escrib&iacute;a la procedencia y fecha en que se hab&iacute;a tomado la muestra. '"></p>
<p v-html="'Por otro lado, las muestras provenientes del concentrado o la “cola” ven&iacute;an h&uacute;medas y para reducir su porcentaje de humedad se val&iacute;an de secadores, tanto el&eacute;ctricos como a le&ntilde;a. '"></p>

          <img
            src="https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F3%20laboratorio%2Fsi%20LABORATORIO%20Y%20TALLER%20-19.jpg?alt=media&token=d1409416-f60e-40ed-b676-ef5692b5bf93&_gl=1*hq952o*_ga*NjAzNTQwNTkxLjE2ODU3MjcyNzM.*_ga_CW55HF8NVT*MTY4NjE4NzYzMS4xMi4xLjE2ODYxODg4ODkuMC4wLjA."
            alt=""
            class="right"
          />
          <p v-html="'En el laboratorio qu&iacute;mico se preparaban y analizaban las muestras de mineral que llegaban, tanto de la mina como de la planta de concentrado. En &eacute;l se determinaba qu&eacute; ley conten&iacute;an las muestras de plomo y zinc. Para esto se ocupaban distintos qu&iacute;micos y reactivos y el equipamiento necesario como probetas, pipetas, balanzas, embudos, microscopios, entre otros equipos. '"></p>
<p v-html="'En el centro del laboratorio hab&iacute;a una chimenea construida en madera, que cumpl&iacute;a una funci&oacute;n fundamental, ya que en ella se realizaban los an&aacute;lisis qu&iacute;micos que emit&iacute;an gases nocivos, que se evacuaban por el conducto de la chimenea hacia al exterior, sin perjudicar al personal que estaba trabajando en el laboratorio. '"></p>

        </div>
      </div>
    </div>
    <div class="section">
          <h1 v-html="'Fotograf&iacute;as'"></h1>
          <div class="gallery">
            <div
              class="item"
              v-for="(item, index) in images"
              :key="index"
              :style="'background-image:url(' + item.url + ')'"
            >
              <div class="hover" @click="openGallery(index)">
                <div>
                  <i class="material-icons">fullscreen</i>
                  <h5>{{ item.name }}</h5>
                </div>
              </div>
            </div>
        
      </div>
    </div>
    <Loading text="cargando" />
    <Message />
    <Gallery/>
  </div>
</template>
<script>
// @ is an alias to /src
import Loading from "@/components/Loading.vue";
import Message from "@/components/Message.vue";

import Gallery from "@/components/Gallery.vue";

import { mapMutations } from "vuex";
export default {
  name: "Home-page",
  components: {
    Loading,
    Message,
    Gallery
  },
  data: function () {
    return {
      col: [
        {
          link: "https://memoriaaustral.cl/",
          imagen: "../../../assets/media/puerto_cristal/MINA.JPG",
          title: "Mina",
          content:
            "Corporaci&oacute;n Cultural Memoria Austral nace en el a&ntilde;o 2013, como corporaci&oacute;n sin fines de lucro, con el objetivo de velar por el rescate y conservaci&oacute;n de la memoria de Ays&eacute;n, desde todos los &aacute;mbitos del patrimonio, favoreciendo la salvaguarda de la identidad regional.",
        },
        {
          link: "https://www.museoregionalaysen.gob.cl/",
          imagen: "../../assets/media/puerto_cristal/LABORATORIO.JPG",
          title: "Laboratorio",
          content:
            "El Museo Regional de Ays&eacute;n es una instituci&oacute;n perteneciente al Servicio Nacional del Patrimonio Cultural. Su apertura a la comunidad se produjo el a&ntilde;o 2018, luego de un largo trabajo de restauraci&oacute;n y habilitaci&oacute;n del Monumento Hist&oacute;rico Construcciones de la Sociedad Industrial de Ays&eacute;n y la construcci&oacute;n de un moderno edificio que alberga la Exposici&oacute;n Permanente Regional, Dep&oacute;sito de Colecciones, Auditorio y Oficinas de Trabajo.",
        },
        {
          link: "https://web.facebook.com/groups/310271558203",
          imagen:
            "https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Flogo_cristalinos.jpg?alt=media&token=e1e5fb66-43fb-4e98-8ddb-2717b54878f2",
          title: "Agrupaci&oacute;n Social y Cultural Los Cristalinos",
          content:
            "La Agrupaci&oacute;n Social y Cultural Los Cristalinos es una instituci&oacute;n que congrega a los antiguos habitantes del Campamento Minero Puerto Cristal, y que con su actuar, busca mantener viva su historia e identidad ligada a este Monumento Nacional.",
        },
      ],
      images: [{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F3%20laboratorio%2F008%20LABORATORIO%202.jpg?alt=media&token=44d1e151-47c6-469c-9661-1c6335cd212e&_gl=1*ji9s2y*_ga*MTIyNTY4ODAyOC4xNjg1NzMyODQy*_ga_CW55HF8NVT*MTY4NjE3MDA0OC4xMi4xLjE2ODYxNzAzNjYuMC4wLjA.',name:'Chumacera',position:0},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F3%20laboratorio%2F009%20LABORATORIO.jpg?alt=media&token=5c67d3c9-d4f7-4e9c-b219-b07cef68545e&_gl=1*15xa9lw*_ga*MTIyNTY4ODAyOC4xNjg1NzMyODQy*_ga_CW55HF8NVT*MTY4NjE3MDA0OC4xMi4xLjE2ODYxNzAxMjUuMC4wLjA.',name:'Chumacera',position:1},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F3%20laboratorio%2FLABORATORIO%20Y%20TALLER%20-34.jpg?alt=media&token=0506bc41-93de-49e9-b05e-86cadbc7009a&_gl=1*4d30qg*_ga*MTIyNTY4ODAyOC4xNjg1NzMyODQy*_ga_CW55HF8NVT*MTY4NjE3MDA0OC4xMi4xLjE2ODYxNzAyMDIuMC4wLjA.',name:'Chumacera',position:2},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F3%20laboratorio%2F007%20LABORATORIO%201.jpg?alt=media&token=b2db93d9-fcfc-4390-9fa9-21b548078240&_gl=1*1cm5rrx*_ga*MTIyNTY4ODAyOC4xNjg1NzMyODQy*_ga_CW55HF8NVT*MTY4NjE3MDA0OC4xMi4xLjE2ODYxNzAzNDEuMC4wLjA.',name:'Detalle de Toma de Muestras',position:3},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F3%20laboratorio%2FLABORATORIO%20Y%20TALLER%20-18.jpg?alt=media&token=e6d4dc7a-3e95-4819-8962-a7a61831e960&_gl=1*vgad3w*_ga*MTIyNTY4ODAyOC4xNjg1NzMyODQy*_ga_CW55HF8NVT*MTY4NjE3MDA0OC4xMi4xLjE2ODYxNzAxNjIuMC4wLjA.',name:'Equipamiento',position:4},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F3%20laboratorio%2FLABORATORIO%20Y%20TALLER%20-28.jpg?alt=media&token=c4783607-4a89-4eb5-b101-d486bf8510ed&_gl=1*1jnwnu8*_ga*MTIyNTY4ODAyOC4xNjg1NzMyODQy*_ga_CW55HF8NVT*MTY4NjE3MDA0OC4xMi4xLjE2ODYxNzAxOTcuMC4wLjA.',name:'Ingreso a Laboratorio',position:5},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F3%20laboratorio%2Fsi%20LABORATORIO%20Y%20TALLER%20-29.jpg?alt=media&token=4185173d-a1cf-4936-a018-ee5111177e69&_gl=1*1x53b8q*_ga*MTIyNTY4ODAyOC4xNjg1NzMyODQy*_ga_CW55HF8NVT*MTY4NjE3MDA0OC4xMi4xLjE2ODYxNzAyMTcuMC4wLjA.',name:'Materiales Laboratorio',position:6},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F3%20laboratorio%2FLABORATORIO%20Y%20TALLER%20-17.jpg?alt=media&token=d7bddb9b-58c7-4131-9acb-72417a214ae9&_gl=1*11oob9q*_ga*MTIyNTY4ODAyOC4xNjg1NzMyODQy*_ga_CW55HF8NVT*MTY4NjE3MDA0OC4xMi4xLjE2ODYxNzAxNzcuMC4wLjA.',name:'Mesón de Trabajo',position:7},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F3%20laboratorio%2Fsi%20LABORATORIO%20Y%20TALLER%20-19.jpg?alt=media&token=d1409416-f60e-40ed-b676-ef5692b5bf93&_gl=1*b4glu8*_ga*MTIyNTY4ODAyOC4xNjg1NzMyODQy*_ga_CW55HF8NVT*MTY4NjE3MDA0OC4xMi4xLjE2ODYxNzAyMDYuMC4wLjA.',name:'Muestra',position:8},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F3%20laboratorio%2FLABORATORIO%20Y%20TALLER%20-20.jpg?alt=media&token=6477528b-8327-447f-9662-7f32cc5cc7f4&_gl=1*2w5lry*_ga*MTIyNTY4ODAyOC4xNjg1NzMyODQy*_ga_CW55HF8NVT*MTY4NjE3MDA0OC4xMi4xLjE2ODYxNzAxODQuMC4wLjA.',name:'Muestras de Mineral',position:9},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F3%20laboratorio%2FLABORATORIO%20Y%20TALLER%20-23.jpg?alt=media&token=41048436-23cf-4760-932f-0e949b559fb4&_gl=1*16gnkr7*_ga*MTIyNTY4ODAyOC4xNjg1NzMyODQy*_ga_CW55HF8NVT*MTY4NjE3MDA0OC4xMi4xLjE2ODYxNzAxOTMuMC4wLjA.',name:'Vista al exterior',position:10},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F3%20laboratorio%2FLABORATORIO%20Y%20TALLER%20-16.jpg?alt=media&token=0a0b6705-aa93-416a-b097-f64a92b2515e&_gl=1*zp2n28*_ga*MTIyNTY4ODAyOC4xNjg1NzMyODQy*_ga_CW55HF8NVT*MTY4NjE3MDA0OC4xMi4xLjE2ODYxNzAxNTYuMC4wLjA.',name:'Vista al exterior',position:11},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F3%20laboratorio%2FLABORATORIO%20Y%20TALLER%20-05.jpg?alt=media&token=50dec076-7d01-4f1d-8c1d-b80d58d7a713&_gl=1*8e77rb*_ga*MTIyNTY4ODAyOC4xNjg1NzMyODQy*_ga_CW55HF8NVT*MTY4NjE3MDA0OC4xMi4xLjE2ODYxNzAxMzEuMC4wLjA.',name:'Vista Exterior del Laboratorio',position:12},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F3%20laboratorio%2FLABORATORIO%20Y%20TALLER%20-03.jpg?alt=media&token=ebf3bf39-7b0f-4451-b252-9540e15f69a1&_gl=1*1xodnvl*_ga*MTIyNTY4ODAyOC4xNjg1NzMyODQy*_ga_CW55HF8NVT*MTY4NjE3MDA0OC4xMi4xLjE2ODYxNzAxMjguMC4wLjA.',name:'Vista Exterior del Laboratorio',position:13},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F3%20laboratorio%2Fsi%20LABORATORIO%20Y%20TALLER%20-22.jpg?alt=media&token=539f8f8e-a925-4dc8-ba95-178a294cafc7&_gl=1*1g0ep8d*_ga*MTIyNTY4ODAyOC4xNjg1NzMyODQy*_ga_CW55HF8NVT*MTY4NjE3MDA0OC4xMi4xLjE2ODYxNzAyMTEuMC4wLjA.',name:'Vista Interior Laboratorio',position:14}
],
    };
  },
  created() {
    this.SET_LOADING(false);
  },
  methods: {
    ...mapMutations([
      "SET_LOADING",
      "SET_GALLERY",
      "SET_GALLERY_INDEX",
      "SET_GALLERY_ITEMS",
    ]),
    openGallery(payload) {
      let pos = this.images.findIndex((item) => item.position === payload);
      let el = this.images[pos];
      let arr = this.images;
      arr.splice(pos, 1);
      arr.splice(0, 0, el);
      this.SET_GALLERY_ITEMS(arr);
      this.SET_GALLERY_INDEX(payload);
      this.SET_GALLERY(true);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/sass/variables";
@import "../../assets/sass/buttons";
@import "../../assets/sass/cards";

.home {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 70vh auto;
  width: 100vw;
  div.autocomplete-container {
    padding: 25px 13em 45px 3em;
    div {
      display: grid;
      grid-template-columns: auto 60px;
      grid-template-rows: 50px;
      border-radius: 50px;
      background-color: white;
      padding: 0px;
      overflow: visible;
      .radius-left {
        border-bottom-left-radius: 50px;
        border-top-left-radius: 50px;
      }
      .radius-right {
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
      }

      button {
        margin-block-end: 0px;
        margin-block-start: 0px;
        background-color: $theme;
        border: none;
        cursor: pointer;
        i.material-icons {
          vertical-align: middle;
          font-size: 35px;
          color: white;
        }
      }
      button:disabled {
        opacity: 0.5;
        cursor: no-drop;
      }
    }
  }
  .search {
    background-image: linear-gradient(
        to bottom,
        transparent,
        rgba(0, 0, 0, 0.75)
      ),
      url("https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F3%20laboratorio%2FLABORATORIO%20Y%20TALLER%20-03.jpg?alt=media&token=ebf3bf39-7b0f-4451-b252-9540e15f69a1&_gl=1*rk5z2w*_ga*NjAzNTQwNTkxLjE2ODU3MjcyNzM.*_ga_CW55HF8NVT*MTY4NjE4NzYzMS4xMi4xLjE2ODYxODg1NjEuMC4wLjA.");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: grid;
    grid-template-rows: calc(100% - 220px) 50px 120px 50px;
    h1,
    h4 {
      position: relative;
      text-align: center;
      padding-left: 10%;
      padding-right: 10%;
      margin-block-end: 0px;
      margin-block-start: 0px;
    }
    div.search-options {
      align-content: center;
      input[type="radio"] {
        margin-block-start: 5px;
        margin-block-end: 5px;
        height: 20px;
        width: 20px;
        margin-right: 5px;
        display: inline-block;
        vertical-align: middle;
      }
      label {
        color: white;
        display: inline-block;
        margin-right: 30px;
        margin-block-end: 0px;
        margin-block-start: 0px;
        vertical-align: middle;
        margin-top: 0px;
        line-height: 20px;
      }
    }
    div {
      padding-left: 10%;
      padding-right: 10%;
      position: relative;
      h1 {
        text-align: center;
        display: block;
        width: 100%;
        bottom: 0%;
        left: 50%;
        margin-bottom: 0px;
        position: absolute;
        transform: translateX(-50%);
      }
    }
    h1 {
      font-size: 3em;
      font-weight: 700;
      color: $theme-l4;
      vertical-align: text-bottom;
    }
    h4 {
      font-size: 2em;
      color: wheat;
    }
  }
  .section {
    position: relative;
    padding: 0.5em 2em;
    display: grid;
    grid-template-rows: auto;
    gap: 1em;
    
      
    h1 {
            font-size: 3em;
            font-weight: 700;
            margin-block-start: 0;
            margin-block-end: 0;
          }
          p {
            font-size: 1.1em;
            font-weight: 300;
            text-align: justify;
          }
    &.color1 {
      background-color: $blue-grey;
      h1,
      h2,
      h3,
      h4,
      h5 {
        color: $grey-2;
      }
      p,
      li {
        color: $grey-1;
      }
    }
    &.color2 {
      background-color: $grey-1;
      h1,
      h2,
      h3,
      h4,
      h5 {
        color: $theme-d1;
      }
      p,
      li {
        color: $theme-d3;
      }
    }

    &.color3 {
      background-color: $theme-l4;
      h1,
      h2,
      h3,
      h4,
      h5 {
        color: $theme-d2;
      }
      p,
      li {
        color: $blue-grey-dark;
      }
    }
    &.split,
    &.full {
      height: 100%;
      div {
        &.full {
          grid-column-start: 1;
          grid-column-end: 3;
        }
        position: relative;
        height: 100% !important;
        display: block;
        .title,
        .content {
          width: 100%;
          display: block;
          height: fit-content;
          &.img {
            img.left {
              border-radius: 1em;
              width: 100%;
            }
            img.right {
              border-radius: 1em;
              width: 100%;
            }
          }
          &.multy {
            display: grid;
          }
          h1 {
            font-size: 3em;
            font-weight: 700;
            margin-block-start: 0;
            margin-block-end: 0;
          }
          p {
            font-size: 1.1em;
            font-weight: 300;
            text-align: justify;
          }
          
        }
        .image {
          border-radius: 1em;
          width: 100%;
          padding-top: 100%;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          &#img1 {
            background-image: url("https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F1%20mina%2FLastScan%20TYUI.jpg?alt=media&token=a22f582f-788c-48b4-b1b2-4a826a056bd4&_gl=1*anfvst*_ga*NjAzNTQwNTkxLjE2ODU3MjcyNzM.*_ga_CW55HF8NVT*MTY4NTg0MDU5My41LjEuMTY4NTg0MDYwMS4wLjAuMA..");
          }
          &#img2 {
            background-image: url("https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F1%20mina%2FMINA%20-40.jpg?alt=media&token=ade126b1-af45-4eb7-a212-3cf1e6d13b9f&_gl=1*lf7w9i*_ga*NjAzNTQwNTkxLjE2ODU3MjcyNzM.*_ga_CW55HF8NVT*MTY4NTkxNTczMy43LjEuMTY4NTkxNjU0OS4wLjAuMA..");
          }
          &#img3 {
            background-image: url("https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F1%20mina%2FLastScan%20106.jpg?alt=media&token=996f6d57-a11f-48b8-a920-405597c273dd&_gl=1*1gbuw9u*_ga*NjAzNTQwNTkxLjE2ODU3MjcyNzM.*_ga_CW55HF8NVT*MTY4NTkxNTczMy43LjEuMTY4NTkxNjgwMC4wLjAuMA..");
          }
        }
      }
    }
    .gallery {
            display: grid;
            grid-gap: 0.8em;
            grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
            position: relative;
            padding-bottom: 2em;
            .item {
              padding-top: 100%;
              background-size: cover;
              background-repeat: no-repeat;
              background-position: center;
              position: relative;
              border-radius: 5px;
              overflow: hidden;
              .hover {
                height: 100%;
                width: 100%;
                position: absolute;
                background-color: rgba($color: #000000, $alpha: 0.65);
                top: 0%;
                margin-top: 0%;
                opacity: 0;
                div {
                  width: 100%;
                  margin-top: calc(50% - 32px);
                  i.material-icons {
                    font-size: 35px;
                    color: whitesmoke;
                    text-align: center;
                    width: 100%;
                    vertical-align: middle;
                  }
                  h5 {
                    color: whitesmoke;
                    text-align: center;
                  }
                }
              }
              .hover:hover {
                cursor: pointer;
                opacity: 1;
              }
            }
          }
  }
}
@media (min-width: 768px) {
  .home {
    grid-template-rows: 75vh auto;
    .search {
      .video-container {
        video {
          min-height: 100%;
          min-width: 100%;
        }
      }
      .content {
        margin-left: 5em !important;
      }
    }
    .section {
      padding: 3em 5em;
      gap: 0.5em 2em;
      &.split {
        &.left {
          grid-template-columns: 1.5fr 2.5fr;
        }
        &.right {
          grid-template-columns: 2.5fr 1.5fr;
        }
        .content {
          &.img {
            img.left {
              border-radius: 1em;
              float: left;
              max-width: 40%;
              margin-right: 2em;
            }
            img.right {
              border-radius: 1em;
              float: right;
              max-width: 40%;
              margin-left: 2em;
            }
          }
        }
      }
    }
  }
}
@media (min-width: 992px) {
  .home {
    grid-template-rows: 75vh auto;
    .search {
      .video-container {
        video {
          min-height: 100%;
          min-width: 100%;
        }
      }
      .content {
        margin-left: 11em !important;
      }
    }
    .section {
      padding: 3em 13em;
      &.split {
        &.left {
          grid-template-columns: 1.5fr 2.5fr;
        }
        &.right {
          grid-template-columns: 2.5fr 1.5fr;
        }
      }
    }
  }
}
</style>
