<template>
  <div class="settings-container">
    <div class="section">
      <h2 v-html="'Exportar Registros'"></h2>
      <div>
        <div class="tabs">
          <div
            :class="{ active: exportSection === 'objeto' }"
            @click="exportSection = 'objeto'"
          >
            <h4>Exportar Objetos</h4>
          </div>
          <div
            :class="{ active: exportSection === 'archivo' }"
            @click="exportSection = 'archivo'"
          >
            <h4>Exportar Archivos</h4>
          </div>
        </div>
        <div class="form-section tab-module" v-if="exportSection === 'objeto'">
          <p
            v-html="
              'En esta secci&oacute;n podr&aacute;s filtrar y descargar objetos en formato excel'
            "
          ></p>
          <h4 v-html="'Filtros r&aacute;pidos'"></h4>
          <label v-html="'Selecciona uno de los siguientes filtros:'"></label>
          <div class="inputs large">
            <div class="questions">
              <div>
                <input
                  type="radio"
                  name="filter"
                  :value="'all'"
                  v-model="quickFilter"
                />
                <label v-html="'Toda la Colecci&oacute;n'"></label>
              </div>
              <div>
                <input
                  type="radio"
                  name="filter"
                  :value="'published'"
                  v-model="quickFilter"
                />
                <label v-html="'Objetos publicados'"></label>
              </div>
              <div>
                <input
                  type="radio"
                  name="filter"
                  :value="'unpublished'"
                  v-model="quickFilter"
                />
                <label v-html="'Objetos sin publicar'"></label>
              </div>
              <div>
                <input
                  type="radio"
                  name="filter"
                  :value="'compound'"
                  v-model="quickFilter"
                />
                <label v-html="'Conjuntos'"></label>
              </div>
              <div>
                <input
                  type="radio"
                  name="filter"
                  :value="'simpleObject'"
                  v-model="quickFilter"
                />
                <label v-html="'Objetos simples'"></label>
              </div>
              <div>
                <input
                  type="radio"
                  name="filter"
                  :value="'origin'"
                  v-model="quickFilter"
                />
                <label v-html="'Objetos por Origen'"></label>
              </div>
            </div>
          </div>
          <label
            class="label"
            v-html="'&iquest;En qu&eacute; lugar se hay&oacute; el objeto?'"
            v-show="quickFilter === 'origin'"
          ></label>
          <div class="inputs large">
            <div class="questions">
              <div v-if="quickFilter === 'origin'">
                <label v-html="'Sitio'" v-show="quickFilter !== ''"></label>
                <select name="site" id="site" v-model="location">
                  <option value="none" v-html="'Seleccione un sitio'"></option>
                  <option
                    v-for="site in getSitiosInExhibition"
                    :key="site.id"
                    :value="site.id"
                  >
                    {{ site.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="nav-buttons">
            <div class="left"></div>
            <div class="right">
              <p>
                <button
                  class="ok-btn right"
                  style="margin-right: 1em"
                  :disabled="quickFilter === 'none' || quickFilter === ''"
                  @click="filter1()"
                >
                  Filtrar
                </button>
              </p>
            </div>
          </div>
        </div>
        <div class="form-section tab-module" v-if="exportSection === 'archivo'">
          <p
            v-html="
              'En esta secci&oacute;n podr&aacute;s filtrar y descargar archivos en formato excel'
            "
          ></p>
          <h4 v-html="'Filtros r&aacute;pidos'"></h4>
          <label v-html="'Selecciona uno de los siguientes filtros:'"></label>
          <div class="inputs large">
            <div class="questions">
              <div>
                <input
                  type="radio"
                  name="archivefilter"
                  :value="'fondos'"
                  v-model="archiveFilter"
                />
                <label v-html="'Fondos'"></label>
              </div>
              <div>
                <input
                  type="radio"
                  name="archivefilter"
                  :value="'series'"
                  v-model="archiveFilter"
                />
                <label v-html="'Series'"></label>
              </div>
            </div>
          </div>
          <div class="nav-buttons">
            <div class="left"></div>
            <div class="right">
              <p>
                <button
                  class="ok-btn right"
                  style="margin-right: 1em"
                  :disabled="archiveFilter === 'none' || archiveFilter === ''"
                  @click="filter2()"
                >
                  Filtrar
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>
      <input type="file" id="archivoExcel" @change="subirFondos()" />
      <input type="file" id="seriesExcel" @change="subirSeries()" />
      <input type="file" id="documentosExcel" @change="subirDocumentos()" />
      <input
        type="file"
        id="documentosCompExcel"
        @change="subirDocumentosConjuntos()"
      />
      <button @click="uploadFondos()">Subir fondos</button>
      <button @click="uploadSeries()">Subir series</button>

      <button @click="uploadDocumentos()">Subir documentos</button>
      <button @click="uploadLista()">actualizar lista</button>
      <table>
        <tr>
          <th>imagen</th>
          <th>id</th>
        </tr>
        <tr v-for="item in result" :key="item.id">
          <td><img :src="item.imagen" alt="id" width="200" height="200" /></td>
          <td>{{ item.id }}</td>
        </tr>
      </table>
      <div class="form-section" v-if="result.length > 0">
        <h2 v-html="'Resultado de B&uacute;squeda'"></h2>
        <div class="nav-buttons">
          <div class="left"></div>
          <div class="right">
            <p>
              <button
                class="ok-btn right"
                style="margin-right: 1em"
                :disabled="quickFilter === 'none' || quickFilter === ''"
                @click="exportar()"
              >
                Exportar
              </button>
            </p>
          </div>
        </div>
        <div class="card-container-h">
          <div class="card-h" v-for="item in result" :key="item.id">
            <div
              class="img"
              :style="{
                'background-image': 'url(' + item.imagen + ')',
              }"
            ></div>
            <div class="data">
              <h5 v-html="item.nombre"></h5>
              <p><b>Id: </b>{{ item.id }}</p>
              <p>
                <b v-html="'Clasificaci&oacute;n: '"></b
                >{{ item.clasificacion }}
              </p>
            </div>
            <div
              class="full-content right"
              @click="id = $router.push('/item/' + item.id)"
            >
              <i class="material-icons icon">add</i>
              <div class="inner-content">
                <div class="action">
                  <i class="material-icons">assignment</i>
                  <p>Abrir Ficha del Objeto</p>
                </div>
              </div>
            </div>
          </div>
          <div v-if="result.length === 1"></div>
        </div>
      </div>
      <div class="form-section" v-if="archiveResult.length > 0">
        <h2 v-html="'Resultado de B&uacute;squeda'"></h2>
        <div class="nav-buttons">
          <div class="left"></div>
          <div class="right">
            <p>
              <button
                class="ok-btn right"
                style="margin-right: 1em"
                :disabled="archiveFilter === 'none' || archiveFilter === ''"
                @click="exportarArchivo()"
              >
                Exportar
              </button>
            </p>
          </div>
        </div>
        <div class="card-container-h">
          <div class="card-h" v-for="item in archiveResult" :key="item.id">
            <div
              class="img"
              :style="{
                'background-image': 'url(' + +')',
              }"
            ></div>
            <div class="data">
              <h5 v-html="item.titulo"></h5>
              <p><b>Id: </b>{{ item.id }}</p>
              <p><b v-html="'Productor: '"></b>{{ item.productor }}</p>
            </div>
          </div>
          <div v-if="result.length === 1"></div>
        </div>
      </div>
    </div>
    <SimpleBar v-if="false"></SimpleBar>
    <Loading text="cargando" />
    <Message />
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

//import EditDefinition from "@/components/EditDefinition.vue"
import { SimpleBar } from "simplebar-vue3";
import Message from "@/components/Message.vue";
import Loading from "@/components/Loading.vue";
import exportFromJson from "export-from-json";
import readXlsxFile from "read-excel-file";
import app from "@/firebase";
import { getFirestore, setDoc, doc } from "firebase/firestore";
const db = getFirestore(app);
export default {
  name: "Export-section",
  components: { SimpleBar, Message, Loading },
  data() {
    return {
      title: "Exportar datos de la Colecci&oacute;n",
      quickFilter: "none",
      archiveFilter: "none",
      result: [],
      location: "",
      archiveResult: [],
      columnDefs: [
        {
          field: "id",
        },
        {
          field: "nombre",
        },
        {
          field: "dimensiones",
        },
        {
          field: "notas:",
        },
        {
          field: "restauracion",
        },
        {
          field: "donante",
        },
        {
          field: "origen",
        },
        {
          field: "objetosRelacionados",
        },
        {
          field: "descripcion",
        },
        {
          field: "notasUbicacion",
        },
        {
          field: "registrador",
        },
        {
          field: "compuesto",
        },
        {
          field: "ubicacion",
        },
        {
          field: "encontradoEn",
        },
        {
          field: "tecnicas",
        },
        {
          field: "materiales",
        },
        {
          field: "estado",
        },
        {
          field: "clasificacion",
        },
        {
          field: "trabajoConservacion",
        },
        {
          field: "rotulado",
        },
        {
          field: "imagen",
        },
      ],
      columns: [
        {
          label: "Id",
          field: "id",
        },
        {
          label: "Nombre",
          field: "nombre",
        },
        {
          label: "Dimensiones",
          field: "dimension",
        },
        {
          label: "Notas:",
          field: "notas:",
        },
        {
          label: "Requiere Restauración",
          field: "restauracion",
        },
        {
          label: "Donante",
          field: "donante",
        },
        {
          label: "Origen",
          field: "origen",
        },
        {
          label: "Objetos Relacionados",
          field: "objetosRelacionados",
        },
        {
          label: "Descripción",
          field: "descripcion",
        },
        {
          label: "Notas de la Ubicación",
          field: "notasUbicacion",
        },
        {
          label: "Registrador",
          field: "registrador",
        },
        {
          label: "Es objeto Compuesto",
          field: "compuesto",
        },
        {
          label: "Ubicación",
          field: "ubicacion",
        },
        {
          label: "Lugar donde fue encontrado",
          field: "encontradoEn",
        },
        {
          label: "Técnicas",
          field: "tecnicas",
        },
        {
          label: "Materiales",
          field: "materiales",
        },
        {
          label: "Estado de conservación",
          field: "estado",
        },
        {
          label: "Clasificacion",
          field: "clasificacion",
        },
        {
          label: "Acciones de conservación preventiva",
          field: "trabajoConservacion",
        },
        {
          label: "Ubicación de rotulado",
          field: "rotulado",
        },
        {
          label: "Imagen",
          field: "imagen",
        },
      ],
      exportSection: "objeto",
      importado: [],
      series: [],
      documentos: [],
      lista: [],
    };
  },
  computed: {
    ...mapGetters("allItems"),
    ...mapGetters("materials", { materials: "items", materialName: "name" }),
    ...mapGetters("techniques", { techniques: "items", techniqueName: "name" }),
    ...mapGetters("keywords", { keywords: "items", keywordName: "name" }),
    ...mapGetters("sitios", [
      "getSiteById",
      "getRoomById",
      "getSitiosInExhibition",
    ]),
  },
  mounted() {},
  watch: {},
  methods: {
    ...mapGetters([
      "allItems",
      "itemsByPublishedStatus",
      "itemsByCompundStatus",
    ]),
    ...mapGetters("archive", {
      fondos: "fondos",
    }),
    ...mapActions([
      "getAllObjects",
      "getAllObjectsByPublishedStatus",
      "getAllObjectsByCompoundStatus",
      "setList",
    ]),
    ...mapMutations([
      "SET_MESSAGE_PARAMETERS",
      "SET_LOADING",
      "SET_LOADING_TEXT",
      "SET_MISSING_OBJECTS",
      "ORDER_ITEMS_BY",
      "SET_FILTER_VALUE",
    ]),

    ...mapActions("archive", {
      getFondos: "getFondos",
    }),
    async filter1() {
      let list;
      let response = [];
      this.SET_LOADING_TEXT("Cargando...");
      this.SET_LOADING(true);
      switch (this.quickFilter) {
        case "all":
          this.SET_MISSING_OBJECTS();
          response = await this.getAllObjects();
          console.log(response);
          this.ORDER_ITEMS_BY("id");
          this.result = [];
          list = this.allItems();
          break;
        case "published":
          this.SET_MISSING_OBJECTS();
          response = await this.getAllObjectsByPublishedStatus(true);
          console.log(response);
          this.result = [];
          this.ORDER_ITEMS_BY("id");
          this.SET_FILTER_VALUE(true);
          list = this.itemsByPublishedStatus();
          break;
        case "unpublished":
          this.SET_MISSING_OBJECTS();
          response = await this.getAllObjectsByPublishedStatus(false);
          console.log(response);
          this.result = [];
          this.ORDER_ITEMS_BY("id");
          this.SET_FILTER_VALUE(false);
          list = this.itemsByPublishedStatus();
          break;
        case "compound":
          this.SET_MISSING_OBJECTS();
          response = await this.getAllObjectsByCompoundStatus(true);
          console.log(response);
          this.result = [];
          this.ORDER_ITEMS_BY("id");
          this.SET_FILTER_VALUE(true);
          list = this.itemsByCompundStatus();
          break;
        case "simpleObject":
          this.SET_MISSING_OBJECTS();
          response = await this.getAllObjectsByCompoundStatus(false);
          console.log(response);
          this.result = [];
          this.ORDER_ITEMS_BY("id");
          this.SET_FILTER_VALUE(false);
          list = this.itemsByCompundStatus();
          break;
        case "systemData":
          this.SET_MISSING_OBJECTS();
          response = await this.getAllObjectsByCompoundStatus(false);
          console.log(response);
          this.result = [];
          this.ORDER_ITEMS_BY("id");
          this.SET_FILTER_VALUE(false);
          list = this.itemsByCompundStatus();
          break;
        default:
          break;
      }
      list.forEach((element) => {
        let img;
        console.log(element.id);
        if (element.referenceImage.url !== undefined) {
          img = element.referenceImage.url;
        } else {
          img = "";
        }
        let data = {
          id: element.id,
          nombre: element.name,
          dimensiones: this.getDimensiones(element.measurements),
          notas: element.others,
          restauracion: element.restoration,
          donante: element.donant,
          origen: element.origin,
          objetosRelacionados: this.getRelatedObjects(element.relatedObjects),
          descripcion: element.description,
          notasUbicacion: element.locationComments,
          registrador: element.creator,
          compuesto: this.isCompuesto(element.compuesto),
          ubicacion: this.ubicacion({
            exhibition: element.exhibition,
            location: element.location,
          }),
          encontradoEn: this.ubicacion({
            exhibition: "",
            location: element.found,
          }),
          tecnicas: this.getTecnicas(element.techniques),
          materiales: this.getMateriales(element.materials),
          estado: this.estado(element.pieceStatus),
          clasificacion: this.getClasificacion(element.clasification),
          trabajoConservacion: this.conservacionPreventiva(
            element.conservation
          ),
          rotulado: element.identifier,
          imagen: img,
          inventario: element.inventory,
          imagenes: this.getImages(element.images),
          publicado: element.published,
        };
        this.result.push(data);
      });
      this.SET_LOADING(false);
    },

    async getSystemData() {
      let data = [];
      this.SET_LOADING_TEXT("Cargando...");
      this.SET_LOADING(true);
      this.materials.forEach((element) => {
        console.log(element.id);
        let val = {
          tipo: "Material",
          id: element.id,
          nombre: element.name,
          definicion: element.definition,
          fuente: element.source,
        };
        data.push(val);
      });
      this.techniques.forEach((element) => {
        console.log(element.id);
        let val = {
          tipo: "Técnicas",
          id: element.id,
          nombre: element.name,
          definicion: element.definition,
          fuente: element.source,
        };
        data.push(val);
      });
      this.keywords.forEach((element) => {
        console.log(element.id);
        let val = {
          tipo: "Palabras Clave",
          id: element.id,
          nombre: element.name,
          definicion: element.definition,
          fuente: element.source,
        };
        data.push(val);
      });
      let fileName = "sistema";
      let exportType = exportFromJson.types.xls;
      exportFromJson({ data, fileName, exportType });
      this.SET_LOADING(false);
    },
    async filter2() {
      let list;
      let response = [];
      let arr;
      this.SET_LOADING_TEXT("Cargando...");
      this.SET_LOADING(true);
      switch (this.archiveFilter) {
        case "fondos":
          response = await this.getFondos();
          console.log(response);
          this.archiveResult = [];
          list = this.fondos();
          break;
        case "series":
          response = await this.getFondos();
          console.log(response);
          this.archiveResult = [];
          arr = this.fondos();
          list = [];
          arr.forEach((item) => {
            item.series.forEach((el) => {
              list.push({ ...el, id: item.id + "." + el.id });
            });
          });
          break;
        default:
          break;
      }
      list.forEach((element) => {
        let data = {
          id: element.id,
          volumen: element.volumen,
          ...this.archivePublicData({
            data: element.publicData,
            tipo: this.archiveFilter,
          }),
          ...this.archivePrivateData(element.privateData),
        };
        this.archiveResult.push(data);
      });
      this.SET_LOADING(false);
    },
    exportar() {
      let data = this.result;
      let fileName = "colecciones";
      let exportType = exportFromJson.types.xls;
      exportFromJson({ data, fileName, exportType });
    },
    exportarArchivo() {
      let data = this.archiveResult;
      let fileName = "archivo";
      let exportType = exportFromJson.types.xls;
      exportFromJson({ data, fileName, exportType });
    },
    getDimensiones(payload) {
      let text = "";
      payload.forEach((element, index) => {
        if (index < payload.length - 1) {
          text = text + element.dimension + ": " + element.measure + "\n";
        } else {
          text = text + element.dimension + ": " + element.measure;
        }
      });
      return text;
    },
    getRelatedObjects(payload) {
      let text = "";
      payload.forEach((element, index) => {
        if (index < payload.length - 1) {
          text = text + element.id + ": " + element.relation + "\n";
        } else {
          text = text + element.id + ": " + element.relation;
        }
      });
      return text;
    },
    isCompuesto(payload) {
      if (payload) {
        return "Si";
      } else {
        return "No";
      }
    },
    ubicacion(payload) {
      if (payload.exhibition !== "") {
        let text = {};
        text.title = "Ubicación actual:";
        text.value = "";
        if (payload.exhibition === "No") {
          text.value = "Depósito";
          if (this.user) {
            if (payload.location.label === "Deposito") {
              text.value = text.value + ", " + payload.location.value;
            } else {
              text.value = text.value + ", Caja: " + payload.location.value;
            }
          }
        } else {
          text.value = "En Exhibición";
          if (this.user) {
            let siteId = "";
            if (payload.location) {
              payload.location.forEach((item) => {
                if (item.label === "Sitio") {
                  siteId = item.value;
                  text.value =
                    text.value +
                    ", Sitio: " +
                    this.getSiteById(item.value).name;
                } else {
                  text.value =
                    text.value +
                    ", en la Habitación: " +
                    this.getRoomById({ siteId: siteId, roomId: item.value })
                      .name;
                }
              });
            }
          } else {
            if (payload.location) {
              payload.location.forEach((item) => {
                if (item.label === "Sitio") {
                  text.value =
                    text.value +
                    ", Sitio: " +
                    this.getSiteById(item.value).name;
                }
              });
            }
          }
        }
        return text.title + " " + text.value;
      } else {
        let text = "";
        if (payload.location) {
          console.log(payload.location);
          payload.location.forEach((item) => {
            if (item.label === "sitio") {
              if (
                item.value === "desconocido" ||
                item.value === "Desconocido"
              ) {
                text = "Desconocido";
              } else {
                text = this.getSiteById(item.value);
              }
            }
          });
        }
        return text;
      }
    },
    getTecnicas(payload) {
      let text = "";
      payload.forEach((elem, index) => {
        text = text + this.techniqueName(elem);
        if (index < payload.length - 1) {
          text = text + ", ";
        }
      });
      return text;
    },
    getImages(payload) {
      let text = "";
      payload.forEach((elem, index) => {
        text = text + elem.view + ": " + elem.url;
        if (index < payload.length - 1) {
          text = text + ",  ";
        }
      });
      return text;
    },
    getMateriales(payload) {
      let text = "";
      payload.forEach((elem, index) => {
        text = text + this.materialName(elem);
        if (index < payload.length - 1) {
          text = text + ", ";
        }
      });
      return text;
    },
    getPalabras(payload) {
      let text = "";
      payload.forEach((elem, index) => {
        text = text + this.keywordName(elem);
        if (index < payload.length - 1) {
          text = text + ", ";
        }
      });
      return text;
    },
    getClasificacion(payload) {
      let text = "";
      payload.forEach((element, index) => {
        if (index < payload.length - 1) {
          text = text + element.name + " - ";
        } else {
          text = text + element.name;
        }
      });
      return text;
    },
    estado(payload) {
      let text = payload.status;
      if (payload.comments !== "") {
        text = text + ", " + payload.comments;
      }
      return text;
    },
    conservacionPreventiva(payload) {
      let text = payload.requires;
      if (payload.comments !== "") {
        text = text + ", " + payload.comments;
      }
      return text;
    },
    archivePublicData(payload) {
      let a = {};
      switch (payload.tipo) {
        case "fondos":
          payload.data.forEach((elem) => {
            switch (elem.position) {
              case 1:
                a.titulo = elem.value;
                break;
              case 2:
                a.fechas = elem.value;
                break;
              case 3:
                a.productor = elem.value;
                break;
              case 4:
                a.historia = elem.value;
                break;
              case 5:
                a.alcanceContenido = elem.value;
                break;
              case 6:
                a.acceso = elem.value;
                break;
              case 7:
                a.lengua = elem.value;
                break;
              case 8:
                a.caracteristicasFisicas = elem.value;
                break;
              case 9:
                a.notas = elem.value;
                break;
              default:
                break;
            }
          });
          break;
        case "series":
          payload.data.forEach((elem) => {
            switch (elem.position) {
              case 1:
                a.titulo = elem.value;
                break;
              case 2:
                a.fechas = elem.value;
                break;
              case 3:
                a.productor = elem.value;
                break;
              case 4:
                a.alcanceContenido = elem.value;
                break;
              case 5:
                a.acceso = elem.value;
                break;
              case 6:
                a.lengua = elem.value;
                break;
              case 7:
                a.caracteristicasFisicas = elem.value;
                break;
              case 8:
                a.notas = elem.value;
                break;
              default:
                break;
            }
          });
          break;
        default:
          break;
      }

      return a;
    },
    archivePrivateData(payload) {
      let a = {};
      payload.forEach((elem) => {
        switch (elem.position) {
          case 1:
            a.ubicacionOriginales = elem.value;
            break;
          case 2:
            a.ubicacionCopias = elem.value;
            break;
          case 3:
            a.notasArchivista = elem.value;
            break;
          case 4:
            a.fechaDescripcion = elem.value;
            break;
          case 5:
            a.registrador = elem.value;
            break;
          default:
            break;
        }
      });
      return a;
    },
    printDocs() {
      this.documentos.forEach((doc) => {
        console.log(doc);
      });
    },
    async uploadFondos() {
      this.importado.forEach(async (item) => {
        await setDoc(doc(db, "archiveCollections/PCD/fondos", item.id), {
          publicData: item.data.publicData,
          privateData: item.data.privateData,
          nextIndex: item.data.nextIndex,
          Volumen: item.data.Volumen,
          published: item.data.published,
          series:item.data.series
        });
        console.log(item.id);
      });
    },
    async uploadSeries() {
      this.series.forEach(async (item) => {
        await setDoc(
          doc(
            db,
            "archiveCollections/PCD/fondos/" + item.fondo + "/series",
            item.id
          ),
          {
            publicData: item.data.publicData,
            privateData: item.data.privateData,
            nextIndex: item.data.nextIndex,
            Volumen: item.data.Volumen,
          }
        );
      });
    },
    async uploadDocumentos() {
      this.documentos.forEach(async (item) => {
        await setDoc(doc(db, "archive", item.id), {
          ...item.data
        });
        console.log(item.id)
      });
    },async uploadLista() {
        await setDoc(doc(db, "archive", "index"), {
          archiveList:this.lista
        });
    },
    subirFondos() {
      const input = document.getElementById("archivoExcel");
      readXlsxFile(input.files[0]).then((rows) => {
        rows.forEach((data) => {
          this.importado.push({
            id: data[0],
            data: {
              Volumen: data[3],
              nextIndex: data[16] + 1,
              publicData: [
                {
                  position: 1,
                  field: "Título",
                  value: data[1],
                },
                {
                  position: 2,
                  field: "Fechas",
                  value: data[2],
                },
                {
                  position: 3,
                  field: "Productor",
                  value: data[4],
                },
                {
                  position: 4,
                  field: "Historia Institucional",
                  value: data[5],
                },
                {
                  position: 5,
                  field: "Alcance y Contenido",
                  value: data[6],
                },
                {
                  position: 6,
                  field: "Condiciones de Acceso",
                  value: data[7],
                },
                {
                  position: 7,
                  field: "Lengua/Escritura del Documento",
                  value: data[8],
                },
                {
                  position: 8,
                  field: "Notas",
                  value: data[12],
                },
              ],
              privateData: [
                {
                  position: 1,
                  field: "Existencia y Localicación Documentos Originales",
                  value: data[10],
                },
                {
                  position: 2,
                  field: "Existencia y Localicación Documentos Copias",
                  value: data[11],
                },
                {
                  position: 3,
                  field: "Notas del Archivista",
                  value: data[13],
                },
                {
                  position: 4,
                  field: "Fecha de Descripción",
                  value: data[14],
                },
                {
                  position: 5,
                  field: "Nombre de quién Registra",
                  value: data[15],
                },
              ],
              published: true,
              series:[]
            },
          });
        });
      });
    },
    subirSeries() {
      const input = document.getElementById("seriesExcel");
      readXlsxFile(input.files[0]).then((rows) => {
        rows.forEach((data) => {
          let fondo=this.importado.find(item=>item.id===data[0])
          fondo.data.series.push({
            fondo: data[0],
            id: data[2].split(".")[1],
              Volumen: data[6],
              nextIndex: data[18] + 1,
              published:data[19],
              publicData: [
                {
                  position: 1,
                  field: "Título",
                  value: data[3],
                },
                {
                  position: 2,
                  field: "Fechas",
                  value: data[4],
                },
                {
                  position: 3,
                  field: "Productor",
                  value: data[7],
                },
                {
                  position: 4,
                  field: "Alcance y Contenido",
                  value: data[8],
                },
                {
                  position: 5,
                  field: "Condiciones de Acceso",
                  value: data[9],
                },
                {
                  position: 6,
                  field: "Lengua/Escritura del Documento",
                  value: data[10],
                },
                {
                  position: 7,
                  field: "Características Físicas",
                  value: data[11],
                },
                {
                  position: 8,
                  field: "Notas",
                  value: data[14],
                },
              ],
              privateData: [
                {
                  position: 1,
                  field: "Existencia y Localicación Documentos Originales",
                  value: data[12],
                },
                {
                  position: 2,
                  field: "Existencia y Localicación Documentos Copias",
                  value: data[13],
                },
                {
                  position: 3,
                  field: "Notas del Archivista",
                  value: data[15],
                },
                {
                  position: 4,
                  field: "Fecha de Descripción",
                  value: data[16],
                },
                {
                  position: 5,
                  field: "Nombre de quién Registra",
                  value: data[17],
                },
              ],
          });
        });
      });
      console.log(this.importado);
    },

    subirDocumentos() {
      const input = document.getElementById("documentosExcel");
      readXlsxFile(input.files[0]).then((rows) => {
        rows.forEach((data) => {
          this.documentos.push({
            id: data[4],
            data: {
              conjunto: [],
              Fondo: data[0],
              Serie: data[4].split(".")[1],
              imagen: data[24],
              link: data[25],
              published: data[26],
              publicData: [
                {
                  position: 1,
                  field: "Título",
                  value: data[7],
                },
                {
                  position: 2,
                  field: "Fecha",
                  value: data[27],
                },
                {
                  position: 3,
                  field: "Nivel de Descripción",
                  value: data[9],
                },
                {
                  position: 4,
                  field: "Formato",
                  value: data[10],
                },
                {
                  position: 5,
                  field: "Tipo de Documento",
                  value: data[11],
                },
                {
                  position: 6,
                  field: "Nombre del Productor",
                  value: data[12],
                },
                {
                  position: 7,
                  field: "Descripción de Contenido",
                  value: data[13],
                },
                {
                  position: 8,
                  field: "Nombres personales identificados en el Documento",
                  value: data[14] === "-" ? [] : data[14].split("/"),
                },
                {
                  position: 9,
                  field:
                    "Nombres de instituciones identificadas en el Documento",
                  value: data[15] === "-" ? [] : data[15].split("/"),
                },
                {
                  position: 10,
                  field: "Lengua/Escritura del Documento",
                  value: data[16],
                },
                {
                  position: 11,
                  field: "Estado de conservación",
                  value: data[17],
                },
                {
                  position: 12,
                  field: "Notas",
                  value: data[20],
                },
              ],
              privateData: [
                {
                  position: 1,
                  field: "Existencia y Localicación Documentos Originales",
                  value: data[18],
                },
                {
                  position: 2,
                  field: "Existencia y Localicación Documentos Copias",
                  value: data[19],
                },
                {
                  position: 3,
                  field: "Notas del Archivista",
                  value: data[21],
                },
                {
                  position: 4,
                  field: "Fecha de Descripción",
                  value: data[28],
                },
                {
                  position: 5,
                  field: "Nombre de quién Registra",
                  value: data[23],
                },
              ],
            },
          });
          this.lista.push({
            id: data[4],
            name: data[7],
          });
        });
      });
    },
    subirDocumentosConjuntos() {
      const input = document.getElementById("documentosCompExcel");
      readXlsxFile(input.files[0]).then((rows) => {
        rows.forEach((data) => {
          let docId = data[4].split(".");
          let item = this.documentos.find(
            (doc) => doc.id === docId[0] + "." + docId[1] + "." + docId[2]
          );
          item.data.conjunto.push({
            id: data[4],
            Fondo: data[0],
            Serie: data[4].split(".")[1],
            imagen: data[24],
            link: data[25],
            published: data[26],
            publicData: [
              {
                position: 1,
                field: "Título",
                value: data[7],
              },
              {
                position: 2,
                field: "Fecha",
                value: data[27],
              },
              {
                position: 3,
                field: "Nivel de Descripción",
                value: data[9],
              },
              {
                position: 4,
                field: "Formato",
                value: data[10],
              },
              {
                position: 5,
                field: "Tipo de Documento",
                value: data[11],
              },
              {
                position: 6,
                field: "Nombre del Productor",
                value: data[12],
              },
              {
                position: 7,
                field: "Descripción de Contenido",
                value: data[13],
              },
              {
                position: 8,
                field: "Nombres personales identificados en el Documento",
                value: data[14] === "-" ? [] : data[14].split("/"),
              },
              {
                position: 9,
                field: "Nombres de instituciones identificadas en el Documento",
                value: data[15] === "-" ? [] : data[15].split("/"),
              },
              {
                position: 10,
                field: "Lengua/Escritura del Documento",
                value: data[16],
              },
              {
                position: 11,
                field: "Estado de conservación",
                value: data[17],
              },
              {
                position: 12,
                field: "Notas",
                value: data[20],
              },
            ],
            privateData: [
              {
                position: 1,
                field: "Existencia y Localicación Documentos Originales",
                value: data[18],
              },
              {
                position: 2,
                field: "Existencia y Localicación Documentos Copias",
                value: data[19],
              },
              {
                position: 3,
                field: "Notas del Archivista",
                value: data[21],
              },
              {
                position: 4,
                field: "Fecha de Descripción",
                value: data[28],
              },
              {
                position: 5,
                field: "Nombre de quién Registra",
                value: data[23],
              },
            ],
          });
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./../../assets/sass/variables";
@import "./../../assets/sass/buttons";
@import "./../../assets/sass/modal";
@import "./../../assets/sass/ficha";
@import "./../../assets/sass/cards";

.settings-container {
  div.autocomplete-container {
    padding: 25px 5% 25px 10%;
    display: block !important;

    div {
      display: grid;
      grid-template-columns: auto 60px;
      grid-template-rows: 50px;
      border-radius: 50px;
      background-color: white;
      padding: 0px;
      overflow: visible;

      .radius-left {
        border-bottom-left-radius: 50px;
        border-top-left-radius: 50px;
      }

      .radius-right {
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
      }

      button {
        margin-block-end: 0px;
        margin-block-start: 0px;
        background-color: $theme;
        border: none;
        cursor: pointer;

        i.material-icons {
          vertical-align: middle;
          font-size: 35px;
          color: white;
        }
      }

      button:disabled {
        opacity: 0.5;
        cursor: no-drop;
      }
    }
  }

  .index-container {
    text-align: center;

    p {
      display: inline-block;
      color: $text-dark;
      vertical-align: middle;

      button {
        background-color: transparent;
        border: none;
        cursor: pointer;
        font-size: 1em;

        &:hover,
        &.selected {
          font-size: 1.5em;
          color: $theme-d1;
          font-weight: 600;
        }
      }
    }
  }

  h1 {
    font-size: 3.5em;
    margin-block-start: 0px;
    color: $theme-d2;
    margin-left: 1em;
  }

  .search {
    background-image: linear-gradient(
        to bottom,
        transparent,
        rgba(0, 0, 0, 0.75)
      ),
      url("../../assets/media/MAESTRANZA.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: grid;
    grid-template-rows: auto auto 15px;

    h1,
    h2 {
      position: relative;
      text-align: center;
      color: $theme-l4;
      padding-left: 10%;
      padding-right: 10%;
      margin-block-end: 0px;
      margin-block-start: 0px;
      padding-top: 15%;
    }

    div {
      display: grid;
      padding-left: 10%;
      padding-right: 10%;
      grid-template-columns: 1.5fr 0.25fr;

      div.options {
        .icon-container {
          padding-top: 25px;
          padding-bottom: 25px;

          button {
            background-color: $theme;
            color: white;
            border-radius: 50px;
          }
        }
      }
    }
  }

  .section {
    display: grid;
    grid-template-columns: 100%;
    row-gap: 0.5em;
    margin: 2em 5vh;
  }

  .modal {
    .nav,
    .form-section {
      box-shadow: none !important;
    }
  }

  .section,
  .modal {
    .tabs {
      width: calc(100% - 5px);
      background-color: transparent;
      height: 44.5px;
      margin-left: 5px;

      div {
        height: 100%;
        position: relative;
        float: left;
        width: fit-content;
        background-color: white;
        padding-left: 1em;
        padding-right: 1em;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        //border-top: solid 1px $text;
        border-top: solid 1px $theme-d2;
        border-left: solid 1px $theme-d2;
        border-right: solid 1px $theme-d2;
        cursor: pointer;
        h4 {
          color: $text;
        }
        &.active {
          background-color: $theme-d2;
          h4 {
            color: white;
          }
        }
      }
    }
    .nav {
      border-radius: 5px;
      background-color: white;
      padding: 15px;
      width: 100%;

      .nav-items {
        display: grid;
        width: fit-content;
        position: relative;
        margin: 0 auto;
        column-gap: 0.5em;
        width: 100%;
        min-width: 50%;

        &.sub-nav {
          grid-template-columns: repeat(3, 1fr);
        }

        &.main-nav {
          grid-template-columns: repeat(8, 1fr);
        }

        .items {
          text-align: center;
          border-bottom-color: $grey;
          border-bottom-style: solid;
          border-bottom-width: 5px;
          cursor: pointer;

          h4 {
            color: $grey;
          }

          &.selected,
          &.checked {
            border-bottom-color: $theme-d1;

            h4 {
              color: $theme-d1;
            }
          }

          &.selected,
          &:hover {
            h4 {
              font-weight: 700;
            }
          }
        }

        .item-title {
          padding-top: 1em;
          grid-row-start: 2;
          grid-row-end: 3;
          grid-column-start: 1;
          grid-column-end: 9;

          &.sub-nav {
            grid-column-end: 4;
          }

          align-content: center;

          h4 {
            font-weight: 500;
            color: $theme-d1;
            text-align: center;
          }
        }
      }
    }

    .form-section {
      border-radius: 5px;
      background-color: white;
      padding: 15px;
      border-radius: 5px;
      border-color: $theme-d2;
      &.tab-module {
        //border-top-left-radius: 0px;
        border: solid 1px $theme-d2;
      }
      .split-field {
        display: grid;
        gap: 8px;
        grid-template-columns: 100%;
      }

      .add-element {
        width: 100%;
        display: grid;
        height: 40px;
        grid-template-columns: auto 40px;
        grid-gap: 5px;

        .autocomplete-container {
          overflow: visible;
          margin-top: 5px;
          height: 30px;
        }
      }

      .tag {
        width: auto;
        float: left;
        position: relative;
        background-color: lightgray;
        border: none;
        padding: 3px 5px 3px 10px;
        border-radius: 50px;
        width: fit-content;
        margin-right: 3px;
        margin-bottom: 3px;

        p {
          line-height: 18px;
          vertical-align: middle;
          margin-block-end: 0px;
          margin-block-start: 0px;

          i.material-icons {
            vertical-align: middle;
            background-color: transparent;
            border-radius: 50px;
            padding: 3px;
            margin-left: 10px;
          }

          i:hover {
            background-color: white;
            cursor: pointer;
          }
        }
      }

      .split-60-40 {
        width: calc(100% - 8px);
        display: grid;
        grid-template-columns: 60% 40%;
        gap: 8px;

        &.fullsize {
          grid-template-columns: 100%;
        }
      }

      table {
        width: 100%;
        margin-top: 1em;
        border-spacing: 0;
        border-radius: 5px;

        th {
          background-color: $blue-grey;
          color: white;
          padding: 1em;
          font-weight: 500;
          font-size: 1.17em;
        }

        td {
          border-bottom: 1px solid $blue-grey;
          padding-top: 0.5em;
          padding-bottom: 0.5em;
          text-align: center;

          div.icon {
            background-color: $theme-d1;
            border-radius: 5px;

            i {
              padding: 0.5em;
              font-size: 2em;
              color: white;
            }
          }

          &.center {
            div {
              position: relative;
              background-color: white;
            }
          }

          p {
            display: inline;

            i {
              margin-left: 0.25em;
              margin-right: 0.25em;

              &:hover {
                color: $theme-d1;
                cursor: pointer;
              }
            }
          }

          img {
            margin-top: 0.5em;
            margin-bottom: 0.25em;
            max-width: 100px;
            height: auto;
            border-radius: 5px;
          }
        }
      }

      .file {
        display: none;
      }

      .left {
        margin-top: 4.5px;
        position: relative;

        label.label {
          display: inline;
          color: $text-dark;
          line-height: 30px;
          margin-block-end: 0;
          margin-block-start: 0;
          vertical-align: middle;
          top: 0%;
          margin-right: 1em;
        }
      }
    }

    .nav-buttons {
      display: grid;
      grid-template-columns: auto auto;
      margin-top: 1em;

      .left {
        float: left;
      }

      .right {
        float: right;
      }
    }

    .inputs {
      label,
      input,
      textarea {
        width: 100%;
      }
    }

    .picture-preview {
      height: 100vh;
      width: 100vw;
      position: fixed;
      top: 0;
      left: 0;
      margin: 0px;
      background-color: #22190880;
      z-index: 21;

      div.box {
        overflow-y: auto;
        top: 50%;
        left: 50%;
        width: 85%;
        border-radius: 10px;
        -webkit-box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.2),
          0 8px 20px 0 rgba(0, 0, 0, 0.19);
        -moz-box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.2),
          0 8px 20px 0 rgba(0, 0, 0, 0.19);
        box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.2),
          0 8px 20px 0 rgba(0, 0, 0, 0.19);
        margin-bottom: 10px;
        background-color: white;
        transform: translate(-50%, -50%) !important;
        position: absolute;
        max-height: 90vh;

        .box-content {
          width: 100%;
          height: 100%;
          display: grid;
          grid-template-rows: auto 25px 25px auto;
          grid-template-columns: 100%;
          grid-gap: 5px;

          div.image {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            grid-row-start: 1;
            grid-row-end: 3;
            grid-column-start: 1;
            grid-column-end: 2;
            padding-top: 80%;
          }

          div.icon {
            grid-row-start: 2;
            grid-row-end: 4;
            grid-column-start: 1;
            grid-column-end: 2;

            p {
              text-align: center;
              margin-block-start: 0px;
              margin-block-end: 0px;

              i.material-icons {
                background-color: $theme-l1;
                width: 50px;
                border-radius: 50px;
                height: 50px;
                color: whitesmoke;
                font-size: 35px;
                line-height: 50px;
                vertical-align: middle;

                -webkit-box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2),
                  0 4px 10px 0 rgba(0, 0, 0, 0.19);
                -moz-box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2),
                  0 4px 10px 0 rgba(0, 0, 0, 0.19);
                box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2),
                  0 4px 10px 0 rgba(0, 0, 0, 0.19);
              }
            }
          }

          div.text {
            padding: 0px 25px 12px 25px;
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 4;
            grid-row-end: 5;

            h4 {
              text-align: center;
            }

            p.btn {
              text-align: center;

              button:first-child {
                border-radius: 50px;
                color: whitesmoke;
                margin-right: 15px;
                background-color: $theme;
                text-decoration: none;
              }

              button {
                color: $text;
                background-color: white;
                text-decoration: underline;
                width: auto;
                padding: 12px 18px;
                height: auto;
                cursor: pointer;
                font-weight: 300;
                border: none;
                font-size: 18px;
              }
            }
          }

          &.input {
            grid-template-rows: auto;
          }
        }
      }
    }

    .inputs.large {
      margin-top: 0.75em;
      margin-bottom: 0.5em;
      height: fit-content;
      position: relative;

      .questions {
        display: grid;
        grid-gap: 5px;
        grid-template-columns: auto;

        div {
          input[type="radio"] {
            width: 15px !important;
            height: 15px !important;
            background-color: whitesmoke;
            margin-block-start: 0px;
            margin-block-end: 0px;
          }
        }

        .note {
          font-size: 14px;
          font-style: italic;
        }
      }
    }

    .text-link {
      div {
        p,
        span {
          display: inline;
          margin-left: 0.1em;
        }

        span {
          font-family: "Roboto", sans-serif;
          font-size: 1.1em;
          font-weight: 500;
          text-decoration: underline;
          color: $theme-d1;
          cursor: pointer;
        }
      }
    }

    .save-btn {
      width: 100%;
      text-align: center;

      button {
        padding: 12px 18px;
        border: none;
        width: auto !important;
        height: auto !important;
        font-size: 16px;
        font-weight: 300;
      }

      button.ok {
        color: whitesmoke;
        background-color: $theme;
        border-radius: 50px;
      }

      button.cancel {
        margin-left: 15px;
        background-color: transparent;
        color: $text;
        text-decoration: underline;
      }
    }
  }

  @media (min-width: 576px) {
    .section {
      .nav {
        .nav-items {
          width: auto;
        }
      }

      .gallery {
        grid-template-columns: 1fr;
      }

      .inputs.large {
        .questions {
          grid-template-columns: auto auto auto;
        }
      }
    }
  }

  @media (min-width: 768px) {
    .section {
      .form-section {
        .split-field {
          grid-template-columns: minmax(290px, 35%) auto;
        }

        .split-60-40 {
          &.fullsize {
            grid-template-columns: 40% 60%;
          }
        }

        table {
          td,
          th {
            padding-left: 1.5rem;
            padding-right: 1.5rem;

            img {
              max-width: 150px;
            }
          }
        }
      }

      .dimensions {
        grid-template-columns: 3fr 2fr 1fr 40px;

        .long {
          grid-column-end: 5;
        }

        .mid {
          grid-column-end: 2;
        }
      }

      .pictures {
        grid-template-columns: 1fr 1fr;

        .long {
          grid-column-start: 1;
          grid-column-end: 3;
        }
      }

      .picture-preview {
        div.box {
          width: 50%;
        }
      }

      .gallery {
        grid-template-columns: 1fr 1fr 1fr;
      }
    }
  }
}
</style>