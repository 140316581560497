import app from '@/firebase'
import {
  getFirestore,
  collection,
  getDocs,
  onSnapshot,
} from "firebase/firestore";

const db = getFirestore(app)

export default {
  state: {
    categories: [],
    collections: [],
  },
  actions: {
    async getCategories({
      commit
    }) {
      let qS = await getDocs(collection(db, 'Categoria'))
      qS.forEach(async elem => {
        commit('ADD_CATEGORY', {
          id: elem.id,
          name: elem.data().name,
          last: elem.data().lastValue,
          definition: elem.data().definition,
          subCategories: []
        })
        this.dispatch('getSubCategories', {
          ids: [elem.id],
          path: 'Categoria/'+elem.id
        })
      })

    },
    async getSubCategories({
      commit
    }, payload) {
      let qS=await getDocs(collection(db,payload.path+'/Categorias'))
        if (qS.size > 0) {
          qS.forEach(elem => {
            commit('ADD_SUBCATEGORY_TO_CATEGORY', {
              ids: payload.ids,
              doc: {
                id: elem.id,
                name: elem.data().name,
                definition: elem.data().definition,
                subCategories: []
              }
            })
            var arr = []
            payload.ids.forEach(id => {
              arr.push(id)
            })
            arr.push(elem.id)
            this.dispatch('getSubCategories', {
              ids: arr,
              path: payload.path+'/Categorias/'+elem.id
            })
          })
        }
      
    },
    async getCollectionData({
        commit
    }) {
        onSnapshot(collection(db, 'Colecciones'), qS => {
            qS.docChanges().forEach(change => {
                commit('ADD_COLLECTION', {
                    id: change.doc.id,
                    name: change.doc.data().name,
                    description: change.doc.data().description,
                    nextValue: change.doc.data().nextValue
                })

            })
        })
    },
    getStatistics({commit,rootGetters}){
      rootGetters.allItems.forEach(item=>{
        let arr=[]
        item.clasification.forEach(el=>{
          arr.push(el.id)
        })
        commit("SUM_ONE_TO_STATISTIC",arr)
      })
    }
  },
  getters: {
    categories: state => {
      return state.categories
    },
  },
  mutations: {
    ADD_CATEGORY: function (state, elem) {
      state.categories.push(
        elem
      )
    },
    ADD_SUBCATEGORY_TO_CATEGORY: function (state, payload) {
      var i = 0
      var a = state.categories.find(doc => doc.id === payload.ids[i])
      i++
      while (i < payload.ids.length) {
        a = a.subCategories.find(doc => doc.id === payload.ids[i])
        i++
      }
      a.subCategories.push(
        payload.doc
      )
    },
    ADD_COLLECTION(state, payload) {
        if (!state.collections.find(item => item.id === payload.id)) {
            state.collections.push(payload)
        }
    },
    SUM_ONE_TO_STATISTIC(state,payload){
      var i = 0
      var a = state.categories.find(doc => doc.id === payload[i])
      if(a.estadistica){
        a.estadistica=a.estadistica+1
      }else{
        a.estadistica=1
      }
      i++
      while (i < payload.length) {
        a = a.subCategories.find(doc => doc.id === payload[i])
        if(a.estadistica){
          a.estadistica=a.estadistica+1
        }else{
          a.estadistica=1
        }
        i++
      }
    },

  }
}