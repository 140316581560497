<template>
  <div class="home">
    <div class="search">
      <div>
        <h1 v-html="'Archivo Hist&oacute;rico de Puerto Cristal'"></h1>
      </div>
      <h4 v-html="'Museo de Puerto Cristal'"></h4>
    </div>
    <div class="section color1 split">
      <div>
        <div class="title">
      <h3 v-html="'Archivo'"></h3>
      <h3 v-html="'Hist&oacute;rico de'"></h3>
      <h1 v-html="'PUERTO CRISTAL'"></h1>
        </div>
      </div>
      <div>
        <div class="content">
          <p
        v-html="
          'Desde que inici&oacute; el trabajo de rescate y puesta en valor de Puerto Cristal se han realizado m&uacute;ltiples proyectos que han permitido recolectar y resguardar diversos objetos y documentos que se encontraban dispersos por el Campamento Minero expuestos a condiciones adversas que los pon&iacute;an en riesgo de p&eacute;rdida o deterioro.'
        "
      ></p>
      <p
        v-html="
          'A trav&eacute;s de un trabajo sistem&aacute;tico se logr&oacute; organizar los documentos hallados en este Monumento Nacional que hoy conforman el Archivo Hist&oacute;rico de Puerto Cristal, en una etapa inicial se logr&oacute; resguardar adecuadamente m&aacute;s de 5.000 ejemplares que hoy se encuentran f&iacute;sicamente en el Museo Regional de Ays&eacute;n en modalidad de custodia para evitar su deterioro. En una segunda etapa se ha iniciado un proceso de digitalizaci&oacute;n, registro y catalogaci&oacute;n de 750 documentos que hoy est&aacute;n disponibles para que toda la comunidad pueda acceder a ellos a trav&eacute;s de la plataforma web implementada por el Museo de Puerto Cristal.'
        "
      ></p>
        </div>
      </div>
      
    </div>
    <div class="section color3 full">
      
      <div>
        <div class="content">
        <h1>Agradecimientos</h1>
        <div class="split">
          <img src="../../assets/media/Logo_GORE_Aysen.png" alt="">
          <div>
            <p v-html="'Gracias al Fondo de Cultura 6% del FNDR del Gobierno Regional de Ays&eacute;n se ha podido ejecutar el Proyecto <strong>Digitalizaci&oacute;n archivo hist&oacute;rico de Puerto Cristal</strong> que ha permitido desarrollar una plataforma virtual mediante la cual la comunidad podr&aacute; acceder libremente a 750 documentos que han sido seleccionados y digitalizados con este financiamiento.'"></p>
          </div>
        </div>
      </div>
      </div>
    </div>
    <div class="section full color2">
      
      <div>
        <div class="content">
      <h1 v-html="'¿C&oacute;mo se estructura el Archivo?'"></h1>
      <p
        v-html="
          'Los documentos de Puerto Cristal se organizan y clasifican en una estructura que facilita la b&uacute;squeda y comprensi&oacute;n del Archivo, seg&uacute;n las Directrices y Normas T&eacute;cnicas para la Gesti&oacute;n de Archivos, subdividi&eacute;ndose de la siguiente manera:'
        "
      ></p>
      <li v-html="'<strong>Fondo: </strong>Corresponde a un conjunto de documentos, de cualquier formato o soporte. Un fondo reune la totalidad de la documentaci&oacute;n producida y recibida por la persona, familia o instituci&oacute;n  que lo produce.'">
        
      </li>
      <li v-html="'<strong>Serie: </strong>Es el testimonio documental y continuado de actividades repetitivas desarrolladas por un &oacute;rgano o en virtud de una funci&oacute;n; por lo tanto, las series agrupan documentos del mismo tipo o con informaci&oacute;n similar, que son producidos en el tiempo y con repetici&oacute;n.'">
        
      </li>
      <li v-html="'<strong>Unidad Documental Simple: </strong>Informaci&oacute;n contenida en cualquier soporte y tipo documental, es la unidad archiv&iacute;stica m&aacute;s peque&ntilde;a e indivisible, como por ejemplo:una carta, un informe, una fotograf&iacute;a o una grabaci&oacute;n sonora.'">
        
      </li>
      <li v-html="'<strong>Unidad Documental Compuesta: </strong>Corresponde a un conjunto de documentos reunidos y organizados porque se refieren al mismo tema, actividad o asunto.'">        
      </li>
      
      </div>
      </div>
    </div>
    <div class="main-block">
      <div class="top-section">
        <div
          class="gallery"
          :class="{
            color1: levelList === true,
            color2: levelFondo === true,
            color3: levelSerie === true,
          }"
        >
          <div
            class="title color1"
            v-if="levelList === false"
            @click="
              SET_LEVEL_LIST(true);
              SET_LEVEL_FONDO(false);
              SET_LEVEL_SERIE(false);
            "
          >
            <h3><i class="material-icons">arrow_back_ios</i>Inicio</h3>
          </div>
          <div
            class="title color2"
            v-if="levelList === false && levelFondo === false"
            @click="
              SET_LEVEL_LIST(false);
              SET_LEVEL_FONDO(true);
              SET_LEVEL_SERIE(false);
            "
          >
            <h3>
              <i class="material-icons">arrow_back_ios</i>Fondo:
              {{ currentFondo.publicData[0].value }}
            </h3>
          </div>
          <div class="elements state-1" v-if="levelList === true">
            <div class="info">
              <h1
                v-html="'Explora el Archivo Hist&oacute;rico de Puerto Cristal'"
              ></h1>
              <p
                class="large"
                v-html="
                  'En esta secci&oacute;n podr&aacute;s navegar a traves de las fondos, series y documentos del Archivo.'
                "
              ></p>
            </div>
            <div>
              <div class="item-list">
                <h3>Fondos</h3>
                <ul class="list-container">
                  <li
                    v-for="item in fondos"
                    :key="item.id"
                    :class="{ selected: item.id === fondoActual }"
                    @click="
                      SET_FONDO_ACTUAL(item.id);
                      SET_LEVEL_LIST(false);
                      SET_LEVEL_FONDO(true);
                      updateFondo = { visible: false, fondo: {} };
                    "
                  >
                    <p v-html="item.publicData[0].value"></p>
                    <i class="material-icons">arrow_forward_ios</i>
                  </li>
                  <li
                    v-if="user !== null"
                    class="add"
                    @click="addFondoModuleDisplay = true"
                  >
                    <p v-html="'Agregar Fondo'"></p>
                    <i class="material-icons">add</i>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="elements state-2" v-if="levelFondo === true">
            <div class="header">
              <div class="title-block">
                <h2>{{ currentFondo.publicData[0].value }}</h2>
                <h6>Fondo</h6>
              </div>
              <div class="buttons" v-if="user!==null">
                <button
                  class="icon-and-label-btn white"
                  @click="
                    updateFondo.visible
                      ? (updateFondo = { visible: false, fondo: '' })
                      : ((updateFondo = { visible: true, fondo: currentFondo }),
                        (navigation.showSeriesList = false))
                  "
                  v-if="!navigationOption"
                >
                  <i class="icon material-icons">edit</i>
                  <span class="text" style="white-space: nowrap"> Editar Fondo</span>
                </button>
              </div>
            </div>
            <div class="info">
              <ul>
                <li v-for="(value, key) in currentFondo.publicData" :key="key">
                  <strong>{{ value.field }}:</strong>
                  {{ value.value }}
                </li>
              </ul>
            </div>
            <div>
              <div class="item-list">
                <h3>Series</h3>
                <ul class="list-container">
                  <li
                    v-for="item in currentSeriesList"
                    :key="item.id"
                    :class="{
                      selected: item.id === serieActual,
                    }"
                    @click="
                      SET_SERIE_ACTUAL(item.id);
              SET_LEVEL_FONDO(false);
              SET_LEVEL_SERIE(true);
                      loadDocuments();
                    "
                  >
                    <p v-html="item.publicData[0].value"></p>
                    <i class="material-icons">arrow_forward_ios</i>
                  </li>
                  <li
                    v-if="user !== null"
                    class="add"
                    @click="
                      addSerieModuleDisplay = true;
                      newSerie.fondo = fondoActual;
                    "
                  >
                    <i class="material-icons">add</i>
                    <p v-html="'Agregar Serie'"></p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="elements state-3" v-if="levelSerie === true">
            <div class="header">
              <div class="title-block">
                <h2>{{ currentSerie.publicData[0].value }}</h2>
              <h6>Serie</h6>
              </div>
              <div class="buttons" v-if="user!==null">
                <button
                  class="icon-and-label-btn white"
                  @click="
                    updateSerie.visible
                      ? (updateSerie = { visible: false, serie: '' })
                      : ((updateSerie = { visible: true, serie: currentSerie }),
                        (navigation.showSeriesList = false))
                  "
                  v-if="!navigationOption"
                >
                  <i class="icon material-icons">edit</i>
                  <span class="text" style="white-space: nowrap"> Editar Serie</span>
                </button>
                <button
                  class="icon-and-label-btn white"
                  @click="
                      addDocumentModuleDisplay = true;
                      newDoc.fondo = fondoActual;
                      newDoc.serie = serieActual;
                    "
                  v-if="!navigationOption"
                >
                  <i class="icon material-icons">add</i>
                  <span class="text" style="white-space: nowrap"> Agregar Documento</span>
                </button>
              </div>
            </div>
            <div class="info">
              
              <ul>
                <li v-for="(value, key) in currentSerie.publicData" :key="key">
                  <strong>{{ value.field }}:</strong>
                  {{ value.value }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="conection-btn" v-if="levelSerie === true">
        <div class="btn">
          <p>Documentos <i class="material-icons"> keyboard_arrow_down</i></p>
        </div>
      </div>
      <div
        class="bottom-section"
        v-if="levelSerie === true && currentSerie.documentos.length > 0"
      >
        <DocViewer
          :setup="{
            iconPosition: 'right',
            lista: currentSerie.documentos,
            buttons: [],
          }"
        />
      </div>
    </div>
    <Loading text="cargando" />
    <Message />

    <div class="modal" v-if="addFondoModuleDisplay">
    <div class="top" @click="addFondoModuleDisplay = false"></div>
    <div class="left" @click="addFondoModuleDisplay = false"></div>
    <div class="right" @click="addFondoModuleDisplay = false"></div>
    <div class="bottom" @click="addFondoModuleDisplay = false"></div>
      <div class="box">
        <p class="icon">
          <i class="material-icons" @click="addFondoModuleDisplay = false"
            >close</i
          >
        </p>
        <div class="content padding">
          <h2 v-html="'Registrar Nuevo Fondo'"></h2>
        </div>
        <div class="padding">
          <label v-html="'C&oacute;digo de referencia'"></label>
          <input
            type="text"
            name="fondoCode"
            id="fondoCode"
            v-model="newFondo.code"
          />
          <label v-html="'T&iacute;tulo del Fondo'"></label>
          <input
            type="text"
            name="fondoTitle"
            id="fondoTitle"
            v-model="newFondo.title"
          />
          <label v-html="'Periodo o fechas'"></label>
          <input
            type="text"
            name="fondoYears"
            id="fondoYears"
            v-model="newFondo.dates"
          />
          <label v-html="'Nombre del Productor'"></label>
          <input
            type="text"
            name="fondoProducer"
            id="fondoProducer"
            v-model="newFondo.producer"
          />
          <p class="btn-center">
            <button
              class="ok-btn"
              :disabled="validateNewFondo"
              @click="addFondo"
            >
              Guardar
            </button>
            <button class="cancel-btn" @click="addFondoModuleDisplay = false">
              Cancelar
            </button>
          </p>
        </div>
      </div>
    </div>
    <div class="modal" v-if="addSerieModuleDisplay">
    <div class="top" @click="addSerieModuleDisplay = false"></div>
    <div class="left" @click="addSerieModuleDisplay = false"></div>
    <div class="right" @click="addSerieModuleDisplay = false"></div>
    <div class="bottom" @click="addSerieModuleDisplay = false"></div>
      <div class="box">
        <p class="icon">
          <i class="material-icons" @click="addSerieModuleDisplay = false"
            >close</i
          >
        </p>
        <div class="content padding">
          <h2 v-html="'Registrar Nueva Serie'"></h2>
        </div>
        <div class="padding">
          <label v-html="'Fondo al que pertenece la Serie'"></label>
          <select name="fondo" id="fondo" v-model="newSerie.fondo">
            <option
              v-for="item in fondos"
              :key="item.id"
              :value="item.id"
              v-html="item.publicData[0].value"
            ></option>
          </select>
          <label v-html="'T&iacute;tulo de la Serie'"></label>
          <input
            type="text"
            name="serieTitle"
            id="serieTitle"
            v-model="newSerie.title"
          />
          <label v-html="'Periodo o fechas'"></label>
          <input
            type="text"
            name="serieYears"
            id="serieYears"
            v-model="newSerie.dates"
          />
          <label v-html="'Nombre del Productor'"></label>
          <input
            type="text"
            name="serieProducer"
            id="serieProducer"
            v-model="newSerie.producer"
          />
          <p class="btn-center">
            <button
              class="ok-btn"
              :disabled="validateNewSerie"
              @click="addSerie"
            >
              Guardar
            </button>
            <button class="cancel-btn" @click="addSerieModuleDisplay = false">
              Cancelar
            </button>
          </p>
        </div>
      </div>
    </div>
    <div class="modal" v-if="addDocumentModuleDisplay">
    <div class="top" @click="addDocumentModuleDisplay = false"></div>
    <div class="left" @click="addDocumentModuleDisplay = false"></div>
    <div class="right" @click="addDocumentModuleDisplay = false"></div>
    <div class="bottom" @click="addDocumentModuleDisplay = false"></div>
      <div class="box">
        <p class="icon">
          <i class="material-icons" @click="addDocumentModuleDisplay = false"
            >close</i
          >
        </p>
        <div class="content padding">
          <h2 v-html="'Registrar Nuevo Documento'"></h2>
        </div>
        <div class="padding">
          <label v-html="'Fondo al que pertenece el Documento'"></label>
          <select name="fondo-doc" id="fondo-doc" v-model="newDoc.fondo">
            <option
              v-for="item in fondos"
              :key="item.id"
              :value="item.id"
              v-html="item.publicData[0].value"
            ></option>
          </select>
          <label v-html="'Serie al que pertenece el Documento'"></label>
          <select name="serie-doc" id="serie-doc" v-model="newDoc.serie">
            <option value="" v-html="'Seleccione una Serie'"></option>
            <option
              v-for="item in getFondosSeries(newDoc.fondo)"
              :key="item.id"
              :value="item.id"
              v-html="item.publicData[0].value"
            ></option>
          </select>
          <label v-html="'T&iacute;tulo del Documento'"></label>
          <input
            type="text"
            name="docTitle"
            id="docTitle"
            v-model="newDoc.title"
            :disabled="getFondosSeries(newDoc.fondo) === false"
          />
          <label v-html="'Fecha'"></label>
          <input
            type="text"
            name="serieYears"
            id="serieYears"
            v-model="newDoc.dates"
            :disabled="getFondosSeries(newDoc.fondo) === false"
          />
          <label v-html="'Nombre del Productor'"></label>
          <input
            type="text"
            name="serieProducer"
            id="serieProducer"
            v-model="newDoc.productor"
            :disabled="getFondosSeries(newDoc.fondo) === false"
          />
          <label class="warning" v-if="getFondosSeries(newDoc.fondo) === false">
            El fondo no tiene series, debe registrar una primero.
            <span
              @click="
                addDocumentModuleDisplay = false;
                addSerieModuleDisplay = true;
                newSerie.fondo = newDoc.fondo;
              "
              >+ Agregar Serie</span
            >
          </label>
          <p class="btn-center">
            <button
              class="ok-btn"
              :disabled="validateNewDocument"
              @click="addDocumento"
            >
              Guardar
            </button>
            <button
              class="cancel-btn"
              @click="addDocumentModuleDisplay = false"
            >
              Cancelar
            </button>
          </p>
        </div>
      </div>
    </div>
    <div class="modal" v-if="currentDocumento !== null && displayDocumentData">
    <div class="top" @click="displayDocumentData = false"></div>
    <div class="left" @click="displayDocumentData = false"></div>
    <div class="right" @click="displayDocumentData = false"></div>
    <div class="bottom" @click="displayDocumentData = false"></div>
      <div class="box document">
        <p class="icon">
          <i class="material-icons" @click="displayDocumentData = false"
            >close</i
          >
        </p>
        <div class="content">
          <div class="wrapper">
            <div
              class="slider"
              :class="{
                position1: previewPosition === 1,
                position2: previewPosition === 2,
              }"
            >
              <SimpleBar
                class="data-section"
                v-show="previewPosition === 1"
                data-SimpleBar-auto-hide="false"
              >
                <h2>Ficha de Registro</h2>
                <ul>
                  <li class="id">
                    <p
                      class="field"
                      v-html="'C&oacute;digo Identificador:'"
                    ></p>
                    <p>
                      {{ currentDocumento.id }}
                    </p>
                  </li>
                </ul>
                <h5
                  v-html="'Informaci&oacute;n P&uacute;blica del Documento'"
                ></h5>
                <ul>
                  <li
                    v-for="(value, key) in currentDocumento.publicData"
                    :key="key"
                  >
                    <p class="field">{{ value.field }}:</p>
                    <p :class="{ highlight: value.value === 'incompleto' }">
                      {{ value.value }}
                    </p>
                  </li>
                </ul>
                <h5 v-html="'Informaci&oacute;n Reservada del Documento'"></h5>
                <ul>
                  <li
                    v-for="(value, key) in currentDocumento.privateData"
                    :key="key"
                  >
                    <p class="field">{{ value.field }}:</p>
                    <p :class="{ highlight: value.value === 'incompleto' }">
                      {{ value.value }}
                    </p>
                  </li>
                </ul>
              </SimpleBar>
              <SimpleBar
                class="data-section"
                v-show="previewPosition === 2"
                data-SimpleBar-auto-hide="false"
              >
                <h2 v-html="'Previsualizaci&oacute;n'"></h2>
                <ul>
                  <li class="id">
                    <p
                      class="field"
                      v-html="'C&oacute;digo Identificador:'"
                    ></p>
                    <p>
                      {{ currentDocumento.id }}
                    </p>
                  </li>
                </ul>
                <div
                  class="doc-preview padding"
                  v-if="currentDocumento.link === ''"
                >
                  <h2><i class="material-icons">cloud_off</i></h2>
                  <h2 v-html="'No se ha cargado ning&uacute;n documento.'"></h2>
                </div>
              </SimpleBar>
            </div>
            <div class="navigation-btn">
              <div>
                <button
                  class="icon-btn fill-on-hover left"
                  v-if="previewPosition === 2"
                  @click="previewPosition = 1"
                >
                  <i class="material-icons">arrow_back</i>
                  <span class="tooltiptext"> Ver Ficha </span>
                </button>
              </div>
              <div>
                <button
                  class="icon-btn fill-on-hover right"
                  v-if="previewPosition === 1"
                  @click="previewPosition = 2"
                >
                  <i class="material-icons">arrow_forward</i>
                  <span class="tooltiptext"> Ver Documento </span>
                </button>
              </div>
            </div>
            <div class="nav-bar">
              <div
                class="dot"
                :class="{ 'active-dot': previewPosition === 1 }"
                @click="previewPosition = 1"
              ></div>
              <div
                class="dot"
                :class="{ 'active-dot': previewPosition === 2 }"
                @click="previewPosition = 2"
              ></div>
            </div>
            <div class="action-btn">
              <div>
                <button
                  class="icon-btn fill-on-hover selected"
                  @click="$router.push('/archive/' + currentDocumento.id)"
                >
                  <i class="material-icons">open_in_full</i>
                  <span class="tooltiptext">Ver Documento</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" v-if="updateFondo.visible">
    <div class="top" @click="updateFondo.visible = false"></div>
    <div class="left" @click="updateFondo.visible = false"></div>
    <div class="right" @click="updateFondo.visible = false"></div>
    <div class="bottom" @click="updateFondo.visible = false"></div>
      <div class="box">
        <p class="icon">
          <i class="material-icons" @click="updateFondo.visible = false"
            >close</i
          >
        </p>
        <div class="content padding">
          <div class="tabs">
            <div class="tab-link" @click="selectedForm='public'" :class="{selected:selectedForm==='public'}"><p v-html="'Informaci&oacute;n P&uacute;blica'"></p></div>
            <div class="tab-link" @click="selectedForm='private'" :class="{selected:selectedForm==='private'}"><p v-html="'Informaci&oacute;n Privada'"></p></div>
          </div>
          <SimpleBar class="content padding">
            <div class="edit-block" v-show="selectedForm === 'public'">
            <ul>
              <li
                class="update"
                v-for="(value, key) in updateFondo.fondo.publicData"
                :key="key"
              >
                <label>{{ value.field }}: </label>
                <input
                  type="text"
                  :name="'att' + value.position"
                  v-model="value.value"
                  v-if="
                    value.position === 1 ||
                    value.position === 2 ||
                    value.position === 3 ||
                    value.position === 7
                  "
                />
                <textarea
                  rows="5"
                  :name="'att' + value.position"
                  v-model="value.value"
                  v-else
                ></textarea>
              </li>
            </ul>
            <p class="btn-center">
              <button class="ok-btn" @click="updatePublicDataOfFondo">
                Guardar
              </button>
              <button
                class="cancel-btn"
                @click="updateFondo = { visible: false, serie: {} }"
              >
                Cancelar
              </button>
            </p>
            </div>
            <div class="edit-block" v-show="selectedForm === 'private'">
            <ul>
              <li
                class="update"
                v-for="(value, key) in updateFondo.fondo.privateData"
                :key="key"
              >
                <label>{{ value.field }}: </label>
                <input
                  type="text"
                  :name="'att' + value.position"
                  v-model="value.value"
                  v-if="
                    value.position === 1 ||
                    value.position === 2 ||
                    value.position === 3 ||
                    value.position === 4 ||
                    value.position === 5
                  "
                />
                <textarea
                  rows="5"
                  :name="'att' + value.position"
                  v-model="value.value"
                  v-else
                ></textarea>
              </li>
            </ul>
            <p class="btn-center">
              <button class="ok-btn" @click="updatePrivateDataOfFondo">
                Guardar
              </button>
              <button
                class="cancel-btn"
                @click="updateFondo = { visible: false, serie: {} }"
              >
                Cancelar
              </button>
            </p>
            </div>
          </SimpleBar>
        </div>
        
      </div>
    </div>
    <div class="modal" v-if="updateSerie.visible">
    <div class="top" @click="updateSerie.visible = false"></div>
    <div class="left" @click="updateSerie.visible = false"></div>
    <div class="right" @click="updateSerie.visible = false"></div>
    <div class="bottom" @click="updateSerie.visible = false"></div>
      <div class="box">
        <p class="icon">
          <i class="material-icons" @click="updateSerie.visible = false"
            >close</i
          >
        </p>
        <div class="content padding">
          <div class="tabs">
            <div class="tab-link" @click="selectedForm='public'" :class="{selected:selectedForm==='public'}"><p v-html="'Informaci&oacute;n P&uacute;blica'"></p></div>
            <div class="tab-link" @click="selectedForm='private'" :class="{selected:selectedForm==='private'}"><p v-html="'Informaci&oacute;n Privada'"></p></div>
          </div>
          <SimpleBar class="content padding">
            <div class="edit-block" v-show="selectedForm === 'public'">
            <ul>
              <li
                class="update"
                v-for="(value, key) in updateSerie.serie.publicData"
                :key="key"
              >
                <label>{{ value.field }}: </label>
                <input
                  type="text"
                  :name="'att' + value.position"
                  v-model="value.value"
                  v-if="
                    value.position === 1 ||
                    value.position === 2 ||
                    value.position === 3 ||
                    value.position === 7
                  "
                />
                <textarea
                  rows="5"
                  :name="'att' + value.position"
                  v-model="value.value"
                  v-else
                ></textarea>
              </li>
            </ul>
            <p class="btn-center">
              <button class="ok-btn" @click="updatePublicDataOfSerie">
                Guardar
              </button>
              <button
                class="cancel-btn"
                @click="updateSerie = { visible: false, serie: {} }"
              >
                Cancelar
              </button>
            </p>
            </div>
            <div class="edit-block" v-show="selectedForm === 'private'">
            <ul v-show="selectedForm === 'private'">
              <li
                class="update"
                v-for="(value, key) in updateSerie.serie.privateData"
                :key="key"
              >
                <label>{{ value.field }}: </label>
                <input
                  type="text"
                  :name="'att' + value.position"
                  v-model="value.value"
                  v-if="
                    value.position === 1 ||
                    value.position === 2 ||
                    value.position === 3 ||
                    value.position === 7
                  "
                />
                <textarea
                  rows="5"
                  :name="'att' + value.position"
                  v-model="value.value"
                  v-else
                ></textarea>
              </li>
            </ul>
            <p class="btn-center">
              <button class="ok-btn" @click="updatePrivateDataOfSerie">
                Guardar
              </button>
              <button
                class="cancel-btn"
                @click="updateSerie = { visible: false, serie: {} }"
              >
                Cancelar
              </button>
            </p>
          </div>
          </SimpleBar>
        </div>
        
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import Loading from "@/components/Loading.vue";
import Message from "@/components/Message.vue";
import DocViewer from "@/components/DocViewer.vue";
import {SimpleBar} from "simplebar-vue3";
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
  name: "archive-landingpage",
  components: {
    Loading,
    Message,
    SimpleBar,
    DocViewer,
  },
  data: function () {
    return {
      windowWidth: window.innerWidth,
      searchedValue: "",
      result: {
        doc: { id: "", name: "", definition: "", parents: [] },
      },
      id: "",
      searchId: "",
      addDocumentModuleDisplay: false,
      addFondoModuleDisplay: false,
      addSerieModuleDisplay: false,
      searchMethod: "name",
      lastName: "",
      resultList: [],
      itemsPerPage: 5,
      navigationOption: false,
      currentPage: 1,
      filters: ["clasification", "material", "technique"],
      docType: "",
      fondo: "",
      glossary: "",
      newFondo: { code: "", title: "", dates: "", producer: "" },
      newSerie: { fondo: "", title: "", dates: "", producer: "" },
      newDoc: { fondo: "", serie: "", title: "", tipe: "", date: "" },
      navigation: {
        fondoActual: "",
        serieActual: "",
        docActual: "",
        showSeriesList: false,
        showDocList: false,
      },
      currentDocumento: null,
      displayDocumentData: false,
      displayLevel: 1,
      updateFondo: { visible: false, fondo: {} },
      updateSerie: { visible: false, serie: {} },
      selectedForm: "public",
      previewPosition: 1,
    };
  },
  created() {
    this.SET_LOADING_TEXT("Cargando...");
    this.SET_LOADING(false);
  },
  watch: {
    searchResult() {},
    itemsPerPage() {
      this.currentPage = 1;
    },
    collections() {},
    fondoActual(value){
      this.SET_FONDO_ACTUAL(value)
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeUnmountd() {
    window.removeEventListener("resize", this.onResize);
  },
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters("archive", {
      fondos: "fondos",
      series:"series",
      documentos: "documentos",
      fondoActual: "currentFondo",
      serieActual: "currentSerie",
      levelList: "levelList",
      levelFondo: "levelFondo",
      levelSerie: "levelSerie",
    }),
    
    validateNewFondo() {
      if (
        this.newFondo.code !== "" &&
        this.newFondo.title !== "" &&
        this.newFondo.dates !== "" &&
        this.newFondo.producer !== ""
      ) {
        return false;
      } else {
        return true;
      }
    },
    validateNewSerie() {
      if (
        this.newSerie.title !== "" &&
        this.newSerie.dates !== "" &&
        this.newSerie.producer !== ""
      ) {
        return false;
      } else {
        return true;
      }
    },
    validateNewDocument() {
      if (
        this.newDoc.title !== "" &&
        this.newDoc.dates !== "" &&
        this.newDoc.producer !== ""
      ) {
        return false;
      } else {
        return true;
      }
    },
    currentFondo() {
      if (this.fondoActual !== "") {
        let f = this.fondos.find(
          (item) => item.id === this.fondoActual
        );
        return f;
      }
      return null;
    },
    currentSerie() {
      if (this.serieActual !== "") {
        let s = this.series.find((item) => item.fondo === this.fondoActual&&item.id === this.serieActual);
        if (s !== undefined) {
          return s;
        }
        return null;
      }
      return null;
    },
    currentSeriesList(){
      if (this.fondoActual !== "") {
        let f = this.series.filter(
          (item) => item.fondo === this.fondoActual
        );
        return f;
      }
      return null;
    },
    currentDoc() {
      if (this.docActual !== "") {
        let f = this.fondos.find((item) => item.id === this.fondoActual);
        let s = f.series.find((item) => item.id === this.serieActual);
        let d = s.documentos.find((item) => item.id === this.docActual);
        if (d !== undefined) {
          return s;
        }
        return null;
      }
      return null;
    },
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    ...mapMutations("archive",{
      SET_FONDO_ACTUAL:"SET_FONDO_ACTUAL",
      SET_SERIE_ACTUAL:"SET_SERIE_ACTUAL",
      SET_LEVEL_LIST:"SET_LEVEL_LIST",
      SET_LEVEL_FONDO:"SET_LEVEL_FONDO",
      SET_LEVEL_SERIE:"SET_LEVEL_SERIE",
      }),
    ...mapMutations([
      "SET_LOADING",
      "SET_LOADING_OPACITY",
      "SET_LOADING_TEXT",
      "SET_MESSAGE_PARAMETERS",
    ]),
    ...mapActions("archive", {
      addNewFondo: "addNewFondo",
      addNewSerie: "addNewSerie",
      addNewDocument: "addNewDocument",
      getDocumentosFromSerie: "getDocumentosFromSerie",
      getPublishedDocumentosFromSerie: "getPublishedDocumentosFromSerie",
      setSeriesPublicData: "setSeriesPublicData",
      updatePrivateDataOfSerie: "updatePrivateDataOfSerie",
      loadDocumento: "loadDocumento",
      loadFONDOS: "loadFONDOS",
      loadSERIES: "loadSERIES",
      cargarDocumentos: "cargarDocumentos",
      setPublished: "setPublished",
      addDocToSerie: "addDocToSerie",
      publishAll: "publishAll",
    }),
    addFondo() {
      this.SET_LOADING(true);
      this.addNewFondo({
        code: this.newFondo.code,
        titulo: this.newFondo.title,
        fechas: this.newFondo.dates,
        productor: this.newFondo.producer,
      }).then(() => {
        this.SET_LOADING(false);
        this.addFondoModuleDisplay = false;
        this.SET_MESSAGE_PARAMETERS({
          isVisible: true,
          type: "success",
          icon: "done",
          title: "Fondo Creado",
          message: "Se ha creado exitosamente el fondo " + this.newFondo.title,
          buttonAction: "",
          button: "Aceptar",
          respondTo: "success",
          action: 0,
        });
      });
    },
    addSerie() {
      this.SET_LOADING(true);
      this.addNewSerie({
        fondo: this.newSerie.fondo,
        serie: {
          titulo: this.newSerie.title,
          fechas: this.newSerie.dates,
          productor: this.newSerie.producer,
        },
      }).then((result) => {
        if (result) {
          this.SET_LOADING(false);
          this.addSerieModuleDisplay = false;
          this.SET_MESSAGE_PARAMETERS({
            isVisible: true,
            type: "success",
            icon: "done",
            title: "Serie Creada",
            message:
              "Se ha creado exitosamente la serie " + this.newSerie.title,
            buttonAction: "",
            button: "Aceptar",
            respondTo: "success",
            action: 0,
          });
        }
      });
    },
    addDocumento() {
      this.SET_LOADING(true);
      this.addNewDocument({
        fondo: this.newDoc.fondo,
        serie: this.newDoc.serie,
        doc: {
          titulo: this.newDoc.title,
          fecha: this.newDoc.dates,
          productor: this.newDoc.productor,
        },
      }).then((result) => {
        if (result) {
          this.SET_LOADING(false);
          this.addDocumentModuleDisplay = false;
          this.SET_MESSAGE_PARAMETERS({
            isVisible: true,
            type: "success",
            icon: "done",
            title: "Serie Creada",
            message:
              "Se ha creado exitosamente la serie " + this.newSerie.title,
            buttonAction: "",
            button: "Aceptar",
            respondTo: "success",
            action: 0,
          });
        }
      });
    },
    loadDocuments() {
      if (this.serieActual !== "") {
        let s = this.series.find(
          (item) => item.fondo===this.fondoActual&&item.id === this.serieActual
        );
        if (s !== undefined) {
          if (s.documentos.length === 0) {
            if (this.user !== null) {
              this.SET_LOADING(true);
              this.getDocumentosFromSerie({
                fondo: this.fondoActual,
                serie: this.serieActual,
              }).then((result) => {
                console.log(result);
                this.SET_LOADING(false);
              });
            } else {
              this.SET_LOADING(true);
              this.getPublishedDocumentosFromSerie({
                fondo: this.fondoActual,
                serie: this.serieActual,
              }).then((result) => {
                console.log(result);
                this.SET_LOADING(false);
              });
            }
          }
        }
      }
      this.navigation.showDocumentList = !this.navigation.showDocumentList;
    },
    displayDocumento() {
      let doc = this.documentos.find(
        (item) => item.id === this.navigation.docActual
      );
      if (doc === undefined || doc === null) {
        this.SET_LOADING(true);
        this.loadDocumento(this.navigation.docActual).then((ans) => {
          if (ans !== false) {
            this.currentDocumento = ans;
            this.displayDocumentData = true;
            this.SET_LOADING(false);
          } else {
            console.log("Documento no encontrado");
          }
        });
      } else {
        this.currentDocumento = doc;
        this.displayDocumentData = true;
      }
    },
    getFondosSeries(payload) {
      let fon = this.fondos.find((item) => item.id === payload);
      if (fon.series.length > 0) {
        return fon.series;
      }
      return false;
    },
    updatePublicDataOfSerie() {
      let update = {
        fondo: this.currentFondo.id,
        serie: {
          id: this.updateSerie.serie.id,
          publicData: this.updateSerie.serie.publicData,
        },
      };
      this.SET_LOADING(true);
      this.setSeriesPublicData(update).then((result) => {
        this.SET_LOADING(false);
        if (result) {
          this.SET_MESSAGE_PARAMETERS({
            isVisible: true,
            type: "success",
            icon: "done",
            title: "Informaci&oacute;n Actualizada",
            message:
              "Se ha actualizado exitosamente la informaci&oacute;n de la serie " +
              this.newFondo.title,
            buttonAction: "",
            button: "Aceptar",
            respondTo: "success",
            action: 0,
          });
        } else {
          this.SET_MESSAGE_PARAMETERS({
            isVisible: true,
            type: "error",
            icon: "error",
            title: "Ocurri&oacute; un Error",
            message:
              "Ocurri&oacute; un error al tratar de actualizar la Serie, es posible que no se hayan guardado los cambios.",
            buttonAction: "",
            button: "Aceptar",
            respondTo: "",
            action: 0,
          });
        }
      });
    },
    updatePrivateDataOfSerie() {
      let update = {
        fondo: this.currentFondo.id,
        serie: {
          id: this.updateSerie.serie.id,
          privateData: this.updateSerie.serie.privateData,
        },
      };
      this.SET_LOADING(true);
      this.setSeriesPrivateData(update).then((result) => {
        this.SET_LOADING(false);
        if (result) {
          this.SET_MESSAGE_PARAMETERS({
            isVisible: true,
            type: "success",
            icon: "done",
            title: "Informaci&oacute;n Actualizada",
            message:
              "Se ha actualizado exitosamente la informaci&oacute;n de la serie " +
              this.newFondo.title,
            buttonAction: "",
            button: "Aceptar",
            respondTo: "success",
            action: 0,
          });
        } else {
          this.SET_MESSAGE_PARAMETERS({
            isVisible: true,
            type: "error",
            icon: "error",
            title: "Ocurri&oacute; un Error",
            message:
              "Ocurri&oacute; un error al tratar de actualizar la Serie, es posible que no se hayan guardado los cambios.",
            buttonAction: "",
            button: "Aceptar",
            respondTo: "",
            action: 0,
          });
        }
      });
    },
    setResult(payload) {
      if (payload.doc === null || payload.doc === undefined) {
        this.searchedValue = payload.text;
        this.result.doc = null;
      } else {
        if (payload.text === payload.doc.name) {
          this.searchedValue = payload.doc.name;
          this.result.doc = payload.doc;
        } else {
          this.searchedValue = payload.text;
          this.result.doc = null;
        }
      }
    },
    getId(payload) {
      if (payload.doc === null || payload.doc === undefined) {
        this.searchId = payload.text;
        this.id = "";
      } else {
        if (payload.text === payload.doc.name) {
          this.searchId = payload.doc.name;
          this.id = payload.doc.name;
        } else {
          this.searchId = payload.text;
          this.id = "";
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/sass/variables";
@import "../../assets/sass/buttons";
@import "../../assets/sass/cards";
@import "../../assets/sass/modal";

.home {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 70vh auto;
  width: 100vw;
  .warning {
    color: $orange-d2 !important;
  }
  div.autocomplete-container {
    padding: 25px 13em 45px 3em;
    div {
      display: grid;
      grid-template-columns: auto 60px;
      grid-template-rows: 50px;
      border-radius: 50px;
      background-color: white;
      padding: 0px;
      overflow: visible;
      .radius-left {
        border-bottom-left-radius: 50px;
        border-top-left-radius: 50px;
      }
      .radius-right {
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
      }

      button {
        margin-block-end: 0px;
        margin-block-start: 0px;
        background-color: $theme;
        border: none;
        cursor: pointer;
        i.material-icons {
          vertical-align: middle;
          font-size: 35px;
          color: white;
        }
      }
      button:disabled {
        opacity: 0.5;
        cursor: no-drop;
      }
    }
  }
  .search {
    background-image: linear-gradient(
        to bottom,
        transparent,
        rgba(0, 0, 0, 0.75)
      ),
      url("../../assets/media/Labratory.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: grid;
    grid-template-rows: calc(100% - 220px) 50px 120px 50px;
    h1,
    h4 {
      position: relative;
      text-align: center;
      padding-left: 10%;
      padding-right: 10%;
      margin-block-end: 0px;
      margin-block-start: 0px;
    }
    div.search-options {
      align-content: center;
      input[type="radio"] {
        margin-block-start: 5px;
        margin-block-end: 5px;
        height: 20px;
        width: 20px;
        margin-right: 5px;
        display: inline-block;
        vertical-align: middle;
      }
      label {
        color: white;
        display: inline-block;
        margin-right: 30px;
        margin-block-end: 0px;
        margin-block-start: 0px;
        vertical-align: middle;
        margin-top: 0px;
        line-height: 20px;
      }
    }
    div {
      padding-left: 10%;
      padding-right: 10%;
      position: relative;
      h1 {
        text-align: center;
        display: block;
        width: 100%;
        bottom: 0%;
        left: 50%;
        margin-bottom: 0px;
        position: absolute;
        transform: translateX(-50%);
      }
    }
    h1 {
      color: $theme-l4;
      vertical-align: text-bottom;
    }
    h4 {
      color: wheat;
    }
  }
  .border-shadow {
    -webkit-box-shadow: 0px -1px 0px 0px $grey-1 inset,
      -1px 0px 0px 0px $grey-1 inset;
    box-shadow: 0px -1px 0px 0px $grey-1 inset, -1px 0px 0px 0px $grey-1 inset;

    -webkit-box-shadow: 3px 3px 7px -2px $grey-1, -1px -1px 7px -3px $grey-1;
    box-shadow: 3px 3px 7px -2px $grey-1, -1px -1px 7px -3px $grey-1;
  }
  .bottom-shadow {
    -webkit-box-shadow: 0px -1px 0px 0px $grey-1 inset;
    box-shadow: 0px -1px 0px 0px $grey-1 inset;
  }
  .section {
    position: relative;
    padding: 2em 2em;
    display: grid;
    grid-template-rows: auto;
    gap: 1em;
    &.color1 {
      background-color: $blue-grey;
      h1,
      h2,
      h3,
      h4,
      h5 {
        color: $grey-2;
      }
      p,
      li {
        color: $grey-1;
      }
    }
    &.color2 {
      background-color: $grey-1;
      h1,
      h2,
      h3,
      h4,
      h5 {
        color: $theme-d1;
      }
      p,
      li {
        color: $theme-d3;
      }
    }
    &.color3 {
      background-color: $theme-l4;
      h1,
      h2,
      h3,
      h4,
      h5 {
        color: $theme-d2;
      }
      p,
      li {
        color: $blue-grey-dark;
      }
    }
    &.split,&.full {
      height: 100%;
      div {
        position: relative;
        display: block;
        .title,
        .content {
          width: 100%;
          display: block;
          height: fit-content;
          h1 {
            font-size: 3em;
            font-weight: 700;
            margin-block-start: 0;
            margin-block-end: 0;
          }
          h3{
            margin-block-start: 0;
            margin-block-end: 0;
          }
          li{
            list-style-type: none;
            margin-left: 0;
            font-size: 1.2rem;
            display: inline-block;
            strong,p{
              display: inline-block;
            font-size: 1.2rem;
            }
          }
          p{
            font-size: 1.2em;
            font-weight: 300;
          }
          .split {
            display: grid;
            
          }
        }
        .content{
          h1{
            text-align: center;
            margin-block-end: 1em;
          }
        }
        .image {
          border-radius: 1em;
          width: 100%;
          padding-top: 100%;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          &#img1{
            background-image:url('../../assets/media/documentos.jpg');
          }
        }
      }
    }
  }
  .main-block {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto 35px 35px auto;
    .top-section,
    .conection-btn,
    .bottom-section {
      grid-column-start: 1;
      grid-column-end: 2;
    }
    .top-section {
      grid-row-start: 1;
      grid-row-end: 3;
      div.gallery {
        width: 100%;
        display: grid;
        padding: 0;
        grid-template-columns: 3rem auto 3rem;
        grid-template-rows: auto auto auto 3rem;
        .title {
          grid-column-start: 1;
          grid-column-end: 4;
          cursor: pointer;
          padding-left: 1em;
          padding-right: 1.5em;
          &.color1 {
            background-color: $theme-light;
            h3 {
              color: $theme-dark;
              font-size: 1.8em;
              font-weight: 500;
              padding-left: 1em;
              i.material-icons {
                vertical-align: middle;
              }
            }
          }
          &.color2 {
            background-color: $theme;
            h3 {
              color: $theme-l3;
              font-size: 1.8em;
              font-weight: 500;
              padding-left: 1em;
              i.material-icons {
                vertical-align: middle;
              }
            }
          }
        }
        &.color1 {
          background-color: $theme-light;
          h1 {
            color: $theme-dark;
            font-size: 3em;
            font-weight: 700;
            margin-block-start: 0%;
          }
          p {
            color: $blue-grey-dark;
          }
          div {
            div.item-list {
              background-color: $theme-dark;
              h3 {
                color: $theme-light;
              }
              ul.list-container {
                li {
                  p,
                  i.material-icons {
                    color: white;
                  }
                  &:hover,
                  &.selected {
                    background-color: $theme;
                  }
                }
              }
            }
          }
        }
        &.color2 {
          background-color: $theme;
          h2 {
            color: $theme-l3;
            font-size: 2.5em;
            font-weight: 600;
            margin-block-start: 0%;
            margin-block-end: 0%;
          }
          h6 {
            color: $theme-l2;
          }
          p,
          li {
            color: $theme-l4;
          }
          div {
            div.item-list {
              background-color: $theme-light;
              h3 {
                color: $theme-dark;
              }
              ul.list-container {
                li {
                  p,
                  i.material-icons {
                    color: white;
                  }
                  &:hover,
                  &.selected {
                    background-color: $theme;
                  }
                }
              }
            }
          }
        }
        &.color3 {
          background-color: $theme-d3;
          h2 {
            color: $theme-l3;
            font-size: 2em;
            font-weight: 600;
            margin-block-start: 0%;
            margin-block-end: 0%;
          }
          h6 {
            color: $theme-l2;
          }
          p,
          li {
            color: $theme-l4;
          }
          div {
            div.item-list {
              background-color: $theme-light;
              h3 {
                color: $theme-dark;
              }
              ul.list-container {
                li {
                  p,
                  i.material-icons {
                    color: white;
                  }
                  &:hover,
                  &.selected {
                    background-color: $theme;
                  }
                }
              }
            }
          }
        }
        div.elements {
          padding-top: 2em;
          grid-row-start: 3;
          grid-row-end: 4;
          grid-column-start: 2;
          grid-column-end: 3;
          display: grid;
          gap: 1em;
          .header {
            display: grid;
            grid-template-rows: auto;
            grid-template-columns: auto min-content;
          }

          div.info {
            h6 {
              font-size: 1.4em;
              text-decoration: underline;
              margin-block-end: 2em;
              margin-block-start: 0%;
            }
            p.large {
              font-size: 1.5em;
            }
            ul {
              list-style-type: none;
              margin: 0;
              padding: 0;
              li {
                font-size: 1.1em;
              }
            }
          }
          div {
            height: 100%;
            .item-list {
              width: 100%;
              border-radius: 1em;
              overflow: hidden;
              height: 100%;
              h3 {
                text-decoration: underline;
                font-size: 1.4em;
                padding-left: 1em;
                font-weight: 400;
              }
              ul.list-container {
                list-style-type: none;
                margin: 0;
                padding: 3px 0 0.5em 0;
                li {
                  display: grid;
                  grid-template-columns: 90% 10%;
                  padding: 0.35em 1em 0.35em 1em;
                  margin-block-end: 0px !important;
                  margin-block-start: 0px !important;
                  p {
                    width: calc(100% - 30px);
                    display: inline;
                    font-size: 1.1em;
                    line-height: 24px;
                    margin-block-end: 0px !important;
                    margin-block-start: 0px !important;
                  }
                  i {
                    text-align: right;
                  }
                  &.add {
                    display: inline-block;
                    line-height: 24px;
                    color: $theme;
                    width: 100%;
                    p {
                      width: calc(100% - 30px);
                      display: inline;
                      line-height: 24px;
                      margin-block-end: 0px !important;
                      margin-block-start: 0px !important;
                      font-weight: 500;
                      color: $theme;
                      vertical-align: middle;
                      color: white;
                    }
                    i {
                      vertical-align: middle;
                    }
                  }
                }
                li:hover,
                li.selected {
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }
    .conection-btn {
      grid-row-start: 2;
      grid-row-end: 4;
      position: relative;
      padding-top: 0;
      div.btn {
        height: 70px;
        width: auto;
        position: absolute;
        left: 50%;
        padding-top: 0;
        transform: translateX(-50%);
        border-radius: 40px;
        background-color: $theme;
        padding-left: 2em;
        padding-right: 2em;
        min-width: fit-content;
        p {
          color: white;
          font-weight: 500;
          font-size: 1.2em;
          line-height: 70px;
          margin-block-end: 0%;
          margin-block-start: 0%;
          i.material-icons {
            line-height: 70px;
            vertical-align: middle;
            font-size: 2em;
          }
        }
      }
    }
    .bottom-section {
      grid-row-start: 3;
      grid-row-end: 5;
      background-color: white;
      padding: 3rem 3rem 1rem 3rem;
    }
  }
}
@media (min-width: 768px) {
  .home {
    .section {
      padding: 3em 5em;
      gap: 2em;
      &.split {
        grid-template-columns: 1.5fr 2.5fr;
        div{
          .content{
            .split{
              gap: 1em;
              grid-template-columns: 1fr 3fr;
              div{
                display:flex;
    align-items: center;

              }
            }

          }
        }
      }
      &.full {
        div{
          .content{
            .split{
              gap: 1em;
              grid-template-columns: 1fr 3fr;
              grid-template-rows: auto;
              div{
                display:flex;
    align-items: center;

              }
            }

          }
        }
      }
    }
    .main-block {
      .top-section {
        .gallery {
          grid-template-columns: min-content min-content auto 4em !important;
          grid-template-rows: auto !important;
          .title {
            writing-mode: vertical-rl;
            padding: 1em 0 1.5em 0 !important;
            width: fit-content;
            transform: rotate(180deg);
            h3 {
              padding-left: 0 !important;
              i.material-icons {
                transform: rotate(90deg);
              }
            }
            &.color1 {
              grid-column-start: 1 !important;
              grid-column-end: 2 !important;
              grid-row-end: 2 !important;
              grid-row-start: 1 !important;
            }
            &.color2 {
              grid-column-start: 2 !important;
              grid-column-end: 3 !important;
              grid-row-end: 2 !important;
              grid-row-start: 1 !important;
            }
          }
          .elements {
            padding-left: 3em;
            padding-bottom: 3em;
            grid-column-start: 3 !important;
            grid-column-end: 4 !important;
            grid-row-end: 2 !important;
            grid-row-start: 1 !important;
            grid-template-rows: min-content auto;
            grid-template-columns: 2fr 1fr;
            &.state-2 {
              .header {
                grid-column-start: 1;
                grid-column-end: 3;
                grid-row-end: 2;
                grid-row-start: 1;
              }
            }
            &.state-3 {
              .header {
                grid-column-start: 1;
                grid-column-end: 3;
                grid-row-end: 2;
                grid-row-start: 1;
              }
              .info {
                grid-column-start: 1;
                grid-column-end: 3;
                grid-row-end: 3;
                grid-row-start: 2;
              }
            }
          }
        }
        .layout {
          grid-template-columns: 1fr 1fr;
          grid-template-rows: auto;
          .data-block {
            .block {
              grid-template-columns: 2fr 1fr;
              grid-template-rows: auto;
              &.wide {
                grid-template-columns: 100%;
              }
            }
            &.wide {
              grid-column-start: 1;
              grid-column-end: 3;
            }
          }
        }
      }
    }
  }
}
@media (min-width: 992px) {
  .home {
    .main-block {
      .top-section {
        .gallery {
          grid-template-columns: min-content min-content auto 4em !important;
          grid-template-rows: auto !important;
          .title {
            writing-mode: vertical-rl;
            padding: 1em 0 1.5em 0 !important;
            width: fit-content;
            transform: rotate(180deg);
            h3 {
              padding-left: 0 !important;
              i.material-icons {
                transform: rotate(90deg);
              }
            }
            &.color1 {
              grid-column-start: 1 !important;
              grid-column-end: 2 !important;
              grid-row-end: 2 !important;
              grid-row-start: 1 !important;
            }
            &.color2 {
              grid-column-start: 2 !important;
              grid-column-end: 3 !important;
              grid-row-end: 2 !important;
              grid-row-start: 1 !important;
            }
          }
          .elements {
            padding-left: 13em;
            padding-right: 10em;
            padding-bottom: 3em;
            grid-column-start: 3 !important;
            grid-column-end: 4 !important;
            grid-row-end: 2 !important;
            grid-row-start: 1 !important;
            grid-template-rows: min-content auto;
            grid-template-columns: 2fr 1fr;
            &.state-2 {
              
            padding-left: 7.5em;
              .header {
                grid-column-start: 1;
                grid-column-end: 3;
                grid-row-end: 2;
                grid-row-start: 1;
              }
            }
            &.state-3 {
              
            padding-left: 5.5em;
              .header {
                grid-column-start: 1;
                grid-column-end: 3;
                grid-row-end: 2;
                grid-row-start: 1;
              }
              .info {
                grid-column-start: 1;
                grid-column-end: 3;
                grid-row-end: 3;
                grid-row-start: 2;
              }
            }
          }
        }
      }
    }
    .section {
      padding: 3em 13em;
      &.split {
        grid-template-columns: 1.5fr 2.5fr;
      }
    }
  }
}
</style>
