<template>
  <div class="home">
    <div class="search">
      <div>
        <h1 v-html="'Tipolog&iacute;as'"></h1>
      </div>
      
      <h4 v-html="'Museo de Sitio Puerto Cristal'"></h4>
      <div>
        <label>Nombre:</label>
      </div>
    </div>

    <Loading text="cargando" />
    <Message />
  </div>
</template>

<script>
// @ is an alias to /src
import Loading from "@/components/Loading.vue";
import Message from "@/components/Message.vue";
export default {
  name: "Typology-section",
  components: {
    Loading,
    Message
  },
  data: function() {
    return {
    };
  },
  methods: {
  }
};
</script>
<style lang="scss" scoped>
@import "../assets/sass/variables";
.home {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 70vh auto;
  grid-gap: 10px;
  width: 100vw;
  .search {
    background-image: linear-gradient(to bottom, transparent, rgba(0,0,0,0.75)), url("../assets/media/home.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: grid;
    grid-template-rows: calc(100% - 170px) 50px 120px;
    h1,h4{
      position: relative;
      text-align: center;
      padding-left: 10%;
      padding-right: 10%;
      margin-block-end: 0px;
      margin-block-start: 0px;
    }
  div{
      padding-left: 10%;
      padding-right: 10%;
    position: relative;
    h1{
      text-align: center;
      display: block;
      width: 100%;
      bottom: 0%;
      left: 50%;
      margin-bottom: 0px;
      position: absolute;
      transform: translateX(-50%);
    }
  }
    h1 {
      color: $theme-l4;
      vertical-align: text-bottom;
    }
    h4{
      color: wheat;
    }
    div {
      padding: 25px 10% 45px 10%;
      div {
        display: grid;
        grid-template-columns: auto 60px;
        border-radius: 50px;
        background-color: white;
        padding: 0px;
        overflow: hidden;
        input {
          height: 50px;
          border: none;
          margin-block-start: 0%;
          margin-block-end: 0%;
          margin-left: 20px;
          cursor: text;
        }
        button {
          margin-block-end: 0px;
          margin-block-start: 0px;
          background-color: $theme;
          border: none;
          cursor: pointer;
          i.material-icons {
            vertical-align: middle;
            font-size: 35px;
            color: white;
          }
        }
        button:disabled {
          opacity: 0.5;
          cursor: no-drop;
        }
      }
      .options {
        display: inline-block;
        position: relative;
        width: calc(100% - 80px);
        margin-left: 20px;
        height: 0px;
        z-index: 5 !important;
        overflow: visible;
        margin-top: 0px;
        height: fit-content;
        ul {
          background-color: white;
          border: solid 1px $text;
          margin-block-start: 0px;
          margin-block-end: 0px;
          padding-inline-start: 0px;
          li {
            padding-left: 10px;
            line-height: 27px;
            color: $text;
            margin-block-start: 0px;
            margin-block-end: 0px;
            padding: 3px 0px;
            cursor: pointer;
            list-style: none;
          }
          li.selected,
          li:hover {
            background-color: $theme;
            color: white;
            font-weight: 500;
          }
        }
      }
    }
  }
  .add {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    margin: 0px;
    background-color: #ededed80;
    z-index: 5;
    display: none;
    padding: 5%;
    div.box {
      top: 50%;
      left: 50%;
      width: 85%;
      border-radius: 10px;
      border: solid 5px hsla(0, 0%, 65%, 0.514);
      background-color: white;
      transform: translate(-50%, -50%) !important;
      position: absolute;
      padding: 15px 25px;
      max-height: 90vh;
      overflow-y: auto;
      .tabs {
        padding: 0px;
        width: 100%;
        top: 0;
        display: grid;
        grid-template-columns: 1fr 1fr;
        button {
          background-color: transparent;
          border: none;
          border-bottom: solid 6px $text-light;
          border-radius: 0px;
          cursor: pointer;
          padding: 8px 0px;
          font-weight: 300;
          width: 100%;
          color: $text;
          i {
            font-size: 24px;
            margin-right: 8px;
          }
          br {
            display: block;
          }
        }
        button:hover,
        button.selected {
          border-bottom-color: $theme;
        }
      }
      .tab1,
      .tab2 {
        display: none;
      }
      p.icon {
        text-align: right;
        i {
          font-size: 36px;
          cursor: pointer;
        }
        i:hover {
          font-weight: bold;
        }
        padding: 0px;
        color: $text;
        margin-block-start: 0px;
        width: 100%;
        margin-block-end: 0px;
      }
      h2 {
        margin-block-start: 0px;
        text-align: center;
      }
      p {
        color: $text;
        text-align: center;
      }
    }
  }
  .visible {
    display: block !important;
  }
}
@media (min-width: 768px) {
  .home{
    .search{
      .h1{
        padding-top: 25% !important;
      }
    }
  }
}
</style>
