<template>
  <div class="home">
    <div class="search">
      <div class="video-container">
        <video autoplay muted loop>
          <source
            src="https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2Fweb.mp4?alt=media&token=3f37d774-06ba-4b91-b7d6-f7f852e97331&_gl=1*128usbx*_ga*NjAzNTQwNTkxLjE2ODU3MjcyNzM.*_ga_CW55HF8NVT*MTY4NjUzMjA3OS4yMy4xLjE2ODY1MzgyMzUuMC4wLjA."
            type="video/mp4"
          />
        </video>
      </div>

      <div class="content">
        <h3 v-html="'Descubre'"></h3>
        <h1 v-html="'PUERTO'"></h1>
        <h1 v-html="'CRISTAL'"></h1>
        <h3 v-html="'y sus rincones'"></h3>
      </div>
    </div>
    <div class="section color2 split">
      <div><div class="image" id="img1"></div></div>
      <div>
        <div class="content">
          <h1 v-html="'Contexto Hist&oacute;rico'"></h1>
          <p
            v-html="
              'El Monumento Hist&oacute;rico Campamento Minero Puerto Cristal, ubicado en la ribera norte del Lago General Carrera, fue declarado Monumento Nacional en agosto del a&ntilde;o 2008 (Ley 17.288; D.E. 2507). Forma parte del patrimonio industrial de Chile y se constituye como uno de los monumentos nacionales de mayor envergadura de la regi&oacute;n de Ays&eacute;n. El campamento minero signific&oacute; un foco de desarrollo econ&oacute;mico para la regi&oacute;n durante la segunda mitad del siglo XX. Su legado cultural posee un valor hist&oacute;rico, tecnol&oacute;gico, social, arquitect&oacute;nico y cient&iacute;fico muy relevante, tanto para la historia de la regi&oacute;n de Ays&eacute;n, como para la historia de la miner&iacute;a en Chile.'
            "
          ></p>
          <p
            v-html="
              'Puerto Cristal nace en la d&eacute;cada de los a&ntilde;os cincuenta, cuando se produce el auge de la miner&iacute;a extractiva en la cuenca del Lago General Carrera, donde la Compa&ntilde;&iacute;a Minera Ays&eacute;n lleg&oacute; a producir hasta el 50% del plomo y del zinc de la producci&oacute;n nacional. Para esa fecha, el campamento minero, contaba con aproximadamente, novecientos habitantes. En 1963, una crisis financiera forz&oacute; el traspaso de la minera a la Corporaci&oacute;n de Fomento de la Producci&oacute;n (CORFO), terminando m&aacute;s tarde sus operaciones como Empresa Minera Ays&eacute;n Ltda. (EMA). En 1994, pas&oacute; a manos de la Sociedad Vecam Ltda. y actualmente pertenece a la Sociedad Comercial Casanova y Soto Ltda., quienes, debido a la ca&iacute;da del precio en el mercado durante la crisis asi&aacute;tica, decidieron detener las faenas de extracci&oacute;n del mineral en el a&ntilde;o 1996. '
            "
          ></p>
          <p
            v-html="
              'La miner&iacute;a en la cuenca del lago tuvo un fuerte impacto en la regi&oacute;n, impulsando una mayor colonizaci&oacute;n del territorio y la construcci&oacute;n de obras viales que hasta hoy permiten una mejor conectividad dentro del territorio regional. El patrimonio inmaterial de Puerto Cristal se manifiesta en su m&aacute;xima expresi&oacute;n, con la Agrupaci&oacute;n Social y Cultural Los Cristalinos, de Chile Chico, constituida en el a&ntilde;o 2005 por antiguos habitantes de Puerto Cristal y sus descendientes, quienes organizan todos los a&ntilde;os, un viaje al campamento, con el fin de visitar a sus antepasados en el cementerio y para realizar trabajos de puesta en valor de su memoria.'
            "
          ></p>
          <p
            v-html="
              'Debido a este nivel de desarrollo, es que en Puerto Cristal se pueden encontrar diversos hitos y lugares de inter&eacute;s que contribu&iacute;an al desarrollo de actividades productivas y cotidianas.'
            "
          ></p>
        </div>
      </div>
    </div>
    <div class="section full">
      <div>
        <div class="content">
          <h1 v-html="'Sitios de Inter&eacute;s'"></h1>
          <p
            v-html="
              'La Corporaci&oacute;n Puerto Cristal trabaja en colaboraci&oacute;n con instituciones que contribuyen al fortalecimiento del Museo, a trav&eacute;s iniciativas de investigaci&oacute;n, dise&ntilde;o, promoci&oacute;n e intervenci&oacute;n en este lugar.'
            "
          ></p>
          <CardSlider :setup="{ list: col ,target:'self'}" />
        </div>
      </div>
    </div>
    <Loading text="cargando" />
    <Message />
  </div>
</template>
<script>
// @ is an alias to /src
import Loading from "@/components/Loading.vue";
import Message from "@/components/Message.vue";
import CardSlider from "@/components/CardSlider.vue";

import { mapMutations } from "vuex";
export default {
  name: "Home-page",
  components: {
    Loading,
    Message,
    CardSlider,
  },
  data: function () {
    return {
      col: [
        {
          link: "/puerto_cristal/mina",
          imagen: "https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2FMINA%20-53.jpg?alt=media&token=7e3af4cc-3b9c-44f4-92ff-c763e3028c64&_gl=1*i99k4j*_ga*NjAzNTQwNTkxLjE2ODU3MjcyNzM.*_ga_CW55HF8NVT*MTY4NTcyNzI3My4xLjEuMTY4NTcyNzQ1Ny4wLjAuMA..",
          title: "Mina",
          id:'Sector Mina',
          content:
            "Sector Mina",
        },
        {
          link: "/puerto_cristal/planta_concentrados",
          imagen: "https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2FPLANTA%20-10.jpg?alt=media&token=9f124e74-f2db-41e9-8cd0-9e0bfc5b8bfc&_gl=1*1tkyoje*_ga*NjAzNTQwNTkxLjE2ODU3MjcyNzM.*_ga_CW55HF8NVT*MTY4NTc1NzExNS4yLjEuMTY4NTc1NzE1Ni4wLjAuMA..",
          title: "Planta de Concentrados",
          id:'Sector Industrial',
          content:
            "Sector Mina",
        },
        {
          link: "/puerto_cristal/laboratorio",
          imagen: "https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2FLABORATORIO%20Y%20TALLER%20-01.jpg?alt=media&token=fbfc31d0-e963-4571-9758-3b31c5f760f2&_gl=1*dw568t*_ga*NjAzNTQwNTkxLjE2ODU3MjcyNzM.*_ga_CW55HF8NVT*MTY4NTc1NzExNS4yLjEuMTY4NTc1NzIzNS4wLjAuMA..",
          title: "Laboratorio",
          id:'Sector Industrial',
          content:
            "Sector Industrial",
        },
        {
          link: "/puerto_cristal/taller_electrico",
          imagen: "https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2FLABORATORIO%20Y%20TALLER%20-13.jpg?alt=media&token=30928ad5-6b57-45b0-837f-039087c83070&_gl=1*1unr51s*_ga*NjAzNTQwNTkxLjE2ODU3MjcyNzM.*_ga_CW55HF8NVT*MTY4NTc1NzExNS4yLjEuMTY4NTc1NzM2My4wLjAuMA..",
          title: "Planta Hidroel&eacute;ctica",
          id:'Sector Industrial',
          content:
            "Sector Industrial",
        },
        {
          link: "/puerto_cristal/bodegas_portuarias",
          imagen: "https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F_DSC0368.jpg?alt=media&token=cba54929-22e1-49fa-83fc-d96284f9006e&_gl=1*r1j16j*_ga*NjAzNTQwNTkxLjE2ODU3MjcyNzM.*_ga_CW55HF8NVT*MTY4NTc1NzExNS4yLjEuMTY4NTc1NzQ0Ni4wLjAuMA..",
          title: "Bodegas Portuarias",
          id:'Sector Industrial',
          content:
            "Sector Industrial",
        },
        {
          link: "/puerto_cristal/abastecimiento",
          imagen: "https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2FDSC_2651.jpg?alt=media&token=858fea3a-44fd-48ff-8e53-b232926467a0&_gl=1*1ttj5b7*_ga*NjAzNTQwNTkxLjE2ODU3MjcyNzM.*_ga_CW55HF8NVT*MTY4NTc5MzYyMy4zLjEuMTY4NTc5NDU3MS4wLjAuMA..",
          title: "Abastecimiento",
          id:'Sector Campamento',
          content:
            "Sector Industrial",
        },
        {
          link: "/puerto_cristal/vivienda",
          imagen: "https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2Fsii%20VIVIENDAS%20OBREROS%20-05.jpg?alt=media&token=a29fc215-5811-49fc-9747-9e3dfa97e0d6&_gl=1*18d1ct8*_ga*NjAzNTQwNTkxLjE2ODU3MjcyNzM.*_ga_CW55HF8NVT*MTY4NTc5MzYyMy4zLjEuMTY4NTc5NDgzOS4wLjAuMA..",
          title: "Vivienda",
          id:'Sector Campamento',
          content:
            "Sector Industrial",
        },
        {
          link: "/puerto_cristal/servicios",
          imagen: "https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2FSAM_4994.jpg?alt=media&token=0c72654d-8fb8-4b70-b92b-b246c607be51&_gl=1*1o5ly02*_ga*NjAzNTQwNTkxLjE2ODU3MjcyNzM.*_ga_CW55HF8NVT*MTY4NTc5MzYyMy4zLjEuMTY4NTc5NDgwOS4wLjAuMA..",
          title: "Servicios",
          id:'Sector Campamento',
          content:
            "Sector Industrial",
        },
        {
          link: "/puerto_cristal/vida_cotidiana",
          imagen: "https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2FLastScan%20109.jpg?alt=media&token=7b364113-c2bd-4b87-b70d-57fc60aa1aae&_gl=1*1qetipm*_ga*NjAzNTQwNTkxLjE2ODU3MjcyNzM.*_ga_CW55HF8NVT*MTY4NTc5MzYyMy4zLjEuMTY4NTc5NDM3NS4wLjAuMA..",
          title: "Vida Cotidiana",
          id:'Sector Campamento',
          content:
            "Sector Industrial",
        },
        {
          link: "/puerto_cristal/vida_espiritual",
          imagen: "https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2FIGLESIA03.jpg?alt=media&token=d372f201-1d19-4b9a-abef-fd275fcd0a4e&_gl=1*5hs6mv*_ga*NjAzNTQwNTkxLjE2ODU3MjcyNzM.*_ga_CW55HF8NVT*MTY4NTc5MzYyMy4zLjEuMTY4NTc5NDc1Ni4wLjAuMA..",
          title: "Vida Espiritual",
          id:'Sector Campamento',
          content:
            "Sector Industrial",
        },
      ],
    };
  },
  created() {
    this.SET_LOADING(false);
  },
  methods: {
    ...mapMutations(["SET_LOADING"]),
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/sass/variables";
@import "../../assets/sass/buttons";
@import "../../assets/sass/cards";

.home {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 70vh auto;
  width: 100vw;
  div.autocomplete-container {
    padding: 25px 13em 45px 3em;
    div {
      display: grid;
      grid-template-columns: auto 60px;
      grid-template-rows: 50px;
      border-radius: 50px;
      background-color: white;
      padding: 0px;
      overflow: visible;
      .radius-left {
        border-bottom-left-radius: 50px;
        border-top-left-radius: 50px;
      }
      .radius-right {
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
      }

      button {
        margin-block-end: 0px;
        margin-block-start: 0px;
        background-color: $theme;
        border: none;
        cursor: pointer;
        i.material-icons {
          vertical-align: middle;
          font-size: 35px;
          color: white;
        }
      }
      button:disabled {
        opacity: 0.5;
        cursor: no-drop;
      }
    }
  }
  .search {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
    overflow: hidden;
    .video-container {
      position: absolute;
      width: 100%;
      height: 100%;
      video {
        min-width: 100%;
        min-height: 100%;

        /* Setting width & height to auto prevents the browser from stretching or squishing the video */
        width: auto;
        height: auto;

        /* Center the video */
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .content {
      background-color: rgba($color: #ffb980, $alpha: 0.6);
      position: relative;
      bottom: 0%;
      padding: 2em;
      margin: auto 2em 2em 2em;
      h3 {
        font-size: 1.4em;
        color: $theme-d3;
        font-weight: 500;
      }
      h1 {
        font-size: 3em;
        font-weight: 700;
        color: $theme-d3;
        margin-block-end: 0;
        margin-block-start: 0;
      }
    }
  }
  .section {
    position: relative;
    padding: 2em 2em;
    display: grid;
    grid-template-rows: auto;
    gap: 1em;
    &.color1 {
      background-color: $blue-grey;
      h1,
      h2,
      h3,
      h4,
      h5 {
        color: $grey-2;
      }
      p,
      li {
        color: $grey-1;
      }
    }
    &.color2 {
      background-color: $grey-1;
      h1,
      h2,
      h3,
      h4,
      h5 {
        color: $theme-d1;
      }
      p,
      li {
        color: $theme-d3;
      }
    }

    &.color3 {
      background-color: $theme-l4;
      h1,
      h2,
      h3,
      h4,
      h5 {
        color: $theme-d2;
      }
      p,
      li {
        color: $blue-grey-dark;
      }
    }
    &.split,
    &.full {
      height: 100%;
      div {
        position: relative;
        height: 100% !important;
        display: block;
        .title,
        .content {
          width: 100%;
          display: block;
          height: fit-content;
          h1 {
            font-size: 3em;
            font-weight: 700;
            margin-block-start: 0;
            margin-block-end: 0;
          }
          p {
            font-size: 1.2em;
            font-weight: 300;
          }
        }
        .image {
          border-radius: 1em;
          width: 100%;
          padding-top: 100%;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          &#img1 {
            background-image: url("../../assets/media/puerto_cristal/DSC04031.JPG");
          }
        }
      }
    }
  }
}
@media (min-width: 768px) {
  .home {
    grid-template-rows: 75vh auto;
    .search {
      .video-container {
        video {
          min-height: 100%;
          min-width: 100%;
        }
      }
      .content {
        margin-left: 5em !important;
      }
    }
    .section {
      padding: 3em 5em;
      gap: 2em;
      &.split {
        grid-template-columns: 1.5fr 2.5fr;
      }
    }
  }
}
@media (min-width: 992px) {
  .home {
    grid-template-rows: 75vh auto;
    .search {
      .video-container {
        video {
          min-height: 100%;
          min-width: 100%;
        }
      }
      .content {
        margin-left: 11em !important;
      }
    }
    .section {
      padding: 3em 13em;
      &.split {
        grid-template-columns: 1.5fr 2.5fr;
      }
    }
  }
}
</style>
