<template>
  <div class="main-container">
    <div class="search" :style="{'background-image': 'url('+currentItem.image+')'}">
      <h2 v-html="currentItem.name"></h2>
      
    </div>
    <div class="edit-options" v-show="user !== null">
        <div class="left">
          <p v-html="'Editar Informaci&oacute;n: '"></p>
          <div class="onoffswitch">
            <input
              type="checkbox"
              name="onoffswitch"
              class="onoffswitch-checkbox"
              id="myonoffswitch"
              tabindex="0"
              v-model="editMode"
              @click="editMode = !editMode"
            />
            <label class="onoffswitch-label" for="myonoffswitch">
              <span class="onoffswitch-inner"></span>
              <span class="onoffswitch-switch"></span>
            </label>
          </div>
        </div>
        
      </div>
    <section class="page-container">
      <div class="display-data" v-if="!editMode">
        <h2 v-html="'Informaci&oacute;n del Inmueble o Sitio'"></h2>
        <li>
          <p><strong v-html="'C&oacute;digo Identificador:'"></strong> {{currentItem.id}}</p>
        </li>
        <li>
          <p><strong v-html="'Descripci&oacute;n del Inmueble:'"></strong> {{currentItem.descripcion}}</p>
        </li>
      </div>
      <div class="edit-data" v-if="editMode">
        <h2 v-html="'Editar Inmueble o Sitio'"></h2>
        <li>
          <p><strong v-html="'C&oacute;digo Identificador:'"></strong> {{currentItem.id}}</p>
        </li>
        <li>
          <p><strong v-html="'Descripci&oacute;n del Inmueble:'"></strong> asdfasdfasdfadsf sdfasdfsdfasdf asdfasdfasfasdfadfasdfasdfasdf</p>
        </li>
      </div>
    </section>
    <div class="modal" v-show="addModule">
      <SimpleBar class="box">
        <div class="top" @click="addModule = false"></div>
        <div class="left" @click="addModule = false"></div>
        <div class="right" @click="addModule = false"></div>
        <div class="bottom" @click="addModule = false"></div>
        <button class="close" @click="addModule = false">
          <i class="material-icons">close</i>
        </button>
        <h4 v-html="'Agregar Sitio'"></h4>
        <label v-html="'ID:'"></label>
        <input type="text" v-model="addModuleID" />
        <label v-html="'Nombre:'"></label>
        <input type="text" v-model="addModuleName" />
        <label v-html="'Definici&oacute;n:'"></label>
        <textarea
          name
          id
          cols="30"
          rows="10"
          v-model="addModuleDescription"
        ></textarea>
        <label v-html="'Fuente:'"></label>
        <input type="text" v-model="addModuleSource" />
        <p class="btn-center">
          <button class="ok-btn" @click="addNewItem">Guardar</button>
          <button class="cancel-btn" @click="addModule = false">
            Cancelar
          </button>
        </p>
      </SimpleBar>
    </div>
    <Loading text="cargando" />
    <Message />
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";

//import EditDefinition from "@/components/EditDefinition.vue"
import {SimpleBar} from "simplebar-vue3";
import Message from "@/components/Message.vue";
import Loading from "@/components/Loading.vue";
export default {
  name: "Building-section",
  components: { SimpleBar, Message, Loading },
  data() {
    return {
      list: [],
      currentIndex: "B",
      currentId: "",
      itemId: "",
      allItems: [],
      searchedId: "",
      displayData: false,
      editMode: false,
      addModule: false,
      addModuleName: "",
      addModuleID: "",
      addModuleDescription: "",
      addModuleSource: "",
    };
  },
  computed: {
    ...mapGetters("sitios", {
      item: "itemById",
    }),
    currentItem() {
      return this.item(this.$route.params.id)
    },
  },
  mounted() {
    this.currentId = this.$route.params.id;
    this.setContent();
    this.currentIndex = this.indexList[0];
  },
  watch: {
    getMessageAction(payload) {
      if (payload.component === "addSuccess") {
        console.log("entra");
        this.addModule = false;
        this.SET_MESSAGE_PARAMETERS({});
      }
    },
    currentIndex(val) {
      this.list = this.itemsByIndex(val);
    },
    itemId(val) {
      this.currentItem = this.allItems.find((item) => item.id === val);
    },
  },
  methods: {
    ...mapActions("materials", {
      addMaterial: "addItem",
    }),
    ...mapActions("techniques", {
      addTechnic: "addItem",
    }),
    ...mapActions("typologies", {
      addTypology: "addItem",
    }),
    ...mapActions("glossary", {
      addDefinicion: "addItem",
    }),
    ...mapMutations(["SET_MESSAGE_PARAMETERS", "SET_LOADING"]),
    setContent() {
      if (this.currentId === "materials") {
        if (this.indexMaterials && this.indexMaterials.length > 0) {
          this.currentIndex = this.indexMaterials[0];
          this.indexList = this.indexMaterials;
        }
        this.title = "Administrar Materiales";
        this.list = this.materialsByIndex(this.currentIndex);
        this.allItems = this.materials;
      } else if (this.currentId === "techniques") {
        if (this.indexTechniques && this.indexTechniques.length > 0) {
          this.currentIndex = this.indexTechniques[0];
          this.indexList = this.indexTechniques;
        }
        this.title = "Administrar T&eacute;cnicas";
        this.list = this.techniquesByIndex(this.currentIndex);
        this.allItems = this.techniques;
      } else if (this.currentId === "typologies") {
        if (this.indexTypologies && this.indexTypologies.length > 0) {
          this.currentIndex = this.indexTypologies[0];
          this.indexList = this.indexTypologies;
        }
        this.title = "Administrar Tipolog&iacute;as";
        this.list = this.typologiesByIndex(this.currentIndex);
        this.allItems = this.typologies;
      } else if (this.currentId === "definitions") {
        if (this.indexGlossary && this.indexGlossary.length > 0) {
          this.currentIndex = this.indexGlossary[0];
          this.indexList = this.indexGlossary;
        }
        this.title = "Administrar Definiciones";
        this.list = this.glossaryByIndex(this.currentIndex);
        this.allItems = this.glossary;
      }
    },
    getId(payload) {
      console.log(payload);
    },
    addNewItem() {
      let response;
      if (this.currentId === "materials") {
        this.SET_LOADING(true);
        response = this.addMaterial({
          name: this.addModuleName,
          definition: this.addModuleDescription,
          source: this.addModuleSource,
        });
        if (response) {
          this.SET_LOADING(false);
          this.addModule = false;
          this.SET_MESSAGE_PARAMETERS({
            isVisible: true,
            type: "success",
            icon: "done",
            title: "Definici&oacute;n Agregada",
            message: "Se ha agregado correctamente la Definici&oacute;n",
            buttonAction: "",
            button: "Aceptar",
            respondTo: "addSuccess",
            action: 0,
          });
        } else {
          console.log(response);
        }
      } else if (this.currentId === "techniques") {
        this.SET_LOADING(true);
        response = this.addTechnic({
          name: this.addModuleName,
          definition: this.addModuleDescription,
          source: this.addModuleSource,
        });
        if (response) {
          this.SET_LOADING(false);
          this.addModule = false;
          this.SET_MESSAGE_PARAMETERS({
            isVisible: true,
            type: "success",
            icon: "done",
            title: "Definici&oacute;n Agregada",
            message: "Se ha agregado correctamente la Definici&oacute;n",
            buttonAction: "",
            button: "Aceptar",
            respondTo: "addSuccess",
            action: 0,
          });
        } else {
          console.log(response);
        }
      } else if (this.currentId === "typologies") {
        this.SET_LOADING(true);
        response = this.addTypology({
          name: this.addModuleName,
          definition: this.addModuleDescription,
          source: this.addModuleSource,
        });
        if (response) {
          this.SET_LOADING(false);
          this.addModule = false;
          this.SET_MESSAGE_PARAMETERS({
            isVisible: true,
            type: "success",
            icon: "done",
            title: "Definici&oacute;n Agregada",
            message: "Se ha agregado correctamente la Definici&oacute;n",
            buttonAction: "",
            button: "Aceptar",
            respondTo: "addSuccess",
            action: 0,
          });
        } else {
          console.log(response);
        }
      } else if (this.currentId === "definitions") {
        this.SET_LOADING(true);
        response = this.addDefinicion({
          name: this.addModuleName,
          definition: this.addModuleDescription,
          source: this.addModuleSource,
        });
        if (response) {
          this.SET_LOADING(false);
          this.addModule = false;
          this.SET_MESSAGE_PARAMETERS({
            isVisible: true,
            type: "success",
            icon: "done",
            title: "Definici&oacute;n Agregada",
            message: "Se ha agregado correctamente la Definici&oacute;n",
            buttonAction: "",
            button: "Aceptar",
            respondTo: "addSuccess",
            action: 0,
          });
        } else {
          console.log(response);
        }
      }
    },
    search() {},
    
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/sass/variables";
@import "./../assets/sass/buttons";
@import "./../assets/sass/modal";
.main-container {
  
  .search {
    background-image: linear-gradient(
        to bottom,
        transparent,
        rgba(0, 0, 0, 0.75)
      );
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding-top: 25vh;
    padding-bottom: 15vh;
    h1,
    h2 {
      position: relative;
      text-align: center;
      color: $theme-l4;
      padding-left: 10%;
      padding-right: 10%;
      margin-block-end: 0px;
      margin-block-start: 0px;
      padding-top: 15%;
    }
    div {
      display: grid;
      padding-left: 10%;
      padding-right: 10%;
      grid-template-columns: 1.5fr 0.25fr;
      div.options {
        .icon-container {
          padding-top: 25px;
          padding-bottom: 25px;
          button {
            background-color: $theme;
            color: white;
            border-radius: 50px;
          }
        }
      }
    }
  }
  .edit-options {
    display: grid;
    grid-template-columns: 1fr;
    padding-left: 2em;
    padding-right: 2em;
    margin-bottom: 10px;
    .left {
      margin-top: 4.5px;
      p {
        display: inline;
        color: $text-dark;
        line-height: 30px;
        margin-block-end: 0;
        margin-block-start: 0;
        vertical-align: middle;
        top: 0%;
        margin-right: 10px;
      }
    }
  }
  .page-container {
    padding: 0.7em 2em;
    grid-gap: 10px;
    margin-bottom: 10px;
    .display-data{
      background-color: $blue-grey;
      border-radius: 10px;
      padding: 2em 1.5em;
      h2{
        color: $grey-1;
        font-weight: 700;
      }
      p{
        color: white;
      }
      li{
        list-style: none;
        margin-block-start: 0%;
        margin-block-end: 0%;
        p{
          font-size: 1.2em;
        }
      }
    }
  }
}
</style>