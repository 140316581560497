<template>
  <div class="settings-container">
    <div class="section">
      <div class="card wide-left dark-theme">
        <div class="title"><h3 v-html="'Estad&iacute;sticas'"></h3></div>
        <p
          v-html="
            'En esta secci&oacute;n podr&aacute;s obtener informaci&oacute;n estad&iacute;stica de la colecci&oacute;n de objetos del Museo de Puerto Cristal y su composici&oacute;n.'
          "
        ></p>
      </div>
      <div class="card split white">
        <div class="title"><h5>Recuento</h5></div>
        <div class="number left">
          <p>{{ allItems.length }}</p>
        </div>
        <div class="data right"><p>Objetos registrados</p></div>
      </div>
      <div class="card chart white">
        <div class="title">
          <h5 v-html="'Clasificaci&oacute;n de los Objetos'"></h5>
        </div>
        <div
          class="pie right"
          :style="{ backgroundImage: statsClasificacion.css }"
        >
          <div
            class="percent-container"
            v-for="item in statsClasificacion.data"
            :key="item.id"
            :style="{
              left: item.left + '%',
              top: item.top + '%',
            }"
          >
            <p
              :style="{
                color: item.text,
              }"
            >
              {{ Math.round(item.percent * 100) }}%
            </p>
          </div>
          <div class="donat"></div>
        </div>
        <div class="left">
          <div
            class="dot-container"
            v-for="(item, index) in statsClasificacion.data"
            :key="index"
          >
            <div>
              <div
                class="dot"
                :style="{
                  'background-color': item.color,
                }"
              ></div>
            </div>
            <p
              v-html="
                '<b>' +
                item.estadistica +
                '</b>: Objetos en categor&iacute;a ' +
                item.name
              "
            ></p>
          </div>
        </div>
      </div>
      <div class="card wide-right white chart">
        <div class="title">
          <h5 v-html="'Lugar de Hallazgo'"></h5>
          <p
            v-html="
              'Aqu&iacute; se presentan los lugares en d&oacute;nde han sido encontrado los objetos.'
            "
          ></p>
        </div>

        <div class="frequency">
          <template v-for="el in frecuenciaSitios" :key="el.id">
            <div
              class="turquesa left-f"
              :style="{
                width: el.porcentaje * 100 + '%',
              }"
            >
              <p style="text-align: center">{{ el.estadistica }}</p>
            </div>
            <div class="right-f">
              <p>{{ el.name }}</p>
            </div>
          </template>
        </div>
      </div>
      <div class="card chart white">
        <div class="title"><h5>Estructura de los Objetos</h5></div>
        <div
          class="pie right"
          :style="{ backgroundImage: estadistica('conjunto').css }"
        >
          <div
            class="percent-container"
            v-for="(item, index) in estadistica('conjunto').data"
            :key="index"
            :style="{
              left: item.left + '%',
              top: item.top + '%',
            }"
          >
            <p
              :style="{
                color: item.textColor,
              }"
            >
              {{ item.percent }}%
            </p>
          </div>
        <div class="donat"></div>
        </div>
        <div class="left">
          <div
            class="dot-container"
            v-for="(item, index) in estadistica('conjunto').data"
            :key="index"
          >
            <div>
              <div
                class="dot"
                :style="{
                  'background-color': item.color,
                }"
              ></div>
            </div>
            <p v-html="'<b>' + item.estadistica + '</b>: ' + item.name"></p>
          </div>
        </div>
      </div>
      <div class="card chart white">
        <div class="title"><h5>Acceso a los Objetos</h5></div>
        <div
          class="pie right"
          :style="{ backgroundImage: estadistica('publicados').css }"
        >
          <div
            class="percent-container"
            v-for="(item, index) in estadistica('publicados').data"
            :key="index"
            :style="{
              left: item.left + '%',
              top: item.top + '%',
            }"
          >
            <p
              :style="{
                color: item.textColor,
              }"
            >
              {{ item.percent }}%
            </p>
          </div>
        <div class="donat"></div>
        </div>
        <div class="left">
          <div
            class="dot-container"
            v-for="(item, index) in estadistica('publicados').data"
            :key="index"
          >
            <div>
              <div
                class="dot"
                :style="{
                  'background-color': item.color,
                }"
              ></div>
            </div>
            <p v-html="'<b>' + item.estadistica + '</b>: ' + item.name"></p>
          </div>
        </div>
      </div>
      <div class="card chart white">
        <div class="title"><h5 v-html="'Ubicaci&oacute;n actual'"></h5></div>
        <div
          class="pie right"
          :style="{ backgroundImage: estadistica('exhibicion').css }"
        >
          <div
            class="percent-container"
            v-for="(item, index) in estadistica('exhibicion').data"
            :key="index"
            :style="{
              left: item.left + '%',
              top: item.top + '%',
            }"
          >
            <p
              :style="{
                color: item.textColor,
              }"
            >
              {{ item.percent }}%
            </p>
          </div>
        <div class="donat"></div>
        </div>
        <div class="left">
          <div
            class="dot-container"
            v-for="(item, index) in estadistica('exhibicion').data"
            :key="index"
          >
            <div>
              <div
                class="dot"
                :style="{
                  'background-color': item.color,
                }"
              ></div>
            </div>
            <p v-html="'<b>' + item.estadistica + '</b>: ' + item.name"></p>
          </div>
        </div>
      </div>
      <div
        class="across"
        :class="{ split: !displayTechniques && !displayMaterials }"
      >
        <div class="card chart white">
          <div class="title">
            <h5 v-html="'Materiales'"></h5>
            <p
              v-html="
                'Se presentan los materiales utilizados con mayor frecuencia en la colecci&oacute;n, tanto el n&uacute;mero de veces que se repito como el porcentaje que representa.'
              "
            ></p>
          </div>

          <div class="frequency" v-if="!displayMaterials">
            <template v-for="el in frecuenciaMateriales" :key="el.id">
              <div
                class="theme-d4 left-f"
                :style="{
                  width: el.porcentaje * 100 + '%',
                }"
              >
                <p style="text-align: center">{{ el.estadistica }}</p>
              </div>
              <div class="right-f">
                <p>{{ el.name }}</p>
              </div>
            </template>
          </div>
          <div class="frequency" v-if="displayMaterials">
            <template v-for="el in estadisticaMateriales" :key="el.id">
              <div
                v-if="el.porcentaje > 0"
                class="theme-d4 left-f"
                :style="{
                  width: el.porcentaje * 100 + '%',
                }"
              >
                <p style="text-align: center">{{ el.estadistica }}</p>
              </div>
              <div class="right-f" v-if="el.porcentaje > 0">
                <p>{{ el.name }}</p>
              </div>
            </template>
          </div>
          <p
            v-html="'+ Ver M&aacute;s'"
            class="more"
            @click="displayMaterials = true"
            v-if="!displayMaterials"
          ></p>
          <p
            v-html="'+ Ver Menos'"
            class="more"
            @click="displayMaterials = false"
            v-else
          ></p>
        </div>
        <div class="card chart white">
          <div class="title">
            <h5 v-html="'T&eacute;cnicas'"></h5>
            <p
              v-html="
                'Se presentan las t&eacute;cnicas utilizadas con mayor frecuencia en la colecci&oacute;n, tanto el n&uacute;mero de veces que se repito como el porcentaje que representa.'
              "
            ></p>
          </div>
          <div class="frequency" v-if="!displayTechniques">
            <template v-for="el in frecuenciaTecnicas" :key="el.id">
              <div
                class="blue-grey left-f"
                :style="{
                  width: el.porcentaje * 100 + '%',
                }"
              >
                <p style="text-align: center">{{ el.estadistica }}</p>
              </div>
              <div class="right-f">
                <p>{{ el.name }}</p>
              </div>
            </template>
          </div>
          <div class="frequency" v-if="displayTechniques">
            <template v-for="el in estadisticaTecnicas" :key="el.id">
              <div
                class="blue-grey left-f"
                :style="{
                  width: el.porcentaje * 100 + '%',
                }"
                v-if="el.porcentaje > 0"
              >
                <p style="text-align: center">{{ el.estadistica }}</p>
              </div>
              <div class="right-f" v-if="el.porcentaje > 0">
                <p>{{ el.name }}</p>
              </div>
            </template>
          </div>
          <p
            v-html="'+ Ver M&aacute;s'"
            class="more"
            @click="displayTechniques = true"
            v-if="!displayTechniques"
          ></p>
          <p
            v-html="'+ Ver Menos'"
            class="more"
            @click="displayTechniques = false"
            v-else
          ></p>
        </div>
      </div>
    </div>
    <SimpleBar v-if="false"></SimpleBar>
    <Loading text="cargando" />
    <Message />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

//import EditDefinition from "@/components/EditDefinition.vue"
import { SimpleBar } from "simplebar-vue3";
import Message from "@/components/Message.vue";
import Loading from "@/components/Loading.vue";
export default {
  name: "Statistics-section",
  components: { SimpleBar, Message, Loading },
  data() {
    return {
      displayTechniques: false,
      displayMaterials: false,
    };
  },
  computed: {
    ...mapGetters(["allItems"]),
    ...mapGetters("sitios", {
      sites: "sitios",
      estadisticaSitios: "statistics",
    }),
    ...mapGetters("materials", {
      materials: "items",
      indexMaterials: "abcItems",
      materialsByIndex: "itemsByIndex",
      estadisticaMateriales: "statistics",
    }),
    ...mapGetters("techniques", {
      techniques: "items",
      indexTechniques: "abcItems",
      techniquesByIndex: "itemsByIndex",
      estadisticaTecnicas: "statistics",
    }),
    frecuenciaMateriales() {
      let arr = this.estadisticaMateriales.slice(0, 5);
      return arr;
    },
    frecuenciaTecnicas() {
      let arr = this.estadisticaTecnicas.slice(0, 5);
      return arr;
    },

    frecuenciaSitios() {
      let arr = this.estadisticaSitios.slice(0, 12);
      return arr;
    },
    statsClasificacion() {
      let palet = [
        {
          color: "#ffb980",
          text: "#331700",
        },
        {
          color: "#ff9d4d",
          text: "#331700",
        },
        {
          color: "#cc5c00",
          text: "#ffffff",
        },
        {
          color: "#994500",
          text: "#ffffff",
        },
        {
          color: "#662e00",
          text: "#ffffff",
        },
        {
          color: "#331700",
          text: "#ffffff",
        },
      ];
      let data = [];
      let val;
      this.allItems.forEach((elem) => {
        val = data.find((item) => item.id === elem.clasification[0].id);
        if (val) {
          val.estadistica++;
        } else {
          data.push({
            id: elem.clasification[0].id,
            name: elem.clasification[0].name,
            estadistica: 1,
          });
        }
      });
      let arr = [];

      let pos = 0;
      data.forEach((elem) => {
        pos = 0;
        elem.percent = elem.estadistica / this.allItems.length;
        arr.forEach((item) => {
          if (item.estadistica < elem.estadistica) {
            pos++;
          }
        });
        arr.splice(pos, 0, elem);
      });
      let css = "conic-gradient(";
      let lastDeg = 0;
      arr.forEach((elem, index) => {
        if (index < arr.length - 1) {
          css =
            css +
            palet[index].color +
            " " +
            lastDeg +
            "deg, " +
            palet[index].color +
            " " +
            (lastDeg + elem.percent * 360) +
            "deg, ";
          lastDeg = lastDeg + elem.percent * 360;
        } else {
          css = css + palet[index].color + " " + lastDeg + "deg";
          lastDeg = lastDeg + elem.percent * 360;
        }
        elem.left =
          Math.sin(3.14 * ((lastDeg - (elem.percent * 360) / 2) / 180)) * 40 +
          50;
        elem.top =
          50 -
          Math.cos(3.14 * ((lastDeg - (elem.percent * 360) / 2) / 180)) * 40;
        elem.color = palet[index].color;
        elem.text = palet[index].text;
      });
      css = css + ")";
      return { css: css, data: arr };
    },
  },
  onMounted() {
    this.obtenerEstadistica();
  },
  watch: {
    allItems() {},
  },
  methods: {
    ...mapActions(["getAllObjects"]),
    ...mapActions("materials", {
      obtenerEstadistica: "getStatistics",
    }),
    estadistica(payload) {
      let i = 0;
      let j = 0;
      let mainColor = "";
      let secundaryColor = "";
      let mainTextColor = "";
      let secundaryTextColor = "";
      let secundaryPercent = 0;
      let mainPercent = 0;
      let secundaryText = "";
      let mainText = "";
      switch (payload) {
        case "conjunto":
          this.allItems.forEach((el) => {
            if (el.compuesto) {
              i++;
            } else {
              j++;
            }
          });
          mainColor = "#263238";
          secundaryColor = "#4f5b62";
          mainTextColor = "#ffffff";
          secundaryTextColor = "#ffffff";
          secundaryText = "Conjuntos de objetos";
          mainText = "Objetos simples";
          secundaryPercent = Math.round((i / this.allItems.length) * 100);
          mainPercent = Math.round((j / this.allItems.length) * 100);
          break;
        case "publicados":
          this.allItems.forEach((el) => {
            if (el.published) {
              j++;
            } else {
              i++;
            }
          });
          mainColor = "#994500";
          secundaryColor = "#ffd5b3";
          mainTextColor = "#ffffff";
          secundaryTextColor = "#662e00";
          secundaryText = "Objetos de acceso restringrido";
          mainText = "Objetos de libre acceso al P&uacute;blico";
          secundaryPercent = Math.round((i / this.allItems.length) * 100);
          mainPercent = Math.round((j / this.allItems.length) * 100);
          break;
        case "exhibicion":
          this.allItems.forEach((el) => {
            if (el.exhibition === "Si") {
              i++;
            } else {
              j++;
            }
          });
          mainColor = "#006080";
          secundaryColor = "#00bfff";
          mainTextColor = "#ffffff";
          secundaryTextColor = "#000a12";
          mainText = "Objetos guardados en el dep&oacute;sito";
          secundaryText = "Objetos en Exhibici&oacute;n";
          secundaryPercent = Math.round((i / this.allItems.length) * 100);
          mainPercent = Math.round((j / this.allItems.length) * 100);
          break;
        default:
          break;
      }
      let lastDeg = secundaryPercent * 3.6;
      let secundaryLeft = Math.sin(3.14 * ((lastDeg - (secundaryPercent * 3.6) / 2) / 180)) * 40 + 50;
      let secundaryTop = 50 - Math.cos(3.14 * ((lastDeg - (secundaryPercent * 3.6) / 2) / 180)) * 40;
      lastDeg = lastDeg + mainPercent * 3.6;
      let mainLeft = Math.sin(3.14 * ((lastDeg - (mainPercent * 3.6) / 2) / 180)) * 40 + 50;
      let mainTop = 50 - Math.cos(3.14 * ((lastDeg - (mainPercent * 3.6) / 2) / 180)) * 40;
      let data = [
        {
          estadistica: i,
          percent: secundaryPercent,
          color: secundaryColor,
          textColor: secundaryTextColor,
          left: secundaryLeft,
          top: secundaryTop,
          name: secundaryText,
        },
        {
          estadistica: j,
          percent: mainPercent,
          color: mainColor,
          textColor: mainTextColor,
          left: mainLeft,
          top: mainTop,
          name: mainText,
        },
      ];
      let css = "conic-gradient(";
      lastDeg = 0;
      data.forEach((elem, index) => {
        if (index < data.length - 1) {
          css =
            css +
            elem.color +
            " " +
            lastDeg +
            "deg, " +
            elem.color +
            " " +
            (lastDeg + elem.percent * 3.6) +
            "deg, ";
          lastDeg = lastDeg + elem.percent * 3.6;
        } else {
          css = css + elem.color + " " + lastDeg + "deg";
          lastDeg = lastDeg + elem.percent * 3.6;
        }
      });
      css = css + ")";
      console.log(css, data);
      return {
        css: css,
        data: data,
      };
    },
    showMaterials() {
      this.obtenerEstadistica();
      this.estadisticaMateriales.forEach((element) => {
        console.log(element);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./../../assets/sass/variables";
@import "./../../assets/sass/buttons";
@import "./../../assets/sass/modal";
@import "./../../assets/sass/ficha";
@import "./../../assets/sass/cards";

.settings-container {
  .section {
    display: grid;
    grid-template-columns: 100%;
    row-gap: 1em;
    column-gap: 1em;
    margin: 2em 5vh;
    .across {
      &.split {
        display: grid;
        grid-template-columns: 100%;
        row-gap: 1em;
        column-gap: 1em;
      }
    }
    .card {
      border-radius: 1em;
      padding: 1em;
      -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
      -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
      h1,
      h2,
      h3,
      h4,
      h5 {
        color: $blue-grey-dark;
        font-weight: 600;
      }
      &.split {
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: max-content auto;
        p {
          margin-block-start: 0;
          margin-block-end: 0;
        }
        .title {
          grid-column-start: 1;
          grid-column-end: 3;
          grid-row-start: 1;
          grid-row-end: 2;
        }
        .left {
          grid-column-start: 1;
          grid-column-end: 2;
          grid-row-start: 2;
          grid-row-end: 3;
          position: relative;
        }
        .number {
          height: fit-content;
          min-height: 90px;
          p {
            font-size: 4em;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
          }
        }
        .data {
          min-height: 100%;
          height: fit-content;
          p {
            font-size: 1.2em;
            margin-left: 0.5em;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
          }
        }
        .right {
          grid-column-start: 2;
          grid-column-end: 3;
          grid-row-start: 2;
          grid-row-end: 3;
          position: relative;
        }
      }
      &.chart {
        .pie {
          margin: 1em auto;
          border-radius: 50%;
          width: 65vw;
          height: 65vw;
          max-height: 270px;
          max-width: 270px;
          overflow: hidden;
          position: relative;
          .percent-container {
            position: absolute;
            width: fit-content;
            height: fit-content;
            transform: translateX(-50%) translateY(-50%);
            p {
              width: auto;
              position: relative;
              margin-block-end: 0;
              margin-block-start: 0;
              transform: none;
            }
          }
          .donat {
            width: 60%;
            height: 60%;
            background-color: white;
            border-radius: 50%;
            position: absolute;
            left: 20%;
            top: 20%;
          }
        }
        .more {
          width: 100%;
          text-align: right;
          text-decoration: underline;
          font-weight: 600;
          cursor: pointer;
        }
        .frequency {
          width: 100%;
          height: auto;
          overflow: hidden;
          margin-bottom: 0.5em;
          display: grid;
          grid-template-columns: auto fit-content(35%);
          row-gap: 0.5em;
          column-gap: 1em;
          div {
            height: 25px;
            border-radius: 13px;
            p {
              margin-block-start: 3px;
            }
          }

          .left-f {
            grid-column-start: 1;
            grid-column-end: 2;
          }
          .right-f {
            grid-column-start: 2;
            grid-column-end: 3;
          }
        }
      }
      &.white {
        background-color: white;
      }
      .blue-grey {
        background-color: $blue-grey;
        p {
          color: white;
        }
      }
      .theme-d4 {
        background-color: $theme-d2;
        p {
          color: whitesmoke;
        }
      }
      .turquesa {
        background-color: $message;
        p {
          color: whitesmoke;
        }
      }
      &.dark-theme {
        background-color: $message;
        h1,
        h2,
        h3,
        h4,
        h5 {
          color: whitesmoke;
        }
        p {
          color: white;
        }
      }
      .dot-container {
        display: grid;
        grid-template-columns: 15px auto;
        gap: 0.5em;
        div {
          position: relative;
        }
        .dot {
          border-radius: 50%;
          height: 15px;
          width: 15px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
}
@media (min-width: 576px) {
  .settings-container {
    .section {
      grid-template-columns: 50% 50%;
      gap: 1em;

      .across {
        grid-column-start: 1;
        grid-column-end: 3;
        &.split {
          display: grid;
          grid-template-columns: 50% 50%;
        }
      }
      .card {
        &.chart {
          .pie {
            width: 35vw;
            height: 35vw;
          }
        }
        &.narrow-left {
          grid-column-start: 1;
          grid-column-end: 2;
        }
        &.narrow-center {
          grid-column-start: 2;
          grid-column-end: 3;
        }
        &.narrow-right {
          grid-column-start: 2;
          grid-column-end: 3;
        }
        &.wide-left,
        &.wide-right {
          grid-column-start: 1;
          grid-column-end: 3;
        }
      }
    }
  }
}
@media (min-width: 768px) {
  .settings-container {
    .section {
      grid-template-columns: 1fr 1fr 1fr;
      .across {
        width: calc(100% - 1em);
        grid-column-start: 1;
        grid-column-end: 4;
      }
      .card {
        &.chart {
          .pie {
            width: 25vw;
            height: 25vw;
          }

          &.narrow-left {
            grid-column-start: 1;
            grid-column-end: 2;
          }
          &.narrow-center {
            grid-column-start: 2;
            grid-column-end: 3;
          }
          &.narrow-right {
            grid-column-start: 3;
            grid-column-end: 4;
          }
        }
        &.wide-left {
          grid-column-start: 1;
          grid-column-end: 3;
        }
        &.wide-right {
          grid-column-start: 2;
          grid-column-end: 4;
        }
      }
    }
  }
}
@media (min-width: 920px) {
  .settings-container {
    .section {
      grid-template-columns: 1fr 1fr 1fr;
      .card {
        &.chart {
          .pie {
            width: 22vw;
            height: 22vw;
          }
        }
      }
    }
  }
}
</style>