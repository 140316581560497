<template>
  <div class="home">
    <div class="search">
      <div>
        <h1 v-html="'PLANTA DE CONCENTRADOS'"></h1>
      </div>
      <h4 v-html="'Sector Industrial'"></h4>
    </div>
    <div class="section color1 split left">
      <div class="full-height"><div class="image" :style="{'background-image': 'url(https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F2%20planta%2F01%20-%20PLANTA%20PROCESOS%201.jpg?alt=media&token=af128b4a-faa4-47c0-891c-4bad28a0db53&_gl=1*1y4j16z*_ga*MTIyNTY4ODAyOC4xNjg1NzMyODQy*_ga_CW55HF8NVT*MTY4NTk5OTYxNy4zLjEuMTY4NTk5OTc4NC4wLjAuMA..)'}"></div></div>
      <div>
        <div class="content">
          <p v-html="'La planta de concentrado fue una pieza fundamental en todo proceso minero. Mediante varias etapas, en la planta se reduce el tama&ntilde;o de la roca y se utilizan reactivos para separar el mineral. Hoy, la planta de concentrado de Puerto Cristal se conserva relativamente en buen estado: pareciera haber quedado congelada en el tiempo, con las tolvas a punto de abrir para comenzar de nuevo con la producci&oacute;n. La construcci&oacute;n estaba emplazada en la pendiente del cerro Cristal, usando esta condici&oacute;n para el desplazamiento del material en su interior. Esta se divid&iacute;a en sectores y cada sector se utilizaba para una parte espec&iacute;fica del proceso. Al procesar el mineral se obten&iacute;a el concentrado en polvo, que se almacenaba en sacos o “big bags” para su exportaci&oacute;n. '"></p>
          <p v-html="'La planta concentradora, com&uacute;nmente llamada en Puerto Cristal “Planta de Concentrado”, “Planta de Procesos” o “Planta de Beneficios”, era una pieza fundamental del proceso minero que ten&iacute;a como finalidad separar aquellos metales con valor comercial del material est&eacute;ril que se encontraba en las rocas. El proceso se divid&iacute;a en varias etapas, obteni&eacute;ndose as&iacute; concentrados de plomo y zinc. Para lograr la concentraci&oacute;n de estos elementos, el material rocoso extra&iacute;do de la mina pasaba por una serie de equipos que iban reduciendo su tama&ntilde;o mediante un proceso que se denominaba “conminuci&oacute;n”. Luego, se somet&iacute;a a otro proceso, llamado “flotaci&oacute;n”, que permit&iacute;a separar los elementos de inter&eacute;s para su posterior comercializaci&oacute;n. '"></p>
          <p v-html="'La <b>conminuci&oacute;n</b> era la etapa en que mediante aplicaci&oacute;n de fuerzas f&iacute;sicas se disminu&iacute;a el tama&ntilde;o de las rocas que conten&iacute;an los minerales. Para esto, se empleaban distintos equipos, entre ellos, los chancadores y los molinos. '"></p>
          <p v-html="'La <b> flotaci&oacute;n</b>  correspond&iacute;a a la etapa en la que al mineral se le adicionaba agua y reactivos qu&iacute;micos, logrando que los minerales se adhirieran a las burbujas de aire que sub&iacute;an a la superficie. Despu&eacute;s se recolectaban por rebalse y, tras varios ciclos consecutivos, se aumentaba la concentraci&oacute;n de mineral. '"></p>
        </div>
      </div>
    </div>
    <div class="section color2 split right">
      <div class="full">
        <div class="content img">
          <h1 v-html="'ETAPA 1 CONMINUCI&Oacute;N - REDUCCI&Oacute;N DE TAMA&Ntilde;O'"></h1>
          <p v-html="'El proceso de conminuci&oacute;n se divid&iacute;a en dos etapas: chancado y molienda. '"></p>

          <h2>PROCESO DE CHANCADO</h2>
          <p v-html="'En el proceso de chancado se utilizaban diversos equipos destinados a la reducci&oacute;n del tama&ntilde;o de la roca. El material rocoso era depositado en las tolvas, d&oacute;nde se suministraba posteriormente para la siguiente etapa del proceso. Al finalizar la etapa de chancado se obten&iacute;an tama&ntilde;os de roca de aproximadamente una pulgada de di&aacute;metro. Para ello, el proceso se divid&iacute;a en dos sub-etapas: chancado primario y chancado secundario. '"></p>
          <CardSlider :setup="{ list: col ,target:'return'}" v-on:get-result="setResult"/>
          
          
          <h2 v-html="'PROCESO DE MOLIENDA'"></h2>
          
          <p v-html="'El proceso de molienda era la &uacute;ltima etapa de “conminuci&oacute;n” o de reducci&oacute;n de tama&ntilde;o del material. Al igual que en las etapas anteriores, el material proveniente del chancado secundario era depositado y almacenado en una tolva de alimentaci&oacute;n. Al abrirse la compuerta todo el material ca&iacute;a, para luego ser mezclado con agua y cal. Inmediatamente despu&eacute;s el material ingresaba al molino de bolas.'"></p>
          
          <h3>Molino de Bolas</h3>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F2%20planta%2F003%20Plant%20machine.jpg?alt=media&token=43e45d3b-b1d7-4f67-8ce4-484899b9ba24&_gl=1*3vn3oq*_ga*MTIyNTY4ODAyOC4xNjg1NzMyODQy*_ga_CW55HF8NVT*MTY4NTc0MDYzMy4yLjEuMTY4NTc0MjAyMi4wLjAuMA.."
            alt=""
            class="right"
          />
          <p v-html="'El molino de bolas era un equipo con forma de cilindro, cuyo peso era soportado por dos rodamientos ubicados en sus extremos que permit&iacute;an que toda la estructura girara  en torno a su eje horizontal. El molino era hueco y aproximadamente un tercio de su volumen era llenado con bolas de acero.  Por uno de sus extremos se alimentaba con la mezcla de mineral, agua y cal.  Un motor conectado al molino (mediante un sistema de poleas y engranajes) lo hac&iacute;a girar en torno a su eje, facilitando el sube y baja de las bolas, que golpeaban los fragmentos de mineral contra las paredes del molino y contra ellas mismas. De este modo, y producto del impacto, el material reduc&iacute;a su tama&ntilde;o hasta pulverizarse. Las part&iacute;culas quedaban suspendidas en el agua formando una soluci&oacute;n acuosa que sal&iacute;a por el otro extremo del molino. '"></p>

          <p v-html="'La utilizaci&oacute;n de agua en esta etapa cumpl&iacute;a varias funciones que mejoraban la eficiencia del proceso: ayudaba a reducir el ruido que generaba el impacto de las bolas entre s&iacute; y disminu&iacute;a la cantidad de polvo que se dispersaba en el ambiente. Por otra parte, la cal neutralizaba el pH de la soluci&oacute;n, lo cual era fundamental para la etapa de flotaci&oacute;n. '"></p>
          
          <p v-html="'Cabe destacar que el proceso de arranque del molino de bolas era una tarea compleja debido al gran tama&ntilde;o y peso del equipo, el cual requer&iacute;a de mucha energ&iacute;a para iniciar el movimiento, sin embargo, una vez que comenzaba a rotar, la energ&iacute;a necesaria para mantener este movimiento era mucho menor. Cada vez que se iniciaba el funcionamiento del molino de bolas, deb&iacute;a sincronizarse el funcionamiento de la planta hidroel&eacute;ctrica con el de la planta de concentrado, ya que la planta hidroel&eacute;ctrica deb&iacute;a funcionar en su m&aacute;xima capacidad. Una vez que el molino disminu&iacute;a su consumo energ&eacute;tico se reduc&iacute;a tambi&eacute;n el suministro el&eacute;ctrico. Si estas tareas no se hac&iacute;an correctamente, pod&iacute;a producirse un alza de voltaje que ocasionaba la quema de artefactos el&eacute;ctricos en las viviendas e instalaciones del campamento. Por lo complejo de esta operaci&oacute;n, el molino de bolas funcionaba las veinticuatro horas del d&iacute;a todos los d&iacute;as del a&ntilde;o, deteni&eacute;ndose &uacute;nicamente cuando correspond&iacute;a hacerle mantenciones o reparaciones. '"></p>
<p v-html="'Una vez que la mezcla de polvo, agua y cal sal&iacute;a del molino, se deb&iacute;a clasificar el tama&ntilde;o de las part&iacute;culas que se encontraban en suspensi&oacute;n, ya que s&oacute;lo el material m&aacute;s fino pod&iacute;a continuar a la siguiente etapa. Para lograr este objetivo, la soluci&oacute;n era bombeada a trav&eacute;s de ca&ntilde;er&iacute;as hacia un hidrocicl&oacute;n con forma de embudo, m&aacute;s conocido como “cicl&oacute;n”, que se encontraba sobre el molino de bolas. Cuando la mezcla ingresaba al sistema de separaci&oacute;n por el costado superior del cicl&oacute;n y las part&iacute;culas en suspensi&oacute;n comenzaban a girar, las part&iacute;culas de mayor tama&ntilde;o y peso se desplazaban hacia las paredes del separador haciendo un movimiento helicoidal o de espiral. Por su peso, estas part&iacute;culas se mov&iacute;an hacia la parte inferior, por donde sal&iacute;an y volv&iacute;an a ingresar al molino de bolas reduciendo as&iacute; su tama&ntilde;o. Por otra parte, las part&iacute;culas m&aacute;s finas y de menor peso se desplazaban hacia la parte central del cicl&oacute;n con movimientos ascendentes, hasta salir por la parte superior del separador. Desde ah&iacute; la mezcla flu&iacute;a por una tuber&iacute;a hacia la etapa siguiente. '"></p>
<img src="https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F2%20planta%2FPLANTA%20-54.jpg?alt=media&token=cbd8ac1c-f7a3-4a16-84f5-bc174eba3d33&_gl=1*go3mg1*_ga*MTIyNTY4ODAyOC4xNjg1NzMyODQy*_ga_CW55HF8NVT*MTY4NTc0MDYzMy4yLjEuMTY4NTc0Mjg0NC4wLjAuMA.." class="left" alt="">
<p v-html="'Adicionalmente, se sol&iacute;a utilizar un clasificador de rastrillos o “rastra”.  Este equipo ten&iacute;a forma de artesa o batea, su fondo era liso y se encontraba inclinado. Sobre este fondo se ubicaban dos brazos con rastrillos. La mezcla que ven&iacute;a del molino de bolas era alimentada en la parte central de la batea y la clasificaci&oacute;n se produc&iacute;a debido al movimiento que realizaban los rastrillos: &eacute;stos hac&iacute;an contacto con el fondo, en la parte inferior de la artesa, y se desplazaban hacia la parte superior, arrastrando en este movimiento las part&iacute;culas de mayor tama&ntilde;o. Una vez que los rastrillos llegaban a la parte superior, se levantaban y desplazaban hacia la parte inferior, en donde comenzaba un nuevo ciclo de arrastre. Las part&iacute;culas de mayor tama&ntilde;o sal&iacute;an por el extremo superior de la artesa y volv&iacute;an a ingresar al molino de bolas, mientras que las m&aacute;s finas sal&iacute;an por rebalse en la soluci&oacute;n, desde el extremo inferior de la batea, continuando hacia la siguiente etapa por tuber&iacute;as. '"></p>

        </div>
      </div>
    </div>
    <div class="section split right">
      <div class="full">
        <div class="content img">
          <h1 v-html="'ETAPA 2 FLOTACI&Oacute;N. SEPARACI&Oacute;N DEL MINERAL'"></h1>
          <p v-html="'Una vez finalizado el proceso de conminuci&oacute;n del material, las part&iacute;culas m&aacute;s finas llegaban a la etapa de flotaci&oacute;n: el objetivo era separar aquellos elementos met&aacute;licos (zinc, plomo y plata) del material est&eacute;ril que no ten&iacute;a valor comercial. Para este fin se utilizaban reactivos qu&iacute;micos que facilitaban la separaci&oacute;n y concentraci&oacute;n del mineral. '"></p>
          <img src="https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F2%20planta%2F002%20Plant%20machine%203.jpg?alt=media&token=d9d4d31b-80f6-4d7b-bc6d-f1dd7029cc2c&_gl=1*1at911z*_ga*MTIyNTY4ODAyOC4xNjg1NzMyODQy*_ga_CW55HF8NVT*MTY4NTc0MDYzMy4yLjEuMTY4NTc0MTk4My4wLjAuMA.." alt="" class="left">
          <h3 v-html="'Celdas de Flotaci&oacute;n'"></h3>
          <p v-html="'El proceso de flotaci&oacute;n se realizaba en una serie de recipientes llamados “celdas de flotaci&oacute;n”. Desde el molino de bolas llegaba la soluci&oacute;n acuosa, a la cual se le adicionaba m&aacute;s agua y ciertos reactivos (espumantes, colectores y modificadores) que facilitaban el proceso de separaci&oacute;n fisicoqu&iacute;mica. Los espumantes ablandaban el agua y facilitaban la formaci&oacute;n de burbujas. Los colectores hac&iacute;an que los metales de inter&eacute;s se volvieran hidrof&oacute;bicos (repelentes al agua) y los modificadores aumentaban la selectividad del proceso. Algunos de los reactivos que se utilizaban eran isopropilo, aceite de pino y sulfato de cobre. '"></p>
<p v-html="'La soluci&oacute;n resultante de la mezcla de agua, minerales y reactivos pasaba por una serie de celdas de flotaci&oacute;n. Desde el fondo de &eacute;stas se insuflaba aire y unas paletas al interior de los tanques giraban para romper las burbujas grandes y convertirlas en burbujas m&aacute;s peque&ntilde;as que ascend&iacute;an a la superficie. Debido a la acci&oacute;n de los reactivos, los elementos met&aacute;licos que se buscaban recuperar se volv&iacute;an hidrof&oacute;bicos (repelentes al agua), adhiri&eacute;ndose as&iacute; a la superficie de las burbujas y ascendiendo hasta la superficie de las celdas. De aqu&iacute; viene el nombre del proceso, ya que el concentrado “flotaba” y era separado por rebalse con la ayuda de paletas que giraban en el borde superior de las celdas de flotaci&oacute;n, mientras que el resto del material decantaba por efecto de la gravedad y se iba hacia el fondo de la celda, desde donde se extra&iacute;a y se bombeaba al tranque de relave. '"></p>
<p v-html="'Este proceso de recuperaci&oacute;n del mineral ten&iacute;a sus limitantes: no se pod&iacute;a recuperar m&aacute;s de un 12% de zinc y un 20% de plomo. Si la ley de cabeza (ley del mineral bruto) que ingresaba a la planta era mayor a los l&iacute;mites establecidos, un porcentaje importante del mineral se iba hacia el tranque de relave. Por esta raz&oacute;n, el laboratorio qu&iacute;mico hac&iacute;a un control permanente al funcionamiento de los procesos, asegurando as&iacute; el correcto funcionamiento de los par&aacute;metros definidos por la empresa. Durante los &uacute;ltimos a&ntilde;os que estuvo operando la planta de concentrado, se extra&iacute;a s&oacute;lo zinc y se procesaban cerca de 1000 toneladas de material mensualmente con una ley del 12% de zinc por tonelada. Gracias al proceso de flotaci&oacute;n se obten&iacute;an aproximadamente 215 toneladas de concentrado con una ley del 54%, mientras que las 785 toneladas restantes, que acababan en el tranque de relave, conten&iacute;an entre un 0,1% y un 0,3% de zinc. '"></p>
<p v-html="'Una vez que el concentrado sal&iacute;a de las celdas de flotaci&oacute;n era bombeado hacia la siguiente etapa de secado y envasado. '"></p>

        </div>
      </div>
    </div>
    <div class="section color2 split right">
      <div class="full">
        <div class="content img">
          <h1 v-html="'ETAPA 3. SECADO Y ENVASADO'"></h1>
          <p v-html="'El concentrado proveniente de las celdas de flotaci&oacute;n pose&iacute;a un alto contenido de agua por lo que era necesario realizar una separaci&oacute;n s&oacute;lido-l&iacute;quida antes de envasar el producto que ser&iacute;a exportado. Hab&iacute;a dos procesos distintos de secado y envasado seg&uacute;n si era para el concentrado de zinc o para el concentrado de plomo. '"></p>
          <CardSlider :setup="{ list: col2 ,target:'return'}" v-on:get-result="setResult"/>
        </div>
      </div>
    </div>
    <div class="section">
          <h1 v-html="'Fotograf&iacute;as'"></h1>
          <div class="gallery">
            <div
              class="item"
              v-for="(item, index) in images"
              :key="index"
              :style="'background-image:url(' + item.url + ')'"
            >
              <div class="hover" @click="openGallery(index)">
                <div>
                  <i class="material-icons">fullscreen</i>
                  <h5>{{ item.name }}</h5>
                </div>
              </div>
            </div>
        
      </div>
    </div>
    <div class="modal wide" v-if="extraData !=='none'">
      <div class="top" @click="extraData = 'none'"></div>
      <div class="left" @click="extraData = 'none'"></div>
      <div class="right" @click="extraData = 'none'"></div>
      <div class="bottom" @click="extraData = 'none'"></div>
      <div class="box" v-if="extraData ==='1.1'">
        
        <div class="split-40-60">
          <div class="full-image" :style="{'background-image': 'url(https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F2%20planta%2FIMG_9134.jpg?alt=media&token=ad493c27-d82c-4b2a-a013-a9ede220c92c&_gl=1*zr364x*_ga*MTIyNTY4ODAyOC4xNjg1NzMyODQy*_ga_CW55HF8NVT*MTY4NjA3MDIzNi41LjEuMTY4NjA3MDM4MS4wLjAuMA..)'}"></div>
          <SimpleBar>
            <p class="icon" style="padding: 0.5em 0.5em 0 0;text-align: right;">
          <i class="material-icons" @click="extraData = 'none'">close</i>
        </p>
            <h1 v-html="'1.1 CHANCADO PRIMARIO'"></h1>
            <p v-html="'Todo el mineral que sal&iacute;a de la mina se descargaba en la primera tolva. &Eacute;sta ten&iacute;a una compuerta que al abrirse alimentaba a un chancador-mand&iacute;bula que procesaba las rocas que llegaban de la mina con un di&aacute;metro aproximado de diez cent&iacute;metros. Este equipo se caracterizaba por poseer dos placas trituradoras, una fija y otra m&oacute;vil, similares a una mand&iacute;bula, manteniendo una separaci&oacute;n en la parte inferior denominada “paso”. Esta abertura era la que determinaba el tama&ntilde;o m&aacute;ximo que tendr&iacute;an las rocas una vez procesadas. Un motor el&eacute;ctrico hac&iacute;a que la mand&iacute;bula m&oacute;vil se acercara y alejara peri&oacute;dicamente de la mand&iacute;bula fija: al alejarse ingresaba el mineral al sistema por la parte superior y al acercarse se comprim&iacute;a el contenido en su interior, haciendo que las rocas se fracturaran y redujeran su tama&ntilde;o. Tras sucesivas compresiones, aquellas rocas con un di&aacute;metro menor al “paso” sal&iacute;an de la chancadora por la parte inferior. '"></p>
            <p v-html="'Bajo la estructura que soportaba al chancador-mand&iacute;bula se ubicaba una tolva receptora que recog&iacute;a el material procesado deposit&aacute;ndolo sobre una cinta transportadora. Esta cinta lo mov&iacute;a hacia la tolva de alimentaci&oacute;n del chancador secundario. Al finalizar esta etapa, el tama&ntilde;o de las rocas resultantes era de aproximadamente cuatro cent&iacute;metros de di&aacute;metro. '"></p>

          </SimpleBar>
        </div>
      </div>
      <div class="box" v-if="extraData ==='1.2'">
        
        <div class="split-40-60">
          
          <div class="full-image" :style="{'background-image': 'url(https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F2%20planta%2FIMG_9135.jpg?alt=media&token=ebe4e917-eea1-4fcc-a6fc-fd8209c2d606&_gl=1*1tw1fn8*_ga*MTIyNTY4ODAyOC4xNjg1NzMyODQy*_ga_CW55HF8NVT*MTY4NjA3Njg4Ny42LjEuMTY4NjA3NjkwNC4wLjAuMA..)'}"></div>
          <SimpleBar>
            <p class="icon" style="padding: 0.5em 0.5em 0 0;text-align: right;">
          <i class="material-icons" @click="extraData = 'none'">close</i>
        </p>
            <h1 v-html="'1.2 CHANCADO SECUNDARIO'"></h1>
            <p v-html="'El material resultante del chancado primario era almacenado en una segunda tolva de alimentaci&oacute;n. &Eacute;sta, al igual que la anterior, pose&iacute;a una compuerta que al levantarse permit&iacute;a el descenso del material gracias al efecto de la gravedad. Originalmente, en esta etapa se encontraba un chancador de Cono, equipo que pose&iacute;a dos piezas de acero circulares, una inferior con forma de cono, que era cubierta por otra superior con forma de campana. La alimentaci&oacute;n de este equipo se produc&iacute;a por la parte superior, haciendo que el material se fuera encajando entre las paredes de ambas piezas: la campana se manten&iacute;a fija, mientras que el cono rotaba, comprimiendo el mineral contra las paredes de la pieza superior. As&iacute;, las rocas se fracturaban y reduc&iacute;an su tama&ntilde;o cayendo por la abertura que se formaba entre el cono y la campana. Lamentablemente, en los &uacute;ltimos a&ntilde;os de la explotaci&oacute;n, el equipo fue sometido a mantenci&oacute;n y no volvi&oacute; a funcionar, por lo que se reemplaz&oacute; por un chancador-mand&iacute;bula similar al utilizado en el chancado primario. '"></p>
            <p v-html="'Al igual que en la etapa anterior, bajo la estructura que soportaba al chancador secundario, se ubicaba una tolva receptora y una cinta transportadora, donde se llevaba el material hacia la etapa de molienda. Al finalizar este proceso, las rocas ten&iacute;an un di&aacute;metro inferior al de una pulgada. '"></p>

          </SimpleBar>
        </div>
      </div>
      <div class="box" v-if="extraData ==='3.1'">
        <div class="split-40-60">
          <div class="full-image" :style="{'background-image': 'url(https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F2%20planta%2FPLANTA%20-41.jpg?alt=media&token=1959da1c-76b9-4ed7-9da4-1e4a6784dde1&_gl=1*aaorlk*_ga*MTIyNTY4ODAyOC4xNjg1NzMyODQy*_ga_CW55HF8NVT*MTY4NjA4NTc3MS43LjEuMTY4NjA4NTgyNy4wLjAuMA..)'}"></div>
          <SimpleBar>
            <p class="icon" style="padding: 0.5em 0.5em 0 0;text-align: right;">
          <i class="material-icons" @click="extraData = 'none'">close</i>
        </p>
            <h1 v-html="'3.1. SECADO DEL CONCENTRADO DE ZINC'"></h1>
            <p v-html="'El proceso de secado para el zinc contemplaba dos etapas. En la primera se depositaba el concentrado en estanques espesadores. Estos estanques eran grandes recipientes cil&iacute;ndricos con una capacidad de aproximadamente 1000 m3 cada uno, donde se dejaba reposar la soluci&oacute;n. Por efecto de la gravedad, las part&iacute;culas en suspensi&oacute;n descend&iacute;an hacia el fondo del estanque. Sin embargo, para evitar que el sedimento se solidificara en el fondo, se agitaba lentamente la soluci&oacute;n con dos grandes paletas que giraban constantemente. Desde la parte inferior del estanque se succionaba el concentrado con un bajo porcentaje de humedad, mientras que el agua m&aacute;s clara sal&iacute;a, por rebalse, hacia la parte superior del sistema para ser posteriormente reutilizada. '"></p>
            <p v-html="'El concentrado que se exportaba deb&iacute;a contener un porcentaje de humedad del 8%. Si el concentrado estaba muy seco pod&iacute;a volarse con el viento, debido a que era un material particulado muy fino. Por el contrario, si el porcentaje de humedad era muy elevado, el concentrado se volv&iacute;a una masa gelatinosa, lo que afectaba a la estabilidad de los barcos durante su transporte. '"></p>
            <p v-html="'Para lograr el 8% de humedad, el concentrado que proven&iacute;a de los estanques espesadores pasaba por un “filtro de discos”. Este equipo consist&iacute;a en un estanque donde se alimentaba el concentrado y en su interior se ubicaban dos discos en posici&oacute;n vertical, quedando una mitad de los discos sumergida y la otra mitad expuesta al aire. Estos discos pose&iacute;an en su parte exterior una membrana permeable que permit&iacute;a que s&oacute;lo el agua la atravesara. Desde su interior se hac&iacute;a succi&oacute;n, haciendo que el concentrado se adhiriera a la membrana e hiciera girar los discos sobre su eje. A medida que giraban los discos, el concentrado era expuesto al aire y el agua era succionada por el eje; antes de volver a sumergirse, la membrana se inflaba dejando caer el mineral (fuera del recipiente) sobre una rampa que lo llevaba hacia la cancha de envasado. '"></p>
            <p v-html="'Una vez en la cancha de envasado, el concentrado era depositado en bolsas llamadas “big bags” que ten&iacute;an  capacidad de 1 tonelada. Las “big bags” se pesaban en la romana y se sacaban de la planta con la ayuda de un “huinche” hacia los camiones, que las llevaban hacia las bodegas portuarias para su transporte y exportaci&oacute;n a Europa. '"></p>

          </SimpleBar>
        </div>
      </div>
      <div class="box" v-if="extraData ==='3.2'">
        <div class="split-40-60">
          <div class="full-image" :style="{'background-image': 'url(https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F2%20planta%2FPLANTA%20-41.jpg?alt=media&token=1959da1c-76b9-4ed7-9da4-1e4a6784dde1&_gl=1*aaorlk*_ga*MTIyNTY4ODAyOC4xNjg1NzMyODQy*_ga_CW55HF8NVT*MTY4NjA4NTc3MS43LjEuMTY4NjA4NTgyNy4wLjAuMA..)'}"></div>
          <SimpleBar>
        <p class="icon" style="padding: 0.5em 0.5em 0 0;text-align: right;">
          <i class="material-icons" @click="extraData = 'none'">close</i>
        </p>
            <h1 v-html="'3.2. SECADO DEL CONCENTRADO PLOMO'"></h1>
            <p v-html="'Una vez que el concentrado de plomo sal&iacute;a de las celdas de flotaci&oacute;n, el fluido era enviado mediante una rampa directamente hacia un galp&oacute;n ubicado en el muelle, que se utilizaba exclusivamente para secar el concentrado de plomo y ensacarlo. Posteriormente, esta rampa fue reemplazada por tuber&iacute;as de polietileno, m&aacute;s conocidas como planzas. '"></p>
            <p v-html="'Al interior del gal&oacute;n hab&iacute;a seis estanques en donde se dejaba reposar el concentrado de plomo hasta que perd&iacute;a la humedad. Posteriormente, cuando se encontraba seco, era depositado en bolsas de 100 kg y apilado, hasta el momento en que se cargaba en los barcos para ser exportado. '"></p>

          </SimpleBar>
        </div>
      </div>
    </div>
    <Loading text="cargando" />
    <Message />
    <Gallery/>
  </div>
</template>
<script>
// @ is an alias to /src
import Loading from "@/components/Loading.vue";
import Message from "@/components/Message.vue";
import CardSlider from "@/components/CardSlider.vue";
import Gallery from "@/components/Gallery.vue";

import { SimpleBar } from "simplebar-vue3";
import { mapMutations } from "vuex";
export default {
  name: "Home-page",
  components: {
    Loading,
    Message,
    Gallery,CardSlider,SimpleBar
  },
  data: function () {
    return {
      extraData:'none',
      col: [
        {
          link: "1.1",
          imagen: "https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F2%20planta%2FIMG_9138.jpg?alt=media&token=04396833-1236-4363-be42-7f28166914ea&_gl=1*158kzwk*_ga*NjAzNTQwNTkxLjE2ODU3MjcyNzM.*_ga_CW55HF8NVT*MTY4NjAxMjE5Ny45LjEuMTY4NjAxMjQxMi4wLjAuMA..",
          title: "1.1 Chancado primario",
          id:'Chancador de Mandíbula',
          content:
            "Sector Mina",
        },
        {
          link: "1.2",
          imagen: "https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F2%20planta%2FIMG_9137.jpg?alt=media&token=37fd7906-e283-4990-9865-7bd0a67085cb&_gl=1*eq8wgd*_ga*NjAzNTQwNTkxLjE2ODU3MjcyNzM.*_ga_CW55HF8NVT*MTY4NjAxMjE5Ny45LjEuMTY4NjAxMjM4MS4wLjAuMA..",
          title: "1.2 Chancado secundario",
          id:'Chancador de Cono',
          content:
            "Sector Mina",
        },
      ],
      col2: [
        {
          link: "3.1",
          imagen: "https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F2%20planta%2FIMG_9138.jpg?alt=media&token=04396833-1236-4363-be42-7f28166914ea&_gl=1*158kzwk*_ga*NjAzNTQwNTkxLjE2ODU3MjcyNzM.*_ga_CW55HF8NVT*MTY4NjAxMjE5Ny45LjEuMTY4NjAxMjQxMi4wLjAuMA..",
          title: "3.1. Secado del Concentrado de Zinc",
          id:'Producción de Zinc',
          content:
            "Sector Mina",
        },
        {
          link: "3.2",
          imagen: "https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F2%20planta%2FIMG_9137.jpg?alt=media&token=37fd7906-e283-4990-9865-7bd0a67085cb&_gl=1*eq8wgd*_ga*NjAzNTQwNTkxLjE2ODU3MjcyNzM.*_ga_CW55HF8NVT*MTY4NjAxMjE5Ny45LjEuMTY4NjAxMjM4MS4wLjAuMA..",
          title: "3.2 Secado del Concentrado de Plomo",
          id:'Producción de Plomo',
          content:
            "Sector Mina",
        },
      ],
      images: [{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F2%20planta%2FPLANTA%20-44.jpg?alt=media&token=d4f47b79-acb2-4d62-b10d-66c62e1c9d67&_gl=1*y7wfyc*_ga*MTIyNTY4ODAyOC4xNjg1NzMyODQy*_ga_CW55HF8NVT*MTY4NTc0MDYzMy4yLjEuMTY4NTc0Mjc2My4wLjAuMA..',name:'Bodega de Reactivos',position:0},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F2%20planta%2FPLANTA%20-51.jpg?alt=media&token=29b238a0-1715-4f70-8736-e69fb1be2cff&_gl=1*z8wio6*_ga*MTIyNTY4ODAyOC4xNjg1NzMyODQy*_ga_CW55HF8NVT*MTY4NTc0MDYzMy4yLjEuMTY4NTc0MjgwNi4wLjAuMA..',name:'Carro Minero',position:1},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F2%20planta%2F002%20Plant%20machine%203.jpg?alt=media&token=d9d4d31b-80f6-4d7b-bc6d-f1dd7029cc2c&_gl=1*1at911z*_ga*MTIyNTY4ODAyOC4xNjg1NzMyODQy*_ga_CW55HF8NVT*MTY4NTc0MDYzMy4yLjEuMTY4NTc0MTk4My4wLjAuMA..',name:'Celdas de Flotación',position:2},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F2%20planta%2F004%20MAQUINAS.jpg?alt=media&token=c45f0194-5d0e-4de0-bcd6-3714e1c08ede&_gl=1*ctrk0d*_ga*MTIyNTY4ODAyOC4xNjg1NzMyODQy*_ga_CW55HF8NVT*MTY4NTc0MDYzMy4yLjEuMTY4NTc0MjA4NS4wLjAuMA..',name:'Celdas de Flotación',position:3},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F2%20planta%2FLastScan%2054.jpg?alt=media&token=2c276d6d-b12f-4fe4-9131-c14cfa1d5c05&_gl=1*1rvo3uu*_ga*MTIyNTY4ODAyOC4xNjg1NzMyODQy*_ga_CW55HF8NVT*MTY4NTc0MDYzMy4yLjEuMTY4NTc0MjE0Mi4wLjAuMA..',name:'Foto Antigua',position:4},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F2%20planta%2FLastScan%2069.jpg?alt=media&token=3fca07e4-cf4f-410c-863a-6ec19073397e&_gl=1*114trsk*_ga*MTIyNTY4ODAyOC4xNjg1NzMyODQy*_ga_CW55HF8NVT*MTY4NTc0MDYzMy4yLjEuMTY4NTc0MjMwNi4wLjAuMA..',name:'Foto Antigua',position:5},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F2%20planta%2FLastScan%2080.jpg?alt=media&token=64530d05-1c64-487b-b6a9-a4f6bc9a193e&_gl=1*1ftaiij*_ga*MTIyNTY4ODAyOC4xNjg1NzMyODQy*_ga_CW55HF8NVT*MTY4NTc0MDYzMy4yLjEuMTY4NTc0MjM0OC4wLjAuMA..',name:'Foto Antigua',position:6},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F2%20planta%2FLastScan%2086.jpg?alt=media&token=6577871d-e164-4a66-8080-bcab616c3917&_gl=1*10ksvwq*_ga*MTIyNTY4ODAyOC4xNjg1NzMyODQy*_ga_CW55HF8NVT*MTY4NTc0MDYzMy4yLjEuMTY4NTc0MjM5Mi4wLjAuMA..',name:'Foto Antigua',position:7},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F2%20planta%2FLastScan50.jpg?alt=media&token=adb2c58e-f5ba-42aa-9639-af003759f766&_gl=1*1xsuolz*_ga*MTIyNTY4ODAyOC4xNjg1NzMyODQy*_ga_CW55HF8NVT*MTY4NTc0MDYzMy4yLjEuMTY4NTc0MjQzMS4wLjAuMA..',name:'Foto Antigua',position:8},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F2%20planta%2FPLANTA%20-19.jpg?alt=media&token=f8f0a177-4413-421e-b9f8-b21c227c5eb7&_gl=1*g8urz6*_ga*MTIyNTY4ODAyOC4xNjg1NzMyODQy*_ga_CW55HF8NVT*MTY4NTc0MDYzMy4yLjEuMTY4NTc0MjYxNy4wLjAuMA..',name:'Interior Planta de Concentrados',position:9},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F2%20planta%2FPLANTA%20-16.jpg?alt=media&token=a67de40c-85df-4dd3-9cf2-011b17c348f2&_gl=1*1qcbvc*_ga*MTIyNTY4ODAyOC4xNjg1NzMyODQy*_ga_CW55HF8NVT*MTY4NTc0MDYzMy4yLjEuMTY4NTc0MjU3Mi4wLjAuMA..',name:'Maquinaria',position:10},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F2%20planta%2FPLANTA%20-41.jpg?alt=media&token=1959da1c-76b9-4ed7-9da4-1e4a6784dde1&_gl=1*17d6xlw*_ga*MTIyNTY4ODAyOC4xNjg1NzMyODQy*_ga_CW55HF8NVT*MTY4NTc0MDYzMy4yLjEuMTY4NTc0MjcyNC4wLjAuMA..',name:'Maquinaria',position:11},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F2%20planta%2FPLANTA%20-54.jpg?alt=media&token=cbd8ac1c-f7a3-4a16-84f5-bc174eba3d33&_gl=1*go3mg1*_ga*MTIyNTY4ODAyOC4xNjg1NzMyODQy*_ga_CW55HF8NVT*MTY4NTc0MDYzMy4yLjEuMTY4NTc0Mjg0NC4wLjAuMA..',name:'Maquinaria',position:12},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F2%20planta%2F003%20Plant%20machine.jpg?alt=media&token=43e45d3b-b1d7-4f67-8ce4-484899b9ba24&_gl=1*3vn3oq*_ga*MTIyNTY4ODAyOC4xNjg1NzMyODQy*_ga_CW55HF8NVT*MTY4NTc0MDYzMy4yLjEuMTY4NTc0MjAyMi4wLjAuMA..',name:'Molino de Bolas',position:13},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F2%20planta%2F01%20-%20PLANTA%20PROCESOS%201.jpg?alt=media&token=af128b4a-faa4-47c0-891c-4bad28a0db53&_gl=1*1bum3h8*_ga*MTIyNTY4ODAyOC4xNjg1NzMyODQy*_ga_CW55HF8NVT*MTY4NTc0MDYzMy4yLjEuMTY4NTc0MjEwOS4wLjAuMA..',name:'Planta de Concentrados',position:14},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F2%20planta%2FPLANTA%20-03.jpg?alt=media&token=b9ff2f03-3b7e-4598-8e37-1c4057c5beae&_gl=1*18wik2n*_ga*MTIyNTY4ODAyOC4xNjg1NzMyODQy*_ga_CW55HF8NVT*MTY4NTc0MDYzMy4yLjEuMTY4NTc0MjQ1MS4wLjAuMA..',name:'Planta de Concentrados',position:15},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F2%20planta%2FPLANTA%20-04.jpg?alt=media&token=afccb1af-f0ff-4307-aaaf-4f833c126b86&_gl=1*vfht2p*_ga*MTIyNTY4ODAyOC4xNjg1NzMyODQy*_ga_CW55HF8NVT*MTY4NTc0MDYzMy4yLjEuMTY4NTc0MjQ4Ni4wLjAuMA..',name:'Planta de Concentrados',position:16},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F2%20planta%2FPLANTA%20-06.jpg?alt=media&token=afbd02b2-a0ea-4fdd-b317-c1c5ded4ba0c&_gl=1*1j1bv0k*_ga*MTIyNTY4ODAyOC4xNjg1NzMyODQy*_ga_CW55HF8NVT*MTY4NTc0MDYzMy4yLjEuMTY4NTc0MjUwNy4wLjAuMA..',name:'Planta de Concentrados',position:17},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F2%20planta%2FPLANTA%20DE%20PROCESOS.jpg?alt=media&token=33ba0425-efef-481d-ba9a-37d2bf03ef0d&_gl=1*ku9oon*_ga*MTIyNTY4ODAyOC4xNjg1NzMyODQy*_ga_CW55HF8NVT*MTY4NTc0MDYzMy4yLjEuMTY4NTc0Mjg3Ny4wLjAuMA..',name:'Planta de Concentrados',position:18},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F2%20planta%2FPLANTA%20-15.jpg?alt=media&token=703da1c7-c52b-4234-87bb-7f549a50f133&_gl=1*720t97*_ga*NjAzNTQwNTkxLjE2ODU3MjcyNzM.*_ga_CW55HF8NVT*MTY4NjM2MzU5NS4xNi4xLjE2ODYzNjM2NTguMC4wLjA.',name:'Tanque Espesador',position:20}
]
,
    };
  },
  created() {
    this.SET_LOADING(false);
  },
  methods: {
    ...mapMutations([
      "SET_LOADING",
      "SET_GALLERY",
      "SET_GALLERY_INDEX",
      "SET_GALLERY_ITEMS",
    ]),
    openGallery(payload) {
      let pos = this.images.findIndex((item) => item.position === payload);
      let el = this.images[pos];
      let arr = this.images;
      arr.splice(pos, 1);
      arr.splice(0, 0, el);
      this.SET_GALLERY_ITEMS(arr);
      this.SET_GALLERY_INDEX(payload);
      this.SET_GALLERY(true);
    },
    setResult(payload){
      this.extraData=payload
      console.log(this.extraData)
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/sass/variables";
@import "../../assets/sass/buttons";
@import "../../assets/sass/cards";
@import "../../assets/sass/modal";

.home {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 70vh auto;
  width: 100vw;
  div.autocomplete-container {
    padding: 25px 13em 45px 3em;
    div {
      display: grid;
      grid-template-columns: auto 60px;
      grid-template-rows: 50px;
      border-radius: 50px;
      background-color: white;
      padding: 0px;
      overflow: visible;
      .radius-left {
        border-bottom-left-radius: 50px;
        border-top-left-radius: 50px;
      }
      .radius-right {
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
      }

      button {
        margin-block-end: 0px;
        margin-block-start: 0px;
        background-color: $theme;
        border: none;
        cursor: pointer;
        i.material-icons {
          vertical-align: middle;
          font-size: 35px;
          color: white;
        }
      }
      button:disabled {
        opacity: 0.5;
        cursor: no-drop;
      }
    }
  }
  .search {
    background-image: linear-gradient(
        to bottom,
        transparent,
        rgba(0, 0, 0, 0.75)
      ),
      url("https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F2%20planta%2FPLANTA%20-06.jpg?alt=media&token=afbd02b2-a0ea-4fdd-b317-c1c5ded4ba0c&_gl=1*siyrjn*_ga*MTIyNTY4ODAyOC4xNjg1NzMyODQy*_ga_CW55HF8NVT*MTY4NTk5OTYxNy4zLjEuMTY4NTk5OTcxMy4wLjAuMA..");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: grid;
    grid-template-rows: calc(100% - 220px) 50px 120px 50px;
    h1,
    h4 {
      position: relative;
      text-align: center;
      padding-left: 10%;
      padding-right: 10%;
      margin-block-end: 0px;
      margin-block-start: 0px;
    }
    div.search-options {
      align-content: center;
      input[type="radio"] {
        margin-block-start: 5px;
        margin-block-end: 5px;
        height: 20px;
        width: 20px;
        margin-right: 5px;
        display: inline-block;
        vertical-align: middle;
      }
      label {
        color: white;
        display: inline-block;
        margin-right: 30px;
        margin-block-end: 0px;
        margin-block-start: 0px;
        vertical-align: middle;
        margin-top: 0px;
        line-height: 20px;
      }
    }
    div {
      padding-left: 10%;
      padding-right: 10%;
      position: relative;
      h1 {
        text-align: center;
        display: block;
        width: 100%;
        bottom: 0%;
        left: 50%;
        margin-bottom: 0px;
        position: absolute;
        transform: translateX(-50%);
      }
    }
    h1 {
      font-size: 3em;
      font-weight: 700;
      color: $theme-l4;
      vertical-align: text-bottom;
    }
    h2{
      font-size: 2em;
      font-weight: 500;
    }
    h3{
      font-size: 1.5em;
      font-weight: 400;
    }
    h4 {
      font-size: 2em;
      color: wheat;
    }
  }
  .section {
    position: relative;
    padding: 0.5em 2em;
    display: grid;
    grid-template-rows: auto;
    gap: 1em;
    
      
    h1 {
            font-size: 3em;
            font-weight: 700;
            margin-block-start: 0;
            margin-block-end: 0;
          }
          p {
            font-size: 1.1em;
            font-weight: 300;
            text-align: justify;
          }
          h2{ 
            font-size: 2.5em;
            font-weight: 600;

          }
          h3{ 
            font-size: 2em;
            font-weight: 500;

          }
    &.color1 {
      background-color: $blue-grey;
      h1,
      h2,
      h3,
      h4,
      h5 {
        color: $grey-2;
      }
      p,
      li {
        color: $grey-1;
      }
    }
    &.color2 {
      background-color: $grey-1;
      h1,
      h2,
      h3,
      h4,
      h5 {
        color: $theme-d1;
      }
      p,
      li {
        color: $theme-d3;
      }
    }

    &.color3 {
      background-color: $theme-l4;
      h1,
      h2,
      h3,
      h4,
      h5 {
        color: $theme-d2;
      }
      p,
      li {
        color: $blue-grey-dark;
      }
    }
    &.split,
    &.full {
      height: 100%;
      
      div {
        &.full-height{
        height: 100% !important;}
        &.full {
          grid-column-start: 1;
          grid-column-end: 3;
        }
        position: relative;
        display: block;
        .title,
        .content {
          width: 100%;
          display: block;
          height: fit-content;
          &.img {
            img.left {
              border-radius: 1em;
              width: 100%;
            }
            img.right {
              border-radius: 1em;
              width: 100%;
            }
          }
          &.multy {
            display: grid;
          }
          h1 {
            font-size: 3em;
            font-weight: 700;
            margin-block-start: 0;
            margin-block-end: 0;
          }
          p {
            font-size: 1.1em;
            font-weight: 300;
            text-align: justify;
          }
          
        }
        .image {
          border-radius: 1em;
          width: 100%;
          height: 100%;
          max-height: 100%;
          padding-top: 100%;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          &#img1 {
            background-image: url("https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F1%20mina%2FLastScan%20TYUI.jpg?alt=media&token=a22f582f-788c-48b4-b1b2-4a826a056bd4&_gl=1*anfvst*_ga*NjAzNTQwNTkxLjE2ODU3MjcyNzM.*_ga_CW55HF8NVT*MTY4NTg0MDU5My41LjEuMTY4NTg0MDYwMS4wLjAuMA..");
          }
          &#img2 {
            background-image: url("https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F1%20mina%2FMINA%20-40.jpg?alt=media&token=ade126b1-af45-4eb7-a212-3cf1e6d13b9f&_gl=1*lf7w9i*_ga*NjAzNTQwNTkxLjE2ODU3MjcyNzM.*_ga_CW55HF8NVT*MTY4NTkxNTczMy43LjEuMTY4NTkxNjU0OS4wLjAuMA..");
          }
          &#img3 {
            background-image: url("https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F1%20mina%2FLastScan%20106.jpg?alt=media&token=996f6d57-a11f-48b8-a920-405597c273dd&_gl=1*1gbuw9u*_ga*NjAzNTQwNTkxLjE2ODU3MjcyNzM.*_ga_CW55HF8NVT*MTY4NTkxNTczMy43LjEuMTY4NTkxNjgwMC4wLjAuMA..");
          }
        }
      }
    }
    .gallery {
            display: grid;
            grid-gap: 0.8em;
            grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
            position: relative;
            padding-bottom: 2em;
            .item {
              padding-top: 100%;
              background-size: cover;
              background-repeat: no-repeat;
              background-position: center;
              position: relative;
              border-radius: 5px;
              overflow: hidden;
              .hover {
                height: 100%;
                width: 100%;
                position: absolute;
                background-color: rgba($color: #000000, $alpha: 0.65);
                top: 0%;
                margin-top: 0%;
                opacity: 0;
                div {
                  width: 100%;
                  margin-top: calc(50% - 32px);
                  i.material-icons {
                    font-size: 35px;
                    color: whitesmoke;
                    text-align: center;
                    width: 100%;
                    vertical-align: middle;
                  }
                  h5 {
                    color: whitesmoke;
                    text-align: center;
                  }
                }
              }
              .hover:hover {
                cursor: pointer;
                opacity: 1;
              }
            }
          }
  }
}
@media (min-width: 768px) {
  .home {
    grid-template-rows: 75vh auto;
    .search {
      .video-container {
        video {
          min-height: 100%;
          min-width: 100%;
        }
      }
      .content {
        margin-left: 5em !important;
      }
    }
    .section {
      padding: 3em 5em;
      gap: 0.5em 2em;
      &.split {
        &.left {
          grid-template-columns: 1.5fr 2.5fr;
        }
        &.right {
          grid-template-columns: 2.5fr 1.5fr;
        }
        .content {
          &.img {
            img.left {
              border-radius: 1em;
              float: left;
              max-width: 40%;
              margin-right: 2em;
              margin-top: 0.8em;
              margin-bottom: 0.6em;
            }
            img.right {
              border-radius: 1em;
              float: right;
              max-width: 40%;
              margin-left: 2em;
              margin-top: 0.8em;
              margin-bottom: 0.6em;
            }
          }
        }
      }
    }
  }
}
@media (min-width: 992px) {
  .home {
    grid-template-rows: 75vh auto;
    .search {
      .video-container {
        video {
          min-height: 100%;
          min-width: 100%;
        }
      }
      .content {
        margin-left: 11em !important;
      }
    }
    .section {
      padding: 3em 13em;
      &.split {
        &.left {
          grid-template-columns: 1.5fr 2.5fr;
        }
        &.right {
          grid-template-columns: 2.5fr 1.5fr;
        }
      }
    }
  }
}
</style>
