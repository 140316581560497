<template>
  <div class="home">
    <div class="search">
      <div class="video-container">
        <video autoplay muted loop>
          <source
            src="https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fhome-video.mp4?alt=media&token=b27d7120-7b7a-420f-b8c3-3e6e3dc98ecc"
            type="video/mp4"
          />
        </video>
      </div>

      <div class="content">
        <h3 v-html="'Bienvenido al'"></h3>
        <h1 v-html="'MUSEO de'"></h1>
        <h1 v-html="'PUERTO'"></h1>
        <h1 v-html="'CRISTAL'"></h1>
      </div>
    </div>
    <div class="section split color1">
      <div>
        <div class="title">
          <h2 v-html="'Campamento Minero:'"></h2>
          <h1 v-html="'PUERTO'"></h1>
          <h1 v-html="'CRISTAL'"></h1>
        </div>
      </div>
      <div>
        <div class="content">
          <p
            v-html="
              'Puerto Cristal es un Campamento Minero abandonado, ubicado en el Lago General Carrera, Regi&oacute;n de Ays&eacute;n, que surge gracias al descubrimiento de un yacimiento mineral rico en plomo y zinc a mediados de los a&ntilde;os 30 y que se convirti&oacute; en un polo de desarrollo industrial, econ&oacute;mico y tecnol&oacute;gico al ser el principal productor de plomo y zinc de Chile, llegando a vivir en el campamento m&aacute;s de 1.500 personas. Tras el cierre de la empresa minera en 1997 y el consecuente desalojo del lugar, el campamento minero qued&oacute; abandonado por a&ntilde;os hasta que en el a&ntilde;o 2008 se declar&oacute; Monumento Nacional categor&iacute;a hist&oacute;rico.'
            "
          ></p>
        </div>
      </div>
    </div>
    <div class="section color2 split">
      <div><div class="image" id="img1"></div></div>
      <div>
        <div class="content">
          <h1>Historia del Museo</h1>
          <p
            v-html="
              'A partir del a&ntilde;o 2014 la familia Casanova Uribe en colaboraci&oacute;n con Corporaci&oacute;n Memoria Austral, iniciaron un trabajo de rescate y puesta en valor de Puerto Cristal, su historia y patrimonio, facilitando el acceso a este Monumento Hist&oacute;rico, y promoviendo la cultura e identidad ligada a este lugar.'
            "
          ></p>
          <p
            v-html="
              'En este contexto, el a&ntilde;o 2017 se cre&oacute; la Corporaci&oacute;n Puerto Cristal, instituci&oacute;n que ha trabajado en la creaci&oacute;n del Museo de Puerto Cristal desarrollando m&uacute;ltiples proyectos que fortalezcan este espacio de aprendizaje, interacci&oacute;n y sociabilizaci&oacute;n del conocimiento ligado a este Campamento Minero.'
            "
          ></p>
        </div>
      </div>
    </div>
    <div class="section full">
      <div>
        <div class="content">
          <h1 v-html="'Colaboradores'"></h1>
          <p
            v-html="
              'La Corporaci&oacute;n Puerto Cristal trabaja en colaboraci&oacute;n con instituciones que contribuyen al fortalecimiento del Museo, a trav&eacute;s iniciativas de investigaci&oacute;n, dise&ntilde;o, promoci&oacute;n e intervenci&oacute;n en este lugar.'
            "
          ></p>
          <CardSlider :setup="{ list: col,target:'new' }" />
        </div>
      </div>
    </div>
    <Loading text="cargando" />
    <Message />
  </div>
</template>
<script>
// @ is an alias to /src
import Loading from "@/components/Loading.vue";
import Message from "@/components/Message.vue";
import CardSlider from "@/components/CardSlider.vue";

import { mapMutations } from "vuex";
export default {
  name: "Home-page",
  components: {
    Loading,
    Message,
    CardSlider,
  },
  data: function () {
    return {
      col: [
        {
          link: "https://memoriaaustral.cl/",
          imagen:
            "https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Flogo_cma.jpg?alt=media&token=44e3bbc3-86c4-4e42-b62a-3613a3ff2e34",
          title: "Corporaci&oacute;n Memoria Austral",
          content:
            "Corporaci&oacute;n Cultural Memoria Austral nace en el a&ntilde;o 2013, como corporaci&oacute;n sin fines de lucro, con el objetivo de velar por el rescate y conservaci&oacute;n de la memoria de Ays&eacute;n, desde todos los &aacute;mbitos del patrimonio, favoreciendo la salvaguarda de la identidad regional.",
        },
        {
          link: "https://www.museoregionalaysen.gob.cl/",
          imagen:
            "https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2FMuseo_Regional_Aysen.jpg?alt=media&token=13a64cbc-d5de-4a35-ad1b-c2fd625b054b",
          title: "Museo Regional de Ays&eacute;n",
          content:
            "El Museo Regional de Ays&eacute;n es una instituci&oacute;n perteneciente al Servicio Nacional del Patrimonio Cultural. Su apertura a la comunidad se produjo el a&ntilde;o 2018, luego de un largo trabajo de restauraci&oacute;n y habilitaci&oacute;n del Monumento Hist&oacute;rico Construcciones de la Sociedad Industrial de Ays&eacute;n y la construcci&oacute;n de un moderno edificio que alberga la Exposici&oacute;n Permanente Regional, Dep&oacute;sito de Colecciones, Auditorio y Oficinas de Trabajo.",
        },
        {
          link: "https://web.facebook.com/groups/310271558203",
          imagen:
            "https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Flogo_cristalinos.jpg?alt=media&token=e1e5fb66-43fb-4e98-8ddb-2717b54878f2",
          title: "Agrupaci&oacute;n Social y Cultural Los Cristalinos",
          content:
            "La Agrupaci&oacute;n Social y Cultural Los Cristalinos es una instituci&oacute;n que congrega a los antiguos habitantes del Campamento Minero Puerto Cristal, y que con su actuar, busca mantener viva su historia e identidad ligada a este Monumento Nacional.",
        },
      ],
    };
  },
  created() {
    this.SET_LOADING(false);
  },
  methods: {
    ...mapMutations(["SET_LOADING"]),
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/sass/variables";
@import "../assets/sass/buttons";
@import "../assets/sass/cards";

.home {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 70vh auto;
  width: 100vw;
  div.autocomplete-container {
    padding: 25px 13em 45px 3em;
    div {
      display: grid;
      grid-template-columns: auto 60px;
      grid-template-rows: 50px;
      border-radius: 50px;
      background-color: white;
      padding: 0px;
      overflow: visible;
      .radius-left {
        border-bottom-left-radius: 50px;
        border-top-left-radius: 50px;
      }
      .radius-right {
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
      }

      button {
        margin-block-end: 0px;
        margin-block-start: 0px;
        background-color: $theme;
        border: none;
        cursor: pointer;
        i.material-icons {
          vertical-align: middle;
          font-size: 35px;
          color: white;
        }
      }
      button:disabled {
        opacity: 0.5;
        cursor: no-drop;
      }
    }
  }
  .search {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
    overflow: hidden;
    .video-container {
      position: absolute;
      width: 100%;
      height: 100%;
      video {
        min-width: 100%;
        min-height: 100%;

        /* Setting width & height to auto prevents the browser from stretching or squishing the video */
        width: auto;
        height: auto;

        /* Center the video */
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .content {
      background-color: rgba($color: #ffb980, $alpha: 0.6);
      position: relative;
      bottom: 0%;
      padding: 2em;
      margin: auto 2em 2em 2em;
      h3 {
        font-size: 1.4em;
        color: $theme-d3;
        font-weight: 500;
      }
      h1 {
        font-size: 3em;
        font-weight: 700;
        color: $theme-d3;
        margin-block-end: 0;
        margin-block-start: 0;
      }
    }
  }
  .section {
    position: relative;
    padding: 2em 2em;
    display: grid;
    grid-template-rows: auto;
    gap: 1em;
    &.color1 {
      background-color: $blue-grey;
      h1,
      h2,
      h3,
      h4,
      h5 {
        color: $grey-2;
      }
      p,
      li {
        color: $grey-1;
      }
    }
    &.color2 {
      background-color: $grey-1;
      h1,
      h2,
      h3,
      h4,
      h5 {
        color: $theme-d1;
      }
      p,
      li {
        color: $theme-d3;
      }
    }

    &.color3 {
      background-color: $theme-l4;
      h1,
      h2,
      h3,
      h4,
      h5 {
        color: $theme-d2;
      }
      p,
      li {
        color: $blue-grey-dark;
      }
    }
    &.split,&.full {
      height: 100%;
      div {
        position: relative;
        height: 100% !important;
        display: block;
        .title,
        .content {
          width: 100%;
          display: block;
          height: fit-content;
          h1 {
            font-size: 3em;
            font-weight: 700;
            margin-block-start: 0;
            margin-block-end: 0;
          }
          p {
            font-size: 1.2em;
            font-weight: 300;
          }
        }
        .image {
          border-radius: 1em;
          width: 100%;
          padding-top: 100%;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          &#img1 {
            background-image: url("../assets/media/MAESTRANZA.jpg");
          }
        }
      }
    }
  }
}
@media (min-width: 768px) {
  .home {
    grid-template-rows: 75vh auto;
    .search {
      .video-container {
        video {
          min-height: 100%;
          min-width: 100%;
        }
      }
      .content {
        margin-left: 5em !important;
      }
    }
    .section {
      padding: 3em 5em;
      gap: 2em;
      &.split {
        grid-template-columns: 1.5fr 2.5fr;
      }
    }
  }
}
@media (min-width: 992px) {
  .home {
    grid-template-rows: 75vh auto;
    .search {
      .video-container {
        video {
          min-height: 100%;
          min-width: 100%;
        }
      }
      .content {
        margin-left: 11em !important;
      }
    }
    .section {
      padding: 3em 13em;
      &.split {
        grid-template-columns: 1.5fr 2.5fr;
      }
    }
  }
}
</style>
