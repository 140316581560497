<template>
  <div class="home">
    <div class="search">
      <div>
        <h1 v-html="'BODEGAS PORTUARIAS'"></h1>
      </div>
      <h4 v-html="'Sector Industrial'"></h4>
    </div>
    <div class="section color1 split left">
      
      <div class="full">
        <div class="content img">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F5%20bodegas%2FMUELLE%20-%2030.jpg?alt=media&token=569a41b8-5146-4c49-ae04-dd4ac4e038a6&_gl=1*clt8uw*_ga*NjAzNTQwNTkxLjE2ODU3MjcyNzM.*_ga_CW55HF8NVT*MTY4NjMwNzMwOC4xNC4xLjE2ODYzMDc0NjEuMC4wLjA."
            alt=""
            class="right-60"
          />
          <p v-html="'La actividad minera de Ays&eacute;n, que alcanz&oacute; su m&aacute;ximo esplendor durante la segunda mitad del siglo veinte, estuvo directamente relacionada con el Lago General Carrera, pues se crearon nuevas rutas de navegaci&oacute;n que facilitaron la exportaci&oacute;n del mineral. '"></p>
<p v-html="'El puerto era una pieza fundamental para el funcionamiento del enclave. Adem&aacute;s de favorecer la exportaci&oacute;n minera, facilitaba el abastecimiento de la faena y del campamento minero. '"></p>
<p v-html="'En la zona del puerto se encontraban varias edificaciones, entre ellas estaban las bodegas portuarias, el galp&oacute;n de secado de plomo, el matadero y la muebler&iacute;a. Todas estas construcciones eran elaboradas, principalmente en madera, piedra y cemento. '"></p>

        </div>
      </div>
    </div>
    
    <div class="section color2 split right">
      <div class="full">
        <div class="content img">
          <h1 v-html="'BODEGAS PORTUARIAS'"></h1>
          <p v-html="'Las bodegas portuarias cumpl&iacute;an un rol fundamental para el funcionamiento de Puerto Cristal ya que eran el punto de entrada al campamento minero. Este edificio, construido en piedra, madera y zinc, se divid&iacute;a en cuatro naves destinadas a diversos usos y funciones. La primera nave estaba dividida en su interior en diferentes dependencias destinadas a oficinas administrativas. La segunda nave era un galp&oacute;n de acopio de repuestos el&eacute;ctricos, mec&aacute;nicos, navales, sanitarios y todo aquello relacionado con el proceso productivo. La tercera nave era un galp&oacute;n de almacenaje del mineral concentrado, pr&oacute;ximo para su exportaci&oacute;n. La cuarta nave se destinaba al acopio de equipos y maquinarias industriales. '"></p>
          <CardSlider :setup="{ list: col ,target:'return'}" v-on:get-result="setResult"/>
          
        
        </div>
      </div>
    </div>

    <div class="section split left">
      <div class="full">
        <div class="content img">
          <img src="https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F5%20bodegas%2FIMG_9901%20(1).jpg?alt=media&token=315ba218-0393-419c-9525-4ebdd68a20d8&_gl=1*1br6mdz*_ga*NjAzNTQwNTkxLjE2ODU3MjcyNzM.*_ga_CW55HF8NVT*MTY4NjQzNDY5Ni4xOC4xLjE2ODY0MzQ5MDMuMC4wLjA." alt="" class="left-60">
          
          <h1 v-html="'MUELLE'"></h1>
          <p v-html="'La comercializaci&oacute;n del plomo y del zinc, permiti&oacute; conectar los puertos de la regi&oacute;n de Ays&eacute;n con los puertos europeos. Durante los primeros a&ntilde;os de explotaci&oacute;n, el mineral se envasaba en sacos, se cargaba en los barcos y desde all&iacute; atravesaba el Lago General Carrera hasta llegar a Chile Chico. Luego, en un cami&oacute;n, continuaba su camino rumbo a Las Heras, en la Patagonia Argentina, donde se transfer&iacute;a la carga a un ferrocarril con direcci&oacute;n a Puerto Deseado (costas del Oc&eacute;ano Atl&aacute;ntico). All&iacute; la carga se embarcaba y segu&iacute;a su destino hacia los puertos de Hamburgo o Amberes. '"></p>
<p v-html="'M&aacute;s adelante, debido a la importancia que ten&iacute;a la actividad productiva de la Compa&ntilde;&iacute;a Minera Ays&eacute;n, se consolid&oacute; el camino que conectaba Puerto Ib&aacute;&ntilde;ez con Coyhaique.  De esta manera, a mediados de la d&eacute;cada de los cincuenta, el mineral extra&iacute;do en Puerto Cristal dej&oacute; de enviarse a Argentina y comenz&oacute; a exportarse a Europa directamente desde Puerto Chacabuco. El nuevo recorrido del mineral era en barco desde Puerto Cristal a Puerto Ib&aacute;&ntilde;ez, para despu&eacute;s continuar en cami&oacute;n hacia Puerto Chacabuco. '"></p>
<p v-html="'El muelle se caracterizaba por su forma de U, lo que proteg&iacute;a y resguardaba a las embarcaciones de las inclemencias del tiempo y del fuerte oleaje que predominaba en ese sector del lago. Su construcci&oacute;n se realiz&oacute; en base a postes de madera enterrados en el lago y a rocas colocadas entre ellos. '"></p>
<p v-html="'Al final del muelle destacaba la gr&uacute;a que se utilizaba para cargar y descargar el mineral, la mercader&iacute;a, los animales y otras cargas. A un costado de este hab&iacute;a un estanque con combustible para abastecer a los barcos. '"></p>

        </div>
      </div>
    </div>

    <div class="section color1 split right">
      
      <div class="full">
        <div class="content img">
          <img src="https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F5%20bodegas%2FLastScan%2099.jpg?alt=media&token=545aed6c-481f-4a38-a485-8f4ee1b6e661" alt="" class="right-60">
          
          <h1 v-html="'BARCOS'"></h1>
          <p v-html="'Los barcos cumpl&iacute;an un rol fundamental para las operaciones de la empresa y constitu&iacute;an una parte importante de la vida del campamento minero, siendo Puerto Cristal un lugar vinculado directamente con el lago, donde el transporte y el abastecimiento se realizaban por v&iacute;a lacustre. '"></p>
<p v-html="'Las embarcaciones que cruzaban el Lago General Carrera transportaban pasajeros, carga, materiales, animales y el mineral que se exportaba. Las condiciones clim&aacute;ticas no siempre eran favorables, por lo que los nav&iacute;os deb&iacute;an fondearse en la orilla cuando las condiciones clim&aacute;ticas se volv&iacute;an adversas. All&iacute; pod&iacute;an permanecer varios d&iacute;as a la espera de poder continuar su rumbo. '"></p>
<p v-html="'Durante los primeros a&ntilde;os, la empresa contrataba los servicios de embarcaciones como el “Chile” o el “Andes”, tanto para el transporte de pasajeros como para el transporte de carga. Posteriormente, la empresa construy&oacute; el barco “Don Edmundo”, llamado as&iacute; en honor al primer administrador de Puerto Cristal: Don Edmundo Elissetche. Este nav&iacute;o fue por muchos a&ntilde;os la embarcaci&oacute;n m&aacute;s grande que naveg&oacute; en el lago. La embarcaci&oacute;n era de madera y se construy&oacute; en el propio muelle de Puerto Cristal, gracias a las habilidades de los “carpinteros de ribera” provenientes de Chilo&eacute;. En el a&ntilde;o 1952, “Don Edmundo” toc&oacute; las aguas del Lago General Carrera y hacia finales de los a&ntilde;os ochenta se incendi&oacute; en las costas de Puerto Cristal debido a un corto circuito que se produjo en su interior, salv&aacute;ndose parte del casco y los motores. '"></p>
<p v-html="'La Empresa Minera Ays&eacute;n (EMA) tambi&eacute;n fue due&ntilde;a del barco “Don Cote”, llamado as&iacute; en honor a Don Jos&eacute; Ayala, segundo administrador de Puerto Cristal, el “Remolcador”, una embarcaci&oacute;n menor que sol&iacute;a remolcar balsas con materiales provenientes de distintas localidades a orillas del lago, y la “Chalupa” embarcaci&oacute;n menor llevada a Puerto Cristal por Don Edmundo Elissetche. '"></p>

        </div>
      </div>
    </div>
    <div class="section">
          <h1 v-html="'Fotograf&iacute;as'"></h1>
          <div class="gallery">
            <div
              class="item"
              v-for="(item, index) in images"
              :key="index"
              :style="'background-image:url(' + item.url + ')'"
            >
              <div class="hover" @click="openGallery(index)">
                <div>
                  <i class="material-icons">fullscreen</i>
                  <h5>{{ item.name }}</h5>
                </div>
              </div>
            </div>
        
      </div>
    </div>
    <div class="modal wide" v-if="extraData !=='none'">
      <div class="top" @click="extraData = 'none'"></div>
      <div class="left" @click="extraData = 'none'"></div>
      <div class="right" @click="extraData = 'none'"></div>
      <div class="bottom" @click="extraData = 'none'"></div>
      <div class="box" v-if="extraData ==='1.1'">
        
        <div class="split-40-60">
          <div class="full-image" :style="{'background-image': 'url(https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F5%20bodegas%2F_DSC0373.jpg?alt=media&token=a697c8c2-3528-4606-83f5-0b4b5a5f07fb)'}"></div>
          <SimpleBar>
            <p class="icon" style="padding: 0.5em 0.5em 0 0;text-align: right;">
          <i class="material-icons" @click="extraData = 'none'">close</i>
        </p>
            <h1 v-html="'1.1 ALMACENAMIENTO'"></h1>
            <p v-html="'La faena minera, la vida cotidiana, las instalaciones b&aacute;sicas de agua potable y electricidad y la continuidad de los procesos productivos exig&iacute;an un correcto y constante funcionamiento del campamento minero.  Debido a la condici&oacute;n aislada de Puerto Cristal, el almacenaje de repuestos de materiales de todo tipo era muy importante. '"></p>
            <p v-html="'El concentrado de zinc era secado y envasado en la planta de procesos desde donde se transportaba a las bodegas portuarias y se almacenaba para su posterior exportaci&oacute;n. En cambio, el concentrado de plomo era enviado directamente al galp&oacute;n de almacenaje en la zona del muelle, construido en madera, donde era secado y posteriormente envasado. '"></p>

          </SimpleBar>
        </div>
      </div>
      <div class="box" v-if="extraData ==='1.2'">
        
        <div class="split-40-60">
          
          <div class="full-image" :style="{'background-image': 'url(https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F5%20bodegas%2FNeNrWBaB.jpeg?alt=media&token=b013f6f4-6509-46fa-b088-558367d313a5)'}"></div>
          <SimpleBar>
            <p class="icon" style="padding: 0.5em 0.5em 0 0;text-align: right;">
          <i class="material-icons" @click="extraData = 'none'">close</i>
        </p>
            <h1 v-html="'1.2 OFICINAS'"></h1>
            <p v-html="'Este sector del edificio, con cielo falso y tabiquer&iacute;a de madera, cumpl&iacute;a un papel important&iacute;simo en la gesti&oacute;n y administraci&oacute;n de la empresa. En &eacute;l se llevaba la contabilidad, el registro de las horas trabajadas y sueldos, los inventarios de activos, las facturas de compraventa y otros procedimientos administrativos. Adem&aacute;s, se manten&iacute;a un estrecho v&iacute;nculo con la navegaci&oacute;n, ya que se llevaba el registro de los zarpes y arribos de las diferentes embarcaciones y se vend&iacute;an boletos a aquellos pasajeros que necesitaban viajar en los barcos de la empresa. '"></p>
            <p v-html="'Desde un punto de vista administrativo, en Puerto Cristal se llevaba un registro contable y detallado de todos los procesos. Todas las piezas, repuestos, m&aacute;quinas y otras materias primas que llegaban al puerto se etiquetaban para facilitar su identificaci&oacute;n. En el momento que se requer&iacute;a ocupar alguno de estos materiales almacenados, los trabajadores, junto al encargado de bodega, deb&iacute;an completar los formularios correspondientes para hacer el retiro de las piezas o repuestos. '"></p>
            <p v-html="'Fue muy importante para la administraci&oacute;n de Cristal mantener al d&iacute;a el registro del inventario de la empresa, ya que all&iacute; se inclu&iacute;an todos los activos: los materiales almacenados en las bodegas, las maquinarias, las edificaciones y el mobiliario, pues todo esto formaba parte del campamento minero y de sus instalaciones productivas. '"></p>
            <p v-html="'Respecto a los salarios, se llevaba el registro de las horas trabajadas, de los v&iacute;veres solicitados en la pulper&iacute;a, de los pagos obligatorios y de los dep&oacute;sitos en cuentas de ahorro cuando as&iacute; lo solicitaban los trabajadores. '"></p>
            <p v-html="'Tambi&eacute;n se generaban y gestionaban memos administrativos que facilitaban la comunicaci&oacute;n interna entre las distintas unidades de la empresa, as&iacute; como con otras oficinas de la regi&oacute;n y de Chile. '"></p>

          </SimpleBar>
        </div>
      </div>
    </div>
    <Loading text="cargando" />
    <Message />
    <Gallery/>
  </div>
</template>
<script>
// @ is an alias to /src
import Loading from "@/components/Loading.vue";
import Message from "@/components/Message.vue";
import CardSlider from "@/components/CardSlider.vue";

import Gallery from "@/components/Gallery.vue";
import { SimpleBar } from "simplebar-vue3";

import { mapMutations } from "vuex";
export default {
  name: "Home-page",
  components: {
    Loading,
    Message,CardSlider,
    Gallery, SimpleBar
  },
  data: function () {
    return {
      extraData:"none",
      col: [
        {
          link: "1.1",
          imagen: "https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F5%20bodegas%2FDSC_2704.jpg?alt=media&token=53934695-f368-4b72-8795-07770e50fec7&_gl=1*1iht4w9*_ga*MTIyNTY4ODAyOC4xNjg1NzMyODQy*_ga_CW55HF8NVT*MTY4NjMxOTgwNS4xOS4xLjE2ODYzMTk4MTIuMC4wLjA.",
          title: "1.1 Abastecimiento",
          id:'Bodegas Portuarias',
          content:
            "Sector Mina",
        },
        {
          link: "1.2",
          imagen: "https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F5%20bodegas%2FDSC_2728.jpg?alt=media&token=5a807f8d-46f8-493c-b161-2e95829dd28f&_gl=1*1y049cq*_ga*MTIyNTY4ODAyOC4xNjg1NzMyODQy*_ga_CW55HF8NVT*MTY4NjMxOTgwNS4xOS4xLjE2ODYzMTk4NjguMC4wLjA.",
          title: "1.2 Oficinas",
          id:'Bodegas Portuarias',
          content:
            "Sector Mina",
        },
      ],
      images: [{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F5%20bodegas%2FEntre%20bolsas%20de%20plomo.jpg?alt=media&token=86263e96-fe25-4bfa-bf1e-f1def00662e6',name:'Foto Antigua',position:0},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F5%20bodegas%2FLastScan%20101.jpg?alt=media&token=c4f9981e-6e35-43c5-ba38-5c9f6f7e60d0',name:'Foto Antigua',position:1},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F5%20bodegas%2FLastScan%20120.jpg?alt=media&token=d2b508ec-f948-4311-a87c-ea7d89934188',name:'Foto Antigua',position:2},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F5%20bodegas%2FLastScan%2057.jpg?alt=media&token=b8efb12a-571a-4ec8-931f-901014f0a3db',name:'Foto Antigua',position:3},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F5%20bodegas%2FLastScan%2099.jpg?alt=media&token=545aed6c-481f-4a38-a485-8f4ee1b6e661',name:'Foto Antigua',position:4},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F5%20bodegas%2Fsi%20Hermanas%20Inayao.jpg?alt=media&token=166f3df5-5ca5-4a06-ab34-c94382e1dc2a',name:'Foto Antigua',position:5},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F5%20bodegas%2Fsi%20Puerto%20Cristal%20024.jpg?alt=media&token=cb218212-9bec-49e5-97b7-0d6f9e500794',name:'Foto Antigua',position:6},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F5%20bodegas%2Fsi%20Puerto%20Cristal%20057.jpg?alt=media&token=0c7934d5-1218-48dc-ad6d-1e34f58c1009',name:'Foto Antigua',position:7},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F5%20bodegas%2Fsi%20chile%20con%20Ewaldo.jpg?alt=media&token=47f2186a-145f-469e-9c29-2be8b44d241f',name:'Foto Antigua',position:8},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F5%20bodegas%2FDSC_2704.jpg?alt=media&token=53934695-f368-4b72-8795-07770e50fec7',name:'Interior Nave 2',position:9},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F5%20bodegas%2FDSC_2696.jpg?alt=media&token=8413e7c2-72c1-4095-a084-7f62cb78e48a',name:'Interior Nave 2',position:10},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F5%20bodegas%2FMUELLE%20-%2030.jpg?alt=media&token=569a41b8-5146-4c49-ae04-dd4ac4e038a6',name:'Muelle',position:11},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F5%20bodegas%2FMUELLE%20-%2036.jpg?alt=media&token=4cef4ad0-73f2-42c7-aa10-b91194ba639b',name:'Muelle',position:12},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F5%20bodegas%2Fsi%20MUELLE%20-%2021.jpg?alt=media&token=59c95486-9dcd-4035-884d-96c5fb5d5277',name:'Muelle',position:13},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F5%20bodegas%2FDSC_2728.jpg?alt=media&token=5a807f8d-46f8-493c-b161-2e95829dd28f',name:'Oficinas',position:14},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F5%20bodegas%2FMUELLE%20-%2005.jpg?alt=media&token=4d2eef57-fb13-468e-ba4a-a233fb7dbc5a',name:'Vista al muelle',position:15},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F5%20bodegas%2FMUELLE%20-%2035.jpg?alt=media&token=3773be7a-8e2c-473f-a18b-ef87e5d357da',name:'Vista Exterior',position:16},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F5%20bodegas%2F_DSC0419.jpg?alt=media&token=3a9a39d1-0eb9-4a69-ab88-d2bafce032be',name:'Vista Exterior',position:17}
],
    };
  },
  created() {
    this.SET_LOADING(false);
  },
  methods: {
    ...mapMutations([
      "SET_LOADING",
      "SET_GALLERY",
      "SET_GALLERY_INDEX",
      "SET_GALLERY_ITEMS",
    ]),
    openGallery(payload) {
      let arr = this.images;
      this.SET_GALLERY_ITEMS(arr);
      this.SET_GALLERY_INDEX(payload);
      this.SET_GALLERY(true);
    },
    setResult(payload){
      this.extraData=payload
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/sass/variables";
@import "../../assets/sass/buttons";
@import "../../assets/sass/cards";
@import "../../assets/sass/modal";

.home {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 70vh auto;
  width: 100vw;
  div.autocomplete-container {
    padding: 25px 13em 45px 3em;
    div {
      display: grid;
      grid-template-columns: auto 60px;
      grid-template-rows: 50px;
      border-radius: 50px;
      background-color: white;
      padding: 0px;
      overflow: visible;
      .radius-left {
        border-bottom-left-radius: 50px;
        border-top-left-radius: 50px;
      }
      .radius-right {
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
      }

      button {
        margin-block-end: 0px;
        margin-block-start: 0px;
        background-color: $theme;
        border: none;
        cursor: pointer;
        i.material-icons {
          vertical-align: middle;
          font-size: 35px;
          color: white;
        }
      }
      button:disabled {
        opacity: 0.5;
        cursor: no-drop;
      }
    }
  }
  .search {
    background-image: linear-gradient(
        to bottom,
        transparent,
        rgba(0, 0, 0, 0.75)
      ),
      url("https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F5%20bodegas%2FMUELLE%20-%2035.jpg?alt=media&token=3773be7a-8e2c-473f-a18b-ef87e5d357da&_gl=1*rgln1h*_ga*NjAzNTQwNTkxLjE2ODU3MjcyNzM.*_ga_CW55HF8NVT*MTY4NjMwNzMwOC4xNC4xLjE2ODYzMDczNjguMC4wLjA.");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: grid;
    grid-template-rows: calc(100% - 220px) 50px 120px 50px;
    h1,
    h4 {
      position: relative;
      text-align: center;
      padding-left: 10%;
      padding-right: 10%;
      margin-block-end: 0px;
      margin-block-start: 0px;
    }
    div.search-options {
      align-content: center;
      input[type="radio"] {
        margin-block-start: 5px;
        margin-block-end: 5px;
        height: 20px;
        width: 20px;
        margin-right: 5px;
        display: inline-block;
        vertical-align: middle;
      }
      label {
        color: white;
        display: inline-block;
        margin-right: 30px;
        margin-block-end: 0px;
        margin-block-start: 0px;
        vertical-align: middle;
        margin-top: 0px;
        line-height: 20px;
      }
    }
    div {
      padding-left: 10%;
      padding-right: 10%;
      position: relative;
      h1 {
        text-align: center;
        display: block;
        width: 100%;
        bottom: 0%;
        left: 50%;
        margin-bottom: 0px;
        position: absolute;
        transform: translateX(-50%);
      }
    }
    h1 {
      font-size: 3em;
      font-weight: 700;
      color: $theme-l4;
      vertical-align: text-bottom;
    }
    h4 {
      font-size: 2em;
      color: wheat;
    }
  }
  .section {
    position: relative;
    padding: 0.5em 2em;
    display: grid;
    grid-template-rows: auto;
    gap: 1em;
    
      
    h1 {
            font-size: 3em;
            font-weight: 700;
            margin-block-start: 0;
            margin-block-end: 0;
          }
          p {
            font-size: 1.1em;
            font-weight: 300;
            text-align: justify;
          }
    &.color1 {
      background-color: $blue-grey;
      h1,
      h2,
      h3,
      h4,
      h5 {
        color: $grey-2;
      }
      p,
      li {
        color: $grey-1;
      }
    }
    &.color2 {
      background-color: $grey-1;
      h1,
      h2,
      h3,
      h4,
      h5 {
        color: $theme-d1;
      }
      p,
      li {
        color: $theme-d3;
      }
    }

    &.color3 {
      background-color: $theme-l4;
      h1,
      h2,
      h3,
      h4,
      h5 {
        color: $theme-d2;
      }
      p,
      li {
        color: $blue-grey-dark;
      }
    }
    &.split,
    &.full {
      height: 100%;
      div {
        &.full {
          grid-column-start: 1;
          grid-column-end: 3;
        }
        position: relative;
        height: 100% !important;
        display: block;
        .title,
        .content {
          width: 100%;
          display: block;
          height: fit-content;
          &.img {
            img.left-40,img.left-60 {
              border-radius: 1em;
              width: 100%;
            }
            img.right-40,img.right-60 {
              border-radius: 1em;
              width: 100%;
            }
          }
          &.multy {
            display: grid;
          }
          h1 {
            font-size: 3em;
            font-weight: 700;
            margin-block-start: 0;
            margin-block-end: 0;
          }
          p {
            font-size: 1.1em;
            font-weight: 300;
            text-align: justify;
          }
          
        }
        .image {
          border-radius: 1em;
          width: 100%;
          padding-top: 100%;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          &#img1 {
            background-image: url("https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F1%20mina%2FLastScan%20TYUI.jpg?alt=media&token=a22f582f-788c-48b4-b1b2-4a826a056bd4&_gl=1*anfvst*_ga*NjAzNTQwNTkxLjE2ODU3MjcyNzM.*_ga_CW55HF8NVT*MTY4NTg0MDU5My41LjEuMTY4NTg0MDYwMS4wLjAuMA..");
          }
          &#img2 {
            background-image: url("https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F1%20mina%2FMINA%20-40.jpg?alt=media&token=ade126b1-af45-4eb7-a212-3cf1e6d13b9f&_gl=1*lf7w9i*_ga*NjAzNTQwNTkxLjE2ODU3MjcyNzM.*_ga_CW55HF8NVT*MTY4NTkxNTczMy43LjEuMTY4NTkxNjU0OS4wLjAuMA..");
          }
          &#img3 {
            background-image: url("https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F1%20mina%2FLastScan%20106.jpg?alt=media&token=996f6d57-a11f-48b8-a920-405597c273dd&_gl=1*1gbuw9u*_ga*NjAzNTQwNTkxLjE2ODU3MjcyNzM.*_ga_CW55HF8NVT*MTY4NTkxNTczMy43LjEuMTY4NTkxNjgwMC4wLjAuMA..");
          }
        }
      }
    }
    .gallery {
            display: grid;
            grid-gap: 0.8em;
            grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
            position: relative;
            padding-bottom: 2em;
            .item {
              padding-top: 100%;
              background-size: cover;
              background-repeat: no-repeat;
              background-position: center;
              position: relative;
              border-radius: 5px;
              overflow: hidden;
              .hover {
                height: 100%;
                width: 100%;
                position: absolute;
                background-color: rgba($color: #000000, $alpha: 0.65);
                top: 0%;
                margin-top: 0%;
                opacity: 0;
                div {
                  width: 100%;
                  margin-top: calc(50% - 32px);
                  i.material-icons {
                    font-size: 35px;
                    color: whitesmoke;
                    text-align: center;
                    width: 100%;
                    vertical-align: middle;
                  }
                  h5 {
                    color: whitesmoke;
                    text-align: center;
                  }
                }
              }
              .hover:hover {
                cursor: pointer;
                opacity: 1;
              }
            }
          }
  }
}
@media (min-width: 768px) {
  .home {
    grid-template-rows: 75vh auto;
    .search {
      .video-container {
        video {
          min-height: 100%;
          min-width: 100%;
        }
      }
      .content {
        margin-left: 5em !important;
      }
    }
    .section {
      padding: 3em 5em;
      gap: 0.5em 2em;
      &.split {
        &.left {
          grid-template-columns: 1.5fr 2.5fr;
        }
        &.right {
          grid-template-columns: 2.5fr 1.5fr;
        }
        .content {
          &.img {
            img.left-40{
              border-radius: 1em;
              float: left;
              max-width: 40%;
              margin-right: 2em;
            }
            img.left-60{
              border-radius: 1em;
              float: left;
              max-width: 60%;
              margin-right: 2em;
            }
            img.right-60 {
              border-radius: 1em;
              float: right;
              max-width: 60%;
              margin-left: 2em;
            }
          }
        }
      }
    }
  }
}
@media (min-width: 992px) {
  .home {
    grid-template-rows: 75vh auto;
    .search {
      .video-container {
        video {
          min-height: 100%;
          min-width: 100%;
        }
      }
      .content {
        margin-left: 11em !important;
      }
    }
    .section {
      padding: 3em 13em;
      &.split {
        &.left {
          grid-template-columns: 1.5fr 2.5fr;
        }
        &.right {
          grid-template-columns: 2.5fr 1.5fr;
        }
      }
    }
  }
}
</style>
