<template>
  <div class="pagination-container" ref="pages">
    <div :style="'width:'+width">
      <button :disabled="value===1" @click="$emit('page-result',value-1)">
        <i class="material-icons">chevron_left</i>
      </button>
      <i class="material-icons" v-show="displayMoreBefore">more_horiz</i>
      <button
        v-for="n in rangeToDisplay"
        :key="n"
        @click="$emit('page-result',n)"
        :class="{'selected':n===value}"
      >{{n}}</button>
      <i class="material-icons" v-show="displayMoreAfter">more_horiz</i>
      <button :disabled="value===length" @click="$emit('page-result',value+1)">
        <i class="material-icons">chevron_right</i>
      </button>
    </div>
  </div>
</template>
<script>
export default {
  name: "Pagination-component",
  props: ["length", "value"],
  data() {
    return {
      columns: 0,
      displayMoreBefore: false,
      displayMoreAfter: true
    };
  },
  computed: {
    rangeToDisplay() {
      let start = 0;
      let end = 0;
      let array = [];
      if (this.columns <= this.length) {
        if (this.columns % 2 == 0) {
          let x = this.value + (this.columns - 2) / 2;
          if (x > this.length) {
            end = this.length;
          } else {
            end = x;
          }
          start = end - (this.columns - 2);
          if (start < 1) {
            start = 1;
            end = this.columns-2;
          }
        } else {
          let a = Math.trunc((this.columns - 3) / 2);
          if (this.value + a > this.length) {
            end = this.length;
          } else {
            end = this.value + a;
          }
          start = end - (this.columns - 2);
          if (start < 1) {
            start = 1;
            end = this.columns-2;
          }
        }
        if (start === 1) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.displayMoreBefore = false;
        } else {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.displayMoreBefore = true;
          start += 1;
        }
        if (end === this.length) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.displayMoreAfter = false;
        } else {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.displayMoreAfter = true;
          end -= 1;
        }
      }else{
          start=1
          end=this.length
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.displayMoreBefore = false;
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.displayMoreAfter = false;
      }
      for (var i = start; i <= end; i++) {
        array.push(i);
      }
      return array;
    },
    width(){
      let buttons=2
      if(this.displayMoreBefore){
        buttons++
      }
      if(this.displayMoreAfter){
        buttons++
      }
      if(this.length>this.columns){
        return (this.columns*45+5)+'px'
      }else{
        buttons+=this.length
        return (buttons*45+5)+'px'
      }
    }
  },
  ready() {
    this.gridCount(), this.rangeToDisplay;
    window.addEventListener("resize", this.gridCount);
  },
  mounted() {
    this.gridCount(), this.rangeToDisplay;
    window.addEventListener("resize", this.gridCount);
  },
  beforeUnmount: function() {
    window.removeEventListener("resize", this.gridCount);
  },
  methods: {
    gridCount() {
      if (this.$refs.pages) {
        let i=1
        while((i*45-5)<this.$refs.pages.clientWidth){
          i++
        }
        this.columns = i
      }
    }
  }
};
</script>
<style lang="scss" scope>
@import "./../assets/sass/variables";
.pagination-container {
  width: 100%;
  position: relative;
  padding: 10px 1em;
  height: 60px;
  div {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: fit-content;
    max-width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(40px, 1fr));
    grid-template-rows: 40px;
    grid-gap: 5px;
    i.material-icons {
      vertical-align: middle;
      line-height: 40px;
      width: 40px;
      text-align: center;
      margin: 0px;
      color: $text;
    }
    button {
      height: 40px;
      width: 40px;
      border: solid 1px $text;
      color: $text;
      padding: 0px;
      background-color: transparent;
      font-weight: 300;
      font-size: 16px;
      border-radius: 40px;
      cursor: pointer;
      margin: 0px;
      i.material-icons {
        line-height: 38px;
        width: 38px;
      }
      &:disabled {
        border-color: $text-light;
        cursor: not-allowed;
        i.material-icons {
          color: $text-light;
        }
      }
      &.selected,&:hover {
        border-color: $theme-d1;
        color: $theme-d1;
        i.material-icons {
          color: $theme-d1;
        }
      }
    }
  }
}
</style>
