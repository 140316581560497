import app from '@/firebase'
import {
    getFirestore,
    collection,
    doc,
    query,
    updateDoc,
    addDoc,
    onSnapshot,
    orderBy,deleteDoc
} from "firebase/firestore";
const db = getFirestore(app)
export default {
    state: {
        items: []
    },
    namespaced: true,
    actions: {
        async getItems({
            commit
        }) {

            onSnapshot(query(collection(db, 'Glosario'), orderBy('name')), qS => {
                qS.docChanges().forEach(change => {
                    if (change.type === "added") {
                        commit('ADD_ITEMS', {
                            id: change.doc.id,
                            ...change.doc.data()
                        })
                    }
                    if (change.type === "modified") {
                        commit('UPDATE_ITEM_NAME', { id: change.doc.id, name: change.doc.data().name })
                        commit('UPDATE_ITEM_DEFINITION', { id: change.doc.id, name: change.doc.data().definition })
                    }
                    if (change.type === "removed") {
                        commit('REMOVE_ITEM_BY_ID', change.doc.id)
                    }
                })
            })
        },
        async addItem({ commit }, payload) {
            let doc =await addDoc(collection(db, 'Glosario'), {...payload})
            commit('ADD_ITEM', { id: doc.id, ...doc.data() })
        },
        async updateItem({ commit }, payload) {
            await updateDoc(doc(db, 'Glosario', payload.id), { ...payload.data })
            commit('UPDATE_ITEM', payload)
        },
        async updateItemName({ commit }, payload) {
            await updateDoc(doc(db, 'Glosario', payload.id), { name: payload.name })
            commit('UPDATE_ITEM_NAME', payload)
        },
        async updateItemDefinition({ commit }, payload) {
            await updateDoc(doc(db, 'Glosario', payload.id), { definition: payload.definition })
            commit('UPDATE_ITEM_DEFINITION', payload)
        },
        async deleteItemById({ commit }, payload) {
            await deleteDoc(doc(db, 'Glosario', payload))
            commit('REMOVE_ITEM_BY_ID', payload)
        },
        
    },
    getters: {
        items: state => {
            return state.items
        },
        abcItems: state => {
            let arr = []
            state.items.forEach(item => {
                let char = item.name.split('')[0]
                let exists = arr.find(el => el === char.toUpperCase())
                if (exists === undefined) {
                    arr.push(char.toUpperCase())
                }
            })
            return arr
        },
        itemsByIndex: state => payload => {
            let arr = []
            state.items.forEach(item => {
                let char = item.name.split('')[0]
                if (char.toUpperCase() === payload) {
                    if (item.definition.split('').length >= 200) {
                        arr.push({ ...item, shortDefinition: item.definition.slice(0, 199), isLong: true })
                    } else {
                        arr.push({ ...item, shortDefinition: item.definition, isLong: false })
                    }
                }
            })
            return arr
        },
        name: state => payload => {
            let item = state.items.find(el => el.id === payload)
            if (item) {
                return item.name
            } else {
                return ''
            }
        }
    },
    mutations: {
        SET_ITEMS(state, payload) {
            state.items = payload
        },
        ADD_ITEMS(state, payload) {
            let temp = { id: "", name: "", definition: "", soure: "" }
            if (payload.id !== null && payload.id !== undefined) {
                temp.id = payload.id
            }
            if (payload.name !== null && payload.name !== undefined) {
                temp.name = payload.name
            }
            if (payload.definition !== null && payload.definition !== undefined) {
                temp.definition = payload.definition
            }
            if (payload.source !== null && payload.source !== undefined) {
                temp.source = payload.source
            }
            state.items.push(temp)
        },
        CLEAR_ITEMS(state) {
            state.items = []
        },
        REMOVE_ITEM_BY_ID(state, payload) {
            let index = state.items.findIndex(item => item.id === payload)
            if (index >= 0) {
                state.items.splice(index, 1)
            }
        },
        UPDATE_ITEM(state, payload) {
            let elem = state.items.find(item => item.id === payload.id)
            if (elem) {
                elem.name = payload.data.name
                elem.definition = payload.data.definition
                elem.source = payload.data.source
            }
        },
        UPDATE_ITEM_NAME(state, payload) {
            let elem = state.items.find(item => item.id === payload.id)
            if (elem) {
                elem.name = payload.name
            }
        },
        UPDATE_ITEM_DEFINITION(state, payload) {
            let elem = state.items.find(item => item.id === payload.id)
            if (elem) {
                elem.name = payload.definition
            }
        },
        SET_ITEM_OBJECTS(state, payload) {
            let result = state.items.find(elem => elem.id === payload.id)
            if (result) {
                result.elements = payload.data
            }
        }
    }
}