import app from '@/firebase'
import {
    getFirestore,
    collection,
    getDocs,
    doc,
    getDoc,
    query,
    where,
    setDoc,
    runTransaction,
    updateDoc,
    writeBatch,
} from "firebase/firestore";
const db = getFirestore(app)
import {
    getStorage,
    ref,
    listAll,
    getDownloadURL,
} from "firebase/storage";
let storage = getStorage(app)

export default {
    namespaced: true,
    state: {
        items: [],
        archiveIndex: [],
        fondos: [],
        series:[],
        documentosCargados: [],
        currentFondo: "",
        currentSerie: "",
        currentDoc:"",
        levelList: true,
        levelFondo: false,
        levelSerie: false,
    },
    actions: {
        async getItemIndex({
            commit
        }) {
            const item = doc(db, "archive", "index")
            const docSnap = await getDoc(item)
            if (docSnap.exists()) {
                commit("SET_ARCHIVE_INDEX", doc.data().archiveList)
            }
        },
        async getFondos({
            commit
        }) {
            try {
                let qS = await getDocs(collection(db, 'archiveCollections/PCD/fondos'))
                qS.forEach(document => {
                    
                commit("ADD_FONDO",  {
                        id: document.id,
                        ...document.data()
                    })
                })
                return true
            } catch (e) {
                console.log(e)
                return e
            }
        },
        async getSeries({
                commit
            },
            fondo
        ) {
            try {
                let qS = await getDocs(collection(db, 'archiveCollections/PCD/fondos/' + fondo.id + '/series'))
                let arr = []
                qS.forEach(document => {
                    arr.push({
                        id: document.id,
                        ...document.data(),
                        documentos: []
                    })
                })
                commit("ADD_FONDO", fondo)
                return true
            } catch (e) {
                console.log(e)
                return e
            }
        },
        async getPublishedFondos({
            dispatch
        }) {
            try {
                let qS = await getDocs(collection(db, 'archiveCollections/PCD/fondos'))
                qS.forEach(document => {
                    if (document.data().published === true) {
                        dispatch("getPublishedSeries", {
                            id: document.id,
                            ...document.data()
                        })
                    }
                })
                return true
            } catch (e) {
                console.log(e)
                return e
            }
        },
        async getPublishedSeries({
                commit
            },
            fondo
        ) {
            try {
                let qS = await getDocs(collection(db, 'archiveCollections/PCD/fondos/' + fondo.id + '/series'))
                let arr = []
                qS.forEach(document => {
                    if (document.data().published === true) {
                        arr.push({
                            id: document.id,
                            ...document.data(),
                            documentos: []
                        })
                    }
                })
                fondo.series = arr
                commit("ADD_FONDO", fondo)
                return true
            } catch (e) {
                console.log(e)
                return e
            }
        },
        async getPublishedDocumentosFromSerie({
            commit
        }, payload) {
            try {
                let qS = await getDocs(query(collection(db, 'archive'), where("Fondo", "==", payload.fondo), where("Serie", "==", payload.serie), where("published", "==", "true")))

                let arr = []
                qS.forEach(document => {
                    arr.push({
                        id: document.id,
                        ...document.data()
                    })
                })
                commit("ADD_DOCUMENTOS_TO_SERIE", {
                    ...payload,
                    documentos: arr
                })
                return true
            } catch (e) {
                console.log(e)
                return e
            }
        },
        async getDocumentosFromSerie({
            commit
        }, payload) {
            try {
                let qS = await getDocs(query(collection(db, 'archive'), where("Fondo", "==", payload.fondo), where("Serie", "==", payload.serie)))
                
                let arr = []
                qS.forEach(document => {
                    arr.push({
                        id: document.id,
                        ...document.data()
                    })
                })
                commit("ADD_DOCUMENTOS_TO_SERIE", {
                    ...payload,
                    documentos: arr
                })
                return true

            } catch (e) {
                console.log(e)
                return e
            }
        },
        async addNewFondo({
            commit
        }, payload) {

            try {
                await setDoc(doc(db, 'archiveCollection/PCD/fondos/' + payload.code), {
                    publicData: [{
                        position: 1,
                        field: "Título",
                        value: payload.titulo
                    }, {
                        position: 2,
                        field: "Fechas",
                        value: payload.fechas
                    }, {
                        position: 3,
                        field: "Productor",
                        value: payload.productor
                    }, {
                        position: 4,
                        field: "Historia Institucional",
                        value: "incompleto"
                    }, {
                        position: 5,
                        field: "Alcance y Contenido",
                        value: "incompleto"
                    }, {
                        position: 6,
                        field: "Condiciones de Acceso",
                        value: "incompleto"
                    }, {
                        position: 7,
                        field: "Lengua/Escritura del Documento",
                        value: "incompleto"
                    }, {
                        position: 8,
                        field: "Notas",
                        value: "incompleto"
                    }, ],
                    privateData: [{
                        position: 1,
                        field: "Existencia y Localicación Documentos Originales",
                        value: "incompleto"
                    }, {
                        position: 2,
                        field: "Existencia y Localicación Documentos Copias",
                        value: "incompleto"
                    }, {
                        position: 3,
                        field: "Notas del Archivista",
                        value: "incompleto"
                    }, {
                        position: 4,
                        field: "Fecha de Descripción",
                        value: "incompleto"
                    }, {
                        position: 5,
                        field: "Nombre de quién Registra",
                        value: "incompleto"
                    }],
                    nextIndex: 1,
                })

                commit("ADD_FONDO", {
                    id: payload.code,
                    publicData: [{
                        position: 1,
                        field: "Título",
                        value: payload.titulo
                    }, {
                        position: 2,
                        field: "Fechas",
                        value: payload.fechas
                    }, {
                        position: 3,
                        field: "Productor",
                        value: payload.productor
                    }, {
                        position: 4,
                        field: "Historia Institucional",
                        value: "incompleto"
                    }, {
                        position: 5,
                        field: "Alcance y Contenido",
                        value: "incompleto"
                    }, {
                        position: 6,
                        field: "Condiciones de Acceso",
                        value: "incompleto"
                    }, {
                        position: 7,
                        field: "Lengua/Escritura del Documento",
                        value: "incompleto"
                    }, {
                        position: 8,
                        field: "Notas",
                        value: "incompleto"
                    }, ],
                    privateData: [{
                        position: 1,
                        field: "Existencia y Localicación Documentos Originales",
                        value: "incompleto"
                    }, {
                        position: 2,
                        field: "Existencia y Localicación Documentos Copias",
                        value: "incompleto"
                    }, {
                        position: 3,
                        field: "Notas del Archivista",
                        value: "incompleto"
                    }, {
                        position: 4,
                        field: "Fecha de Descripción",
                        value: "incompleto"
                    }, {
                        position: 5,
                        field: "Nombre de quién Registra",
                        value: "incompleto"
                    }],
                    nextIndex: 1,
                    series: []
                })
                return true

            } catch (e) {
                console.log(e)
                return e
            }
        },
        async addNewSerie({
            commit
        }, payload) {

            try {
                let fondo = doc(db, "archiveCollections/PCD/fondos", payload.fondo)
                let newData = {
                    publicData: [{
                            position: 1,
                            field: "Título",
                            value: payload.serie.titulo
                        }, {
                            position: 2,
                            field: "Fechas",
                            value: payload.serie.fechas
                        }, {
                            position: 3,
                            field: "Productor",
                            value: payload.serie.productor
                        }, {
                            position: 4,
                            field: "Alcance y Contenido",
                            value: "incompleto"
                        }, {
                            position: 5,
                            field: "Condiciones de Acceso",
                            value: "incompleto"
                        }, {
                            position: 6,
                            field: "Lengua/Escritura del Documento",
                            value: "incompleto"
                        }, {
                            position: 7,
                            field: "Características Físicas",
                            value: "incompleto"
                        }, {
                            position: 8,
                            field: "Notas",
                            value: "incompleto"
                        },

                    ],
                    privateData: [{
                        position: 1,
                        field: "Existencia y Localicación Documentos Originales",
                        value: "incompleto"
                    }, {
                        position: 2,
                        field: "Existencia y Localicación Documentos Copias",
                        value: "incompleto"
                    }, {
                        position: 3,
                        field: "Notas del Archivista",
                        value: "incompleto"
                    }, {
                        position: 4,
                        field: "Fecha de Descripción",
                        value: "incompleto"
                    }, {
                        position: 5,
                        field: "Nombre de quién Registra",
                        value: "incompleto"
                    }],
                    nextIndex: 1,
                    volumen: 0
                }
                let result = await runTransaction(db, async (transaction) => {
                    let document = await transaction.get(fondo)
                    let index = document.data().nextIndex
                    let id
                    if (index < 10) {
                        id = "00" + index
                    } else if (index > 9 && index < 100) {
                        id = "0" + index
                    } else {
                        id = index
                    }
                    let newIndex = index + 1
                    transaction.set(
                        fondo.collection("series").document(id), newData)
                    transaction.update(fondo, {
                        nextIndex: newIndex
                    })
                    return {
                        fondo: fondo.id,
                        serie: {
                            id: id,
                            ...newData
                        }
                    }
                })

                commit("ADD_SERIE_TO_FONDO", result)
                return true
            } catch (e) {
                console.log(e)
                return e
            }
        },
        async addNewDocument({
            commit
        }, payload) {

            try {
                let fondo = await doc(db, "archiveCollections/PCD/fondos", payload.fondo)
                let serieRef = await doc(db, "archiveCollections/PCD/fondos/" + payload.fondo + "/series", payload.serie)
                let indexado = await doc(db, "archive", "index")
                let newData = {
                    publicData: [{
                        position: 1,
                        field: "Título",
                        value: payload.doc.titulo
                    }, {
                        position: 2,
                        field: "Fecha",
                        value: payload.doc.fecha
                    }, {
                        position: 3,
                        field: "Nivel de Descripción",
                        value: "incompleto"
                    }, {
                        position: 4,
                        field: "Formato",
                        value: "incompleto"
                    }, {
                        position: 5,
                        field: "Tipo de Documento",
                        value: "incompleto"
                    }, {
                        position: 6,
                        field: "Nombre del Productor",
                        value: payload.doc.productor
                    }, {
                        position: 7,
                        field: "Descripción de Contenido",
                        value: "incompleto"
                    }, {
                        position: 8,
                        field: "Nombres personales identificados en el Documento",
                        value: []
                    }, {
                        position: 9,
                        field: "Nombres de instituciones identificadas en el Documento",
                        value: []
                    }, {
                        position: 10,
                        field: "Lengua/Escritura del Documento",
                        value: "incompleto"
                    }, {
                        position: 11,
                        field: "Estado de conservación",
                        value: "incompleto"
                    }, {
                        position: 12,
                        field: "Notas",
                        value: "incompleto"
                    }],
                    privateData: [{
                        position: 1,
                        field: "Existencia y Localicación Documentos Originales",
                        value: "incompleto"
                    }, {
                        position: 2,
                        field: "Existencia y Localicación Documentos Copias",
                        value: "incompleto"
                    }, {
                        position: 3,
                        field: "Notas del Archivista",
                        value: "incompleto"
                    }, {
                        position: 4,
                        field: "Fecha de Descripción",
                        value: "incompleto"
                    }, {
                        position: 5,
                        field: "Nombre de quién Registra",
                        value: "incompleto"
                    }],
                    conjunto: [],
                    Fondo: payload.fondo,
                    Serie: payload.serie,
                    imagen: "",
                    link: ""
                }
                let result = await runTransaction(db, async (transaction) => {
                    let document = await transaction.get(indexado)
                    let lista = document.data().archiveList
                    let serie = await transaction.get(serieRef)
                    let index = serie.data().nextIndex
                    let id
                    if (index < 10) {
                        id = "000" + index
                    } else if (index > 9 && index < 100) {
                        id = "00" + index
                    } else if (index > 99 && index < 1000) {
                        id = "0" + index
                    } else {
                        id = index
                    }
                    let newIndex = index + 1
                    let code = payload.fondo + "." + payload.serie + "." + id
                    lista.push({
                        id: code,
                        name: payload.doc.titulo
                    })
                    transaction.set(doc(db, "archive", code), {
                        newData
                    })

                    transaction.set(doc(db, "archiveCollections/PCD/fondos/" + payload.fondo + "/series/" + payload.serie + "/documentos").doc(code), {
                        title: payload.doc.titulo
                    })

                    transaction.update(serieRef, {
                        nextIndex: newIndex
                    })

                    transaction.update(doc(db, "archive", "index"), {
                        archiveList: lista
                    })
                    return {
                        fondo: fondo.id,
                        serie: serie.id,
                        doc: {
                            id: payload.fondo + "." + payload.serie + "." + id,
                            ...newData
                        }
                    }
                })
                console.log(result)
                commit("ADD_DOCUMENTO_TO_SERIE", result)
                return true
            } catch (e) {
                console.log(e)
                return e
            }
        },
        async addNewSubDocument({
            commit
        }, payload) {

            try {
                let fondo = await doc(db, "archiveCollections/PCD/fondos", payload.fondo)
                let serie = await doc(db, "archiveCollections/PCD/fondos/" + payload.fondo + "/series", payload.serie)
                let docRef = await doc(db, "archive", payload.doc)
                let newData = {
                    publicData: [{
                        position: 1,
                        field: "Título",
                        value: payload.doc.titulo
                    }, {
                        position: 2,
                        field: "Fecha",
                        value: payload.doc.fecha
                    }, {
                        position: 3,
                        field: "Nivel de Descripción",
                        value: "incompleto"
                    }, {
                        position: 4,
                        field: "Formato",
                        value: "incompleto"
                    }, {
                        position: 5,
                        field: "Tipo de Documento",
                        value: "incompleto"
                    }, {
                        position: 6,
                        field: "Nombre del Productor",
                        value: payload.doc.productor
                    }, {
                        position: 7,
                        field: "Descripción de Contenido",
                        value: "incompleto"
                    }, {
                        position: 8,
                        field: "Nombres personales identificados en el Documento",
                        value: "incompleto"
                    }, {
                        position: 9,
                        field: "Nombres de instituciones identificadas en el Documento",
                        value: "incompleto"
                    }, {
                        position: 10,
                        field: "Lengua/Escritura del Documento",
                        value: "incompleto"
                    }, {
                        position: 11,
                        field: "Estado de conservación",
                        value: "incompleto"
                    }, {
                        position: 12,
                        field: "Notas",
                        value: "incompleto"
                    }],
                    privateData: [{
                        position: 1,
                        field: "Existencia y Localicación Documentos Originales",
                        value: "incompleto"
                    }, {
                        position: 2,
                        field: "Existencia y Localicación Documentos Copias",
                        value: "incompleto"
                    }, {
                        position: 3,
                        field: "Notas del Archivista",
                        value: "incompleto"
                    }, {
                        position: 4,
                        field: "Fecha de Descripción",
                        value: "incompleto"
                    }, {
                        position: 5,
                        field: "Nombre de quién Registra",
                        value: "incompleto"
                    }]
                }
                let result = await runTransaction(db, async (transaction) => {
                    let document = await transaction.get(docRef)
                    let id = document.id + "." + payload.nextIndex
                    let documento = document.data()
                    let array = documento.conjunto
                    array.push({
                        id: id,
                        ...newData
                    })
                    documento.conjunto.push({
                        id: id,
                        ...newData
                    })
                    transaction.update(doc(db, "archive", doc.id), {
                        conjunto: array
                    })
                    return {
                        fondo: fondo.id,
                        serie: serie.id,
                        doc: {
                            id: doc.id,
                            ...documento
                        }
                    }
                })

                commit("SET_DOCUMENTO", result)
                return true
            } catch (e) {
                console.log(e)
                return e
            }
        },
        async updateFondosPublicData({
            commit
        }, payload) {
            try {
                let fondo = await doc(db, "archiveCollections/PCD/fondos", payload.fondo)
                await updateDoc(fondo, {
                    publicData: payload.publicData
                })
                commit('UPDATE_PUBLIC_DATA_FROM_FONDO', payload)
                return true
            } catch (e) {
                console.log(e)
                return e
            }
        },
        async updateFondosPrivateData({
            commit
        }, payload) {
            try {
                let fondo = await doc(db, "archiveCollections/PCD/fondos", payload.fondo)
                await updateDoc(fondo, {
                    privateData: payload.privateData
                })
                commit('UPDATE_PRIVATE_DATA_FROM_FONDO', payload)
                return true
            } catch (e) {
                console.log(e)
                return e
            }
        },
        async updateSeriesPublicData({
            commit
        }, payload) {
            try {
                await updateDoc(doc(db, "archiveCollections/PCD/fondos/" + payload.fondo + "/series", payload.serie.id), {
                    publicData: payload.serie.publicData
                })
                commit('UPDATE_PUBLIC_DATA_FROM_SERIE', payload)
                return true
            } catch (e) {
                console.log(e)
                return e
            }
        },
        async updateSeriesPrivateData({
            commit
        }, payload) {
            try {
                await updateDoc(doc(db, "archiveCollections/PCD/fondos/" + payload.fondo + "/series", payload.serie.id), {
                    privateData: payload.serie.privateData
                })
                commit('UPDATE_PRIVATE_DATA_FROM_SERIE', payload)
                return true

            } catch (e) {
                console.log(e)
                return e
            }
        },
        async updateDocumentoPublicData({
            commit
        }, payload) {
            try {
                await updateDoc(doc(db, "archive", payload.id), {
                    publicData: payload.doc.publicData
                })
                commit('UPDATE_PUBLIC_DATA_FROM_SERIE', payload)
                return true
            } catch (e) {
                console.log(e)
                return e
            }
        },
        async updateDocumentoPrivateData({
            commit
        }, payload) {
            try {
                await updateDoc(doc(db, "archive", payload.id), {
                    privateData: payload.doc.privateData
                })
                commit('UPDATE_PRIVATE_DATA_FROM_SERIE', payload)
                return true
            } catch (e) {
                console.log(e)
                return e
            }
        },
        async updateImageOfDocumento({
            commit
        }, payload) {
            try {
                await updateDoc(doc(db, "archive", payload.id), {
                    imagen: {
                        name: payload.name,
                        link: payload.link
                    }
                })
                commit('UPDATE_IMAGEN_FROM_DOCUMENTO', payload)
                return true
            } catch (e) {
                console.log(e)
                return e
            }
        },
        async updatePDFOfDocumento({
            commit
        }, payload) {
            try {
                await updateDoc(doc(db, "archive", payload.id), {
                    link: {
                        name: payload.name,
                        link: payload.link,
                        restricted: payload.restricted
                    }
                })
                commit('UPDATE_PDF_FROM_DOCUMENTO', payload)
                return true
            } catch (e) {
                console.log(e)
                return e
            }
        },
        async deletePDFOfDocumento({
            commit
        }, payload) {
            console.log(payload)
            try {
                await updateDoc(doc(db, "archive", payload.id), {
                    link: ""
                })
                commit('DELETE_PDF_FROM_DOCUMENTO', payload)
                return true
            } catch (e) {
                console.log(e)
                return e
            }
        },
        async loadDocumento({
            commit,
            getters
        }, payload) {
            try {
                let item = getters.documentos.find(el => el.id === payload)
                if (!item) {
                    let document = await getDoc(doc(db, "archive", payload))
                    if (document.exists) {
                        commit("ADD_DOCUMENTO_TO_CARGADOS", {
                            id: document.id,
                            ...document.data()
                        })
                        return ({
                            id: document.id,
                            ...document.data()
                        })
                    } else {
                        return (false)
                    }
                }else{
                    return item
                }
            } catch (e) {
                console.log(e)
                return e
            }
        },
        async loadFONDOS() {
            let document = [{
                id: 'ESC',
                data: {
                    nextIndex: 1,
                    volumen: '2 metros lineales (4 cajas)',
                    publicData: [{
                        position: 1,
                        field: "Título",
                        value: 'Escuela Básica G42'
                    }, {
                        position: 2,
                        field: "Fechas",
                        value: '1974 - 1992'
                    }, {
                        position: 3,
                        field: "Productor",
                        value: 'Escuela Básica G42'
                    }, {
                        position: 4,
                        field: "Historia Institucional",
                        value: 'La Escuela fue fundada en….(breve reseña histórica)'
                    }, {
                        position: 5,
                        field: "Alcance y Contenido",
                        value: 'Este fondo es reflejo del funcionamiento de la Escuela. Está compuesto por certificados, diplomas, libros de clases y correspondencia.'
                    }, {
                        position: 6,
                        field: "Condiciones de Acceso",
                        value: 'Accesible al público en formato digital, a través de www.museopuertocristal.cl'
                    }, {
                        position: 7,
                        field: "Lengua/Escritura de los Documentos",
                        value: 'Español'
                    }, {
                        position: 8,
                        field: "Características Físicas",
                        value: 'Documentos en buen estado de conservación'
                    }, {
                        position: 9,
                        field: "Notas",
                        value: 'Incompleto'
                    }],
                    privateData: [{
                        position: 1,
                        field: "Existencia y Localización Documentos Originales",
                        value: 'Los documentos originales se encuentran en las dependencias del Museo Regional de Aysén'
                    }, {
                        position: 2,
                        field: "Existencia y Localización Documentos Copias",
                        value: 'Existencia y localización de copias'
                    }, {
                        position: 3,
                        field: "Notas del Archivista",
                        value: 'Incompleto'
                    }, {
                        position: 4,
                        field: "Fecha de Descripción",
                        value: 'Incompleto'
                    }, {
                        position: 5,
                        field: "Nombre de quien Registra",
                        value: 'Irene Ruiz Pareras'
                    }]
                }
            }, {
                id: 'CEN',
                data: {
                    nextIndex: 1,
                    volumen: '1,5 metros lineales (3 cajas, 2 archivadores)',
                    publicData: [{
                        position: 1,
                        field: "Título",
                        value: 'Centro de madres'
                    }, {
                        position: 2,
                        field: "Fechas",
                        value: '1972 - 1994'
                    }, {
                        position: 3,
                        field: "Productor",
                        value: 'Centro de madres'
                    }, {
                        position: 4,
                        field: "Historia Institucional",
                        value: 'El Centro de madres Los Cristales se fundó el 10 de octubre de 1971 por… (breve reseña histórica)'
                    }, {
                        position: 5,
                        field: "Alcance y Contenido",
                        value: 'Este fondo da cuenta de las actividades y labores llevadas a cabo desde el Centro de madres Los Cristales. El fondo está compuesto por correspondencia, listado de socias...'
                    }, {
                        position: 6,
                        field: "Condiciones de Acceso",
                        value: 'Accesible al público en formato digital, a través de www.museopuertocristal.cl'
                    }, {
                        position: 7,
                        field: "Lengua/Escritura de los Documentos",
                        value: 'Español'
                    }, {
                        position: 8,
                        field: "Características Físicas",
                        value: 'Documentos en buen estado de conservación'
                    }, {
                        position: 9,
                        field: "Notas",
                        value: 'Incompleto'
                    }],
                    privateData: [{
                        position: 1,
                        field: "Existencia y Localización Documentos Originales",
                        value: 'Los documentos originales se encuentran en las dependencias del Museo Regional de Aysén'
                    }, {
                        position: 2,
                        field: "Existencia y Localización Documentos Copias",
                        value: 'Se cuenta con copias digitales de algunos de estos documentos. Estarán disponibles para su consulta en www.museopuertocristal.cl'
                    }, {
                        position: 3,
                        field: "Notas del Archivista",
                        value: 'Incompleto'
                    }, {
                        position: 4,
                        field: "Fecha de Descripción",
                        value: 'Incompleto'
                    }, {
                        position: 5,
                        field: "Nombre de quien Registra",
                        value: 'Irene Ruiz Pareras'
                    }]
                }
            }, {
                id: 'POS',
                data: {
                    nextIndex: 1,
                    volumen: '1 metro lineal (2 cajas)',
                    publicData: [{
                        position: 1,
                        field: "Título",
                        value: 'Posta'
                    }, {
                        position: 2,
                        field: "Fechas",
                        value: '1985 - 1994'
                    }, {
                        position: 3,
                        field: "Productor",
                        value: 'Posta '
                    }, {
                        position: 4,
                        field: "Historia Institucional",
                        value: 'La posta fue inaugurada el año...'
                    }, {
                        position: 5,
                        field: "Alcance y Contenido",
                        value: 'Este fondo refleja el funcionamiento de la posta...'
                    }, {
                        position: 6,
                        field: "Condiciones de Acceso",
                        value: 'Este fondo posee documentos con datos sensibles por lo que su acceso es restringido. Solicitar acceso vía correo electrónico'
                    }, {
                        position: 7,
                        field: "Lengua/Escritura de los Documentos",
                        value: 'Español'
                    }, {
                        position: 8,
                        field: "Características Físicas",
                        value: 'Parte de sus documentos se encuentra en regular y mal estado de conservación'
                    }, {
                        position: 9,
                        field: "Notas",
                        value: 'Incompleto'
                    }],
                    privateData: [{
                        position: 1,
                        field: "Existencia y Localización Documentos Originales",
                        value: 'Los documentos originales se encuentran en las dependencias del Museo Regional de Aysén'
                    }, {
                        position: 2,
                        field: "Existencia y Localización Documentos Copias",
                        value: 'Se cuenta con copias digitales de algunos de estos documentos. Estarán disponibles para su consulta en www.museopuertocristal.cl'
                    }, {
                        position: 3,
                        field: "Notas del Archivista",
                        value: 'Incompleto'
                    }, {
                        position: 4,
                        field: "Fecha de Descripción",
                        value: 'Incompleto'
                    }, {
                        position: 5,
                        field: "Nombre de quien Registra",
                        value: 'Irene Ruiz Pareras'
                    }]
                }
            }, {
                id: 'RAD',
                data: {
                    nextIndex: 1,
                    volumen: '0,5 metro lineal (1 caja)',
                    publicData: [{
                        position: 1,
                        field: "Título",
                        value: 'Radio'
                    }, {
                        position: 2,
                        field: "Fechas",
                        value: 'Incompleto'
                    }, {
                        position: 3,
                        field: "Productor",
                        value: 'Radio'
                    }, {
                        position: 4,
                        field: "Historia Institucional",
                        value: 'Incompleto'
                    }, {
                        position: 5,
                        field: "Alcance y Contenido",
                        value: 'Incompleto'
                    }, {
                        position: 6,
                        field: "Condiciones de Acceso",
                        value: 'Accesible al público en formato digital, a través de www.museopuertocristal.cl'
                    }, {
                        position: 7,
                        field: "Lengua/Escritura de los Documentos",
                        value: 'Español'
                    }, {
                        position: 8,
                        field: "Características Físicas",
                        value: 'Documentos en buen estado de conservación'
                    }, {
                        position: 9,
                        field: "Notas",
                        value: 'Incompleto'
                    }],
                    privateData: [{
                        position: 1,
                        field: "Existencia y Localización Documentos Originales",
                        value: 'Los documentos originales se encuentran en las dependencias del Museo Regional de Aysén'
                    }, {
                        position: 2,
                        field: "Existencia y Localización Documentos Copias",
                        value: 'Se cuenta con copias digitales de algunos de estos documentos. Estarán disponibles para su consulta en www.museopuertocristal.cl'
                    }, {
                        position: 3,
                        field: "Notas del Archivista",
                        value: 'Incompleto'
                    }, {
                        position: 4,
                        field: "Fecha de Descripción",
                        value: 'Incompleto'
                    }, {
                        position: 5,
                        field: "Nombre de quien Registra",
                        value: 'Irene Ruiz Pareras'
                    }]
                }
            }, {
                id: 'LAB',
                data: {
                    nextIndex: 1,
                    volumen: '1,5 metro lineal (3 caja)',
                    publicData: [{
                        position: 1,
                        field: "Título",
                        value: 'Laboratorio'
                    }, {
                        position: 2,
                        field: "Fechas",
                        value: '1967-1992'
                    }, {
                        position: 3,
                        field: "Productor",
                        value: 'Laboratorio'
                    }, {
                        position: 4,
                        field: "Historia Institucional",
                        value: 'Incompleto'
                    }, {
                        position: 5,
                        field: "Alcance y Contenido",
                        value: 'Incompleto'
                    }, {
                        position: 6,
                        field: "Condiciones de Acceso",
                        value: 'Accesible al público en formato digital, a través de www.museopuertocristal.cl'
                    }, {
                        position: 7,
                        field: "Lengua/Escritura de los Documentos",
                        value: 'Español'
                    }, {
                        position: 8,
                        field: "Características Físicas",
                        value: 'Documentos en buen estado de conservación'
                    }, {
                        position: 9,
                        field: "Notas",
                        value: 'Solamente digitalizada 1 caja'
                    }],
                    privateData: [{
                        position: 1,
                        field: "Existencia y Localización Documentos Originales",
                        value: 'Los documentos originales se encuentran en las dependencias del Museo Regional de Aysén'
                    }, {
                        position: 2,
                        field: "Existencia y Localización Documentos Copias",
                        value: 'Se cuenta con copias digitales de algunos de estos documentos. Estarán disponibles para su consulta en www.museopuertocristal.cl'
                    }, {
                        position: 3,
                        field: "Notas del Archivista",
                        value: 'Incompleto'
                    }, {
                        position: 4,
                        field: "Fecha de Descripción",
                        value: 'Incompleto'
                    }, {
                        position: 5,
                        field: "Nombre de quien Registra",
                        value: 'Irene Ruiz Pareras'
                    }]
                }
            }, {
                id: 'MAN',
                data: {
                    nextIndex: 1,
                    volumen: '0,5 metro lineal (1 caja)',
                    publicData: [{
                        position: 1,
                        field: "Título",
                        value: 'Juan Mansilla'
                    }, {
                        position: 2,
                        field: "Fechas",
                        value: '1960 - 1993'
                    }, {
                        position: 3,
                        field: "Productor",
                        value: 'Juan Mansilla'
                    }, {
                        position: 4,
                        field: "Historia Institucional",
                        value: 'Documentos de la vida laboral y familiar de don Juan Mansilla'
                    }, {
                        position: 5,
                        field: "Alcance y Contenido",
                        value: 'Documentos de la vida laboral y familiar de don Juan Mansilla'
                    }, {
                        position: 6,
                        field: "Condiciones de Acceso",
                        value: 'Este fondo posee documentos con datos sensibles por lo que su acceso es restringido. Solicitar acceso vía correo electrónico'
                    }, {
                        position: 7,
                        field: "Lengua/Escritura de los Documentos",
                        value: 'Español'
                    }, {
                        position: 8,
                        field: "Características Físicas",
                        value: 'Documentos en mal estado de conservación'
                    }, {
                        position: 9,
                        field: "Notas",
                        value: 'Incompleto'
                    }],
                    privateData: [{
                        position: 1,
                        field: "Existencia y Localización Documentos Originales",
                        value: 'Los documentos originales se encuentran en las dependencias del Museo Regional de Aysén'
                    }, {
                        position: 2,
                        field: "Existencia y Localización Documentos Copias",
                        value: 'Se cuenta con copias digitales de algunos de estos documentos. Estarán disponibles para su consulta en www.museopuertocristal.cl'
                    }, {
                        position: 3,
                        field: "Notas del Archivista",
                        value: 'Incompleto'
                    }, {
                        position: 4,
                        field: "Fecha de Descripción",
                        value: 'Agosto 2021'
                    }, {
                        position: 5,
                        field: "Nombre de quien Registra",
                        value: 'Irene Ruiz Pareras'
                    }]
                }
            }]
            try {
                document.forEach(async item => {
                    await setDoc(doc(db, "archiveCollections/PCD/fondos", item.id), {
                        publicData: item.data.publicData,
                        privateData: item.data.privateData,
                        nextIndex: item.data.nextIndex,
                        Volumen: item.data.volumen
                    })
                    console.log(item.id)
                })
                return true
            } catch (e) {
                console.log(e)
                return e
            }
        },
        async loadSERIES() {
            let series = [{
                fondo: 'LAB',
                id: '001',
                data: {
                    nextIndex: 1,
                    volumen: 0,
                    publicData: [{
                        field: "Título",
                        position: 1,
                        value: 'Repuestos Don Edmundo e Importaciones'
                    }, {
                        field: "Fechas",
                        position: 2,
                        value: '1985-1986'
                    }, {
                        field: "Productor",
                        position: 3,
                        value: 'Laboratorio'
                    }, {
                        field: "Alcance y Contenido",
                        position: 4,
                        value: 'Documentos para la compra e importación de los repuestos del barco Don Edmundo'
                    }, {
                        field: "Condiciones de Acceso",
                        position: 5,
                        value: 'Incompleto'
                    }, {
                        field: "Lengua/Escritura de los Documentos",
                        position: 6,
                        value: 'Español'
                    }, {
                        field: "Características Físicas",
                        position: 7,
                        value: 'Documentos en buen estado de conservación'
                    }, {
                        field: "Notas",
                        position: 8,
                        value: 'Incompleto'
                    }],
                    privateData: [{
                        field: "Existencia y Localización Documentos Originales",
                        position: 1,
                        value: 'Los documentos originales se encuentran en el Museo Regional de Aysén'
                    }, {
                        field: "Existencia y Localización Documentos Copias",
                        position: 2,
                        value: 'Copias digitalizadas de la totalidad de documentos que componen la serie'
                    }, {
                        field: "Notas del Archivista",
                        position: 3,
                        value: 'Descripción realizada por: Irene Ruiz Pareras'
                    }, {
                        field: "Fecha de Descripción",
                        position: 4,
                        value: 'Julio 2021'
                    }, {
                        field: "Nombre de quién Registra",
                        position: 5,
                        value: 'Incompleto'
                    }, ]
                }
            }, {
                fondo: 'LAB',
                id: '002',
                data: {
                    nextIndex: 1,
                    volumen: 0,
                    publicData: [{
                        field: "Título",
                        position: 1,
                        value: 'Control de calidad/ Administración'
                    }, {
                        field: "Fechas",
                        position: 2,
                        value: '1983-1992'
                    }, {
                        field: "Productor",
                        position: 3,
                        value: 'Laboratorio'
                    }, {
                        field: "Alcance y Contenido",
                        position: 4,
                        value: 'Documentos que dan cuenta de las leyes de las muestras de laboratorio y control de calidad y administración del departamento'
                    }, {
                        field: "Condiciones de Acceso",
                        position: 5,
                        value: 'Incompleto'
                    }, {
                        field: "Lengua/Escritura de los Documentos",
                        position: 6,
                        value: 'Español'
                    }, {
                        field: "Características Físicas",
                        position: 7,
                        value: 'Documentos en buen estado de conservación'
                    }, {
                        field: "Notas",
                        position: 8,
                        value: 'Incompleto'
                    }],
                    privateData: [{
                        field: "Existencia y Localización Documentos Originales",
                        position: 1,
                        value: 'Los documentos originales se encuentran en el Museo Regional de Aysén'
                    }, {
                        field: "Existencia y Localización Documentos Copias",
                        position: 2,
                        value: 'Copias digitalizadas de la totalidad de documentos que componen la serie'
                    }, {
                        field: "Notas del Archivista",
                        position: 3,
                        value: 'Descripción realizada por: Irene Ruiz Pareras'
                    }, {
                        field: "Fecha de Descripción",
                        position: 4,
                        value: 'Julio 2021'
                    }, {
                        field: "Nombre de quién Registra",
                        position: 5,
                        value: 'Incompleto'
                    }, ]
                }
            }, {
                fondo: 'LAB',
                id: '003',
                data: {
                    nextIndex: 1,
                    volumen: 0,
                    publicData: [{
                        field: "Título",
                        position: 1,
                        value: 'Correspondencia'
                    }, {
                        field: "Fechas",
                        position: 2,
                        value: '1967'
                    }, {
                        field: "Productor",
                        position: 3,
                        value: 'Laboratorio'
                    }, {
                        field: "Alcance y Contenido",
                        position: 4,
                        value: 'Correspondecia externa de diferente índole'
                    }, {
                        field: "Condiciones de Acceso",
                        position: 5,
                        value: 'Incompleto'
                    }, {
                        field: "Lengua/Escritura de los Documentos",
                        position: 6,
                        value: 'Español'
                    }, {
                        field: "Características Físicas",
                        position: 7,
                        value: 'Documentos en buen estado de conservación'
                    }, {
                        field: "Notas",
                        position: 8,
                        value: 'Incompleto'
                    }],
                    privateData: [{
                        field: "Existencia y Localización Documentos Originales",
                        position: 1,
                        value: 'Los documentos originales se encuentran en el Museo Regional de Aysén'
                    }, {
                        field: "Existencia y Localización Documentos Copias",
                        position: 2,
                        value: 'Copias digitalizadas de la totalidad de documentos que componen la serie'
                    }, {
                        field: "Notas del Archivista",
                        position: 3,
                        value: 'Descripción realizada por: Irene Ruiz Pareras'
                    }, {
                        field: "Fecha de Descripción",
                        position: 4,
                        value: 'Julio 2021'
                    }, {
                        field: "Nombre de quién Registra",
                        position: 5,
                        value: 'Incompleto'
                    }, ]
                }
            }, {
                fondo: 'LAB',
                id: '004',
                data: {
                    nextIndex: 1,
                    volumen: 0,
                    publicData: [{
                        field: "Título",
                        position: 1,
                        value: 'Circulares'
                    }, {
                        field: "Fechas",
                        position: 2,
                        value: '1988-1992'
                    }, {
                        field: "Productor",
                        position: 3,
                        value: 'Laboratorio'
                    }, {
                        field: "Alcance y Contenido",
                        position: 4,
                        value: 'Avisos y notas de uso interno para tranferir información'
                    }, {
                        field: "Condiciones de Acceso",
                        position: 5,
                        value: 'Incompleto'
                    }, {
                        field: "Lengua/Escritura de los Documentos",
                        position: 6,
                        value: 'Español'
                    }, {
                        field: "Características Físicas",
                        position: 7,
                        value: 'Documentos en buen estado de conservación'
                    }, {
                        field: "Notas",
                        position: 8,
                        value: 'Incompleto'
                    }],
                    privateData: [{
                        field: "Existencia y Localización Documentos Originales",
                        position: 1,
                        value: 'Los documentos originales se encuentran en el Museo Regional de Aysén'
                    }, {
                        field: "Existencia y Localización Documentos Copias",
                        position: 2,
                        value: 'Copias digitalizadas de la totalidad de documentos que componen la serie'
                    }, {
                        field: "Notas del Archivista",
                        position: 3,
                        value: 'Descripción realizada por: Irene Ruiz Pareras'
                    }, {
                        field: "Fecha de Descripción",
                        position: 4,
                        value: 'Julio 2021'
                    }, {
                        field: "Nombre de quién Registra",
                        position: 5,
                        value: 'Incompleto'
                    }, ]
                }
            }]
            try {
                series.forEach(async item => {
                    await setDoc(doc(db, "archiveCollections/PCD/fondos/" + item.fondo + "/series", item.id), {
                        publicData: item.data.publicData,
                        privateData: item.data.privateData,
                        nextIndex: item.data.nextIndex,
                        Volumen: item.data.volumen
                    })

                })
                return true
            } catch (e) {
                console.log(e)
                return e
            }
        },
        async cargarDocumentos() {
            let arr = []

            //console.log(arr.length)
            //console.log(arr2.length)
            //console.log(arr1.length)
            //console.log(arr3.length)
            //console.log(arr4.length)
            try {
                let batch = writeBatch(db)
                arr.forEach(document => {
                    batch.set(doc(db, "archive", document.id), {
                        ...document.data,
                        published: false
                    })
                    console.log(document.id)
                })
                await batch.commit()
                console.log("ok")
            } catch (err) {
                console.log(err)
            }
        },
        async setPublished() {
            var listRef = ref(storage, 'archive/documento');
            listAll(listRef).then(async list => {
                list.items.forEach(async document => {
                    let id = document.name.slice(0, 12)
                    //let fon=doc.name.slice(0,3)
                    //let ser=doc.nane.slice(4,3)
                    getDownloadURL(document).then(async url => {
                        await updateDoc(doc(db, "archive", id), {
                            link: {
                                link: url,
                                name: document.name,
                                restricted: false
                            }
                        })

                    })
                })
            })
        },
        async addDocToSerie() {
            try {
                let list = await getDocs(collection(db, "archive"))
                list.forEach(async document => {
                    console.log(document.id, document.data())
                    if (document.id !== "counter" && document.id !== "index" && document.id !== "published") {
                        if (document.data().imagen !== "") {
                            await setDoc(doc(db, "archiveCollections/PCD/fondos/" + document.data().Fondo + "/series/" + document.data().Serie + "/documentos", document.id), {
                                title: document.data().publicData[0].value,
                                image: document.data().imagen.link
                            })
                        }
                    }

                })
            } catch (e) {
                console.log(e)
            }
        },
        async publishAll() {
            try {
                let list = await getDocs(collection(db, "archive"))
                list.forEach(async document => {
                    if (document.data().imagen !== "" && document.data().link !== "") {
                        await updateDoc(doc(db, "archive", document.id), {
                            published: true
                        })
                        console.log(document.id)
                    }
                })
            } catch (e) {
                console.log(e)
            }
        }
    },
    getters: {
        fondos(state, rootGetters) {
            if (rootGetters.user !== null) {
                return state.fondos
            } else {
                let arr = state.fondos.find(item => item.published === true)
                return arr
            }
        },
        series(state, rootGetters) {
            if (rootGetters.user !== null) {
                return state.series
            } else {
                let arr = state.series.find(item => item.published === true)
                return arr
            }
        },
        documentos(state) {
            return state.documentosCargados
        },
        currentDocument(state) {
            if(state.currentDoc&&state.currentDoc.length>12){
                let idSplit=state.currentDoc.split(".")
                let doc=state.documentosCargados.find(item=>item.id===(idSplit[0]+"."+idSplit[1]+"."+idSplit[2]))
                console.log(idSplit[0]+"."+idSplit[1]+"."+idSplit[2])
                doc=doc.conjunto.find(item=>item.id===state.currentDoc)
            return doc
            }else{
            let doc=state.documentosCargados.find(item=>item.id===state.currentDoc)
            return doc
            }
        },
        currentFondo(state) {
            return state.currentFondo
        },
        currentSerie(state) {
            return state.currentSerie
        },
        levelList(state) {
            return state.levelList
        },
        levelFondo(state) {
            return state.levelFondo
        },
        levelSerie(state) {
            return state.levelSerie
        }
    },
    mutations: {
        SET_LEVEL_LIST(state, payload) {
            state.levelList = payload
        },
        SET_LEVEL_FONDO(state, payload) {
            state.levelFondo = payload
        },
        SET_LEVEL_SERIE(state, payload) {
            state.levelSerie = payload
        },
        SET_FONDO_ACTUAL(state, payload) {
            state.currentFondo = payload
        },
        SET_SERIE_ACTUAL(state, payload) {
            state.currentSerie = payload
        },
        SET_ARCHIVE_INDEX(state, payload) {
            state.archiveIndex = payload
        },
        SET_CURRENT_DOC(state,payload){
            state.currentDoc=payload
        },

        ADD_FONDO(state, payload) {
            let fondo = state.fondos.find(item => item.id === payload.id)
            if (fondo) {
                fondo = payload
            } else {
                state.fondos.push(payload)
            }
            payload.series.forEach(item=>{
                let serie=state.series.find(elem=>elem.fondo===item.fondo&&elem.id===item.id)
                if(serie){
                    serie=item
                }else{
                    state.series.push({...item,documentos:[]})
                }
            })
        },
        ADD_SERIE_TO_FONDO(state, payload) {
            let fondo = state.fondos.find(item => item.id === payload.fondo)
            if (fondo) {
                let serie = fondo.series.find(item => item.id === payload.serie.id)
                if (serie) {
                    serie = payload.serie
                } else {
                    fondo.series.push(payload.serie)
                }
            }
        },
        ADD_DOCUMENTO_TO_SERIE(state, payload) {
            let fondo = state.fondos.find(item => item.id === payload.fondo)
            if (fondo) {
                let serie = fondo.series.find(item => item.id === payload.serie)
                if (serie) {
                    let document = serie.documentos.find(item => item.id === payload.doc.id)
                    if (document) {
                        document = payload.doc
                    }
                }
            }
        },
        ADD_DOCUMENTO_TO_CARGADOS(state, payload) {
            let document = state.documentosCargados.find(item => item.id === payload.id)
            if (document) {
                document = payload
            } else {
                state.documentosCargados.push(payload)
            }
        },
        ADD_DOCUMENTO(state, payload) {
            let fondo = state.fondos.find(item => item.id === payload.fondo)
            if (fondo) {
                let serie = fondo.series.find(item => item.id === payload.serie)
                if (serie) {
                    let document = serie.documentos.find(item => item.id === payload.doc.id)
                    if (document) {
                        document = payload.doc
                    } else {
                        serie.documentos.push(payload.doc)
                    }
                }
            }
        },
        UPDATE_PUBLIC_DATA_FROM_FONDO(state, payload) {
            let fondo = state.fondos.find(item => item.id === payload.fondo)
            if (fondo) {
                fondo.publicData = payload.publicData
            }
        },
        UPDATE_PRIVATE_DATA_FROM_FONDO(state, payload) {
            let fondo = state.fondos.find(item => item.id === payload.fondo)
            if (fondo) {
                fondo.privateData = payload.privateData
            }
        },
        UPDATE_PUBLIC_DATA_FROM_SERIE(state, payload) {
            let fondo = state.fondos.find(item => item.id === payload.fondo)
            if (fondo) {
                let serie = fondo.series.find(item => item.id === payload.serie.id)
                if (serie) {
                    serie.publicData = payload.serie.publicData
                }
            }
        },
        UPDATE_PRIVATE_DATA_FROM_SERIE(state, payload) {
            let fondo = state.fondos.find(item => item.id === payload.fondo)
            if (fondo) {
                let serie = fondo.series.find(item => item.id === payload.serie.id)
                if (serie) {
                    serie.privateData = payload.serie.privateData
                }
            }
        },

        UPDATE_IMAGEN_FROM_DOCUMENTO(state, payload) {
            let document = state.documentosCargados.find(item => item.id === payload.id)
            if (document) {
                document.imagen = {
                    name: payload.name,
                    link: payload.link
                }
            }
        },
        DELETE_IMAGEN_FROM_DOCUMENTO(state, payload) {
            let document = state.documentosCargados.find(item => item.id === payload)
            if (document) {
                document.imagen = ""
            }
        },
        UPDATE_PDF_FROM_DOCUMENTO(state, payload) {
            let document = state.documentosCargados.find(item => item.id === payload.id)
            if (document) {
                document.link = {
                    name: payload.name,
                    link: payload.link,
                    restricted: payload.restricted
                }
            }
        },
        DELETE_PDF_FROM_DOCUMENTO(state, payload) {
            let document = state.documentosCargados.find(item => item.id === payload)
            if (document) {
                document.link = ""
            }
        },
        UPDATE_PUBLIC_DATA_FROM_DOCUMENTO(state, payload) {
            let document = state.documentosCargados.find(item => item.id === payload.id)
            if (document) {
                document.publicData = payload.doc.publicData
            }
        },
        UPDATE_PRIVATE_DATA_FROM_DOCUMENTO(state, payload) {
            let document = state.documentosCargados.find(item => item.id === payload.id)
            if (document) {
                document.privateData = payload.doc.privateData
            }

        },
        ADD_DOCUMENTOS_TO_SERIE(state, payload) {
            let serie = state.series.find(item => item.fondo === payload.fondo &&item.id === payload.serie)
                if (serie) {
                    serie.documentos = payload.documentos
                }
            
        }
    }
}