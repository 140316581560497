<template>
  <div class="home">
      <h1 class="long">{{$route.params.id}}</h1>
    <div class="page">
      <div class="object-card" v-for="(item,index) in objectsInBox" :key="item.id" v-show="index<20">
        <div>
          <img :src="item.image" alt />
        </div>
        <h5>{{item.name}}</h5>
        <h6>{{item.id}}</h6>
      </div>
    </div>
    <div class="page" v-if="objectsInBox.length>19&&objectsInBox.length<40">
      <div
        class="object-card"
        v-for="(item,index) in objectsInBox"
        :key="item.id"
        v-show="index>19&&index<40"
      >
        <div>
          <img :src="item.image" alt />
        </div>
        <h5>{{item.name}}</h5>
        <h6>{{item.id}}</h6>
      </div>
    </div>
    
    <div class="page" v-if="objectsInBox.length>39&&objectsInBox.length<60">
      <div
        class="object-card"
        v-for="(item,index) in objectsInBox"
        :key="item.id"
        v-show="index>39&&index<60"
      >
        <div>
          <img :src="item.image" alt />
        </div>
        <h5>{{item.name}}</h5>
        <h6>{{item.id}}</h6>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "print-Box",
  data: function() {
    return {
      sectionBox: {
        box: "none",
        removeItem: "",
        location: false,
        currentLocation: "",
        newLocation: ""
      },
      sectionAsign: {
        box: "none",
        selectedItems: [] //array of ids and names
      },
      tab: 1,
      boxTab: 1
    };
  },
  computed: {
    ...mapGetters([
      "boxes",
      "objectsInBox",
      "objectsOutOfBox",
      "getMessageAction"
    ]),
    location() {
      if (
        this.boxes.find(item => item.id === this.$route.params.id) !== undefined
      ) {
        const loc = this.boxes.find(item => item.id === this.$route.params.id)
          .location;
        if (loc !== undefined && loc !== "") {
          return loc;
        } else {
          return "Desconocido";
        }
      } else {
        return "Desconocido";
      }
    }
  },
  watch: {
    objectsInBox() {}
  },
  methods: {
    ...mapMutations([
      "SET_LOADING",
      "SET_MESSAGE_PARAMETERS",
      "SET_BOX_LOCATION"
    ])
  }
};
</script>
<style lang="scss" scoped>
@import "../assets/sass/variables";
body {
  background-color: white !important;
}
.home {
  background-color: white !important;
  display: grid;
    h1 {
      text-align: center;
      font-size: 60px;
      margin-block-start: 0px;
      margin-block-end: 10px;
      color: $text;
    }
  .page {
    display: grid;
    height: 900px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 5px;
    grid-template-rows: auto auto auto auto;
    p {
      font-size: 16px;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $text;
    }
    .long {
      grid-column-start: 1;
      grid-column-end: 6;
    }
    .object-card {
      border: solid 1px $text;
      div {
        img {
          width: 100%;
        }
      }
      h4,
      h5,h6 {
        text-align: center !important;
      }
      h5{
        font-size: 14px;
      }
      h6{
        font-size: 12px;
      }
    }
  }
}
</style>
