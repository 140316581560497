<template>
  <div class="filter-container">
    <button class="header main-btn" @click="filterDisplayed = !filterDisplayed">
      <h3>filtros</h3>
      <i class="material-icons" v-if="filterDisplayed">expand_less</i>
      <i class="material-icons" v-else>expand_more</i>
    </button>
    <div
      class="filter-block"
      v-for="item in data"
      :key="item.name"
      v-show="filterDisplayed"
    >
      <button
        class="main-btn"
        @click="
          item.name === sectionDisplayed
            ? (sectionDisplayed = '')
            : (sectionDisplayed = item.name)
        "
      >
        <h5 v-html="item.tag"></h5>
        <i class="material-icons" v-if="item.name !== sectionDisplayed">add</i>
        <i class="material-icons" v-else>remove</i>
      </button>
      <SimpleBar data-SimpleBar-auto-hide="false" class="scrollbar-container">
        <ul v-show="item.name === sectionDisplayed">
          <li v-for="el in item.list" :key="el.id">
            <input
              type="checkbox"
              class="checkbox"
              :name="item.name"
              :id="el.id"
              v-model="el.checked"
            />
            <label>{{ el.name }}</label>
          </li>
        </ul>
      </SimpleBar>
    </div>
    <p class="filter-btns btn-center" v-show="filterDisplayed">
      <button class="ok-btn" @click="filterObjects">Filtrar</button>
      <button class="cancel-btn" @click="clearFilters">Limpiar</button>
    </p>
  </div>
</template>
<script>
import {SimpleBar} from "simplebar-vue3";
import app from "@/firebase";
import { getFirestore, collection, where, query } from "firebase/firestore";
const db = getFirestore(app);
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  name: "Filter-Menu",
  props: ["payload"],
  components: { SimpleBar },
  data() {
    return {
      filterDisplayed: true,
      sectionDisplayed: "",
      data: [],
      filteredMaterials: [],
      filteredTechniques: [],
    };
  },
  mounted() {
    this.payload.forEach((item) => {
      switch (item) {
        case "material":
          this.updateMaterials(this.materials);
          break;
        case "technique":
          this.updateTechniques(this.techniques);
          break;
        case "clasification":
          this.updateClasifications(this.categories);
          break;
      }
    });
  },
  computed: {
    ...mapGetters("materials", {
      materials: "items",
    }),
    ...mapGetters("techniques", {
      techniques: "items",
    }),
    ...mapGetters([
      "categories",
      "filteredList",
      "indexedItems",
      "getNextBatchByIndex",
      "getNextBatchByName",
      "hasFilterBeenLoaded",
      "allItems",
      "user",
    ]),
  },
  watch: {
    categories(payload) {
      this.updateClasifications(payload);
    },
    materials(payload) {
      this.updateMaterials(payload);
    },
    techniques(payload) {
      this.updateTechniques(payload);
    },
  },
  methods: {
    ...mapActions("materials", {
      getMaterialItems: "getItemObjects",
    }),
    ...mapActions("techniques", {
      getTechniqueItems: "getItemObjects",
    }),
    ...mapActions(["getItemsByQuery"]),
    ...mapMutations([
      "SET_LOADING",
      "SET_LOADING_OPACITY",
      "SET_FILTERED_LIST",
      "SET_FILTER_DISPLAYED",
      "SET_CURRENT_LIST",
      "ADD_LOADED_FILTER",
    ]),
    gridCount() {
      if (this.$refs.pages) {
        this.columns = Math.trunc((this.$refs.pages.clientWidth * 0.9) / 45);
      }
    },
    updateClasifications(payload) {
      let el = this.data.find((item) => item.name === "clasification");
      let arr = [];
      payload.forEach((elem) => {
        arr.push({
          name: elem.name,
          id: elem.id,
          checked: false,
          items: elem.elements,
        });
      });
      console.log(arr)
      if (el === undefined) {
        this.data.push({
          name: "clasification",
          tag: "Clasificaci&oacute;n",
          list: arr,
        });
      } else {
        el.list = arr;
      }
    },
    updateMaterials(payload) {
      let el = this.data.find((item) => item.name === "material");
      let arr = [];
      payload.forEach((elem) => {
        arr.push({
          name: elem.name,
          id: elem.id,
          checked: false,
          items: elem.elements,
        });
      });
      if (el === undefined) {
        this.data.push({
          name: "material",
          tag: "Material",
          list: arr,
        });
      } else {
        el.list = arr;
      }
    },
    updateTechniques(payload) {
      let el = this.data.find((item) => item.name === "technique");
      let arr = [];
      payload.forEach((elem) => {
        arr.push({
          name: elem.name,
          id: elem.id,
          checked: false,
          items: elem.elements,
        });
      });
      if (el === undefined) {
        this.data.push({
          name: "technique",
          tag: "T&eacute;cnica",
          list: arr,
        });
      } else {
        el.list = arr;
      }
    },
    clearFilters() {
      this.data.forEach((item) => {
        item.list.forEach((elem) => {
          elem.checked = false;
        });
      });
    },
    filterObjects() {
      let mat = [];
      let tec = [];
      let cat = [];
      this.SET_LOADING(true);
      this.SET_LOADING_OPACITY(true);
      new Promise((resolve, reject) => {
        try {
          this.data.forEach((item) => {
            switch (item.name) {
              case "material":
                item.list.forEach((elem) => {
                  if (elem.checked) {
                    if (!this.hasFilterBeenLoaded(elem.id)) {
                      mat.push({ id: elem.id, load: true });
                    } else {
                      mat.push({ id: elem.id, load: false });
                    }
                  }
                });
                break;
              case "technique":
                item.list.forEach((elem) => {
                  if (elem.checked) {
                    if (!this.hasFilterBeenLoaded(elem.id)) {
                      tec.push({ id: elem.id, load: true });
                    } else {
                      tec.push({ id: elem.id, load: false });
                    }
                  }
                });
                break;
              case "clasification":
                item.list.forEach((elem) => {
                  if (elem.checked) {
                    if (!this.hasFilterBeenLoaded(elem.id)) {
                      cat.push({ id: elem.id, load: true });
                    } else {
                      cat.push({ id: elem.id, load: false });
                    }
                  }
                });
                break;
            }
          });
          if (mat.length > 0) {
            let loadNewMaterials = [];
            mat.forEach((item) => {
              if (item.load) {
                loadNewMaterials.push(item.id);
              }
            });
            if (loadNewMaterials.length > 0) {
              if (this.user) {
                this.getItemsByQuery(
                  query(
                    collection(db, "ListaObjetos"),
                    where("materials", "array-contains-any", loadNewMaterials)
                  )
                ).then((result) => {
                  loadNewMaterials.forEach((item) => {
                    this.ADD_LOADED_FILTER(item);
                  });
                  resolve(result);
                });
              } else {
                this.getItemsByQuery(
                  query(
                    collection(db, "ListaObjetos"),
                    where("published", "==", true),
                    where("materials", "array-contains-any", loadNewMaterials)
                  )).then((result) => {
                  loadNewMaterials.forEach((item) => {
                    this.ADD_LOADED_FILTER(item);
                  });
                  resolve(result);
                });
              }
            } else {
              resolve([]);
            }
          } else if (tec.length > 0) {
            let loadNewTechniques = [];
            tec.forEach((item) => {
              if (item.load) {
                loadNewTechniques.push(item.id);
              }
            });
            if (loadNewTechniques.length > 0) {
              if (this.user) {
                this.getItemsByQuery(
                  query(
                    collection(db, "ListaObjetos"),
                    where(
                      "techniques",
                      "array-contains-any",
                      loadNewTechniques
                    )
                  )).then((result) => {
                  loadNewTechniques.forEach((item) => {
                    this.ADD_LOADED_FILTER(item);
                  });
                  resolve(result);
                });
              } else {
                this.getItemsByQuery(
                  query(
                    collection(db, "ListaObjetos"),
                    where("published", "==", true),
                    where(
                      "techniques",
                      "array-contains-any",
                      loadNewTechniques
                    )
                  )).then((result) => {
                  loadNewTechniques.forEach((item) => {
                    this.ADD_LOADED_FILTER(item);
                  });
                  resolve(result);
                });
              }
            } else {
              resolve([]);
            }
          } else {
            console.log("algo");
          }
        } catch (e) {
          reject(e);
        }
      }).then((result) => {
        result.length;
        let arr = [];
        this.allItems.forEach((doc) => {
          let m = { item: doc, filter: "material", filters: mat };
          let t = { item: doc, filter: "technique", filters: tec };
          let c = { item: doc, filter: "clasification", filters: cat };
          if (
            this.checkFilter(m) &&
            this.checkFilter(t) &&
            this.checkFilter(c)
          ) {
            arr.push(doc);
          }
        });
        this.SET_FILTERED_LIST(arr);
        this.SET_FILTER_DISPLAYED(true);
        this.SET_CURRENT_LIST();
        this.SET_LOADING(false);
      });
    },
    checkFilter(payload) {
      let state = false;
      switch (payload.filter) {
        case "material":
          if (payload.filters.length > 0) {
            payload.filters.forEach((el) => {
              if (payload.item.materials.find((doc) => doc === el.id)) {
                state = true;
              }
            });
          } else {
            state = true;
          }
          break;
        case "technique":
          if (payload.filters.length > 0) {
            payload.filters.forEach((el) => {
              if (payload.item.techniques.find((doc) => doc === el.id)) {
                state = true;
              }
            });
          } else {
            state = true;
          }
          break;
        case "clasification":
          if (payload.filters.length > 0) {
            payload.filters.forEach((el) => {
              if (payload.item.clasification.find((doc) => doc === el.id)) {
                state = true;
              }
            });
          } else {
            state = true;
          }
          break;
      }
      return state;
    },
  },
};
</script>
<style lang="scss" scope>
@import "./../assets/sass/variables";
@import "./../assets/sass/buttons";

.filter-container {
  width: 100%;
  position: relative;
  button.main-btn {
    padding: 8px 8px;
    border: none;
    width: 100%;
    text-align: left;
    height: fit-content;
    h3,
    h4,
    h5,
    h6 {
      display: inline-block;
      width: 80%;
      margin: 0px;
      vertical-align: middle;
      text-transform: uppercase;
    }
    i.material-icons {
      text-align: right;
      vertical-align: middle;
      left: auto;
      right: 0px;
      width: 20%;
      font-size: 30px;
    }
  }
  button.header {
    background-color: $theme-d1;
    color: white;
    h3,
    h4,
    h5,
    h6 {
      color: white;
    }
  }
  .filter-block {
    width: 100%;
    margin-top: 5px;
    border: solid 1px $text-light;

    background-color: whitesmoke;
    button {
      background-color: white;
      color: $text;
      border: solid 1px $text-light;
      h3,
      h4,
      h5,
      h6 {
        color: $text;
      }
    }
    ul {
      margin: 5px;
      padding: 0px;
      li {
        list-style: none;
        .checkbox {
          width: 15px;
          height: 15px;
          display: inline-block;
          vertical-align: middle;
        }
        label {
          display: inline;
        }
      }
    }
  }
  .scrollbar-container {
    max-height: 50vh;
  }
  .filter-btns {
    margin-top: 5px;
    button {
      width: fit-content;
      &:first-child {
        margin-right: 8px;
      }
    }
  }
}
</style>
