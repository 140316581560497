<template>
  <div class="settings-container">
    <div class="search">
      <h2 v-html="title"></h2>
    </div>

    <div class="index-container">
      <p v-for="(item, index) in indexList" :key="index">
        <button
          class="index"
          :class="{ selected: item === currentIndex }"
          @click="currentIndex = item"
        >
          {{ item }}
        </button>
        <span v-if="index < indexList.length - 1">-</span>
      </p>
    </div>
    <div class="title-add">
      <h1 @click="update">{{ currentIndex }}</h1>
      <div class="right">
        <button
          class="icon-and-label-btn grey"
          style="float: right; margin-right: 1em"
          @click="addModule = true"
        >
          <i class="icon material-icons">add</i>
          <span class="text" style="white-space: nowrap">Agregar Sitio</span>
        </button>
      </div>
    </div>
    <div class="page-container">
      <div class="card-container">
        <div class="card" v-for="item in list" :key="item.id">
          <div
            class="card-img"
            v-if="
              item.image !== null &&
              item.image !== '' &&
              item.image !== undefined
            "
            :style="'background-image:url(' + item.image.url + ')'"
          ></div>
          <div class="card-img" v-else>
            <i class="material-icons">photo</i>
          </div>
          <div class="card-content">
            <h5>{{ item.name }}</h5>
            <p>{{ item.id }}</p>
            <p class="btns">
              <button
                class="btn-container"
                @click="$router.push('item/' + item.id)"
              >
                <div class="btn">
                  <p>Ver Ficha</p>
                  <i class="material-icons">remove_red_eye</i>
                </div>
              </button>
              <button
                class="btn-container"
                @click="
                  editItem = true;
                  itemId = item.id;
                "
              >
                <div class="btn">
                  <p>Editar</p>
                  <i class="material-icons">edit</i>
                </div>
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" v-show="addModule">
      <div class="top" @click="addModule = false"></div>
      <div class="left" @click="addModule = false"></div>
      <div class="right" @click="addModule = false"></div>
      <div class="bottom" @click="addModule = false"></div>
      <div class="center">
        <div class="box align">
          <p class="icon" style="padding: 0.5em 0.5em 0 0">
            <i
              class="material-icons"
              @click="
                addNew.id = '';
                addNew.name = '';
                addModule = false;
              "
              >close</i
            >
          </p>
          <SimpleBar class="padding">
            <h4 v-html="'Agregar Sitio'"></h4>
            <label v-html="'ID:'"></label>
            <input type="text" v-model="addNew.id" />
            <label v-html="'Nombre:'"></label>
            <input type="text" v-model="addNew.name" />
            <p class="btn-center">
              <button class="ok-btn" @click="addNewItem">Guardar</button>
              <button class="cancel-btn" @click="addModule = false">
                Cancelar
              </button>
            </p>
          </SimpleBar>
        </div>
      </div>
    </div>
    <div class="modal" v-if="editItem">
      <div
        id="top"
        @click="
          itemId = '';
          editItem = false;
        "
      ></div>
      <div
        id="left"
        @click="
          itemId = '';
          editItem = false;
        "
      ></div>
      <div
        id="right"
        @click="
          itemId = '';
          editItem = false;
        "
      ></div>
      <div
        id="bottom"
        @click="
          itemId = '';
          editItem = false;
        "
      ></div>
      <div class="center">
        <SimpleBar class="box align">
          <p class="icon" style="padding: 0.5em 0.5em 0 0">
            <i
              class="material-icons"
              @click="
                itemId = '';
                editItem = false;
              "
              >close</i
            >
          </p>
          <div class="padding">
            <div class="nav" style="padding-bottom: 0">
              <div class="nav-items sub-nav">
                <div
                  class="items"
                  v-for="i in 3"
                  :key="i"
                  :class="{
                    selected: i === currentSubLevel,
                    checked: i < currentSubLevel,
                  }"
                  @click="currentSubLevel = i"
                >
                  <h4>{{ i }}.</h4>
                </div>
                <div class="item-title sub-nav">
                  <h4 v-html="currentNavLabelConjunto"></h4>
                </div>
              </div>
            </div>
            <div
              class="form-section"
              v-if="currentSubLevel === 1"
              style="padding-top: 0"
            >
              <label class="label" v-html="'Nombre:'"></label>
              <input type="text" v-model="currentItem.name" />
              <label class="label" v-html="'Descripci&oacute;n:'"></label>
              <textarea
                name=""
                id=""
                rows="5"
                v-model="currentItem.description"
              ></textarea>
              <div class="nav-buttons">
                <div class="left-nav"></div>
                <div class="right-nav">
                    <button class="nav-btn right" @click="currentSubLevel = 2">
                      <p>Continuar</p>
                      <div class="grey">
                        <i class="material-icons">arrow_forward_ios</i>
                      </div>
                    </button>
                    <button
                      class="ok-btn right"
                      style="margin-right: 1em;margin-top:10px"
                      @click="
                        DESPLEGAR_MENSAJE_CONFIRMACION({
                          icon: 'save',
                          title: 'Actualizar Caracter&iacute;sticas',
                          msg: '&iquest;Deseas actualizar las caracter&iacute;sticas del objeto?',
                          response: 'actualizarCaracteristicasObjetoConjunto',
                        })
                      "
                    >
                      Guardar
                    </button>
                </div>
              </div>
            </div>
            <div
              class="form-section"
              v-if="currentSubLevel === 2"
              style="padding-top: 0"
            >
              <p
                v-html="'Ingrese al menos una fotograf&iacute;a del Sitio.'"
              ></p>
              <div class="split-60-40 fullsize">
                <div>
                  <label class="label" v-html="'Vista del Objeto'"></label>
                  <select name="view" id="view" v-model="view">
                    <option
                      v-for="(item, index) in views"
                      :key="index"
                      :value="item.name"
                      v-html="item.text"
                    ></option>
                  </select>
                </div>
                <div>
                  <label class="label" v-html="'Seleccionar archivo'"></label>
                  <div class="add-element">
                    <input type="text" disabled v-model="currentImage.name" />
                    <input
                      class="file"
                      type="file"
                      ref="fileInput2"
                      accept="image/*"
                      @change="onFilePickedConjunto"
                    />
                    <button
                      class="icon-btn"
                      @click="$refs.fileInput2.click()"
                      :disabled="view === 'none'"
                    >
                      <i class="material-icons">cloud_upload</i>
                    </button>
                  </div>
                </div>
              </div>
              <label
                class="label"
                v-html="'Fotograf&iacute;as Cargadas'"
              ></label>
              <table>
                <tr class="titles">
                  <th v-html="'Imagen'"></th>
                  <th>Vista del Objeto</th>
                  <th>Opciones</th>
                </tr>
                <tr
                  v-if="
                    currentItem.image !== undefined &&
                    currentItem.image !== null &&
                    currentItem.image !== ''
                  "
                >
                  <td class="center">
                    <img :src="currentItem.image.url" alt="" />
                  </td>
                  <td class="left">Referencia</td>
                  <td class="center">
                    <div>
                      <button
                        class="tool minimal-icon-btn white"
                        @click="openGallery(0)"
                      >
                        <i class="material-icons">open_in_full</i>
                        <span class="tooltiptext bottom">Visualizar</span>
                      </button>
                      <button
                        class="tool minimal-icon-btn white"
                        @click="
                          DESPLEGAR_MENSAJE_CONFIRMACION({
                            icon: 'delete',
                            title: 'Eliminar Fotograf&iacute;a',
                            msg: '&iquest;Deseas eliminar la fotograf&iacute;a del objeto?',
                            response: 'eliminarFotoObjetoConjunto',
                          });
                          eliminarVista = 'Referencia';
                        "
                      >
                        <i class="material-icons">delete</i>
                        <span class="tooltiptext bottom">Eliminar</span>
                      </button>
                    </div>
                  </td>
                </tr>
                <tr
                  v-for="(item, index) in currentItem.pictures"
                  :key="item.view"
                >
                  <td class="center">
                    <img :src="item.url" alt="" />
                  </td>
                  <td class="left">{{ item.view }}</td>
                  <td class="center">
                    <div>
                      <button
                        class="tool minimal-icon-btn white"
                        @click="openGallery(index + 1)"
                      >
                        <i class="material-icons">open_in_full</i>
                        <span class="tooltiptext bottom">Visualizar</span>
                      </button>
                      <button
                        class="tool minimal-icon-btn white"
                        @click="
                          DESPLEGAR_MENSAJE_CONFIRMACION({
                            icon: 'delete',
                            title: 'Eliminar Fotograf&iacute;a',
                            msg: '&iquest;Deseas eliminar la fotograf&iacute;a del objeto?',
                            response: 'eliminarFotoObjetoConjunto',
                          });
                          eliminarVista = item.view;
                        "
                      >
                        <i class="material-icons">delete</i>
                        <span class="tooltiptext bottom">Eliminar</span>
                      </button>
                    </div>
                  </td>
                </tr>
              </table>
              <div class="nav-buttons">
                <div class="left-nav">
                  <button class="nav-btn" @click="currentSubLevel = 1">
                    <div class="grey">
                      <i class="material-icons">arrow_back_ios_new</i>
                    </div>
                    <p>Volver</p>
                  </button>
                </div>
                <div class="right-nav">
                    <button class="nav-btn right" @click="currentSubLevel = 3">
                      <p>Continuar</p>
                      <div class="grey">
                        <i class="material-icons">arrow_forward_ios</i>
                      </div>
                    </button>
                  </div>
              </div>
            </div>
            <div
              class="form-section"
              v-if="currentSubLevel === 3"
              style="padding-top: 0"
            >
              <p
                v-html="'Ingrese al menos una habitaci&oacute;n del inmueble.'"
              ></p>
              <div class="split-60-40 fullsize">
                <div>
                  <label
                    class="label"
                    v-html="'Nombre Habitaci&oacute;n'"
                  ></label>
                  <div class="add-element">
                    <input type="text" v-model="newRoom.name" />
                    <button
                      class="icon-btn"
                      @click="addNewRoom({ site: itemId, name: newRoom.name })"
                      :disabled="newRoom.name === ''"
                    >
                      <i class="material-icons">add</i>
                    </button>
                  </div>
                </div>
              </div>
              <table>
                <tr class="titles">
                  <th v-html="'Id'"></th>
                  <th>Nombre</th>
                  <th>Opciones</th>
                </tr>

                <tr v-for="item in currentItem.rooms" :key="item.id">
                  <td class="left">{{ item.id }}</td>
                  <td class="left">{{ item.name }}</td>
                  <td class="center">
                    <div>
                      <button class="minimal-icon-btn white">
                        <i class="material-icons">open_in_full</i>
                        <span class="tooltiptext bottom">Visualizar</span>
                      </button>
                      <button class="minimal-icon-btn white">
                        <i class="material-icons">delete</i>
                        <span class="tooltiptext bottom">Eliminar</span>
                      </button>
                    </div>
                  </td>
                </tr>
              </table>
              <div class="nav-buttons">
                <div class="left-nav">
                  <button class="nav-btn" @click="currentSubLevel = 2">
                    <div class="grey">
                      <i class="material-icons">arrow_back_ios_new</i>
                    </div>
                    <p>Volver</p>
                  </button>
                </div>
                <div class="right-nav"></div>
              </div>
            </div>
          </div>
        </SimpleBar>
      </div>
    </div>
    <div class="picture-preview" v-if="imagePreview">
      <SimpleBar class="box">
        <div class="box-content">
          <div
            class="image"
            :style="'background-image:url(' + currentImage.preview + ')'"
          ></div>
          <div class="icon">
            <p>
              <i class="material-icons">add_photo_alternate</i>
            </p>
          </div>
          <div class="text">
            <h4>Agregar Imagen</h4>
            <p>
              <strong>Nombre:</strong>
              {{ currentImage.name }}
            </p>
            <p>
              <strong>Vista:</strong>
              {{ view }}
            </p>
            <p class="btn-center">
              <button class="ok-btn" v-if="imagePreview" @click="addImage">
                Agregar
              </button>
              <button class="cancel-btn" @click="clearImage">Cancelar</button>
            </p>
          </div>
        </div>
      </SimpleBar>
    </div>
    <Loading text="cargando" />
    <Message />
    <Gallery />
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";

//import EditDefinition from "@/components/EditDefinition.vue"
import { SimpleBar } from "simplebar-vue3";
import Message from "@/components/Message.vue";
import Loading from "@/components/Loading.vue";
import Gallery from "@/components/Gallery.vue";
import app from "@/firebase";
import { getFirestore, doc, updateDoc } from "firebase/firestore";
const db = getFirestore(app);
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
const storage = getStorage(app);

export default {
  name: "Sitios-section",
  components: { SimpleBar, Message, Loading, Gallery },
  data() {
    return {
      list: [],
      currentIndex: "B",
      currentId: "",
      itemId: "",
      title: "Sitios y Edificios de Puerto Cristal",
      allItems: [],
      searchedId: "",
      displayData: false,
      editDisplayed: false,
      addModule: false,
      addNew: {
        id: "",
        name: "",
      },
      newRoom: {
        id: "",
        name: "",
      },
      editItem: false,
      currentSubLevel: 1,
      views: [],
      view: "none",
      currentImage: {
        name: "",
        preview: "",
        file: null,
      },
      imagePreview: false,
    };
  },
  computed: {
    ...mapGetters("sitios", {
      sitios: "sitios",
      indexList: "abcItems",
      itemsByIndex: "itemsByIndex",
    }),
    currentItem() {
      return this.sitios.find((item) => item.id === this.itemId);
    },
    currentNavLabelConjunto() {
      let label = "Informaci&oacute;n del Sitio";
      switch (this.currentSubLevel) {
        case 1:
          label = "Informaci&oacute;n del Sitio";
          break;
        case 2:
          label = "Fotograf&iacute;as del Sitio";
          break;
        case 3:
          label = "Habitaciones del Sitio";
          break;
        default:
          label = "Informaci&oacute;n del Sitio";
          break;
      }
      return label;
    },
  },
  mounted() {
    this.currentId = this.$route.params.id;
    this.currentIndex = this.indexList[0];
    this.list = this.itemsByIndex(this.currentIndex);
    this.views = [];
    this.views.push({ name: "none", text: "Seleccione una cara del objeto" });
    this.views.push({ name: "Principal", text: "Imagen Principal" });
    this.views.push({ name: "Plano de Planta", text: "Plano de Planta" });
    this.views.push({ name: "Emplazamiento", text: "Posterior" });
    this.views.push({ name: "Otro", text: "Otro..." });
  },
  watch: {
    getMessageAction(payload) {
      if (payload.component === "addSuccess") {
        console.log("entra");
        this.addModule = false;
        this.SET_MESSAGE_PARAMETERS({});
      }
    },
    currentIndex(val) {
      this.list = this.itemsByIndex(val);
    },
    itemId(val) {
      this.currentItem = this.allItems.find((item) => item.id === val);
    },
  },
  methods: {
    ...mapActions("materials", {
      addMaterial: "addItem",
    }),
    ...mapActions("sitios", {
      update: "updateItems",
      addNewRoom: "addNewRoom",
      addNewSite: "addNewSite",
    }),
    ...mapActions("typologies", {
      addTypology: "addItem",
    }),
    ...mapActions("glossary", {
      addDefinicion: "addItem",
    }),
    ...mapMutations([
      "SET_MESSAGE_PARAMETERS",
      "SET_LOADING",
      "SET_MESSAGE_PARAMETERS",
      "SET_LOADING_OPACITY",
      "SET_LOADING_TEXT",
      "SET_GALLERY",
      "SET_GALLERY_INDEX",
      "SET_GALLERY_ITEMS",
    ]),

    DESPLEGAR_MENSAJE_CONFIRMACION(payload) {
      this.SET_MESSAGE_PARAMETERS({
        isVisible: true,
        type: "error",
        icon: payload.icon,
        title: payload.title,
        message: payload.msg,
        buttonAction: "Aceptar",
        button: "Cancelar",
        respondTo: payload.response,
        action: 0,
      });
    },

    DESPLEGAR_MENSAJE_ADVERTENCIA(payload) {
      this.SET_MESSAGE_PARAMETERS({
        isVisible: true,
        type: "error",
        icon: payload.icon,
        title: payload.title,
        message: payload.msg,
        buttonAction: "Aceptar",
        button: "Cancelar",
        respondTo: payload.response,
        action: 0,
      });
    },
    onFilePickedConjunto: function (event) {
      const file = event.target.files;
      let fileName = file[0].name;
      if (fileName.lastIndexOf(".") <= 0) {
        return alert("Ingrese una imagen valida");
      }
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        this.currentImage.preview = fileReader.result;
      });
      this.currentImage.name = fileName;
      fileReader.readAsDataURL(file[0]);
      this.currentImage.file = file[0];
      this.imagePreview = true;
    },
    addImage() {
      if (
        this.currentItem.pictures.find(
          (doc) => doc.name === this.currentImage.name
        ) !== undefined
      ) {
        this.SET_MESSAGE_PARAMETERS({
          isVisible: true,
          type: "error",
          icon: "error",
          title: "Foto Ingresada",
          message:
            "Esta foto ya se ha agregado a este objeto, seleccione otra.",
          buttonAction: "",
          button: "Aceptar",
          respondTo: "errorPhotoExistsCojunto",
          action: 0,
        });
      } else if (
        this.currentItem.pictures.find((doc) => doc.view === this.view) !==
        undefined
      ) {
        this.SET_MESSAGE_PARAMETERS({
          isVisible: true,
          type: "alert",
          icon: "warning",
          title: "Vista Ingresada",
          message:
            "Ya se ha agregado una fotograf&iacute;a a la vista: " +
            this.view +
            " &iquest;desea reemplazarla por la foto nueva?",
          buttonAction: "Actualizar",
          button: "Cancelar",
          respondTo: "errorViewExistsConjunto",
          action: 0,
        });
      } else {
        let newImage = {
          view: this.view,
          name: this.currentImage.name,
          preview: this.currentImage.preview,
          file: this.currentImage.file,
        };
        this.uploadImage(newImage);
        this.currentImage = {
          name: "",
          preview: "",
          file: null,
        };
      }

      this.imagePreview = false;
    },

    uploadImage(payload) {
      try {
        this.SET_LOADING(true);
        if (payload.name) {
          let refImg = ref(
            storage,

            "/sitios/" + this.currentItem.id + "/" + payload.name
          );
          this.uploadTask = uploadBytesResumable(refImg, payload.file);
          this.uploadTask.on(
            "state_changed",
            (snap) => {
              var progress = Math.trunc(
                (snap.bytesTransferred / snap.totalBytes) * 100
              );
              this.SET_LOADING_TEXT("Cargando " + progress + "%");
              console.log("Upload is " + progress + "% done");
            },
            (error) => {
              console.log(error);
            },
            () => {
              getDownloadURL(this.uploadTask.snapshot.ref).then((url) => {
                if (payload.view === "Principal") {
                  updateDoc(doc(db, "Sitios", this.currentItem.id), {
                    image: {
                      url: url,
                      name: payload.name,
                    },
                  });
                  this.currentItem.image = {
                    preview: url,
                    url: url,
                  };
                } else if (payload.view === "Plano de Planta") {
                  updateDoc(doc(db, "Sitios", this.currentItem.id), {
                    plano: {
                      url: url,
                      name: payload.name,
                    },
                  });
                  this.currentItem.plano = {
                    preview: url,
                    url: url,
                  };
                } else if (payload.view === "Emplazamiento") {
                  updateDoc(doc(db, "Sitios", this.currentItem.id), {
                    emplazamiento: {
                      url: url,
                      name: payload.name,
                    },
                  });
                  this.currentItem.emplazamiento = {
                    preview: url,
                    url: url,
                  };
                } else {
                  let newImg = {
                    preview: url,
                    url: url,
                    view: payload.view,
                  };
                  if (this.currentItem.pictures.length === 0) {
                    this.currentItem.pictures.push(newImg);
                  } else {
                    let position = 0;
                    this.currentItem.pictures.forEach((element) => {
                      if (element.view.localeCompare(newImg.view) === -1) {
                        position++;
                      }
                    });
                    this.currentItem.pictures.splice(position, 0, newImg);
                    updateDoc(doc(db, "Sitios", this.currentItem.id), {
                      pictures: this.currentItem.pictures,
                    });
                  }
                }
                this.imagePreview = false;
                this.SET_LOADING(false);
                this.SET_LOADING_TEXT("Cargando...");
                this.SET_MESSAGE_PARAMETERS({
                  isVisible: true,
                  type: "success",
                  icon: "done",
                  title: "Foto Agregada",
                  message:
                    "Se ha agregado correctamente la fotograf&iacute;a al Sitio.",
                  buttonAction: "",
                  button: "Aceptar",
                  respondTo: "successfullSiteUpdate",
                  action: 0,
                });
              });
            }
          );
        }
      } catch (error) {
        this.SET_MESSAGE_PARAMETERS({
          isVisible: true,
          type: "error",
          icon: "error",
          title: "Ocurri&oacute; un error al cargar la imagen",
          message:
            "Ocurri&oacute; un error al subir la imagen al servidor, por favor intentelo m&aacute;s tarde.",
          buttonAction: "",
          button: "Aceptar",
          respondTo: "error",
          action: 0,
        });
        this.SET_LOADING(false);
        console.log(error);
      }
    },
    clearImage() {
      this.imagePreview = false;
      this.currentImage = {
        name: "",
        preview: "",
        file: null,
      };
    },
    getId(payload) {
      console.log(payload);
    },
    async addNewItem() {
      this.SET_LOADING(true);
      let data = {
        deposit:false,
        description: "",
        emplazamiento: "",
        image: "",
        pictures: [],
        plano: "",
        rooms: [],
        name: this.addNew.name,
      };
      let response = await this.addNewSite({ id: this.addNew.id, data: data });
      if (response) {
        this.SET_MESSAGE_PARAMETERS({
          isVisible: true,
          type: "success",
          icon: "done",
          title: "Sitio Agregado",
          message: "Se ha agregado correctamente el Sitio.",
          buttonAction: "",
          button: "Aceptar",
          respondTo: "successAddSite",
          action: 0,
        });
        this.addModule = false;
      }
      this.SET_LOADING(false);
      this.SET_LOADING_TEXT("Cargando...");
    },
    async addRoom() {
      this.SET_LOADING(true);
      
      let response = await this.addNewRoom({ site: this.itemId, name: this.newRoom.name });
      if (response) {
        this.SET_MESSAGE_PARAMETERS({
          isVisible: true,
          type: "success",
          icon: "done",
          title: "Habitaci&oacute;n Agregada",
          message: "Se ha agregado correctamente la habitaci&oacute;n al Sitio.",
          buttonAction: "",
          button: "Aceptar",
          respondTo: "successAddRoom",
          action: 0,
        });
        this.addModule = false;
      }
      this.SET_LOADING(false);
      this.SET_LOADING_TEXT("Cargando...");
    },

    openGallery(payload) {
      let arr = [];
      let pos = 0;
      let el = {};
      let i = 0;
      if (this.currentItem.image) {
        arr.push({
          url: this.currentItem.image.url,
          view: "Imagen Principal",
          position: i,
        });
        i++;
      }
      this.currentItem.pictures.forEach((item) => {
        arr.push({ ...item, position: i });
        i++;
      });
      pos = arr.findIndex((item) => item.position === payload);
      el = arr[pos];
      arr.splice(pos, 1);
      arr.splice(0, 0, el);
      this.SET_GALLERY_ITEMS(arr);
      this.SET_GALLERY_INDEX(payload);
      this.SET_GALLERY(true);
    },
    search() {},
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/sass/variables";
@import "./../assets/sass/buttons";
@import "./../assets/sass/ficha";
@import "./../assets/sass/modal";

@import "./../assets/sass/cards";
.settings-container {
  position: relative;
  .index-container {
    text-align: center;
    p {
      display: inline-block;
      color: $text-dark;
      vertical-align: middle;
      button {
        background-color: transparent;
        border: none;
        cursor: pointer;
        font-size: 1em;
        &:hover,
        &.selected {
          font-size: 1.5em;
          color: $theme-d1;
          font-weight: 600;
        }
      }
    }
  }
  h1 {
    font-size: 3.5em;
    margin-block-start: 0px;
    color: $theme-d2;
    margin-left: 1em;
  }
  .search {
    background-image: linear-gradient(
        to bottom,
        transparent,
        rgba(0, 0, 0, 0.75)
      ),
      url("../assets/media/MAESTRANZA.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding-top: 25vh;
    padding-bottom: 15vh;
    h1,
    h2 {
      position: relative;
      text-align: center;
      color: $theme-l4;
      padding-left: 10%;
      padding-right: 10%;
      margin-block-end: 0px;
      margin-block-start: 0px;
      padding-top: 15%;
    }
    div {
      display: grid;
      padding-left: 10%;
      padding-right: 10%;
      grid-template-columns: 1.5fr 0.25fr;
      div.options {
        .icon-container {
          padding-top: 25px;
          padding-bottom: 25px;
          button {
            background-color: $theme;
            color: white;
            border-radius: 50px;
          }
        }
      }
    }
  }
  .title-add {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
  }
  .page-container {
    display: grid;
    grid-template-columns: 100%;
    row-gap: 0.5em;
    width: 100%;
    padding: 10px 1em;
  }
  .modal {
    .nav {
      border-radius: 5px;
      background-color: white;
      padding: 15px;
      width: 100%;
      .nav-items {
        display: grid;
        width: fit-content;
        position: relative;
        margin: 0 auto;
        column-gap: 0.5em;
        width: 100%;
        min-width: 50%;
        &.sub-nav {
          grid-template-columns: repeat(3, 1fr);
        }
        &.main-nav {
          grid-template-columns: repeat(8, 1fr);
        }
        .items {
          text-align: center;
          border-bottom-color: $grey;
          border-bottom-style: solid;
          border-bottom-width: 5px;
          cursor: pointer;
          h4 {
            color: $grey;
          }
          &.selected,
          &.checked {
            border-bottom-color: $theme-d1;
            h4 {
              color: $theme-d1;
            }
          }
          &.selected,
          &:hover {
            h4 {
              font-weight: 700;
            }
          }
        }
        .item-title {
          padding-top: 1em;
          grid-row-start: 2;
          grid-row-end: 3;
          grid-column-start: 1;
          grid-column-end: 9;
          &.sub-nav {
            grid-column-end: 4;
          }
          align-content: center;
          h4 {
            font-weight: 500;
            color: $theme-d1;
            text-align: center;
          }
        }
      }
    }
    .form-section {
      border-radius: 5px;
      background-color: white;
      padding: 15px;
      border-radius: 5px;
      border-color: $theme-d2;
      .split-field {
        display: grid;
        gap: 8px;
        grid-template-columns: 100%;
      }
      .add-element {
        width: 100%;
        display: grid;
        height: 40px;
        grid-template-columns: auto 40px;
        grid-gap: 5px;
        .autocomplete-container {
          overflow: visible;
          margin-top: 5px;
          height: 30px;
        }
      }
      .tag {
        width: auto;
        float: left;
        position: relative;
        background-color: lightgray;
        border: none;
        padding: 3px 5px 3px 10px;
        border-radius: 50px;
        width: fit-content;
        margin-right: 3px;
        margin-bottom: 3px;
        p {
          line-height: 18px;
          vertical-align: middle;
          margin-block-end: 0px;
          margin-block-start: 0px;
          i.material-icons {
            vertical-align: middle;
            background-color: transparent;
            border-radius: 50px;
            padding: 3px;
            margin-left: 10px;
          }
          i:hover {
            background-color: white;
            cursor: pointer;
          }
        }
      }
      .split-60-40 {
        width: calc(100% - 8px);
        display: grid;
        grid-template-columns: 60% 40%;
        gap: 8px;
        &.fullsize {
          grid-template-columns: 100%;
        }
      }
      table {
        width: 100%;
        margin-top: 1em;
        border-spacing: 0;
        border-radius: 5px;
        th {
          background-color: $blue-grey;
          color: white;
          padding: 1em;
          font-weight: 500;
          font-size: 1.17em;
        }
        td {
          border-bottom: 1px solid $blue-grey;
          padding-top: 0.5em;
          padding-bottom: 0.5em;
          text-align: center;
          div.icon {
            background-color: $theme-d1;
            border-radius: 5px;
            i {
              padding: 0.5em;
              font-size: 2em;
              color: white;
            }
          }
          &.center {
            div {
              position: relative;
              background-color: white;
            }
          }
          p {
            display: inline;
            i {
              margin-left: 0.25em;
              margin-right: 0.25em;
              &:hover {
                color: $theme-d1;
                cursor: pointer;
              }
            }
          }
          img {
            margin-top: 0.5em;
            margin-bottom: 0.25em;
            max-width: 100px;
            height: auto;
            border-radius: 5px;
          }
        }
      }
      .file {
        display: none;
      }
      .left {
        margin-top: 4.5px;
        position: relative;
        label.label {
          display: inline;
          color: $text-dark;
          line-height: 30px;
          margin-block-end: 0;
          margin-block-start: 0;
          vertical-align: middle;
          top: 0%;
          margin-right: 1em;
        }
      }
    }
    .nav-buttons {
      display: grid;
      grid-template-columns: auto auto;
      grid-auto-rows: auto;
      margin-top: 1em;
      .left-nav {
        float: left;
        grid-row-start: 1;
        grid-row-end: 2;
        grid-column-start: 1;
        grid-column-end: 2;
      }
      .right-nav {
        float: right;
        grid-row-start: 1;
        grid-row-end: 2;
        grid-column-start: 2;
        grid-column-end: 3;
        p{
          text-align: right;
          widows: 100%;
          position: relative;
        }
      }
    }
    .inputs {
      label,
      input,
      textarea {
        width: 100%;
      }
    }
    .picture-preview {
      height: 100vh;
      width: 100vw;
      position: fixed;
      top: 0;
      left: 0;
      margin: 0px;
      background-color: #22190880;
      z-index: 21;
      div.box {
        overflow-y: auto;
        top: 50%;
        left: 50%;
        width: 85%;
        border-radius: 10px;
        -webkit-box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.2),
          0 8px 20px 0 rgba(0, 0, 0, 0.19);
        -moz-box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.2),
          0 8px 20px 0 rgba(0, 0, 0, 0.19);
        box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.2),
          0 8px 20px 0 rgba(0, 0, 0, 0.19);
        margin-bottom: 10px;
        background-color: white;
        transform: translate(-50%, -50%) !important;
        position: absolute;
        max-height: 90vh;
        .box-content {
          width: 100%;
          height: 100%;
          display: grid;
          grid-template-rows: auto 25px 25px auto;
          grid-template-columns: 100%;
          grid-gap: 5px;
          div.image {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            grid-row-start: 1;
            grid-row-end: 3;
            grid-column-start: 1;
            grid-column-end: 2;
            padding-top: 80%;
          }
          div.icon {
            grid-row-start: 2;
            grid-row-end: 4;
            grid-column-start: 1;
            grid-column-end: 2;
            p {
              text-align: center;
              margin-block-start: 0px;
              margin-block-end: 0px;
              i.material-icons {
                background-color: $theme-l1;
                width: 50px;
                border-radius: 50px;
                height: 50px;
                color: whitesmoke;
                font-size: 35px;
                line-height: 50px;
                vertical-align: middle;

                -webkit-box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2),
                  0 4px 10px 0 rgba(0, 0, 0, 0.19);
                -moz-box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2),
                  0 4px 10px 0 rgba(0, 0, 0, 0.19);
                box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2),
                  0 4px 10px 0 rgba(0, 0, 0, 0.19);
              }
            }
          }
          div.text {
            padding: 0px 25px 12px 25px;
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 4;
            grid-row-end: 5;
            h4 {
              text-align: center;
            }
            p.btn {
              text-align: center;
              button:first-child {
                border-radius: 50px;
                color: whitesmoke;
                margin-right: 15px;
                background-color: $theme;
                text-decoration: none;
              }
              button {
                color: $text;
                background-color: white;
                text-decoration: underline;
                width: auto;
                padding: 12px 18px;
                height: auto;
                cursor: pointer;
                font-weight: 300;
                border: none;
                font-size: 18px;
              }
            }
          }
          &.input {
            grid-template-rows: auto;
          }
        }
      }
    }
    .inputs.large {
      margin-top: 0.75em;
      margin-bottom: 0.5em;
      height: fit-content;
      position: relative;
      .questions {
        display: grid;
        grid-gap: 5px;
        grid-template-columns: auto;
        div {
          input[type="radio"] {
            width: 15px !important;
            height: 15px !important;
            background-color: whitesmoke;
            margin-block-start: 0px;
            margin-block-end: 0px;
          }
        }
        .note {
          font-size: 14px;
          font-style: italic;
        }
      }
    }
    .text-link {
      div {
        p,
        span {
          display: inline;
          margin-left: 0.1em;
        }
        span {
          font-family: "Roboto", sans-serif;
          font-size: 1.1em;
          font-weight: 500;
          text-decoration: underline;
          color: $theme-d1;
          cursor: pointer;
        }
      }
    }
    .save-btn {
      width: 100%;
      text-align: center;
      button {
        padding: 12px 18px;
        border: none;
        width: auto !important;
        height: auto !important;
        font-size: 16px;
        font-weight: 300;
      }
      button.ok {
        color: whitesmoke;
        background-color: $theme;
        border-radius: 50px;
      }
      button.cancel {
        margin-left: 15px;
        background-color: transparent;
        color: $text;
        text-decoration: underline;
      }
    }
  }

  .picture-preview {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    margin: 0px;
    background-color: #22190880;
    z-index: 21;
    div.box {
      overflow-y: auto;
      top: 50%;
      left: 50%;
      width: 85%;
      border-radius: 10px;
      -webkit-box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.2),
        0 8px 20px 0 rgba(0, 0, 0, 0.19);
      -moz-box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.2),
        0 8px 20px 0 rgba(0, 0, 0, 0.19);
      box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.2),
        0 8px 20px 0 rgba(0, 0, 0, 0.19);
      margin-bottom: 10px;
      background-color: white;
      transform: translate(-50%, -50%) !important;
      position: absolute;
      max-height: 90vh;
      .box-content {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-rows: auto 25px 25px auto;
        grid-template-columns: 100%;
        grid-gap: 5px;
        div.image {
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          grid-row-start: 1;
          grid-row-end: 3;
          grid-column-start: 1;
          grid-column-end: 2;
          padding-top: 80%;
        }
        div.icon {
          grid-row-start: 2;
          grid-row-end: 4;
          grid-column-start: 1;
          grid-column-end: 2;
          p {
            text-align: center;
            margin-block-start: 0px;
            margin-block-end: 0px;
            i.material-icons {
              background-color: $theme-l1;
              width: 50px;
              border-radius: 50px;
              height: 50px;
              color: whitesmoke;
              font-size: 35px;
              line-height: 50px;
              vertical-align: middle;

              -webkit-box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2),
                0 4px 10px 0 rgba(0, 0, 0, 0.19);
              -moz-box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2),
                0 4px 10px 0 rgba(0, 0, 0, 0.19);
              box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2),
                0 4px 10px 0 rgba(0, 0, 0, 0.19);
            }
          }
        }
        div.text {
          padding: 0px 25px 12px 25px;
          grid-column-start: 1;
          grid-column-end: 2;
          grid-row-start: 4;
          grid-row-end: 5;
          h4 {
            text-align: center;
          }
          p.btn {
            text-align: center;
            button:first-child {
              border-radius: 50px;
              color: whitesmoke;
              margin-right: 15px;
              background-color: $theme;
              text-decoration: none;
            }
            button {
              color: $text;
              background-color: white;
              text-decoration: underline;
              width: auto;
              padding: 12px 18px;
              height: auto;
              cursor: pointer;
              font-weight: 300;
              border: none;
              font-size: 18px;
            }
          }
        }
        &.input {
          grid-template-rows: auto;
        }
      }
    }
  }
}

@media (min-width: 576px) {
  .settings-container {
    .nav {
      .nav-items {
        width: auto;
      }
    }
    .gallery {
      grid-template-columns: 1fr;
    }
    .inputs.large {
      .questions {
        grid-template-columns: auto auto auto;
      }
    }
  }
}
@media (min-width: 768px) {
  .settings-container {
    .modal {
      .form-section {
        table {
          td,
          th {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
            img {
              max-width: 150px;
            }
          }
        }
      }
      .dimensions {
        grid-template-columns: 3fr 2fr 1fr 40px;
        .long {
          grid-column-end: 5;
        }
        .mid {
          grid-column-end: 2;
        }
      }
    }
    .pictures {
      grid-template-columns: 1fr 1fr;
      .long {
        grid-column-start: 1;
        grid-column-end: 3;
      }
    }
    .picture-preview {
      div.box {
        width: 50%;
      }
    }
    .gallery {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}
@media (min-width: 992px) {
  .settings-container {
    .gallery {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }
}
</style>