<template>
  <div class="message" v-show="message.isVisible" :class="message.type">
    <div class="box">
      <div class="top-section">
        <p class="icon">
          <i class="material-icons" @click="closeMessage">cancel</i>
        </p>
        <i class="icon material-icons" v-if="message.icon!==null">{{message.icon}}</i>
        <i class="icon material-icons" v-else>description</i>
        <h2 v-html="message.title"></h2>
      </div>
      <div class="middle-section">
        <p v-html="message.message"></p>
      </div>
      <p class="btn">
        <button v-if="message.buttonAction!==''" @click="action">{{message.buttonAction}}</button>
        <button v-on:click="closeMessage">{{message.button}}</button>
      </p>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "Message-component",
  computed: {
    ...mapGetters(["message"])
  },
  methods: {
    ...mapMutations(["SET_MESSAGE_STATE","SET_MESSAGE_ACTION"]),
    closeMessage: function() {
      this.SET_MESSAGE_STATE(false);
      this.SET_MESSAGE_ACTION(-1);
    },
    action() {
      this.SET_MESSAGE_STATE(false);
      this.SET_MESSAGE_ACTION(1);
    }
  }
};
</script>
<style lang="scss" scope>
@import "./../assets/sass/variables";
@import "./../assets/sass/buttons";
.message {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  margin: 0px;
  background-color: #22190880;
  z-index: 25;
  padding: 5%;
  div.box {
    top: 50%;
    left: 50%;
    width: 85%;
    border-radius: 10px;
    -webkit-box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.2),
      0 8px 20px 0 rgba(0, 0, 0, 0.19);
    -moz-box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.2),
      0 8px 20px 0 rgba(0, 0, 0, 0.19);
    box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.2), 0 8px 20px 0 rgba(0, 0, 0, 0.19);
    margin-bottom: 10px;
    background-color: white;
    transform: translate(-50%, -50%) !important;
    position: absolute;
    max-height: 90vh;
    overflow-y: auto;
    display: grid;
    div.top-section {
      padding: 15px 15px 0px 15px;
      p.icon {
        text-align: right;
        i {
          font-size: 36px;
          cursor: pointer;
        }
        i:hover {
          font-weight: bold;
        }
        padding: 0px;
        color: white;
        margin-block-start: 0px;
        width: 100%;
        margin-block-end: 0px;
      }
      i.icon {
        text-align: center;
        font-size: 60px;
        width: 100%;
        color: white;
      }
      h2 {
        margin-block-start: 0px;
        text-align: center;
        color: white;
      }
    }
    div.middle-section {
      padding: 1em 1.5em;
      background-color: white;
      p {
        color: $text;
        text-align: center;
      }
    }
    p.btn {
      text-align: center;
      padding-bottom: 1em;
      button:first-child {
        border-radius: 50px;
        color: whitesmoke;
        
        margin-right: 15px;
      }
      button{
        color: $text;
        background-color: white;
        text-decoration: underline;        
        width: auto;
        padding: 12px 18px;
        cursor: pointer;
        font-weight: 300;
        border: none;
        font-size: 18px;
      }
    }
  }
}
.message {
  div.box {
    div.top-section {
      background-color: $message;
    }
    p.btn {
      button:first-child {
        background-color: $message-bg;
      }
    }
  }
}
.message.alert {
  div.box {
    div.top-section {
      background-color: $alert;
    }
    p.btn {
      button:first-child {
        background-color: $alert-bg;
      }
    }
  }
}
.message.success {
  div.box {
    div.top-section {
      background-color: $success;
    }
    p.btn {
      button:first-child {
        background-color: $success-bg;
      }
    }
  }
}
.message.error {
  div.box {
    div.top-section {
      background-color: $error;
    }
    p.btn {
      button:first-child {
        background-color: $error-bg;
      }
    }
  }
}
@media (min-width: 768px) {
  .message {
    div.box {
      width: 50%;
    }
  }
}
@media (min-width: 992px) {
  .message {
    div.box {
      width: 40%;
    }
  }
}
</style>
