import app from '@/firebase'
import {
    getFirestore,
    doc,
    updateDoc,
    onSnapshot,
    deleteDoc,
    runTransaction,
    getDoc
} from "firebase/firestore";
const db = getFirestore(app)
export default {
    state: {
        items: []
    },
    namespaced: true,
    actions: {
        async getItems({
            commit
        }) {
            try {
                let response=await getDoc(doc(db, 'System', "materiales"))
                let list=response.data().datos
                list.forEach(el=>{
                    el.estadistica=0
                })
                commit('ADD_ITEMS', {
                    list
                })
                return true
            } catch (e) {
                console.log('error', e)
            }
        },
        async addItem({
            commit
        }, payload) {
            try {
                let count = doc(db, "Materiales", "counter")
                let newDoc = await runTransaction(db, async (transaction) => {
                    let doc = await transaction.get(count)
                    let index = doc.data().nextValue
                    let id
                    if (index < 10) {
                        id = "000" + index
                    } else if (index > 9 && index < 100) {
                        id = "00" + index
                    } else if (index > 99 && index < 1000) {
                        id = "0" + index
                    } else {
                        id = index
                    }
                    let newIndex = index + 1
                    let code = "mat" + id
                    transaction.update(count, {
                        nextValue: newIndex
                    })
                    transaction.set(doc(db, "Materiales", code), {
                        ...payload
                    })
                    return {
                        id: code,
                        ...payload
                    }
                })
                console.log(newDoc)
                commit("ADD_ITEM", newDoc)
                return true
            } catch (e) {
                console.log('error', e)
            }
        },
        async updateItemName({
            commit
        }, payload) {
            try {
                await updateDoc(doc(db, 'Materiales', payload.id), {
                    name: payload.name
                })
                commit('UPDATE_ITEM_NAME', payload)

            } catch (e) {
                console.log('error', e)
            }
        },
        async updateItemDefinition({
            commit
        }, payload) {
            try {

                await updateDoc(doc(db, 'Materiales', payload.id), {
                    definition: payload.definition
                })
                commit('UPDATE_ITEM_DEFINITION', payload)
            } catch (e) {
                console.log('error', e)
            }
        },
        async deleteItemById({
            commit
        }, payload) {
            try {

                await deleteDoc(doc(db, 'Materiales', payload))
                commit('REMOVE_ITEM_BY_ID', payload)
            } catch (e) {
                console.log('error', e)
            }
        },
        async getItemObjects({
            commit
        }, payload) {
            try {
                onSnapshot(doc(db, 'Materiales/' + payload + '/items'), qS => {
                    var arr = []
                    qS.forEach(doc => {
                        arr.push(doc.id)
                    })
                    commit('SET_ITEM_OBJECTS', {
                        id: payload,
                        data: arr
                    })
                    return arr
                })
            } catch (e) {
                console.log('error', e)
            }

        },
        async getStatistics({commit,rootGetters}){
            rootGetters.allItems.forEach(item=>{
                item.materials.forEach(elem=>{
                    commit("SUM_ONE_TO_STATISTIC",elem)
                })
            })
            return true
        },
        async setMateriales(){
            let materiales=[
                {id:'mat0001',name:'Acero',definition:'Cualquiera de las diversas aleaciones maleables, durables, resistentes y duras de hierro y carbono, a menudo con elementos como manganeso, cromo, níquel, molibdeno, cobre, tungsteno, cobalto o silicio, utilizado ampliamente como material en estructuras.',source:''},
                {id:'mat0002',name:'Acetato de plivinilo',definition:'Resina sintética derivada de la polimerización de acetato de vinilo. Se utiliza principalmente como agente de filmación en pinturas a base de agua, y también como un ingrediente en adhesivos.',source:''},
                {id:'mat0003',name:'Acuarela',definition:'Una pintura transparente acuosa a base de tierra producida por la mezcla de pigmentos con agua y, en general, goma árabe. Las pinturas a base de agua hechas con goma vegetal fueron utilizadas por artistas egipcios, griegos y romanos en pinturas murales. Los pintores japoneses y chinos utilizaron ampliamente pinturas de acuarela sobre paneles de seda y rollos de papel delicado. En el siglo XVI y a través del siglo XVIII, las pinturas de acuarela se utilizaron para ilustraciones en miniatura de porcelana, marfil, tarjetas, libros y manuscritos. En el siglo XVIII y comienzos del XIX, las acuarelas aumentaron rápidamente en popularidad debido a la disponibilidad de pastillas de acuarelas en bandejas de metal, por lo general se aplica a un soporte de papel con un pincel.',source:''},
                {id:'mat0004',name:'Anilina',definition:'Colorante producido a partir de anilina, un líquido venenoso y oleoso. Utilizado para fabricar las primeras anilinas sintéticas.',source:''},
                {id:'mat0005',name:'Arcilla',definition:'Sedimentos que se encuentran en la naturaleza producida por acción química durante la intemperización de las rocas; a menudo se aplica el término a todo tipo de tierra que forma una pasta con agua y que se endurece al calentarla.',source:''},
                {id:'mat0006',name:'Arenisca',definition:'Roca sedimentaria consolidada que está formada por granos de arena unidos por un material de cimentación natural. La arena más común en la arenisca contiene cuarzo, con cantidades importantes de feldespato, cal, mica y materias gredosas.',source:''},
                {id:'mat0007',name:'Basalto',definition:'Roca ígnea volcánica densa y dura, de color marrón oscuro a negro, formada por feldespatos y minerales llamativos como augita u olivina.',source:''},
                {id:'mat0008',name:'Bronce',definition:'Se refiere a una amplia gama de aleaciones de cobre, específicamente todas las aleaciones no ferrosas de cobre, estaño y zinc u otros metales de traza. El bronce fue hecho antes del 3.000 a. C. -posiblemente antes del 10.000 a.C, aunque su uso común en herramientas y objetos de decoración es datado sólo para los artefactos más tarde. Las proporciones de cobre y estaño varían ampliamente, desde 70 a 95% de cobre para artefactos antiguos que se han encontrado. Debido a la base de cobre, el bronce puede ser muy maleable y fácil de trabajar. En la Edad Media en Europa, se reconoció que el uso de los metales en ciertas proporciones podría producir propiedades específicas. Algunos bronces modernos no contienen estaño, sustituidos por otros metales como aluminio, manganeso y incluso zinc. Históricamente, el término se utiliza indistintamente con "latten". El bronce estándar de EE.UU. está compuesto de cobre 90%, 7% de estaño y 3% de zinc. Aleaciones de bronce antiguo veces contenían hasta 14% de estaño.',source:''},
                {id:'mat0009',name:'Cartulina',definition:'Cartulina blanca de alto grado, supersatinado con caolín, obtenida también pegando entre sí hojas de papel de cuentas pesado.',source:''},
                {id:'mat0010',name:'Cartón',definition:'Clase de papel grueso por lo general en forma de hojas pesadas que se caracteriza por ser duro y hecho pegando capas de papel.',source:''},
                {id:'mat0011',name:'Cerámica',definition:'Se refiere a cualquier material duro, quebradizo, resistente al calor y resistente a la corrosión que se hace dando forma y después cociendo a altas temperaturas minerales no metálicos, tales como arcilla.',source:''},
                {id:'mat0012',name:'Cobre',definition:'Elemento metálico puro que tiene como símbolo Cu y como número atómico 29; metal rojizo muy maleable y dúctil que está presente en la corteza terrestre como metal nativo y en minerales de sulfuro, sulfato y carbonato (azurita, malaquita, etc.) Fue el primer metal usado por los seres humanos, probablemente alrededor del 8.000 a.C. en las regiones de Mesopotamia y la India. Alrededor de 3.800 a.C. el cobre se convirtió en bronce para armas y cuchillos. Hoy en día, el cobre es uno de los metales más utilizados, ya que tiene alta conductividad eléctrica y térmica, puede ser fácilmente fabricado, es dúctil y se pule bien. En el aire húmedo, el cobre forma una película protectora verde de carbonato básico. El cobre metálico se combina bien con otros metales para formar aleaciones, más comúnmente de latón y bronce. El cobre y sus aleaciones se utiliza para alambre, dispositivos eléctricos, tuberías, recipientes de cocina, municiones, material para techos ornamentales, recortes, rejas, monedas, instrumentos musicales, joyas y esculturas.',source:''},
                {id:'mat0013',name:'Concha',definition:'La concha de varios caracoles de mar del género Conus y de la familia Conidae, típicamente con un lado recto, y un extraordinario cuerpo en espiral, este baja hacia una abertura estrecha, es de colores vivos y marcas excéntricas. Es apreciado como material para brazaletes, discos y otros adornos.',source:''},
                {id:'mat0014',name:'Crin',definition:'Pelo de las crines y colas de caballos, utilizados fundamentalmente como fibra para cepillos, arcos de instrumentos musicales y para fabricar telas.',source:''},
                {id:'mat0015',name:'Cristal',definition:'Vidrio fino, pesado de alta calidad, decorativo, hecho de fina arena blanca, por lo menos 24% de óxido de plomo y pequeñas cantidades de potasio y nitrato potásico. Es vidrio transparente, incoloro, altamente refractivo pesado y es dos veces mayor que la densidad del vidrio de borato estándar. Fue desarrollado en Inglaterra en 1676, a menudo utilizado para prismas de araña de alta calidad y finas copas.',source:''},
                {id:'mat0016',name:'Cuero',definition:'Piel o cuero de un animal que ha sido curtido para hacerlo resistente a la putrefacción y relativamente blando y flexible al secarse. Para material compuesto hecho de piezas de cuero de chatarra, use "maril."',source:''},
                {id:'mat0017',name:'Emulsión',definition:'Suspensión de pequeñas gotas de un líquido insoluble en otro líquido. También una estrecha relación de una sustancia sólida o semi-sólida con un líquido, a veces con ayuda de un emulsificante. En fotografía, la mezcla del fijador y del material de la imagen final.',source:''},
                {id:'mat0018',name:'Esmalte',definition:'Sustancia calcárea intensamente dura que cubre parte o la totalidad de la corona del diente en los mamíferos. Esmalte, cuando madura, se compone principalmente de cristales de apatita que contienen calcio y fosfato. El esmalte es el tejido más duro del cuerpo, sino que no está viviendo y no contiene nervios. El espesor y la densidad de esmalte varia sobre la superficie del diente.',source:''},
                {id:'mat0019',name:'Fibra vegetal',definition:'Cualquier fibra obtenida de fuentes vegetales, incluyendo de corteza (lino, cáñamo, yute), tallos u hojas (abacá, sisal), y semillas (algodón). Una forma en que las fibras vegetales se pueden distinguir es que en general, arden cuando se exponen al fuego, mientras que las fibras animales sólo se carboniza.',source:''},
                {id:'mat0020',name:'Gelatina',definition:'Una forma coloidal insípida soluble en agua del colágeno procesado a partir de huesos, pezuñas o pieles y se utiliza como un agente dispersante, de tamaño medio, adhesivo, o de revestimiento para películas fotográficas, y estabilizador para productos alimenticios y preparaciones farmacéuticas. Es alta en el peso molecular después del procesamiento, a diferencia de colágeno procesado más vigorosamente que resulta en cola animal.',source:''},
                {id:'mat0021',name:'Grafito',definition:'Forma natural cristalina de carbono dimorfo con diamante. El grafito tiene una estructura en capas que consiste en anillos de seis átomos de carbono dispuestos en hojas horizontales ampliamente espaciado, y por lo tanto cristaliza en el sistema hexagonal, en contraste con el mismo elemento de cristalización en el sistema octaédrica o tetraédrica como el diamante, lo que resulta en características muy diferentes en cada uno. El grafito es opaco, suave, untuoso al tacto, y es negro de hierro a acero gris en color; se produce en forma de cristales, copos, escamas, venas, masas, o diseminaciones en rocas metamórficas.',source:''},
                {id:'mat0022',name:'Granito',definition:'Roca ígnea de grano grueso que se ha sometido a procesos metamórficos, formada por el enfriamiento del magma rico en sílice por debajo de la superficie de la tierra a grandes profundidades y presiones. Es la roca ígnea ácida intrusiva más común de la corteza de la Tierra. El granito se encuentra comúnmente en los continentes, pero está prácticamente ausente de las cuencas oceánicas. El término "granito" se refiere a su composición granular visible. Tiene una textura cristalina visible, es por lo general de color rojo, blanquecino, gris o de color dependiendo de su composición, es muy dura y resistente, y toma un pulimento fino. El granito es principalmente compuesto de feldespato, cuarzo y mica, junto con varios otros minerales en porcentajes variables. El granito es de uso frecuente para los edificios y monumentos.',source:''},
                {id:'mat0023',name:'Greda',definition:'Arcilla natural seleccionada por su finura, plasticidad, absorbencia y otras cualidades, que se prestan para diferentes usos en cerámica.',source:''},
                {id:'mat0024',name:'Hierro',definition:'Elemento metálico puro que tiene el símbolo Fe y número atómico 26; hierro metálico es de color plateado, brillante, blando, dúctil, maleable y ligeramente magnético; se oxida cuando se expone al aire húmedo. Rara vez se encuentra como metal nativo (hierro telúrico), excepto en meteoritos (hierro meteórico). El hierro se encuentra con más frecuencia en todo el mundo como óxidos de hierro (hematita, magnetita, limonita, siderita y) mezclado con otros minerales.',source:''},
                {id:'mat0025',name:'Hilo',definition:'Hilo continuo, único o compuesto, hecho de filamentos de fibra mediante devanado, hilado en rueca, trenzado o torcido.',source:''},
                {id:'mat0026',name:'Hueso',definition:'El material rígido calcáreo que es de color blanco y forma el esqueleto de los vertebrados; compuesto principalmente de hidroxiapatita de calcio con cantidades más pequeñas de carbonato de calcio, fluoruro de calcio, fosfato de magnesio, y oseína, una proteína de alto peso molecular. Los huesos tienen una estructura concéntrica con canales linfáticos central rodeada por una región laminar esponjoso protegida por una densa corteza externa. Huesos han sido tallados y utilizados desde la antigüedad para muchos propósitos, incluyendo anzuelos, puntas de lanza, agujas, mangos y objetos de arte. Los huesos también eran quemados para producir negro de hueso y se hierve para producir pegamento óseo. El hueso puede ser distinguido de marfil por ser generalmente más blanco, más poroso, y menos denso.',source:''},
                {id:'mat0027',name:'Lana',definition:'Textil fabricado con lana de pelo de animales.',source:''},
                {id:'mat0028',name:'Lápiz',definition:'Barras de una sustancia marcadora, encerradas en madera, plástico o metal, y utilizadas para escribir o dibujar.',source:''},
                {id:'mat0029',name:'Lápiz grafito',definition:'Lápices con una mina de grafito.',source:''},
                {id:'mat0030',name:'Madera',definition:'El tejido principal de árboles y otras plantas que proporciona fuerza y un medio de realización de nutrientes. La madera es uno de los materiales más versátiles conocidos.',source:''},
                {id:'mat0031',name:'Metal',definition:'Cualquiera de un amplio grupo de sustancias que presentan típicamente un brillo característico, son buenos conductores de la electricidad y el calor, son opacos, pueden fundirse y son habitualmente maleables o dúctiles.',source:''},
                {id:'mat0032',name:'Nitrato de plata',definition:'Polvo venenoso, corrosivo, amargo, blanco y cristalino, soluble en agua, AgNO3, producido por reacción de la plata con el ácido nítrico diluido. Se utilizó fundamentalmente en la manufactura de emulsiones fotográficas y espejos.',source:''},
                {id:'mat0033',name:'Obsidiana',definition:'Un vidrio oscuro, brillante formado en la naturaleza por el rápido enfriamiento de lava. La obsidiana se encuentra ampliamente distribuida y se ha utilizado desde el Paleolítico (antes de 3500 aC) para espejos, armas, herramientas y joyas. La obsidiana es a menudo de color negro, pero también puede ser de color rojo, marrón o verde. Produce fracturas concoides cuando se escinde. Las fuentes de obsidiana incluyen Anatolia, Armenia, Etiopía, Grecia (Milos), Italia (Lipari, Eolie), Islandia, EE.UU. (Wyoming), México (Teotihuacan), Guatemala (Tikal), y Perú. La obsidiana se transforma en piedra pómez cuando se ha derretido.',source:''},
                {id:'mat0034',name:'Oro',definition:'Elemento metálico puro que tiene el símbolo Au y número atómico 79; un metal blando de color rojizo y amarillo brillante inerte, que es muy maleable y dúctil. El oro ha sido muy valorado y se encuentra en artefactos que datan de antes de 5000 aC. El oro nativo, que se encuentra en vetas de cuarzo (oro vena) y depósitos aluviales (oro aluvial), por lo general contiene un poco de plata y cobre. El oro se purifica por disolución en soluciones de cianuro de mercurio o, por fusión o por electrodeposición. La pureza del oro comercial se expresa en quilates que es el número de partes de oro en 24 partes de la aleación. Hoy en día el oro se utiliza principalmente para sistemas monetarios y de joyería.',source:''},
                {id:'mat0035',name:'Papel',definition:'Papel de impresión de cloruro de emulsión de gelatina desarrollado en 1892 por Eastman Kodak Co. para uso de aficionados. Fue mejorado progresivamente y fue el papel de este tipo de mayor éxito. Disponible en algunos sitios hasta finales de la década de 1969, por lo menos dos décadas después que se había terminado de fabricar este tipo de papel.',source:''},
                {id:'mat0036',name:'Piedra',definition:'Término general para una roca que ha sido cortada, modelada, triturada o transformada de algún modo para ser utilizada en construcción o con otros propósitos. Incluye el sentido específico arqueológico y antropológico de las piedras individuales que pueden ser decoradas o adornadas y que pueden ser utilizadas en contextos rituales. Estas no suelen ser talladas o policromadas, por lo que difieren de esculturas hechas de piedra.',source:''},
                {id:'mat0037',name:'Plata',definition:'Elemento metálico puro con símbolo Ag y número atómico 47; un metal blanco maleable y dúctil, con un brillo característico, considerado un metal precioso. La plata es ampliamente distribuida en todo el mundo; ocurre rara vez como plata metálica (en Perú, Noruega), pero más a menudo como aleaciones de plata-oro y mineral de plata. Hoy la plata se obtiene como subproducto en el refinamiento de oro, plomo, cobre o de minerales de zinc. La plata se fundía del mineral de galena ya en el 3800 antes de Cristo. Como metal puro, la plata secunda al oro en maleabilidad y ductilidad, se puede pulir con una superficie altamente reflectante, y usados - típicamente en aleación - en joyería, monedas, fotografías, espejos, contactos eléctricos y artículos de mesa.',source:''},
                {id:'mat0038',name:'Plástico',definition:'Un término general para cualquiera de una gran y variada clase de materiales orgánicos naturales o sintéticos que pueden ser moldeados, extruidos o fundidos cuando está blanda o líquida, y luego puesta en una forma rígida o ligeramente elástica. Plásticos naturales incluyen resinas de árboles, cera de abejas, gutapercha, cuerno y arcilla. Plásticos sintéticos se hicieron por primera vez en el siglo XIX. Plásticos se utilizan ampliamente en la industria manufacturera.',source:''},
                {id:'mat0039',name:'Porcelana',definition:'Un material que comprende arcilla blanca o "caolín", y una piedra de feldespato, que reacciona cuando se cuece para que la arcilla sirva para mantener la forma del objeto y la roca se funde creando un vidrio natural. En China, se incluye dicha cerámica que es altamente cocida lo suficiente para producir un sonido de campana cuando se golpea. En Europa, se limita a cerámica-dura cocida, que es translúcida.',source:''},
                {id:'mat0040',name:'Seda',definition:'Textiles hechos de fibra de seda, que es una fibra derivada del capullo del gusano de seda.',source:''},
                {id:'mat0041',name:'Sílice',definition:'Forma dióxido del silicio, SiO2, presente en la arena de cuarzo, pizarra y ágata. Se utiliza fundamentalmente en forma de polvo blanco preparado para utilizarse en la fabricación de vidrios, cerámicas y abrasivos.',source:''},
                {id:'mat0042',name:'Tela',definition:'En general, textil tejido a telar, por enfieltrado, tejido a punto, machacado o transformado de otra manera en una pieza plana. Para textil en forma de hebras continuas fabricadas con filamentos de fibras mediante devanado, hilado, peinado o torcido, ver "hilo".',source:''},
                {id:'mat0043',name:'Terciopelo',definition:'Tejido a telar con pelo, con un pelo corto y denso producido por una urdimbre suplementaria que sobresale en lazadas sobre la superficie del textil mediante la introducción de varillas durante el tejido. Las lazadas se cortan o pueden dejarse sin cortar.',source:''},
                {id:'mat0044',name:'Tinta',definition:'Medio fluido utilizado para dibujos o trazos. Pigmento opaco, habitualmente negro, mezclado con un vehículo tal como agua para producir un fluido que puede ser aplicado con una pluma o una aguja. Hacia fines del siglo XIX, la tinta se suministraba seca, en barras o bloques, que eran molidos y mezclados con agua a medida que se necesitaba. A comienzos del siglo XX se hizo más popular la tinta preparada.',source:''},
                {id:'mat0045',name:'Vidrio',definition:'Sustancia amorfa inorgánica fabricada por fusión de sílice (dióxido de silicio) con un óxido básico; generalmente transparente, pero a menudo translúcido u opaco. Sus propiedades características son la dureza y rigidez a temperatura ordinaria, su capacidad para ser trabajada plásticamente a elevadas temperaturas y su resistencia a la acción atmosférica y a la mayoría de los productos químicos, excepto al ácido fluorhídrico. Utilizada para propósitos utilitarios y decorativos, puede dársele diferentes formas, colores o decoraciones. El vidrio se originó como vidriado en Mesopotamia aproximadamente en 3500 a.C., y los primeros objetos realizados enteramente de vidrio datan de aproximadamente 2500 a.C.',source:''},
                {id:'mat0046',name:'Yeso',definition:'Mineral blanco blando que existe en la naturaleza y es utilizado comúnmente como agente de retardo en el cemento portland, como centro en láminas de planchas de muro, como pigmento blanco, molido en pintura y como principal componente del alabastro.',source:''},
                {id:'mat0047',name:'Resina de poliester de fibra de vidrio',definition:'Resina',source:''},
                {id:'mat0048',name:'Hormigón',definition:'Un fuerte material duro de construcción que comprende una mezcla de arena, grava, piedra triturada u otro agregado unidos, por lo general por una pasta endurecida de cemento y o cal. Muchos varios tipos de agregados son utilizados como piedra triturada, escoria, cenizas o grava. Los antiguos romanos desarrollaron cemento puzolana sobre el 3er siglo a. C. Hormigones modernos utilizan diversos cementos tales como Portland o hidráulico. El hormigón es resistente y relativamente barato, utilizado para fundaciones, puentes, presas, muros y carreteras. El hormigón es resistente a la compresión, pero débil en tensión por lo que es a menudo reforzado con barras de acero o malla de alambre. Una vez que una mezcla de hormigón se agita con agua y se vierte en un molde, se deja fraguar lentamente durante alrededor de una semana. Destaca, como la vibración, congelación y el secado rápido, disminuirán la resistencia y durabilidad del hormigón. A medida que envejece, el concreto está sujeto a la erosión, desprendimientos y contaminación. Mezcla mala puede causar erosión. Grietas pueden ser debida a ciclos de congelación-descongelación de humedad y hielo, cristalización de sales o la corrosión de refuerzos de acero. La lluvia ácida puede agotar la reserva alcalina natural del concreto fresco.',source:''},
                {id:'mat0049',name:'Ladrillo',definition:'Arcilla o producto de arcilla formada en un bloque rectangular endurecido por medio del secado al sol o por medio de fuego en un horno de secar.',source:''},
                {id:'mat0050',name:'Pintura',definition:'Cualquier dispersión de pigmento en un aglutinante líquido. La pintura se aplica con un pincel, rodillo, pulverizador o por inmersión y se seca para formar una película decorativa o protectora.',source:''},
                {id:'mat0051',name:'Mineral',definition:'Elemento o compuesto inorgánico que se encuentra en la naturaleza y que tiene una estructura interna ordenada y una composición, forma cristalina y propiedades físicas características; úsese también para equivalentes sintéticamente derivados.',source:''},
                {id:'mat0052',name:'Fieltro',definition:'Textil fabricado habitualmente de lana y fibra de piel, a menudo mezclados con fibras naturales o sintéticas entrelazando las fibras sueltas mediante la acción del calor, la humedad, productos químicos y presión, sin hilado ni tejido de telar ni de punto.',source:''},
                {id:'mat0053',name:'Caucho',definition:'Jugo resinoso puro, lechoso, pegajoso, elástico, obtenido a partir de varias especies del género tropical Hevea y Ficus. Se coagula al exponerse al aire, se convierte altamente elástico, y es resistente al agua. Se distingue de "gutapercha" ya que ese caucho se debe calentar más tiempo para prepararlo. En el siglo XIX, el nombre "caucho" también se utiliza para un cemento a base de caucho comercial en la que se disuelve una pequeña cantidad del elastómero en un disolvente y se mezcla con masilla. Hoy en día, este material se denomina "látex".',source:''},
                {id:'mat0054',name:'Textil',definition:'Materiales producidos por tejido, anudado, torcido, o de otro modo el procesamiento de fibras naturales o sintéticas para que cohesionen en una forma o unidad; excluye tradicionalmente tableros de fibra, papel, cartón piedra, y papiro, que, aunque también son productos de fibra, se consideran como tipos distintos de material',source:'Tesauro de Arte & Arquitectura'},
                {id:'mat0055',name:'Cuerda',definition:'Objeto largo, delgado y flexible, toscamente cilíndrico, fabricado con material fibroso y habitualmente de varias hebras torcidas entre sí. El cordón es normalmente hecho de un tipo de fibra basta, como el algodón, yute, lino, ramio, cáñamo, sisal, Olona, Gravata, agel, widuri, caraguatá, u otros.',source:'Tesauro de Arte & Arquitectura'},
                {id:'mat0056',name:'Latex',definition:'Fluido viscoso, similar a la leche compuesto de resina o cera en forma de pequeños glóbulos en suspensión acuoso, obtenidos especialmente a partir de árboles de caucho; utilizado para muchas aplicaciones de caucho, tales como adhesivo. Use también para una dispersión acuosa de caucho sintético y de plástico gomoso.',source:'Tesauro de Arte y Arquitectura'}]
                try {

                    await updateDoc(doc(db, 'System', 'materiales'), {
                        datos: materiales
                    })
                } catch (e) {
                    console.log('error', e)
                }    
                
        }
    },
    getters: {
        items: state => {
            return state.items
        },
        abcItems: state => {
            let arr = []
            state.items.forEach(item => {
                let char = item.name.split('')[0]
                let exists = arr.find(el => el === char.toUpperCase())
                if (exists === undefined) {
                    arr.push(char.toUpperCase())
                }
            })
            return arr
        },
        itemsByIndex: state => payload => {
            let arr = []
            state.items.forEach(item => {
                let char = item.name.split('')[0]
                if (char.toUpperCase() === payload) {
                    if (item.definition.split('').length >= 200) {
                        arr.push({
                            ...item,
                            shortDefinition: item.definition.slice(0, 199),
                            isLong: true
                        })
                    } else {
                        arr.push({
                            ...item,
                            shortDefinition: item.definition,
                            isLong: false
                        })
                    }
                }
            })
            return arr
        },
        name: state => payload => {
            let item = state.items.find(el => el.id === payload)
            if (item) {
                return item.name
            } else {
                return ''
            }
        },
        statistics:state=>{//retorna lista de elementos ordenados de mayor a menor estadistica
            let arr=[]
            let pos=0
            state.items.forEach(item=>{
                pos=0
                arr.forEach(el=>{
                    if(el.estadistica>item.estadistica){
                        pos++
                    }
                })
                arr.splice(pos,0,item)
            })
            return arr
        }
    },
    mutations: {
        SET_ITEMS(state, payload) {
            state.items = payload.list
        },
        ADD_ITEMS(state, payload) {
            console.log(payload)
            state.items=payload.list
        },
        SUM_ONE_TO_STATISTIC(state,payload){
            let elem=state.items.find(item=>item.id===payload)
            if(elem){
                elem.estadistica=elem.estadistica+1
            }
        },
        CALC_PERCENTS(state){
            let max=0
            state.items.forEach(element=>{
                if(element.estadistica>max){
                    max=element.estadistica
                }
            })
            state.items.forEach(element=>{
                element.porcentaje=element.estadistica/max
            })
        },
        CLEAR_ITEMS(state) {
            state.items = []
        },
        REMOVE_ITEM_BY_ID(state, payload) {
            let index = state.items.findIndex(item => item.id === payload)
            if (index >= 0) {
                state.items.splice(index, 1)
            }
        },
        UPDATE_ITEM_NAME(state, payload) {
            let elem = state.items.find(item => item.id === payload.id)
            if (elem) {
                elem.name = payload.name
            }
        },
        UPDATE_ITEM_DEFINITION(state, payload) {
            let elem = state.items.find(item => item.id === payload.id)
            if (elem) {
                elem.name = payload.definition
            }
        },
        SET_ITEM_OBJECTS(state, payload) {
            let result = state.items.find(elem => elem.id === payload.id)
            if (result) {
                result.elements = payload.data
            }
        }
    }
}