<template>
  <div class="home">
    <div class="image-page">
      <div>
        <h1 v-html="'Gesti&oacute;n de Dep&oacute;sito'"></h1>
      </div>
      <h4 v-html="'Museo de Sitio Puerto Cristal'"></h4>
      <div></div>
    </div>
    <div class="tabs">
      <button
        v-html="'Gesti&oacute;n de Cajas'"
        :class="{ selected: tab === 1 }"
        @click="tab = 1"
      ></button>
      <button
        v-html="'Objetos Sin Ubicaci&oacute;n'"
        :class="{ selected: tab === 2 }"
        @click="tab = 2"
      ></button>
      <button
        v-html="'Objetos Fuera de Cajas'"
        :class="{ selected: tab === 3 }"
        @click="tab = 3"
      ></button>
    </div>
    <div class="container" v-show="tab === 1">
      <div class="section">
        <h3 v-html="'Ver contenido de cajas'" class="long"></h3>
        <div class="search-container long">
          <select name="boxes" id="boxes" v-model="sectionBox.box" v-if="boxes">
            <option value="none" v-html="'Seleccione la caja'"></option>
            <option v-for="item in boxes" :key="item.id" :value="item.id">
              {{ item.id }}
            </option>
          </select>
          <button
            :disabled="sectionBox.box === 'none'"
            @click="searchItems(sectionBox.box)"
          >
            <i class="material-icons">search</i>
          </button>
        </div>
      </div>
      <div
        class="section"
        v-if="sectionBox.box !== 'none' && objectsInBox.length > 0"
      >
        <div class="long icon-container">
          <div class="icon-to-label-btn">
            <div class="inner" @click="exportPDF">
              <p>imprimir</p>
              <i class="material-icons">print</i>
            </div>
          </div>
          <div
            class="icon-to-label-btn"
            :class="{ disabled: sectionBox.location }"
          >
            <div class="inner" @click="sectionBox.location = true">
              <p>editar</p>
              <i class="material-icons">edit</i>
            </div>
          </div>
        </div>
        <h3
          v-html="
            'Informaci&oacute;n de la caja <strong>' +
            sectionBox.box +
            '</strong>'
          "
          class="long"
        ></h3>
        <h5
          class="long"
          v-html="'Ubicaci&oacute;n de la caja en el dep&oacute;sito'"
        ></h5>
        <div class="long location" v-if="!sectionBox.location">
          <p
            v-if="
              sectionBox.currentLocation !== undefined &&
              sectionBox.currentLocation !== ''
            "
          >
            <strong v-html="'Ubicaci&oacute;n de la Caja: '"></strong>
            {{ sectionBox.currentLocation }}
          </p>
          <p
            v-if="
              sectionBox.currentLocation !== undefined &&
              sectionBox.currentLocation === ''
            "
          >
            <strong v-html="'Ubicaci&oacute;n de la Caja: '"></strong>
            <span style="text-style: italic">Desconocido</span>
          </p>
        </div>
        <div class="long location" v-if="sectionBox.location">
          <label v-html="'Ubicaci&oacute;n de la Caja'"></label>
          <textarea
            name="newLocation"
            v-model="sectionBox.newLocation"
            id="newLocation"
            rows="5"
          ></textarea>
          <p
            v-if="
              sectionBox.currentLocation !== undefined &&
              sectionBox.currentLocation !== ''
            "
          >
            <strong v-html="'Ubicaci&oacute;n de la Caja: '"></strong>
            {{ sectionBox.currentLocation }}
          </p>
          <p class="btn-right">
            <button
              class="ok-btn"
              :disabled="sectionBox.currentLocation === sectionBox.newLocation"
              @click="saveNewLocation"
            >
              Guardar
            </button>
            <button class="cancel-btn" @click="sectionBox.location = false">
              Cancelar
            </button>
          </p>
        </div>
        <h5 class="long" v-html="'Objetos al interior de la caja'"></h5>
        <div class="card-container long">
          <div class="card" v-for="item in objectsInBox" :key="item.id">
            <div
              class="card-img"
              v-if="item.image !== ''"
              :style="'background-image:url(' + item.image + ')'"
            ></div>
            <div class="card-img" v-else>
              <i class="material-icons">photo</i>
            </div>
            <div class="card-content">
              <h5>{{ item.name }}</h5>
              <p>{{ item.id }}</p>
              <p class="btns">
                <button
                  class="btn-container"
                  @click="$router.push('item/' + item.id)"
                >
                  <div class="btn">
                    <p>Ver Ficha</p>
                    <i class="material-icons">remove_red_eye</i>
                  </div>
                </button>
                <button
                  class="btn-container"
                  @click="confirmRemoveItemFromBox(item.id)"
                >
                  <div class="btn">
                    <p>Eliminar</p>
                    <i class="material-icons">delete</i>
                  </div>
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="section"
        v-else-if="sectionBox.box !== 'none' && objectsInBox.length === 0"
      >
        <h3
          v-html="
            'La caja <strong>' +
            sectionBox.box +
            '</strong> se encuentra vac&iacute;a'
          "
          class="long"
        ></h3>
      </div>
    </div>
    <div class="container" v-show="tab === 2">
      <div class="section">
        <h3
          v-html="'Objetos que se encuentran sin ubicaci&oacute;n definida'"
          class="long"
        ></h3>
        <p
          class="long"
          v-html="
            'En esta secci&oacute;n asigne una ubicaci&oacute;n a aquellos objetos el dep&oacute;sito que no han sido asignados.'
          "
        ></p>
        <div class="tabs long">
          <button
            v-html="'Ubicar en Cajas'"
            :class="{ selected: boxTab === 1 }"
            @click="boxTab = 1"
          ></button>
          <button
            v-html="'Ubicar en Dep&oacute;sito'"
            :class="{ selected: boxTab === 2 }"
            @click="boxTab = 2"
          ></button>
        </div>
        <div class="long" v-if="boxTab === 1">
          <p
            v-html="
              'En este apartado podr&aacute; agregar objetos sin ubicaci&oacute;n a las cajas ingresadas en el sistema. Para esto seleccione una caja, y los objetos que desea agregarle.'
            "
          ></p>
          <div class="search-container long">
            <select
              name="boxes"
              id="boxes"
              v-model="sectionAsign.box"
              v-if="boxes"
              @change="searchItems(sectionAsign.box)"
            >
              <option value="none" v-html="'Seleccione la caja'"></option>
              <option v-for="item in boxes" :key="item.id" :value="item.id">
                {{ item.id }}
              </option>
            </select>
          </div>
          <p
            v-if="objectsInBox.length > 0"
            v-html="
              'Objetos al interior de la caja <strong>' +
              sectionAsign.box +
              '</strong>'
            "
          ></p>
          <p
            v-else
            v-html="
              'La caja <strong>' +
              sectionAsign.box +
              '</strong> no tiene objetos en su interior'
            "
          ></p>
          <ul>
            <li v-for="item in objectsInBox" :key="item.id">
              <strong v-html="'C&oacute;digo Identificador: '"></strong>
              {{ item.id }},
              <strong v-html="'Nombre: '"></strong>
              {{ item.name }}
            </li>
          </ul>
          <p
            v-if="sectionAsign.selectedItems.length > 0"
            v-html="'Objetos seleccionados para ser agregados a la caja'"
          ></p>
          <ul>
            <li v-for="item in sectionAsign.selectedItems" :key="item.id">
              <strong v-html="'C&oacute;digo Identificador: '"></strong>
              {{ item.id }},
              <strong v-html="'Nombre: '"></strong>
              {{ item.name }}
            </li>
          </ul>
          <p class="btn-right">
            <button
              class="ok-btn"
              :disabled="
                sectionAsign.selectedItems.length === 0 ||
                sectionAsign.box === 'none'
              "
              @click="addObjectsToBox"
            >
              Guardar Cambios
            </button>
          </p>
          <p
            v-html="'Seleccione los objetos que quiere agregar a esta caja'"
          ></p>
        </div>
        <div class="long" v-else-if="boxTab === 2">
          <p
            v-html="
              'En este apartado podr&aacute; indicar la ubicaci&oacute;n de los objetos que est&aacute;n fuera de cajas al interior del dep&oacute;sito. Seleccione un objeto y detalle su ubicaci&oacute;n'
            "
          ></p>
          <label v-html="'Ubicaci&oacute;n del objeto'"></label>
          <textarea
            name="location"
            v-model="sectionAsign.location"
            id="location"
            rows="5"
          ></textarea>
          <p class="btn-right">
            <button
              class="ok-btn"
              :disabled="
                sectionAsign.item === '' || sectionAsign.location === ''
              "
              @click="setObjectLocation"
            >
              Guardar Cambios
            </button>
          </p>
          <p v-html="'Seleccione el objeto que desea ubicar'"></p>
        </div>
        <div class="card-container long">
          <div class="card" v-for="item in objectsOutOfBox" :key="item.id">
            <div
              class="card-img"
              :style="'background-image:url(' + item.image + ')'"
              v-if="item.image"
            ></div>
            <div
              class="card-img"
              :style="'background-image:url(' + item.image + ')'"
              v-else
            >
              <i class="material-icons">insert_photo</i>
            </div>
            <div class="card-content">
              <h5>{{ item.name }}</h5>
              <p>{{ item.id }}</p>
            </div>
            <div
              class="hover"
              :class="{ locked: isItemSelected(item.id) }"
              @click="selectItem(item)"
            >
              <div v-if="isItemSelected(item.id)">
                <i class="material-icons">check_box</i>
                <h5>Seleccionado</h5>
              </div>

              <div v-else>
                <i class="material-icons">check_box_outline_blank</i>
                <h5>Seleccionar</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container" v-show="tab === 3">
      <div class="section">
        <h3 v-html="'Objetos sin guardar en cajas'" class="long"></h3>
        <PageViewer
          :list="singleObjects"
          :setup="{
            link: false,
            iconPosition: 'right',
            buttons: [
              {
                label: 'Ver Ficha',
                icon: 'remove_red_eye',
                action: 'openLink',
              },
            ],
          }"
          v-on:response="actionOutOfBox"
        />
      </div>
    </div>
    <Loading text="cargando" />
    <Message />
  </div>
</template>

<script>
// @ is an alias to /src
import Loading from "@/components/Loading.vue";
import Message from "@/components/Message.vue";
import PageViewer from "@/components/PageViewer.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import app from "@/firebase";
import {
  getFirestore,
  doc,
  updateDoc,
  writeBatch,
} from "firebase/firestore";
const db = getFirestore(app);
export default {
  name: "deposit-section",
  components: {
    Loading,
    Message,
    PageViewer,
  },
  data: function () {
    return {
      sectionBox: {
        box: "none",
        removeItem: "",
        location: false,
        currentLocation: "",
        newLocation: "",
      },
      sectionAsign: {
        box: "none",
        selectedItems: [], //array of ids and names
        item: "",
        location: "",
      },
      tab: 1,
      boxTab: 1,
    };
  },
  computed: {
    ...mapGetters([
      "boxes",
      "objectsInBox",
      "objectsOutOfBox",
      "getMessageAction",
      "objectsAlone",
    ]),
    singleObjects() {
      let arr = [];
      this.objectsAlone.forEach((item) => {
        arr.push({
          id: item.id,
          name: item.name,
          image: item.image,
          content: {
            title: "Ubicaci&oacute;n del Objeto",
            content:
              "<strong>Ubicaci&oacute;n: </strong>" +
              item.location.label +
              "<br>" +
              "<strong>Habitaci&oacute;n: </strong>" +
              item.location.value,
          },
        });
      });
      return arr;
    },
  },
  watch: {
    objectsInBox() {},
    getMessageAction(payload) {
      if (payload.component === "addObjectsToBox") {
        if (payload.action === 1) {
          this.SET_MESSAGE_PARAMETERS({});
        } else if (payload.action === -1) {
          this.SET_MESSAGE_PARAMETERS({});
        }
      } else if (payload.component === "deleteOjbectFromBox") {
        if (payload.action === 1) {
          this.$store.dispatch("getObjectsInBox", this.sectionBox.box);
          this.$store.dispatch("getBoxesOutOfBox");
          this.SET_MESSAGE_PARAMETERS({});
        } else if (payload.action === -1) {
          this.SET_MESSAGE_PARAMETERS({});
        }
      } else if (payload.component === "confirmDeleteOjbectFromBox") {
        if (payload.action === 1) {
          this.removeItemFromBox(this.sectionBox.removeItem);
          this.sectionBox.removeItem = "";
        } else if (payload.action === -1) {
          this.sectionBox.removeItem = "";
          this.SET_MESSAGE_PARAMETERS({});
        }
      }
    },
    itemsPerPage() {
      this.currentPage = 1;
    },
  },
  methods: {
    ...mapActions(["getBoxesOutOfBox", "getObjectsInBox"]),
    ...mapMutations([
      "SET_LOADING",
      "SET_LOADING_OPACITY",
      "SET_MESSAGE_PARAMETERS",
      "SET_BOX_LOCATION",
      "REMOVE_ITEM_OUT_OF_BOX",
      "SET_CURRENT_BOX",
    ]),
    async removeItemFromBox(payload) {
      this.SET_LOADING(true);
      this.SET_LOADING_OPACITY(true);
      const batch = writeBatch(db);
      batch.update(doc(db, "ListaObjetos", payload), {
        location: "",
      });
      batch.delete(
        doc(db, "Sitios/boxes/boxes/" + this.sectionBox.box + "/items", payload)
      );
      await batch.commit();
      this.SET_LOADING(false);
      this.SET_MESSAGE_PARAMETERS({
        isVisible: true,
        type: "success",
        icon: "done",
        title: "Objeto eliminado de la Caja",
        message:
          "El objeto se ha eliminado de la caja <strong> " +
          this.sectionBox.box +
          " </strong> correctamente",
        buttonAction: "",
        button: "Aceptar",
        respondTo: "deleteOjbectFromBox",
        action: 0,
      });
    },
    confirmRemoveItemFromBox(payload) {
      this.sectionBox.removeItem = payload;
      this.SET_MESSAGE_PARAMETERS({
        isVisible: true,
        type: "alert",
        icon: "warning",
        title: "&iquest;Desea eliminar este objeto?",
        message:
          "&iquest;Confirma que desea eliminar el objeto <strong> " +
          this.sectionBox.removeItem +
          " </strong> de la caja <strong> " +
          this.sectionBox.box +
          "?</strong>",
        buttonAction: "Confirmar",
        button: "Cancelar",
        respondTo: "confirmDeleteOjbectFromBox",
        action: 0,
      });
    },
    searchItems(payload) {
      this.SET_LOADING(true);
      this.SET_LOADING_OPACITY(true);
      const loc = this.boxes.find((item) => item.id === this.sectionBox.box);
      if (loc !== undefined) {
        this.sectionBox.currentLocation = loc.location;
        this.sectionBox.newLocation = loc.location;
      } else {
        this.sectionBox.currentLocation = "";
        this.sectionBox.newLocation = "";
      }
      this.SET_CURRENT_BOX(payload);
      this.SET_LOADING(false);
    },
    selectItem(payload) {
      if (this.boxTab === 1) {
        if (
          this.sectionAsign.selectedItems.find(
            (item) => item.id === payload.id
          ) === undefined
        ) {
          this.sectionAsign.selectedItems.push(payload);
        } else {
          let i = this.sectionAsign.selectedItems.findIndex(
            (item) => item.id === payload.id
          );
          this.sectionAsign.selectedItems.splice(i, 1);
        }
      } else {
        this.sectionAsign.item = payload;
      }
    },
    isItemSelected(payload) {
      if (this.boxTab === 1) {
        if (
          this.sectionAsign.selectedItems.find(
            (item) => item.id === payload
          ) === undefined
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        if (this.sectionAsign.item.id === payload) {
          return true;
        } else {
          return false;
        }
      }
    },
    async addObjectsToBox() {
      this.SET_LOADING(true);
      this.SET_LOADING_OPACITY(true);
      let batch = writeBatch(db);
      this.sectionAsign.selectedItems.forEach((item) => {
        if (item.imageName !== undefined && item.image !== undefined) {
          batch.set(
            doc(
              db,
              "Sitios/boxes/boxes/" + this.sectionAsign.box + "/items",
              item.id
            ),
            {
              name: item.name,
              imageUrl: {
                name: item.imageName,
                url: item.image,
              },
            }
          );
        } else {
          batch.set(
            doc(
              db,
              "Sitios/boxes/boxes/" + this.sectionAsign.box + "/items",
              item.id
            ),
            {
              name: item.name,
            }
          );
        }
        batch.update(doc(db, "ListaObjetos", item.id), {
          location: {
            label: "Caja",
            value: this.sectionAsign.box,
          },
        });
      });
      await batch.commit();
      this.sectionAsign.selectedItems.forEach((item) => {
        this.REMOVE_ITEM_OUT_OF_BOX(item.id);
      });
      this.getBoxesOutOfBox();
      this.getObjectsInBox(this.sectionAsign.box);
      this.SET_MESSAGE_PARAMETERS({
        isVisible: true,
        type: "success",
        icon: "done",
        title: "Objetos agregados a la Caja",
        message:
          "Los objetos seleccionados se han agregado a la Caja <strong> " +
          this.sectionAsign.box +
          " </strong>",
        buttonAction: "",
        button: "Aceptar",
        respondTo: "addObjectsToBox",
        action: 0,
      });
      this.sectionAsign.selectedItems = [];
      this.SET_LOADING(false);
    },
    async saveNewLocation() {
      try {
        this.SET_LOADING(true);
        this.SET_LOADING_OPACITY(true);
        await updateDoc(doc(db, "Sitios/boxes/boxes", this.sectionBox.box), {
          location: this.sectionBox.newLocation,
        });
        this.SET_BOX_LOCATION(this.sectionBox.newLocation);
        this.sectionBox.currentLocation = this.sectionBox.newLocation;
        this.sectionBox.location = false;
        this.SET_LOADING(false);
      } catch (e) {
        this.SET_MESSAGE_PARAMETERS({
          isVisible: true,
          type: "error",
          icon: "error",
          title: "Ocurri&oacute; un error inesperado",
          message:
            "Ocurri&oacute; un error inesperado, por favor intente m&aacute;s tarde",
          buttonAction: "",
          button: "Aceptar",
          respondTo: "error",
          action: 0,
        });
        this.SET_LOADING(false);
        console.log("error", e);
      }
    },
    exportPDF() {
      this.$router.push("/print/box/" + this.sectionBox.box);
    },
    async setObjectLocation() {
      await updateDoc(doc(db, "ListaObjetos", this.sectionAsign.item.id), {
        location: {
          label: "Depósito",
          value: this.sectionAsign.location,
        },
      });
      this.SET_OBJECT_LOCATION({
        id: this.sectionAsign.item.id,
        location: {
          label: "Depósito",
          value: this.sectionAsign.location,
        },
      });
    },
    actionOutOfBox(payload) {
      if (payload.action === "openLink") {
        this.$router.push("item/" + payload.id);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/sass/variables";
@import "../assets/sass/buttons";
@import "../assets/sass/cards";
.home {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 70vh auto;
  grid-gap: 10px;
  width: 100vw;
  .image-page {
    background-image: linear-gradient(
        to bottom,
        transparent,
        rgba(0, 0, 0, 0.75)
      ),
      url("../assets/media/home.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: grid;
    grid-template-rows: calc(100% - 170px) 50px 120px;
    h1,
    h4 {
      position: relative;
      text-align: center;
      padding-left: 10%;
      padding-right: 10%;
      margin-block-end: 0px;
      margin-block-start: 0px;
    }
    div {
      padding-left: 10%;
      padding-right: 10%;
      position: relative;
      h1 {
        text-align: center;
        display: block;
        width: 100%;
        bottom: 0%;
        left: 50%;
        margin-bottom: 0px;
        position: absolute;
        transform: translateX(-50%);
      }
    }
    h1 {
      color: $theme-l4;
      vertical-align: text-bottom;
    }
    h4 {
      color: wheat;
    }
    div {
      padding: 25px 10% 45px 10%;
      div {
        display: grid;
        grid-template-columns: auto 60px;
        border-radius: 50px;
        background-color: white;
        padding: 0px;
        overflow: hidden;
        input {
          height: 50px;
          border: none;
          margin-block-start: 0%;
          margin-block-end: 0%;
          margin-left: 20px;
          cursor: text;
        }
        button {
          margin-block-end: 0px;
          margin-block-start: 0px;
          background-color: $theme;
          border: none;
          cursor: pointer;
          i.material-icons {
            vertical-align: middle;
            font-size: 35px;
            color: white;
          }
        }
        button:disabled {
          opacity: 0.5;
          cursor: no-drop;
        }
      }
      .options {
        display: inline-block;
        position: relative;
        width: calc(100% - 80px);
        margin-left: 20px;
        height: 0px;
        z-index: 5 !important;
        overflow: visible;
        margin-top: 0px;
        height: fit-content;
        ul {
          background-color: white;
          border: solid 1px $text;
          margin-block-start: 0px;
          margin-block-end: 0px;
          padding-inline-start: 0px;
          li {
            padding-left: 10px;
            line-height: 27px;
            color: $text;
            margin-block-start: 0px;
            margin-block-end: 0px;
            padding: 3px 0px;
            cursor: pointer;
            list-style: none;
          }
          li.selected,
          li:hover {
            background-color: $theme;
            color: white;
            font-weight: 500;
          }
        }
      }
    }
  }
  .tabs {
    widows: 100%;
    background-color: transparent;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    button {
      background-color: transparent;
      border: none;
      border-bottom: solid 3px transparent;
      color: $text;
      font-size: 18px;
      font-weight: 300;
      padding-top: 10px;
      padding-bottom: 10px;
    }
    button.selected,
    button:hover {
      color: $theme;
      border-bottom-color: $theme;
    }
  }
  .tabs.long {
    grid-template-columns: 1fr 1fr;
  }
  .container {
    margin-top: 15px;
    display: grid;
    grid-gap: 10px;
    padding: 0px 5%;
    .section {
      border-radius: 25px;
      background-color: white;
      padding: 15px;
      display: block;
      grid-gap: 10px;
      .search-container {
        width: 100%;
        display: grid;
        grid-template-columns: auto 40px;
        grid-gap: 5px;
        button {
          background-color: $theme;
          color: white;
          border: none;
          border-radius: 50px;
          width: 40px;
          margin-block-start: 0px;
          margin-block-end: 0px;
          height: 40px;
          i.material-icons {
            vertical-align: middle;
          }
        }
        button:disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }
      .long {
        grid-column-start: 1;
        grid-column-end: 3;
      }
      .long.location {
        display: grid;
        p.edit {
          text-align: right;
          button {
            border: none;
            background-color: transparent;
            cursor: pointer;
            color: $text;
            span {
              font-size: 18px;
              font-weight: 200;
              text-decoration: underline;
              margin-left: 3px;
              height: 100%;
              vertical-align: middle;
            }
            i.material-icons {
              font-size: 28px;
              vertical-align: middle;
            }
          }
        }
        textarea {
          width: 100%;
        }
      }
    }
  }
}
@media (min-width: 768px) {
  .home {
    .section {
      .search-container {
        width: 80%;
      }
    }
    .tabs {
      padding: 15px 10%;
    }
    .container {
      padding: 15px 10%;
      .section {
        .long {
          grid-column-start: 1;
          grid-column-end: 4;
        }
      }
    }
  }
}
</style>
