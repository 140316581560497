<template>
  <div class="home">
    <div class="search">
      <div>
        <h1 v-html="'PLANTA HIDROEL&Eacute;CTRICA'"></h1>
      </div>
      <h4 v-html="'Sector Industrial'"></h4>
    </div>
    <div class="section color1 split left">
      <div class="full-height"><div class="image" :style="{'background-image': 'url(https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F4%20hidroelectrica%2FPLANTA%20HIDROELECTRICA.jpg?alt=media&token=a234765a-77ff-4466-a1ad-b51132b12579&_gl=1*hhzmt*_ga*NjAzNTQwNTkxLjE2ODU3MjcyNzM.*_ga_CW55HF8NVT*MTY4NjE4NzYzMS4xMi4xLjE2ODYxOTA1OTMuMC4wLjA.)'}"></div></div>
      <div>
        <div class="content">
          <h1 v-html="'TURBINA HIDROEL&Eacute;CTRICA'"></h1>
          <p v-html="'La turbina hidr&aacute;ulica produc&iacute;a energ&iacute;a gracias a la fuerza del agua. El agua era tomada de un arroyo en lo alto de la monta&ntilde;a, en un sector llamado “El Fonteche”, y era desviada por un canal, de aproximadamente un kil&oacute;metro de largo, que desembocaba en una peque&ntilde;a represa (compuerta). En ese lugar, se encauzaba el agua por una tuber&iacute;a hasta llegar a la planta hidroel&eacute;ctrica. Debido a la altura, el agua llegaba a la planta con mucha presi&oacute;n. Al ser liberada, generaba una gran fuerza que lograba mover la turbina hidr&aacute;ulica, la que a su vez hac&iacute;a girar el generador, transformando as&iacute; la energ&iacute;a mec&aacute;nica en energ&iacute;a el&eacute;ctrica. '"></p>     
        </div>
      </div>
    </div>
    
    <div class="section color2 split right">
      <div class="full">
        <div class="content img">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F4%20hidroelectrica%2FLABORATORIO%20Y%20TALLER%20-25.jpg?alt=media&token=6ffad67b-b8a4-4649-8dd8-a0a62d0c25d0&_gl=1*rndesj*_ga*NjAzNTQwNTkxLjE2ODU3MjcyNzM.*_ga_CW55HF8NVT*MTY4NjE4NzYzMS4xMi4xLjE2ODYxOTA3NzQuMC4wLjA."
            alt=""
            class="right"
          />
          <h1 v-html="'GENERADOR DIESEL'"></h1>
          <p v-html="'El sistema secundario para generar energ&iacute;a funcionaba a petr&oacute;leo y alimentaba a dos motores que, en conjunto, produc&iacute;an la energ&iacute;a mec&aacute;nica. Esta energ&iacute;a hac&iacute;a girar el generador, que transformaba la energ&iacute;a mec&aacute;nica en energ&iacute;a el&eacute;ctrica. La funci&oacute;n de este sistema secundario era la de suplantar a la turbina principal, cuando hab&iacute;a que hacerle mantenciones o cuando el caudal del agua era insuficiente para que funcionara adecuadamente. '"></p>
        
        </div>
      </div>
    </div>

    <div class="section split left">
      <div class="full-height"><div class="image" :style="{'background-image': 'url(https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F4%20hidroelectrica%2FDSC05067.jpg?alt=media&token=782a8c7e-e155-4953-a883-8c1452fc256e)'}"></div></div>
      <div>
        <div class="content">
          <h1 v-html="'CARGA DE BATER&Iacute;AS'"></h1>
          <p v-html="'En la planta hidroel&eacute;ctrica se cargaban y manten&iacute;an las bater&iacute;as que alimentaban las linternas de los cascos de los mineros, que eran entregadas todos los d&iacute;as antes del inicio de las faenas. Los mineros se las colocaban en el cintur&oacute;n y las conectaban al casco mediante un cable. Cada bater&iacute;a pesaba entre dos y tres kilos.  Por su parte, la estanter&iacute;a donde se cargaban las bater&iacute;as ten&iacute;a capacidad para cargar ciento veinte unidades y, para que estuvieran en un &oacute;ptimo funcionamiento, se les hac&iacute;a todo tipo de mantenciones como cambio de &aacute;cido o a&ntilde;adir agua destilada. '"></p>
        </div>
      </div>
    </div>

    <div class="section color1 split right">
      
      <div>
        <div class="content">
          <h1 v-html="'TURBINA HIDROEL&Eacute;CTRICA SECUNDARIA'"></h1>
          <p v-html="'Debido al incremento de la poblaci&oacute;n, se construy&oacute; posteriormente en Puerto Cristal otra peque&ntilde;a planta hidroel&eacute;ctrica. Se aprovech&oacute; el paso del agua por la turbina principal y  se canaliz&oacute; por debajo de la planta hidroel&eacute;ctrica principal, haci&eacute;ndola llegar a una piscina. All&iacute; se encauz&oacute; hacia la segunda planta hidroel&eacute;ctrica que se encontraba a unos cincuenta metros m&aacute;s abajo, al nivel del Lago General Carrera. Gracias a esta diferencia de altura se consegu&iacute;a la presi&oacute;n necesaria para producir electricidad. '"></p>
        </div>
      </div>
      <div class="full-height"><div class="image" :style="{'background-image': 'url(https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F4%20hidroelectrica%2Fturbina%202.jpg?alt=media&token=05596f89-4bf4-4c99-bf28-cb3745b0b0bf)'}"></div></div>
    </div>
    <div class="section">
          <h1 v-html="'Fotograf&iacute;as'"></h1>
          <div class="gallery">
            <div
              class="item"
              v-for="(item, index) in images"
              :key="index"
              :style="'background-image:url(' + item.url + ')'"
            >
              <div class="hover" @click="openGallery(index)">
                <div>
                  <i class="material-icons">fullscreen</i>
                  <h5>{{ item.name }}</h5>
                </div>
              </div>
            </div>
        
      </div>
    </div>
    <Loading text="cargando" />
    <Message />
    <Gallery/>
  </div>
</template>
<script>
// @ is an alias to /src
import Loading from "@/components/Loading.vue";
import Message from "@/components/Message.vue";

import Gallery from "@/components/Gallery.vue";

import { mapMutations } from "vuex";
export default {
  name: "Home-page",
  components: {
    Loading,
    Message,
    Gallery
  },
  data: function () {
    return {
      col: [
        {
          link: "https://memoriaaustral.cl/",
          imagen: "../../../assets/media/puerto_cristal/MINA.JPG",
          title: "Mina",
          content:
            "Corporaci&oacute;n Cultural Memoria Austral nace en el a&ntilde;o 2013, como corporaci&oacute;n sin fines de lucro, con el objetivo de velar por el rescate y conservaci&oacute;n de la memoria de Ays&eacute;n, desde todos los &aacute;mbitos del patrimonio, favoreciendo la salvaguarda de la identidad regional.",
        },
        {
          link: "https://www.museoregionalaysen.gob.cl/",
          imagen: "../../assets/media/puerto_cristal/LABORATORIO.JPG",
          title: "Laboratorio",
          content:
            "El Museo Regional de Ays&eacute;n es una instituci&oacute;n perteneciente al Servicio Nacional del Patrimonio Cultural. Su apertura a la comunidad se produjo el a&ntilde;o 2018, luego de un largo trabajo de restauraci&oacute;n y habilitaci&oacute;n del Monumento Hist&oacute;rico Construcciones de la Sociedad Industrial de Ays&eacute;n y la construcci&oacute;n de un moderno edificio que alberga la Exposici&oacute;n Permanente Regional, Dep&oacute;sito de Colecciones, Auditorio y Oficinas de Trabajo.",
        },
        {
          link: "https://web.facebook.com/groups/310271558203",
          imagen:
            "https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Flogo_cristalinos.jpg?alt=media&token=e1e5fb66-43fb-4e98-8ddb-2717b54878f2",
          title: "Agrupaci&oacute;n Social y Cultural Los Cristalinos",
          content:
            "La Agrupaci&oacute;n Social y Cultural Los Cristalinos es una instituci&oacute;n que congrega a los antiguos habitantes del Campamento Minero Puerto Cristal, y que con su actuar, busca mantener viva su historia e identidad ligada a este Monumento Nacional.",
        },
      ],
      images: [{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F4%20hidroelectrica%2FLABORATORIO%20Y%20TALLER%20-02.jpg?alt=media&token=8cfb136b-1ebf-416c-8989-e45dcba56096&_gl=1*ok0lhj*_ga*MTIyNTY4ODAyOC4xNjg1NzMyODQy*_ga_CW55HF8NVT*MTY4NjE3MDA0OC4xMi4xLjE2ODYxNzA4OTAuMC4wLjA.',name:'Exterior Taller Eléctrico',position:0},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F4%20hidroelectrica%2FLABORATORIO%20Y%20TALLER%20-09.jpg?alt=media&token=72627da2-e954-4c3b-a0cd-13ef3605eaba&_gl=1*9zizi*_ga*MTIyNTY4ODAyOC4xNjg1NzMyODQy*_ga_CW55HF8NVT*MTY4NjE3MDA0OC4xMi4xLjE2ODYxNzA4OTkuMC4wLjA.',name:'Exterior Taller Eléctrico',position:1},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F4%20hidroelectrica%2FLABORATORIO%20Y%20TALLER%20-25.jpg?alt=media&token=6ffad67b-b8a4-4649-8dd8-a0a62d0c25d0&_gl=1*1qa1xq6*_ga*MTIyNTY4ODAyOC4xNjg1NzMyODQy*_ga_CW55HF8NVT*MTY4NjE3MDA0OC4xMi4xLjE2ODYxNzA5MDQuMC4wLjA.',name:'Motores',position:2},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F4%20hidroelectrica%2FLABORATORIO%20Y%20TALLER%20-36.jpg?alt=media&token=e631870e-e5ef-4361-84b4-480f80354b81&_gl=1*12ywykb*_ga*MTIyNTY4ODAyOC4xNjg1NzMyODQy*_ga_CW55HF8NVT*MTY4NjE3MDA0OC4xMi4xLjE2ODYxNzA5MDcuMC4wLjA.',name:'Motores',position:3},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F4%20hidroelectrica%2FLABORATORIO%20Y%20TALLER%20-38.jpg?alt=media&token=fbb6e62a-6a82-41bb-be91-b7ace75c39a1&_gl=1*t6ksm5*_ga*MTIyNTY4ODAyOC4xNjg1NzMyODQy*_ga_CW55HF8NVT*MTY4NjE3MDA0OC4xMi4xLjE2ODYxNzA5MTAuMC4wLjA.',name:'Pizarra',position:4},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F4%20hidroelectrica%2FLABORATORIO%20Y%20TALLER%20-24.jpg?alt=media&token=24813922-cbdf-40c4-949d-8456dfcb0b37&_gl=1*1l7k5f0*_ga*MTIyNTY4ODAyOC4xNjg1NzMyODQy*_ga_CW55HF8NVT*MTY4NjE3MDA0OC4xMi4xLjE2ODYxNzA5MDEuMC4wLjA.',name:'Tuercas y Tornillos',position:5},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F4%20hidroelectrica%2FLABORATORIO%20Y%20TALLER%20-39.jpg?alt=media&token=95b0ae8d-5418-492d-aa12-c44cd3a41025&_gl=1*ls992t*_ga*MTIyNTY4ODAyOC4xNjg1NzMyODQy*_ga_CW55HF8NVT*MTY4NjE3MDA0OC4xMi4xLjE2ODYxNzA5MTQuMC4wLjA.',name:'Turbina',position:6}
],
    };
  },
  created() {
    this.SET_LOADING(false);
  },
  methods: {
    ...mapMutations([
      "SET_LOADING",
      "SET_GALLERY",
      "SET_GALLERY_INDEX",
      "SET_GALLERY_ITEMS",
    ]),
    openGallery(payload) {
      let pos = this.images.findIndex((item) => item.position === payload);
      let el = this.images[pos];
      let arr = this.images;
      arr.splice(pos, 1);
      arr.splice(0, 0, el);
      this.SET_GALLERY_ITEMS(arr);
      this.SET_GALLERY_INDEX(payload);
      this.SET_GALLERY(true);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/sass/variables";
@import "../../assets/sass/buttons";
@import "../../assets/sass/cards";

.home {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 70vh auto;
  width: 100vw;
  div.autocomplete-container {
    padding: 25px 13em 45px 3em;
    div {
      display: grid;
      grid-template-columns: auto 60px;
      grid-template-rows: 50px;
      border-radius: 50px;
      background-color: white;
      padding: 0px;
      overflow: visible;
      .radius-left {
        border-bottom-left-radius: 50px;
        border-top-left-radius: 50px;
      }
      .radius-right {
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
      }

      button {
        margin-block-end: 0px;
        margin-block-start: 0px;
        background-color: $theme;
        border: none;
        cursor: pointer;
        i.material-icons {
          vertical-align: middle;
          font-size: 35px;
          color: white;
        }
      }
      button:disabled {
        opacity: 0.5;
        cursor: no-drop;
      }
    }
  }
  .search {
    background-image: linear-gradient(
        to bottom,
        transparent,
        rgba(0, 0, 0, 0.75)
      ),
      url("https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F3%20laboratorio%2FLABORATORIO%20Y%20TALLER%20-07.jpg?alt=media&token=2857223b-ad1c-44b2-b7ae-23b4f0b36f62&_gl=1*y6890h*_ga*NjAzNTQwNTkxLjE2ODU3MjcyNzM.*_ga_CW55HF8NVT*MTY4NjE4NzYzMS4xMi4xLjE2ODYxODkzNDYuMC4wLjA.");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: grid;
    grid-template-rows: calc(100% - 220px) 50px 120px 50px;
    h1,
    h4 {
      position: relative;
      text-align: center;
      padding-left: 10%;
      padding-right: 10%;
      margin-block-end: 0px;
      margin-block-start: 0px;
    }
    div.search-options {
      align-content: center;
      input[type="radio"] {
        margin-block-start: 5px;
        margin-block-end: 5px;
        height: 20px;
        width: 20px;
        margin-right: 5px;
        display: inline-block;
        vertical-align: middle;
      }
      label {
        color: white;
        display: inline-block;
        margin-right: 30px;
        margin-block-end: 0px;
        margin-block-start: 0px;
        vertical-align: middle;
        margin-top: 0px;
        line-height: 20px;
      }
    }
    div {
      padding-left: 10%;
      padding-right: 10%;
      position: relative;
      h1 {
        text-align: center;
        display: block;
        width: 100%;
        bottom: 0%;
        left: 50%;
        margin-bottom: 0px;
        position: absolute;
        transform: translateX(-50%);
      }
    }
    h1 {
      font-size: 3em;
      font-weight: 700;
      color: $theme-l4;
      vertical-align: text-bottom;
    }
    h4 {
      font-size: 2em;
      color: wheat;
    }
  }
  .section {
    position: relative;
    padding: 0.5em 2em;
    display: grid;
    grid-template-rows: auto;
    gap: 1em;
    
      
    h1 {
            font-size: 3em;
            font-weight: 700;
            margin-block-start: 0;
            margin-block-end: 0;
          }
          p {
            font-size: 1.1em;
            font-weight: 300;
            text-align: justify;
          }
    &.color1 {
      background-color: $blue-grey;
      h1,
      h2,
      h3,
      h4,
      h5 {
        color: $grey-2;
      }
      p,
      li {
        color: $grey-1;
      }
    }
    &.color2 {
      background-color: $grey-1;
      h1,
      h2,
      h3,
      h4,
      h5 {
        color: $theme-d1;
      }
      p,
      li {
        color: $theme-d3;
      }
    }

    &.color3 {
      background-color: $theme-l4;
      h1,
      h2,
      h3,
      h4,
      h5 {
        color: $theme-d2;
      }
      p,
      li {
        color: $blue-grey-dark;
      }
    }
    &.split,
    &.full {
      height: 100%;
      div {
        &.full {
          grid-column-start: 1;
          grid-column-end: 3;
        }
        position: relative;
        height: 100% !important;
        display: block;
        .title,
        .content {
          width: 100%;
          display: block;
          height: fit-content;
          &.img {
            img.left {
              border-radius: 1em;
              width: 100%;
            }
            img.right {
              border-radius: 1em;
              width: 100%;
            }
          }
          &.multy {
            display: grid;
          }
          h1 {
            font-size: 3em;
            font-weight: 700;
            margin-block-start: 0;
            margin-block-end: 0;
          }
          p {
            font-size: 1.1em;
            font-weight: 300;
            text-align: justify;
          }
          
        }
        .image {
          border-radius: 1em;
          width: 100%;
          padding-top: 100%;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          &#img1 {
            background-image: url("https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F1%20mina%2FLastScan%20TYUI.jpg?alt=media&token=a22f582f-788c-48b4-b1b2-4a826a056bd4&_gl=1*anfvst*_ga*NjAzNTQwNTkxLjE2ODU3MjcyNzM.*_ga_CW55HF8NVT*MTY4NTg0MDU5My41LjEuMTY4NTg0MDYwMS4wLjAuMA..");
          }
          &#img2 {
            background-image: url("https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F1%20mina%2FMINA%20-40.jpg?alt=media&token=ade126b1-af45-4eb7-a212-3cf1e6d13b9f&_gl=1*lf7w9i*_ga*NjAzNTQwNTkxLjE2ODU3MjcyNzM.*_ga_CW55HF8NVT*MTY4NTkxNTczMy43LjEuMTY4NTkxNjU0OS4wLjAuMA..");
          }
          &#img3 {
            background-image: url("https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F1%20mina%2FLastScan%20106.jpg?alt=media&token=996f6d57-a11f-48b8-a920-405597c273dd&_gl=1*1gbuw9u*_ga*NjAzNTQwNTkxLjE2ODU3MjcyNzM.*_ga_CW55HF8NVT*MTY4NTkxNTczMy43LjEuMTY4NTkxNjgwMC4wLjAuMA..");
          }
        }
      }
    }
    .gallery {
            display: grid;
            grid-gap: 0.8em;
            grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
            position: relative;
            padding-bottom: 2em;
            .item {
              padding-top: 100%;
              background-size: cover;
              background-repeat: no-repeat;
              background-position: center;
              position: relative;
              border-radius: 5px;
              overflow: hidden;
              .hover {
                height: 100%;
                width: 100%;
                position: absolute;
                background-color: rgba($color: #000000, $alpha: 0.65);
                top: 0%;
                margin-top: 0%;
                opacity: 0;
                div {
                  width: 100%;
                  margin-top: calc(50% - 32px);
                  i.material-icons {
                    font-size: 35px;
                    color: whitesmoke;
                    text-align: center;
                    width: 100%;
                    vertical-align: middle;
                  }
                  h5 {
                    color: whitesmoke;
                    text-align: center;
                  }
                }
              }
              .hover:hover {
                cursor: pointer;
                opacity: 1;
              }
            }
          }
  }
}
@media (min-width: 768px) {
  .home {
    grid-template-rows: 75vh auto;
    .search {
      .video-container {
        video {
          min-height: 100%;
          min-width: 100%;
        }
      }
      .content {
        margin-left: 5em !important;
      }
    }
    .section {
      padding: 3em 5em;
      gap: 0.5em 2em;
      &.split {
        &.left {
          grid-template-columns: 1.5fr 2.5fr;
        }
        &.right {
          grid-template-columns: 2.5fr 1.5fr;
        }
        .content {
          &.img {
            img.left {
              border-radius: 1em;
              float: left;
              max-width: 40%;
              margin-right: 2em;
            }
            img.right {
              border-radius: 1em;
              float: right;
              max-width: 60%;
              margin-left: 2em;
            }
          }
        }
      }
    }
  }
}
@media (min-width: 992px) {
  .home {
    grid-template-rows: 75vh auto;
    .search {
      .video-container {
        video {
          min-height: 100%;
          min-width: 100%;
        }
      }
      .content {
        margin-left: 11em !important;
      }
    }
    .section {
      padding: 3em 13em;
      &.split {
        &.left {
          grid-template-columns: 1.5fr 2.5fr;
        }
        &.right {
          grid-template-columns: 2.5fr 1.5fr;
        }
      }
    }
  }
}
</style>
