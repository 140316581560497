<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import app from "@/firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";
const auth =  getAuth(app);

export default {
  name: "app",
  created: function () {
    onAuthStateChanged(auth, async (user) => {
      if (await user) {
        await this.autoSignIn(user);
      }
    });
    let pdf = document.createElement("script");
    pdf.setAttribute("src", "https://documentcloud.adobe.com/view-sdk/main.js");
    document.head.appendChild(pdf);
  },
  mounted: function () {
    this.loadData();
    let pdf = document.createElement("script");
    pdf.setAttribute("src", "https://documentcloud.adobe.com/view-sdk/main.js");
    document.head.appendChild(pdf);
  },
  methods: {
    ...mapActions([
      "getCategories",
      "autoSignIn",
      "getSites",
      "getInitialItems",
      "getCollectionData",
      "getSize",
      "user",
      "setConjunto",
      "getAllObjects",
      "updateIndexList",
      "getNextValue",
      "getSystemData"
    ]),
    ...mapGetters(["user", "listIncomplitObjects"]),
    ...mapActions("materials", {
      getMaterials: "getItems",
    }),
    ...mapActions("techniques", {
      getTechniques: "getItems",
    }),
    ...mapActions("typologies", {
      getGlossary: "getItems",
    }),
    ...mapActions("keywords", {
      getKeyWords: "getItems",
    }),
    ...mapActions("glossary", {
      getDefiniciones: "getItems",
    }),
    ...mapActions("sitios", {
      getSites: "getSites",
    }),
    ...mapActions("archive", {
      getFondos: "getFondos",
      getPublishedFondos: "getPublishedFondos",
      cargarDocumentos: "publishAll",
    }),
    loadData: async function () {
      await this.getSystemData()
      await this.getCategories();
      await this.getGlossary();
      await this.getTechniques();
      await this.getMaterials();
      await this.getKeyWords();
      await this.getInitialItems();
      await this.getSites();
      await this.getCollectionData();
      await this.getSize();
      await this.getDefiniciones();
      await this.getNextValue()
      if (this.user() !== null) {
        this.getFondos();
      } else {
        this.getPublishedFondos();
      }
      //this.cargarDocumentos()
    },
  },
};

</script>

<style lang="scss">
@import "./assets/sass/variables";
body {
  background-color: whitesmoke;
}
</style>
