
import materials from './modules/materials'
import typologies from './modules/typologies'
import techniques from './modules/techniques'
import componentsBehavior from './modules/componentsBehavior'
import authentication from './modules/authentication'
import colecciones from './modules/collection'
import categories from './modules/categories'
import keywords from './modules/keywords'
import archive from './modules/archive'
import glossary from './modules/glossary'
import sitios from './modules/sites'

import print from './modules/print'
import { createStore } from 'vuex'
import app from '@/firebase'
import {
  getFirestore,
  collection,
  doc,
  query,
  getDoc,
  getDocs,
  where
} from "firebase/firestore";
const db = getFirestore(app)
const store = createStore({
  modules: {
    materials,
    techniques,
    componentsBehavior,
    authentication,
    typologies,
    colecciones,
    categories,
    keywords,
    archive,
    glossary,
    sitios,print
  },
  state: {
    message: {
      title: '',
      message: '',
      button: '',
      isVisible: false,
      action: ''
    },
    filter: {
      objectId: 'DC0003',
      collectionId: ''
    },
    objectData: null,
    sites: [],
    lastItems: [],
    boxes: [],
    objectsInBox: [],
    objectsOutOfBox: [],
    searchResult: [],
    objectsInDB: [],
    profileDisplayed: false,
    menuDisplayed: false,
    objectsInDeposit: [],
    currentBox: '',
    system:[]
  },
  mutations: {
    ADD_SYSTEM_DATA:function (store, payload) {
      store.system = payload
    }, 
    SET_ERROR_MESSAGE: function (store, payload) {
      store.errorMessage = payload
    },
    SET_ERROR_MESSAGE_STATE: function (store, payload) {
      store.errorMessage.state = payload
    },
    SET_SUCCESS_MESSAGE_PARAMETERS(store, payload) {
      store.successMessage = payload
    },
    SET_SUCCESS_MESSAGE_STATE(store, payload) {
      store.successMessage.isVisible = payload
    },
    SET_MESSAGE_PARAMETERS(store, payload) {
      store.message = payload
    },
    SET_MESSAGE_STATE(store, payload) {
      store.message.isVisible = payload
    },
    SET_MESSAGE_ACTION(store, payload) {
      store.message.action = payload
    },
    UPDATE_LAST_VALUE(store, payload) {
      const i = store.categories.findIndex(doc => doc.id === payload.id)
      store.categories[i].last = payload.newValue
    },
    SET_CURRENT_OBJECT_ID(store, payload) {
      store.filter.objectId = payload
    },
    SET_CURRENT_OBJECT_DATA(store, payload) {
      store.objectData = payload.data
      store.objectData.id = payload.id
      store.objectData.images = payload.images
    },
    ADD_SITE(store, payload) {
      var pos = 0;
      var added = false;
      if (store.sites.find(doc => doc.id === payload.id) !== undefined) {
        return;
      } else if (payload.id === 'boxes') {
        return;
      } else {
        store.sites.forEach(val => {
          if (added) {
            return;
          } else {
            if (
              val.name
              .toLowerCase()
              .localeCompare(payload.name.toLowerCase()) === -1
            ) {
              pos++;
            } else if (
              val.name
              .toLowerCase()
              .localeCompare(payload.name.toLowerCase()) === 1
            ) {
              store.sites.splice(pos, 0, payload);
              added = true;
              return;
            } else {
              pos++;
              return;
            }
          }
        });
        if (!added) {
          store.sites.push(payload);
        }
      }
    },
    ADD_ROOM_TO_SITE(store, payload) {
      const site = store.sites.find(doc => doc.id === payload.siteId)
      if (site !== undefined) {
        const room = site.rooms.find(room => room.id === payload.roomId)
        if (room === undefined) {
          site.rooms.push({
            id: payload.roomId,
            name: payload.name,
            boxes: []
          })
        }
      }
    },
    ADD_BOX_TO_ROOM(store, payload) {
      const site = store.sites.find(doc => doc.id === payload.siteId)
      if (site !== undefined) {
        const room = site.rooms.find(room => room.id === payload.roomId)
        if (room !== undefined) {
          const box = room.boxes.find(box => box.id === payload.boxId)
          if (box === undefined) {
            room.boxes.push({
              id: payload.boxId,
              name: payload.boxId,
              items: []
            })
          }
        }
      }
    },
    ADD_ITEM_TO_BOX(store, payload) {
      const site = store.sites.find(doc => doc.id === payload.siteId)
      if (site !== undefined) {
        const room = site.rooms.find(room => room.id === payload.roomId)
        if (room !== undefined) {
          const box = room.boxes.find(box => box.id === payload.boxId)
          if (box !== undefined) {
            const item = box.items.find(item => item.id === payload.itemId)
            if (item === undefined)
              box.items.push({
                id: payload.itemId,
                name: payload.name
              })
          }
        }
      }
    },
    ADD_NEW_LAST_ITEM(store, payload) {
      const obj = store.lastItems.find(elem => elem.id === payload.id)
      if (obj === undefined) {
        let data = payload.data
        data.id = payload.id
        data.images = payload.images
        store.lastItems.push(data)
      }
    },
    ADD_BOX(store, payload) {
      if (store.boxes.find(doc => doc.id === payload.id) === undefined) {
        if (payload.location !== undefined) {

          store.boxes.push({
            id: payload.id,
            location: payload.data().location
          })
        } else {
          store.boxes.push({
            id: payload.id,
            location: ''
          })
        }
      }
    },
    ADD_OBJECT_IN_BOX(store, payload) {
      if (store.objectsInBox.find(item => item.id === payload.id) === undefined) {
        store.objectsInBox.push(payload)
      }
    },
    RESET_OBJECT_IN_BOX(store) {
      store.objectsInBox = []
    },
    ADD_OBJECT_OUT_OF_BOX(store, payload) {
      if (store.objectsOutOfBox.find(item => item.id === payload.id) === undefined) {
        store.objectsOutOfBox.push(payload)
      }
    },
    RESET_SEARCH_RESULT(store) {
      store.searchResult = []
    },
    ADD_OBJECT_TO_SEARCH_RESULT(store, payload) {
      if (store.searchResult.find(item => item.id === payload.id) === undefined) {
        store.searchResult.push(payload)
      }
    },
    SET_BOX_LOCATION(store, payload) {
      if (store.boxes.find(item => item.id === payload.id) !== undefined) {
        store.boxes.find(item => item.id === payload.id).location = payload.location
      }
    },
    RESET_OBJECTS_IN_DB(store) {
      store.objectsInDB = []
    },
    ADD_OBJECT_IN_DB(store, payload) {
      if (store.objectsInDB.find(item => item.id === payload.id) === undefined) {
        store.objectsInDB.push(payload)
      }
    },
    SET_PROFILE_DISPLAYED(store, payload) {
      store.profileDisplayed = payload
    },
    SET_MENU_DISPLAYED(store, payload) {
      store.menuDisplayed = payload
    },
    REMOVE_ITEM_OUT_OF_BOX(store, payload) {
      if (store.objectsOutOfBox.find(item => item.id === payload) !== undefined) {
        const i = store.objectsOutOfBox.findIndex(item => item.id === payload)
        store.objectsOutOfBox.splice(i, 1)
      }
    },
    ADD_OBJECT_IN_DEPOSIT(store, payload) {
      if (store.objectsInDeposit.find(item => item.id === payload.id) === undefined) {
        store.objectsInDeposit.push(payload)
      }
    },
    SET_CURRENT_BOX(store, payload) {
      store.currentBox = payload
    },
    SET_OBJECT_LOCATION(store, payload) {
      if (store.objectsInDeposit.find(item => item.id === payload.id) !== undefined) {
        let data = store.objectsInDeposit.find(item => item.id === payload.id)
        data.location = payload.location
      }
    }
  },
  actions: {
    async getSystemData({
      commit
    }){
      let item = await getDoc(doc(db, 'system', 'system'))
      
        commit('ADD_SYSTEM_DATA', item.data())
    },

    async getBoxes({
      commit
    }) {
      let sq = await getDocs(collection(db, 'Sitios/boxes/boxes'))
      sq.forEach(item => {
        commit('ADD_BOX', item)
      })
    },
    async getObjectsInBox({
      commit
    }, payload) {
      try {
        commit('RESET_OBJECT_IN_BOX')
        let sq = await getDocs(collection(db, 'Sitios/boxes/boxes/' + payload + '/items'))
        sq.forEach(async el => {
          let item = await getDoc(doc(db, 'ListaObjetos', el.id))
          if (item.data().referenceImage.url !== undefined) {
            commit('ADD_OBJECT_IN_BOX', {
              id: item.id,
              name: item.data().name,
              image: item.data().referenceImage.url
            })
          } else {
            commit('ADD_OBJECT_IN_BOX', {
              id: item.id,
              name: item.data().name,
              image: ''
            })
          }
        })

      } catch (e) {
        console.log('error', e)
      }
    },
    async getBoxesOutOfBox({
      commit
    }) {
      try {
        let sq = await getDocs(query(collection(db, 'ListaObjetos'), where('exhibition', '==', "No")))
        sq.forEach(item => {
          if (item.data().location === undefined || item.data().location === '') {
            if (item.data().referenceImage) {
              commit('ADD_OBJECT_OUT_OF_BOX', {
                id: item.id,
                name: item.data().name,
                image: item.data().referenceImage.url,
                imageName: item.data().referenceImage.name
              })
            } else {
              commit('ADD_OBJECT_OUT_OF_BOX', {
                id: item.id,
                name: item.data().name
              })
            }
          }
        })
      } catch (e) {
        console.log('error', e)
      }
    },
    async getObjectsInDeposit({
      commit
    }) {
      try {
        let sq = await getDocs(query(collection(db, 'ListaObjetos'), where('exhibition', '==', "No")))
        sq.forEach(item => {
          let data = {
            id: item.id,
            name: item.data().name,
          }
          if (item.data().referenceImage) {
            data.image = item.data().referenceImage.url,
              data.imageName = item.data().referenceImage.name
          }
          if (item.data().location === undefined || item.data().location === '') {
            data.location = ''
          } else {
            data.location = item.data().location
          }
          commit('ADD_OBJECT_IN_DEPOSIT', data)
        })
      } catch (e) {
        console.log("error", e)
      }
    },
  },
  getters: {
    systemData:state=>{
      return state.system
    },
    profileDisplayed: state => {
      return state.profileDisplayed
    },
    menuDisplayed: state => {
      return state.menuDisplayed
    },
    searchResult: state => {
      return state.searchResult
    },
    subCategories: state => id => {
      return state.categories.find(item => item.id === id).subCategories
    },
    errorMessage: state => {
      return state.errorMessage
    },
    successMessage: state => {
      return state.successMessage
    },
    message: state => {
      return state.message
    },
    getMessageAction: state => {
      return {
        component: state.message.respondTo,
        action: state.message.action
      }
    },
    currentObjectData: state => {
      return state.objectData
    },
    sites: state => {
      return state.sites
    },
    boxes: state => {
      return state.boxes
    },
    objectsInBox: state => {
      return state.objectsInDeposit.filter(item => item.location !== '' && item.location.value === state.currentBox)
    },
    objectsOutOfBox: state => {
      return state.objectsInDeposit.filter(item => item.location === '')
    },
    objectsAlone: state => {
      return state.objectsInDeposit.filter(item => item.location !== '' && item.location.label === 'Deposito')
    },
    allObjectsInDB: state => {
      return state.objectsInDB
    },
    objectsByClasification: state => {
      let arr = []
      state.objectsInDB.forEach(item => {
        let value = arr.find(elem => elem.clasification === item.clasification)
        if (value) {
          value.objects.push(item)
        } else {
          arr.push({
            clasification: item.clasification,
            objects: [item]
          })
        }
      })
      return arr;
    }
  }
})

export default store