<template>
  <div class="home">
    <div class="search">
      <div>
        <h1 v-html="'VIVIENDA'"></h1>
      </div>
      <h4 v-html="'Sector Campamento'"></h4>
    </div>
    <div class="section color1 split left">
      <div class="full">
        <div class="content img">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F7%20vivienda%2FFACHADA%20VIVIENDA%20OBREROS%20-02.jpg?alt=media&token=dc0058c2-b31d-4fe3-8564-f4c6a823ad8f&_gl=1*1xyfna*_ga*NjAzNTQwNTkxLjE2ODU3MjcyNzM.*_ga_CW55HF8NVT*MTY4NjUxNTEzMi4yMS4xLjE2ODY1MTgxMDkuMC4wLjA."
            alt=""
            class="left-40"
          />
          <h1 v-html="'VIVIENDA'"></h1>
          <p v-html="'Uno de los atractivos que ofrec&iacute;a el lugar para quienes buscaban trabajo en la regi&oacute;n, era la posibilidad de tener una vivienda gratuita con los servicios b&aacute;sicos, como agua y electricidad. Las viviendas eran de buena calidad y ofrec&iacute;an confort, contaban con dormitorios, &aacute;rea com&uacute;n con cocina a le&ntilde;a y ba&ntilde;o interior. '"></p>
        </div>
      </div>
    </div>
    
    <div class="section color2 split right">
      <div class="full">
        <div class="content img">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F7%20vivienda%2FCASINO%20OBREROS.jpg?alt=media&token=2aec2693-b756-4d15-92d9-d0a39f1c4c6d&_gl=1*16yw0d9*_ga*NjAzNTQwNTkxLjE2ODU3MjcyNzM.*_ga_CW55HF8NVT*MTY4NjUxNTEzMi4yMS4xLjE2ODY1MTkwNzAuMC4wLjA."
            alt=""
            class="right-60"
          />
          <h1 v-html="'CASINOS'"></h1>
          <p v-html="'En Puerto Cristal funcionaban dos casinos: el casino de obreros y el casino de empleados. La funci&oacute;n principal de estos era dar alojamiento y alimentaci&oacute;n a los trabajadores solteros que llegaban para trabajar en la empresa. Los trabajadores se divid&iacute;an acorde a una jerarqu&iacute;a: jefatura, empleados y obreros. En el casino de obreros viv&iacute;an principalmente los mineros y en el casino de empleados viv&iacute;an todos aquellos trabajadores que desempe&ntilde;aban funciones administrativas o relacionadas con otro tipo de profesiones. '"></p>
<p v-html="'La ubicaci&oacute;n de los dos edificios dentro del campamento tenia relaci&oacute;n con esta jerarqu&iacute;a. El casino de empleados se encontraba en un sector c&eacute;ntrico del campamento, frente a la cancha de f&uacute;tbol y la multi-cancha, mientras que el casino de obreros se encontraba ubicado en la parte alta de Puerto Cristal. '"></p>
<p v-html="'Cabe destacar que los espacios comunes de los dos casinos cumplieron una funci&oacute;n social, ya que eran lugares donde se reun&iacute;a la comunidad para las fiestas u otro tipo de celebraciones, como Navidad, Fiestas Patrias o el D&iacute;a del Minero. '"></p>
<p v-html="'El casino de obreros era una edificaci&oacute;n de dos pisos que contaba con aproximadamente veintis&eacute;is habitaciones y ten&iacute;a una capacidad para cincuenta personas. Normalmente dorm&iacute;an un trabajador por habitaci&oacute;n, pero con el tiempo llegaron a alojar dos. En el primer piso, se encontraban dormitorios, ba&ntilde;o com&uacute;n con duchas y el sal&oacute;n-comedor. El casino de empleados contaba con dieciocho habitaciones, con ba&ntilde;os independientes que eran compartidos cada dos habitaciones. El sal&oacute;n- comedor del casino de empleados ofrec&iacute;a un espacio de ocio en el que se encontraba una barra de bar, una mesa de pool y una mesa de pingpong, en el se celebraron diferentes fiestas. '"></p>
<img src="https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F7%20vivienda%2FCASINO%20EMPLEADOS%20-%2005.jpg?alt=media&token=78034b97-fc01-4c77-965f-d92bd78f31e8&_gl=1*1auja47*_ga*NjAzNTQwNTkxLjE2ODU3MjcyNzM.*_ga_CW55HF8NVT*MTY4NjUxNTEzMi4yMS4xLjE2ODY1MTkxNTEuMC4wLjA." alt="" class="left-60">
<p v-html="'Desde el punto de vista arquitect&oacute;nico, los casinos reflejaban una manera propia de construir en Puerto Cristal, con materiales y t&eacute;cnicas diferentes de las construcciones del resto de la regi&oacute;n. Estas construcciones incluyeron el uso de la piedra. La abundancia del granito existente en el lugar hizo llevar a canteros de la zona central, especializados en el labrado de la roca, para que le dieran forma c&uacute;bica o rectangular. Posteriormente, estos mampuestos de piedra eran utilizados para construir cimientos y paredes exteriores del primer piso. Dependiendo de las edificaciones, algunas ten&iacute;an el granito a la vista, se pintaban de otros colores o se encalaban con la misma cal que fabricaban en Cristal. Estas construcciones en mamposter&iacute;a fueron combinadas en su estructura de segundo piso y techumbre con madera nativa de lenga y &ntilde;ire, siendo ambas cubiertas revestidas con tejuela (pieza de madera con forma plana y rectangular utilizada para revestimientos). Posteriormente, las cubiertas de tejuela se sustituyeron o recubrieron con planchas de zinc. '"></p>

        </div>
      </div>
    </div>

    <div class="section split left">
      <div class="full">
        <div class="content img">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F7%20vivienda%2FDJI_0192.jpg?alt=media&token=18943198-8f0c-4738-8a12-482b06a2f918"
            alt=""
            class="right-60"
          />
          <h1 v-html="'VIVIENDAS'"></h1>
          <p v-html="'En general, todas las viviendas estaban construidas en madera y eran de muy buena calidad. Se les entregaba a los trabajadores completamente amobladas y no ten&iacute;an que pagar los servicios b&aacute;sicos de la luz y el agua y adem&aacute;s contaban con ba&ntilde;o en su interior. Estas condiciones de habitabilidad no eran comunes para el resto de la regi&oacute;n. '"></p>
<p v-html="'Cuando alg&uacute;n trabajador soltero, ya fuera empleado u obrero, se casaba o llevaba a su familia a Puerto Cristal, la empresa le prove&iacute;a de una vivienda. En ese momento, el trabajador desocupaba la habitaci&oacute;n del casino y se iba a vivir a una de las viviendas para obreros o para empleados. Adem&aacute;s de estas viviendas, estaban las viviendas para la jefatura o casas de administraci&oacute;n. Cada una de estas construcciones ten&iacute;a sus propias caracter&iacute;sticas. '"></p>
<p v-html="'Las viviendas para los administradores eran las construcciones m&aacute;s grandes. Eran casas aisladas, de dos pisos, con chimenea de piedra, ladrillo y concreto y ten&iacute;an desde dos a tres habitaciones. Las viviendas de empleados eran similares, pero no contaban con chimenea. Las viviendas de obreros eran casas un poco m&aacute;s peque&ntilde;as, de uno o dos pisos, con una o dos habitaciones. En algunos casos se constru&iacute;an casas pareadas y en otros, casas aisladas. '"></p>

        </div>
      </div>
    </div>

    <div class="section color1 split right">
      <div class="full">
        <div class="content img">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F7%20vivienda%2Fsii%20VIVIENDAS%20OBREROS%20-02.jpg?alt=media&token=824cfc8a-273e-4285-9ed2-fba56bdb16c3"
            alt=""
            class="left-60"
          />
          <h1 v-html="'PABELLONES'"></h1>
          <p v-html="'Los pabellones fueron las primeras viviendas que se construyeron en Puerto Cristal, ubic&aacute;ndose a un costado de la cancha de f&uacute;tbol y la multi-cancha. Con el pasar de los a&ntilde;os, se fue cambiando el uso original destinado a los pabellones, quedando &uacute;nicamente uno de los bloques como vivienda. '"></p>
<p v-html="'Al igual que los casinos, estas edificaciones ten&iacute;an sus cimientos y paredes de granito, sobre los cuales se levantaba el segundo piso de madera. Cada uno de estos pabellones ten&iacute;a cuatro viviendas pareadas. '"></p>

        </div>
      </div>
      
    </div>
    <div class="section color2 split right">
      <div class="full">
        <div class="content img">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F7%20vivienda%2FDSC04568.jpg?alt=media&token=cbc5f3ba-6d9f-4fdf-975c-0b92b847346e"
            alt=""
            class="right-60"
          />
          <h1 v-html="'CASA DE HU&Eacute;SPEDES'"></h1>
          <p v-html="'La casa de hu&eacute;spedes se construy&oacute; con el objetivo de prestar alojamiento a las visitas importantes y a los profesionales que llegaban de paso a Puerto Cristal. Normalmente esta casa recib&iacute;a a ingenieros y ge&oacute;logos, tanto chilenos como extranjeros, quienes llegaban a realizar estudios por un periodo de tiempo determinado. En la casa de hu&eacute;spedes se alojaban tambi&eacute;n los contadores de la Corporaci&oacute;n de Fomento de la Producci&oacute;n (CORFO), que iban a realizar auditoria, y autoridades de la &eacute;poca. Por esta raz&oacute;n, la casa de hu&eacute;spedes fue construida en un punto alto del campamento, con grandes ventanales hacia el lago, una chimenea de granito, ladrillo refractario y concreto y habitaciones grandes y espaciosas. Los pisos y las paredes en general eran de madera y en el hall de entrada el piso era de piedra. '"></p>
<p v-html="'Se construyeron dos casas de hu&eacute;spedes, la primera fue una construcci&oacute;n en madera con grandes ventanales que, en el a&ntilde;o 1953, se destruy&oacute; debido a un temporal. El viento levant&oacute; la casa y parte de ella fue arrastrada hasta la orilla. Ese mismo a&ntilde;o se reconstruy&oacute; en el mismo lugar la nueva casa de hu&eacute;spedes. '"></p>
<p v-html="'Como an&eacute;cdota, se cuenta que la noche en que la casa de hu&eacute;spedes fue arrasada por el temporal, hab&iacute;a dos personas en su interior, una dorm&iacute;a y la otra estaba en el ba&ntilde;o. La persona que estaba durmiendo vol&oacute; y cay&oacute; junto con la casa, pero s&oacute;lo tuvo lesiones leves. Por otra parte, la persona que estaba sentada en la taza del ba&ntilde;o, en vez de mirar las cuatro paredes, se qued&oacute; de un momento a otro, muerta de fr&iacute;o mirando al lago. '"></p>
<p v-html="'En el a&ntilde;o 2013, la casa de hu&eacute;spedes fue recuperada y habilitada por la familia Casanova para recibir visitantes, con cuatro habitaciones y dos ba&ntilde;os compartidos, contando con una capacidad para ocho personas. '"></p>
</div>
      </div>
    </div>
    <div class="section">
          <h1 v-html="'Fotograf&iacute;as'"></h1>
          <div class="gallery">
            <div
              class="item"
              v-for="(item, index) in images"
              :key="index"
              :style="'background-image:url(' + item.url + ')'"
            >
              <div class="hover" @click="openGallery(index)">
                <div>
                  <i class="material-icons">fullscreen</i>
                  <h5>{{ item.name }}</h5>
                </div>
              </div>
            </div>
        
      </div>
    </div>
    <Loading text="cargando" />
    <Message />
    <Gallery/>
  </div>
</template>
<script>
// @ is an alias to /src
import Loading from "@/components/Loading.vue";
import Message from "@/components/Message.vue";

import Gallery from "@/components/Gallery.vue";

import { mapMutations } from "vuex";
export default {
  name: "Home-page",
  components: {
    Loading,
    Message,
    Gallery
  },
  data: function () {
    return {
      
      images: [{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F7%20vivienda%2FLIVING%203.jpg?alt=media&token=165e057f-c986-4123-b6d6-1ad8fd8bc91f',name:'Casa de Huéspedes',position:0},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F7%20vivienda%2FIMG_9120.jpg?alt=media&token=d2f1c4ac-5020-4547-8053-7d403b565bd6',name:'Casa de Huéspedes',position:1},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F7%20vivienda%2FDSC04568.jpg?alt=media&token=cbc5f3ba-6d9f-4fdf-975c-0b92b847346e',name:'Casa de Huéspedes',position:2},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F7%20vivienda%2FDSC00538-2.jpg?alt=media&token=645b7f98-0058-4d35-8557-54da1db9b0fb',name:'Casa de Huéspedes',position:3},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F7%20vivienda%2FBREAKFAST-LAPTOP-GAN3QP2I.jpg?alt=media&token=4b1533fe-d89c-436f-b5d8-1d8757924546',name:'Casa de Huéspedes',position:4},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F7%20vivienda%2FCASINO%20EMPLEADOS.jpg?alt=media&token=d6dfd543-fd6d-49e6-b2ad-2e8a8dd2b81e',name:'Casino de Empleados',position:5},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F7%20vivienda%2FCASINO%20EMPLEADOS%20-%2015.jpg?alt=media&token=250917d6-e813-4c2c-b69d-003613eee61c',name:'Casino de Empleados',position:6},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F7%20vivienda%2FCASINO%20EMPLEADOS%20-%2005.jpg?alt=media&token=78034b97-fc01-4c77-965f-d92bd78f31e8',name:'Casino de Empleados',position:7},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F7%20vivienda%2FCASINO%20EMPLEADOS%20-%2001.jpg?alt=media&token=8dce9015-ffb5-4d51-aca4-4e6b13dae5b2',name:'Casino de Empleados',position:8},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F7%20vivienda%2FCASINO%20OBREROS.jpg?alt=media&token=2aec2693-b756-4d15-92d9-d0a39f1c4c6d',name:'Casino de Obreros',position:9},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F7%20vivienda%2FCASINO%20OBREROS%20-17.jpg?alt=media&token=42240304-1e14-43be-a02c-2a4c279a5bd8',name:'Casino de Obreros',position:10},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F7%20vivienda%2FCASINO%20OBREROS%20-16.jpg?alt=media&token=8d66c55d-8dba-4e19-82b2-9adae87f96f9',name:'Casino de Obreros',position:11},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F7%20vivienda%2FCASINO%20OBREROS%20-11.jpg?alt=media&token=dfc92805-a156-4b8f-abbd-3a96efd39dda',name:'Casino de Obreros',position:12},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F7%20vivienda%2FCASINO%20OBREROS%20-06.jpg?alt=media&token=17e31650-b584-44a2-b2fa-1edd007e24df',name:'Casino de Obreros',position:13},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F7%20vivienda%2FCASINO%20OBREROS%20-02.jpg?alt=media&token=63977bc1-5094-4a69-a013-b5085a0fb3ae',name:'Casino de Obreros',position:14},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F7%20vivienda%2FVIVIENDAS%20EMPLEADOS%20-018.jpg?alt=media&token=b7c0ebed-8fff-4604-9d72-c81687cd4fc6',name:'Interior Vivienda Empleados',position:15},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F7%20vivienda%2FVIVIENDA%20EMPLEADOS%20-%2012.jpg?alt=media&token=9fe7c98b-7f9e-448c-943a-5d1d72c644ee',name:'Interior Vivienda Empleados',position:16},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F7%20vivienda%2FVIVIENDAS%20OBREROS%20-03.jpg?alt=media&token=5e842257-bc13-4ee2-a70f-4c0e7827e108',name:'Pabellones',position:17},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F7%20vivienda%2FVIVIENDAS%20OBREROS%20-06.jpg?alt=media&token=66737864-6cb0-47ba-b99e-eba4a21442ac',name:'Pabellones',position:18},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F7%20vivienda%2FVIVIENDAS%20OBREROS%20-08.jpg?alt=media&token=a443d341-db39-4011-ad6f-36709fdae6fe',name:'Pabellones',position:19},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F7%20vivienda%2FVIVIENDAS%20OBREROS%20-13.jpg?alt=media&token=b2b62ef7-4f31-4170-a29d-1b3cdac4f680',name:'Pabellones',position:20},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F7%20vivienda%2FVIVIENDAS%20OBREROS%20-18.jpg?alt=media&token=8235c727-6615-4e90-b68d-817bd8c64e9d',name:'Pabellones',position:21},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F7%20vivienda%2Fsii%20VIVIENDAS%20OBREROS%20-02.jpg?alt=media&token=824cfc8a-273e-4285-9ed2-fba56bdb16c3',name:'Pabellones',position:22},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F7%20vivienda%2FVIVIENDA%20EMPLEADOS%20-%2002.jpg?alt=media&token=53ff1155-3b25-485b-800d-e74a8d546984',name:'Vivienda Empleados',position:23},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F7%20vivienda%2FFACHADA%20VIVIENDA%20OBREROS%20-18.jpg?alt=media&token=0c74e647-711b-4818-b27d-a2fb96794b38',name:'Vivienda Obrera',position:24},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F7%20vivienda%2FFACHADA%20VIVIENDA%20OBREROS%20-12.jpg?alt=media&token=c70501d2-97eb-4588-9754-28040fd6277f',name:'Vivienda Obrera',position:25},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F7%20vivienda%2FFACHADA%20VIVIENDA%20OBREROS%20-02.jpg?alt=media&token=dc0058c2-b31d-4fe3-8564-f4c6a823ad8f',name:'Vivienda Obrera',position:26},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F7%20vivienda%2FVIVIENDAS%20OBRERAS%20-03.jpg?alt=media&token=979d2325-9a79-4138-8966-483d180f6fd7',name:'Viviendas Obreras',position:27},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F7%20vivienda%2Fsi%20%20FACHADA%20VIVIENDA%20OBREROS%20-20.jpg?alt=media&token=bdc1a66c-4185-4a66-bd11-c250c994c4cd',name:'Viviendas Obreras',position:28},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F7%20vivienda%2Fsi%20VIVIENDAS%20OBRERAS%20-01.jpg?alt=media&token=6a458772-aa6b-44d3-a27a-027960508b26',name:'Viviendas Obreras',position:29}
],
    };
  },
  created() {
    this.SET_LOADING(false);
  },
  methods: {
    ...mapMutations([
      "SET_LOADING",
      "SET_GALLERY",
      "SET_GALLERY_INDEX",
      "SET_GALLERY_ITEMS",
    ]),
    openGallery(payload) {
      let pos = this.images.findIndex((item) => item.position === payload);
      let el = this.images[pos];
      let arr = this.images;
      arr.splice(pos, 1);
      arr.splice(0, 0, el);
      this.SET_GALLERY_ITEMS(arr);
      this.SET_GALLERY_INDEX(payload);
      this.SET_GALLERY(true);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/sass/variables";
@import "../../assets/sass/buttons";
@import "../../assets/sass/cards";
@import "../../assets/sass/modal";

.home {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 70vh auto;
  width: 100vw;
  div.autocomplete-container {
    padding: 25px 13em 45px 3em;
    div {
      display: grid;
      grid-template-columns: auto 60px;
      grid-template-rows: 50px;
      border-radius: 50px;
      background-color: white;
      padding: 0px;
      overflow: visible;
      .radius-left {
        border-bottom-left-radius: 50px;
        border-top-left-radius: 50px;
      }
      .radius-right {
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
      }

      button {
        margin-block-end: 0px;
        margin-block-start: 0px;
        background-color: $theme;
        border: none;
        cursor: pointer;
        i.material-icons {
          vertical-align: middle;
          font-size: 35px;
          color: white;
        }
      }
      button:disabled {
        opacity: 0.5;
        cursor: no-drop;
      }
    }
  }
  .search {
    background-image: linear-gradient(
        to bottom,
        transparent,
        rgba(0, 0, 0, 0.75)
      ),
      url("https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2Fsi%20VIVIENDAS%20OBRERAS%20-01.jpg?alt=media&token=422132b8-d93f-4263-81de-88c78b2d78a8&_gl=1*kauhmc*_ga*NjAzNTQwNTkxLjE2ODU3MjcyNzM.*_ga_CW55HF8NVT*MTY4NjUxNTEzMi4yMS4xLjE2ODY1MTc5OTYuMC4wLjA.");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: grid;
    grid-template-rows: calc(100% - 220px) 50px 120px 50px;
    h1,
    h4 {
      position: relative;
      text-align: center;
      padding-left: 10%;
      padding-right: 10%;
      margin-block-end: 0px;
      margin-block-start: 0px;
    }
    div.search-options {
      align-content: center;
      input[type="radio"] {
        margin-block-start: 5px;
        margin-block-end: 5px;
        height: 20px;
        width: 20px;
        margin-right: 5px;
        display: inline-block;
        vertical-align: middle;
      }
      label {
        color: white;
        display: inline-block;
        margin-right: 30px;
        margin-block-end: 0px;
        margin-block-start: 0px;
        vertical-align: middle;
        margin-top: 0px;
        line-height: 20px;
      }
    }
    div {
      padding-left: 10%;
      padding-right: 10%;
      position: relative;
      h1 {
        text-align: center;
        display: block;
        width: 100%;
        bottom: 0%;
        left: 50%;
        margin-bottom: 0px;
        position: absolute;
        transform: translateX(-50%);
      }
    }
    h1 {
      font-size: 3em;
      font-weight: 700;
      color: $theme-l4;
      vertical-align: text-bottom;
    }
    h4 {
      font-size: 2em;
      color: wheat;
    }
  }
  .section {
    position: relative;
    padding: 0.5em 2em;
    display: grid;
    grid-template-rows: auto;
    gap: 1em;
    
      
    h1 {
            font-size: 3em;
            font-weight: 700;
            margin-block-start: 0;
            margin-block-end: 0;
          }
          p {
            font-size: 1.1em;
            font-weight: 300;
            text-align: justify;
          }
    &.color1 {
      background-color: $blue-grey;
      h1,
      h2,
      h3,
      h4,
      h5 {
        color: $grey-2;
      }
      p,
      li {
        color: $grey-1;
      }
    }
    &.color2 {
      background-color: $grey-1;
      h1,
      h2,
      h3,
      h4,
      h5 {
        color: $theme-d1;
      }
      p,
      li {
        color: $theme-d3;
      }
    }

    &.color3 {
      background-color: $theme-l4;
      h1,
      h2,
      h3,
      h4,
      h5 {
        color: $theme-d2;
      }
      p,
      li {
        color: $blue-grey-dark;
      }
    }
    &.split,
    &.full {
      height: 100%;
      div {
        &.full {
          grid-column-start: 1;
          grid-column-end: 3;
        }
        position: relative;
        height: 100% !important;
        display: block;
        .title,
        .content {
          width: 100%;
          display: block;
          height: fit-content;
          &.img {
            img.left-40,img.left-60 {
              border-radius: 1em;
              width: 100%;
              margin-bottom: 0.8em;
            }
            img.right-40,img.right-60 {
              border-radius: 1em;
              width: 100%;
              margin-bottom: 0.8em;
            }
          }
          &.multy {
            display: grid;
          }
          h1 {
            font-size: 3em;
            font-weight: 700;
            margin-block-start: 0;
            margin-block-end: 0;
          }
          p {
            font-size: 1.1em;
            font-weight: 300;
            text-align: justify;
          }
          
        }
        .image {
          border-radius: 1em;
          width: 100%;
          padding-top: 100%;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          &#img1 {
            background-image: url("https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F1%20mina%2FLastScan%20TYUI.jpg?alt=media&token=a22f582f-788c-48b4-b1b2-4a826a056bd4&_gl=1*anfvst*_ga*NjAzNTQwNTkxLjE2ODU3MjcyNzM.*_ga_CW55HF8NVT*MTY4NTg0MDU5My41LjEuMTY4NTg0MDYwMS4wLjAuMA..");
          }
          &#img2 {
            background-image: url("https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F1%20mina%2FMINA%20-40.jpg?alt=media&token=ade126b1-af45-4eb7-a212-3cf1e6d13b9f&_gl=1*lf7w9i*_ga*NjAzNTQwNTkxLjE2ODU3MjcyNzM.*_ga_CW55HF8NVT*MTY4NTkxNTczMy43LjEuMTY4NTkxNjU0OS4wLjAuMA..");
          }
          &#img3 {
            background-image: url("https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F1%20mina%2FLastScan%20106.jpg?alt=media&token=996f6d57-a11f-48b8-a920-405597c273dd&_gl=1*1gbuw9u*_ga*NjAzNTQwNTkxLjE2ODU3MjcyNzM.*_ga_CW55HF8NVT*MTY4NTkxNTczMy43LjEuMTY4NTkxNjgwMC4wLjAuMA..");
          }
        }
      }
    }
    .gallery {
            display: grid;
            grid-gap: 0.8em;
            grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
            position: relative;
            padding-bottom: 2em;
            .item {
              padding-top: 100%;
              background-size: cover;
              background-repeat: no-repeat;
              background-position: center;
              position: relative;
              border-radius: 5px;
              overflow: hidden;
              .hover {
                height: 100%;
                width: 100%;
                position: absolute;
                background-color: rgba($color: #000000, $alpha: 0.65);
                top: 0%;
                margin-top: 0%;
                opacity: 0;
                div {
                  width: 100%;
                  margin-top: calc(50% - 32px);
                  i.material-icons {
                    font-size: 35px;
                    color: whitesmoke;
                    text-align: center;
                    width: 100%;
                    vertical-align: middle;
                  }
                  h5 {
                    color: whitesmoke;
                    text-align: center;
                  }
                }
              }
              .hover:hover {
                cursor: pointer;
                opacity: 1;
              }
            }
          }
  }
}
@media (min-width: 768px) {
  .home {
    grid-template-rows: 75vh auto;
    .search {
      .video-container {
        video {
          min-height: 100%;
          min-width: 100%;
        }
      }
      .content {
        margin-left: 5em !important;
      }
    }
    .section {
      padding: 3em 5em;
      gap: 0.5em 2em;
      &.split {
        &.left {
          grid-template-columns: 1.5fr 2.5fr;
        }
        &.right {
          grid-template-columns: 2.5fr 1.5fr;
        }
        .content {
          &.img {
            img.left-40{
              border-radius: 1em;
              float: left;
              max-width: 40%;
              margin-right: 2em;
            }
            img.left-60{
              border-radius: 1em;
              float: left;
              max-width: 60%;
              margin-right: 2em;
            }
            img.right-60 {
              border-radius: 1em;
              float: right;
              max-width: 60%;
              margin-left: 2em;
            }
            img.right-40 {
              border-radius: 1em;
              float: right;
              max-width: 40%;
              margin-left: 2em;
            }
          }
        }
      }
    }
  }
}
@media (min-width: 992px) {
  .home {
    grid-template-rows: 75vh auto;
    .search {
      .video-container {
        video {
          min-height: 100%;
          min-width: 100%;
        }
      }
      .content {
        margin-left: 11em !important;
      }
    }
    .section {
      padding: 3em 13em;
      &.split {
        &.left {
          grid-template-columns: 1.5fr 2.5fr;
        }
        &.right {
          grid-template-columns: 2.5fr 1.5fr;
        }
      }
    }
  }
}
</style>
