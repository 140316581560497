<template>
  <div class="home">
    <div class="search">
      <div>
        <h1 v-html="'ABASTECIMIENTO'"></h1>
      </div>
      <h4 v-html="'Sector Campamento'"></h4>
    </div>
    <div class="section color1 split left">
      <div class="full-height"><div class="image" :style="{'background-image': 'url(https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F6%20abastecimiento%2FDSC01873.jpg?alt=media&token=19dac16a-454c-4624-bad4-8919cd15a7e8)'}"></div></div>
      <div>
        <div class="content">
          
          <h1 v-html="'ABATECIMIENTO'"></h1>
          <p v-html="'El abastecimiento de productos en el campamento minero se diferenci&oacute; del resto de la regi&oacute;n por la calidad y el bajo costo de los productos. Adem&aacute;s, los bienes manufacturados y el ganado eran surtidos por la propia empresa. '"></p>
<p v-html="'Como servicios b&aacute;sicos para el abastecimiento, existieron en Puerto Cristal una pulper&iacute;a, un matadero y una carnicer&iacute;a, servicios que cubr&iacute;an la mayor&iacute;a de las necesidades de los trabajadores del lugar. '"></p>

        </div>
      </div>
    </div>
    
    <div class="section color2 split right">
      <div class="full">
        <div class="content img">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F6%20abastecimiento%2FDSC_2617.jpg?alt=media&token=40e7cd5a-e7b6-4fbc-a479-e94418f02ba9&_gl=1*f3u84m*_ga*NjAzNTQwNTkxLjE2ODU3MjcyNzM.*_ga_CW55HF8NVT*MTY4NjQ4OTIyMC4xOS4xLjE2ODY0OTE5MzYuMC4wLjA."
            alt=""
            class="right-60"
          />
          <h1 v-html="'MAESTRANZA'"></h1>
          <p v-html="'La faena minera y la actividad del campamento no pod&iacute;an paralizarse en ning&uacute;n momento, debido a esto y a las dificultades de log&iacute;stica para abastecerse en caso de falla, la empresa manten&iacute;a un amplio inventario de todas aquellas piezas y repuestos esenciales de la maquinaria. Esto aseguraba que, de existir imprevistos, los tiempos de reparaci&oacute;n fuesen los m&iacute;nimos posibles. Sin embargo, en ciertas oportunidades, los componentes necesarios para reparar los equipos no se encontraban disponibles en el inventario, por lo que se fabricaban en la propia maestranza de Puerto Cristal. '"></p>
<p v-html="'Estos trabajos se realizaban en la maestranza, lugar donde se fabricaban diversas piezas, repuestos o herramientas met&aacute;licas necesarias para el funcionamiento del proceso productivo al interior de la mina, de la planta de concentrado y del campamento. Se divid&iacute;a en varios sectores: la zona de fundici&oacute;n, zona de soldadura, taller mec&aacute;nico y gasfiter&iacute;a. Al interior de la maestranza se encontraban tornos, fresadoras, equipos de corte y soldadoras, entre otros. '"></p>
<p v-html="'Debido a su importancia para el proceso industrial, la maestranza se ubic&oacute; a un costado de la planta de concentrado, ya que la mayor&iacute;a de las piezas que all&iacute; se fabricaban eran para ser usadas en los procesos productivos. Tambi&eacute;n se hac&iacute;an artefactos que se utilizaban en el campamento, un ejemplo de esto son algunas “estufas hechizas”, que se pod&iacute;an encontrar al interior de las viviendas, o los ca&ntilde;os de las mismas. '"></p>

        </div>
      </div>
    </div>

    <div class="section split left">
      <div class="full">
        <div class="content img">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F5%20bodegas%2FMUELLE%20-%2039.jpg?alt=media&token=75a69828-c323-4fbd-8e33-be21f0745e1e&_gl=1*1uu9elt*_ga*NjAzNTQwNTkxLjE2ODU3MjcyNzM.*_ga_CW55HF8NVT*MTY4NjQ4OTIyMC4xOS4xLjE2ODY0OTIyNDIuMC4wLjA."
            alt=""
            class="left-60"
          />
          <h1 v-html="'MUEBLER&Iacute;A'"></h1>
          <p v-html="'A un costado del muelle se ubicaba la muebler&iacute;a o carpinter&iacute;a, edificio de madera destinado a la fabricaci&oacute;n del mobiliario necesario para el correcto funcionamiento de todas las dependencias del campamento, desde las viviendas hasta los edificios del sector industrial. Al igual que la maestranza, la muebler&iacute;a proporcionaba todo lo necesario para el autoabastecimiento del campamento y lo que no llegaba por barco, se pod&iacute;a fabricar all&iacute;. '"></p>
        </div>
      </div>
    </div>

    <div class="section color1 split right">
      <div class="full">
        <div class="content img">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F6%20abastecimiento%2FDSC03873.jpg?alt=media&token=5c87f490-43c1-40d3-9f16-e29b47421358&_gl=1*y3x60u*_ga*NjAzNTQwNTkxLjE2ODU3MjcyNzM.*_ga_CW55HF8NVT*MTY4NjQ5NTc1MC4yMC4xLjE2ODY0OTU4MTIuMC4wLjA."
            alt=""
            class="right-40"
          />
          <h1 v-html="'PULPER&Iacute;A'"></h1>
          <p v-html="'La pulper&iacute;a era el lugar donde se vend&iacute;an y entregaban gran variedad de los productos necesarios para la vida diaria, desde prendas de vestir, hasta abarrotes de todo tipo. Una de las grandes ventajas que ten&iacute;an los trabajadores en Puerto Cristal, respecto al resto de la regi&oacute;n, era que la mayor&iacute;a de los productos que se vend&iacute;an en la pulper&iacute;a eran subsidiados por la propia empresa minera, ya que &eacute;sta adquir&iacute;a los productos a muy buen precio. Generalmente, los productos que llegaban a Cristal v&iacute;a lacustre proven&iacute;an del pa&iacute;s e incluso desde Argentina, los cuales muchas veces eran de mejor calidad que los que se encontraban en cualquier otra parte de la regi&oacute;n. '"></p>
<p v-html="'El sistema que ten&iacute;an los trabajadores para adquirir estos productos era a trav&eacute;s de vales. Los trabajadores iban a la Oficina de Bienestar de la empresa donde solicitaban vales con los productos que iban a necesitar para la semana o mes siguiente. Posteriormente, estos vales se canjeaban en la pulper&iacute;a. All&iacute; los trabajadores recib&iacute;an sus v&iacute;veres y la empresa descontaba del sueldo el monto que correspond&iacute;a a lo que hab&iacute;an solicitado. '"></p>
<p v-html="'Algunos productos dif&iacute;ciles de conseguir eran las verduras, por este motivo, la empresa compraba los productos a algunas mujeres que hac&iacute;an huerta en su casa, para luego distribuirlos en la pulper&iacute;a. Como la cantidad de verduras no era suficiente, la empresa tambi&eacute;n se abastec&iacute;a gracias a otros sectores de la cuenca del lago, como por ejemplo Bah&iacute;a Murta, Mall&iacute;n Grande, y Puerto Rio Tranquilo entre otras localidades, desde donde se prove&iacute;an de gran parte de las verduras y hortalizas necesarias para la poblaci&oacute;n de Puerto Cristal. '"></p>

        </div>
      </div>
      
    </div>
    <div class="section split right">
      <div class="full">
        <div class="content img">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F6%20abastecimiento%2FDSC_0216.jpg?alt=media&token=c26f6b0d-924c-475a-bb09-07c56ed10b79"
            alt=""
            class="right-40"
          />
          <h1 v-html="'CARNICER&Iacute;A Y MATADERO'"></h1>
          <p v-html="'En la carnicer&iacute;a se entregaba la carne, sin embargo, esta era siempre escasa. Dado que la geograf&iacute;a del lugar no era buena para tener grandes cantidades de animales, la empresa deb&iacute;a traerlos desde otras localidades, siendo estos despu&eacute;s faenados en el matadero de Puerto Cristal. '"></p>
          <p v-html="'Los animales eran comprados en distintas zonas de la cuenca del Lago General Carrera. Normalmente se enviaba a los “campa&ntilde;istas”, hombres que a caballo recorr&iacute;an largas extensiones de territorio hacia el sur y que, en sus inicios, ejerc&iacute;an labores relacionadas con el transporte del mineral con mulas. Visitaban a distintos pobladores para comprar animales, desde Puerto Tranquilo hasta Cochrane. Los primeros a&ntilde;os, los “campa&ntilde;istas” llegaban a un sector llamado Colonia, cerca del R&iacute;o Baker, en donde compraban doscientos o trescientos animales. En los a&ntilde;os posteriores hubo escasez de ganado, por lo que deb&iacute;an comprarse tres o cuatro animales a diferentes pobladores hasta reunir un n&uacute;mero adecuado para llevar a Cristal. Una vez que los “campa&ntilde;istas” ya ten&iacute;an un grupo considerable de animales, se dirig&iacute;an hacia Mall&iacute;n Grande, en donde hac&iacute;an una gran fogata y se&ntilde;as de humo hacia Puerto Cristal y as&iacute; la empresa enviaba barcos a buscar la carga. En la carnicer&iacute;a, la carne se les entregaba a los trabajadores de uno en uno. Mineros, administradores y operarios deb&iacute;an esperar su turno, los cuales eran llamados en voz alta para que se acercaran al mes&oacute;n y se llevaran su pedido. '"></p>
        </div>
      </div>
    </div>
    <div class="section color2 split left">
      <div class="full">
        <div class="content img">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F6%20abastecimiento%2FDSC_0331.jpg?alt=media&token=90c10543-65aa-4f07-9949-16ab2d62276a"
            alt=""
            class="left-40"
          />
          <h1 v-html="'OTROS PRODUCTOS'"></h1>
          <p v-html="'En Puerto Cristal tambi&eacute;n se consum&iacute;an pescados y mariscos, pero la adquisici&oacute;n de estos productos requer&iacute;a de una log&iacute;stica m&aacute;s compleja. Por esta raz&oacute;n, este tipo de alimentos se sol&iacute;a consumir s&oacute;lo para las fechas importantes o las fiestas destacadas, como por ejemplo Semana Santa. Para facilitar su abastecimiento la empresa solicitaba, con anticipaci&oacute;n a las fechas se&ntilde;aladas, la cantidad de pescados y mariscos que los trabajadores iban a necesitar, estableciendo de antemano su precio de venta. Al llegar los barcos se repart&iacute;a la mercader&iacute;a de acuerdo con las cantidades que los trabajadores hab&iacute;an solicitado. No era posible hacer cambios ya que no se compraban excedentes. '"></p>
        </div>
      </div>
    </div>
    <div class="section">
          <h1 v-html="'Fotograf&iacute;as'"></h1>
          <div class="gallery">
            <div
              class="item"
              v-for="(item, index) in images"
              :key="index"
              :style="'background-image:url(' + item.url + ')'"
            >
              <div class="hover" @click="openGallery(index)">
                <div>
                  <i class="material-icons">fullscreen</i>
                  <h5>{{ item.name }}</h5>
                </div>
              </div>
            </div>
        
      </div>
    </div>
    <Loading text="cargando" />
    <Message />
    <Gallery/>
  </div>
</template>
<script>
// @ is an alias to /src
import Loading from "@/components/Loading.vue";
import Message from "@/components/Message.vue";

import Gallery from "@/components/Gallery.vue";

import { mapMutations } from "vuex";
export default {
  name: "Home-page",
  components: {
    Loading,
    Message,
    Gallery
  },
  data: function () {
    return {
      col: [
        {
          link: "https://memoriaaustral.cl/",
          imagen: "../../../assets/media/puerto_cristal/MINA.JPG",
          title: "Mina",
          content:
            "Corporaci&oacute;n Cultural Memoria Austral nace en el a&ntilde;o 2013, como corporaci&oacute;n sin fines de lucro, con el objetivo de velar por el rescate y conservaci&oacute;n de la memoria de Ays&eacute;n, desde todos los &aacute;mbitos del patrimonio, favoreciendo la salvaguarda de la identidad regional.",
        },
        {
          link: "https://www.museoregionalaysen.gob.cl/",
          imagen: "../../assets/media/puerto_cristal/LABORATORIO.JPG",
          title: "Laboratorio",
          content:
            "El Museo Regional de Ays&eacute;n es una instituci&oacute;n perteneciente al Servicio Nacional del Patrimonio Cultural. Su apertura a la comunidad se produjo el a&ntilde;o 2018, luego de un largo trabajo de restauraci&oacute;n y habilitaci&oacute;n del Monumento Hist&oacute;rico Construcciones de la Sociedad Industrial de Ays&eacute;n y la construcci&oacute;n de un moderno edificio que alberga la Exposici&oacute;n Permanente Regional, Dep&oacute;sito de Colecciones, Auditorio y Oficinas de Trabajo.",
        },
        {
          link: "https://web.facebook.com/groups/310271558203",
          imagen:
            "https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Flogo_cristalinos.jpg?alt=media&token=e1e5fb66-43fb-4e98-8ddb-2717b54878f2",
          title: "Agrupaci&oacute;n Social y Cultural Los Cristalinos",
          content:
            "La Agrupaci&oacute;n Social y Cultural Los Cristalinos es una instituci&oacute;n que congrega a los antiguos habitantes del Campamento Minero Puerto Cristal, y que con su actuar, busca mantener viva su historia e identidad ligada a este Monumento Nacional.",
        },
      ],
      images: [{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F6%20abastecimiento%2Fsi%20LUZ%20-15.jpg?alt=media&token=40992330-d841-4638-a197-edd9193d1281',name:'Carnicería',position:0},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F6%20abastecimiento%2FDSC_0214.jpg?alt=media&token=fec39b48-eaeb-4aca-9850-0f72086596ed',name:'Carnicería',position:1},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F6%20abastecimiento%2FPuerto%20Cristal%20021.jpg?alt=media&token=c5b8b1be-03eb-4317-b1f8-3b774a23d025',name:'Foto Antigua',position:2},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F6%20abastecimiento%2FDSC_2617.jpg?alt=media&token=40e7cd5a-e7b6-4fbc-a479-e94418f02ba9',name:'Maestranza',position:3},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F6%20abastecimiento%2FDSC_2622.jpg?alt=media&token=bf6cf84c-4e00-404c-a14e-43dad97c1840',name:'Maestranza',position:4},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F6%20abastecimiento%2FDSC_2628.jpg?alt=media&token=83602b21-e368-4fb9-b5b9-f2e35b4b8adf',name:'Maestranza',position:5},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F6%20abastecimiento%2FDSC_2631.jpg?alt=media&token=7dac911a-2ab4-4f35-beac-ae88f8f168f7',name:'Maestranza',position:6},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F6%20abastecimiento%2FDSC_2640.jpg?alt=media&token=d6071149-a32d-4b31-99e0-c4561616de9d',name:'Maestranza',position:7},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F6%20abastecimiento%2FDSC_2651.jpg?alt=media&token=e111894d-5e11-41ac-82ad-837ee0dec483',name:'Maestranza',position:8},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F6%20abastecimiento%2FMATADERO01.jpg?alt=media&token=b88513de-a848-4834-8bbb-43d2d7d3f879',name:'Matadero',position:9},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F6%20abastecimiento%2Fsi%20MATADERO02.jpg?alt=media&token=21012c0d-d2ef-4a44-b8a6-86a6d342ad58',name:'Matadero',position:10},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F5%20bodegas%2FDSC_2768.jpg?alt=media&token=b09aeb65-dcd9-4ced-9e9a-8974d01246c3',name:'Mueblería',position:11},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F5%20bodegas%2FMUELLE%20-%2008.jpg?alt=media&token=c719fae1-d5d1-4ecc-9b27-fe8582fe0a4c',name:'Mueblería',position:12},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F5%20bodegas%2FMUELLE%20-%2039.jpg?alt=media&token=75a69828-c323-4fbd-8e33-be21f0745e1e',name:'Mueblería',position:13}
],
    };
  },
  created() {
    this.SET_LOADING(false);
  },
  methods: {
    ...mapMutations([
      "SET_LOADING",
      "SET_GALLERY",
      "SET_GALLERY_INDEX",
      "SET_GALLERY_ITEMS",
    ]),
    openGallery(payload) {
      let pos = this.images.findIndex((item) => item.position === payload);
      let el = this.images[pos];
      let arr = this.images;
      arr.splice(pos, 1);
      arr.splice(0, 0, el);
      this.SET_GALLERY_ITEMS(arr);
      this.SET_GALLERY_INDEX(payload);
      this.SET_GALLERY(true);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/sass/variables";
@import "../../assets/sass/buttons";
@import "../../assets/sass/cards";
@import "../../assets/sass/modal";

.home {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 70vh auto;
  width: 100vw;
  div.autocomplete-container {
    padding: 25px 13em 45px 3em;
    div {
      display: grid;
      grid-template-columns: auto 60px;
      grid-template-rows: 50px;
      border-radius: 50px;
      background-color: white;
      padding: 0px;
      overflow: visible;
      .radius-left {
        border-bottom-left-radius: 50px;
        border-top-left-radius: 50px;
      }
      .radius-right {
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
      }

      button {
        margin-block-end: 0px;
        margin-block-start: 0px;
        background-color: $theme;
        border: none;
        cursor: pointer;
        i.material-icons {
          vertical-align: middle;
          font-size: 35px;
          color: white;
        }
      }
      button:disabled {
        opacity: 0.5;
        cursor: no-drop;
      }
    }
  }
  .search {
    background-image: linear-gradient(
        to bottom,
        transparent,
        rgba(0, 0, 0, 0.75)
      ),
      url("https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F6%20abastecimiento%2FMATADERO01.jpg?alt=media&token=b88513de-a848-4834-8bbb-43d2d7d3f879&_gl=1*rzym0d*_ga*NjAzNTQwNTkxLjE2ODU3MjcyNzM.*_ga_CW55HF8NVT*MTY4NjUxNTEzMi4yMS4xLjE2ODY1MTY1MzEuMC4wLjA.");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: grid;
    grid-template-rows: calc(100% - 220px) 50px 120px 50px;
    h1,
    h4 {
      position: relative;
      text-align: center;
      padding-left: 10%;
      padding-right: 10%;
      margin-block-end: 0px;
      margin-block-start: 0px;
    }
    div.search-options {
      align-content: center;
      input[type="radio"] {
        margin-block-start: 5px;
        margin-block-end: 5px;
        height: 20px;
        width: 20px;
        margin-right: 5px;
        display: inline-block;
        vertical-align: middle;
      }
      label {
        color: white;
        display: inline-block;
        margin-right: 30px;
        margin-block-end: 0px;
        margin-block-start: 0px;
        vertical-align: middle;
        margin-top: 0px;
        line-height: 20px;
      }
    }
    div {
      padding-left: 10%;
      padding-right: 10%;
      position: relative;
      h1 {
        text-align: center;
        display: block;
        width: 100%;
        bottom: 0%;
        left: 50%;
        margin-bottom: 0px;
        position: absolute;
        transform: translateX(-50%);
      }
    }
    h1 {
      font-size: 3em;
      font-weight: 700;
      color: $theme-l4;
      vertical-align: text-bottom;
    }
    h4 {
      font-size: 2em;
      color: wheat;
    }
  }
  .section {
    position: relative;
    padding: 0.5em 2em;
    display: grid;
    grid-template-rows: auto;
    gap: 1em;
    
      
    h1 {
            font-size: 3em;
            font-weight: 700;
            margin-block-start: 0;
            margin-block-end: 0;
          }
          p {
            font-size: 1.1em;
            font-weight: 300;
            text-align: justify;
          }
    &.color1 {
      background-color: $blue-grey;
      h1,
      h2,
      h3,
      h4,
      h5 {
        color: $grey-2;
      }
      p,
      li {
        color: $grey-1;
      }
    }
    &.color2 {
      background-color: $grey-1;
      h1,
      h2,
      h3,
      h4,
      h5 {
        color: $theme-d1;
      }
      p,
      li {
        color: $theme-d3;
      }
    }

    &.color3 {
      background-color: $theme-l4;
      h1,
      h2,
      h3,
      h4,
      h5 {
        color: $theme-d2;
      }
      p,
      li {
        color: $blue-grey-dark;
      }
    }
    &.split,
    &.full {
      height: 100%;
      div {
        &.full {
          grid-column-start: 1;
          grid-column-end: 3;
        }
        position: relative;
        height: 100% !important;
        display: block;
        .title,
        .content {
          width: 100%;
          display: block;
          height: fit-content;
          &.img {
            img.left-40,img.left-60 {
              border-radius: 1em;
              width: 100%;
              margin-bottom: 0.8em;
            }
            img.right-40,img.right-60 {
              border-radius: 1em;
              width: 100%;
              margin-bottom: 0.8em;
            }
          }
          &.multy {
            display: grid;
          }
          h1 {
            font-size: 3em;
            font-weight: 700;
            margin-block-start: 0;
            margin-block-end: 0;
          }
          p {
            font-size: 1.1em;
            font-weight: 300;
            text-align: justify;
          }
          
        }
        .image {
          border-radius: 1em;
          width: 100%;
          padding-top: 100%;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          &#img1 {
            background-image: url("https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F1%20mina%2FLastScan%20TYUI.jpg?alt=media&token=a22f582f-788c-48b4-b1b2-4a826a056bd4&_gl=1*anfvst*_ga*NjAzNTQwNTkxLjE2ODU3MjcyNzM.*_ga_CW55HF8NVT*MTY4NTg0MDU5My41LjEuMTY4NTg0MDYwMS4wLjAuMA..");
          }
          &#img2 {
            background-image: url("https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F1%20mina%2FMINA%20-40.jpg?alt=media&token=ade126b1-af45-4eb7-a212-3cf1e6d13b9f&_gl=1*lf7w9i*_ga*NjAzNTQwNTkxLjE2ODU3MjcyNzM.*_ga_CW55HF8NVT*MTY4NTkxNTczMy43LjEuMTY4NTkxNjU0OS4wLjAuMA..");
          }
          &#img3 {
            background-image: url("https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F1%20mina%2FLastScan%20106.jpg?alt=media&token=996f6d57-a11f-48b8-a920-405597c273dd&_gl=1*1gbuw9u*_ga*NjAzNTQwNTkxLjE2ODU3MjcyNzM.*_ga_CW55HF8NVT*MTY4NTkxNTczMy43LjEuMTY4NTkxNjgwMC4wLjAuMA..");
          }
        }
      }
    }
    .gallery {
            display: grid;
            grid-gap: 0.8em;
            grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
            position: relative;
            padding-bottom: 2em;
            .item {
              padding-top: 100%;
              background-size: cover;
              background-repeat: no-repeat;
              background-position: center;
              position: relative;
              border-radius: 5px;
              overflow: hidden;
              .hover {
                height: 100%;
                width: 100%;
                position: absolute;
                background-color: rgba($color: #000000, $alpha: 0.65);
                top: 0%;
                margin-top: 0%;
                opacity: 0;
                div {
                  width: 100%;
                  margin-top: calc(50% - 32px);
                  i.material-icons {
                    font-size: 35px;
                    color: whitesmoke;
                    text-align: center;
                    width: 100%;
                    vertical-align: middle;
                  }
                  h5 {
                    color: whitesmoke;
                    text-align: center;
                  }
                }
              }
              .hover:hover {
                cursor: pointer;
                opacity: 1;
              }
            }
          }
  }
}
@media (min-width: 768px) {
  .home {
    grid-template-rows: 75vh auto;
    .search {
      .video-container {
        video {
          min-height: 100%;
          min-width: 100%;
        }
      }
      .content {
        margin-left: 5em !important;
      }
    }
    .section {
      padding: 3em 5em;
      gap: 0.5em 2em;
      &.split {
        &.left {
          grid-template-columns: 1.5fr 2.5fr;
        }
        &.right {
          grid-template-columns: 2.5fr 1.5fr;
        }
        .content {
          &.img {
            img.left-40{
              border-radius: 1em;
              float: left;
              max-width: 40%;
              margin-right: 2em;
            }
            img.left-60{
              border-radius: 1em;
              float: left;
              max-width: 60%;
              margin-right: 2em;
            }
            img.right-60 {
              border-radius: 1em;
              float: right;
              max-width: 60%;
              margin-left: 2em;
            }
            img.right-40 {
              border-radius: 1em;
              float: right;
              max-width: 40%;
              margin-left: 2em;
            }
          }
        }
      }
    }
  }
}
@media (min-width: 992px) {
  .home {
    grid-template-rows: 75vh auto;
    .search {
      .video-container {
        video {
          min-height: 100%;
          min-width: 100%;
        }
      }
      .content {
        margin-left: 11em !important;
      }
    }
    .section {
      padding: 3em 13em;
      &.split {
        &.left {
          grid-template-columns: 1.5fr 2.5fr;
        }
        &.right {
          grid-template-columns: 2.5fr 1.5fr;
        }
      }
    }
  }
}
</style>
