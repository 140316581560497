<template>
  <div class="home">
    <div class="search">
      <div>
        <h1 v-html="'VIDA ESPIRITUAL'"></h1>
      </div>
      <h4 v-html="'Sector Campamento'"></h4>
    </div>
    <div class="section color1 split left">
      <div class="full-height"><div class="image" :style="{'background-image': 'url(https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F10%20vida%20espiritual%2FIGLESIA01.jpg?alt=media&token=4d44624f-5921-4ddd-a953-69c316e7d80f&_gl=1*t30go6*_ga*NjAzNTQwNTkxLjE2ODU3MjcyNzM.*_ga_CW55HF8NVT*MTY4NjUzMjA3OS4yMy4xLjE2ODY1MzYzODUuMC4wLjA.)'}"></div></div>
      <div>
        <div class="content">
          <h1 v-html="'IGLESIA CAT&Oacute;LICA'"></h1>
          <p v-html="'La Iglesia cat&oacute;lica de San Lorenzo, santo patrono de los mineros no contaba con sacerdote, sino que con una feligresa que se encargaba de realizar las homil&iacute;as. Otros miembros de la comunidad hac&iacute;an catequesis y preparaban a los cristalinos para los sacramentos, los que se impart&iacute;an cada cierto tiempo, todos juntos de una sola vez, cuando llegaba el sacerdote o el obispo desde Chile Chico. '"></p>
<p v-html="'El edificio, con base de piedra y cerramientos de madera, se ubicaba en la zona de los pabellones, a un costado de la cancha de f&uacute;tbol, en el lugar donde anteriormente hab&iacute;a existido una vivienda de obreros que se incendi&oacute;. En las dependencias posteriores a la iglesia se encontraban los aposentos de los religiosos. '"></p>
<p v-html="'La imagen de San Lorenzo, ubicada al interior de la iglesia, era adornada y llevada a peregrinar en un carro aleg&oacute;rico para el D&iacute;a del Minero (10 de agosto). La tradici&oacute;n de este ritual se mantuvo hasta despu&eacute;s de la partida de los cristalinos hacia la localidad de Chile Chico. '"></p>
</div>
      </div>
    </div>
    
    <div class="section split right">
      <div class="full">
        <div class="content img">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F10%20vida%20espiritual%2FIMG_0765.jpg?alt=media&token=aece75fc-11c4-43d7-9bc0-133fbfefc124"
            alt=""
            class="right-40"
          />
          <h1 v-html="'IGLESIA EVANG&Eacute;LICA'"></h1>
          <p v-html="'En el templo comunitario evang&eacute;lico Alianza Cristiana y Misionera se realizaban cultos todas las semanas y eran oficiados por el pastor o encargado de la iglesia. La comunidad participaba en escuelas dominicales y celebraba las fiestas de Navidad y de Matrimonios. '"></p>
<p v-html="'La iglesia, que funcion&oacute; hasta un tiempo antes de que cerrara la mina, se encontraba camino al sector industrial y fue construida por los propios fieles en un terreno facilitado por la empresa. '"></p>

        </div>
      </div>
    </div>

    <div class="section color2 split left">
      <div class="full-height"><div class="image" :style="{'background-image': 'url(https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F10%20vida%20espiritual%2FDSC_0123.jpg?alt=media&token=ea1a1fc6-8ac4-456f-b83c-180e6415e6d0&_gl=1*8ri5r2*_ga*NjAzNTQwNTkxLjE2ODU3MjcyNzM.*_ga_CW55HF8NVT*MTY4NjUzMjA3OS4yMy4xLjE2ODY1MzY4OTQuMC4wLjA.)'}"></div></div>
      <div>
        <div class="content">
          <h1 v-html="'GRUTA DE LA VIRGEN'"></h1>
          <p v-html="'Los cristalinos dedicaron un lugar al aire libre para venerar a la Virgen de Lourdes. La gruta estaba ubicada bajo una cavidad natural de una pared rocosa, camino al cementerio. La escultura de la Virgen se elabor&oacute; en granito y fue esculpida en Puerto Cristal por los mismos canteros que construyeron las primeras edificaciones del campamento. Med&iacute;a un metro con sesenta y seis cent&iacute;metros y se convirti&oacute; en una de las representaciones religiosas de la Virgen m&aacute;s grandes de la regi&oacute;n. '"></p>
<p v-html="'Cada domingo se realizaba una peregrinaci&oacute;n a la Gruta de la Virgen excepto en el mes de Mar&iacute;a (mes especial de advocaci&oacute;n a la Virgen, desde el 8 de noviembre hasta el 8 de diciembre), cuando la peregrinaci&oacute;n era diaria. Cuando el clima lo permit&iacute;a, se celebraban en la gruta las misas y catequesis. '"></p>

        </div>
      </div>      
    </div>
    <div class="section">
          <h1 v-html="'Fotograf&iacute;as'"></h1>
          <div class="gallery">
            <div
              class="item"
              v-for="(item, index) in images"
              :key="index"
              :style="'background-image:url(' + item.url + ')'"
            >
              <div class="hover" @click="openGallery(index)">
                <div>
                  <i class="material-icons">fullscreen</i>
                  <h5>{{ item.name }}</h5>
                </div>
              </div>
            </div>
        
      </div>
    </div>
    <Loading text="cargando" />
    <Message />
    <Gallery/>
  </div>
</template>
<script>
// @ is an alias to /src
import Loading from "@/components/Loading.vue";
import Message from "@/components/Message.vue";

import Gallery from "@/components/Gallery.vue";

import { mapMutations } from "vuex";
export default {
  name: "Home-page",
  components: {
    Loading,
    Message,
    Gallery
  },
  data: function () {
    return {
      
      images: [{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F10%20vida%20espiritual%2Fsi%20CEMENTERIO%20-09.jpg?alt=media&token=d32ebf2f-0c4e-482c-88da-b4f3ef92e416',name:'Cementerio',position:0},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F10%20vida%20espiritual%2FCEMENTERIO%20-04.jpg?alt=media&token=92914161-adbe-4013-956e-27efb3244775',name:'Cementerio',position:1},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F10%20vida%20espiritual%2FCEMENTERIO%20-05.jpg?alt=media&token=f2b08bf6-2802-4176-a5bf-bc0fc6ac94f5',name:'Cementerio',position:2},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F10%20vida%20espiritual%2FCEMENTERIO%20-06.jpg?alt=media&token=7d48bc9a-e3a7-4982-bf70-ffa21822309d',name:'Cementerio',position:3},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F10%20vida%20espiritual%2Fsi%20CEMENTERIO%20-02.jpg?alt=media&token=19f3acbd-a36c-4590-825b-9b6d3879d2a3',name:'Cementerio',position:4},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F10%20vida%20espiritual%2Fsi%20CEMENTERIO%20-03.jpg?alt=media&token=09381b36-14e0-4045-ad39-8a34a69f28f3',name:'Cementerio',position:5},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F10%20vida%20espiritual%2FDSC_0110.jpg?alt=media&token=2cd358a8-1049-4f9b-abf2-ee7a3f381238',name:'Gruta de la Virgen',position:6},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F10%20vida%20espiritual%2FDSC_0123.jpg?alt=media&token=ea1a1fc6-8ac4-456f-b83c-180e6415e6d0',name:'Gruta de la Virgen',position:7},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F10%20vida%20espiritual%2FIGLESIA01.jpg?alt=media&token=4d44624f-5921-4ddd-a953-69c316e7d80f',name:'Iglesia Católica',position:8},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F10%20vida%20espiritual%2FIGLESIA03.jpg?alt=media&token=b4bb5cdb-9e23-4745-bf05-ea434e5d13c0',name:'Iglesia Católica',position:9},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F10%20vida%20espiritual%2FDSC04259.jpg?alt=media&token=0de4ea18-1eee-49d6-a793-1da303e126d8',name:'Iglesia Católica',position:10}
],
    };
  },
  created() {
    this.SET_LOADING(false);
  },
  methods: {
    ...mapMutations([
      "SET_LOADING",
      "SET_GALLERY",
      "SET_GALLERY_INDEX",
      "SET_GALLERY_ITEMS",
    ]),
    openGallery(payload) {
      let pos = this.images.findIndex((item) => item.position === payload);
      let el = this.images[pos];
      let arr = this.images;
      arr.splice(pos, 1);
      arr.splice(0, 0, el);
      this.SET_GALLERY_ITEMS(arr);
      this.SET_GALLERY_INDEX(payload);
      this.SET_GALLERY(true);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/sass/variables";
@import "../../assets/sass/buttons";
@import "../../assets/sass/cards";
@import "../../assets/sass/modal";

.home {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 70vh auto;
  width: 100vw;
  div.autocomplete-container {
    padding: 25px 13em 45px 3em;
    div {
      display: grid;
      grid-template-columns: auto 60px;
      grid-template-rows: 50px;
      border-radius: 50px;
      background-color: white;
      padding: 0px;
      overflow: visible;
      .radius-left {
        border-bottom-left-radius: 50px;
        border-top-left-radius: 50px;
      }
      .radius-right {
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
      }

      button {
        margin-block-end: 0px;
        margin-block-start: 0px;
        background-color: $theme;
        border: none;
        cursor: pointer;
        i.material-icons {
          vertical-align: middle;
          font-size: 35px;
          color: white;
        }
      }
      button:disabled {
        opacity: 0.5;
        cursor: no-drop;
      }
    }
  }
  .search {
    background-image: linear-gradient(
        to bottom,
        transparent,
        rgba(0, 0, 0, 0.75)
      ),
      url("https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F10%20vida%20espiritual%2Fsi%20CEMENTERIO%20-02.jpg?alt=media&token=19f3acbd-a36c-4590-825b-9b6d3879d2a3");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: grid;
    grid-template-rows: calc(100% - 220px) 50px 120px 50px;
    h1,
    h4 {
      position: relative;
      text-align: center;
      padding-left: 10%;
      padding-right: 10%;
      margin-block-end: 0px;
      margin-block-start: 0px;
    }
    div.search-options {
      align-content: center;
      input[type="radio"] {
        margin-block-start: 5px;
        margin-block-end: 5px;
        height: 20px;
        width: 20px;
        margin-right: 5px;
        display: inline-block;
        vertical-align: middle;
      }
      label {
        color: white;
        display: inline-block;
        margin-right: 30px;
        margin-block-end: 0px;
        margin-block-start: 0px;
        vertical-align: middle;
        margin-top: 0px;
        line-height: 20px;
      }
    }
    div {
      padding-left: 10%;
      padding-right: 10%;
      position: relative;
      h1 {
        text-align: center;
        display: block;
        width: 100%;
        bottom: 0%;
        left: 50%;
        margin-bottom: 0px;
        position: absolute;
        transform: translateX(-50%);
      }
    }
    h1 {
      font-size: 3em;
      font-weight: 700;
      color: $theme-l4;
      vertical-align: text-bottom;
    }
    h4 {
      font-size: 2em;
      color: wheat;
    }
  }
  .section {
    position: relative;
    padding: 0.5em 2em;
    display: grid;
    grid-template-rows: auto;
    gap: 1em;
    
      
    h1 {
            font-size: 3em;
            font-weight: 700;
            margin-block-start: 0;
            margin-block-end: 0;
          }
          p {
            font-size: 1.1em;
            font-weight: 300;
            text-align: justify;
          }
    &.color1 {
      background-color: $blue-grey;
      h1,
      h2,
      h3,
      h4,
      h5 {
        color: $grey-2;
      }
      p,
      li {
        color: $grey-1;
      }
    }
    &.color2 {
      background-color: $grey-1;
      h1,
      h2,
      h3,
      h4,
      h5 {
        color: $theme-d1;
      }
      p,
      li {
        color: $theme-d3;
      }
    }

    &.color3 {
      background-color: $theme-l4;
      h1,
      h2,
      h3,
      h4,
      h5 {
        color: $theme-d2;
      }
      p,
      li {
        color: $blue-grey-dark;
      }
    }
    &.split,
    &.full {
      height: 100%;
      div {
        &.full {
          grid-column-start: 1;
          grid-column-end: 3;
        }
        position: relative;
        height: 100% !important;
        display: block;
        .title,
        .content {
          width: 100%;
          display: block;
          height: fit-content;
          &.img {
            img.left-40,img.left-60 {
              border-radius: 1em;
              width: 100%;
              margin-bottom: 0.8em;
            }
            img.right-40,img.right-60 {
              border-radius: 1em;
              width: 100%;
              margin-bottom: 0.8em;
            }
          }
          &.multy {
            display: grid;
          }
          h1 {
            font-size: 3em;
            font-weight: 700;
            margin-block-start: 0;
            margin-block-end: 0;
          }
          p {
            font-size: 1.1em;
            font-weight: 300;
            text-align: justify;
          }
          
        }
        .image {
          border-radius: 1em;
          width: 100%;
          padding-top: 100%;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          &#img1 {
            background-image: url("https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F1%20mina%2FLastScan%20TYUI.jpg?alt=media&token=a22f582f-788c-48b4-b1b2-4a826a056bd4&_gl=1*anfvst*_ga*NjAzNTQwNTkxLjE2ODU3MjcyNzM.*_ga_CW55HF8NVT*MTY4NTg0MDU5My41LjEuMTY4NTg0MDYwMS4wLjAuMA..");
          }
          &#img2 {
            background-image: url("https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F1%20mina%2FMINA%20-40.jpg?alt=media&token=ade126b1-af45-4eb7-a212-3cf1e6d13b9f&_gl=1*lf7w9i*_ga*NjAzNTQwNTkxLjE2ODU3MjcyNzM.*_ga_CW55HF8NVT*MTY4NTkxNTczMy43LjEuMTY4NTkxNjU0OS4wLjAuMA..");
          }
          &#img3 {
            background-image: url("https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F1%20mina%2FLastScan%20106.jpg?alt=media&token=996f6d57-a11f-48b8-a920-405597c273dd&_gl=1*1gbuw9u*_ga*NjAzNTQwNTkxLjE2ODU3MjcyNzM.*_ga_CW55HF8NVT*MTY4NTkxNTczMy43LjEuMTY4NTkxNjgwMC4wLjAuMA..");
          }
        }
      }
    }
    .gallery {
            display: grid;
            grid-gap: 0.8em;
            grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
            position: relative;
            padding-bottom: 2em;
            .item {
              padding-top: 100%;
              background-size: cover;
              background-repeat: no-repeat;
              background-position: center;
              position: relative;
              border-radius: 5px;
              overflow: hidden;
              .hover {
                height: 100%;
                width: 100%;
                position: absolute;
                background-color: rgba($color: #000000, $alpha: 0.65);
                top: 0%;
                margin-top: 0%;
                opacity: 0;
                div {
                  width: 100%;
                  margin-top: calc(50% - 32px);
                  i.material-icons {
                    font-size: 35px;
                    color: whitesmoke;
                    text-align: center;
                    width: 100%;
                    vertical-align: middle;
                  }
                  h5 {
                    color: whitesmoke;
                    text-align: center;
                  }
                }
              }
              .hover:hover {
                cursor: pointer;
                opacity: 1;
              }
            }
          }
  }
}
@media (min-width: 768px) {
  .home {
    grid-template-rows: 75vh auto;
    .search {
      .video-container {
        video {
          min-height: 100%;
          min-width: 100%;
        }
      }
      .content {
        margin-left: 5em !important;
      }
    }
    .section {
      padding: 3em 5em;
      gap: 0.5em 2em;
      &.split {
        &.left {
          grid-template-columns: 1.5fr 2.5fr;
        }
        &.right {
          grid-template-columns: 2.5fr 1.5fr;
        }
        .content {
          &.img {
            img.left-40{
              border-radius: 1em;
              float: left;
              max-width: 40%;
              margin-right: 2em;
            }
            img.left-60{
              border-radius: 1em;
              float: left;
              max-width: 60%;
              margin-right: 2em;
            }
            img.right-60 {
              border-radius: 1em;
              float: right;
              max-width: 60%;
              margin-left: 2em;
            }
            img.right-40 {
              border-radius: 1em;
              float: right;
              max-width: 40%;
              margin-left: 2em;
            }
          }
        }
      }
    }
  }
}
@media (min-width: 992px) {
  .home {
    grid-template-rows: 75vh auto;
    .search {
      .video-container {
        video {
          min-height: 100%;
          min-width: 100%;
        }
      }
      .content {
        margin-left: 11em !important;
      }
    }
    .section {
      padding: 3em 13em;
      &.split {
        &.left {
          grid-template-columns: 1.5fr 2.5fr;
        }
        &.right {
          grid-template-columns: 2.5fr 1.5fr;
        }
      }
    }
  }
}
</style>
