<template>
  <div class="home">
    <div class="search">
      <div>
        <h1 v-html="'VIDA COTIDIANA'"></h1>
      </div>
      <h4 v-html="'Sector Campamento'"></h4>
    </div>
    <div class="section color1 split left">
      <div class="full">
        <div class="content img">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F9%20vida%20cotidiana%2Fsii%20club%20deportivo%20planta.jpg?alt=media&token=4c2dc2bf-2dd7-430e-807d-31b4cde86d0a"
            alt=""
            class="left-40"
          />
          <h1 v-html="'CLUBES DEPORTIVOS'"></h1>
          <p v-html="'A principios de los a&ntilde;os cincuenta se crearon cuatro clubes de f&uacute;tbol que representaban y depend&iacute;an, cada uno de ellos, de alguna &aacute;rea de trabajo del campamento minero, agrup&aacute;ndose a los jugadores seg&uacute;n su oficio, de tal manera que los clubes de f&uacute;tbol en Cristal se llamaban Club Deportivo Mina, Club Deportivo Planta, Club Deportivo Naval y Club Deportivo Independiente. '"></p>
<p v-html="'El Club Deportivo Mina agrupaba a todos los obreros que trabajaban al interior de los t&uacute;neles de la mina; el Club Deportivo Planta a aquellos trabajadores que cumpl&iacute;an sus funciones en el sector industrial, tanto en la planta de procesos como en el laboratorio, planta hidroel&eacute;ctrica, maestranza y talleres; el Club Deportivo Naval agrupaba a los trabajadores que cumpl&iacute;an su funci&oacute;n en los barcos, bodegas portuarias y todo lo relacionado con el abastecimiento y, por &uacute;ltimo, el Club Deportivo Independiente agrupaba a los trabajadores de la planta administrativa. '"></p>
<p v-html="'Era tan fuerte la pasi&oacute;n por el f&uacute;tbol que se suscitaban ciertas din&aacute;micas sociales en torno al deporte. Por ejemplo, el jefe de mina pod&iacute;a contratar trabajadores con el &uacute;nico prop&oacute;sito de que rindieran en los partidos y, en algunas ocasiones, las jefaturas de alg&uacute;n &aacute;rea pod&iacute;an utilizar su posici&oacute;n al interior de la empresa para beneficiar a su equipo deportivo. '"></p>
<p v-html="'Todos los fines de semana jugaban un campeonato de f&uacute;tbol. Cuando terminaba la jornada laboral, los mineros sol&iacute;an practicar en la cancha prepar&aacute;ndose para el partido del domingo. En el caso de los jugadores de la selecci&oacute;n se les ofrec&iacute;a el beneficio de salir un tiempo antes de su trabajo. Las mujeres y los ni&ntilde;os tambi&eacute;n participaban del deporte, existiendo una liga femenina y una liga infantil de b&aacute;squetbol, voleibol y baby f&uacute;tbol. '"></p>
<img
            src="https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F9%20vida%20cotidiana%2Fsi%20la%20foto(2).JPG?alt=media&token=2fa504a6-7522-4871-8d9d-0d18797c08c8"
            alt=""
            class="right-40"
          />
<p v-html="'Para practicar los deportes hab&iacute;a una cancha de f&uacute;tbol y una multi-cancha. Esta &uacute;ltima ten&iacute;a piso de concreto y fue la primera cancha iluminada de toda la regi&oacute;n. Alrededor de ambas canchas se encontraban las grader&iacute;as donde la comunidad animaba a sus equipos. Los jugadores de todos los clubes rastrillaban la cancha de f&uacute;tbol de tierra y la demarcaban para dejarla preparada para jugar el partido del domingo. En algunos casos utilizaban la cal que se produc&iacute;a en el sector industrial para la demarcaci&oacute;n de la pista. '"></p>
<p v-html="'Surgi&oacute; en esa &eacute;poca en Puerto Cristal el torneo de “la Copa del Lago” que sigui&oacute; jug&aacute;ndose despu&eacute;s del cierre de las faenas mineras y del abandono de campamento. Para este torneo se cre&oacute; la selecci&oacute;n de f&uacute;tbol de Puerto Cristal con los mejores jugadores del campamento. Entorno a &eacute;l se reun&iacute;a la comunidad de la cuenca del lago y los equipos compet&iacute;an tanto en Cristal como en las distintas localidades. '"></p>
</div>
      </div>
    </div>
    
    <div class="section split right">
      <div class="full">
        <div class="content img">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F9%20vida%20cotidiana%2FDSC03208.jpg?alt=media&token=8455ae71-ade0-4cea-b8e6-c0a4d85edf6f"
            alt=""
            class="left-40"
          />
          <h1 v-html="'CENTRO DE MADRES'"></h1>
          <p v-html="'En Puerto Cristal surgieron dos centros de madres que fueron fundados por las mujeres cristalinas. Estas organizaciones femeninas buscaban proporcionar los conocimientos necesarios para la buena crianza de los hijos y la organizaci&oacute;n de la casa, estas eran instancias de participaci&oacute;n social. Su funci&oacute;n era muy relevante para la vida cotidiana, ya que ayudaban a mejorar la calidad de vida de sus familias. En los centros de madres se ense&ntilde;aba a coser, tejer, pintar, entre otras habilidades manuales, y permit&iacute;a a las mujeres cristalinas producir enseres para el hogar. '"></p>
<p v-html="'Los centros de madres se organizaban mediante una directiva conformada por una directora, secretaria y tesorera. Organizaban actividades de beneficencia para recaudar fondos para su funcionamiento. Entre estas actividades destacaban la realizaci&oacute;n de exposiciones anuales donde presentaban y vend&iacute;an sus confecciones artesanales como pa&ntilde;os bordados, fundas, s&aacute;banas y cubrecamas, adem&aacute;s de productos alimentarios de elaboraci&oacute;n propia. '"></p>
<p v-html="'Tambi&eacute;n organizaban bailes para las Fiestas Patrias, donde las mujeres socias del centro de madres vend&iacute;an empanadas. La fiesta m&aacute;s importante para la que recaudaban fondos era la fiesta de Navidad, donde ellas les preparaban una merienda a sus hijos y les entregaban regalos.  '"></p>
<p v-html="'La primera persona en trabajar con los centros de madres fue la esposa de unos de los administradores de la empresa. Ella fund&oacute; el centro de madres Mar&iacute;a In&eacute;s. Cuando lleg&oacute; CEMA Chile (Central Relacionadora de Centros de Madres), el centro pas&oacute; a llamarse Gabriela Mistral. CEMA Chile coordinaba los centros de madres del pa&iacute;s y depend&iacute;an del Estado. El centro que perdur&oacute; m&aacute;s tiempo, hasta que la mina dej&oacute; de funcionar y sus habitantes se marcharon de Puerto Cristal, fue el centro de madres “Los Cristales”. '"></p>

        </div>
      </div>
    </div>

    <div class="section color2 split right">
      <div class="full">
        <div class="content img">
          <h1 v-html="'FESTIVALES'"></h1>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F9%20vida%20cotidiana%2Fsi%20LastScan%20PHGJB%20%20RET%20.jpg?alt=media&token=0656b191-e06d-47d0-8cbb-b106fcc8c764"
            alt=""
            class="right-40"
          />
          <p v-html="'La vida social era parte importante para la comunidad de Puerto Cristal.  Se reun&iacute;an en los dos casinos para la celebraci&oacute;n de las fechas se&ntilde;aladas, como el 21 de mayo, las Fiestas Patrias, la Navidad o el D&iacute;a del Minero. Durante esas celebraciones se realizaban asados, bailes, actuaciones y otras actividades que compart&iacute;an en familia, vistiendo ropa elegante o trajes t&iacute;picos. Los desfiles u otras actividades al aire libre reun&iacute;an a la comunidad en la cancha donde realizaban los actos sobre un escenario. '"></p>
<p v-html="'Para el D&iacute;a del Minero, algunos cristalinos realizaban una carrera por el primer tramo de riel, hasta el sector filita, donde se encontraba una bandera que deb&iacute;an recoger y llevarla de vuelta a la cancha de f&uacute;tbol. El ganador se llevaba un premio menor, pero lo m&aacute;s importante era el orgullo de ganar la carrera. '"></p>

        </div>
      </div>      
    </div>
    <div class="section">
          <h1 v-html="'Fotograf&iacute;as'"></h1>
          <div class="gallery">
            <div
              class="item"
              v-for="(item, index) in images"
              :key="index"
              :style="'background-image:url(' + item.url + ')'"
            >
              <div class="hover" @click="openGallery(index)">
                <div>
                  <i class="material-icons">fullscreen</i>
                  <h5>{{ item.name }}</h5>
                </div>
              </div>
            </div>
        
      </div>
    </div>
    <Loading text="cargando" />
    <Message />
    <Gallery/>
  </div>
</template>
<script>
// @ is an alias to /src
import Loading from "@/components/Loading.vue";
import Message from "@/components/Message.vue";

import Gallery from "@/components/Gallery.vue";

import { mapMutations } from "vuex";
export default {
  name: "Home-page",
  components: {
    Loading,
    Message,
    Gallery
  },
  data: function () {
    return {
      
      images: [{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F9%20vida%20cotidiana%2FLastScan%2041%20%20RET%20.jpg?alt=media&token=8bf6790a-213b-44f8-bb66-6408e9008f87',name:'Foto Antigua',position:0},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F9%20vida%20cotidiana%2FLastScan%C3%91LKKM.jpg?alt=media&token=e7db93bd-9301-4cb7-84ff-426225bd2aad',name:'Foto Antigua',position:1},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F9%20vida%20cotidiana%2Fsi%20LastScan%20%20RET%20.jpg?alt=media&token=78a9239d-f3fa-4c4f-9574-ca25e104f862',name:'Foto Antigua',position:2},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F9%20vida%20cotidiana%2Fsi%20LastScan%20GHJ%20%20RET%20%20.jpg?alt=media&token=cebb29ce-bcf2-4608-a15a-bb770904e4ca',name:'Foto Antigua',position:3},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F9%20vida%20cotidiana%2Fsi%20LastScan%20PHGJB%20%20RET%20.jpg?alt=media&token=0656b191-e06d-47d0-8cbb-b106fcc8c764',name:'Foto Antigua',position:4},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F9%20vida%20cotidiana%2Fsi%20LastScan%20VBNGHF%20%20RET%20%20.jpg?alt=media&token=d9c15ee4-bba2-4da8-aec9-daef415a05a8',name:'Foto Antigua',position:5},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F9%20vida%20cotidiana%2Fsi%20Ni%C3%B1os%20Elissetche.%201947.jpg?alt=media&token=1f54433a-8903-485f-9d8d-cc82d23a0f72',name:'Foto Antigua',position:6},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F9%20vida%20cotidiana%2Fsii%20club%20deportivo%20planta.jpg?alt=media&token=4c2dc2bf-2dd7-430e-807d-31b4cde86d0a',name:'Foto Antigua',position:7}
],
    };
  },
  created() {
    this.SET_LOADING(false);
  },
  methods: {
    ...mapMutations([
      "SET_LOADING",
      "SET_GALLERY",
      "SET_GALLERY_INDEX",
      "SET_GALLERY_ITEMS",
    ]),
    openGallery(payload) {
      let pos = this.images.findIndex((item) => item.position === payload);
      let el = this.images[pos];
      let arr = this.images;
      arr.splice(pos, 1);
      arr.splice(0, 0, el);
      this.SET_GALLERY_ITEMS(arr);
      this.SET_GALLERY_INDEX(payload);
      this.SET_GALLERY(true);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/sass/variables";
@import "../../assets/sass/buttons";
@import "../../assets/sass/cards";
@import "../../assets/sass/modal";

.home {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 70vh auto;
  width: 100vw;
  div.autocomplete-container {
    padding: 25px 13em 45px 3em;
    div {
      display: grid;
      grid-template-columns: auto 60px;
      grid-template-rows: 50px;
      border-radius: 50px;
      background-color: white;
      padding: 0px;
      overflow: visible;
      .radius-left {
        border-bottom-left-radius: 50px;
        border-top-left-radius: 50px;
      }
      .radius-right {
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
      }

      button {
        margin-block-end: 0px;
        margin-block-start: 0px;
        background-color: $theme;
        border: none;
        cursor: pointer;
        i.material-icons {
          vertical-align: middle;
          font-size: 35px;
          color: white;
        }
      }
      button:disabled {
        opacity: 0.5;
        cursor: no-drop;
      }
    }
  }
  .search {
    background-image: linear-gradient(
        to bottom,
        transparent,
        rgba(0, 0, 0, 0.75)
      ),
      url("https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F9%20vida%20cotidiana%2Fsi%20LastScan%20GHJ%20%20RET%20%20.jpg?alt=media&token=cebb29ce-bcf2-4608-a15a-bb770904e4ca");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: grid;
    grid-template-rows: calc(100% - 220px) 50px 120px 50px;
    h1,
    h4 {
      position: relative;
      text-align: center;
      padding-left: 10%;
      padding-right: 10%;
      margin-block-end: 0px;
      margin-block-start: 0px;
    }
    div.search-options {
      align-content: center;
      input[type="radio"] {
        margin-block-start: 5px;
        margin-block-end: 5px;
        height: 20px;
        width: 20px;
        margin-right: 5px;
        display: inline-block;
        vertical-align: middle;
      }
      label {
        color: white;
        display: inline-block;
        margin-right: 30px;
        margin-block-end: 0px;
        margin-block-start: 0px;
        vertical-align: middle;
        margin-top: 0px;
        line-height: 20px;
      }
    }
    div {
      padding-left: 10%;
      padding-right: 10%;
      position: relative;
      h1 {
        text-align: center;
        display: block;
        width: 100%;
        bottom: 0%;
        left: 50%;
        margin-bottom: 0px;
        position: absolute;
        transform: translateX(-50%);
      }
    }
    h1 {
      font-size: 3em;
      font-weight: 700;
      color: $theme-l4;
      vertical-align: text-bottom;
    }
    h4 {
      font-size: 2em;
      color: wheat;
    }
  }
  .section {
    position: relative;
    padding: 0.5em 2em;
    display: grid;
    grid-template-rows: auto;
    gap: 1em;
    
      
    h1 {
            font-size: 3em;
            font-weight: 700;
            margin-block-start: 0;
            margin-block-end: 0;
          }
          p {
            font-size: 1.1em;
            font-weight: 300;
            text-align: justify;
          }
    &.color1 {
      background-color: $blue-grey;
      h1,
      h2,
      h3,
      h4,
      h5 {
        color: $grey-2;
      }
      p,
      li {
        color: $grey-1;
      }
    }
    &.color2 {
      background-color: $grey-1;
      h1,
      h2,
      h3,
      h4,
      h5 {
        color: $theme-d1;
      }
      p,
      li {
        color: $theme-d3;
      }
    }

    &.color3 {
      background-color: $theme-l4;
      h1,
      h2,
      h3,
      h4,
      h5 {
        color: $theme-d2;
      }
      p,
      li {
        color: $blue-grey-dark;
      }
    }
    &.split,
    &.full {
      height: 100%;
      div {
        &.full {
          grid-column-start: 1;
          grid-column-end: 3;
        }
        position: relative;
        height: 100% !important;
        display: block;
        .title,
        .content {
          width: 100%;
          display: block;
          height: fit-content;
          &.img {
            img.left-40,img.left-60 {
              border-radius: 1em;
              width: 100%;
              margin-bottom: 0.8em;
            }
            img.right-40,img.right-60 {
              border-radius: 1em;
              width: 100%;
              margin-bottom: 0.8em;
            }
          }
          &.multy {
            display: grid;
          }
          h1 {
            font-size: 3em;
            font-weight: 700;
            margin-block-start: 0;
            margin-block-end: 0;
          }
          p {
            font-size: 1.1em;
            font-weight: 300;
            text-align: justify;
          }
          
        }
        .image {
          border-radius: 1em;
          width: 100%;
          padding-top: 100%;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          &#img1 {
            background-image: url("https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F1%20mina%2FLastScan%20TYUI.jpg?alt=media&token=a22f582f-788c-48b4-b1b2-4a826a056bd4&_gl=1*anfvst*_ga*NjAzNTQwNTkxLjE2ODU3MjcyNzM.*_ga_CW55HF8NVT*MTY4NTg0MDU5My41LjEuMTY4NTg0MDYwMS4wLjAuMA..");
          }
          &#img2 {
            background-image: url("https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F1%20mina%2FMINA%20-40.jpg?alt=media&token=ade126b1-af45-4eb7-a212-3cf1e6d13b9f&_gl=1*lf7w9i*_ga*NjAzNTQwNTkxLjE2ODU3MjcyNzM.*_ga_CW55HF8NVT*MTY4NTkxNTczMy43LjEuMTY4NTkxNjU0OS4wLjAuMA..");
          }
          &#img3 {
            background-image: url("https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F1%20mina%2FLastScan%20106.jpg?alt=media&token=996f6d57-a11f-48b8-a920-405597c273dd&_gl=1*1gbuw9u*_ga*NjAzNTQwNTkxLjE2ODU3MjcyNzM.*_ga_CW55HF8NVT*MTY4NTkxNTczMy43LjEuMTY4NTkxNjgwMC4wLjAuMA..");
          }
        }
      }
    }
    .gallery {
            display: grid;
            grid-gap: 0.8em;
            grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
            position: relative;
            padding-bottom: 2em;
            .item {
              padding-top: 100%;
              background-size: cover;
              background-repeat: no-repeat;
              background-position: center;
              position: relative;
              border-radius: 5px;
              overflow: hidden;
              .hover {
                height: 100%;
                width: 100%;
                position: absolute;
                background-color: rgba($color: #000000, $alpha: 0.65);
                top: 0%;
                margin-top: 0%;
                opacity: 0;
                div {
                  width: 100%;
                  margin-top: calc(50% - 32px);
                  i.material-icons {
                    font-size: 35px;
                    color: whitesmoke;
                    text-align: center;
                    width: 100%;
                    vertical-align: middle;
                  }
                  h5 {
                    color: whitesmoke;
                    text-align: center;
                  }
                }
              }
              .hover:hover {
                cursor: pointer;
                opacity: 1;
              }
            }
          }
  }
}
@media (min-width: 768px) {
  .home {
    grid-template-rows: 75vh auto;
    .search {
      .video-container {
        video {
          min-height: 100%;
          min-width: 100%;
        }
      }
      .content {
        margin-left: 5em !important;
      }
    }
    .section {
      padding: 3em 5em;
      gap: 0.5em 2em;
      &.split {
        &.left {
          grid-template-columns: 1.5fr 2.5fr;
        }
        &.right {
          grid-template-columns: 2.5fr 1.5fr;
        }
        .content {
          &.img {
            img.left-40{
              border-radius: 1em;
              float: left;
              max-width: 40%;
              margin-right: 2em;
            }
            img.left-60{
              border-radius: 1em;
              float: left;
              max-width: 60%;
              margin-right: 2em;
            }
            img.right-60 {
              border-radius: 1em;
              float: right;
              max-width: 60%;
              margin-left: 2em;
            }
            img.right-40 {
              border-radius: 1em;
              float: right;
              max-width: 40%;
              margin-left: 2em;
            }
          }
        }
      }
    }
  }
}
@media (min-width: 992px) {
  .home {
    grid-template-rows: 75vh auto;
    .search {
      .video-container {
        video {
          min-height: 100%;
          min-width: 100%;
        }
      }
      .content {
        margin-left: 11em !important;
      }
    }
    .section {
      padding: 3em 13em;
      &.split {
        &.left {
          grid-template-columns: 1.5fr 2.5fr;
        }
        &.right {
          grid-template-columns: 2.5fr 1.5fr;
        }
      }
    }
  }
}
</style>
