<template>
  <div class="category-selector">
    <ul class="parents">
      <li
        v-show="parents.length>0"
        @click="parents=[];category='none';categorySelection=categories;$emit('selected-category',parents)"
      >
        <i class="material-icons">home</i>Inicio
      </li>
      <li v-for="(item,index) in parents" :key="item.id" @click="goTo(index)">
        <i class="material-icons">keyboard_arrow_right</i>
        {{item.name}}
      </li>
    </ul>
    <select name="category" id="category" v-model="category">
      <option
        value="none"
        v-html="'Seleccione la categor&iacute;a'"
        v-if="categorySelection.length>0"
      ></option>
      <option value="none" v-html="'No hay m&aacute;s subcategor&iacute;as'" v-else></option>
      <option v-for="item in categorySelection" :key="item.id" :value="item.id">{{item.name}}</option>
    </select>
    <button @click="setCategories" :disabled="category==='none'">
      <i class="material-icons">search</i>
    </button>
    <button @click="selectCategory" :disabled="category==='none'">
      <i class="material-icons">done</i>
    </button>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "Category-Selector",
  data: function() {
    return {
      parents: [],
      category: "none",
      categorySelection: [],
      currentCategory: null
    };
  },
  computed: {
    ...mapGetters(["categories"])
  },
  mounted() {
    this.setCategories();
  },
  watch: {
    categories() {
      this.setCategories();
    }
  },
  methods: {
    ...mapMutations([
      "SET_LOADING",
      "SET_ERROR_MESSAGE",
      "SET_SUCCESS_MESSAGE_PARAMETERS"
    ]),
    setCategories() {
      if (this.category === "none") {
        this.categorySelection = this.categories;
      } else {
        var cat = null;
        if (this.parents.length > 0) {
          cat = this.categorySelection.find(doc => doc.id === this.category);
        } else {
          cat = this.categories.find(doc => doc.id === this.category);
        }
        if (this.parents.findIndex(doc => doc.id === cat.id) < 0) {
          this.parents.push({ id: this.category, name: cat.name });
        }
        this.categorySelection = cat.subCategories;
        this.category = "none";
        this.$emit('selected-category',this.parents)
      }
    },
    selectCategory() {
      if (this.category !== "none") {
        var cat = null;
        if (this.parents.length > 0) {
          cat = this.categorySelection.find(doc => doc.id === this.category);
        } else {
          cat = this.categories.find(doc => doc.id === this.category);
        }
        if (this.parents.findIndex(doc => doc.id === cat.id) < 0) {
          this.parents.push({ id: this.category, name: cat.name });
        }
      }this.$emit('selected-category',this.parents)
    },
    goTo(payload) {
      this.parents = this.parents.slice(0, payload + 1);
      this.categorySelection = this.categories;
      this.parents.forEach(element => {
        this.category = element.id;
        this.setCategories();
      });
      this.$emit('selected-category',this.parents)
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../assets/sass/variables";
div.category-selector {
  display: grid;
  grid-template-columns: auto 35px 35px;
  grid-template-rows: auto 35px;
  grid-gap: 5px;
  label {
    grid-column-start: 1;
    grid-column-end: 5;
  }
  select {
    height: 35px;
    margin-block-end: 0px;
    margin-block-start: 0px;
  }
  button {
    border: none;
    height: 100%;
    background-color: $theme;
    border-radius: 25px;
    color: white;
    height: 35px;
    margin-block-end: 0px;
    margin-block-start: 0px;
    padding: 0px;
    width: 35px;
    i.material-icons {
      vertical-align: middle;
    }
  }
  button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
  ul.parents {
    padding-left: 15px;
    grid-column-start: 1;
    grid-column-end: 4;
    margin-block-end: 0px;
    margin-block-start: 0px;
    li {
      list-style: none;
      text-decoration: underline;
      margin-block-end: 2px;
      margin-block-start: 2px;
      cursor: pointer;
      i.material-icons {
        margin-top: 0px;
        font-size: 20px;
        vertical-align: middle;
        margin-right: 3px;
      }
    }
  }
  ul.parents {
    li:nth-child(2) {
      margin-left: 5px;
    }
    li:nth-child(3) {
      margin-left: 10px;
    }
    li:nth-child(4) {
      margin-left: 15px;
    }
    li:nth-child(5) {
      margin-left: 20px;
    }
    li:nth-child(6) {
      margin-left: 25px;
    }
    li:nth-child(7) {
      margin-left: 30px;
    }
  }
  div {
    width: 100%;
    background-color: white;
    padding: 0px;
    overflow: visible;
    input {
      height: 100%;
      border: solid 1px lightgray;
      margin-block-start: 0%;
      margin-block-end: 0%;
      cursor: text;
    }
    .options {
      display: inline-block;
      position: relative;
      width: 100%;
      height: 0px;
      z-index: 5 !important;
      overflow: visible;
      margin-top: 0px;
      height: fit-content;
      ul {
        background-color: white;
        border: solid 1px $text;
        margin-block-start: 0px;
        margin-block-end: 0px;
        padding-inline-start: 0px;
        li {
          padding-left: 10px !important;
          line-height: 27px;
          color: $text;
          margin-block-start: 0px;
          margin-block-end: 0px;
          padding: 3px 0px;
          cursor: pointer;
          list-style: none;
        }
        li.selected,
        li:hover {
          background-color: $theme;
          color: white;
          font-weight: 500;
        }
      }
    }
  }
}
</style>
