import app from '@/firebase'
import {
    getFirestore,
    collection,
    getDoc,
    doc,
    updateDoc,
    onSnapshot,
    addDoc,
    deleteDoc
} from "firebase/firestore";
const db = getFirestore(app)
export default {
    state: {
        palabras: []
    },
    namespaced: true,
    actions: {
        async getItems({
            commit
        }) {
            try {
                let response=await getDoc(doc(db, 'System', "palabras"))
                let list=response.data().datos
                list.forEach(el=>{
                    el.estadistica=0
                })
                commit('SET_ITEMS', {
                    list
                })
                return true
            } catch (e) {
                console.log('error', e)
            }
        },
        async checkLoadedItems({ commit, getters }, payload) {
            if (payload !== undefined && payload.length > 0) {
                payload.forEach(async elem => {
                    if (!getters.items.find(item => item.id === elem)) {
                        onSnapshot(doc(db, 'PalabrasClave', elem), doc => {
                            commit('ADD_ITEMS', {
                                id: doc.id,
                                ...doc.data()
                            })
                        })
                    }
                })
            }
        },
        async addItem({ commit }, payload) {
            let doc = await addDoc(collection(db, 'PalabrasClave'), payload)
            commit('ADD_ITEM', { id: doc.id, ...doc.data() })
        },
        async updateItemName({ commit }, payload) {
            await updateDoc(doc(db, 'PalabrasClave', payload.id), { name: payload.name })
            commit('UPDATE_ITEM_NAME', payload)
        },
        async updateItemDefinition({ commit }, payload) {
            await updateDoc(doc(db, 'PalabrasClave', payload.id), { definition: payload.definition })
            commit('UPDATE_ITEM_DEFINITION', payload)
        },
        async deleteItemById({ commit }, payload) {
            await deleteDoc(doc(db, 'PalabrasClave', payload))
            commit('REMOVE_ITEM_BY_ID', payload)
        },
        async getItemObjects({ commit }, payload) {
            return new Promise((resolve, reject) => {
                onSnapshot(collection(db,'PalabrasClave/'+payload+'/items'),qS => {
                    var arr = []
                    qS.forEach(doc => {
                        arr.push(doc.id)
                    })
                    commit('SET_ITEM_OBJECTS', { id: payload, data: arr })
                    resolve(arr)
                }, error => {
                    reject(error)
                })
            })
        },
        async setPalabras(){
            let palabras=[{id:'kHGBSbJgPjAV2HBJ1J9x',name:'´Casco de Minero'},
{id:'WfpH49NzxEVPxLhjJyEr',name:'Abastecimiento'},
{id:'tqE5s4kdBhYMyxBhf6mM',name:'Abrigo'},
{id:'WTIZGHvLNCnEx7OYEi8Q',name:'Accesorios de laboratorio químico'},
{id:'BJ0fIfCCHLTw1o8ZqDB2',name:'Accesorios de minería'},
{id:'vf58McAwgf35hmXO8zDy',name:'Accesorios de minería'},
{id:'5aIwH7VZu6OMHMRX9dFX',name:'Accesorios de protección'},
{id:'X1GbomxnPxApTkM7Zitl',name:'Accesorios de protección'},
{id:'j6iPqNcjBDk2fZiFscjk',name:'Accesorios médicos'},
{id:'FfRr7CPeCXtDpJU3m9EQ',name:'Accidentes'},
{id:'JdlEi6mxFAp4FH0cu7Hw',name:'Acero Eléctrico'},
{id:'1aSH6lkRFYcczsAJupKA',name:'ACHS'},
{id:'T1fX4oKPbu4vZLQMuiOp',name:'Afiche'},
{id:'NfB7IaaZOJWkGd5Wsb6n',name:'Agitadores'},
{id:'0OW3krKVfvaEi7saNGvY',name:'Agua destilada'},
{id:'zHnApuMH4LHovQ4FIcK8',name:'Aislador Eléctrico'},
{id:'whNkvvcAleWWsK9zk7iC',name:'Alimentos'},
{id:'zJ6YxpwjIjhQkUFRW1PF',name:'Ampolla'},
{id:'WyQ8Cb9EYRw1GUJkY5il',name:'Análisis de muestras'},
{id:'eek4ZYd6WUg6kavi99lI',name:'Análisis químico'},
{id:'oOdoXr1bqFazeKVZtUAb',name:'Aparatos mecánicos'},
{id:'b1oLp9ggmCRiNNTcer9E',name:'Armario'},
{id:'gQ4kKwZ7YAPEr0aoPsou',name:'Armario'},
{id:'ebA840Y6lFSEFYqDtMex',name:'Arte'},
{id:'Fi5ZQfx0RLydc0EUibBg',name:'Artesania'},
{id:'mwiZ0MiWZz7svkSxch8M',name:'Artículo de escritorio'},
{id:'DNR5ecWunKe3O6H3ie8S',name:'Artículos de oficina'},
{id:'U8GIp8Zo9SUg26luFhRQ',name:'Asiento'},
{id:'2NAqxdGKUpofmqHzD86s',name:'Asociación Chilena de Seguridad'},
{id:'M1qsSuSJrUigMzhdTX0r',name:'Audio'},
{id:'3hUGQylFKcczNzvSLvay',name:'Bacinica'},
{id:'mwc5yK6c8dDcdMoX0aM0',name:'Bacinilla'},
{id:'BOmZjeTBGGOGUh2mUHpJ',name:'Báscula'},
{id:'miePrncNMwuyvuTkZkbY',name:'Bebidas'},
{id:'8pNbalntotjf4jYagjLm',name:'Bitácora'},
{id:'AAAwOqYldaGkQGubXZj5',name:'Bodega Portuaria'},
{id:'zZtSadyAOjRiSJS4d5St',name:'Bodegas Portuarias'},
{id:'Gq0wbSh4QZrieHokKdev',name:'Cableado'},
{id:'hraDJ8yXLyTZ4zGi9G0y',name:'Calcetín'},
{id:'ImopitJtj2t8dcza0Clk',name:'Calculadora'},
{id:'GJoYPyDlm0blnZBL2nsw',name:'Calefactor'},
{id:'Hja4Tn80KpQTpzUNcyKu',name:'Campamento'},
{id:'jibl0AScyH3iTccy8GAG',name:'Carabineros de Chile'},
{id:'FXLzkoz0d6OtKWWID9nA',name:'Carabineros de Puerto Cristal'},
{id:'ZHFoY0TmRt1TobDw5VYG',name:'Carne'},
{id:'PtnJSbDFpIr1QkBrFZBt',name:'Carnicería'},
{id:'bfQuIpKK1AT5e6sm6rRE',name:'Carpintería'},
{id:'xOfqo5wd6i1Rz1oiPkMq',name:'Cartel de Culto'},
{id:'yd0tHTTYbg4QveFqIpEY',name:'Casa de empleados'},
{id:'v1Lxoot3vAJlOXSPoH4t',name:'Casco de Minero'},
{id:'Gm146AGzZMj21f54ksWB',name:'Casco de protección'},
{id:'SQxsfcSPt7WxqynahDqh',name:'Casco de seguridad'},
{id:'K367lnZNKMqzW44qlipf',name:'Centro de madres'},
{id:'pqsCdNMweSrW7HH6s6NK',name:'Centro de madres'},
{id:'5vcKnxXPG7xT45O6xE8B',name:'Cepo'},
{id:'1cUUnbTaRoLbbGrIiHHg',name:'Cerradura'},
{id:'BPwFqs9jkCZigyRcxQBs',name:'Chancado'},
{id:'a0xL48dzqcFOEMWuAyup',name:'Chumacera'},
{id:'yni196Hb6o6NZoOohxA6',name:'Chumacera'},
{id:'WooyAvGiaMO6wnVWlmIp',name:'Cigarro'},
{id:'56p977AH3Hmjt15nCSNa',name:'Cinta aislante'},
{id:'fJrLro0xNTbtxboFfNem',name:'Cinta de grabación'},
{id:'HjpQDndYWB8jcdnBIhAZ',name:'Ciudadano'},
{id:'lbQM1TmEbkVQ1W5ugSAc',name:'Cobre'},
{id:'jnEYtQOSnGgVdLnkZnp2',name:'Colegio'},
{id:'ERlFm3S35kclqYuod2NQ',name:'Comunicaciones'},
{id:'MQZJdo7A4VsKrpv22J05',name:'Comunicaciones'},
{id:'YyXOuQptaYMJqohO48Rr',name:'Comunicaciones'},
{id:'GvhswmC7ZjSIEvDsJTME',name:'Comunidad'},
{id:'hxPc7uCOD4e4J9TADtDV',name:'Confección manual'},
{id:'Ao7jTZjwiaESFWMQX9CI',name:'Construcción de rieles'},
{id:'uew5KVXNtJomL2p5CVlz',name:'Construcción en madera'},
{id:'36BWJAPTItlHuBdMRjMc',name:'Contabilidad'},
{id:'Kt0d6v9kRqKqNEjNpbfR',name:'Contabilidad'},
{id:'Xh49pW454lZ0CxB1rGy3',name:'Contabilidad'},
{id:'YStba30Yb5p90CWG4Fo1',name:'Contabilidad'},
{id:'flh0SR1QKLSOOXCPzJ7R',name:'Contabilidad'},
{id:'gHcgsBcDwdIXqrRnSOs1',name:'Contabilidad'},
{id:'mpE9MT44lUc4AvvLSrMQ',name:'Contabilidad'},
{id:'I2YKgeOyj0zhw1mTVlTT',name:'Corte'},
{id:'FtcWeLAG27TCmtGPZypu',name:'Costumbres'},
{id:'dKasbXTZdqZpzSAimGEi',name:'Costumbres'},
{id:'GLmtsNl32VmVDOYOSPSl',name:'Costumbres y tradiciones'},
{id:'VXt6CuJsX2rudag20qXf',name:'Cuchara'},
{id:'kTlwXdoxvTOyCvWD1idS',name:'Cuidado personal'},
{id:'DZYrSIOjp2u5FL2Mu9va',name:'Cuña'},
{id:'GiNPZC99xrAFvYMPB11a',name:'Daño al Patrimonio'},
{id:'OSNyFwtiCTIjJhJiOeVj',name:'Decoración'},
{id:'OctrH6E7r4rz6MZZNXZK',name:'Deportes'},
{id:'nhebjKDMDvAgzwSqZrYc',name:'Disco de vinilo'},
{id:'be0PXnqTybqWq0kMg4ox',name:'Documentos'},
{id:'E180DkGb1OKmJSrCaODI',name:'Educación'},
{id:'DkL6MElf3sBhNhMZgedS',name:'ele'},
{id:'o4AzZmkYc3gefstETF51',name:'Electricidad'},
{id:'1Aeqm5QKzFQ1x7v03qtw',name:'Elemento de Protección Personal'},
{id:'hehqwbQKDLC31KQq4qgi',name:'Elementos de curación'},
{id:'uYg6UAyRqpRBRUhx2RLn',name:'Elementos de trabajo'},
{id:'nOsnI05VmsOHUkbPgkMG',name:'Elementos domésticos'},
{id:'MrjKmwkXz8DvQm6MXue9',name:'Empresa de suministros'},
{id:'5yhUj0gd3ZfQrV0vYUV9',name:'Engranaje Helicoidal'},
{id:'OtnmcZ4F94JUhGrrC7AV',name:'Entretenimiento'},
{id:'UJ08xVE9A3IHSEIySVEq',name:'Envase'},
{id:'6dgQZygNz47mEu1jtzh1',name:'Envase de lata'},
{id:'YYdXXm5ECKUN1pONb6Oe',name:'Equipamiento médico'},
{id:'81X7QRgh0GqD5UjA1MGD',name:'Equipo de laboratorio'},
{id:'zI1e5saQiDV3m9FV1paI',name:'Equipo Eléctrico'},
{id:'x7VbORNSJH8WFXkseRRV',name:'Escuela'},
{id:'rCkF1c5xc7uI2opmqMmD',name:'Escuela de Puerto Cristal'},
{id:'8XGystAjZYrY9geu4WBQ',name:'Escultura'},
{id:'KSMEa8wdDdzBWq8TPC1d',name:'Extracción de minerales'},
{id:'5k463qhu09RBoKgrxoOx',name:'Forja'},
{id:'VkXbddz2MsuW0LM6m4bi',name:'fragil'},
{id:'qExLeoPGwxBuHf8bz1my',name:'Fragua'},
{id:'93dqQjInWq5RXCsaIgAo',name:'Frasco'},
{id:'AO91wgwa5izKNakafR58',name:'Fuente'},
{id:'eggc0xk7nq7q18nrjh9C',name:'Fundición'},
{id:'7b7Mm1V3i3r57NIajixk',name:'Fusibles'},
{id:'fatRoxLzCgYAg9PMWNza',name:'Fusibles'},
{id:'YXN0EyJtNAjvcvkGXDzJ',name:'Ganaderia'},
{id:'6eOpeKgNttDBssrkgmGj',name:'Ganadería'},
{id:'EYpEUbcYyxoDsN8sZKGa',name:'Gasa antiadherente'},
{id:'rTCAW3CfJJ1MLgxAtAoW',name:'Gastronomía'},
{id:'LyaafgDs4mdAcZaZvWP0',name:'Gaucho'},
{id:'xHZz4BLwXUIXaOUDYGHC',name:'Generador Eléctrico'},
{id:'pBywmJgMbEDmEWob5oit',name:'Geografía'},
{id:'kevel6JB1TLaBYnbtkx6',name:'Geología'},
{id:'Im5QomL8TtM1UDHe0JEW',name:'Golilla'},
{id:'jCbKco8PxZdoksQSqryn',name:'Hacha de mano'},
{id:'ys7QaCoIvT3DNsevXwLI',name:'Herramienta industrial'},
{id:'7OGliiK3A4bhMYdrunkC',name:'Herramientas de cocina'},
{id:'UTzJQ8wG0PJUbvZKvjPk',name:'Herramientas laboratorio'},
{id:'DhhNdQFlOEBoLaC3Iiyz',name:'Herramientas manuales'},
{id:'kr8T9RmBsgKiPrIRrVcE',name:'Herramientas tecnológicas'},
{id:'YdoD25PyYOJaEbnmxg1n',name:'Herrería'},
{id:'BW92eSjxLvUoaa3705Gq',name:'Hierro'},
{id:'xpUutGADabD6DK23sXVI',name:'Hogar'},
{id:'YKKSn8UEPgAOn88rbQEY',name:'Horno'},
{id:'CXbtRJ8sZD7yJt7qKEtI',name:'Huso'},
{id:'E7uGsH4maBjtiWYSwXiX',name:'Identidad regional'},
{id:'8RdI3bJM1RKJMFx4V7J7',name:'Iglesia'},
{id:'fWLendBkLYyFd59mzHfR',name:'Iglesia'},
{id:'iyLBf6WD9d4PVpxQZ6JL',name:'Iluminación'},
{id:'trGoGHPT6ZgLR1rROqX3',name:'Ilustración'},
{id:'bj5kKEIfmoKK0HpPx2uq',name:'Impresora'},
{id:'zJ1b5H1p46VVCBxvB2aI',name:'Infancia'},
{id:'4JYC569LPbH6awGmk43l',name:'Ingeniería Eléctrica'},
{id:'YlRmPhmRyAhJH5uadx7O',name:'Ingeniería en minas'},
{id:'JJ8uBpw5QSIYgpf8wwSn',name:'Ingeniería industrial'},
{id:'mbXHwWbdNHQ8Yd9L0Oy3',name:'Ingeniería industrial'},
{id:'zpxgZzX3IIkzusV5emVo',name:'Instructivo'},
{id:'I12sxZeAQmk9gEAFhHby',name:'Instrumento de Medición'},
{id:'JoQjZQ8XH8Bu0YeEv2TM',name:'Instrumentos de cocina'},
{id:'VhRMhmBQknKFTntf43dc',name:'Instrumentos de laboratorio químico'},
{id:'ixnIy7g9NGf6xmyT1cC9',name:'Insumos cocina'},
{id:'2RGaSctCpGHyNNdb9FfF',name:'Insumos dentales'},
{id:'kJygmqxHsnmQVmKF2rq6',name:'Insumos Laboratorio'},
{id:'06VnbyHht6UxTxqE8lvp',name:'Insumos médicos'},
{id:'G8zbMWtnWBsstcPARbwt',name:'Inventario de abastecimiento'},
{id:'W83Y9UoAuMNFYeJv837m',name:'Inventario de Abastecimiento'},
{id:'E74i80VPJMduTbnh4ivo',name:'Juego'},
{id:'UKM8rpcVyuOmSgEIkoR3',name:'Laboratorio'},
{id:'NVTvXPNMv6kZmwqGRBlp',name:'Laboratorio de Puerto Cristal'},
{id:'Khr1pJBa0WfGl7j1oEIw',name:'Laboratorio Químico'},
{id:'DMsHAdi53QGy6NK5mHQv',name:'Labores hogareñas'},
{id:'XpJWKkyfIlqRH9JCVG3F',name:'Lámpara'},
{id:'kHz2zhmtcmPcElwA3eU5',name:'lámpara'},
{id:'chv5PdRsJ8Pr4jDI6xnF',name:'Lámpara utilizada en minería'},
{id:'6GslCFAOnRA9NiTYMWwV',name:'Lana'},
{id:'Zsdv6qEjzuoHKLtRTHTh',name:'Leña'},
{id:'ayYKRrogYP5RLMvj7oll',name:'Letrero'},
{id:'pNuBr4NJAYtjp5hQk8X1',name:'Libro'},
{id:'uN9XDn5slvipcT5xGeML',name:'Linterna'},
{id:'yqOFiMP4TwVKn6jr6qJU',name:'Llave Regulable'},
{id:'sOURScELrg3vcmKr9K0b',name:'Loza'},
{id:'yzgPrvdVJAulonPgviNn',name:'Maestranza'},
{id:'1dRfJ8bwQgqMsxDXQTdH',name:'Manchon de Acople'},
{id:'iNcAdKGHqdO5WiBOfaB1',name:'Manómetro'},
{id:'PkxCnol84LMOMAbYdIez',name:'Mantenimiento'},
{id:'zGVfsFu1mm8bJBkP5OR4',name:'Mantenimiento'},
{id:'HdpxfIHqwiZ7orFjGRaD',name:'Manufactura'},
{id:'OOvB7kKeKKOhP5Z575M6',name:'Maquinaria Industrial'},
{id:'9yZT2ueUEki369MRDvjF',name:'Mate'},
{id:'yutihTIbquwTiP83P3Mu',name:'Material de construcción'},
{id:'lTS7v7VQhJa7rOHmHQ8a',name:'Material de laboratorio'},
{id:'qVPWREGzF389KN3bFnhP',name:'Material de laboratorio'},
{id:'8l85JQJ36fz5DsPxRgRX',name:'Mazo de mortero'},
{id:'m8bUFGYE9nHdCDubZHCW',name:'Medicamento'},
{id:'zp5tlPaRrswA03xmIauf',name:'Medicamento inyectable'},
{id:'dzPpPLc5LufgIZCZon0y',name:'Medicina'},
{id:'jfSTjh4lJchLkmofsUUq',name:'medicina'},
{id:'Bf8m3uMQzVD5XWBh4Wxo',name:'Medición'},
{id:'8eIti1Thluan7sHZkro7',name:'Metal'},
{id:'FN1fAhvnH85duFx04A8i',name:'Mineral'},
{id:'J0F80OUjH2qVOYSm2UdW',name:'Minerales'},
{id:'DPy67cuZEaTFsWrFDmBC',name:'Minería'},
{id:'sWEiX2a8ukjUqsqyuMhr',name:'Minería'},
{id:'q8PswU81UyNyG90HglST',name:'MineSpot'},
{id:'YSMkfmXKRLzaCGyqo9bc',name:'Ministerio de Salud'},
{id:'5HF2nD7QD9RtzPAdKPRV',name:'Mobiliario'},
{id:'MTlCSHXqb0Ic96CKhBAh',name:'Momentos de ocio'},
{id:'xo9LXWg0M0UNB1SwN1DY',name:'Mueble'},
{id:'8xPkDudCsvRxcITQcvTk',name:'Muleta'},
{id:'0YTod0uyhPfLePwtjMUs',name:'Música'},
{id:'cLorHynPHf70EcICJ1Rp',name:'Navegación'},
{id:'C5JbNyH9RPJXXVDM9WjQ',name:'Navegación Seguridad'},
{id:'zskqrwihUOEUygXh3Q7z',name:'Navegación Seguridad Transporte'},
{id:'A9vdlcxUOkfutpedUZm0',name:'Niñez'},
{id:'PTWE9MS89gSwO7fJg0oh',name:'Objeto de colección'},
{id:'8cHpd2yp6EN0l2scMjl0',name:'Ocio y recreación'},
{id:'NtF31ZS0aa0LGfAIk5qI',name:'Odontología'},
{id:'Ubcbo7F4DvmbAgRBv33t',name:'Óptica'},
{id:'izCz4mQ06YSvfChFDqav',name:'Orden y organización'},
{id:'t3Mlz62r4vnk4UFK84vx',name:'Orinal'},
{id:'nAMIVLQpiRAn713wRfJQ',name:'Paisajismo'},
{id:'UNRLsaEr5FbaOZ8xyf1v',name:'Palangana'},
{id:'ZK1kSodABdhAfm6N64r9',name:'Pantalón'},
{id:'u3u9Zi1qDelBVGJRhXEK',name:'Partes y Piezas'},
{id:'HSiwYeLCulYoKiObojzK',name:'Patrimonio cultural'},
{id:'QK3A52Az6u5UXhhVNRvr',name:'Patrimonio Histórico'},
{id:'2SU6AHSGBoNDq5IFbMYb',name:'Patrimonio industrial'},
{id:'MRhwUTV4b1fc3JYyW48X',name:'Patrimonio Industrial'},
{id:'Gngvx9HSIGDrCCOi7YeR',name:'Pediatría'},
{id:'OOQ9PzU2ArtJW8lRildu',name:'Perforación'},
{id:'zt7B8VRjD1JVvZTAQIOu',name:'Plancha'},
{id:'Wdup8iVtMgsHgF7uXbVy',name:'Planta hidroeléctrica'},
{id:'0Cqc9Tx1ggxXLmtk8B0o',name:'Plantas'},
{id:'HZE6f6dRel7RJSQ39NZ0',name:'Poblamiento de Aysén'},
{id:'yXMIm89HiTa95Vkcr3UO',name:'Polera'},
{id:'ZjfriqnhDEu6JhznR4aF',name:'Posta'},
{id:'w88eAiEvNAxl753S1dkH',name:'Póster'},
{id:'JuQFLFWpAwCCmE5CKY5s',name:'Prenda de vestir'},
{id:'flLoi7lx4izEXJqltpaO',name:'Prevención de Riesgos'},
{id:'vMg6FX5K09L8T8CIeTKd',name:'Procesos Mineros'},
{id:'Ul3ZOLJQQCWERl2VRXiM',name:'Procesos Químicos'},
{id:'a6r2b8M7eIk759hOrrhd',name:'Radio'},
{id:'xGWgUjTwSpze7iUSfzHk',name:'Recipiente'},
{id:'mkhbjBW2XSbpgP6iayG2',name:'Relieve'},
{id:'6xEhHYICaMCTtp2BMZ6L',name:'Religión'},
{id:'IOC9TkOLtligJPllqZ9p',name:'Religión'},
{id:'gsVAIBL7GpcbLHh9uWrU',name:'Remaches'},
{id:'4FoqOFpdkrIyW7utCSPY',name:'Repuestos'},
{id:'heiiYNQvEAAQsrCuTZVy',name:'Retén'},
{id:'dhCczOqfjpSNTdpJX5xU',name:'Retén de Carabineros'},
{id:'6d28LsqHyDUPjY2JL13b',name:'Retrato'},
{id:'WlE6rz8XDAaII1eTJIAK',name:'Revisar'},
{id:'FdluZTcMh66Aeu6Aq6Rb',name:'Riesgos laborales'},
{id:'bUtLZyMZ6myRxpp2Kgy0',name:'Romana'},
{id:'HChcY0BewVxvPAAM8hWL',name:'Ropa'},
{id:'fdkY8EDbwVqyqeYOUJL1',name:'Ropa casual'},
{id:'vCvlidRRqlB9v0Pe0LYp',name:'Ruta del Mineral'},
{id:'EjtMAw99UbXXoco5xhus',name:'Sala de carga y mantención de baterías'},
{id:'XuaA5bROgMKunxYF2DG9',name:'Sala de carga y mantenimiento de baterías'},
{id:'Wus8FFHhXQE4JLIMUG8X',name:'Salas'},
{id:'t4owXDIShBGqDzKECFTz',name:'Salud'},
{id:'2i3ketvHU7hZ9yiA4Kw1',name:'Sandalia'},
{id:'n8t7qWnBAekvM5zCaoQc',name:'Seguridad'},
{id:'uQSr5Q0ZpGXAHRE91nSX',name:'Seguridad'},
{id:'zMZ5FYCmuJH9G7dpcU0e',name:'Seguridad'},
{id:'HaVl4QYhNweV6T7N3o3d',name:'Seguridad Industrial'},
{id:'cFoEbrN59i7Dm8uAk6Mj',name:'Seguridad Industrial'},
{id:'SiwcaiRwpyE0W5cjZLyu',name:'Seguridad Navegación'},
{id:'yNQ6ear1x4g2BoBFDpwA',name:'Seguridad Navegación Transporte'},
{id:'6imHzzSzi2j9LJpH6gDN',name:'Señalética'},
{id:'BlP4vHomETNhIoeXBZQV',name:'Señalización'},
{id:'UtkJdDdlD6SdN2wGieKy',name:'Sistema de registro'},
{id:'a7ZF5dYLVHDHMzeBW9ik',name:'Soldadura'},
{id:'yjDQ4y5BZUqbquwevBmb',name:'Soporte de balones'},
{id:'veshmGQ5YNowSW6Yy5EA',name:'Soporte de Pipetas'},
{id:'lvRVZfjURByuGozs4Smr',name:'Soporte de Tubos de Ensayo'},
{id:'ww1cYWIgaPZn7FASu4kn',name:'Suministro de Energía'},
{id:'NgNK3D4oiUuxmnydW43W',name:'Tala de madera'},
{id:'KQn8PnknTs10PswxelAd',name:'Taller Eléctrico'},
{id:'gGwohpBEZJkNmCaOkHhf',name:'Taller Mecánico'},
{id:'UL4JzgGyq6ASeI84AV4A',name:'Tareas'},
{id:'QWXZHBbwyUHWFbCiCUmF',name:'Tejidos textiles'},
{id:'Pt2BMuLahGo5bNllGf3b',name:'Telefonía'},
{id:'YaqInUcUPqeuTvXghfn3',name:'Telefónica de Coyhaique'},
{id:'6QIIg0Jkbr43a7kSeyG1',name:'Teléfono de prueba'},
{id:'5ExSKnFDs3o546XYpejJ',name:'Terraja de Roscar'},
{id:'X2H6L2mRMpBldYSZmJws',name:'Testigos'},
{id:'PgSB5OPIMI6YGjiVe45T',name:'Tiempo libre'},
{id:'7rdmbqRrwN5rclvpkURX',name:'Tiempo libre y recreación'},
{id:'tcHRo9AaBtKvxjPV6ZjM',name:'Tijera de esquilar'},
{id:'b8HXvqpQwK33hUgofzj4',name:'Toma de muestras'},
{id:'sWSfC0PIIWiImMT2PEII',name:'Tope'},
{id:'FwgZR0IY745POuG2OMNr',name:'Topografía'},
{id:'H4VJjVZ69sSHLX0PxxUC',name:'Tradición cultural'},
{id:'j8eMDr4gg0oIL5GElpVo',name:'Tradición cultural'},
{id:'z7jPaayFtYJMRD2QueIS',name:'Traducción cultural'},
{id:'rHIoZhQdedcT5GZlagL0',name:'Traje impermeable'},
{id:'5sA5QBmsJxdh9dcDCx9y',name:'Transporte'},
{id:'NUpRYzl4yKESlHM1h2Da',name:'Transporte de Materiales en Seco'},
{id:'E16RQ4lugqXSaAaFPjO5',name:'turbina'},
{id:'HVYKqp0N3p6BXyre6tRb',name:'Turbina Diesel'},
{id:'yskOZEOjFMhJ8iv6fQsZ',name:'Unidad dental'},
{id:'clfleTmcMfKr1pPYt9Mz',name:'Uso doméstico'},
{id:'1dgJFoJRK2oH71QOCmsv',name:'utensilios de cocina'},
{id:'QiCbq6Kqm55ZRY6L3fTE',name:'Utensilios de cocina'},
{id:'Selbr1jSiTdzdbTR5PgW',name:'Válvula de admisión para compresor'},
{id:'Ow1U6wKTDzfH6yUIyNIW',name:'Vestimenta adulto'},
{id:'3HSUkIPgCisr3JEOoEVz',name:'Vestimenta de trabajo'},
{id:'cLgjj8ew0TmouuKHogMt',name:'Vestimenta infantil'},
{id:'8qEKoFiDzr5A3s1TMhWQ',name:'Vestuario adulto'},
{id:'9my1HGVF0ssaP5UENSwn',name:'Vida cotidiana'},
{id:'jZ1T41UDarOfqTlS5VpW',name:'Vida familiar'},
{id:'THJdX3wDXZjCQYO7Rwrr',name:'Vida personal'},
{id:'McvhcWHusBTP8Qo7wGSp',name:'Vidrio'},
{id:'934vldE6yAFDn43CaBxu',name:'Vinilo'},
{id:'TkKakROsHVNXUXBQug2U',name:'Virgen de Lourdes'},
{id:'YpHLWix0vdJojJZQwzxa',name:'Virgen Maria'},
{id:'PYWt4HTxsMHTg7sCEpN6',name:'Vivienda'},
{id:'bJkX7bvOgr1WLB0AqIih',name:'Zapato'}]

try {

    await updateDoc(doc(db, 'System', 'palabras'), {
        datos: palabras
    })
} catch (e) {
    console.log('error', e)
}    

        }
    },
    getters: {
        items: state => {
            return state.palabras
        },
        abcItems: state => {
            let arr = []
            state.palabras.forEach(item => {
                let char = item.name.split('')[0]
                let exists = arr.find(el => el === char.toUpperCase())
                if (exists === undefined) {
                    arr.push(char.toUpperCase())
                }
            })
            return arr
        },
        itemsByIndex: state => payload => {
            let arr = []
            state.palabras.forEach(item => {
                let char = item.name.split('')[0]
                if (char.toUpperCase() === payload) {
                    if (item.definition.split('').length >= 200) {
                        arr.push({ ...item, shortDefinition: item.definition.slice(0, 199), isLong: true })
                    } else {
                        arr.push({ ...item, shortDefinition: item.definition, isLong: false })
                    }
                }
            })
            return arr
        },
        name: state => payload => {
            let item = state.palabras.find(el => el.id === payload)
            if (item) {
                return item.name
            } else {
                return ''
            }
        }
    },
    mutations: {
        SET_ITEMS(state, payload) {
            state.palabras = payload.list
        },
        ADD_ITEMS(state, payload) {
            let index = state.palabras.findIndex(item => item.id === payload.id)
            if (index && index >= 0) {
                state.palabras[index] = payload
            } else {
                state.palabras.push(payload)
            }
        },
        CLEAR_ITEMS(state) {
            state.palabras = []
        },
        REMOVE_ITEM_BY_ID(state, payload) {
            let index = state.palabras.findIndex(item => item.id === payload)
            if (index >= 0) {
                state.items.splice(index, 1)
            }
        },
        UPDATE_ITEM_NAME(state, payload) {
            let elem = state.palabras.find(item => item.id === payload.id)
            if (elem) {
                elem.name = payload.name
            }
        },
        UPDATE_ITEM_DEFINITION(state, payload) {
            let elem = state.palabras.find(item => item.id === payload.id)
            if (elem) {
                elem.name = payload.definition
            }
        },
        SET_ITEM_OBJECTS(state, payload) {
            let result = state.palabras.find(elem => elem.id === payload.id)
            if (result) {
                result.elements = payload.data
            }
        }
    }
}