<template>
  <div class="home">
    <div class="search">
      <div>
        <h1 v-html="'MINA SILVA - MINA EL ROSILLO'"></h1>
      </div>
      <h4 v-html="'Sector Mina'"></h4>
    </div>
    <div class="section color1 split left">
      <div><div class="image" id="img1"></div></div>
      <div>
        <div class="content">
          <p
            v-html="
              'El La Mina Silva fue descubierta en el a&ntilde;o 1936, comenzando su explotaci&oacute;n en 1945 con la extracci&oacute;n principalmente de zinc y plomo, con una ley promedio del 50% de mineral (concentrado de mineral presente en la roca) y llegando a un promedio de un 15% en sus &uacute;ltimos a&ntilde;os de actividad.'
            "
          ></p>
          <p
            v-html="
              'El mineral se encontraba en vetas y fue extra&iacute;do con poca tecnolog&iacute;a durante todo el periodo que duraron las faenas mineras. Una de las grandes dificultades para la explotaci&oacute;n, era la fragilidad del cerro, siendo determinante el uso de madera para sostener los t&uacute;neles y efectuar una explotaci&oacute;n segura. '
            "
          ></p>
          <p
            v-html="
              'Las condiciones de seguridad en los inicios de los trabajos mineros fueron bastante b&aacute;sicas. Se trabajaba en tres turnos (ma&ntilde;ana, tarde y noche) y los mineros sub&iacute;an a la boca de la mina en carros vac&iacute;os que hab&iacute;an bajado previamente con el mineral; este sistema de rieles y cables, manejados por un huinchero desde la salida de los t&uacute;neles, se utilizaba aprovechando la gran pendiente que presentaba el cerro. '
            "
          ></p>
        </div>
      </div>
    </div>
    <div class="section color2 split right">
      <div class="full">
        <div class="content img">
          <h1>Mina Silva</h1>
          <p
            v-html="
              'La Mina Silva fue una mina subterr&aacute;nea ubicada a m&aacute;s de mil metros de altura sobre el nivel del mar. En su interior trabajaban entre setenta y ochenta mineros por turno, quienes se introduc&iacute;an en una red de t&uacute;neles que se extend&iacute;a por varios kil&oacute;metros hacia el interior del macizo rocoso para extraer los minerales de plomo y zinc. '
            "
          ></p>
          <p
            v-html="
              'La veta, que originalmente se explot&oacute; en la Mina Silva a mediados de los a&ntilde;os cuarenta, se encontraba muy pr&oacute;xima a la superficie y su alto contenido de plomo, cuya ley promedio superaba el 60%, permit&iacute;a que el mineral fuera escogido manualmente, para posteriormente reducir su tama&ntilde;o de forma artesanal y ser envasado en sacos para su posterior transporte y comercializaci&oacute;n. '
            "
          ></p>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F1%20mina%2FMINA%20-06.jpg?alt=media&token=1d71ce37-9204-4285-bc10-3d9a2f111bab&_gl=1*1rgs9qs*_ga*NjAzNTQwNTkxLjE2ODU3MjcyNzM.*_ga_CW55HF8NVT*MTY4NTkxMjQyNy42LjEuMTY4NTkxMjkxNy4wLjAuMA.."
            alt=""
            class="right"
          />

          <p
            v-html="
              'Desde un punto de vista geol&oacute;gico el Cerro Cristal, donde se encuentra la Mina Silva, posee m&uacute;ltiples vetas de cuarzo con minerales ricos en plomo, zinc y plata, que se distribuyen como “bolsones” bajo la superficie. Estos “bolsones” se emplazan en m&aacute;rmol pre-paleozoico y corresponden a lentes alargados que miden entre diecis&eacute;is y cuarenta metros de largo con anchos de uno a seis metros. Este yacimiento debi&oacute; originarse por intrusi&oacute;n, es decir, por cuerpos de magma que se solidificaron en grietas o fisuras antes de emerger a la superficie. Los minerales que se pueden encontrar en las vetas son arsenopirita (FeAsS), pirita (FeS2), blenda o esfalerita (ZnS), calcopirita (CuFeS2), galena (PbS) y tetraedrita ((Cu,Fe)12Sb4S13 ); de &eacute;stos se extra&iacute;a la galena y blenda cuyos nombres qu&iacute;micos son Sulfuro de Plomo y Sulfuro de Zinc respectivamente, f&iacute;sicamente ambas forman cristales c&uacute;bicos. '
            "
          ></p>

          <p
            v-html="
              'Una vez que la veta original que se encontraba expuesta en la superficie empez&oacute; a agotarse, comenz&oacute; la construcci&oacute;n de t&uacute;neles hacia el interior del macizo rocoso del Cerro Cristal, estos eran galer&iacute;as o caserones horizontales cuyo acceso se encontraba a m&aacute;s de mil metros de altura sobre el nivel del mar. Debido a las caracter&iacute;sticas de la mina, como la topograf&iacute;a de la superficie, la calidad del macizo rocoso, el tipo de mineralizaci&oacute;n y lo inestable de la roca, entre otros, es que se produc&iacute;an derrumbes de material frecuentemente, lo cual pod&iacute;a obstruir los t&uacute;neles y dejar atrapados a los mineros. '
            "
          ></p>

          <img
            src="https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F1%20mina%2FPuerto%20Cristal%20015.JPG?alt=media&token=e4208d36-d15e-48bd-a05f-72e2399732e7&_gl=1*b110ew*_ga*NjAzNTQwNTkxLjE2ODU3MjcyNzM.*_ga_CW55HF8NVT*MTY4NTkxMjQyNy42LjEuMTY4NTkxMjQ3MS4wLjAuMA.."
            alt=""
            class="left"
          />
          <p
            v-html="
              'Las vetas se encontraban expuestas en la superficie, por ello se comenz&oacute; la explotaci&oacute;n en superficie hasta acabar con el mineral superficial, para posteriormente comenzar la construcci&oacute;n de t&uacute;neles hacia el interior del macizo rocoso del Cerro Cristal. Estos, consist&iacute;an en galer&iacute;as horizontales cuyo acceso se encontraba a 1.000 m.s.n.m. Debido a las caracter&iacute;sticas rocosas del macizo, se produc&iacute;an frecuentemente derrumbes de material, por lo que se pod&iacute;an obstruir las galer&iacute;as y dejar atrapados a los mineros. '
            "
          ></p>
          <p
            v-html="
              'Por este motivo, la secci&oacute;n de las galer&iacute;as era de aproximadamente dos metros de ancho por dos metros de alto y los primeros veinte metros de t&uacute;nel se encontraban reforzados con basas de madera para sostener el techo y paredes de los t&uacute;neles para evitar su colapso. Hacia el interior de la mina no era necesario reforzar la estructura ya que el macizo rocoso era mucho m&aacute;s estable y autosoportante. '
            "
          ></p>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F1%20mina%2FMINA%20-33.jpg?alt=media&token=2a224474-533a-4c95-843f-408ea906716f&_gl=1*122fcez*_ga*NjAzNTQwNTkxLjE2ODU3MjcyNzM.*_ga_CW55HF8NVT*MTY4NTkxNTczMy43LjEuMTY4NTkxNTc2Ny4wLjAuMA.."
            alt=""
            class="right"
          />
          <p
            v-html="
              'A medida que se avanzaba por el t&uacute;nel principal se pod&iacute;a observar c&oacute;mo las galer&iacute;as se iban ramificando horizontalmente, existiendo distintos niveles que se conectaban verticalmente por medio de rampas, escaleras y chimeneas. Al interior de los t&uacute;neles exist&iacute;a una red de rieles sobre la cual se mov&iacute;an los carros mineros con el material, estos carros llegaban hasta la boca mina desde donde se conectaban con los carros que transportaban el mineral hacia la planta de procesos. Durante el proceso de extracci&oacute;n, los mineros perforaban el macizo rocoso, para luego colocar los explosivos en las perforaciones y detonar la roca; luego de la explosi&oacute;n deb&iacute;an esperar a que el polvo se asentara y se remov&iacute;an aquellas rocas sueltas que pudiesen caer desde el techo y/o paredes de los t&uacute;neles (se conoce como acu&ntilde;ar el techo del cerro), posteriormente proced&iacute;an a extraer el mineral con ayuda de palas, picotas y carretillas. El uso de tecnolog&iacute;a para la extracci&oacute;n del mineral era m&iacute;nimo, por lo que el uso del recurso humano era intensivo al interior de la mina, llegando a haber tres turnos al d&iacute;a con m&aacute;s de setenta trabajadores por turno. '
            "
          ></p>
          <p
            v-html="
              'Para realizar esta ardua labor los mineros iban equipados con la vestimenta de trabajo que les entregaba la empresa, cada uno con su casco y su l&aacute;mpara minera para iluminar el camino en el interior de los t&uacute;neles, la que se conectaba a una pesada bater&iacute;a que se cargaba en su cintur&oacute;n. Como las jornadas de trabajo eran largas, los mineros deb&iacute;an llevar su comida para almorzar en la mina, existiendo algunos espacios reducidos al interior de los t&uacute;neles donde pod&iacute;an calentar su comida. Durante la mayor parte de su historia las condiciones de seguridad para los mineros fueron bastante b&aacute;sicas. '
            "
          ></p>
        </div>
      </div>
    </div>
    <div class="section split right">
      <div>
        <div class="content">
          <h1>Sector Filita y Sistema de Rieles</h1>
          <p
            v-html="
              'Una vez que el mineral era extra&iacute;do de la Mina Silva, este deb&iacute;a ser procesado en la Planta de Concentrado, para esto en el a&ntilde;o 1950 se inici&oacute; la construcci&oacute;n de un funicular que conectaba estos dos puntos para facilitar el transporte del mineral. Debido a la geograf&iacute;a y a la gran pendiente que ten&iacute;a el Cerro Cristal, es que este sistema funicular se construy&oacute; en dos tramos; uno conectaba la Mina Silva con el Sector Filita y el otro, el Sector Filita con la Planta de Concentrados. Cada tramo estaba compuesto por una l&iacute;nea de rieles sobre los cuales se montaban los carros, que los mineros sol&iacute;an llamar “zorritas” o “patitos”; estos carros estaban conectados por un cable de acero que permit&iacute;a utilizar la gravedad para facilitar el movimiento de los carros, ya que el carro ubicado en la parte superior era cargado con el mineral y cuando bajaba hac&iacute;a que el carro vac&iacute;o en la parte inferior subiera, esto era aprovechado por los mineros ya que sub&iacute;an a trabajar en los carros vac&iacute;os. De esta manera, siempre hab&iacute;a un carro subiendo y el otro bajando en cada secci&oacute;n de rieles. '
            "
          ></p>
          <p
            v-html="
              'Por otra parte, para optimizar los recursos, los carros compart&iacute;an las l&iacute;neas de rieles, sin embargo, en el punto medio de &eacute;stas se produc&iacute;a una bifurcaci&oacute;n para que ambos carros puedan cruzarse sin chocar. El mineral extra&iacute;do en la mina se cargaba en los carros del primer tramo del funicular y era descargado en el Sector Filita, luego, en este punto de intersecci&oacute;n se cargaban los carros del segundo tramo del funicular para ser descargados en la primera tolva de la Planta de Procesos. Tanto a la salida de la Mina Silva como en el Sector Filita, trabajaban los “huincheros” quienes operaban y controlaban el funcionamiento de los carros. '
            "
          ></p>
          <p
            v-html="
              'Cabe destacar que cuando inici&oacute; la extracci&oacute;n del mineral a finales de los a&ntilde;os cuarenta el mineral se bajaba en “trineos” tirados a mano hacia el sector filita, y desde all&iacute; se transportaba utilizando mulas. Por otra parte, la construcci&oacute;n de los funiculares era provisoria ya que para los tiempos de nevazones se pensaba construir un andarivel gravitacional, sin embargo, esto nunca ocurri&oacute;. '
            "
          ></p>
        </div>
      </div>
      <div><div class="image" id="img2"></div></div>
    </div>
    <div class="section color2 split left">
      <div><div class="image" id="img3"></div></div>
      <div>
        <div class="content">
          <h1>Mina El Rosillo</h1>
          <p
            v-html="
              'La Mina El Rosillo se encontraba situada en el cerro colindante al cerro Cristal, un poco m&aacute;s lejos del campamento, alrededor de unos 3 km al nordeste.  Las actividades mineras en la Mina El Rosillo se desarrollaron entre los a&ntilde;os ‘60 y continuaron espor&aacute;dicamente hasta finales de 1997, cuando cesaron las faenas mineras en Puerto Cristal, debido a la baja del precio del zinc, a consecuencia de la crisis asi&aacute;tica. '
            "
          ></p>
          <p
            v-html="
              'Respecto a la geolog&iacute;a, la mineralizaci&oacute;n de la Mina El Rosillo se encuentra en filitas verdes, negras y m&aacute;rmoles del Paleozoico. El dep&oacute;sito de El Rosillo es un cuerpo mantiforme deformado que se encuentra entre las filitas negras y m&aacute;rmoles. Las filitas negras consisten en cuarzo, clorita, albita, mica blanca con subordinaci&oacute;n biotita y plagioclasa. Los m&aacute;rmoles son de una alta pureza. Los mantos mineralizados son de esfaleritas masivas (sulfuro de Zinc), pirita (&oacute;xido de Fe) y Galena (sulfuro de Plomo). '
            "
          ></p>
        </div>
      </div>
    </div>
    <div class="section">
          <h1 v-html="'Fotograf&iacute;as'"></h1>
          <div class="gallery">
            <div
              class="item"
              v-for="(item, index) in images"
              :key="index"
              :style="'background-image:url(' + item.url + ')'"
            >
              <div class="hover" @click="openGallery(index)">
                <div>
                  <i class="material-icons">fullscreen</i>
                  <h5>{{ item.name }}</h5>
                </div>
              </div>
            </div>
        
      </div>
    </div>
    <Loading text="cargando" />
    <Message />
    <Gallery/>
  </div>
</template>
<script>
// @ is an alias to /src
import Loading from "@/components/Loading.vue";
import Message from "@/components/Message.vue";

import Gallery from "@/components/Gallery.vue";

import { mapMutations } from "vuex";
export default {
  name: "Home-page",
  components: {
    Loading,
    Message,
    Gallery
  },
  data: function () {
    return {
      col: [
        {
          link: "https://memoriaaustral.cl/",
          imagen: "../../../assets/media/puerto_cristal/MINA.JPG",
          title: "Mina",
          content:
            "Corporaci&oacute;n Cultural Memoria Austral nace en el a&ntilde;o 2013, como corporaci&oacute;n sin fines de lucro, con el objetivo de velar por el rescate y conservaci&oacute;n de la memoria de Ays&eacute;n, desde todos los &aacute;mbitos del patrimonio, favoreciendo la salvaguarda de la identidad regional.",
        },
        {
          link: "https://www.museoregionalaysen.gob.cl/",
          imagen: "../../assets/media/puerto_cristal/LABORATORIO.JPG",
          title: "Laboratorio",
          content:
            "El Museo Regional de Ays&eacute;n es una instituci&oacute;n perteneciente al Servicio Nacional del Patrimonio Cultural. Su apertura a la comunidad se produjo el a&ntilde;o 2018, luego de un largo trabajo de restauraci&oacute;n y habilitaci&oacute;n del Monumento Hist&oacute;rico Construcciones de la Sociedad Industrial de Ays&eacute;n y la construcci&oacute;n de un moderno edificio que alberga la Exposici&oacute;n Permanente Regional, Dep&oacute;sito de Colecciones, Auditorio y Oficinas de Trabajo.",
        },
        {
          link: "https://web.facebook.com/groups/310271558203",
          imagen:
            "https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Flogo_cristalinos.jpg?alt=media&token=e1e5fb66-43fb-4e98-8ddb-2717b54878f2",
          title: "Agrupaci&oacute;n Social y Cultural Los Cristalinos",
          content:
            "La Agrupaci&oacute;n Social y Cultural Los Cristalinos es una instituci&oacute;n que congrega a los antiguos habitantes del Campamento Minero Puerto Cristal, y que con su actuar, busca mantener viva su historia e identidad ligada a este Monumento Nacional.",
        },
      ],
      images: [{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F1%20mina%2FPuerto%20Cristal%20015.JPG?alt=media&token=e4208d36-d15e-48bd-a05f-72e2399732e7&_gl=1*1qxt39j*_ga*MTIyNTY4ODAyOC4xNjg1NzMyODQy*_ga_CW55HF8NVT*MTY4NTc0MDYzMy4yLjEuMTY4NTc0MTI4MS4wLjAuMA..',name:'Cordillera',position:0},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F1%20mina%2FPuerto%20Cristal%20035.JPG?alt=media&token=bb9e9b98-39ca-48cd-aa3d-632f21204a99&_gl=1*olxw7e*_ga*MTIyNTY4ODAyOC4xNjg1NzMyODQy*_ga_CW55HF8NVT*MTY4NTc0MDYzMy4yLjEuMTY4NTc0MTA5OS4wLjAuMA..',name:'Foto Antigua',position:1},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F1%20mina%2FMINA%20-06.jpg?alt=media&token=1d71ce37-9204-4285-bc10-3d9a2f111bab&_gl=1*pyevg7*_ga*MTIyNTY4ODAyOC4xNjg1NzMyODQy*_ga_CW55HF8NVT*MTY4NTc0MDYzMy4yLjEuMTY4NTc0MDcxMC4wLjAuMA..',name:'Mina',position:2},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F1%20mina%2FMINA%20-08.jpg?alt=media&token=bcf8d8ae-8f54-4294-ad53-d1a368022487&_gl=1*4fz5al*_ga*MTIyNTY4ODAyOC4xNjg1NzMyODQy*_ga_CW55HF8NVT*MTY4NTc0MDYzMy4yLjEuMTY4NTc0MDc1Ni4wLjAuMA..',name:'Mina',position:3},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F1%20mina%2FDSC_0358.jpg?alt=media&token=d3e1bccf-4435-4998-9463-d8061e3530e7',name:'Mina El Rosillo',position:4},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F1%20mina%2FDSC_0351.jpg?alt=media&token=b49df8d4-1dea-4932-897e-7ea4fcdba5fc',name:'Mina El Rosillo',position:5},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F1%20mina%2FMINA%20-32.jpg?alt=media&token=6457ec2f-0cb6-47fe-92c2-b9fcd5aa187d&_gl=1*155481p*_ga*MTIyNTY4ODAyOC4xNjg1NzMyODQy*_ga_CW55HF8NVT*MTY4NTc0MDYzMy4yLjEuMTY4NTc0MDkxMi4wLjAuMA..',name:'Mina Silva',position:6},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F1%20mina%2FMINA%20-33.jpg?alt=media&token=2a224474-533a-4c95-843f-408ea906716f&_gl=1*5wcz2l*_ga*MTIyNTY4ODAyOC4xNjg1NzMyODQy*_ga_CW55HF8NVT*MTY4NTc0MDYzMy4yLjEuMTY4NTc0MDk0MS4wLjAuMA..',name:'Mina Silva',position:7},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F1%20mina%2FLastScan%20106.jpg?alt=media&token=996f6d57-a11f-48b8-a920-405597c273dd&_gl=1*ztpmks*_ga*MTIyNTY4ODAyOC4xNjg1NzMyODQy*_ga_CW55HF8NVT*MTY4NTczMjg0Mi4xLjEuMTY4NTczMzgwNi4wLjAuMA..',name:'Minero',position:8},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F1%20mina%2FLastScan%2066.jpg?alt=media&token=06ea4399-9891-4001-ac01-955935e6e632&_gl=1*13ktp92*_ga*MTIyNTY4ODAyOC4xNjg1NzMyODQy*_ga_CW55HF8NVT*MTY4NTczMjg0Mi4xLjEuMTY4NTczNDAzMS4wLjAuMA..',name:'Mineros',position:9},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F1%20mina%2FPP.jpg?alt=media&token=96d6c2d9-be4d-43df-8fdb-eec94424e182&_gl=1*myqpql*_ga*MTIyNTY4ODAyOC4xNjg1NzMyODQy*_ga_CW55HF8NVT*MTY4NTc0MDYzMy4yLjEuMTY4NTc0MTA1OC4wLjAuMA..',name:'Mineros',position:10},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F1%20mina%2FLastScan%20TYUI.jpg?alt=media&token=a22f582f-788c-48b4-b1b2-4a826a056bd4&_gl=1*1mym8hs*_ga*MTIyNTY4ODAyOC4xNjg1NzMyODQy*_ga_CW55HF8NVT*MTY4NTc0MDYzMy4yLjAuMTY4NTc0MDYzMy4wLjAuMA..',name:'Mineros',position:11},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F1%20mina%2FMINA%20-01.jpg?alt=media&token=11aa8568-e881-41c7-963b-4100a5d33d0e&_gl=1*19poxdc*_ga*MTIyNTY4ODAyOC4xNjg1NzMyODQy*_ga_CW55HF8NVT*MTY4NTc0MDYzMy4yLjEuMTY4NTc0MDY2NC4wLjAuMA..',name:'Rieles',position:12},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F1%20mina%2FMINA%20-40.jpg?alt=media&token=ade126b1-af45-4eb7-a212-3cf1e6d13b9f&_gl=1*1fxm9ux*_ga*MTIyNTY4ODAyOC4xNjg1NzMyODQy*_ga_CW55HF8NVT*MTY4NTc0MDYzMy4yLjEuMTY4NTc0MDk3NS4wLjAuMA..',name:'Rieles',position:13},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F1%20mina%2FDSC_0357.jpg?alt=media&token=6b88c145-6865-4b5d-945f-3db3862fbb24',name:'Vista Mina El Rosillo',position:14},
{url:'https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F1%20mina%2FDSC_0342.jpg?alt=media&token=c5265c21-695f-46d1-8b19-d80b53fbefc3',name:'Vista Mina El Rosillo',position:15}
],
    };
  },
  created() {
    this.SET_LOADING(false);
  },
  methods: {
    ...mapMutations([
      "SET_LOADING",
      "SET_GALLERY",
      "SET_GALLERY_INDEX",
      "SET_GALLERY_ITEMS",
    ]),
    openGallery(payload) {
      let pos = this.images.findIndex((item) => item.position === payload);
      let el = this.images[pos];
      let arr = this.images;
      arr.splice(pos, 1);
      arr.splice(0, 0, el);
      this.SET_GALLERY_ITEMS(arr);
      this.SET_GALLERY_INDEX(payload);
      this.SET_GALLERY(true);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/sass/variables";
@import "../../assets/sass/buttons";
@import "../../assets/sass/cards";

.home {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 70vh auto;
  width: 100vw;
  div.autocomplete-container {
    padding: 25px 13em 45px 3em;
    div {
      display: grid;
      grid-template-columns: auto 60px;
      grid-template-rows: 50px;
      border-radius: 50px;
      background-color: white;
      padding: 0px;
      overflow: visible;
      .radius-left {
        border-bottom-left-radius: 50px;
        border-top-left-radius: 50px;
      }
      .radius-right {
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
      }

      button {
        margin-block-end: 0px;
        margin-block-start: 0px;
        background-color: $theme;
        border: none;
        cursor: pointer;
        i.material-icons {
          vertical-align: middle;
          font-size: 35px;
          color: white;
        }
      }
      button:disabled {
        opacity: 0.5;
        cursor: no-drop;
      }
    }
  }
  .search {
    background-image: linear-gradient(
        to bottom,
        transparent,
        rgba(0, 0, 0, 0.75)
      ),
      url("https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F1%20mina%2FMINA%20-08.jpg?alt=media&token=bcf8d8ae-8f54-4294-ad53-d1a368022487&_gl=1*3kf92j*_ga*NjAzNTQwNTkxLjE2ODU3MjcyNzM.*_ga_CW55HF8NVT*MTY4NTgxMDM0OS40LjEuMTY4NTgxMDYyMy4wLjAuMA..");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: grid;
    grid-template-rows: calc(100% - 220px) 50px 120px 50px;
    h1,
    h4 {
      position: relative;
      text-align: center;
      padding-left: 10%;
      padding-right: 10%;
      margin-block-end: 0px;
      margin-block-start: 0px;
    }
    div.search-options {
      align-content: center;
      input[type="radio"] {
        margin-block-start: 5px;
        margin-block-end: 5px;
        height: 20px;
        width: 20px;
        margin-right: 5px;
        display: inline-block;
        vertical-align: middle;
      }
      label {
        color: white;
        display: inline-block;
        margin-right: 30px;
        margin-block-end: 0px;
        margin-block-start: 0px;
        vertical-align: middle;
        margin-top: 0px;
        line-height: 20px;
      }
    }
    div {
      padding-left: 10%;
      padding-right: 10%;
      position: relative;
      h1 {
        text-align: center;
        display: block;
        width: 100%;
        bottom: 0%;
        left: 50%;
        margin-bottom: 0px;
        position: absolute;
        transform: translateX(-50%);
      }
    }
    h1 {
      font-size: 3em;
      font-weight: 700;
      color: $theme-l4;
      vertical-align: text-bottom;
    }
    h4 {
      font-size: 2em;
      color: wheat;
    }
  }
  .section {
    position: relative;
    padding: 0.5em 2em;
    display: grid;
    grid-template-rows: auto;
    gap: 1em;
    
      
    h1 {
            font-size: 3em;
            font-weight: 700;
            margin-block-start: 0;
            margin-block-end: 0;
          }
          p {
            font-size: 1.1em;
            font-weight: 300;
            text-align: justify;
          }
    &.color1 {
      background-color: $blue-grey;
      h1,
      h2,
      h3,
      h4,
      h5 {
        color: $grey-2;
      }
      p,
      li {
        color: $grey-1;
      }
    }
    &.color2 {
      background-color: $grey-1;
      h1,
      h2,
      h3,
      h4,
      h5 {
        color: $theme-d1;
      }
      p,
      li {
        color: $theme-d3;
      }
    }

    &.color3 {
      background-color: $theme-l4;
      h1,
      h2,
      h3,
      h4,
      h5 {
        color: $theme-d2;
      }
      p,
      li {
        color: $blue-grey-dark;
      }
    }
    &.split,
    &.full {
      height: 100%;
      div {
        &.full {
          grid-column-start: 1;
          grid-column-end: 3;
        }
        position: relative;
        height: 100% !important;
        display: block;
        .title,
        .content {
          width: 100%;
          display: block;
          height: fit-content;
          &.img {
            img.left {
              border-radius: 1em;
              width: 100%;
            }
            img.right {
              border-radius: 1em;
              width: 100%;
            }
          }
          &.multy {
            display: grid;
          }
          h1 {
            font-size: 3em;
            font-weight: 700;
            margin-block-start: 0;
            margin-block-end: 0;
          }
          p {
            font-size: 1.1em;
            font-weight: 300;
            text-align: justify;
          }
          
        }
        .image {
          border-radius: 1em;
          width: 100%;
          padding-top: 100%;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          &#img1 {
            background-image: url("https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F1%20mina%2FLastScan%20TYUI.jpg?alt=media&token=a22f582f-788c-48b4-b1b2-4a826a056bd4&_gl=1*anfvst*_ga*NjAzNTQwNTkxLjE2ODU3MjcyNzM.*_ga_CW55HF8NVT*MTY4NTg0MDU5My41LjEuMTY4NTg0MDYwMS4wLjAuMA..");
          }
          &#img2 {
            background-image: url("https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F1%20mina%2FMINA%20-40.jpg?alt=media&token=ade126b1-af45-4eb7-a212-3cf1e6d13b9f&_gl=1*lf7w9i*_ga*NjAzNTQwNTkxLjE2ODU3MjcyNzM.*_ga_CW55HF8NVT*MTY4NTkxNTczMy43LjEuMTY4NTkxNjU0OS4wLjAuMA..");
          }
          &#img3 {
            background-image: url("https://firebasestorage.googleapis.com/v0/b/colecciones-e9ef9.appspot.com/o/system%2Fmedia_pc%2F1%20mina%2FLastScan%20106.jpg?alt=media&token=996f6d57-a11f-48b8-a920-405597c273dd&_gl=1*1gbuw9u*_ga*NjAzNTQwNTkxLjE2ODU3MjcyNzM.*_ga_CW55HF8NVT*MTY4NTkxNTczMy43LjEuMTY4NTkxNjgwMC4wLjAuMA..");
          }
        }
      }
    }
    .gallery {
            display: grid;
            grid-gap: 0.8em;
            grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
            position: relative;
            padding-bottom: 2em;
            .item {
              padding-top: 100%;
              background-size: cover;
              background-repeat: no-repeat;
              background-position: center;
              position: relative;
              border-radius: 5px;
              overflow: hidden;
              .hover {
                height: 100%;
                width: 100%;
                position: absolute;
                background-color: rgba($color: #000000, $alpha: 0.65);
                top: 0%;
                margin-top: 0%;
                opacity: 0;
                div {
                  width: 100%;
                  margin-top: calc(50% - 32px);
                  i.material-icons {
                    font-size: 35px;
                    color: whitesmoke;
                    text-align: center;
                    width: 100%;
                    vertical-align: middle;
                  }
                  h5 {
                    color: whitesmoke;
                    text-align: center;
                  }
                }
              }
              .hover:hover {
                cursor: pointer;
                opacity: 1;
              }
            }
          }
  }
}
@media (min-width: 768px) {
  .home {
    grid-template-rows: 75vh auto;
    .search {
      .video-container {
        video {
          min-height: 100%;
          min-width: 100%;
        }
      }
      .content {
        margin-left: 5em !important;
      }
    }
    .section {
      padding: 3em 5em;
      gap: 0.5em 2em;
      &.split {
        &.left {
          grid-template-columns: 1.5fr 2.5fr;
        }
        &.right {
          grid-template-columns: 2.5fr 1.5fr;
        }
        .content {
          &.img {
            img.left {
              border-radius: 1em;
              float: left;
              max-width: 40%;
              margin-right: 2em;
            }
            img.right {
              border-radius: 1em;
              float: right;
              max-width: 40%;
              margin-left: 2em;
            }
          }
        }
      }
    }
  }
}
@media (min-width: 992px) {
  .home {
    grid-template-rows: 75vh auto;
    .search {
      .video-container {
        video {
          min-height: 100%;
          min-width: 100%;
        }
      }
      .content {
        margin-left: 11em !important;
      }
    }
    .section {
      padding: 3em 13em;
      &.split {
        &.left {
          grid-template-columns: 1.5fr 2.5fr;
        }
        &.right {
          grid-template-columns: 2.5fr 1.5fr;
        }
      }
    }
  }
}
</style>
