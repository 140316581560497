import app from '@/firebase'
import {
    getFirestore,
    getDoc,
    doc,
    updateDoc,
    onSnapshot,
    deleteDoc,
    runTransaction
} from "firebase/firestore";
const db = getFirestore(app)
export default {
    state: {
        items: []
    },
    namespaced: true,
    actions: {
        async getItems({
            commit
        }) {
            try {
                let response = await getDoc(doc(db, 'System', "tecnicas"))
                let list = response.data().datos
                list.forEach(el => {
                    el.estadistica = 0
                })
                commit('SET_ITEMS', {
                    list
                })
                return true
            } catch (e) {
                console.log('error', e)
            }
        },
        async addItem({
            commit
        }, payload) {
            try {
                let count = doc(db, "Tecnicas", "counter")
                let newDoc = await runTransaction(db, async (transaction) => {
                    let doc = await transaction.get(count)
                    let index = doc.data().nextValue
                    let id
                    if (index < 10) {
                        id = "000" + index
                    } else if (index > 9 && index < 100) {
                        id = "00" + index
                    } else if (index > 99 && index < 1000) {
                        id = "0" + index
                    } else {
                        id = index
                    }
                    let newIndex = index + 1
                    let code = "mat" + id
                    transaction.update(count, {
                        nextValue: newIndex
                    })
                    transaction.set(doc(db, "Tecnicas", code), {
                        ...payload
                    })
                    return {
                        id: code,
                        ...payload
                    }
                })
                console.log(newDoc)
                commit("ADD_ITEM", newDoc)
                return true
            } catch (e) {
                console.log('error', e)
            }
        },
        async updateItemName({
            commit
        }, payload) {
            try {
                await updateDoc(doc(db, 'Tecnicas', payload.id), {
                    name: payload.name
                })
                commit('UPDATE_ITEM_NAME', payload)

            } catch (e) {
                console.log('error', e)
            }
        },
        async updateItemDefinition({
            commit
        }, payload) {
            try {

                await updateDoc(doc(db, 'Tecnicas', payload.id), {
                    definition: payload.definition
                })
                commit('UPDATE_ITEM_DEFINITION', payload)
            } catch (e) {
                console.log('error', e)
            }
        },
        async deleteItemById({
            commit
        }, payload) {
            try {

                await deleteDoc(doc(db, 'Tecnicas', payload))
                commit('REMOVE_ITEM_BY_ID', payload)
            } catch (e) {
                console.log('error', e)
            }
        },
        async getItemObjects({
            commit
        }, payload) {
            try {
                onSnapshot(doc(db, 'Tecnicas/' + payload + '/items'), qS => {
                    var arr = []
                    qS.forEach(doc => {
                        arr.push(doc.id)
                    })
                    commit('SET_ITEM_OBJECTS', {
                        id: payload,
                        data: arr
                    })
                    return arr
                })
            } catch (e) {
                console.log('error', e)
            }

        },
        getStatistics({
            commit,
            rootGetters
        }) {
            rootGetters.allItems.forEach(item => {
                item.techniques.forEach(elem => {
                    commit("SUM_ONE_TO_STATISTIC", elem)
                })
            })
            return true
        },
        async setTecnicas() {
            let tecnicas = [{
                    id: 'tec0001',
                    name: 'Abrasión',
                    definition: 'Áreas frotadas o rascadas causadas por una pérdida gradual de material en la superficie debido a la fricción, desgaste o roce de un objeto o material contra sí mismo o cualquier otro objeto o material suele ser más duro. La abrasión puede ser un intento deliberado para suavizar, limpiar o pulir la superficie. También puede ser un proceso de deterioro que se produce a través del tiempo como resultado de la erosión o la manipulación.',
                    source: ''
                },
                {
                    id: 'tec0002',
                    name: 'Acuarela',
                    definition: 'Pintura al agua en pinceladas transparentes de color, para diferenciar el método opaco use gouache (aguada).',
                    source: ''
                },
                {
                    id: 'tec0003',
                    name: 'Acuñación',
                    definition: 'Se refiere a todo el proceso de producción de monedas.',
                    source: ''
                },
                {
                    id: 'tec0004',
                    name: 'Aguafuerte',
                    definition: 'Proceso de la talla en el cual el diseño se trabaja en una sustancia de prueba de ácido que cubre la placa de impresión del metal; la placa se expone al ácido, que grabó al aguafuerte la placa donde se expone el metal. Para el solo paso de exponer la placa al ácido, use “morder.”',
                    source: ''
                },
                {
                    id: 'tec0005',
                    name: 'Aguatinta',
                    definition: 'Proceso de impresión en el que se aplica resina u otra sustancia a una placa para formar una base porosa. La placa es luego calentada y grabada, produciendo un rango de valores tonales. Combinado a menudo con el trabajo en líneas.',
                    source: ''
                },
                {
                    id: 'tec0006',
                    name: 'Alisado',
                    definition: 'Fijación manual de cuero o parche, realizada cuando la piel es muy fina y delicada o cuando se requiere un lado de grano excepcionalmente suave, como con el pergamino.',
                    source: ''
                },
                {
                    id: 'tec0007',
                    name: 'Bordado',
                    definition: 'Se refiere al proceso mediante el cual una aguja e hilo o alambre fino son usados para coser diseños decorativos en tela, cuero, papel, u otro material. También puede referirse al procesos usado para crear imitaciones de bordado a mano con una máquina. Para la técnica de patrones de trama que teje patrones de sobrerrelieve en un textil tejido, use "brocado".',
                    source: ''
                },
                {
                    id: 'tec0008',
                    name: 'Bruñido',
                    definition: 'Hacer brillante o lustroso un objeto mediante fricción con una herramienta que compacta o suaviza.',
                    source: ''
                },
                {
                    id: 'tec0009',
                    name: 'Cocción',
                    definition: 'Se refiere al proceso de preparar, curar o voluntariamente transformar materiales como arcilla, sílice o vidrio mediante el calor.',
                    source: ''
                },
                {
                    id: 'tec0010',
                    name: 'Cosido',
                    definition: 'Se refiere a la costura de materiales con hilo, cordón u otro filamento mediante una aguja o lezna. En el contexto de encuadernación, úsase especificamente para la unión de las hojas o la unión de pliegos uno por uno pasando hilos o alambres por detrás.',
                    source: ''
                },
                {
                    id: 'tec0011',
                    name: 'Curtido',
                    definition: 'Proceso irreversible de tratamiento de pellejos o pieles con taninos para producir cuero. Diferente del “curtido en blanco”, reversible por lavado.',
                    source: ''
                },
                {
                    id: 'tec0012',
                    name: 'Dibujo',
                    definition: 'Producción de formas visibles principalmente por delineación, por lo general por el uso directo de material o instrumento a la superficie del apoyo.',
                    source: ''
                },
                {
                    id: 'tec0013',
                    name: 'Dorado',
                    definition: 'Aplicación superficial de metal en forma de láminas, polvo aplicado directamente a la superficie, polvo mezclado con un aglutinante, u otras formas para aproximar el efecto de metal sólido o incrustado.',
                    source: ''
                },
                {
                    id: 'tec0014',
                    name: 'Encuadernación',
                    definition: 'El proceso, que incluye procedimientos distintos hecho a mano o a máquina, que aseguran las hojas o uniones, generalmente dentro de las cubiertas, para formar un libro. La producción de libros, el proceso de montaje del libro terminado, el concepto de obtener hojas de las secciones de un texto con el fin de mantenerlos en buen estado y protegerlos.',
                    source: ''
                },
                {
                    id: 'tec0015',
                    name: 'Engobe',
                    definition: 'Arcilla fina que, al ser mezclada con agua, se convierte en un fluido de consistencia cremosa utilizada en moldes, revestimiento, decoración y reparación de piezas cerámicas. En estado natural, contiene suficiente fundente para ser utilizada en revestimiento y decoración sin necesidad de aditivos. Para un vidriado de cerámica con un alto contenido de barbotina, use "vidriado de barbotina."',
                    source: ''
                },
                {
                    id: 'tec0016',
                    name: 'Ensamblado',
                    definition: 'Acoplamiento de varias partes para hacer un todo operativo',
                    source: ''
                },
                {
                    id: 'tec0017',
                    name: 'Esmaltado',
                    definition: 'Proceso de aplicación de una cobertura vítrea al metal, cerámica, vidrio u otras superficies por fusión, usando calor en un horno o incinerador, con el resultado de crear una superficie suave y dura.',
                    source: ''
                },
                {
                    id: 'tec0018',
                    name: 'Estampado',
                    definition: 'Técnica de alterar cualquier configuración lisa, decoración de superficie o composición estructural con un motivo repetido, en particular en textiles.',
                    source: ''
                },
                {
                    id: 'tec0019',
                    name: 'Forjado',
                    definition: 'Dar forma a un objeto de metal con calor y martilleo.',
                    source: ''
                },
                {
                    id: 'tec0020',
                    name: 'Fotografía',
                    definition: 'Arte o proceso de realizar fotografías, es decir, imágenes producidas por acción química de la luz sobre una película, vidrio, papel o metal sensibles.',
                    source: ''
                },
                {
                    id: 'tec0021',
                    name: 'Fundición',
                    definition: 'Proceso de fusión y vaciado de materiales tales como metal o vidrio, dentro de un molde; para el vidrio, incluye la amalgama.',
                    source: ''
                },
                {
                    id: 'tec0022',
                    name: 'Grabado',
                    definition: 'El proceso de huecograbado en la que para el diseño se realiza una incisión en una plancha de impresión, por lo general una placa plana de cobre, con la ayuda de un buril o punzón que se tiene en la palma de la mano y se empuja contra el cobre para cortar las líneas que comprenden ranuras en forma de V. La placa se entinta arriba, se limpia de manera que la tinta se retenga en las ranuras y luego forzada a salir bajo presión del proceso de impresión para crear líneas en el papel. La técnica fue desarrollada por primera vez en el siglo XV en Alemania, probablemente por los orfebres que deseaban llevar un registro de los diseños que se habían grabado en sus productos. El proceso es distinto de "grabado en madera (proceso) ", que es un proceso para la impresión en relieve ; " del corte de madera (proceso)" se refiere a bloques de madera de grabar para la impresión. Históricamente, "grabado" a veces se ha utilizado incorrectamente para referirse a todos los procesos de grabado, particularmente cualquier procedimiento que emplea placas de impresión. Para el paso de una sola incisión de una inscripción o el diseño en cualquier superficie, no sólo una plancha de impresión, vea "grabado (incisión)."',
                    source: ''
                },
                {
                    id: 'tec0023',
                    name: 'Impresión',
                    definition: 'Producir marcas o decoración indentadas en una superficie presionando con una herramienta de formado. Se utiliza en especial en referencia a decorados creados presionando un metal con forma o un sello de madera o bien una rueda dentada en arcilla blanda antes de la cocción.',
                    source: ''
                },
                {
                    id: 'tec0024',
                    name: 'Laminado',
                    definition: 'Moldeado por la unión de capas superpuestas de uno más materiales.',
                    source: ''
                },
                {
                    id: 'tec0025',
                    name: 'Litografía',
                    definition: 'Proceso de impresión planográfica en el cual el dibujo es depositado sobre la piedra o placa con una sustancia grasa, y la superficie es químicamente tratada para aceptar tinta sólo en las áreas grasas.',
                    source: ''
                },
                {
                    id: 'tec0026',
                    name: 'Manuscrito',
                    definition: 'Formado de letras y palabras a mano.',
                    source: ''
                },
                {
                    id: 'tec0027',
                    name: 'Modelado de arcilla',
                    definition: 'Generalmente, uso del material maleable tal como cera o arcilla para crear una forma que es tridimensional. Específicamente, en el arte de cerámica, el formar un objeto de cerámica original o el modelo de los cuales se hace un molde.',
                    source: ''
                },
                {
                    id: 'tec0028',
                    name: 'Moldeado',
                    definition: 'Dar forma a algo utilizando un molde. Se refiere habitualmente a presionar un material en el molde, diferente a vaciar material líquido en el molde, para lo cual prefiera “vaciado”.',
                    source: ''
                },
                {
                    id: 'tec0029',
                    name: 'Moldeo en barbotina',
                    definition: 'El proceso de formar el vidriado por engobe que se vierte en el yeso moldeado.',
                    source: ''
                },
                {
                    id: 'tec0030',
                    name: 'Pintado a mano',
                    definition: 'Úsese especialmente para describir imágenes pintadas manualmente, para distinguirlas de imágenes traspasadas mecánicamente, como sobre alfarería, textiles, y otros objetos. Para la adición manual de color a una imagen existente, use “coloreado a mano”.',
                    source: ''
                },
                {
                    id: 'tec0031',
                    name: 'Pintura',
                    definition: 'La aplicación de pintura a una superficie principalmente para protección o para aplicar un color general. Para la aplicación de pigmentos a una superficie para crear una imagen expresiva o comunicativa, úsese "pintura" (elaboración de imágen).',
                    source: ''
                },
                {
                    id: 'tec0032',
                    name: 'Pintura al óleo',
                    definition: 'Arte o práctica de producir obras de creación con óleo, es decir, pigmento suspendido en aceites vegetales que se secan. Proviene al menos de la Edad Media europea y fue ampliamente adoptada para la pintura de caballete en el siglo XV.',
                    source: ''
                },
                {
                    id: 'tec0033',
                    name: 'Policromía',
                    definition: 'La práctica de pintar o decorar en varios colores; un término usado fundamentalmente en relación con escultura, decoración arquitectónica, cerámica, y diversos artefactos antiguos. Para el atributo que describe el resultado de este proceso, use "policromo".',
                    source: ''
                },
                {
                    id: 'tec0034',
                    name: 'Proceso de gelatina sobre placa seca',
                    definition: 'Proceso fotográfico que emplea plata en un fijador de gelatina sobre una placa fotográfica de vidrio.',
                    source: ''
                },
                {
                    id: 'tec0035',
                    name: 'Repujado',
                    definition: 'En trabajo en metales, técnica de decoración de una superficie martillando el reverso del objeto, a veces sobre un molde de madera tallado al intaglio, para crear diseños en relieve.',
                    source: ''
                },
                {
                    id: 'tec0036',
                    name: 'Serigrafía',
                    definition: 'Técnica de impresión por estencil en donde la tinta o colorante se fuerzan a través de una malla, tradicionalmente de seda, en el que un diseño se ha formado al detener a cabo ciertas áreas.',
                    source: ''
                },
                {
                    id: 'tec0037',
                    name: 'Soldadura',
                    definition: 'Se refiere al proceso de ensamblar dos pedazos de metal ablandado en ambas superficies que se ensamblarán por el uso del calor, con o sin la inserción de metal de relleno y con o sin el uso de presión. Para el proceso que ensambla el metal que implica necesariamente la inserción de soldadura pero no es necesario derretir las superficies que se ensamblarán, ver “soldar” para el proceso realizado en temperaturas bajo los 750 grados centígrados, o “soldar” para el proceso similar realizado en las temperaturas que se extienden entre 750 y 900 grados centígrados.',
                    source: ''
                },
                {
                    id: 'tec0038',
                    name: 'Soplado de vidrio',
                    definition: 'Proceso de dar forma al vidrio soplando aire a través de un tubo de soplado dentro de un globo de vidrio fundido.',
                    source: ''
                },
                {
                    id: 'tec0039',
                    name: 'Tallado',
                    definition: 'Acto de dar forma a la madera, piedra u otro material por corte o incisión.',
                    source: ''
                },
                {
                    id: 'tec0040',
                    name: 'Tejido',
                    definition: 'Úsese generalmente para el proceso de entrelazar hilos y bandas de variados materiales, tales como caña, textil o varillas, para fabricar materiales u objetos tales como mimbre, tela, canastos o guirnaldas. Usese especificamente para el proceso de elaboración de textiles en un telar.',
                    source: ''
                },
                {
                    id: 'tec0041',
                    name: 'Teñido',
                    definition: 'Proceso de aplicación de una materia colorante que está en una solución o dispersión fina, así que al menos algo de la materia colorante es absorvida por la sustancia.',
                    source: ''
                },
                {
                    id: 'tec0042',
                    name: 'Torneado',
                    definition: 'Dar forma a objetos utilizando herramientas de corte, mientras el material del cual están hechos los objetos gira rápidamente en un torno.',
                    source: ''
                },
                {
                    id: 'tec0043',
                    name: 'Troquelado',
                    definition: 'Corte de metal o papel con troquel.',
                    source: ''
                },
                {
                    id: 'tec0044',
                    name: 'Vaciado',
                    definition: 'Acto o proceso de hacer moldes o conformar en moldes; usualmente se refiere a verter material líquido en un molde, distinto de presionar un material en un molde, para el que prefiera "moldear".',
                    source: ''
                },
                {
                    id: 'tec0045',
                    name: 'Xilografía',
                    definition: 'Proceso en relieve en el cual el dibujo es recortado e impreso a partir del extremo de la fibra de un bloque de madera. Diferente a “talla de madera (proceso)”, que es un proceso de relieve que utiliza el lado del tablón de un bloque de madera.',
                    source: ''
                },
                {
                    id: 'tec0046',
                    name: 'No Aplica',
                    definition: 'No Aplica',
                    source: ''
                },
                {
                    id: 'tec0047',
                    name: 'Extrusión',
                    definition: 'La extrusión es un proceso utilizado para crear objetos con sección transversal definida y fija. El material se empuja o se extrae a través de un troquel de una sección transversal deseada.',
                    source: ''
                },
                {
                    id: 'tec0048',
                    name: 'Confección',
                    definition: 'Producción de una cosa material a partir de la combinación de sus componentes, especialmente las que requieren un trabajo manual, como prendas de vestir, comidas y bebidas, compuestos, etc.',
                    source: ''
                },
                {
                    id: 'tec0049',
                    name: 'Moldeado por Compresión',
                    definition: 'es un proceso de conformado de piezas en el que el material, generalmente un polímero, es introducido en un molde abierto al que luego se le aplica presión para que el material adopte la forma del molde y calor para que el material reticule y adopte definitivamente la forma deseada.',
                    source: ''
                },
                {
                    id: 'tec0050',
                    name: 'Doblado',
                    definition: 'Torcer algo sobre si mismo, como tela o papel. Para las partes torcidas entre ellas, usar "pliegue"; para las arrugas resultantes, use "arruga". Para describir objetos diseñados para doblarse en una forma compacta, use "doblado (atributo)".',
                    source: ''
                },
                {
                    id: 'tec0051',
                    name: 'Termolaminado',
                    definition: 'Proceso mediante el cual se recubre el papel con una Mica Traslúcida de Polietileno mediante una máquina que utiliza calor para estos efectos',
                    source: ''
                },
                {
                    id: 'tec0052',
                    name: 'Fresado',
                    definition: 'En metalistería, el proceso de trabajar una superficie con varias formas de cuchillos giratorios para producir una superficie plana o ranurada.',
                    source: ''
                },
                {
                    id: 'tec0053',
                    name: 'Esmerilado',
                    definition: 'Afilar herramientas con bordes con una piedra húmeda de afilar o con una semejanza de ella.',
                    source: ''
                },
                {
                    id: 'tec0054',
                    name: 'Moldeo por Inyección',
                    definition: 'En ingeniería, el moldeo por inyección es un proceso semicontinuo que consiste en inyectar un polímero, cerámico o un metal en estado fundido en un molde cerrado a presión y frío, a través de un orificio pequeño llamado compuerta.',
                    source: 'Wikipedia'
                },
                {
                    id: 'tec0055',
                    name: 'Artesanal',
                    definition: 'Persona que ejercita un arte u oficio meramente mecánico. U. modernamente para referirse a quien hace por su cuenta objetos de uso doméstico imprimiéndoles un sello personal, a diferencia del obrero fabril.',
                    source: 'RAE'
                }
            ]

            try {

                await updateDoc(doc(db, 'System', 'tecnicas'), {
                    datos: tecnicas
                })
            } catch (e) {
                console.log('error', e)
            }
        },
        async updateOrigin() {
            let ids=['PC0013','PC0136','PC0137','PC0204','PC0457','PC0467','PC0468','PC0471','PC0473','PC0539','PC0540','PC0541','PC0542','PC0543','PC0544','PC0545','PC0546','PC0547','PC0548','PC0549','PC0550','PC0559','PC0561','PC0565','PC0566','PC0568','PC0571','PC0573','PC0574','PC0575','PC0576','PC0578','PC0579','PC0580','PC0581','PC0582','PC0583','PC0586','PC0587','PC0588','PC0589','PC0590','PC0591','PC0592','PC0593','PC0594','PC0595','PC0596','PC0597','PC0599']
            ids.forEach(async el=>{
                await updateDoc(doc(db, 'ListaObjetos', el), {
                    origin: "Puerto Cristal, Región de Aysén, Chile."
                })
            })

        }
    },
    getters: {
        items: state => {
            return state.items
        },
        abcItems: state => {
            let arr = []
            state.items.forEach(item => {
                let char = item.name.split('')[0]
                let exists = arr.find(el => el === char.toUpperCase())
                if (exists === undefined) {
                    arr.push(char.toUpperCase())
                }
            })
            return arr
        },
        itemsByIndex: state => payload => {
            let arr = []
            state.items.forEach(item => {
                let char = item.name.split('')[0]
                if (char.toUpperCase() === payload) {
                    if (item.definition.split('').length >= 200) {
                        arr.push({
                            ...item,
                            shortDefinition: item.definition.slice(0, 199),
                            isLong: true
                        })
                    } else {
                        arr.push({
                            ...item,
                            shortDefinition: item.definition,
                            isLong: false
                        })
                    }
                }
            })
            return arr
        },

        name: state => payload => {
            let item = state.items.find(el => el.id === payload)
            if (item) {
                return item.name
            } else {
                return ''
            }
        },
        statistics: state => { //retorna lista de elementos ordenados de mayor a menor estadistica
            let arr = []
            let pos = 0
            state.items.forEach(item => {
                pos = 0
                arr.forEach(el => {
                    if (el.estadistica > item.estadistica) {
                        pos++
                    }
                })
                arr.splice(pos, 0, item)
            })
            return arr
        }
    },
    mutations: {
        SET_ITEMS(state, payload) {
            state.items = payload.list
        },
        ADD_ITEMS(state, payload) {
            let val = state.items.find(el => el.id === payload.id)
            if (val) {
                val.name = payload.data.name
                val.definition = payload.data.name
                if (payload.data.source) {
                    val.source = payload.data.source
                } else {
                    val.source = ""
                }
            } else {
                state.items.push({
                    id: payload.id,
                    ...payload.data,
                    estadistica: 0
                })
            }
        },
        CALC_PERCENTS(state) {
            let max = 0
            state.items.forEach(element => {
                if (element.estadistica > max) {
                    max = element.estadistica
                }
            })
            state.items.forEach(element => {
                element.porcentaje = element.estadistica / max
            })
        },
        CLEAR_ITEMS(state) {
            state.items = []
        },
        SUM_ONE_TO_STATISTIC(state, payload) {
            let elem = state.items.find(item => item.id === payload)
            if (elem) {
                elem.estadistica = elem.estadistica + 1
            }
        },
        REMOVE_ITEM_BY_ID(state, payload) {
            let index = state.items.findIndex(item => item.id === payload)
            if (index >= 0) {
                state.items.splice(index, 1)
            }
        },
        UPDATE_ITEM_NAME(state, payload) {
            let elem = state.items.find(item => item.id === payload.id)
            if (elem) {
                elem.name = payload.name
            }
        },
        UPDATE_ITEM_DEFINITION(state, payload) {
            let elem = state.items.find(item => item.id === payload.id)
            if (elem) {
                elem.name = payload.definition
            }
        },
        SET_ITEM_OBJECTS(state, payload) {
            let result = state.items.find(elem => elem.id === payload.id)
            if (result) {
                result.elements = payload.data
            }
        }
    }
}