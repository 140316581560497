<template>
  <div class="container">
    <h3 v-html="'Agregar Nuevo Concepto'"></h3>
    <p
      class="full-length"
      v-html="'Complete los siguientes campos para agregar la nueva categor&iacute;a'"
    ></p>
    <div>
      <h4>Datos del Concepto</h4>
      <label>Nombre</label>
      <input name="name" v-model="name" />
      <label v-html="'Definici&oacute;n'"></label>
      <textarea name="definition" v-model="definition" id="definition" rows="6"></textarea>
      <h4 v-html="'Clasificaci&oacute;n'"></h4>
      <div class="tabs">
        <button
          v-on:click="searchMethod=true"
          :class="{'selected':searchMethod}"
          v-html="'Selecci&oacute;n Manual'"
        ></button>
        <button
          v-on:click="searchMethod=false"
          v-bind:class="{'selected':!searchMethod}"
          v-html="'B&uacute;squeda por Concepto'"
        ></button>
      </div>
      <div class="list" v-show="searchMethod">
        <label
          v-html="'Selecci&oacute;ne manualmente la categor&iacute;a correspondiente, la jerarqu&iacute;a se desplegar&aacute; autom&aacute;ticamente.'"
        ></label>
        <ul class="parents">
          <li v-for="item in parents" :key="item.id">
            <i class="material-icons">subdirectory_arrow_right</i>
            {{item.name}}
          </li>
        </ul>
        <select name="category" id="category" v-model="category">
          <option
            value="none"
            v-html="'Seleccione la categor&iacute;a'"
            v-if="categorySelection.length>0"
          ></option>
          <option
            value="none"
            v-html="'No hay m&aacute;s subcategor&iacute;as'"
            v-if="categorySelection.length===0"
          ></option>
          <option v-for="item in categorySelection" :key="item.id" :value="item.id">{{item.name}}</option>
        </select>
        <button @click="setCategories" :disabled="category==='none'">
          <i class="material-icons">arrow_forward</i>
        </button>
      </div>
      <div class="list" v-show="!searchMethod">
        <label
          v-html="'Ingrese el concepto asociado y la jerarqu&iacute;a se desplegar&aacute; autom&aacute;ticamente.'"
        ></label>
        <ul class="parents">
          <li v-for="item in parents" :key="item.id">
            <i class="material-icons">subdirectory_arrow_right</i>
            {{item.name}}
          </li>
        </ul>
        <div>
          <input
            type="text"
            v-model="searchedValue"
            placeholder="Buscar concepto"
            ref="search"
            @focusin="optionsDisplayed=true"
            @focusout="checkFocus"
            @keyup.down="(selectedWord<(matchedWords.length-1)) ? selectedWord=selectedWord+1 : selectedWord=0"
            @keyup.up="(selectedWord>0) ? selectedWord=selectedWord-1 : selectedWord=matchedWords.length-1"
            @keyup.enter="selectWord()"
          />
          <div class="options" v-show="optionsDisplayed" ref="options">
            <ul>
              <li
                v-for="(item,index) in matchedWords"
                :key="index"
                :class="{'selected':selectedWord===index}"
                @click="selectWord(index)"
              >{{item.name}}</li>
            </ul>
          </div>
        </div>
        <button @click="setParents" :disabled="searchedValue==='none'">
          <i class="material-icons">arrow_forward</i>
        </button>
      </div>
    </div>
    <p class="btn">
      <button
        class="add-btn"
        :disabled="!(name.length>3&&definition.length>10&&parents.length>0)"
        @click="addConcept"
      >Agregar</button>
    </p>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import app from "@/firebase";
import {
  getFirestore,
  collection,
  addDoc,
} from "firebase/firestore";
const db = getFirestore(app);
export default {
  name: "Add-Concept",
  data: function() {
    return {
      name: "",
      definition: "",
      parents: [],
      category: "none",
      categorySelection: [],
      currentCategory: null,
      searchMethod: true,
      searchedValue: "",
      selectedWord: 0,
      currentId: "",
      optionsDisplayed: false
    };
  },
  props: ["concepts"],
  computed: {
    ...mapGetters(["categories"]),
    matchedWords() {
      if (this.searchedValue === "") {
        return this.concepts;
      } else {
        return this.concepts.filter(item =>
          item.name.toLowerCase().includes(this.searchedValue.toLowerCase())
        );
      }
    }
  },
  mounted() {
    this.setCategories();
  },
  watch: {
    categories() {
      this.setCategories();
    }
  },
  methods: {
    ...mapActions(["getLastIndex"]),
    ...mapMutations([
      "SET_LOADING",'SET_LOADING_OPACITY',
      "SET_ERROR_MESSAGE",
      "SET_SUCCESS_MESSAGE_PARAMETERS",
      "SET_MESSAGE_PARAMETERS"
    ]),
    setCategories() {
      if (this.category === "none") {
        this.categorySelection = this.categories;
      } else {
        var cat = null;
        if (this.parents.length > 0) {
          cat = this.currentCategory.find(doc => doc.id === this.category);
        } else {
          cat = this.categories.find(doc => doc.id === this.category);
        }
        this.parents.push({ id: this.category, name: cat.name });
        this.categorySelection = cat.subCategories;
        this.currentCategory = cat.subCategories;
        this.category = "none";
      }
    },
    setParents() {
      const value = this.concepts.find(doc => doc.id === this.currentId);
      this.parents = value.parents;
      this.parents.push({ id: value.id, name: value.name });
    },
    checkFocus() {
      setTimeout(() => {
        this.optionsDisplayed = false;
      }, 300);
    },
    async addConcept() {
      var arr = this.concepts.filter(
        item => item.name.toLowerCase() === this.name.toLowerCase()
      );
      if (arr.length > 0) {
        this.SET_ERROR_MESSAGE({
          state: true,
          title: "Concepto ya Existe",
          text:
            "Este concepto ya est&aacute; registrado en la base de datos, ingrese uno diferente.",
          button: "Acepar"
        });
      } else {
        this.SET_LOADING(true);
        this.SET_LOADING_OPACITY(true)
        let path = "Categoria";
        this.parents.forEach((elem) => {
            path = path + "/" + elem.id + "/Categorias";
        });
        try{
        await addDoc(collection(db,"path"),{
            definition: this.definition,
            name: this.name})
            this.SET_MESSAGE_PARAMETERS({
                isVisible: true,
                type: "success",
                icon: "done",
                title: "Concepto Agregado",
              message: "El nuevo concepto se ha agregado exitosamente.",
                buttonAction: "",
                button: "Aceptar",
                respondTo: "successObjectCreate",
                action: 0,
              });
              
        this.SET_LOADING(false);
        this.SET_LOADING_OPACITY(false)
        }catch(error){
          this.SET_MESSAGE_PARAMETERS({
            isVisible: true,
            type: "error",
            icon: "error",
            title: "Ocurri&oacute; un error",
              message:
                "Ocurri&oacute; un error, no se pudo agregar el nuevo concepto. Intente nuevamente m&aacute;s tarde."+error,
              
            buttonAction: "",
            button: "Aceptar",
            respondTo: "error",
            action: 0,
          });
        this.SET_LOADING(false);
        this.SET_LOADING_OPACITY(false)
          console.log(error)
        }
      }
    },
    selectWord(payload) {
      if (payload !== undefined) {
        this.currentId = this.matchedWords[payload].id;
        this.searchedValue = this.matchedWords[payload].name;
        this.selectedWord = payload;
      } else {
        this.currentId = this.matchedWords[this.selectedWord].id;
        this.searchedValue = this.matchedWords[this.selectedWord].name;
      }
      this.optionsDisplayed = false;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../assets/sass/variables";
div.container {
  width: 100%;
  padding: 0px;
  display: grid;
  grid-gap: 5px;
  grid-template-columns: auto;
  h2,
  h3,
  h4,
  div {
    label,
    input,
    select,
    textarea {
      width: 100%;
    }
  }
  h3 {
    margin-block-start: 0px;
    text-align: center;
  }
  .tabs {
    padding: 0px;
    width: 100%;
    top: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    button {
      background-color: transparent;
      border: none;
      border-bottom: solid 6px $text-light;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      cursor: pointer;
      padding: 8px 12px;
      width: auto;
      color: $text;
      font-size: 1.1em;
    }
    button:hover,
    button.selected {
      border-bottom-color: $theme;
      color: $theme;
    }
    button:nth-child(1) {
      border-right: solid 1px lightgray;
    }
  }
  .list {
    display: grid;
    grid-template-columns: auto 35px;
    grid-template-rows: auto auto 35px;
    grid-gap: 5px;
    label {
      grid-column-start: 1;
      grid-column-end: 3;
    }
    select {
      height: 35px;
      margin-block-end: 0px;
      margin-block-start: 0px;
    }
    button {
      border: none;
      height: 100%;
      background-color: $theme;
      border-radius: 25px;
      color: white;
      height: 35px;
      margin-block-end: 0px;
      margin-block-start: 0px;
      i.material-icons {
        vertical-align: middle;
      }
    }
    button:disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }
    ul.parents {
      padding-left: 15px;
      grid-column-start: 1;
      grid-column-end: 3;
      margin-block-end: 0px;
      margin-block-start: 0px;
      li {
        list-style: none;
        text-decoration: underline;
        margin-block-end: 2px;
        margin-block-start: 2px;
        cursor: pointer;
        i.material-icons {
          margin-top: 0px;
          font-size: 20px;
          vertical-align: middle;
          margin-right: 3px;
        }
      }
    }
    ul.parents {
      li:nth-child(2) {
        margin-left: 5px;
      }
      li:nth-child(3) {
        margin-left: 10px;
      }
      li:nth-child(4) {
        margin-left: 15px;
      }
      li:nth-child(5) {
        margin-left: 20px;
      }
      li:nth-child(6) {
        margin-left: 25px;
      }
      li:nth-child(7) {
        margin-left: 30px;
      }
    }
    div {
      width: 100%;
      background-color: white;
      padding: 0px;
      overflow: visible;
      input {
        height: 100%;
        border: solid 1px lightgray;
        margin-block-start: 0%;
        margin-block-end: 0%;
        cursor: text;
      }
      .options {
        display: inline-block;
        position: relative;
        width: 100%;
        height: 0px;
        z-index: 5 !important;
        overflow: visible;
        margin-top: 0px;
        height: fit-content;
        ul {
          background-color: white;
          border: solid 1px $text;
          margin-block-start: 0px;
          margin-block-end: 0px;
          padding-inline-start: 0px;
          li {
            padding-left: 10px !important;
            line-height: 27px;
            color: $text;
            margin-block-start: 0px;
            margin-block-end: 0px;
            padding: 3px 0px;
            cursor: pointer;
            list-style: none;
          }
          li.selected,
          li:hover {
            background-color: $theme;
            color: white;
            font-weight: 500;
          }
        }
      }
    }
  }
  p.btn {
    text-align: center;
    .add-btn {
      margin-top: 5px;
      background-color: $theme;
      color: whitesmoke;
      border: none;
      font-weight: 400;
      cursor: pointer;
      border-radius: 10px;
      border: none;
      width: auto;
      font-size: 18px;
      line-height: 32px;
      padding: 8px 25px;
      vertical-align: middle;
    }
    button.add-btn:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}
</style>
