<template>
  <div>
    <ProfileCard v-if="profileDisplayed" />
    <div class="system-container" :class="{'collapsed':adminBarCollapsed}">
      <div class="main-container" :class="{'user':adminBarDisplayed&&isUserLoged}">
        <Navbar v-if="$route.name!=='printBox'" />
        <router-view name="system" />
      </div>
      <AdminMenu class="collapsable-menu" v-show="adminBarDisplayed&&isUserLoged"/>
      <button class="collapse outer" @click="toggleCollapse" v-show="adminBarDisplayed&&isUserLoged&&adminBarCollapsed">
      <i class="material-icons">chevron_right</i>
    </button>
    </div>
  </div>
</template>
<script>
import Navbar from "@/components/Navbar.vue";
import ProfileCard from "@/components/ProfileCard.vue";
import AdminMenu from "@/components/AdminMenu";
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "System-app",
  components: {
    Navbar,
    ProfileCard,
    AdminMenu
  },
  computed: {
    ...mapGetters([
      "profileDisplayed",
      "isUserLoged",
      "adminBarDisplayed",
      "adminBarCollapsed"
    ])
  },
  methods: {
    ...mapMutations(["SET_ADMIN_BAR_COLLAPSE"]),
    toggleCollapse() {
      this.SET_ADMIN_BAR_COLLAPSE(!this.adminBarCollapsed);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "./../assets/sass/variables";
body {
  background-color: rgb(235, 235, 235);
}
button.collapse {
    background-color: $theme-d3;
    padding: 5px;
    border: solid 2px transparent;
    border-radius: 25px;
    color: white;
    position: fixed;
    margin-bottom: 10px;
    margin-top: auto;
    bottom: 0;
    height: auto;
    width: auto;
    margin-left: 16px;
    cursor: pointer;
    i.material-icons {
      vertical-align: middle;
      width: 100%;
      font-size: 28px;
      text-align: center;
    }
    &:hover {
      border-color: white;
    }
  }
.system-container {
  display: grid;
  grid-template-columns: 260px calc(100% - 260px);
  grid-template-rows: 100%;
  
}
.system-container.collapsed {
  grid-template-columns: 70px calc(100% - 70px);
}
.collapsable-menu {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
  z-index: 10;
}
.main-container {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;
  position: relative;
  width: 100%;
}
@media (min-width: 920px) {
  .main-container.user {
    grid-column-start: 2;
    grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;
  }
  button.collapse {
    display: none;
  }
}
</style>