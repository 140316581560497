<template>
  <div class="container">
    <div class="img"></div>
    <div class="form">
      <div class="content">
        <h2>Museo Puerto Cristal</h2>
        <p>Bienvenido al Sistema de Gesti&oacute;n de Colecciones del Museo de Puerto Cristal. Por favor ingresa:</p>
        <label>Correo Electrónico</label>
        <input type="email" v-model="email" v-on:keydown.tab="checkEmail" v-on:change="checkEmail" />
        <label>Contraseña</label>
        <input
          type="password"
          v-model="password"
          v-on:keydown.tab="checkPassword"
          v-on:change="checkPassword"
        />
        <li v-for="(item,index) in errors" :key="index">{{item}}</li>
        <button :disabled="isLoginDisabled" v-on:click="onSignIn">Ingresar</button>
      </div>
    </div>
    <Loading text="Ingresando..." />
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import Loading from "@/components/Loading.vue";
export default {
  name: "Insert-section",
  components: { Loading },
  data: function() {
    return {
      email: "",
      password: "",
      errors: [],
      re: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    };
  },
  watch: {
    user: function(value) {
      if (value !== null && value !== undefined) {
        this.$router.push('/');
      }
    }
  },
  computed: {
    ...mapGetters(["user"]),
    isLoginDisabled: function() {
      if (
        this.password !== "" &&
        this.password.length >= 6 &&
        this.re.test(this.email.toLowerCase())
      ) {
        return false;
      } else {
        return true;
      }
    }
  },
  methods: {
    ...mapMutations(["SET_LOADING",'SET_LOADING_OPACITY',]),
    onSignIn: function() {
      this.SET_LOADING(true);
      this.SET_LOADING_OPACITY(false)
      this.$store.dispatch("signUserIn", {
        email: this.email,
        password: this.password
      });
    },
    checkEmail: function() {
      let val = "Ingrese un correo valido";
      var index = this.errors.indexOf(val);
      if (index > -1) {
        this.errors.splice(index, 1);
      }
      if (!this.re.test(this.email.toLowerCase())) {
        this.errors.push(val);
      }
    },
    checkPassword: function() {
      let val = "Su contrase&ntilde;a debe tener m&aacute;s de 6 caracteres";
      var index = this.errors.indexOf(val);
      if (index > -1) {
        this.errors.splice(index, 1);
      }
      if (this.password === "" || this.password.length < 6) {
        this.errors.push(val);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./../assets/sass/variables";
div.container {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 60% auto;
  width: 100vw;
  height: 100vh;
  margin: 0px;
  div.img {
    background-image: url("../assets/media/Labratory.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
  }
  div.form {
    margin: 25px 5%;
    display: flex;
    align-items: center;
    div.content {
      h2 {
        color: $theme;
      }
      p,
      label,
      input {
        width: 100%;
        color: #808080;
      }
      input {
        border-radius: 3px;
        border: solid 1px $theme-d1;
      }
      li {
        color: $error-bg;
      }
      button {
        background-color: $theme-d2;
        color: $theme-l4;
        font-weight: 400;
        cursor: pointer;
        border-radius: 10px;
        border: none;
        padding: 8px 25px;
        font-size: 17px;
        margin-top: 5px;
        float: right;
      }
      button:disabled {
        opacity: 0.5;
      }
    }
  }
}
@media (min-width: 768px) {
  div.container {
    grid-template-columns: 50% 50%;
    grid-template-rows: auto;
  }
}
@media (min-width: 992px) {
  div.container {
    grid-template-columns: 60% 40%;
    grid-template-rows: auto;
    div.form {
      padding: 25px 10%;
    }
  }
}
</style>