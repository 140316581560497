<template>
  <div class="autocomplete">
    <div :style="'margin-left:'+margin">
      <input
        :style="'height:'+height"
        type="text"
        v-model="text"
        :placeholder="alternativeText"
        ref="search"
        :class="{'no-border':!border}"
        @focusin="displayed=true"
        @focusout="checkFocus"
        @keyup.down="(current<(matchedWords.length-1)) ? current=current+1 : current=0"
        @keyup.up="(current>0) ? current=current-1 : current=matchedWords.length-1"
        @keyup.enter="selectItem({jump:true})"
        @input="$emit('get-result', {text:text,doc:result})"
      />
      <div class="options" v-show="displayed">
        <ul>
          <li
            v-for="(item,index) in matchedWords"
            :key="index"
            :class="{'selected':current===index}"
            @click="selectItem({idx:index, jump:true})"
          >
            {{item.name}}
            <i
              class="material-icons"
              @click="displayDefinition(index)"
              v-show="showInfo&&item.definition!==null&&item.definition!==undefined&&item.definition!==''"
            >info</i>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations } from "vuex";
export default {
  name: "Autocomplete-component",
  props: {
    list: {
      default() {
        return [];
      }
    },
    alternativeText: {},
    border: Boolean,
    height: {},
    margin: {},
    showInfo: Boolean
  },
  data() {
    return {
      current: 0,
      displayed: false,
      result: {},
      text: "",
      messageData: {}
    };
  },
  computed: {
    matchedWords() {
      if (this.text === "" || this.text === undefined) {
        if (this.showInfo) {
          return this.list;
        } else {
          return [];
        }
      } else {
        return this.list.filter(item =>
          item.name.toLowerCase().includes(this.text.toLowerCase())
        );
      }
    }
  },
  watch:{
    text(value){
      if(this.result.name!==undefined&&this.result.name!==value){
        this.result={}
      }
    }
  },
  methods: {
    ...mapMutations(["SET_MESSAGE_PARAMETERS"]),
    selectItem(payload) {
      if (payload.idx !== undefined) {
        this.result = this.matchedWords[payload.idx];
        this.text = this.result.name;
      } else {
        this.result = this.matchedWords[this.current];
        this.text = this.result.name;
      }
      if (payload.jump) {
        this.$refs.search.blur();
        this.displayed = false;
        this.$emit("get-result", { text: this.text, doc: this.result });
      }
    },
    checkFocus() {
      setTimeout(() => {
        this.displayed = false;
      }, 300);
    },
    displayDefinition(payload) {
      this.SET_MESSAGE_PARAMETERS({
        isVisible: true,
        type: "",
        icon: "menu_book",
        title: this.matchedWords[payload].name,
        message:
          "<strong>Definici&oacute;n: </strong>" +
          this.matchedWords[payload].definition,
        buttonAction: "",
        button: "Aceptar",
        action: 0,
        respondTo: "autocomplete"
      });
    },
    messageResponse(payload) {
      this.messageData.isVisible = payload;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "./../assets/sass/variables";
.autocomplete {
  display: block !important;
  div {
    input {
      height: 100%;
      width: 100%;
      margin-block-start: 0%;
      margin-block-end: 0%;
      cursor: text;
    }
    input.no-border {
      border: none;
    }
    .options {
      display: block;
      position: relative;
      width: 100%;
      height: 0px;
      z-index: 5 !important;
      overflow: visible;
      margin-top: 0px;
      height: fit-content;
      ul {
        background-color: white;
        border: solid 1px $text;
        margin-block-start: 0px;
        margin-block-end: 0px;
        padding-inline-start: 0px;
        li {
          padding-left: 10px;
          line-height: 27px;
          color: $text;
          margin-block-start: 0px;
          margin-block-end: 0px;
          padding: 3px 0px;
          cursor: pointer;
          list-style: none;
          i.material-icons {
            color: gray;
            font-size: 25px;
            vertical-align: middle;
            cursor: pointer;
            float: right;
            margin: 1px 3px;
            height: 100%;
          }
        }
        li.selected,
        li:hover {
          background-color: $theme;
          color: white;
          font-weight: 500;
          i.material-icons {
            color: white;
          }
        }
      }
    }
  }
}
</style>