<template>
  <div class="page-container">
    <div class="show-selector">
      <div>
        <label>Ordenar por:</label>
        <select name="show" id="show" v-model="order">
          <option :value="'name'" v-html="'Nombre'"></option>
          <option :value="'id'" v-html="'C&oacute;digo Identificador'"></option>
        </select>
        <label>Mostrar:</label>
        <select name="show" id="show" v-model="itemsPerPage">
          <option :value="8">8</option>
          <option :value="16">16</option>
          <option :value="32">32</option>
          <option :value="50">50</option>
          <option :value="100">100</option>
        </select>
      </div>
    </div>
    <div class="card-container" v-if="setup.link">
      <router-link
        :to="'/item/' + item.id"
        class="card"
        v-for="item in list"
        :key="item.id"
      >
        <div
          class="card-img"
          :style="'background-image:url(' + item.referenceImage.url + ')'"
          v-if="item.referenceImage"
        ></div>
        <div class="card-img" v-else>
          <i class="material-icons">insert_photo</i>
        </div>
        <div class="middle-icon" v-if="!item.content">
          <div :class="setup.iconPosition">
            <i class="material-icons">add</i>
          </div>
        </div>

        <div class="full-content" :class="setup.iconPosition" v-else>
          <i class="material-icons">add</i>
          <div class="inner-content">
            <h4 v-html="item.content.title"></h4>
            <p v-html="item.content.content"></p>
          </div>
        </div>
        <div class="card-content">
          <h4>{{ item.name }}</h4>
          <p>{{ item.id }}</p>
          <p class="btns" v-if="setup.buttons.length > 0">
            <button
              class="btn-container"
              v-for="(el, index) in setup.buttons"
              :key="item.id + '-' + index"
              @click="$emit('response', { id: item.id, action: el.action })"
            >
              <div class="btn">
                <p>{{ el.label }}</p>
                <i class="material-icons">{{ el.icon }}</i>
              </div>
            </button>
          </p>
        </div>
      </router-link>
    </div>
    <div class="card-container" v-else>
      <div class="card" v-for="item in list" :key="item.id">
        <div
          class="card-img"
          :style="'background-image:url(' + item.referenceImage.url + ')'"
          v-if="item.referenceImage"
        ></div>
        <div class="card-img" v-else>
          <i class="material-icons">insert_photo</i>
        </div>
        <div class="middle-icon" v-if="!item.content">
          <div :class="setup.iconPosition">
            <i class="material-icons">add</i>
          </div>
        </div>

        <div class="full-content" :class="setup.iconPosition" v-else>
          <i class="material-icons">add</i>
          <div class="inner-content">
            <h4 v-html="item.content.title"></h4>
            <p v-html="item.content.content"></p>
          </div>
        </div>
        <div class="card-content">
          <h4>{{ item.name }}</h4>
          <p>{{ item.id }}</p>
          <p class="btns" v-if="setup.buttons.length > 0">
            <button
              class="btn-container"
              v-for="(el, index) in setup.buttons"
              :key="item.id + '-' + index"
              @click="$emit('response', { id: item.id, action: el.action })"
            >
              <div class="btn">
                <p>{{ el.label }}</p>
                <i class="material-icons">{{ el.icon }}</i>
              </div>
            </button>
          </p>
        </div>
      </div>
    </div>
    <Pagination
      :length="pagesNumber"
      :value="currentPage"
      v-model="currentPage"
      v-on:page-result="setPage"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import Pagination from "@/components/Pagination.vue";

import { mapGetters, mapMutations, mapActions } from "vuex";
import app from "@/firebase";
import { getFirestore, collection, query, where } from "firebase/firestore";
const db = getFirestore(app);
export default {
  name: "Page-Viewer",
  components: {
    Pagination,
  },
  props: ["setup"],
  data: function () {
    return {
      itemsPerPage: 8,
      currentPage: 1,
      order: "name",
    };
  },
  watch: {
    itemsPerPage(value) {
      this.setPage(1);
      this.SET_ITEMS_PER_PAGE(value);
      this.setCurrentList();
    },
    filteredList() {
      this.setPage(1);
      this.setCurrentList();
    },
    order(value) {
      console.log(value);
      this.SET_ORDER(value);
      this.setCurrentList();
    },
    storeOrder(value) {
      this.order = value;
    },
  },
  computed: {
    ...mapGetters({
      list: "currentItemList",
      number: "itemsPerPage",
      page: "currentPage",
      listSize: "listSize",
      hasFilters: "hasFilters",
      filteredList: "filteredList",
      nextBatchName: "getNextBatchByName",
      nextBatchId: "getNextBatchByIndex",
      document: "getDocumentById",
      pack: "getBatch",
      storeOrder: "order",
      user: "user",
    }),
    pagesNumber() {
      return Math.ceil(this.listSize / this.itemsPerPage) === 0
        ? 1
        : Math.ceil(this.listSize / this.itemsPerPage);
    },
    objectsInPage() {
      return this.list;
    },
  },
  methods: {
    ...mapActions(["getSingleItemByQuery", "getItemsByQuery"]),
    ...mapMutations([
      "SET_CURRENT_PAGE",
      "MOVE_PAGE",
      "SET_ITEMS_PER_PAGE",
      "SET_LOADING",
      "SET_LOADING_OPACITY",
      "SET_CURRENT_LIST",
      "SET_ORDER",
      "ORDER_ITEMS_BY",
      "ORDER_INDEXED_ITEMS_BY",
    ]),
    setPage(payload) {
      if (payload > 0 && payload <= this.pagesNumber) {
        this.currentPage = payload;
      } else if (payload < 1) {
        this.currentPage = this.pagesNumber;
      } else {
        this.pagesNumber = 1;
      }

      this.SET_CURRENT_PAGE(this.currentPage);
      this.setCurrentList();
    },
    async setCurrentList() {
      this.SET_LOADING(true);
      this.SET_LOADING_OPACITY(true);
      let result = [];
      let start = this.itemsPerPage * (this.currentPage - 1);
      let end = this.itemsPerPage * this.currentPage - 1;
      if (this.hasFilters) {
        result = this.filteredList.slice(start, end);
      } else {
        let q = "";
        if (this.order === "name") {
          this.ORDER_INDEXED_ITEMS_BY("name");
        } else {
          this.ORDER_INDEXED_ITEMS_BY("id");
        }
        let batch = this.pack({ start: start, end: end });
        let arr = [];
        batch.forEach(async (element) => {
          if (this.document(element.id) === undefined) {
            arr.push(element.inventory);
          }
        });
        if (arr.length > 10) {
          let count = 0;
          while (count * 10 <= arr.length) {
            let section = arr.slice(count * 10, (count + 1) * 10);
            if (section.length > 0) {
              if (this.user) {
                q = query(
                  collection(db, "ListaObjetos"),
                  where("inventory", "in", section)
                );
              } else {
                q = query(
                  collection(db, "ListaObjetos"),
                  where("inventory", "in", section),
                  where("published", "==", true)
                );
              }
              result = await this.getItemsByQuery(q);
            }
            count++;
          }
        } else if(arr.length>0){
          if (this.user) {
            q = query(
              collection(db, "ListaObjetos"),
              where("inventory", "in", arr)
            );
          } else {
            q = query(
              collection(db, "ListaObjetos"),
              where("inventory", "in", arr),
              where("published", "==", true)
            );
          }
          result = await this.getItemsByQuery(q);
        }
        result = [];
        batch.forEach((el) => {
          result.push(this.document(el.id));
        });
        /*batch.forEach(element => {
          let el = this.document(element.id);
          if (!el) { 
            let query = doc(db,"ListaObjetos",element.id);
            this.getSingleItemByQuery(query).then(snap => {
              let isAdded = false;
              for (let i = 0; i < result.length; i++) {
                console.log(result[i])
                if (this.order === "name") {
                  if (result[i].name.localeCompare(snap[0].name) === 1) {
                    result.splice(i, 0, snap[0]);
                    isAdded = true;
                    i = result.length;
                  }
                } else {
                  if (result[i].id.localeCompare(snap[0].id) === 1) {
                    result.splice(i, 0, snap[0]);
                    isAdded = true;
                    i = result.length;
                  }
                }
              }
              if (!isAdded) {
                result.push(snap[0]);
              }
            });
          } else {
            let isAdded = false;
            for (let i = 0; i < result.length; i++) {
              if (this.order === "name") {
                if (result[i].name.localeCompare(el.name) === 1) {
                  result.splice(i, 0, el);
                  isAdded = true;
                  i = result.length;
                }
              } else {
                if (result[i].id.localeCompare(el.id) === 1) {
                  result.splice(i, 0, el);
                  isAdded = true;
                  i = result.length;
                }
              }
            }
            if (!isAdded) {
              result.push(el);
            }
          }
        });*/
      }
      console.log(result);
      this.SET_CURRENT_LIST(result);
      this.SET_LOADING(false);

      this.ORDER_INDEXED_ITEMS_BY("id");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/sass/variables";
@import "../assets/sass/buttons";
@import "../assets/sass/cards";

.page-container {
  width: 100%;
  padding: 10px 1em;
  .show-selector {
    width: 100%;
    position: relative;
    div {
      width: fit-content;
      margin-right: 0px;
      margin-left: auto;
      label {
        width: fit-content;
        margin-right: 5px;
        margin-left: 5px;
      }
      select {
        min-width: 75px;
        width: fit-content;
      }
    }
  }
}
@media (min-width: 768px) {
  .home {
    .section {
      .search-container {
        width: 70%;
      }
    }
    .tabs {
      padding: 15px 10%;
    }
    .section {
      margin: 15px 1em;
      &.side-menu {
        grid-template-columns: auto 260px;
        grid-template-rows: auto;
        grid-template-areas: "objects filter";
      }
    }
  }
}
@media (min-width: 992px) {
  .home {
    .section {
      .search-container {
        width: 60%;
      }
    }
    .tabs {
      padding: 15px 15%;
    }
    .section {
      &.side-menu {
        grid-template-columns: auto 350px;
      }
    }
  }
}
</style>
