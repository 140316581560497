<template>
  <div class="loading" v-show="loading" :class="{'transparent':isTransparent}">
    <div class="box">
      <div class="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <p v-if="loadingText===''">{{text}}</p>
      <p v-else>{{loadingText}}</p>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex"
export default {
    name: 'Loading-component',
    props:['text'],
    computed: {
        ...mapGetters(["loading","loadingText",'isTransparent'])
    },
    watch: {
        loading: function() {
      }
    }
}
</script>
<style lang="scss" scoped>
@import "./../assets/sass/variables";
.transparent{
  background-color: #22190880;

}
div.loading {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  margin: 0px;
  background-color:whitesmoke;
  z-index: 22;
  div.box {
    top: 50%;
    left: 50%;
    margin-top: 0px;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    -webkit-box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.2),
      0 8px 20px 0 rgba(0, 0, 0, 0.19);
    -moz-box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.2),
      0 8px 20px 0 rgba(0, 0, 0, 0.19);
    box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.2), 0 8px 20px 0 rgba(0, 0, 0, 0.19);
    background-color: white;
    transform: translate(-50%, -50%) !important;
    position: absolute;
    p {
      color: $text;
      margin-block-start: 10px;
      margin-block-end: 0px;
      text-align: center;
    }
    .lds-ellipsis {
      background-color: transparent;
      display: block;
      margin-top: 25px;
      position: relative;
      width: 64px;
      height: 40px;
      left: 50%;
      transform: translateX(-50%);
      div {
        position: absolute;
        top: 27px;
        width: 11px;
        height: 11px;
        border-radius: 50%;
        background: $theme;
        animation-timing-function: cubic-bezier(0, 1, 1, 0);
      }
      div:nth-child(1) {
        left: 6px;
        animation: lds-ellipsis1 0.6s infinite;
      }
      div:nth-child(2) {
        left: 6px;
        animation: lds-ellipsis2 0.6s infinite;
      }
      div:nth-child(3) {
        left: 26px;
        animation: lds-ellipsis2 0.6s infinite;
      }
      div:nth-child(4) {
        left: 45px;
        animation: lds-ellipsis3 0.6s infinite;
      }
    }
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(19px, 0);
    }
  }
}
</style>
